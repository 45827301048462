import {Component, OnInit} from '@angular/core';
import {
  DeactivationReason,
  LabelData,
  TalentAccountResource,
  TalentApplicationResource,
  TalentCoachData,
  TalentCoachResource,
  TalentDeactivationData,
  TalentTalentProfileResource,
  UtilityService
} from "utility";
import {FormControl, Validators} from "@angular/forms";
import {TalentAuthService} from "../../services/talent.auth.service";
import {Router} from "@angular/router";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-talent-account-deactivation-dialog',
  templateUrl: './talent-account-deactivation-dialog.component.html',
  styleUrls: ['./talent-account-deactivation-dialog.component.scss']
})
export class TalentAccountDeactivationDialogComponent implements OnInit {
  readonly jobFoundByTalentAgentControl = new FormControl<boolean>(false, [Validators.required]);
  readonly reasonControl = new FormControl<DeactivationReason>('FoundJob', [Validators.required]);
  readonly anonymizeControl = new FormControl<boolean>(false);
  readonly applicationSelectControl = new FormControl<number>(null, Validators.required);

  applicationsWithPositon: LabelData[];
  applicationsLoaded: boolean = false;
  deleteLoading: boolean = false;
  talentAgentData: TalentCoachData;

  constructor(
      public dialogRef: MatDialogRef<TalentAccountDeactivationDialogComponent>,
      private talentApplicationResource: TalentApplicationResource,
      private talentTalentProfileResource: TalentTalentProfileResource,
      private talentAccountResource: TalentAccountResource,
      private talentAuthService: TalentAuthService,
      private utilityService: UtilityService,
      private router: Router,
      private talentTalentAgentResource: TalentCoachResource,
  ) {}

  ngOnInit(): void {
    this.getApplications();
    this.loadTalentAgent();
  }

  loadTalentAgent() {
    this.talentTalentAgentResource.getTalentCoach().then(result => {
      this.talentAgentData = result;
    });
  }

  delete() {
    this.deleteLoading = true
    let data: TalentDeactivationData = {
      reason: this.reasonControl.value,
      applicationId: this.applicationSelectControl.value,
      withdrawRequests: false,
      anonymize: this.anonymizeControl.value,
    };

    this.talentAccountResource.deactivateAccount(data).then(() => {
      this.talentAuthService.logout();
      this.dialogRef.close();
      this.deleteLoading = false
      this.utilityService.showSnackBar("Dein Account wurde erfolgreich deaktiviert.");
    });
  }

  navigateToNotificationSettings() {
    this.dialogRef.close('notification');
  }

  close() {
    this.dialogRef.close(false);
  }

  getApplications() {
    this.talentApplicationResource.getOwnActiveApplicationIdsWithPositionName().then(response => {
      this.applicationsWithPositon = response;
      this.applicationsLoaded = true;
    });
  }
}
