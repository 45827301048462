import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() item: any
  @Input() displayProperty: string
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  getDisplayString(item: any) {
    if (typeof item === 'string') {
      return item
    } else {
      return item[this.displayProperty]
    }
  }


}
