import {Injectable, OnDestroy} from '@angular/core';
import {TalentPositionPreviewData, TalentPositionRelationData, TalentPositionRelationResource} from "utility";
import {PositionService} from "../services/position.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    recommendedPositions: TalentPositionPreviewData[] = []

    pageIndex: number = 0;
    pageSize: number = 10;
    totalRecommendations: number = 0;
    totalPages: number = 0;
    feedIsHovered = false;

  constructor(
      private talentPositionRelationResource: TalentPositionRelationResource,
      public positionService: PositionService,
      private router: Router
  ) {
  }


    loadPositionRecommendations(selectedPositionId?: number, nextPage?: number) {
        this.loading.next(true);
        if (nextPage != undefined) this.pageIndex = nextPage
        this.recommendedPositions = []
        let url = this.router.url
        this.talentPositionRelationResource.getActiveTalentPositionRecommendations(
            {pageNum: this.pageIndex, pageSize: this.pageSize, forcedIds: selectedPositionId? [selectedPositionId] : []}
        ).then(result => {
            this.recommendedPositions = result.content
            this.totalRecommendations = result.totalElements
            this.totalPages = result.totalPages
            this.loading.next(false);
            //not update selected position id page changed since loading start
            if (this.recommendedPositions.length && url == this.router.url) {
                if (selectedPositionId && this.recommendedPositions.findIndex(r => r.relationData.positionId == selectedPositionId) >= 0){
                    this.positionService.selectedPositionId.next(selectedPositionId)
                } else {
                    this.positionService.selectedPositionId.next(this.recommendedPositions[0].id)
                }
            }
        })
    }

}
