import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CityData, LocationResource, TalentLocationPreferenceData, TalentLocationPreferencesResource,} from "utility";
import {MediaService} from "../../../services/inno-utils/media.service";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {SearchService} from "../../../services/search.service";

@Component({
  selector: 'location-dropdown-content',
  templateUrl: './location-dropdown-content.component.html',
  styleUrls: ['./location-dropdown-content.component.scss']
})
export class LocationDropdownContentComponent implements OnInit, OnDestroy, OnChanges  {

  getTalentPreferencesLoading: boolean = false
  talentPreferencesData: TalentLocationPreferenceData
  savedLocationIds = []
  citiesCtr: FormControl = new FormControl()
  wholeGermanyCtr: FormControl = new FormControl('nationWide')

  @Input() selectedRadius: number
  @Input() locationIds: number[] = []

  @Output() selectedRadiusChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() locationIdsChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() nationWideEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()
  loadingLocations: boolean = false

  cityIdsFormSubscription: Subscription = new Subscription()

  constructor(
      private talentLocationPreferencesResource: TalentLocationPreferencesResource,
      public mediaService: MediaService,
      private locationResource: LocationResource,
      private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.wholeGermanyCtr.valueChanges.subscribe(next => {
        this.nationWideEmitter.emit(next === 'nationWide')
    })

    if (this.locationIds?.length > 0) {
      this.getSelectedCities();
    } else {
      this.getTalentPreferences();
    }

    this.cityIdsFormSubscription = this.citiesCtr.valueChanges.subscribe(next => {

      this.updateWholeGermanyCtrValue(next?.length)

      let ids = next.map(it => it.id)
      this.locationIds = ids
      this.savedLocationIds = ids

      this.locationIdsChange.emit(ids)
    })
  }

  updateWholeGermanyCtrValue(newCityCtrlValueLength) {
    if(newCityCtrlValueLength) {
      this.wholeGermanyCtr.setValue('specificCities')
    } else this.wholeGermanyCtr.setValue('nationWide')
  }

  getTalentPreferences() {
    this.talentLocationPreferencesResource.getTalentLocationPreferences().then(result => {
      this.talentPreferencesData = result;
      if (this.talentPreferencesData.searchMode == "SpecificCities") {
          this.citiesCtr.setValue(this.talentPreferencesData?.specificCities);
      }
      this.getTalentPreferencesLoading = true;
    });
  }

  getSelectedCities() {
    this.loadingLocations = true
    this.locationResource.getCitiesById({cityIds: this.locationIds}).then(result => {
      this.citiesCtr.setValue(result)
      if(this.citiesCtr.value.length > 0) {
        this.wholeGermanyCtr.patchValue('specificCities')
      }
      this.loadingLocations = false
    })
  }

  removePreferredCity(city: CityData) {
    let preferredCities = this.citiesCtr.value
    let index = preferredCities.findIndex(c1 => c1.id == city.id)
    preferredCities.splice(index, 1)
    this.citiesCtr.setValue(preferredCities)
  }

  selectCity(event: CityData) {
    if (event === null) return
    let actualCities = []

    if(this.citiesCtr.value){
      actualCities = actualCities.concat(this.citiesCtr.value)
    }

    if (actualCities.findIndex(city => city.id == event.id) > -1) return
    actualCities.push(event)

    this.citiesCtr.setValue(actualCities)
  }

  clearSelectedCities() {
    this.citiesCtr.setValue([])
  }

  ngOnDestroy(): void {
    this.cityIdsFormSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.locationIds.length == 0) {
      this.clearSelectedCities()
    }
  }

}
