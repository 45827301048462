import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from "./home.component";
import {RouterModule, Routes} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UtilityModule} from "utility";
import {BrowserModule} from "@angular/platform-browser";
import {
    ApplicationsHomeDesktopComponent
} from './applications/applications-home-desktop/applications-home-desktop.component';
import {
    ApplicationsHomeMobileComponent
} from './applications/applications-home-mobile/applications-home-mobile.component';
import {BookmarksHomeMobileComponent} from './bookmarks/bookmarks-home-mobile/bookmarks-home-mobile.component';
import {BookmarksHomeDesktopComponent} from './bookmarks/bookmarks-home-desktop/bookmarks-home-desktop.component';
import {CoachHomeDesktopComponent} from './coach/coach-home-desktop/coach-home-desktop.component';
import {CoachHomeMobileComponent} from './coach/coach-home-mobile/coach-home-mobile.component';
import {CvUploadHomeMobileComponent} from './cvUpload/cv-upload-home-mobile/cv-upload-home-mobile.component';
import {CvUploadHomeDesktopComponent} from './cvUpload/cv-upload-home-desktop/cv-upload-home-desktop.component';
import {
    ExplanationHomeDesktopComponent
} from './explanation/explanation-home-desktop/explanation-home-desktop.component';
import {ExplanationHomeMobileComponent} from './explanation/explanation-home-mobile/explanation-home-mobile.component';
import {MatchingHomeMobileComponent} from './matching/matching-home-mobile/matching-home-mobile.component';
import {MatchingHomeDesktopComponent} from './matching/matching-home-desktop/matching-home-desktop.component';
import {NextStepsHomeDesktopComponent} from './nextSteps/next-steps-home-desktop/next-steps-home-desktop.component';
import {NextStepsHomeMobileComponent} from './nextSteps/next-steps-home-mobile/next-steps-home-mobile.component';
import {
    RecommendationsHomeMobileComponent
} from './recommendations/recommendations-home-mobile/recommendations-home-mobile.component';
import {
    RecommendationsHomeDesktopComponent
} from './recommendations/recommendations-home-desktop/recommendations-home-desktop.component';
import {SearchHomeDesktopComponent} from './search/search-home-desktop/search-home-desktop.component';
import {SearchHomeMobileComponent} from './search/search-home-mobile/search-home-mobile.component';
import {ShareHomeMobileComponent} from './share/share-home-mobile/share-home-mobile.component';
import {ShareHomeDesktopComponent} from './share/share-home-desktop/share-home-desktop.component';
import {
    RecommendationsHomeMobileBigComponent
} from './recommendations/recommendations-home-mobile-big/recommendations-home-mobile-big.component';
import {MatchingHomeMobileBigComponent} from './matching/matching-home-mobile-big/matching-home-mobile-big.component';
import {
    StaufenbielTileDesktopComponent
} from './staufenbiel/staufenbiel-tile-desktop/staufenbiel-tile-desktop.component';
import {StaufenbielTileMobileComponent} from './staufenbiel/staufenbiel-tile-mobile/staufenbiel-tile-mobile.component';
import {AuthGuardService} from "../services/auth-guard.service";
import {JoobleTileDesktopComponent} from './jooble/jooble-tile-desktop/jooble-tile-desktop.component';
import {JoobleTileMobileComponent} from './jooble/jooble-tile-mobile/jooble-tile-mobile.component';
import {HomeHeadlineComponent} from "../utility/home-headline/home-headline.component";
import {
    CareerFairyTileDesktopComponent
} from "./career-fairy/career-fairy-tile-desktop/career-fairy-tile-desktop.component";
import {
    CareerFairyTileMobileComponent
} from "./career-fairy/career-fairy-tile-mobile/career-fairy-tile-mobile.component";

const appRoutes: Routes = [
    {
        path: 'home', component: HomeComponent, canActivate: [AuthGuardService]
    }
]

@NgModule({
    declarations: [
        HomeComponent,
        HomeHeadlineComponent,
        ApplicationsHomeDesktopComponent,
        ApplicationsHomeMobileComponent,
        BookmarksHomeMobileComponent,
        BookmarksHomeDesktopComponent,
        CoachHomeDesktopComponent,
        CoachHomeMobileComponent,
        CvUploadHomeMobileComponent,
        CvUploadHomeDesktopComponent,
        ExplanationHomeDesktopComponent,
        ExplanationHomeMobileComponent,
        MatchingHomeMobileComponent,
        MatchingHomeDesktopComponent,
        NextStepsHomeDesktopComponent,
        NextStepsHomeMobileComponent,
        RecommendationsHomeMobileComponent,
        RecommendationsHomeDesktopComponent,
        SearchHomeDesktopComponent,
        SearchHomeMobileComponent,
        ShareHomeMobileComponent,
        ShareHomeDesktopComponent,
        RecommendationsHomeMobileBigComponent,
        MatchingHomeMobileBigComponent,
        StaufenbielTileDesktopComponent,
        StaufenbielTileMobileComponent,
        JoobleTileDesktopComponent,
        JoobleTileMobileComponent,
        CareerFairyTileDesktopComponent,
        CareerFairyTileMobileComponent,
    ],
    exports: [
        RouterModule,
    ],
    imports: [
        RouterModule.forChild(appRoutes),
        BrowserAnimationsModule,
        CommonModule,
        UtilityModule,
        BrowserModule
    ]
})
export class HomeModule {
}
