import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentPositionDetailsData, TalentPositionInteractionOrigin} from "utility";
import {PositionService} from "../../../services/position.service";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {RecommendationService} from "../../../recommendations/recommendation.service";

@Component({
  selector: 'app-positions-details-apply-buttons',
  templateUrl: './positions-details-apply-buttons.component.html',
  styleUrls: ['./positions-details-apply-buttons.component.scss']
})
export class PositionsDetailsApplyButtonsComponent implements OnInit {

  @Input() sticky: boolean = false
  @Input() position: TalentPositionDetailsData
  @Input() origin: TalentPositionInteractionOrigin
  @Input() lightMode: boolean = false

  constructor(
      public mediaService: MediaService,
      private positionService: PositionService,
      private authService: TalentAuthService,
      private recommendationService: RecommendationService
  ) { }

  ngOnInit(): void {
  }

  handleRemoveClick() {
      this.positionService.removePositionFromList(this.position.relationData, this.recommendationService.recommendedPositions).then(() => {
          this.positionService.rejectMatchingPosition(this.position, this.origin)
      })
  }

    handleApplyClick() {
      if (!this.authService.isAuthenticated()) return
      if (['Stepstone', 'Instaffo'].includes(this.position.employmentPosition.employmentPositionOrigin)) {
          this.positionService.trackTalentCtaClickedForPosition(this.position.employmentPosition.id, this.origin)
          window.open(this.position.employmentPosition.webLink, '_blank')
      } else {
          this.positionService.applyForPosition(this.position, this.origin)
      }
    }

}
