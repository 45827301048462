<div class="nps-score-wrapper" [ngClass]="{'show-nps-score': canTalentGiveNpsFeedback, 'nps-score-sent': npsSent}">
<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="!npsSent">
    <div class="header">Wie wahrscheinlich würdest du Talentagent weiterempfehlen?</div>
    <div class="nps-buttons-wrapper" fxLayout="column" fxLayoutAlign="start space-between">

        <div class="nps-rating-wrapper" *ngIf="step == 1"  fxLayoutGap="8px" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px" class="nps-buttons">
            <div *ngFor="let element of arr(11).fill(1); index as i;" (click)="selectScore(i)">{{i}}</div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="font-size-12">sehr unwahrscheinlich</div>
            <div class="font-size-12">sehr wahrscheinlich</div>
        </div>
        </div>

        <div *ngIf="step == 2" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <span>Dein Rating:</span> <span style="font-weight: bold;margin-left: 5px">{{npsRating}} / 10</span> <span class="hid-link" (click)="step = step -1">Ändern</span></div>
            <hid-textarea [minRows]="5" class="m-top5" [label]="getLabel()" [control]="ratingComment"></hid-textarea>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="m-top10">
            <button mat-button (click)="canTalentGiveNpsFeedback = false">jetzt nicht</button>
            <button mat-flat-button color="warn"
                    [disabled]="step != 2"
                    (click)="sendNpsFeedback()"
                    [loadingBool]="loadingSendNpsFeedback">Feedback senden
            </button>
        </div>
    </div>
</div>
<div *ngIf="npsSent">
    <div fxLayout="row" fxLayoutAlign="center start">
        <div  *ngIf="this.npsRating <= 8" class="thank-you">Danke für dein Feedback!</div>
        <div  *ngIf="this.npsRating > 8" class="thank-you">Danke für dein Feedback! <br>Hilf uns und bewerte Talentagent auf Trustpilot! :)</div>
    </div>

    <div class="width-100" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="this.npsRating <= 8">
        <div class="text-center">Talentagent könnte deinen Freunden und Kommilitonen auch gefallen? Dann hilf Ihnen mit diesem Link weiter!</div>
        <div class="m-top10">
            <button mat-flat-button (click)="shareService.showSharingButtons('Hey, schau dir mal Talentagent an: ', '?utm_source=talentagent-share&utm_medium=website-share&utm_campaign=nps-share')" color="primary" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" >
                <i class="mdi mdi-share"></i>
                <span style="font-size: 12px !important; margin-left: 8px !important;">Jetzt teilen</span>
            </button>
        </div>
    </div>

    <div *ngIf="this.npsRating > 8" class="width-100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <img src="../../../assets/images/trustpilot/trustpilot-logo.png" class="trustpilot-logo">
        <button mat-flat-button color="primary" (click)="routeToTrustpilot()">
            <i class="mdi mdi-share"></i>
            <span style="font-size: 12px !important; margin-left: 8px !important;">Bewerte uns jetzt auf Trustpilot</span>
            </button>
    </div>
</div>
</div>
