import {Component, Input, OnInit} from '@angular/core';
import {PositionChipData, PublicEmploymentPositionResource} from "utility";
import {PositionService} from "../../services/position.service";

@Component({
  selector: 'app-positions-chips',
  templateUrl: './positions-chips.component.html',
  styleUrls: ['./positions-chips.component.scss']
})
export class PositionsChipsComponent implements OnInit {

  chips: PositionChipData[] = []

  loading: boolean = false

  @Input() positionId: number
  @Input() topjob : boolean = false

  constructor(
      private publicEmploymentPositionResource: PublicEmploymentPositionResource,
      public positionService: PositionService
  ) { }

  ngOnInit(): void {
    this.loadChips()
  }

  loadChips() {
    this.loading = true
      this.publicEmploymentPositionResource.getEmploymentPositionChipsById(this.positionId).then(result => {
          this.chips = result
          this.loading = false
      })
  }

}
