<div class="flex row start-center">
    <div class="search-input-wrapper-hybrid margin-right" (keyup.enter)="sendKeyword()">
        <input (input)="checkIfKeywordIsValid()"
               [(ngModel)]="searchString"
               type="text" name="search" [placeholder]="placeholder">
        <i *ngIf="searchString?.length > 0"
           (click)="searchString = ''"
           class="mdi mdi-close clear-icon clickable"></i>
    </div>
    <button
            class="dark-search-button flex row center search-button-padding"
            style="max-height: 40px;"
            [ngClass]="{'highlight-dark-element': lastQuery != searchString}"
            (click)="sendKeyword()">
        <i *ngIf="mediaService.isMobile()" class="mdi mdi-send"></i>
        <span *ngIf="!mediaService.isMobile()"
              style="display: inline-block;
              vertical-align: top;
              white-space: nowrap;
              ">Stellen suchen</span>
    </button>
</div>
