import {Component, OnInit} from '@angular/core';
import {PublicEmploymentPositionResource, TalentPositionPreviewData} from "utility";
import {ActivatedRoute} from "@angular/router";
import {PositionService} from "../services/position.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-position-preview',
    templateUrl: './position-preview.component.html',
    styleUrls: ['./position-preview.component.scss']
})
export class PositionPreviewComponent implements OnInit {

    previewPosition: TalentPositionPreviewData
    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    splitId?: number = null
    positionId: number = null

    constructor(
        private publicEmploymentPositionResource: PublicEmploymentPositionResource,
        private route: ActivatedRoute,
        private positionService: PositionService
    ) {
        this.positionId = parseInt(this.route.snapshot.params['position'])
        this.route.snapshot.queryParams['splitId'] ? this.splitId = parseInt(this.route.snapshot.queryParams['splitId']) : this.splitId = null
    }

    ngOnInit(): void {
        this.getPreview()
    }

    getPreview() {
        this.loading.next(true)
        this.publicEmploymentPositionResource.getSingleEmploymentPositionPreviewById(this.positionId, {splitId: this.splitId})
            .then(result => {
                this.loading.next(false)
                this.previewPosition = result
                this.positionService.selectedSplitId = this.splitId
                this.positionService.selectedPositionId.next(this.positionId)
            })
    }
}
