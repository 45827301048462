<div fxLayout="column" fxLayoutAlign="start start" class="not-found">

  <img src="../../../assets/images/404.jpg" width="100%">

  <div class="not-found-content-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="not-found-content" fxLayout="column" fxLayoutAlign="center center">
      <div  class="title-404">404</div>
      <div  class="title">Hier gehts leider nicht zu deiner Traumfirma!</div>
      <div  class="sub-title">
        Die von dir angeforderte Seite, gibt es leider nicht.
      </div>
      <div class="button-bar" fxLayoutAlign="center">
        <a routerLink="/" mat-raised-button color="primary" *ngIf="!authService.isAuthenticated()">
          Zur Startseite
        </a>
        <a routerLink="/talent/proposals/matches" mat-raised-button color="primary" *ngIf="authService.isAuthenticated()">
          Zu den Stellenvorschlägen
        </a>
      </div>
    </div>
  </div>
</div>
