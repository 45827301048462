import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {MediaService} from "../../../services/inno-utils/media.service";
import {delay} from "../../../services/inno-utils/general-utils.service";
import {MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";

let nextid = 1;

@Component({
  selector: 'hid-input-field',
  templateUrl: './hid-input-field.component.html',
  styleUrls: ['./hid-input-field.component.scss']
})

export class HidInputFieldComponent implements OnInit {

  @Input() showValidatorIcons: boolean;
  @Input() label: string;
  @Input() model: any;
  @Input() type: string;
  @Input() control: AbstractControl;
  @Input() disabled: boolean;
  @Input() lock: boolean;
  @Input() name: string;
  @Input() inputFieldClass: string = '';
  @Input() forceValidation: Subject<boolean>; //Subject or behaviouralsubject
  @Input() mask: string;
  @Input() readonly: boolean = false;
  @Input() step: string;
  @Input() inputHeight: number = 37;
  @Input() pattern: string;
  @Input() hideLabelWhenFilled: boolean = false;
  @Input() matAutocomplete: MatAutocomplete;

  @Input() autocomplete: string = 'on';

  @Input() focusInputField: Subject<any>;

  //Scrolls field into view after keyboard-opening on mobile
  @Input() autoFocus: boolean;

  @Input() highlighted: boolean;

  //Provide if your FormCOntrol has async validators. This prevents invalid indicator to show before the validation is ready. If this smh makes performance problems, do no initialize it in any case and rather use a secodn property and a subscriber
  @Input() validationPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() showClearButton: boolean = false

  validationForced;

  show = true;

  dynamicId = "hid-input-" + nextid++;
  required: boolean = false;


  isPassword = false;

  @ViewChild("input") input: ElementRef;

  @ViewChild("scrollTarget", { static: true }) scrollTarget: ElementRef

  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() inputFocused: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<null> = new EventEmitter();
  @Output() focusIn: EventEmitter<boolean> = new EventEmitter();
  @Output() focusOut: EventEmitter<boolean> = new EventEmitter();


  constructor(private el: ElementRef, private mediaService: MediaService) {
  }

  ngOnInit() {
    if (this.focusInputField != null) {
      this.focusInputField.subscribe(() => {
        //in combination with Hide-attributes on  the input field, the element might not be available for focus yet
        delay(200).then(() => {
          this.input.nativeElement.focus()

        })
      })
    }

    if (this.autoFocus == null) {
      this.autoFocus = this.mediaService.isMobile()
    }

    if (!this.mask) this.mask = '';
    if (!this.control) {
      this.control = new UntypedFormControl()
      this.control.setValue(this.model)
    }
    this.control.valueChanges.subscribe(n => {
      this.modelChange.emit(n)
    })
    this.required = this.validator();

    if (this.forceValidation) {
      this.forceValidation.subscribe(forced => {
        this.validationForced = forced;
        this.validateControl()
      })
    }

    this.isPassword = this.type === 'password';

  }

  validator() {
    if (!this.control.validator) return false
    const validator = this.control.validator({} as AbstractControl);
    if (validator != null && validator['required']) {
      return true;
    }
    return false
  }

  //This is required to react to autofill-functions of browsers. Otherwise formcontrol doesnt get it
  updateControl(ev) {
    this.control.setValue(ev.target.value)
  }

  reportTouch() {
    if (this.el.nativeElement.className.indexOf(' touched') == -1 && this.model != null && this.model.length > 0) {
      this.el.nativeElement.className += ' touched';
    }
    this.blur.emit();
  }


  focus() {
    this.inputFocused.emit()
    if (this.autoFocus) {
      // Commented out because container will be scrolled out of viewport sometimes. No solution yet.
      /* delay(200).then(() => {
         this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth'});
       })*/
    }
  }

  togglePasswordVisibility() {
    if (!this.isPassword) return
    if (this.type === 'password') {
      this.type = 'text'
    } else {
      this.type = 'password';
    }
  }

  validateControl() {
    this.control.markAsTouched()
  }
}
