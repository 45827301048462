import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {delay, TalentOnboardingResource} from "utility";
import {EventCachedOBInputs, EventOnboardingService} from "../event-onboarding.service";

@Component({
  selector: 'app-event-ob-select-subject',
  templateUrl: './event-ob-select-subject.component.html',
  styleUrls: ['./event-ob-select-subject.component.scss']
})
export class EventObSelectSubjectComponent implements OnInit {


  readonly control = new FormControl<number>(this.obService.cachedInputs.subjectId, [Validators.required])
  constructor(private router: Router, private obService: EventOnboardingService, private onboardingResource: TalentOnboardingResource,

              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  progress(){
    this.obService.updateInputs(<EventCachedOBInputs>{subjectId: this.control.value});
    this.obService.updateStudyAndPreferencesForTalent()

    // this.getAvailablePositionsForSubject().then(res => {
    //   if (res > 16) {
    //     this.showPositionsFoundAnimation = true
    //     delay(100).then(() => {
    //       this.animatePositionCount(res)
    //       delay(3200).then(() => {
    //         this.obService.delayedRouting( "/onboarding/event/password")
    //       })
    //     })
    //   } else {
        this.obService.delayedRouting( "/onboarding/event/cv")
      // }
    // })

  }


  //ANIMATION STUFF//
  showPositionsFoundAnimation = false;

  availablePositions;
  getAvailablePositionsForSubject(): Promise<number> {
    return new Promise(resolve => {
      if (!this.obService.cachedInputs?.subjectId) {
        resolve(null)
        return null
      }

      this.onboardingResource.getAvailablePositionsCountForSubject(
          {subjectId: this.obService.cachedInputs?.subjectId}
      ).then(result => {
        this.availablePositions = result.matchingPositionsCount
        resolve(this.availablePositions)
      })
    })
  }

  availablePositionContent: string[] = []


  animatePositionCount(availablePositionCount: number) {
    let wrapper = document.getElementById('available-position-count')
    wrapper.innerHTML = ''
    let textElement = document.getElementById('available-position-text')

    textElement.setAttribute("class", "")
    this.availablePositionContent = availablePositionCount.toString().split('')
    for (let i = 0; i < this.availablePositionContent.length; i++) {
      let element = document.createElement('div', {});
      element.classList.add('count-number');
      let text = document.createTextNode(this.availablePositionContent[i]);
      element.appendChild(text);
      wrapper.appendChild(element)
    }
    this.changeDetectorRef.detectChanges()

    setTimeout(() => {
      textElement.classList.add('animate-position-text-fade-in')
      setTimeout(() => {
        textElement.classList.add('animate-position-text-fade-out')
      }, (3500 - (this.availablePositionContent.length * 200) - 400))
    }, (this.availablePositionContent.length * 200))
  }
}
