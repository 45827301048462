<div class="whoAreU" fxLayout="column" fxLayoutAlign="center center">
    <div class="mob-headline">Wie können Unternehmen Kontakt zu dir aufnehmen? 💻</div>
    <div class="step-content-ob">

        <div class="m-bottom20">
            <hid-input-field [control]="form.controls['firstName']"
                             label="Vorname"
                             name="firstName"
                             type="text"
                             autocomplete="given-name"
                             [forceValidation]="checkError"
                             [showValidatorIcons]="false"
                             [showClearButton]="true">
            </hid-input-field>
        </div>

        <div class="m-bottom20">
            <hid-input-field [control]="form.controls['lastName']"
                             label="Nachname"
                             type="text"
                             name="lastName"
                             autocomplete="family-name"
                             [showValidatorIcons]="false"
                             [showClearButton]="true">
            </hid-input-field>
        </div>

        <div class="m-bottom20">
            <hid-input-field
                    [forceValidation]="checkError"
                    [validationPending]="emailValidationPending" name="email"
                    [autocomplete]="'off'"
                    [showValidatorIcons]="false"
                    [showClearButton]="true"
                    autocomplete="email"
                    [control]="form.controls['contactEmailAddress']" label="Email-Adresse" type="email">
            </hid-input-field>
        </div>

        <span class="hid-validationError duplicate-mail-error"
              style="margin-bottom: 10px"
              *ngIf="form.controls['contactEmailAddress'].errors &&
          form.controls['contactEmailAddress'].errors['emailTaken'] &&
          !form.controls['contactEmailAddress'].value.includes('@mlp.de')">
        Diese Email-Adresse ist bereits vergeben.<br>
        <a  routerLink="/talent/login">Jetzt anmelden</a> oder
        <a (click)="forgotPassword()">Neues Passwort anfordern</a>
    </span>

        <span class="hid-validationError"
              style="margin-bottom: 10px"
              *ngIf="form.controls['contactEmailAddress'].errors &&
          form.controls['contactEmailAddress'].errors['emailTaken'] &&
          form.controls['contactEmailAddress'].value.includes('@mlp.de')">
        Emailadressen, die mit @mlp.de enden sind nicht erlaubt.
    </span>

        <div class="m-bottom20">
            <hid-input-phone-number
                    [control]="form.controls['phoneNumber']"
                    label="Telefonnummer"
                    name="phoneNumber"
                    type="tel"
                    [showValidatorIcons]="false"
                    [showClearButton]="true"
                    [forceValidation]="checkError">
            </hid-input-phone-number>
        </div>

        <div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="10px" class="privacy">
            <mat-checkbox [formControl]="form.controls.acceptPrivacy" style="max-width: 350px;" #checkbox>
                <div [ngClass]="{'hid-validationError': (!form.controls.acceptPrivacy.valid && nextStepClicked)}"
                     style="    max-width: 300px;
    display: block;
    white-space: normal;
    font-size: 12px;
    line-height: 13px;
}">Ich akzeptiere die <a class="font-size-12" [href]="useRegulationsUrl" target="_blank">Nutzerbestimmungen</a> und
                    die
                    Bestimmungen zum <a class="font-size-12" [href]="privacyUrl" target="_blank"> Datenschutz</a> und bin mit den dort erklärten
                    Einwilligungen
                    einverstanden.
                </div>
            </mat-checkbox>

        </div>

        <span class="hid-validationError duplicate-mail-error"
              style="margin-top: 10px; padding: 5px 20px; text-align: center"
              *ngIf="!form.controls.acceptPrivacy.value && submitFailed"
        >
            Du musst den Nutzerbestimmungen zustimmen, um fortzufahren
        </span>
    </div>

    <!--app-s2-o-button-bar [valid]="form.controls.firstName?.valid && form.controls.lastName?.valid && form.controls.phoneNumber?.valid &&
                                  form.controls.acceptPrivacy?.valid && form.controls.contactEmailAddress?.valid"
                         (pressedNext)="progress()" [buttonLockSubject]="progressingSubject">
    </app-s2-o-button-bar-->
    <app-cpd-o-button-bar [valid]="!emailValidationPending.value"
                                 (pressedNext)="progress()" [buttonLockSubject]="progressingSubject">
    </app-cpd-o-button-bar>
</div>
