import { Component, OnInit } from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";
import {Subject} from "rxjs";
import {FileService} from "../../../services/file.service";
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentOnboardingResource} from "utility";
import {CachedOBInputs, S2Fax2OnboardingService} from "../s2-fax2-onboarding.service";

@Component({
  selector: 'app-s2-fax2-o-cv-upload',
  templateUrl: './s2-fax2-o-cv-upload.component.html',
  styleUrls: ['./s2-fax2-o-cv-upload.component.scss']
})
export class S2Fax2OCvUploadComponent implements OnInit {
    controlErrors: ValidationErrors
    fileValidSubject = new Subject()
    readonly cvFile = new FormControl<FileList>(null, this.fileService.uploadFileControlValidator());
    fileIsWordDocument: boolean = false;
    uploadTried: boolean = false;
    preSelectedFile: boolean = false;


    constructor(
        public fileService: FileService,
        private onboardingService: S2Fax2OnboardingService,
        public mediaService: MediaService,
        private talentOnboardingResource: TalentOnboardingResource
    ) {
    }

    ngAfterViewInit(): void {
        this.updateValidSubject()
    }

    ngOnInit(): void {
        let file = this.onboardingService.getCv()

        if (file) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            this.cvFile.setValue(dataTransfer.files)
            this.preSelectedFile = true
        }
        this.cvFile.valueChanges.subscribe(() => {
            this.updateValidSubject()
        });
    }

    updateValidSubject(): boolean {
        let valid = (this.cvFile.valid && !this.fileIsWordDocument) || this.preSelectedFile
        this.fileValidSubject.next(valid)
        return valid
    }

    checkForWordDocument(file) {
        if (!file) {
            this.fileIsWordDocument = false
        } else {
            this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
        }

        this.updateValidSubject()
    }

    set(fileEvent: Event) {
        let file = this.cvFile.value[0];

        this.checkForWordDocument(file)
        this.attemptUpload();
    }


    attemptUpload() {
        this.preSelectedFile = false
        this.uploadTried = true
        let valid = this.updateValidSubject()
        this.controlErrors = this.cvFile.errors

        if (valid) {
            this.fileService.uploadCV(this.cvFile.value[0])
            this.onboardingService.updateInputs(<CachedOBInputs>{
                cvUploaded: true
            })
            this.onboardingService.setCV(this.cvFile.value[0])
            this.progress("upload")
        } else {
            this.cvFile.setValue(null)
        }
    }

    skipNoCv() {
        this.talentOnboardingResource.reportTalentSkipedCvUploadOnboarding()
        this.progress('skip')
    }

    progress(context: string) {
        this.onboardingService.routeToPlattform(context)
    }
}
