<div class="study-search-wrapper">
    <div fxLayout="row" fxLayoutAlign="start start" class="width-100">
        <div fxLayout="column" fxLayoutAlign="start start" class="width-100">
            <div class="custom-label">(Aktueller) Studiengang</div>
            <hid-input-field [control]="searchControl"
                             class="width-100"
                             [showValidatorIcons]="this.control.value"
                             [autocomplete]="'off'">
            </hid-input-field>
        </div>

        <div [fxHide]="!showSpinner" fxLayoutAlign="end center" style="height: 0; width: 0">
            <i class="input-icon validator-icon fas fa-spinner"></i>
        </div>
    </div>

    <div *ngIf="searchedOneTime" class="width-100 m-bottom15 m-top10" style="position: relative">
        <div (click)="scrollChipsLeft()" *ngIf="!chipsScrolledLeft"  class="chevron-left-wrapper">
            <div class="chevron-left"><i
                    class="mdi mdi-chevron-left"></i></div>
            <div class="blend"></div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" class="degree-wrapper"
             (scroll)="handleChipsScroll($event)" #chipsWrapper>
            <div class="degree"
                 [class.selected]="selectedDegree == 'all'"
                 (click)="selectDegree('all')">
                Alle
            </div>
            <div class="degree"
                 [class.selected]="selectedDegree == degree"
                 *ngFor="let degree of degreeTypes"
                 (click)="selectDegree(degree)">
                {{degree | degreeType}}
            </div>
        </div>
        <div class="chevron-right-wrapper" (click)="scrollChipsRight()" *ngIf="!chipsScrolledRight">
            <div class="blend"></div>
            <div class="chevron-right"><i
                    class="mdi mdi-chevron-right"></i></div>
        </div>

    </div>


    <div class="result-list" *ngIf="showResults" fxLayout="column" fxLayoutGap="14px">
        <div class="result" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
             *ngFor="let r of searchResults">
            <div class="subject-name">{{r.name}}</div>
            <div>
                <button mat-ripple class="select-button"
                        (click)="setStudyControl(r)">Auswählen
                </button>
            </div>
        </div>
        <div *ngIf="!searchResults?.length && searched" class="nothing-found">Zu deinem Suchbegriff haben wir keinen
            Studiengang gefunden
        </div>
    </div>
</div>
