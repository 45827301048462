import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiResource} from '../../../../../../utility/src/lib/api-resource';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {delay} from '../../../services/inno-utils/general-utils.service';
import {Subject} from "rxjs";
import {MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";

export class LoadingFunctionConfiguration {
  constructor(
    public resource: ApiResource,
    public functionName: string) {
  }
}

@Component({
  selector: 'hid-autocomplete-input-field',
  templateUrl: './hid-autocomplete-input-field.component.html',
  styleUrls: ['./hid-autocomplete-input-field.component.scss']
})
export class HidAutocompleteInputFieldComponent implements OnInit, AfterViewInit {

  @Input() model: any;
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() displayProperty: string;
  @Input() valueProperty: string;
  @Input() set showValidatorIcons(value: boolean) {
    this.showValidatorIconsInner = value
    this.showValidatorIconsInitial = value
  }
  @Input() set showSmallLoadingSpinner(value: boolean) {
    this.showSmallLoadingSpinnerInitial = value
    this.showSmallLoadingSpinnerInner = value
  }
  @Input() forceValidation: Subject<boolean>;
  @Input() disabled: boolean = false;

  @Input() loadingFunctionConfig: LoadingFunctionConfiguration;

  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() openingAttempted: EventEmitter<number> = new EventEmitter();

  @ViewChild('menuTriggerStudy') menuTriggerStudy: MatMenuTrigger
  @ViewChild('menuStudy') menuStudy: MatMenu

  options = [];
  oldControlValue: any;

  loadingOptions: boolean;
  clickedOption: string = '';

  showValidatorIconsInner: boolean = true
  showValidatorIconsInitial: boolean = true
  showSmallLoadingSpinnerInner: boolean = true
  showSmallLoadingSpinnerInitial: boolean = true

  requestIndex: number = 0;

  constructor(
    private fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {


    if (!this.control) {
      this.control = new UntypedFormControl();
      this.control.setValue(this.model);
    }

    this.oldControlValue = this.control.value;

    this.control.valueChanges.subscribe(event => {
      if (this.clickedOption == this.control.value) return;

      if (this.oldControlValue != event) {
        this.updateInnerShowSmallLoadingSpinnerIfNecesarry(true)
        this.updateInnerShowValidIconsIfNecesarry(false)
        this.oldControlValue = event;
        this.modelChange.emit(event);
        this.loadingOptions = true;

        delay(500).then(() => {
          let localRequestIndex = ++this.requestIndex;
          this.loadSuggestions().then(() => {
            if (localRequestIndex != this.requestIndex) {
              this.updateInnerShowSmallLoadingSpinnerIfNecesarry(false)
              this.updateInnerShowValidIconsIfNecesarry(true)
              return
            }
              this.toggleMatMenuIfNecessary()
            this.updateInnerShowValidIconsIfNecesarry(true)
            this.updateInnerShowSmallLoadingSpinnerIfNecesarry(false)
          });
        });
      }
    });

    if (this.displayProperty == null) this.displayProperty = 'name';
  }

  updateInnerShowValidIconsIfNecesarry(newValue: boolean) {
    if(this.showValidatorIconsInitial) {
      this.showValidatorIconsInner = newValue
    }
  }
  updateInnerShowSmallLoadingSpinnerIfNecesarry(newValue: boolean) {
    if(this.showSmallLoadingSpinnerInitial) {
      this.showSmallLoadingSpinnerInner = newValue
    }
  }

  toggleMatMenuIfNecessary() {
    if (this.options.length != 0 && !this.menuTriggerStudy.menuOpen) {
      this.menuTriggerStudy.openMenu()
    } else if (this.options.length == 0 && this.menuTriggerStudy.menuOpen) {
      this.menuTriggerStudy.closeMenu()
    }
  }

  async loadSuggestions() {
    await this.loadingFunctionConfig.resource[this.loadingFunctionConfig.functionName]({
      q: this.control.value,
      page: 0,
      pageSize: 10,
      forcedIds: null
    }).then(result => {
      this.options = []
      for (let o of result.content) {
        this.options.push(o);
      }
      this.loadingOptions = false;
    });
  }

  isSelected(option) {
      return this.control.value?.id == option?.id;
  }

  setModel(option) {
    if (!option[this.displayProperty]) console.warn('Display property unknown');
    this.clickedOption = option[this.displayProperty];
    this.control.setValue(option[this.valueProperty ? this.valueProperty : this.displayProperty]);
    this.updateInnerShowSmallLoadingSpinnerIfNecesarry(false)
    this.updateInnerShowValidIconsIfNecesarry(true)
  }

  ngAfterViewInit(): void {
    this.menuStudy.focusFirstItem = () => {}
  }


}
