import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {delay, TalentFeedbackData, TalentRateTalentagentResource} from "utility";
import {FormControl} from "@angular/forms";
import {TalentAuthService} from "../../services/talent.auth.service";
import {Subscription} from "rxjs";
import {ShareService} from "../../services/share.service";

@Component({
  selector: 'app-npsscore-overlay',
  templateUrl: './npsscore-overlay.component.html',
  styleUrls: ['./npsscore-overlay.component.scss']
})
export class NPSScoreOverlayComponent implements OnInit {

  canTalentGiveNpsFeedback = false;
  npsSent = false;

  npsRating: number
  arr = Array

  loadingSendNpsFeedback = false;

  sendNpsWithValue: number;
  queryParamSubscription: Subscription

  step = 1;

  readonly ratingComment = new FormControl<string>('')
  origin : 'Email' | 'Talentagent'= 'Talentagent';

  constructor(
      private route: ActivatedRoute,
      private talentRateTalentagentResource: TalentRateTalentagentResource,
      public shareService: ShareService,
      private talentAuthService: TalentAuthService) { }

  ngOnInit(): void {
   this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      if ('sendNpsWithValue' in params) {
        this.canTalentGiveNpsFeedback = true;
        this.origin = "Email"
        this.selectScore(parseInt(params['sendNpsWithValue']))
        this.queryParamSubscription.unsubscribe()
      } else if (this.talentAuthService.isAuthenticated()){
        this.talentRateTalentagentResource.canTalentSendNpsScoreFeedback().then(res => {
          //initial params are empty, so the request might get triggered before the if-condition is true in case of param
          if(!this.canTalentGiveNpsFeedback) {
            this.canTalentGiveNpsFeedback = res;
            this.queryParamSubscription.unsubscribe()
          }
        })
      }
    })
  }

  routeToTrustpilot() {
      window.open("https://de.trustpilot.com/evaluate/talentagent.de", "_blank")
  }

  getLabel():string{
    if(this.npsRating < 8){
      return "Was müssen wir besser machen? (optional)"
    }else{
      return "Kommentar (optional)"
    }
  }

  selectScore(score: number){
    this.npsRating = score;
    this.step++
  }

  sendNpsFeedback() {
    this.loadingSendNpsFeedback = true
    let data:TalentFeedbackData = {
      origin: this.origin,
      comment: this.ratingComment.value,
      score: this.npsRating
    }
    this.talentRateTalentagentResource.sendNpsScoreFeedback(data).then(() => {
      this.loadingSendNpsFeedback = false
      this.npsSent = true

      delay(9000).then(() => {
        this.canTalentGiveNpsFeedback = false
      })
    })
  }
}
