import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "../services/auth-guard.service";
import {UtilityModule} from "utility";
import {BrowserModule} from "@angular/platform-browser";
import {MatDividerModule} from "@angular/material/divider";
import { ChatOverlayComponent } from './chat-overlay/chat-overlay.component';

@NgModule({
  declarations: [
      ChatOverlayComponent
  ],
  imports: [
      CommonModule,
      UtilityModule,
      BrowserModule,
      MatDividerModule
  ],
    exports: [
        RouterModule,
        ChatOverlayComponent
    ]
})
export class ChatModule { }
