import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BenefitData, BenefitResource, EmploymentCategory, LabelData, PagedData} from "utility";
import {SearchService} from "../../../../services/search.service";
import {MediaService} from "../../../../services/inno-utils/media.service";
import {PositionService} from "../../../../services/position.service";
import {BottomSheetService} from "../../../../utility/slideable-bottom-sheets/bottom-sheet.service";

@Component({
  selector: 'app-filter-other-form',
  templateUrl: './filter-other-form.component.html',
  styleUrls: ['./filter-other-form.component.scss']
})
export class FilterOtherFormComponent implements OnInit {

    @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>()


    isFocused: boolean = false;

  minValue: number = 5;
  maxValue: number = 48;

  lastChangedWorkingHours: 'from' | 'to' | null = null

  benefitQuery: string = ""
  searchedBenefits: BenefitData[] = []
  timeBenefits: LabelData[] = []
    benefitsLabelData: LabelData[] = []
  chosenBenefits: LabelData[] = []
    chosenTimeBenefits: LabelData[] = []
    loadingTimeBenefits: boolean = false

    workingHoursFrom: number = 5
    workingHoursTo: number = 48

  employmentTypes: EmploymentCategory[] = [
      "PermanentEmployment",
      "Trainee",
      "DualStudies",
      "WorkingStudent",
      "MandatoryInternship"
  ]


  selectedEmploymentTypes: EmploymentCategory[] = ["PermanentEmployment"]

  workTimeExpanded: boolean = true
  removingWorkTime: boolean = false
  removingEmploymentTypes: boolean = false
  employmentTypesExpanded: boolean = true
  removingBenefits: boolean = false
  benefitsExpanded: boolean = true

    stickyCtaButtons: boolean = false

    addingBenefit: boolean = false


    // workingHoursLabel(value: number): string {
    //   if(this.lastChangedWorkingHours === 'from') {
    //       return this.workingHoursTo + 'h';
    //   }
    //   else if(this.lastChangedWorkingHours === 'to') {
    //       return this.workingHoursFrom + 'h';
    //   }
    //   else return ''
    // }

  constructor(
      private benefitResource: BenefitResource,
      private searchService: SearchService,
      public mediaService: MediaService,
      private positionService: PositionService,
      private bottomSheetService: BottomSheetService
  ) {
      this.bottomSheetService.sheetFullHeight.subscribe(next => {
          this.stickyCtaButtons = next
      })
  }


  ngOnInit(): void {
      this.searchService.updateLastFilter()
      this.getCurrentEmploymentCategories()
      this.getCurrentWorkTimes()
      this.loadBenefits()
      this.getTimeRelatedBenefits()
  }

    changeEmploymentType(type: EmploymentCategory) {
        if(this.selectedEmploymentTypes.includes(type)){
            this.selectedEmploymentTypes = this.selectedEmploymentTypes.filter(t => t !== type)
            return
        }
        else {
            this.selectedEmploymentTypes.push(type)
        }
    }


  addBenefit(benefit: BenefitData) {
      this.chosenBenefits.push(<LabelData>{name: benefit.name, id: benefit.id})
      setTimeout(() => {
          this.searchedBenefits = this.searchedBenefits.filter(b => b !== benefit)
          this.isFocused = false
      }, 50)
  }

  removeBenefit(benefit: LabelData) {
      //this seems weird, but it's a necessary workaround for the click outside directive
      setTimeout(() => {
          this.chosenBenefits = this.chosenBenefits.filter(b => b.id !== benefit.id)
      }, 50)
  }

  toggleTimeBenefit(benefit: LabelData){
      if(this.chosenTimeBenefits.includes(benefit)){
          this.chosenTimeBenefits = this.chosenTimeBenefits.filter(b => b.id !== benefit.id)
      }
      else {
         this.chosenTimeBenefits.push(benefit)
      }
  }

  getTimeRelatedBenefits() {
      this.loadingTimeBenefits = true
      this.benefitResource.getTimeRelatedBenefits().then((benefits: BenefitData[]) => {
        this.timeBenefits = benefits.map(b => <LabelData>{name: b.name, id: b.id})
          this.chosenTimeBenefits = this.timeBenefits.filter(timeBenefit =>
              this.searchService.searchFilterData.benefits.some(benefit => benefit.id === timeBenefit.id)
          );
          this.getCurrentBenefits()
      })
  }
    getCurrentBenefits() {
        this.chosenBenefits = this.searchService.searchFilterData.benefits.filter(benefit =>
            !this.chosenTimeBenefits.some(timeBenefit => timeBenefit.id === benefit.id)
        );
        this.loadingTimeBenefits = false
    }

    getCurrentEmploymentCategories() {
        this.selectedEmploymentTypes = [...this.searchService.searchFilterData.employmentCategories]
    }

    getCurrentWorkTimes() {
        this.workingHoursFrom = this.searchService.searchFilterData.workingHourFrom
        this.workingHoursTo = this.searchService.searchFilterData.workingHourTo
    }

  loadBenefits(query: string | null = null) {
      let searchQuery = ''
      if(query) searchQuery = query
      else searchQuery = this.benefitQuery
      this.benefitResource.getBenefits({searchString: searchQuery, page: 0, pageSize: 40})
          .then((benefits: PagedData<BenefitData>) => {
            this.searchedBenefits = benefits.content.filter(b => !(this.chosenBenefits.findIndex(ben => b.id == ben.id) >= 0))
            this.benefitsLabelData = this.benefitsToLabelData(this.searchedBenefits)
            //if we can not find the specific benefit, load all benefits
            if(benefits.content.length == 0){
                this.loadBenefits('')
            }
          })
  }

  applyFilterAndSearch() {
      this.searchService.searchFilterData.benefits = this.chosenBenefits
      this.searchService.searchFilterData.benefits = this.searchService.searchFilterData.benefits.concat(this.chosenTimeBenefits)
      this.searchService.searchFilterData.employmentCategories = this.selectedEmploymentTypes
      this.searchService.searchFilterData.workingHourFrom = this.workingHoursFrom
      this.searchService.searchFilterData.workingHourTo = this.workingHoursTo
      this.closeFilter.emit()
      this.searchService.triggerSearch()
  }

    clearFilterAndSearch() {
        this.chosenBenefits = []
        this.chosenTimeBenefits = []
        this.selectedEmploymentTypes = []
        this.workingHoursFrom = 5
        this.workingHoursTo = 48
        this.searchService.searchFilterData.benefits = []
        this.searchService.searchFilterData.employmentCategories = []
        this.searchService.searchFilterData.workingHourFrom = 5
        this.searchService.searchFilterData.workingHourTo = 48
        this.closeFilter.emit()
        this.searchService.triggerSearch()
    }

    benefitsToLabelData(benefits: BenefitData[]): LabelData[] {
        return benefits.map(b => <LabelData>{id: b.id, name: b.name})
    }

    addBenefitAsLabelData(labelBenefit: LabelData) {
      if(this.chosenBenefits.includes(labelBenefit) || this.addingBenefit) {
          this.removeBenefit(labelBenefit)
          return
      }
        this.addingBenefit = true
        console.log(this.chosenBenefits)
        this.chosenBenefits.push(labelBenefit)
        console.log(this.chosenBenefits)
        setTimeout(() => {
            this.searchedBenefits = this.searchedBenefits.filter(b => b.id !== labelBenefit.id)
            this.isFocused = false
            this.addingBenefit = false
        }, 50)
    }

    expandOrMinimizeSection(section: 'benefits' | 'employmentTypes' | 'workTime') {
      switch (section) {
            case 'benefits':
                if(this.removingBenefits) this.removingBenefits = true
                setTimeout(() => {
                    this.benefitsExpanded = !this.benefitsExpanded
                    if(!this.benefitsExpanded) this.removingBenefits = false
                }, 50)
                break
            case 'employmentTypes':
                if(this.removingEmploymentTypes) this.removingEmploymentTypes = true
                setTimeout(() => {
                    this.employmentTypesExpanded = !this.employmentTypesExpanded
                    if(!this.employmentTypesExpanded) this.removingEmploymentTypes = false
                }, 50)
                break
            case 'workTime':
                if(this.removingWorkTime) this.removingWorkTime = true
                setTimeout(() => {
                    this.workTimeExpanded = !this.workTimeExpanded
                    if(!this.workTimeExpanded) this.removingWorkTime = false
                }, 50)
                break
      }
    }

    deselectAllEmploymentTypes() {
        this.selectedEmploymentTypes = []
    }

    resetWorkTimes(){
        this.workingHoursFrom = 5
        this.workingHoursTo = 48
        this.chosenTimeBenefits = []
    }

    deselectAllBenefits(){
        this.chosenBenefits = []
    }

    updateWorkingHoursFrom(event: any){
      this.workingHoursFrom =  parseInt(event.target.value)
        if(this.workingHoursFrom < 5) this.workingHoursFrom = 5
        if(this.workingHoursFrom >= this.workingHoursTo) this.workingHoursFrom = this.workingHoursTo - 1
    }

    updateWorkingHoursTo(event: any){
      this.workingHoursTo = parseInt(event.target.value)
        if(this.workingHoursTo > 48) this.workingHoursTo = 48
        if(this.workingHoursTo <= this.workingHoursFrom) this.workingHoursTo = this.workingHoursFrom + 1
    }

    updateWorkingHoursFromOnDrag(event: any): void {
        this.workingHoursFrom = parseInt(event.target.value, 10);
        this.bottomSheetService.pauseScrollAndResize.next(true); // Notify to pause
    }

    updateWorkingHoursToOnDrag(event: any): void {
        this.workingHoursTo = parseInt(event.target.value, 10);
        this.bottomSheetService.pauseScrollAndResize.next(true); // Notify to pause
    }

    stopDragging(): void {
        this.bottomSheetService.pauseScrollAndResize.next(false); // Notify to resume
    }

    scrollToBottom(event: boolean){
        if(this.mediaService.isMobile()) return
        if(!event) return;
        setTimeout(() => {
            const outerWrapper = document.getElementById('filter-other-inner-container')
            outerWrapper.scrollTo({ behavior: 'smooth', top: outerWrapper.scrollHeight });
        }, 50)
    }
}
