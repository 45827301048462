<div (click)="openPositionDetails(position.id)"
     *ngIf="position"
     appInView
     (inView)="setIsInView($event)"
     [ngClass]="{
     'selected-box': positionService?.selectedPositionId?.value == position.id && !position.topjobSlotData,
     'transparent-container': positionService?.selectedPositionId?.value != position.id && !position.topjobSlotData,
     'light-teal-container': positionService?.selectedPositionId?.value == position.id && position.topjobSlotData,
     'very-light-teal-container': positionService?.selectedPositionId?.value != position.id && position.topjobSlotData,
     'selected-border': positionService?.selectedPositionId?.value == position.id && position.topjobSlotData
     }">
<div  class="flex column gap-sm">

    <div *ngIf="position?.topjobSlotData" class="topjob-image-container">
        <img class="topjob-image"  [src]="topjobImage" alt="" *ngIf="isInView">
        <div class="dark-gradient"></div>
        <div class="flex row-wrap-gap-sm start-center position-info-topjob">

            <div class="flex row-gap-sm start-center">
                <company-picture class="company-image" [apiPositionLogoUrl]="position.companyLogoUrl" [positionId]="position.id" *ngIf="isInView"></company-picture>
                <div class="company-name" [innerHtml]="position.company.name | safeHtml"></div>
            </div>

            <div class="divider-vertical"></div>
            <div class="location-marker-loading-dummy" *ngIf="!isInView"></div>
            <locations-marker
                    *ngIf="isInView"
                    [preventClickability]="true"
                    [positionId]="position.id"
                    [lightMode]=" true"
                    [textSize]="12"
                    [markerSize]="0"
                    [locationDescription]="position?.locationDescription"
                    [personalizesString]="position?.locationShortDescription"></locations-marker>
        </div>
    </div>

    <div class="flex row space-between-center gap">
        <h1 class="position-title">{{position.title}}</h1>
        <app-positions-favourite-button [origin]="origin" [type]="'Icon'" [positionRelation]="position.relationData"></app-positions-favourite-button>
    </div>

    <div class="flex row-wrap-gap-sm start-center position-info" *ngIf="!position?.topjobSlotData">

        <div class="flex row-gap-sm start-center">
            <company-picture class="company-image" [apiPositionLogoUrl]="position.companyLogoUrl" [positionId]="position.id" *ngIf="isInView"></company-picture>
            <div class="company-name" [innerHtml]="position.company.name | safeHtml"></div>
        </div>

        <div class="divider-vertical"></div>
        <div class="location-marker-loading-dummy" *ngIf="!isInView"></div>
        <locations-marker
                *ngIf="isInView"
                [preventClickability]="true"
                [positionId]="position.id"
                [textSize]="12"
                [markerSize]="0"
                [locationDescription]="position?.locationDescription"
                [personalizesString]="position?.locationShortDescription"></locations-marker>
    </div>

    <app-positions-chips [topjob]="!!position.topjobSlotData" [positionId]="position.id"></app-positions-chips>
</div>
</div>
