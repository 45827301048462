import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {
  CreateConsultantAppointmentRequestData,
  SupportData, TalentAccountResource, TalentCoachData, TalentCoachResource,
  TalentConsultantAppointmentRequestResource,
  TalentConsultantAvailabilitySlotData, TalentConsultantSlotData,
  TalentSupportResource,
  UtilityService
} from "utility";
import {FormControl, Validators} from "@angular/forms";
import {PropagationService} from "../../services/propagation.service";

@Component({
  selector: 'app-consultant-appointment-dialog',
  templateUrl: './consultant-appointment-dialog.component.html',
  styleUrls: ['./consultant-appointment-dialog.component.scss']
})
export class ConsultantAppointmentDialogComponent implements OnInit {

  slotData: TalentConsultantAvailabilitySlotData
  allSlots: TalentConsultantSlotData[] = []
  firstSlots: TalentConsultantSlotData[] = []
  slots: TalentConsultantSlotData[] = []
  loadingSlots: boolean = false
  requesting: boolean = false
  moreSlots: boolean = false
  minDate: Date
  talentCoachData: TalentCoachData

  slotControl = new FormControl<TalentConsultantSlotData>(null, Validators.required)
  ownSlotControl = new FormControl<Date>(null, Validators.required)

  constructor(
      private dialogRef: MatDialogRef<ConsultantAppointmentDialogComponent>,
      private utilityService: UtilityService,
      @Inject(MAT_DIALOG_DATA) public data: boolean,
      private talentAccountResource: TalentAccountResource,
      private talentConsultantAppointmentRequestResource: TalentConsultantAppointmentRequestResource,
      private talentCoachResource: TalentCoachResource,
      private propagationService: PropagationService
  ) {
    let now = new Date()
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()+2, 8, 0, 0, 0)
  }

  ngOnInit(): void {
    this.loadTalentAgent().then(() => {
      this.reportDialogSeen()
      this.getSlots()
    } )

    this.ownSlotControl.valueChanges.subscribe(next => {
      if (next) this.slotControl.setValue(null)
    })
  }

  loadTalentAgent(): Promise<any> {
    return this.talentCoachResource.getTalentCoach().then(result => {
      this.talentCoachData = result
    }).then(() => {null})
    }

  selectSlot(slotData: TalentConsultantSlotData) {
    this.ownSlotControl.setValue(null)
    this.slotControl.setValue(slotData)
  }

  showMoreSlots() {
    this.slots = this.allSlots
    this.moreSlots = true
  }

  requestAppointment() {
    this.requesting = true
    let data: CreateConsultantAppointmentRequestData = {
      start: this.slotControl.value? new Date(this.slotControl.value.start) : new Date(this.ownSlotControl.value),
      slotId: this.slotControl.value? this.slotControl.value.slotId : null,
      consultantId: this.talentCoachData.talentAgent.id
    }

    this.talentConsultantAppointmentRequestResource.createConsultantAppointmentRequest(data).then(result => {
      this.utilityService.showSnackBar("Gesprächstermin angefragt!")
      this.propagationService.coachAppointmentRequested.next()
      this.dialogRef.close(true)
    })
  }

  getSlots() {
    this.loadingSlots = true
    this.talentConsultantAppointmentRequestResource.getSlots({consultantId: this.talentCoachData.talentAgent.id}).then(result => {
      this.slotData = result
      this.allSlots = this.slotData.freeSlots
      this.firstSlots = this.allSlots.filter( (i, index) => index<6)
      this.slots = this.firstSlots
      this.loadingSlots = false
    })
  }

  weekendFilter = (d: Date): boolean => {
    const day = d.getDay();
    return day != 0 && day != 6 ;
  }

  openPicker(picker) {
    picker.open()
    this.ownSlotControl.setValue(this.minDate)
  }

  reportDialogSeen() {
    this.talentAccountResource.reportConsultantAppointmentDialogSeen({coachingId: this.talentCoachData.coachingId})
  }
}
