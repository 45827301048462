<loading-spinner [loadingVar]="loadingPreviews" [color]="'var(--primary)'">
    <div fxLayout="row">
        <div class="chat-previews width-100" fxLayout="column">

            <button mat-icon-button (click)="hideOverlay.emit()">
                <i class="mdi mdi-close"></i>
            </button>

            <div fxLayout="column"
                 *ngFor="let chat of chatsPreview"
                 (click)="loadChatContent(chat.id); chat.existsUnseen = false">
                <app-chat-minimal [chatMinimalData]="chat"
                                  [talentId]="talentId"></app-chat-minimal>
                <div>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div class="no-messages" *ngIf="chatsPreview?.length == 0">
                Du hast leider noch keine Nachrichten...
            </div>
        </div>

        <div class="chat-container mat-elevation-z10" *ngIf="chatData || loadingChat" #chatContainer>
            <loading-spinner [loadingVar]="loadingChat" [color]="'var(--primary)'">
            <app-chat *ngIf="!loadingChat"
                      [chatData]="chatData"
                      [talentId]="talentId"
                      [fullName]="talentFullName"
                      [firstName]="talentFirstName"
                        (setChatDataToNull)="hideChat()"></app-chat>
            </loading-spinner>
        </div>

    </div>



</loading-spinner>

