
<div fxLayout="column" style="width: 100%">
    <app-co-step-headline>In welcher Stadt wohnst du?</app-co-step-headline>

    <div>
        <location-input-search
                [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                style="width: 100%"
                label="Name oder PLZ"
                [control]="talentCityCtrl"
        ></location-input-search>

        <small style="color: red; font-size: 12px" *ngIf="talentCityCtrl.errors && talentCityCtrl.touched">
            Bitte wähle deinen Wohnort aus, um fortzufahren.
        </small>
    </div>

    <app-co-step-headline>Wann schließt du dein Studium ab? 😊🎓  <br> <span>(Eine Schätzung reicht vollkommen aus)</span></app-co-step-headline>

    <div  fxLayout="column" fxLayoutAlign="center center">

        <div class="step-content-ob" style="margin-top: 20px">
            <study-end-date-selection [control]="studyEndDateCtl"></study-end-date-selection>
        </div>
        <div *ngIf="this.error.value"
             class="hid-validationError duplicate-mail-error"
             style="margin-top: 10px; padding: 5px 20px; text-align: center">
            Bitte gib dein ungefähres Studienende an
        </div>
    </div>


    <german-skill-level [germanSkillLevelCtrl]="germanSkillLevelCtr" [nationalityCtrl]="nationalityCtr">
        <app-co-step-headline nationality>Welche Staatsbürgerschaft hast du?</app-co-step-headline>
        <app-co-step-headline language>Wie sicher bist du in deinen Deutschkenntnissen?</app-co-step-headline>
    </german-skill-level>
</div>

<app-co-button-bar (pressedNext)="progress()"></app-co-button-bar>
