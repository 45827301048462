import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {BenefitData} from "utility";

@Component({
  selector: 'app-positions-details-benefits',
  templateUrl: './positions-details-benefits.component.html',
  styleUrls: ['./positions-details-benefits.component.scss']
})
export class PositionsDetailsBenefitsComponent implements OnInit {

  @Input() benefits: BenefitData[]

  extended: boolean = false

  @ViewChild('grid') grid: ElementRef = new ElementRef<any>(null)
  @ViewChild('overlay') overlay: ElementRef = new ElementRef<any>(null)

  constructor(
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
  }

  setExtended() {
    this.extended = !this.extended
  }

}
