import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
    selector: '[app-file-drag-drop]'
})
export class FileDragDropDirective {
    @Output() fileDropped = new EventEmitter<FileList>();

    @HostBinding('class.fileover') fileOver: boolean;

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer?.files;
        if (files && files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
