import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import {ImageCroppedEvent} from "ngx-image-cropper/lib/interfaces";


export interface EditImageDialogData {
  title: string;
  aspectRatio: number,
  roundCropping: true; //Optional,
  file: any,
  changeEvent: any
}

@Component({
  selector: 'app-edit-image-dialog',
  templateUrl: './edit-image-dialog.component.html',
  styleUrls: ['./edit-image-dialog.component.css']
})
export class EditImageDialogComponent implements OnInit, AfterViewInit {



  whiteListedExtensions = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

  saving: false;
  image: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageFileName = '';
  imageFileType = '';
  imageFileSize = '';
  imageBase64 = '';
  finishing = false;
  readonly file = new FormControl<FileList>(null, this.validateImage());


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditImageDialogData, public dialogRef: MatDialogRef<EditImageDialogComponent>) {
  }

  ngAfterViewInit(): void {
        // this.fileInput?.nativeElement?.click()
    }

  ngOnInit() {

    /**
     * IE workaround (IE only uses msToBlob())
     */
    if (!HTMLCanvasElement.prototype.toBlob && HTMLCanvasElement.prototype['msToBlob']) {
      HTMLCanvasElement.prototype.toBlob = function (callback: BlobCallback, type?: string, quality?: any) {
        let blob = this.msToBlob();
        callback(blob);
      };
    }

    this.file.setValue(this.data.file)
    this.file.updateValueAndValidity()
    this.fileChangeEvent(this.data.changeEvent)
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
    if (!this.file?.value) return
    if (this.file?.value[0] && this.file?.valid) {
      this.imageFileName = this.file.value[0].name;
      this.imageFileType = this.file.value[0].type;
      this.imageFileSize = `${this.file.value[0].size}`;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    const imageBlob = this.dataURItoBlob(event.base64.replace("data:image/png;base64,", ""));

    /**
     * IE workaround (IE does not have File constructor)
     * If IE doesn`t matter anymore use
     * 'new File([imageBlob], this.imageFileName, {type: 'image/jpeg'});'instead
     */
    imageBlob['lastModifiedDate'] = new Date();
    imageBlob['name'] = this.imageFileName;
    this.image = <File>imageBlob;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }


  finish() {
    if (this.image) {
      this.convertFileToArrayBuffer(this.image);
    } else {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  convertFileToArrayBuffer(file) {
    let myReader: FileReader = new FileReader();

    myReader.onloadend = () => {
      this.dialogRef.close({file: myReader.result, fileName: file.name});
    };

    myReader.readAsArrayBuffer(file);
  }

  validateImage(): ValidatorFn {
    //3145728 Bytes = 3MB
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {isNull: true};
      }

      if (control.value[0]?.size >= 10000000) {
        return {sizeTooBig: true};
      }

      if (this.whiteListedExtensions.indexOf(control.value[0]?.type) == -1) {
        return {wrongExtension: true};
      }

      return null;
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/jpeg'});
  }

}
