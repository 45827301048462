import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtilsService {

  constructor() { }
}

export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

