import {Component, Input, OnInit} from '@angular/core';
import {FileService} from "../../services/file.service";

@Component({
  selector: 'coach-image',
  templateUrl: './coach-image.component.html',
  styleUrls: ['./coach-image.component.scss']
})
export class CoachImageComponent implements OnInit {

  @Input() questionSize: number = 75

  public talentAgentImageUrl: any = './../../assets/images/blurred_user.png';
  public talentAgentImageUrlPlaceHolder = './../../assets/images/blurred_user.png';

  constructor(
      private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.loadTalentAgentImageUrl()
  }

  loadTalentAgentImageUrl() {
    this.fileService.getTalentAgentProfilePicture().subscribe((result) => {
      if (result.size > 0) {
        this.talentAgentImageUrl = URL.createObjectURL(result);
      } else {
        this.talentAgentImageUrl = this.talentAgentImageUrlPlaceHolder;
      }
    });
  }

}
