import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TalentApplicationData, TalentApplicationResource, UtilityService} from "utility";
import {FileService} from "../../../services/file.service";
import {FormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {KeyValuePipe} from "@angular/common";

@Component({
  selector: 'app-upload-additional-documents-for-application-dialog',
  templateUrl: './upload-additional-documents-for-application-dialog.component.html',
  styleUrls: ['./upload-additional-documents-for-application-dialog.component.scss']
})
export class UploadAdditionalDocumentsForApplicationDialogComponent implements OnInit, OnDestroy {

  uploading: boolean = false
  loadingRequirements: boolean = true

  readonly attachementsGroup = new UntypedFormGroup({
    file0: new FormControl<File>(null, this.fileService.uploadFileControlValidator())
  })
  controlCount: number = 0

  sub: Subscription

  requirements: string[] = []

  errors: any

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: TalentApplicationData,
      private dialogRef: MatDialogRef<UploadAdditionalDocumentsForApplicationDialogComponent>,
      private fileService: FileService,
      private talentApplicationResource: TalentApplicationResource,
      private keyValuePipe: KeyValuePipe,
      private utilityService: UtilityService
  ) { }

  ngOnDestroy(): void {
        this.sub.unsubscribe()
    }

  ngOnInit(): void {
    this.initForm()
    this.getDocumentRequirements()
  }

  getDocumentRequirements() {
    this.loadingRequirements = true
    this.talentApplicationResource.getAdditionalFileRequirements(this.data.id).then(result => {
      this.requirements = result
      this.loadingRequirements = false
    })
  }

  initForm() {
    this.sub = this.attachementsGroup.valueChanges.subscribe(next => {
      let createNew: boolean = true
      let control = this.attachementsGroup.controls[this.keyValuePipe.transform(this.attachementsGroup.controls)[this.keyValuePipe.transform(this.attachementsGroup.controls).length-1].key]

      if (control.value == null || control.invalid) createNew = false
      if (control.invalid) {
        this.errors = control.errors
        control.setValue(null)
      }

      if (control.value && control.valid) this.errors = null
      if (createNew) this.addControlToGroup()

    })
  }

  isUploadButtonValid(): boolean {
    let keyValues = this.keyValuePipe.transform(this.attachementsGroup.controls)
    return (this.attachementsGroup.valid && this.attachementsGroup.controls[keyValues[0].key].value != null)
  }

  addControlToGroup() {
    this.controlCount += 1
      this.attachementsGroup.addControl(`file${this.controlCount}`, new FormControl<File>(null, this.fileService.uploadFileControlValidator()))
  }

  removeControlFromGroup(controlName: string) {
    this.attachementsGroup.removeControl(controlName)
  }

  upload() {
    this.uploading = true
    this.fileService.addAttachmentsToApplication(this.data.id, this.attachementsGroup).then(result => {
      this.uploading = false
      this.utilityService.showSnackBar("Dateien wurden erfolgreich hochgeladen! Wir informieren das Unternehmen über den Upload.")
      this.close(true)
    })
  }

  close(result: boolean = false) {
    this.dialogRef.close(result)
  }

}
