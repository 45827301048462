import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
    CityData, PublicEmploymentPositionResource,
    TalentEmploymentPositionResource,
} from "utility";
import {PositionLocationInfoDialogComponent} from "../../dialogs/position-location-info-dialog/position-location-info-dialog.component";

@Component({
    selector: 'locations-marker',
    templateUrl: './locations-marker.component.html',
    styleUrls: ['./locations-marker.component.scss']
})
export class LocationsMarkerComponent implements OnInit {

    isModeSpecificCities: boolean = false
   loadingPersonalizesString: boolean = true
    locations: CityData[] = []

    @Input() personalizesString: string | null = null
    @Input() locationDescription: string | null = null
    @Input() positionId: number
    @Input() markerSize: number = 16
    @Input() textSize: number =14
    @Input() lightMode: boolean = false
    @Input() preventClickability: boolean = false

    constructor(
        private dialog: MatDialog,
        private talentEmploymentPositionResource: TalentEmploymentPositionResource,
        private employmentPositionResource: PublicEmploymentPositionResource
    ) {
    }

    ngOnInit() {
        this.isModeSpecificCities = !['Deutschlandweit', 'Weltweit'].includes(this.locationDescription)
        this.getPersonalizedLocationString()
        this.getLocationsForPosition()
    }

    getPersonalizedLocationString() {
        this.loadingPersonalizesString = true
        this.employmentPositionResource.getPersonalizedLocationString(this.positionId).then(result => {
            if (result?.length > 0) {
                this.personalizesString = result
            }
            this.loadingPersonalizesString = false
            }
        )
    }

    getLocationsForPosition() {
        this.employmentPositionResource.getLocationsForPositionById(this.positionId).then(result => {
            this.locations = result
        })
    }

    getToolTipText():string {
        if (this.locations?.length === 1) return ""
        return this.locations.map(l => l.name).join(', ');
    }

}
