import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountResource, TalentOnboardingResource, UniSignAccountResource, UtilsService} from "utility";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";

@Component({
  selector: 'app-cpd-o-password',
  templateUrl: './cpd-o-password.component.html',
  styleUrls: ['./cpd-o-password.component.scss']
})
export class CpdOPasswordComponent implements OnInit {

    form: FormGroup

    constructor(
        private utilsService: UtilsService,
        private talentOnboardingResource: TalentOnboardingResource,
        private authService: TalentAuthService,
        public router: Router,
        private uniSignAccountResource: UniSignAccountResource,
        private accountResource: AccountResource,
        private obService: ConfirmProfileDetailsOnboardingService
    ) {
    }

    ngOnInit() {
        if(this.obService.skippedCvUpload){
            this.talentOnboardingResource.reportTalentSkipedCvUploadOnboarding().then(() => {
            })
        }
        this.form = new FormGroup({
            password: new FormControl<string>(null, [Validators.required, this.utilsService.requirementsPasswordMatched(2, 6)])
        });
    }

    ngOnDestroy() {
    }


    forceValidation = new Subject;
    progressing = new Subject()
    progress() {
        this.forceValidation.next()
        if (!this.form.valid) return

        this.progressing.next()

        let password = this.form.controls.password.value
        //we dont need to wait for the result, we can just move on.
        this.uniSignAccountResource.changeOwnPassword({newPassword: password})
        this.accountResource.finalizeOnboarding().then(() => {
                this.authService.updateOnboardingCompleted(true).then(() => {
                    this.obService.routeToPlattform()
                })
            }
        )
    }

    containsSmallLetter(): boolean {
        if (this.form.controls.password.value == null) return false;
        return (/[a-z]/.test(this.form.controls.password.value));
    }

    containsBigLetterOrNumber() {
        if (this.form.controls.password.value == null) return false;
        return (/[A-Z0-9]/.test(this.form.controls.password.value));
    }

}
