<!--                        desktop-->
<div [formGroup]="coachingsForm" class="mlp-colors" style="margin-bottom: 80px" *ngIf="mediaService.mobileQuery.matches">
    <div #coachingEl
         [ngStyle]="{'background-image': 'url(' + getBackgroundImageUrl(coaching.title) + ')'}"
         class="coaching-offer-card fadeIn" fxLayout="column" fxLayoutAlign="start center"
         fxLayoutGap="30px"
         *ngFor="let coaching of availableCoachingTypes">
        <div><img [src]="getIconUrl(coaching.title)" height="50px"></div>
        <div class="headline">{{coaching.title}}</div>
        <div class="text">{{coaching.description}}</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="coachRequested">
        <div class="checkbox">
            <input type="checkbox" [id]="coaching.id" [name]="coaching.id"
                   [formControlName]="coaching.title">
            <span class="checkmark"></span>
        </div>
            <div>Leistung gewünscht</div>
        </div>
    </div>
</div>


<!--                        mobile-->
<div [formGroup]="coachingsForm" class="mlp-colors" *ngIf="!mediaService.mobileQuery.matches"
     fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div class="width-50">
        <div *ngFor="let coaching of availableCoachingTypes">
            <div [ngStyle]="{'background-image': 'url(' + getBackgroundImageUrl(selectedCoaching.title) + ')'}"
                 *ngIf="coaching.id == selectedCoaching.id"
                 class="coaching-offer-card m-left10 fadeIn" fxLayout="column" fxLayoutAlign="start center"
                 fxLayoutGap="30px">
                <div><img [src]="getIconUrl(selectedCoaching.title)" height="50px"></div>
                <div class="headline">{{selectedCoaching.title}}</div>
                <div class="text">{{selectedCoaching.description}}</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="coachRequested">
                    <div class="checkbox">
                        <input type="checkbox" [id]="selectedCoaching.id" [name]="selectedCoaching.id"
                               [formControlName]="selectedCoaching.title">
                        <span class="checkmark"></span>
                    </div>
                    <div>Leistung gewünscht</div>
                </div>

            </div>
        </div>
    </div>


    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px" class="width-50">
        <div *ngFor="let coaching of availableCoachingTypes" [ngClass]="{'selected-coaching': selectedCoaching.id == coaching.id}" (click)="selectedCoaching=coaching" class="available-coaching"
             fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="15px">
            <div class="checkmark-small" *ngIf="coachingsForm.controls[coaching.title].value && coachRequested"></div>
            <img [src]="getIconUrl(coaching.title)" height="50px">
            <div class="headline text-center">{{coaching.title}}</div>
        </div>
    </div>

</div>
