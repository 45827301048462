<div style="padding-bottom: 15px" class="width-100 text-center"><b>Zehn Bewerbungen</b> brauchst du übrigens im Schnitt für deinen ersten Job!</div>
<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px" class="applied-animation" [ngClass]="{'scale': scaleText()}">
    <div fxLayout="row" fxLayoutAlign="center center" class="width-100">
        <div class="process-bar">
            <div [ngStyle]="{'width.%': getProcessbarWidth()}" class="process-bar-filled" fxLayout="row" fxLayoutAlign="end center">
                <div class="user-position" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div class="user-pic" *ngIf="noProfilePicBool == false" [ngStyle]="{'background-image': 'url(' + profilePicture + ')'}"></div>
                    <div class="user-pic" *ngIf="noProfilePicBool == true" [ngStyle]="{'background-image': 'url(' + './../../assets/images/blurred_user.png' + ')'}"></div>
                    <div class="triangle-down"></div>
                </div>
            </div>
        </div>
        <img src="../../../assets/images/success_icon.png" height="30" style="margin-left:15px">
    </div>
    <div class="applied-information" fxLayout="row" fxLayoutAlign="center center">
        {{applicationCount}}/10 Bewerbungen verschickt<span *ngIf="applicationCount > 0">- Weiter so ;)</span>
    </div>
</div>
