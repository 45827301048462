import {Component, OnDestroy, OnInit} from '@angular/core';
import {TalentHomeResource, TalentPositionDetailsData} from "utility";
import {PositionService} from "../../../services/position.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {HomeService} from "../../home-utils/home.service";
import {Router} from "@angular/router";

@Component({
  selector: 'bookmarks-home-desktop',
  templateUrl: './bookmarks-home-desktop.component.html',
  styleUrls: ['./bookmarks-home-desktop.component.scss']
})
export class BookmarksHomeDesktopComponent implements OnInit, OnDestroy {

  loadingSubject = new BehaviorSubject<boolean>(true)
  bookmarks: TalentPositionDetailsData[] = []
  allBookmarks: TalentPositionDetailsData[] = []

  relationProceeded: Subscription

  constructor(
      private talentHomeResource: TalentHomeResource,
      private homeService: HomeService,
      private positionService: PositionService,
      private router: Router
  ) {
  }

  ngOnDestroy(): void {
        this.relationProceeded.unsubscribe()
    }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getApplications()
    this.subscribeBookmarkOrRejectRelation()
  }

  subscribeBookmarkOrRejectRelation() {
    this.relationProceeded = this.positionService.relationProceeded.subscribe(proceeded => {
      if (proceeded.action == "BookmarkRemoved" || proceeded.action == "Rejected" || proceeded.action == 'Applied') {
        let index = this.allBookmarks.findIndex(r => r.employmentPosition.id == proceeded.relation.positionId)

        this.allBookmarks.splice(index, 1)

      }
    })
  }

  getApplications(): Promise<any> {
    this.loadingSubject.next(true)
    return this.talentHomeResource.getBookmarksForDashboard().then(bookmarks => {
      this.allBookmarks = bookmarks
      this.loadingSubject.next(false)
    })
  }

  routeToPosition(b: TalentPositionDetailsData) {
    this.router.navigate(['/talent/positions'], { queryParams: { positionIds: b.employmentPosition.id } });
  }

  openApplicationDialog(b: TalentPositionDetailsData) {
    this.positionService.applyForPosition(b, 'Bookmarks').then(result => {
    })
  }


}
