import {Component, OnInit} from '@angular/core';
import {TalentApplicationMinimalData, TalentApplicationResource, TalentTalentProfileResource} from "utility";
import {AbstractControl, FormControl, ValidatorFn} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FileService} from "../../../services/file.service";
import {CoachingOnboardingService} from "../coaching-onboarding.service";

@Component({
    selector: 'app-co-ob-state',
    templateUrl: './co-ob-state.component.html',
    styleUrls: ['./co-ob-state.component.scss']
})
export class CoObStateComponent implements OnInit {

    //states wheather the user comes here from his first OB
    hasCv: boolean = true;

    application: TalentApplicationMinimalData;

    loading = false;

    fileName: string;
    fileEvent: Event;
    fileControl = new FormControl<FileList>(null, this.formControlValidator());

    saving: boolean = false;
    fileIsWordDocument: boolean = false;

    constructor(private route: ActivatedRoute,
                private profileResource: TalentTalentProfileResource,
                private applicationResource: TalentApplicationResource,
                private onboardingService: CoachingOnboardingService,
                private fileService: FileService,
    ) {
    }

    ngOnInit(): void {
        this.load();

        this.fileControl.valueChanges.subscribe(() => {
            this.saveCv()
        })
    }

    load() {
        this.loading = true;
        setTimeout(() => {
            this.profileResource.getCVInfo().then(res => {
                this.hasCv = res.cvAvailable
                this.loading = false
            })
        }, 1000)

    }


    progress(context: string) {
        this.onboardingService.delayedRouting("/onboarding/coach/subject", context)
    }

    routeToOnboarding() {
        this.onboardingService.progress = 50
        this.progress('next')
    }


    /*CV STUFF */

    formControlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value?.length) return {isNull: true};

            let file = control.value[0];

            if (file.size >= 10000000) return {sizeTooBig: true};
            if (file.size == 0) return {fileIsEmpty: true};

            if (!['.pdf'].some((extension, index, number) => {
                return file.name.includes(extension)
            })) {
                return {wrongExtension: true};
            }

            return null;
        }
    }

    saveCv() {
        if (this.fileControl.invalid) return
        this.onboardingService.reportCurrentStepEvent("CvUpload")
        this.saving = true;
        let file = this.fileControl.value[0];
        this.fileName = file.name;

        this.fileService.uploadCV(file).subscribe(() => {
            this.saving = false;
            this.hasCv = true;
        })
    }

    set(fileEvent: Event) {
        let file = this.fileControl.value[0];
        if (!file) {
            this.fileIsWordDocument = false
            return
        }
        this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
        this.fileEvent = fileEvent;
    }

    getFileName() {

        if (this.fileControl.value?.length) {

            let formData: FormData = new FormData();

            let file = this.fileControl.value[0];
            formData.append("file", file);
            formData.append("fileName", file.name);
            this.fileName = file.name;

            return this.fileName;
        }

        return "Lebenslauf hochladen";
    }

}
