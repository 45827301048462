<dialog-header [headline]="data.dialogTitle? data.dialogTitle : 'Dein Anhang'"></dialog-header>

<mat-dialog-content class="dialog-max-height">
    <div class="pdf-preview-wrapper" >
        <div *ngIf="isFileIdValid; else fileNotAvailable">
            <ngx-extended-pdf-viewer
                    *ngIf="file && this.data?.type == 'pdf'"
                    [src]="file"
                    [height]="'100%'"
                    [useBrowserLocale]="true">
            </ngx-extended-pdf-viewer>

            <img style="max-width: 100%; max-height: 100%" *ngIf="imageData"  [src]="imageData" src="data:image/png;base64,'.base64_encode($blob).'"/>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="ita_upload_cv_welcome_cancel" (click)="close()">Schließen</button>
</mat-dialog-actions>

<ng-template #fileNotAvailable>
    <div class="broken-file" >
        Der Anhang ist entweder kaputt oder nicht mehr vorhanden!
    </div>
</ng-template>
