import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {Level, Origin} from "utility";

@Component({
  selector: 'german-skill-level',
  templateUrl: './german-skill-level.component.html',
  styleUrls: ['./german-skill-level.component.scss']
})
export class GermanSkillLevelComponent implements OnInit, OnDestroy {

  skillLevel = [
    {'value': 'E', 'display': 'Anfänger (A1)'},
    {'value': 'D', 'display': 'Grundkenntnisse (A2)'},
    {'value': 'C', 'display': 'Fortgeschritten (B1)'},
    {'value': 'B', 'display': 'Fließend (B2)'},
    {'value': 'A', 'display': 'Verhandlungssicher (C1)'},
    {'value': 'O', 'display': 'Muttersprache (C2)'}
  ];

  nationalitySub: Subscription

  @Input() germanSkillLevelCtrl: FormControl<Level>
  @Input() nationalityCtrl: FormControl<Origin>
  constructor() { }

  ngOnInit(): void {
    this.nationalitySub = this.nationalityCtrl.valueChanges.subscribe(next => {
      if (next != "German") {
        this.germanSkillLevelCtrl.setValidators([Validators.required])
      } else {
        this.germanSkillLevelCtrl.clearValidators()
      }
      this.germanSkillLevelCtrl.updateValueAndValidity()
    })
  }

  selectNationality(nationality: Origin) {
    this.nationalityCtrl.setValue(nationality)
  }

  ngOnDestroy(): void {
  }

}
