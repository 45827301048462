<div class="max-w1064" >

    <mat-tab-group [@.disabled]="true" color="accent" class="custom-group"  [(selectedIndex)]="tabIndex">
        <mat-tab label="Email ändern ">

            <div class="tab-content ta-round-drop"  fxLayout="column" fxLayoutAlign="start center">


                <hid-input-field [showValidatorIcons]="false"
                                 [model]="emailAddress"
                                 (modelChange)="emailAddress = $event;"
                                 label="Email-Adresse" type="text"
                                 style="max-width: 350px; width: 100%"></hid-input-field>

                <hid-input-field [showValidatorIcons]="false"
                                 [model]="validationEmailAddress"
                                 (modelChange)="validationEmailAddress = $event;"
                                 label="Wiederholung Email-Adresse" type="text"
                                 style="max-width: 350px; width: 100%"></hid-input-field>

                <div class="valid-password">{{emailAddressChange.error}}</div>

                <div fxLayout="row" fxLayoutAlign="center center" class="button-bar" style="margin-bottom: 5px;">
                    <button mat-flat-button color="accent"
                            (click)="changeEmail()"
                            [loadingBool]="loadingBool">Speichern
                    </button>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Passwort ändern">

            <div class="tab-content ta-round-drop" fxLayout="column" fxLayoutAlign="start center">

                <hid-input-field [model]="credentials.oldPassword"
                                 (modelChange)="credentials.oldPassword =$event;"
                                 label="Altes Passwort"
                                 type="password"
                                 style="width: 80%;"
                ></hid-input-field>

                <hid-input-field [model]="credentials.newPassword"
                                 (modelChange)="credentials.newPassword =$event;"
                                 label="Neues Passwort"
                                 style="width: 80%;"
                                 type="password"></hid-input-field>


                <hid-input-field [model]="credentials.repeatedPassword"
                                 (modelChange)="credentials.repeatedPassword =$event;"
                                 label="Neues Passwort wiederholen"
                                 style="width: 80%;"
                                 type="password"></hid-input-field>

                <div class="valid-password">{{passwordChange.error}}</div>

                <div fxLayout="row" fxLayoutAlign="center center" class="button-bar" style="margin-bottom: 5px;">

                    <button mat-flat-button color="accent"
                            (click)="changePassword()"
                            [loadingBool]="passwordChange.loading">Speichern
                    </button>
                </div>
            </div>

        </mat-tab>

        <!-- Currently the talent should not be able to alter the notification configuration -->
        <mat-tab label="Benachrichtigungen">

            <div class="tab-content notification-settings ta-round-drop"  fxLayout="column" fxLayoutAlign="start center">

                <h2>Benachrichtigungseinstellungen</h2>

                <span style="color: gray; font-size: 12px"> Wir informieren dich nur über Neugigkeiten, die für dich relevant sind. Hier kannst du einstellen, auf welche Art du kontaktiert werden möchtest. </span>

                <div class="section-headline">E-Mails</div>
                    <div fxLayout="column" fxLayoutAlign="start start" *ngIf="talentConfigurationData" class="section-content">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deinen Bewerbungen</div>
                                <div class="config-description">Erhalte eine Nachricht, wenn Unternehmen deine Bewerbung abarbeiten und bspw. als Interessant markieren oder noch Dokumente von dir brauchen
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.applicationEmail"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deiner Merkliste</div>
                                <div class="config-description">Wir erinnern dich unter anderem daran, dass du noch Stellen auf deiner Merkliste hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.bookmarkEmail"></mat-checkbox>
                        </div>

<!--                        CHAT NOTIFICATIONS VIA EMAIL ARE CURRENTLY NOT USED -->

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu Chats</div>
                                <div class="config-description">Wir senden dir Nachrichten wenn du offene Nachrichten hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.chatEmail"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deinem Coaching</div>
                                <div class="config-description">Du bekommst von uns bspw. Nachrichten, wenn das Ergebnis deines Persönlichkeitstest / deiner Potentialanalyse verfügbar ist oder dein Coach dir Stellen empfiehlt
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.coachingEmail"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für Stellenvorschläge von uns</div>
                                <div class="config-description">Wir benachrichtigen dich, wenn du neue Stellenvorschläge erhalten hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.recommendationEmail"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für deine Suche</div>
                                <div class="config-description">Du bekommst von uns eine Nachricht, wenn neue Stellen für deine aktuelle Suche verfügbar sind.
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.searchEmail"></mat-checkbox>
                        </div>


                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für Sonstige Ereignisse</div>
                                <div class="config-description">Wir schreiben dir bspw. wenn dein Studienende näher kommt und es Zeit wird, Bewerbungen zu schreiben
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.otherEmail"></mat-checkbox>
                        </div>
                </div>

                <div class="section-headline">SMS</div>
                    <div fxLayout="column" fxLayoutAlign="start start" *ngIf="talentConfigurationData"  class="section-content">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deinen Bewerbungen</div>
                                <div class="config-description">Erhalte eine Nachricht, wenn Unternehmen deine Bewerbung abarbeiten und bspw. als Interessant markieren oder noch Dokumente von dir brauchen
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.applicationSms"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deiner Merkliste</div>
                                <div class="config-description">Wir erinnern dich unter anderem daran, dass du noch Stellen auf deiner Merkliste hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.bookmarkSms"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu Chats</div>
                                <div class="config-description">Wir senden dir Nachrichten wenn du offene Nachrichten hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.chatSms"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">zu deinem Coaching</div>
                                <div class="config-description">Du bekommst von uns bspw. Nachrichten, wenn das Ergebnis deines Persönlichkeitstest / deiner Potentialanalyse verfügbar ist oder dein Coach dir Stellen empfiehlt
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.coachingSms"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für Stellenvorschläge von uns</div>
                                <div class="config-description">Wir benachrichtigen dich, wenn du neue Stellenvorschläge erhalten hast
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.recommendationSms"></mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für deine Suche</div>
                                <div class="config-description">Du bekommst von uns eine Nachricht, wenn neue Stellen für deine aktuelle Suche verfügbar sind.
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.searchSms"></mat-checkbox>
                        </div>


                        <div fxLayout="row" fxLayoutAlign="space-between center" class="config-setting width-100"  fxLayoutGap="10px" fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="config-headline">für Sonstige Ereignisse</div>
                                <div class="config-description">Wir schreiben dir bspw. wenn dein Studienende näher kommt und es Zeit wird, Bewerbungen zu schreiben
                                </div>
                            </div>

                            <mat-checkbox color="accent" [(ngModel)]="talentConfigurationData.otherSms"></mat-checkbox>
                        </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" class="button-bar" style="margin-bottom: 5px;">
                    <button mat-flat-button color="accent"
                            (click)="changeNotification()"
                            [loadingBool]="notificationChange.loading">Speichern
                    </button>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="Account">

            <div class="tab-content ta-round-drop"  fxLayout="column" fxLayoutAlign="start center">

                <h4>Account Deaktivieren</h4>

                <span style="color: gray; font-size: 12px"> Hier hast du die Möglichkeit, deinen Account zu deaktivieren, falls du Talentagent nicht mehr nutzen möchtest.</span>

                <div style="margin-bottom: 15px !important;">
                    <button mat-flat-button color="warn" (click)="deleteAccount()" style="padding-inline: 2rem">
                        Account deaktivieren
                    </button>
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>
</div>
