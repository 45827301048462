import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {
    CityData,
    CVInfoData, delay,
    EditImageDialogComponent,
    JsonWrappedValue,
    PositionPrivacyDisclaimerData,
    PositionQuestionData,
    ProfileAttachmentData,
    ProfileImageService,
    PublicEmploymentPositionDetailsData, TalentAccountInfoData,
    TalentApplicationResource,
    TalentEmploymentPositionResource,
    TalentPositionDetailsData,
    TalentPositionInteractionOrigin,
    TalentPositionQuestionAnswerData,
    TalentPositionRelationResource,
    TalentProfileAttachmentResource,
    TalentTalentProfileResource,
    UtilityService, WorkwiseApplicantInfoData
} from 'utility';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {FileService} from '../../services/file.service';
import {MediaService} from '../../services/inno-utils/media.service';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {MatStepper} from "@angular/material/stepper";
import {DialogOpenService} from "../../services/dialog-open.service";
import {ShareService} from "../../services/share.service";
import {PositionService} from "../../services/position.service";
import {BottomSheetService} from "../../utility/slideable-bottom-sheets/bottom-sheet.service";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {SearchService} from "../../services/search.service";
import {TalentAuthService} from "../../services/talent.auth.service";


@Component({
    selector: 'app-talent-create-application-dialog',
    templateUrl: './talent-create-application-dialog.component.html',
    styleUrls: ['./talent-create-application-dialog.component.scss']
})
export class TalentCreateApplicationDialogComponent implements OnInit, OnDestroy {

    @ViewChild('stepper') stepper: MatStepper
    public privacyUrl = environment.publicPageUrl + '/privacy'
    applicationSend: boolean = false

    fileName: string;
    fileEvent;
    controlCount: number = 0

    forceValidationWorkwiseAccountData$: Subject<boolean> = new Subject()

    attachments = new FormGroup({})

    fileIsWordDocument: boolean = false;
    cvFileControl = new UntypedFormControl(null, this.fileService.uploadFileControlValidator());

    positionPrivacyDisclaimer: PositionPrivacyDisclaimerData;
    acceptPrivacyControl = new FormControl<boolean>(false);

    image: SafeStyle;

    position: PublicEmploymentPositionDetailsData;

    mobileQuery: MediaQueryList;

    positionTrackingClass: string = 'ita_positionId-';

    companyMessage = new FormControl<string>('', [Validators.maxLength(500)]);

    cvInfo: CVInfoData

    worksamples: ProfileAttachmentData[]

    attachmentsToBig: boolean = false
    loadingWorksamples: boolean = true
    loadingCv: boolean = true

    formGenerated: boolean = false
    positionAnswerForm = new FormGroup({})

    noProfilePic: boolean = true;
    profilePicture;
    containsToolQuestions: boolean = false


    loadingAccept: boolean;

    positionQuestions: PositionQuestionData[] = []
    subscriptionList: Subscription[] = []

    file: Blob
    pdfUrl
    showMissingQuestionsError: boolean = false

    constructor(
        public dialogRef: MatDialogRef<TalentCreateApplicationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            talentPositionRelation: TalentPositionDetailsData,
            companyName: string,
            positionId: number,
            positionTitle: string,
            cvAvailable: boolean,
            positionLocations: CityData[],
            companyLogo: string | null | undefined,
            origin: TalentPositionInteractionOrigin
        },
        public mediaService: MediaService,
        private fileService: FileService,
        private talentEmploymentPositionResource: TalentEmploymentPositionResource,
        private positionResource: TalentEmploymentPositionResource,
        private talentProfileResource: TalentTalentProfileResource,
        private talentApplicationResource: TalentApplicationResource,
        private utilityService: UtilityService,
        private http: HttpClient,
        private workSampleResource: TalentProfileAttachmentResource,
        private profileImageService: ProfileImageService,
        private changeDetectorRef: ChangeDetectorRef,
        public dialogOpenService: DialogOpenService,
        private sanitizer: DomSanitizer,
        private positionService: PositionService,
        private bottomSheetService: BottomSheetService,
        private searchService: SearchService,
        private authService: TalentAuthService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptionList.forEach(s=>s.unsubscribe())
    }

    scrollPosition: number = 0

    ngOnInit() {

        this.handleScrollPosition()

        this.getPositionQuestions()
        this.initForm()
        this.getPositionInformation();

        this.getCvInformation()

        this.loadProfilePic()
        this.loadWorksamples()


        this.talentEmploymentPositionResource.getLatestPrivacyDisclaimerInformation(this.data.positionId).then(result => {
            if (result && result.showDisclaimer) {
                this.positionPrivacyDisclaimer = result;
            } else {
                this.acceptPrivacyControl.setValue(true);
            }
        });

        this.positionTrackingClass = 'ita_positionId-' + this.data.positionId.toString();
    }

    handleScrollPosition(){
        let dialogContent = document.getElementById('dialog-content')


        //This is not working on IOS
        dialogContent.addEventListener('scroll', () =>{
            this.scrollPosition = document.getElementById('dialog-content').scrollTop
        })
    }


    getPositionQuestions() {
        this.talentApplicationResource.getActivePositionQuestions(this.data.positionId).then(result => {
            this.positionQuestions = result
            if (this.positionQuestions.filter(q => q.referenceKey?.includes("tool.")).length) this.containsToolQuestions = true
            this.positionQuestions.forEach(question => {
                this.addControlForPositionAnswer(question)
            })

            this.initHandleQuestionConditions()
            this.formGenerated = true
            this.changeDetectorRef.detectChanges()
        })
    }

    initHandleQuestionConditions() {
        this.positionQuestions.filter(q => q.conditionData != undefined).forEach(q => {
            let relevantQuestion = this.positionQuestions[this.positionQuestions.findIndex(rq => rq.referenceKey == q.conditionData.questionReferenceKey)]
            let sub = this.positionAnswerForm.get(relevantQuestion.id.toString()).valueChanges.subscribe(() => {
                this.updateControlRequiredValidator(q, relevantQuestion)
            })
            this.subscriptionList.push(sub)
            this.updateControlRequiredValidator(q, relevantQuestion)
        })
    }

    updateControlRequiredValidator(conditionalQuestion: PositionQuestionData, relevantQuestion: PositionQuestionData) {
        let conditionControl = this.positionAnswerForm.get(conditionalQuestion.id.toString())
        if (this.positionAnswerForm.get(relevantQuestion.id.toString()).value == conditionalQuestion.conditionData.requiredValue) {
            conditionControl.addValidators([Validators.required])
        } else {
            conditionControl.removeValidators([Validators.required])
        }
        conditionControl.updateValueAndValidity()
    }

    addControlForPositionAnswer(question: PositionQuestionData) {
      this.positionAnswerForm.addControl(
          question.id.toString(),
          new FormControl<any>(
              this.getValueForQuestionControl(question), question.required? [Validators.required] : null
          )
      )
    }

    getValueForQuestionControl(question: PositionQuestionData): any {
        let accountInfo = this.authService.getAccountInfo()
        let value = null
        switch (question.referenceKey) {
            case "general.nationality": if(accountInfo.origin == "German" || accountInfo.origin == "Unknown") value = "DE"
                break;
            case "general.has_work_permit_for_european_union": if(accountInfo.origin != "NotEu" ) value = "yes"
                break;
            case "general.available_since": value = new Date()
                break;
            case "general.available_until": value = null
                break;
            case "general.hours_start": value = this.searchService.searchFilterData.workingHourFrom >= 15? this.searchService.searchFilterData.workingHourFrom.toString() : "30"
                break;
            case "general.hours_end": value = this.searchService.searchFilterData.workingHourTo >= 15? this.searchService.searchFilterData.workingHourTo.toString() : null
                break;
            case "general.is_salary_flexible": value = "yes"
                break;
            case "general.salary_required": value = "40000"
                break;
            case "language.de": value = accountInfo.germanLanguageLevel?? "100"
                break;
            default: value = null
        }

        return value
    }

    initForm() {
        this.addAttachment()

        this.attachments.valueChanges.subscribe(() => {
            let sum = 0
            Object.keys(this.attachments.controls).forEach(key => {
                if (this.attachments.get(key)?.value != null) {
                    sum += this.attachments.get(key)?.value[0]?.size
                }
            })

            this.attachmentsToBig = sum >= 50000000
        })
    }

    uploadCv(): Promise<any> {
        let formData: FormData = new FormData();

        if (this.cvFileControl.value) {
            formData.append('file', this.cvFileControl.value[0]);
            formData.append('fileName', this.cvFileControl.value[0].name);
        } else return null

        return this.http.post(environment.apiUrl + '/talentProfile/CurriculumVitae/file', formData, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        }).toPromise();
    }


    cvAlreadyAvailable = false;
    getCvInformation() {
        this.loadingCv = true
        this.talentProfileResource.getCVInfo().then(result => {
            this.cvAlreadyAvailable = result.cvAvailable
            this.cvInfo = result
            this.loadingCv = false
            this.getFile()
        })
    }

    loadProfilePic() {
        this.profileImageService.getOwnProfilePic().subscribe(b64 => {
            if (b64.size == null || b64.size == 0) this.noProfilePic = true;

            else {
                this.noProfilePic = false;
                let blob = new Blob([b64], {type: 'image/png'})
                this.profilePicture = URL.createObjectURL(blob)
            }
        })
    }

    addAttachment() {
        if (this.controlCount > 0 && !this.attachments.controls[`control${this.controlCount}`].value) return
        this.controlCount += 1
        this.attachments.addControl(`control${this.controlCount}`, new UntypedFormControl(null))
    }

    removeAttachment(key: string) {
        this.attachments.removeControl(key)
    }

    getPositionInformation() {
        this.positionResource.getSingleEmploymentPositionById(this.data.positionId).then(
            result => {
                this.position = result;
            }
        );
    }

    onAccept() {
        this.loadingAccept = true;
        if (!this.positionAnswerForm.valid ) {
            this.loadingAccept = false
            this.positionAnswerForm.markAllAsTouched()
            this.showMissingQuestionsError = true
            this.forceValidationWorkwiseAccountData$.next(true)
            delay(6000).then(() => this.showMissingQuestionsError = false)
            return
        }
        debugger
        if(this.cvFileControl.value != null) this.uploadCv().then(() => this.createApplication())
        else this.createApplication();
    }

    createPositionAnswerData(): TalentPositionQuestionAnswerData[] {
        let data: TalentPositionQuestionAnswerData[] = []
        Object.keys(this.positionAnswerForm.controls).forEach(key => {
            if(this.positionAnswerForm.get(key).hasValidator(Validators.required)) {
                data.push({
                    answer: this.positionAnswerForm.controls[key].value,
                    questionId: parseInt(key)})
            }
            })

        return data.filter(it => it.answer != null)
    }



    addAnswersToApplication(applicationId):Promise<any> {
        let data = this.createPositionAnswerData()
        if (!data.length) return new Promise<any>(resolve => {resolve(false)})
        return this.talentApplicationResource.createPositionAnswersForApplication(
            data, applicationId
        )
    }

    finishApplication(applicationId: number) {
        this.addAnswersToApplication(applicationId).then(() => {

            this.applicationSend = true

                if (this.data.cvAvailable) {
                    this.utilityService.showSnackBar('Bewerbung abgeschickt');
                } else {
                    this.utilityService.showSnackBar('Bewerbung gespeichert');
                }

                if (this.mediaService.getMaxWidthQuery(960).matches) {
                    history.back()
                } else {
                    this.onClose(this.applicationSend)
                    this.loadingAccept = false
                }

                this.bottomSheetService.closeCurrentBottomSheet()
            }
        )
    }


    createApplication() {
        let formData: FormData = new FormData();

        formData.append('origin', this.data.origin)

        if (this.companyMessage.value != null) {
            formData.append('applicationMessage', this.companyMessage.value)
        }

        Object.keys(this.attachments.controls).forEach((controlName) => {
            if (this.attachments.controls[controlName].value) {
                formData.append('attachments', this.attachments.controls[controlName].value[0], this.attachments.controls[controlName].value[0].name);
            }
        })

        if (this.cvFileControl.value) {
            formData.append('cvFile', this.cvFileControl.value[0], this.cvFileControl.value[0].name);
        }

        this.http.post(`${environment.apiUrl}/talent/positions/${this.data.positionId}/application/platform`, formData, {
            headers: new HttpHeaders(),
            responseType: "json"
        }).toPromise().then(
            res => {
                this.positionService.relationProceeded.next({relation: this.data.talentPositionRelation.relationData, action: "Applied"})
                this.finishApplication((res as JsonWrappedValue<number>).value)
            }
        )
    }



    onClose(result: boolean = false) {
        this.dialogRef.close(result);
    }

    isValidCvFile() {
        if (this.cvFileControl.value) {
            return this.cvFileControl.valid && !this.fileIsWordDocument;
        } else return true
    }

    set(fileEvent: Event) {
        if (!this.cvFileControl.value[0]) {
            this.cvFileControl.setValue(null)
            this.fileIsWordDocument = false
        } else {
            let file = this.cvFileControl.value[0];
            this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
        }
        this.fileEvent = fileEvent;
        const reader = new FileReader()

        let file = (fileEvent.target as HTMLInputElement).files[0];

        reader.readAsDataURL(file);

        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)+"#toolbar=0")
        this.showPdf = true
        this.loadingPdf = false
        this.cvAlreadyAvailable = true
    }

    getFileName(): string {

        if (this.cvFileControl.value != null) {

            let formData: FormData = new FormData();

            let file = this.cvFileControl.value[0];
            formData.append("file", file);
            formData.append("fileName", file.name);
            this.fileName = file.name;

            return this.fileName;
        }

        return this.cvInfo?.originalFileName;
    }

    loadWorksamples() {
        this.loadingWorksamples = true
        this.workSampleResource.getOwnProfileAttachments().then(result => {
            this.worksamples = result;
            this.loadingWorksamples = false
        });
    }

    loadingPdf: boolean = false
    showPdf= false

    getFile() {
        if (!this.cvInfo?.id) return

        this.showPdf = false
        this.loadingPdf = true
        this.http.get(`${environment.apiUrl}/talentProfile/storedFiles/${this.cvInfo.id}` , {
            headers: new HttpHeaders(),
            responseType: 'blob',
            observe: 'response'
        }).subscribe((response: HttpResponse<Blob>) => {
            this.loadingPdf = false
            const newBlob = new Blob([response.body], {type: "application/pdf"});
            this.pdfUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(newBlob)+"#toolbar=0")
            this.showPdf = true
        });
    }

    questionsCompleted():boolean{
            return this.positionAnswerForm?.valid
    }

    getLengthOfAttachments(): number{
        return Object.keys(this.attachments.controls).length
    }

    getFormattedLocations(): string {
        if (!this.position?.locations || this.position?.locations.length === 0) {
            return '';
        }

        const names = this.position?.locations.map(location => location.name);

        if (names.length === 1) {
            return `Die Stelle befindet sich am Standort ${names[0]} und erfordert Präsenz vor Ort, da sie nicht vollständig remote ausgeübt werden kann.`;
        }

        const formattedLocations = `${names.slice(0, -1).join(', ')} und ${names.slice(-1)}`;
        return `Die Stelle befindet sich an den Standorten ${formattedLocations} und erfordert Präsenz vor Ort, da sie nicht vollständig remote ausgeübt werden kann.`;
    }

    protected readonly Validators = Validators;
}
