import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
    TalentAccountResource,
    TalentPositionRelationResource,
    TalentTalentProfileResource
} from "utility";
import {TalentAuthService} from "./talent.auth.service";
import {Router} from "@angular/router";
import {CvIncentiveDialogComponent} from "../dialogs/cv-incentive-dialog/cv-incentive-dialog.component";
import {
    ConsultantAppointmentDialogComponent
} from "../dialogs/consultant-appointment-dialog/consultant-appointment-dialog.component";
import {
    PrimeRecommendationRedirectDialogComponent
} from "../dialogs/prime-recommendation-redirect-dialog/prime-recommendation-redirect-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class PopUpService {

    idleTimer: number = 0
    totalTimer: number = 0

    initialFeedOpen: boolean = true


    constructor(
        private dialog: MatDialog,
        private authService: TalentAuthService,
        private router: Router,
        private talentAccountResource: TalentAccountResource,
        private talentTalentProfileResource: TalentTalentProfileResource,
        private talentPositionRelationResource: TalentPositionRelationResource
    ) {
    }

    initPopupHandling() {

        this.openUnseenRecommendationPopUpIfNecessary()
        this.openConsultantAppointmentPopUpIfNecessary()

        //idle Time Check
        let idleIntervalId = setInterval(() => {
            this.totalTimer = this.totalTimer + 10
            this.idleTimer = this.idleTimer + 10

            if (this.totalTimer % 60 == 0) this.openCvIncentivePopupIfNecessary()

        }, 10000)

        window.addEventListener("click", () => {
            this.idleTimer = 0
        })
        window.addEventListener("mousemove", () => {
            this.idleTimer = 0
        })
    }

    isOtherDialogOpen(): boolean {
        return this.dialog.openDialogs.length > 0
    }

    hasOrganicLogin(): boolean {
        return this.authService.getAccountInfo().lastLoginOrigin == "OrganicLogin"
    }
    canPopUpOpenedGenerally(): boolean {
        return this.authService.isAuthenticated() &&
            !this.router.url.includes('position-details/') &&
            !this.router.url.includes('/welcome') &&
            this.hasOrganicLogin() &&
            !this.router.url.includes('/onboarding/') &&
            !this.router.url.includes('/onboarding2/') &&
            !this.isOtherDialogOpen()
    }

    openCvIncentivePopupIfNecessary() {
        if (!this.canPopUpOpenedGenerally()) return
        this.talentTalentProfileResource.shouldTalentOpenCvIncentivePopup().then(result => {
            if (result) this.openCvIncentivePopup()
        })
    }

    openCvIncentivePopup() {
        this.talentTalentProfileResource.createTalentOpenedCvUploadIntentPopUpLogEntry()

        this.dialog.open(CvIncentiveDialogComponent, {
            maxWidth: '450px',
            autoFocus: false,
            panelClass: ['round-border-dialog', 'cv-incentive-popup']
        })
    }

    openConsultantAppointmentDialog(withCoach: boolean): Promise<any> {

        return new Promise<any>(resolve => {
            if (!this.canPopUpOpenedGenerally()) resolve(false)
            const dialogRef = this.dialog.open(ConsultantAppointmentDialogComponent, {
                data: withCoach,
                width: '500px',
                maxWidth: '100vw',
                panelClass: ['full-width-mobile-dialog', 'round-border-dialog', 'full-height-mobile-dialog']
            })

            dialogRef.afterClosed().subscribe(result => {
                resolve(result)
            })
        })

    }

    openUnseenRecommendationPopUpIfNecessary() {
        if (!this.canPopUpOpenedGenerally()) return
        this.talentPositionRelationResource.existsUnseenRecommendation().then(result => {
            if (!result.value) return

            this.dialog.open(PrimeRecommendationRedirectDialogComponent, {
                panelClass: ['prime-recommendation-redirect-dialog', 'round-border-dialog'],
                autoFocus: false
            })
        })
    }

    openConsultantAppointmentPopUpIfNecessary() {
        if (!this.canPopUpOpenedGenerally()) return
        this.talentAccountResource.shouldOpenConsultantAppointmentDialog().then(result => {
            if (result) this.openConsultantAppointmentDialog(true)
        })
    }
}


