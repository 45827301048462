import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HidDropdownComponent} from './hid-dropdown/hid-dropdown.component';
import {HidInputFieldComponent} from './hid-input-field/hid-input-field.component';
import {HidLoadingSpinnerComponent} from './hid-loading-spinner/hid-loading-spinner.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HidDateFlowInputComponent} from './hid-date-flow-input/hid-date-flow-input.component';
import {HidTextareaComponent} from './hid-textarea/hid-textarea.component';
import {HidAsyncDropdownComponent} from './hid-async-dropdown/hid-async-dropdown.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ClickOutsideModule} from 'ng-click-outside';
import {HidCheckboxComponent} from './hid-checkbox/hid-checkbox.component';
import {HidSnackbarComponent} from './hid-snackbar/hid-snackbar.component';
import {HidSnackbarService} from './hid-snackbar/hid-snackbar.service';
import {InnoUtilsModule} from '../../services/inno-utils/inno-utils.module';
import {HidProgressSpinnerComponent} from './hid-progress-spinner/hid-progress-spinner.component';
import {HidAutocompleteInputFieldComponent} from './hid-autocomplete-input-field/hid-autocomplete-input-field.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import { HidInputPhoneNumberComponent } from './hid-input-phone-number/hid-input-phone-number.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";

@NgModule({
  declarations: [
    HidDropdownComponent,
    HidInputFieldComponent,
    HidLoadingSpinnerComponent,
    HidAsyncDropdownComponent,
    HidDateFlowInputComponent,
    HidTextareaComponent,
    HidCheckboxComponent,
    HidSnackbarComponent,
    HidProgressSpinnerComponent,
    HidAutocompleteInputFieldComponent,
    HidInputPhoneNumberComponent


  ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        ClickOutsideModule,
        InnoUtilsModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatButtonModule
    ],
  providers: [
    HidSnackbarService
  ],
    exports: [
        HidDropdownComponent,
        HidInputFieldComponent,
        HidAsyncDropdownComponent,
        HidLoadingSpinnerComponent,
        HidDateFlowInputComponent,
        HidTextareaComponent,
        HidCheckboxComponent,
        HidProgressSpinnerComponent,
        HidAutocompleteInputFieldComponent,
        HidInputPhoneNumberComponent
    ]
})
export class HidLibCloneModule { }
