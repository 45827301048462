<div *ngIf="talentCoachData?.talentAgent != null"
     fxLayout="column" fxLayoutAlign="start start" class="information" fxLayoutGap="10px">
    <div class="contact" fxLayout="row" fxLayoutAlign="start center">
        <i class="mdi mdi-whatsapp icon"></i>
        <span *ngIf="talentCoachData.talentAgent.whatsApp != null">{{talentCoachData.talentAgent.whatsApp}}</span>
        <span *ngIf="talentCoachData.talentAgent.whatsApp == null || talentCoachData.talentAgent.whatsApp == ''">
            {{talentCoachData.talentAgent.phoneNumber}}
          </span>
    </div>

    <div class="contact" fxLayout="row" fxLayoutAlign="start center">
        <i class="mdi mdi-email icon"></i>
        {{talentCoachData.talentAgent.emailAddress}}
    </div>

    <div class="contact" fxLayout="row" fxLayoutAlign="start center">
        <i class="mdi mdi-phone icon"></i>
        {{talentCoachData.talentAgent.phoneNumber}}
    </div>
</div>
