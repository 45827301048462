import {ChangeDetectorRef, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CachedOBInputs, ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {delay, EmailAddressAvailabilityValidatorService, TalentOnboardingResource} from "utility";
import {UnisignService} from "../../../services/unisign.service";
import {FileService} from "../../../services/file.service";
import {BehaviorSubject, Subject} from "rxjs";
import {MediaService} from "../../../services/inno-utils/media.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-cpd-o-confirm-profile-details',
  templateUrl: './cpd-o-confirm-profile-details.component.html',
  styleUrls: ['./cpd-o-confirm-profile-details.component.scss']
})
export class CpdOConfirmProfileDetailsComponent {

    emailValidationPending = new BehaviorSubject<boolean>(false);
    progressPending = false;
    checkError: Subject<any> = new Subject<any>()
    progressingSubject = new Subject();

    public privacyUrl = environment.publicPageUrl + '/privacy'
    public useRegulationsUrl = environment.publicPageUrl + '/use-regulations'

    nextStepClicked: boolean = false
    submitFailed: boolean = false

    readonly form = new FormGroup({
        firstName: new FormControl<string>(this.obService.cachedInputs.firstName, [Validators.required, this.obService.noWhitespaceValidator]),
        lastName: new FormControl<string>(this.obService.cachedInputs.lastName, [Validators.required, this.obService.noWhitespaceValidator]),
        acceptPrivacy: new FormControl<boolean>(this.obService.acceptedPrivacy, [Validators.requiredTrue]),
        contactEmailAddress: new FormControl<string>(this.obService.cachedInputs.email, [Validators.required, Validators.email],
            [this.emailAvailabilityValidatorService.createValidator(this.emailValidationPending)]),
        phoneNumber: new FormControl<string>(this.obService.cachedInputs.phoneNumber, [Validators.required]),
        cityIdCtrl: new FormControl<number>(this.obService.cachedInputs.talentCityId, [Validators.required]),
        subjectId: new FormControl<number>(this.obService.cachedInputs.subjectId, [Validators.required])
    });

    constructor(
        public obService: ConfirmProfileDetailsOnboardingService,
        private onboardingResource: TalentOnboardingResource,
        public analyticsService: AnalyticsService,
        private emailAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
        private unisignService: UnisignService,
        private fileService: FileService,
        public mediaService: MediaService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    progress() {
        this.checkError.next();
        this.form.updateValueAndValidity()
        this.form.markAllAsTouched()

        if (!this.form.valid) {
            this.progressPending = false;
            this.submitFailed = true
            return
        }

        this.progressingSubject.next()

        this.obService.updateInputs(<CachedOBInputs>{
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            phoneNumber: this.form.controls.phoneNumber.value,
            email: this.form.controls.contactEmailAddress.value,
            talentCityId: this.form.controls.cityIdCtrl.value,
            subjectId: this.form.controls.subjectId.value
        })

        this.obService.createOrUpdateTalentAccount().then(
            () => {
                if (this.obService.getCv()) this.fileService.uploadCV(this.obService.getCv())

                this.getAvailablePositionsForSubject().then(res => {
                    if (res > 16) {
                        this.showPositionsFoundAnimation = true
                        delay(100).then(() => {
                            this.animatePositionCount(res)
                            delay(3200).then(() => {
                                this.obService.delayedRouting( "/onboarding/cpd/preferences")
                            })
                        })
                    } else {
                        this.obService.delayedRouting( "/onboarding/cpd/preferences")
                    }
                })
            }
        )
    }

    forgotPassword() {
        this.unisignService.routeToForgotPassword()
    }


    //ANIMATION STUFF//
    showPositionsFoundAnimation = false;

    availablePositions;

    getAvailablePositionsForSubject(): Promise<number> {
        return new Promise(resolve => {
            if (!this.obService.cachedInputs?.subjectId) {
                resolve(null)
                return null
            }

            this.onboardingResource.getAvailablePositionsCountForSubject(
                {subjectId: this.obService.cachedInputs?.subjectId}
            ).then(result => {
                this.availablePositions = result.matchingPositionsCount
                resolve(this.availablePositions)
            })
        })
    }

    availablePositionContent: string[] = []


    animatePositionCount(availablePositionCount: number) {
        let wrapper = document.getElementById('available-position-count')
        wrapper.innerHTML = ''
        let textElement = document.getElementById('available-position-text')

        textElement.setAttribute("class", "")
        this.availablePositionContent = availablePositionCount.toString().split('')
        for (let i = 0; i < this.availablePositionContent.length; i++) {
            let element = document.createElement('div', {});
            element.classList.add('count-number');
            let text = document.createTextNode(this.availablePositionContent[i]);
            element.appendChild(text);
            wrapper.appendChild(element)
        }
        this.changeDetectorRef.detectChanges()

        setTimeout(() => {
            textElement.classList.add('animate-position-text-fade-in')
            setTimeout(() => {
                textElement.classList.add('animate-position-text-fade-out')
            }, (3500 - (this.availablePositionContent.length * 200) - 400))
        }, (this.availablePositionContent.length * 200))
    }
}
