import {Component, Input, OnInit} from '@angular/core';
import {
    MatchingState,
    TalentAccountInfoData,
    TalentAccountResource,
    TalentMatchingStateResource,
    TalentNotificationConfigurationData,
    TalentNotificationConfigurationResource,
    UniSignAccountResource,
    UniSignPublicResource,
    UtilityService,
    UtilsService
} from "utility";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {
    TalentAccountDeactivationDialogComponent
} from "../dialogs/talent-account-deactivation-dialog/talent-account-deactivation-dialog.component";
import {MediaService} from "../services/inno-utils/media.service";
import {TalentAuthService} from "../services/talent.auth.service";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-talentaccount',
    templateUrl: './talent-account.component.html',
    styleUrls: ['./talent-account.component.scss']
})
export class TalentAccountComponent implements OnInit {

    @Input() onboardingComplete;

    talentConfigurationData: TalentNotificationConfigurationData = <TalentNotificationConfigurationData>{};
    loadingBool = false;

    emailAddressChange = {
        error: null,
        loading: false
    };

    passwordChange = {
        error: null,
        loading: false
    };

    notificationChange = {
        loading: false
    };

    credentials = {
        oldPassword: "",
        newPassword: "",
        repeatedPassword: ""
    };

    accountInfo: TalentAccountInfoData;
    emailAddress = "";
    backUpMailAddress = "";
    validationEmailAddress = "";
    tabIndex: number;
    consideredForMatching: MatchingState;

    constructor(
        private accountResource: TalentAccountResource,
        private utilsService: UtilsService,
        private utilityService: UtilityService,
        public talentAuthService: TalentAuthService,
        private dialog: MatDialog,
        public mediaService: MediaService,
        private notificationConfigurationResource: TalentNotificationConfigurationResource,
        private route: ActivatedRoute,
        private matchingStateResource: TalentMatchingStateResource,
        private uniSignAccountResource: UniSignAccountResource,
        private uniSignPublicResource: UniSignPublicResource,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.tabIndex = this.route.snapshot.queryParamMap.get('tab')? Number(this.route.snapshot.queryParamMap.get('tab')): 0;

        this.accountInfo = this.talentAuthService.getAccountInfo()
        if (this.accountInfo == null) {
            this.talentAuthService.reloadAccountInfo().then(() => {
                this.accountInfo = this.talentAuthService.getAccountInfo()
            })
        }

        this.backUpMailAddress = this.accountInfo.emailAddress;
        this.emailAddress = this.accountInfo.emailAddress;
        this.consideredForMatching = this.accountInfo.matchingState;
        this.validationEmailAddress = this.emailAddress;
        this.notificationConfigurationResource.getUserNotificationConfiguration().then(
            result => this.talentConfigurationData = result
        );
    }

    changeEmail() {
        if (this.emailAddress == null || this.emailAddress == "" || this.validationEmailAddress == null || this.validationEmailAddress == "") {
            this.emailAddressChange.error = "Bitte fülle alle Felder aus.";
            this.loadingBool = false
            return;
        }

        if (this.emailAddress != this.validationEmailAddress) {
            this.emailAddressChange.error = "Die E-mail-Adressen stimmen nicht überein.";
            this.loadingBool = false
            return;
        }
        this.uniSignPublicResource.getEmailAvailableForRegistration({email: this.emailAddress}).then(result => {
            if(result.available) {
                this.accountResource.changeEmail({newEmail: this.emailAddress}).then(
                    (result) => {
                            this.emailAddressChange.error = "";
                            this.utilityService.showSnackBar("Deine E-Mail-Adresse wurde erfolgreich geändert. Logge dich neu ein!");
                        this.talentAuthService.logout()
                        })
            } else {
                this.emailAddressChange.error = "Die E-mail-Adresse ist schon vergeben";
                this.loadingBool = false
            }
        })
    }

    changePassword() {
        if (this.credentials.oldPassword.length == 0 || this.credentials.newPassword.length == 0 || this.credentials.repeatedPassword.length == 0) {
            this.passwordChange.error = "Alle Felder müssen augefüllt sein.";
            return;
        }

        if (this.credentials.newPassword != this.credentials.repeatedPassword) {
            this.passwordChange.error = "Passwörter stimmen nicht überein.";
            return;
        }

        let passwordMatched = new FormControl(this.credentials.newPassword, this.utilsService.requirementsPasswordMatched(2, 6));

        if (passwordMatched.errors !== null) {
            if ("passwordTooShort" in passwordMatched.errors) {
                this.passwordChange.error = "Das Passwort muss mindestens 6 Zeichen lang sein.";
                return;
            }

            if ("securityRequirementsNotMatched" in passwordMatched.errors) {
                this.passwordChange.error = "Das Passwort ist zu unsicher. Bitte verwenden Sie mindestens einen Kleinbuchstaben sowie einen Großbuchstaben oder Zahl";
                return;
            }
        }

        this.passwordChange.loading = true;
        this.uniSignAccountResource.changeOwnPassword({
            currentPassword: this.credentials.oldPassword,
            newPassword: this.credentials.newPassword
        }).then(
            (success) => {
                this.passwordChange.loading = false;

                if (success) {
                    this.passwordChange.error = "";
                    this.utilityService.showSnackBar("Passwort wurde erfolgreich geändert.");
                } else {
                    this.passwordChange.error = "Das alte Passwort ist nicht richtig.";
                }
            }
        )
    }


    deleteAccount() {
        this.dialog.open(TalentAccountDeactivationDialogComponent, {
            panelClass: ['round-border-dialog', 'account-deactivation-dialog']
        }).afterClosed().subscribe(result => {
            if (result == "notification") {
                this.tabIndex = 2
            }
        })
    }

    changeNotification() {
        this.notificationChange.loading = true;
        this.talentConfigurationData.settingChangeOrigin = "AccountSettings"
        this.notificationConfigurationResource.updateUserNotificationConfiguration(this.talentConfigurationData).then(() => {
            this.notificationChange.loading = false;
            this.utilityService.showSnackBar("Erfolgreich gespeichert")
        })
    }
}
