<div id="wrapper" #wrapper>
  <div class="search-input-wrapper" >
    <input [formControl]="control" style="width: 0; height: 0; opacity: 0; position: absolute">
    <div id="output-field-wrapper" (click)="toggleDropdown()"
         [ngClass]="{'filled': (multiple && control.value.length > 0) || (!multiple && (control.value || nullable)), 'disabled':disabled, 'invalid': !control.valid && wasOpened,
         'validationForced': validationForced}">
      <div id="label">{{label}}</div>
      <div id="output-field">
        <span *ngIf="!multiple">{{getDisplayProperty(getOptionByIdentifier(control.value))}}</span>
        <span *ngIf="multiple">{{getDisplayProperty(getOptionByIdentifier(control.value[0]))}}
          <span *ngIf="control.value.length > 1"> + {{control.value.length - 1}}</span>
        </span>

        <i *ngIf="showValidatorIcons" class="validator-icon fas fa-minus-circle text-red"
           [ngClass]="{'show': !control.valid}"></i>
        <i *ngIf="showValidatorIcons" class="validator-icon far fa-check-circle text-green"
           [ngClass]="{'show': control.valid}"></i>
      </div>

    </div>

      <div style="width: 100%; position: absolute; top: 37px; opacity: 0;" [matMenuTriggerFor]="menuHid" #menuTrigger="matMenuTrigger"></div>
</div>
</div>

<mat-menu #menuHid="matMenu" yPosition="below" class="location-mat-menu">
  <div [ngStyle]="{'width': wrapper.clientWidth + 'px'}" class="option" [ngClass]="{'selected': isSelected(option)}" fxLayout="row" mat-menu-item
       *ngFor="let option of filteredOptions" (click)="setModel(option, $event)">
    {{getDisplayProperty(option)}}
  </div>
</mat-menu>
