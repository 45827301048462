import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {BottomSheetService} from "../../../utility/slideable-bottom-sheets/bottom-sheet.service";
import {FilterLocationFormComponent} from "./filter-location-form/filter-location-form.component";
import {FilterProfessionFormComponent} from "./filter-profession-form/filter-profession-form.component";
import {FilterOtherFormComponent} from "./filter-other-form/filter-other-form.component";
import {SearchService} from "../../../services/search.service";
import {PositionService} from "../../../services/position.service";

@Component({
  selector: 'app-positions-searchbar-filter',
  templateUrl: './positions-searchbar-filter.html',
  styleUrls: ['./positions-searchbar-filter.scss']
})
export class PositionsSearchbarFilter implements OnInit {

  @Input() type: 'Location' | 'Profession' | 'Other' = 'Location'
  formComponent: any = null

  opened: boolean = false

  constructor(
      public mediaService: MediaService,
      private bottomSheetService: BottomSheetService,
      public searchService: SearchService,
      private positionService: PositionService
  ) { }

  ngOnInit(): void {
  }

  toggleFilterOptions() {
    if (!this.mediaService.isMobile()) {
      this.opened = !this.opened
    } else {
      switch (this.type) {
        case "Location":
          this.formComponent = FilterLocationFormComponent
          break
        case "Profession":
          this.formComponent = FilterProfessionFormComponent
          break
        case "Other":
          this.formComponent = FilterOtherFormComponent
          break
      }
      this.bottomSheetService.openBottomSheet(this.formComponent, {expansionEnabled: false})
    }
  }

  onClickOutside() {
    this.opened = false
  }
}
