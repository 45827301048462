import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GDPRConsent, LocalStorageService} from "../../../services/local-storage.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {MediaService} from "../../../services/inno-utils/media.service";

@Component({
  selector: 'app-gdprconsent',
  templateUrl: './gdprconsent.component.html',
  styleUrls: ['./gdprconsent.component.scss']
})
export class GDPRConsentComponent implements OnInit {


  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  consentState: GDPRConsent;

  @Input() detailedView = false;

  initialConsent = false;

  readonly form = new FormGroup({
    consent_ga: new FormControl(true),
    consent_linkedIn: new FormControl(true),
    consent_facebook: new FormControl(true),
    consent_pinterest: new FormControl(true),
    consent_google_ads: new FormControl(true),
    consent_tiktok: new FormControl(true),
    consent_google_oc: new FormControl(true),
    consent_piwik: new FormControl(true),
    consent_facebook_oc: new FormControl(true),
    consent_ip_api: new FormControl(true),
    consent_hotjar: new FormControl(true)
  });

  constructor(
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    private router: Router,
    public mediaService: MediaService,
  ) {
  }


  ngOnInit() {

    let consentState = this.localStorageService.getGDPRConsent();
    this.initialConsent = consentState.initial;

    this.form.patchValue({
        consent_ga: consentState.consent_ga,
        consent_linkedIn: consentState.consent_linkedIn,
        consent_facebook: consentState.consent_facebook,
        consent_pinterest: consentState.consent_pinterest,
        consent_google_ads: consentState.consent_google_ads,
        consent_google_oc: consentState.consent_google_oc,
        consent_facebook_oc: consentState.consent_facebook_oc,
        consent_piwik: consentState.consent_piwik,
        consent_tiktok: consentState.consent_tiktok,
        consent_ip_api: consentState.consent_ip_api,
        consent_hotjar: consentState.consent_hotjar
      }
    )
  }

  selectAll() {
    this.form.patchValue({
        consent_ga: true,
        consent_linkedIn: true,
        consent_facebook: true,
        consent_pinterest: true,
        consent_google_ads: true,
        consent_google_oc: true,
        consent_facebook_oc: true,
        consent_tiktok: true,
        consent_piwik: true,
        consent_ip_api: true,
        consent_hotjar: true
      }
    )
  }

  submitAll() {
    this.selectAll();
    this.submit();
  }

  submit() {
    this.localStorageService.updateGDPRConsent({
        initial: false,
        consent_ga: this.form.controls.consent_ga.value,
        consent_linkedIn: this.form.controls.consent_linkedIn.value,
        consent_facebook: this.form.controls.consent_facebook.value,
        consent_pinterest: this.form.controls.consent_pinterest.value,
        consent_google_ads: this.form.controls.consent_google_ads.value,
        consent_tiktok: this.form.controls.consent_tiktok.value,
        consent_google_oc: this.form.controls.consent_google_oc.value,
        consent_facebook_oc: this.form.controls.consent_facebook_oc.value,
        consent_piwik: this.form.controls.consent_piwik.value,
        consent_ip_api: this.form.controls.consent_ip_api.value,
        consent_hotjar: this.form.controls.consent_hotjar.value
    });

    /** Set Cookies for Talentagent Domain which will enable all Subdomains to access the GDPR Settings */
    document.cookie = `initial=${this.localStorageService.getGDPRConsent().initial};domain=talentagent.de`
    document.cookie = `consent_ga=${this.form.controls.consent_ga.value};domain=talentagent.de`
    document.cookie = `consent_linkedIn=${this.form.controls.consent_linkedIn.value};domain=talentagent.de`
    document.cookie = `consent_facebook=${this.form.controls.consent_facebook.value};domain=talentagent.de`
    document.cookie = `consent_pinterest=${this.form.controls.consent_pinterest.value};domain=talentagent.de`
    document.cookie = `consent_google_ads=${this.form.controls.consent_google_ads.value};domain=talentagent.de`
    document.cookie = `consent_tiktok=${this.form.controls.consent_tiktok.value};domain=talentagent.de`
    document.cookie = `consent_google_oc=${this.form.controls.consent_google_oc.value};domain=talentagent.de`
    document.cookie = `consent_facebook_oc=${this.form.controls.consent_facebook_oc.value};domain=talentagent.de`
    document.cookie = `consent_piwik=${this.form.controls.consent_piwik.value};domain=talentagent.de`
    document.cookie = `consent_ip_api=${this.form.controls.consent_ip_api.value};domain=talentagent.de`
    document.cookie = `consent_ip_api=${this.form.controls.consent_hotjar.value};domain=talentagent.de`

    this.finish()
  }


  finish() {
    this.close.next()
    if (this.initialConsent) {
      this.analyticsService.reportVirtualPageView();
    }
  }

  routePrivacy() {
    this.router.navigateByUrl('/datenschutz')
    this.close.next();
  }

}
