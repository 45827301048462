import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {CompanyData} from "utility";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {BottomSheetService} from "../../../utility/slideable-bottom-sheets/bottom-sheet.service";
import {CompanyProfileComponent} from "../../../company-profiles/company-profile/company-profile.component";

@Component({
  selector: 'app-positions-details-company',
  templateUrl: './positions-details-company.component.html',
  styleUrls: ['./positions-details-company.component.scss']
})
export class PositionsDetailsCompanyComponent implements OnInit {

    @Input() company: CompanyData
    @Input() companyDescription: string
    @Input() apiPositionLogoUrl: string

  constructor(
      public mediaService: MediaService,
      private authService: TalentAuthService,
      private bottomSheetService: BottomSheetService
  ) { }

  ngOnInit(): void {
  }

    createCompanyProfileLink(): string{
        if (!this.authService.isAuthenticated()) return
        return  '/company-profile/' + this.company.id
    }

    openCompanyProfileDialog(){
        if (!this.authService.isAuthenticated()) return
        this.bottomSheetService.openBottomSheet(CompanyProfileComponent, {
            companyId: this.company.id,
            showPosiitons: true
        })
    }
}
