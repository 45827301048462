import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {CoachingTypeData, CoachingTypeResource, TalentOnboardingResource} from "utility";
import {environment} from "../../../../environments/environment";
import {CachedOBInputs, ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";

@Component({
    selector: 'app-cpd-o-coach',
    templateUrl: './cpd-o-coach.component.html',
    styleUrls: ['./cpd-o-coach.component.scss']
})
export class CpdOCoachComponent implements OnInit, OnDestroy {

    readonly coachingsForm = new FormGroup({}, this.comparisonValidator());
    $skipLockSubject: Subscription
    public availableCoachingTypes: CoachingTypeData[] = [
        {
            id: 5074691,
            title: "Persönlichkeitsanalyse",
            description: "Finde heraus, welcher Job zu dir passt"
        },
        {
            id: 5074690,
            title: "Lebenslauf-Check",
            description: "Ein Experte optimiert deinen Lebenslauf"
        },
        {
            id: 5074692,
            title: "Gehaltsverhandlungs-Coaching",
            description: "So holst du 3.000€ mehr Gehalt raus"
        },
    ];

    public selectedCoachingTypes: CoachingTypeData[];
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public progessingSubject = new Subject()
    public skipLockSubject = new Subject()

    constructor(
        private coachingTypeResource: CoachingTypeResource,
        private onboardingService: ConfirmProfileDetailsOnboardingService,
        private onboardingResource: TalentOnboardingResource,
    ) {
    }

    ngOnInit() {
        this.availableCoachingTypes.forEach(coaching => {
            this.coachingsForm.addControl(coaching.id.toString(), new FormControl(false));
        });

        this.coachingsForm.valueChanges.subscribe(next => {
                if (Object.keys(this.coachingsForm.controls).some(controlName => this.coachingsForm.controls[controlName].value)) {
                    this.skipLockSubject.next(true)
                } else {
                    this.skipLockSubject.next(false)
                }
            }
        )

        this.coachingTypeResource.getSelectedCoachingTypes().then(coachings => {
            this.selectedCoachingTypes = this.availableCoachingTypes.filter(c => coachings.map(co => co.id).includes(c.id))

            this.selectedCoachingTypes.forEach(coaching => {
                this.coachingsForm.controls[coaching.id.toString()].setValue(true)
            })
        })

    }

    comparisonValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            let validStates: boolean[] = []
            for (const key in group.controls) {
                validStates.push(group.controls[key].value);
            }

            if (validStates.includes(true)) {
                return null;
            } else {
                return {noCoachingSelected: true};
            }
        };
    }

    ngOnDestroy() {
        this.$skipLockSubject?.unsubscribe()
    }

    progress(wantsCoachValue: boolean) {

        this.onboardingService.updateInputs(<CachedOBInputs>{
            wantsCoach: wantsCoachValue
        })

        let choosenCoachingIds: number[] = [];

        let context = 'next';
        if (!wantsCoachValue) {
            context = 'skip'
        }

        if (wantsCoachValue) {
            this.coachingsForm.updateValueAndValidity()
            if (this.coachingsForm.invalid) {
                this.coachingsForm.markAsTouched()
                return
            }
            this.progessingSubject.next()

            Object.keys(this.coachingsForm.controls).forEach(controlName => {
                if (this.coachingsForm.controls[controlName].value == true) {
                    choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.id.toString() == controlName)[0].id);
                }
            });

            this.onboardingResource.requestCoach(choosenCoachingIds).then(() => {
                this.onboardingService.delayedRouting("/onboarding/cpd/coach-data", context)
            });
        } else {
            this.progessingSubject.next()

            this.onboardingService.delayedRouting("/onboarding/cpd/password", context)
        }
    }

}
