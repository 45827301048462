<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="year-select" fxLayout="row" fxLayoutAlign="center center">
        <button class="margin-right" mat-icon-button (click)="updateYear(-1)">
            <i style="font-size: 32px !important; font-weight: 500; color: #939393;" class="mdi mdi-chevron-left"></i>
        </button>
        <div class="year margin-right">{{year}}</div>
        <button mat-icon-button (click)="updateYear(1)">
            <i style="font-size: 32px !important; font-weight: 500; color: #939393;" class="mdi mdi-chevron-right"></i>
        </button>

    </div>
    <div fxLayout="column" fxLayoutAlign="start center" class="chip-list">
        <div [ngClass]="{'active': month == 1}" mat-ripple (click)="updateMonth(1)" class="month-range">Januar - März</div>
        <div [ngClass]="{'active': month == 4}" mat-ripple (click)="updateMonth(4)" class="month-range">April - Juni</div>
        <div [ngClass]="{'active': month == 7}" mat-ripple (click)="updateMonth(7)" class="month-range">Juli - September</div>
        <div [ngClass]="{'active': month == 10}" mat-ripple (click)="updateMonth(10)" class="month-range">Oktober - Dezember</div>
        <div style="margin-top: 16px;" [ngClass]="{'active': month == 0}" mat-ripple (click)="updateMonth(0)" class="month-range">schon fertig</div>
    </div>
</div>
