import {Injectable} from '@angular/core';
import {LocalStorage} from "ngx-store";
import {AnalyticsService, OnboardingType} from "../../services/analytics/analytics.service";
import {Subject} from "rxjs";
import {
    delay,
    IpLocationData,
    Level,
    OnboardingVersion,
    Origin,
    RequestInfoResource,
    Split2TalentOnboardingResource,
    TalentCoachRequestDistributionData
} from 'utility';
import bugsnag from "@bugsnag/js";
import {Router} from "@angular/router";
import {TalentAuthService} from "../../services/talent.auth.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {UntypedFormControl} from "@angular/forms";
import {LocalStorageService} from "../../services/local-storage.service";


export class CachedOBInputs {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    subjectId: number;
    studyEndDate: Date;
    wantsCoach: boolean;
    cvUploaded: boolean;
    talentOrigin: Origin;
    germanSkillLevel: Level;
    talentCityId: number;
    searchMode: "Nationwide" | "SpecificCities";
    preferredCityId: number;
    markedAsInterestingFieldIds: number[];
    fieldIds: number[] = [];
    accessStrategy: "LOGIN" | "REGISTRATION " | null;
}

@Injectable({
    providedIn: 'root'
})
export class CVParsingOnboardingService {

    @LocalStorage()
    cachedInputs = <CachedOBInputs>{}

    @LocalStorage()
    private cvName: string


    /**CV gets stored in localstorage via b64-string and gets retreived on first get**/
    @LocalStorage()
    private storedCv;

    @LocalStorage()
    ipLocation: IpLocationData = {cityId: null, countryCode: null};

    //Decided not to store this because if user comes back a lot later it might be illegal to pre-check the acceptance-box.
    acceptedPrivacy = false;

    public skippedCvUpload: boolean = false;

    protected onboardingSplitName: OnboardingVersion = "CVParsingOB"


    getOnboardingType(): OnboardingType {
        if (this.analyticsService.trafficOrigin.invitedFromToken != null) {
            return "CoachInvite"
        } else {
            return "Standard"
        }
    }


    //pass an object that contains properties you want to update on CachedInputs. Keys have to match.
    updateInputs(data: CachedOBInputs) {
        for (var property in this.cachedInputs) {
            if (data[property] == null) {
                data[property] = this.cachedInputs[property]
            }
        }
        this.cachedInputs = data;
    }


    /**this gets called by the step-components instead of router. Its purpose is to be able to do the transition-animation in the main component.
     * The subject tells the main component that navigation is starting, so it can start the animation. then we just route.
     * **/
        //Should be changed in cooperation with marketing. consider documenting it.
    analyticsEventNameMapping = [
        {stepPath: 'cv-welcome', eventName: 'CVParsing'},
        {stepPath: 'subject', eventName: 'SelectSubject'},
        {stepPath: 'preferences', eventName: 'MatchingData'},
        {stepPath: 'contact-data', eventName: 'ContactData'},
        {stepPath: 'coach-intent', eventName: 'Coach'},
        {stepPath: 'coach-data', eventName: 'CoachData'},
        {stepPath: 'password', eventName: 'Password'},
    ]

    routingStart = new Subject()

    delayedRouting(target: string, stepCompletionContext: string = "next") {
        this.routingStart.next()

        this.reportCurrentStepCompletion(stepCompletionContext)
        
        //This delay has to be the same as the opacity-transition duration in matze-ob-component.scss. It's purpose is to support the animation.
        delay(200).then(() => {
            this.router.navigate([target]).then(() => {
                this.reportCurrentStepPageview()
            })
        })
    }

    reportCurrentStepCompletion(stepCompletionContext: string) {
        let completedStepName = this.getStepNameForRoute(this.router.url);
        this.analyticsService.reportOnboardingStepCompletion(this.getOnboardingType(), this.onboardingSplitName, completedStepName, stepCompletionContext)
    }

    //Gets called by the first step of the onboarding
    reportCurrentStepPageview() {
        let completedStepName = this.getStepNameForRoute(this.router.url);
        this.analyticsService.reportOnboardingVirtualPageView(this.getOnboardingType(), completedStepName, this.onboardingSplitName)
    }

    updateDistributionCriteriaForCoachRequest(data: TalentCoachRequestDistributionData): Promise<any> {
        return this.onboardingResource.updateDistributionCriteriaForCoachRequest(data)
    }

    getSufficientGermanLanguageLevel(): boolean {
        if (this.cachedInputs.talentOrigin == "German") return true
        // A: Verhandlungssicher (C1)
        // O: Muttersprache (C2)
        if (this.cachedInputs.talentOrigin == 'Eu' && ['A', 'O'].includes(this.cachedInputs.germanSkillLevel)) return true
        else return false
    }

    routeToPlattform(stepCompletionContext: string = 'next') {
        this.reportCurrentStepCompletion(stepCompletionContext)

        this.analyticsService.reportTalentQualifiedForCoachToDataLayerOld(
            this.onboardingSplitName,
            this.cachedInputs.wantsCoach,
            this.cachedInputs.cvUploaded,
            this.cachedInputs.studyEndDate,
            this.getSufficientGermanLanguageLevel()
        )

        let redirectPath = 'talent/positions'
        this.router.navigate([redirectPath])
    }

    private getStepNameForRoute(route: string): string {
        let lastPathSegment = route.split('/')[route.split('/').length - 1]

        if (lastPathSegment.indexOf('?') > -1) {
            lastPathSegment = lastPathSegment.split("?")[0]
        }

        let eventName = this.analyticsEventNameMapping.find(e => e.stepPath == lastPathSegment)?.eventName
        if (!eventName || eventName === '') {
            console.error(`${lastPathSegment}: ${eventName}`)
            bugsnag.notify("There is an Onboarding-Step with last URL-Segment " + lastPathSegment + " that cannot be mapped to an analytics eventName (" + eventName + "). This will likely break the tracking")
            return undefined
        }
        return eventName
    }


    constructor(
        private router: Router,
        private onboardingResource: Split2TalentOnboardingResource,
        private authService: TalentAuthService,
        private deviceService: DeviceDetectorService,
        private analyticsService: AnalyticsService,
        private localStorageService: LocalStorageService,
        private requestInfoResource: RequestInfoResource,
    ) {
    }


    /**creates account based on current data **/
    createAccount(): Promise<any> {
        return this.onboardingResource.createOrUpdateTalentAccount(
                {
                    firstName: this.cachedInputs.firstName,
                    email: this.cachedInputs.email,
                    lastName: this.cachedInputs.lastName,
                    phoneNumber: this.cachedInputs.phoneNumber,
                    subjectId: this.cachedInputs.subjectId,
                    studyEndDate: this.cachedInputs.studyEndDate,
                    forcedFieldsOfActivity: this.cachedInputs.fieldIds,
                    preferredFieldIds: this.cachedInputs.fieldIds ? Array.from(new Set(this.cachedInputs.markedAsInterestingFieldIds.concat(this.cachedInputs.fieldIds)).values()) : this.cachedInputs.markedAsInterestingFieldIds,
                    preferredCityIds: this.cachedInputs.preferredCityId ? [this.cachedInputs.preferredCityId] : null,
                    searchMode: this.cachedInputs.searchMode,
                    onboardingVersion: this.onboardingSplitName,
                    ipLocation: this.ipLocation,

                    browser: this.deviceService.browser,
                    deviceType: this.deviceService.deviceType,
                    browserVersion: this.deviceService.browser_version,
                    browserLanguage: navigator.language,

                    gclid: this.analyticsService.trafficOrigin.gclid,
                    fbclid: this.analyticsService.trafficOrigin.fbclid,
                    invitedFromToken: this.analyticsService.trafficOrigin.invitedFromToken,
                    utmCampaign: this.analyticsService.trafficOrigin.utmCampaign,
                    utmContent: this.analyticsService.trafficOrigin.utmContent,
                    utmMedium: this.analyticsService.trafficOrigin.utmMedium,
                    utmSource: this.analyticsService.trafficOrigin.utmSource,
                    utmTerm: this.analyticsService.trafficOrigin.utm_term,

                    consent_facebook_oc: this.localStorageService.gdprConsent.consent_facebook_oc,
                    consent_google_oc: this.localStorageService.gdprConsent.consent_google_oc
                }
            ).then(result => {
            if (result?.accessToken) {
                this.authService.setToken(result.accessToken, result.refreshToken)
                this.acceptedPrivacy = true;
                this.authService.applyLogin("LoginSuccessful")
            }
            })
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'whitespace': true};
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    setCV(cv: File) {
        this.cvName = cv.name
        this.getBase64(cv).then(res => {
            this.storedCv = res
        })
    }

    getCv(): File {
        if (!this.storedCv) return null
        return this.dataURLtoFile(this.storedCv, this.cvName)
    }

    async getUserLocationIfConsented(): Promise<IpLocationData | null> {
        if (this.localStorageService.getGDPRConsent().consent_ip_api) {
            this.ipLocation = await this.requestInfoResource.getRequestLocation();
            return this.ipLocation;
        } else {
            return null;
        }
    }

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

}
