import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    CoachingTypeData,
    CoachingTypeResource,
    TalentCoachRequestDistributionData,
    TalentCoachResource,
    TalentTalentProfileResource
} from 'utility';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {environment} from "../../../environments/environment";
import {MediaService} from "../../services/inno-utils/media.service";
import {PropagationService} from "../../services/propagation.service";
import {MatStepper} from "@angular/material/stepper";

@Component({
    selector: 'app-coaching-select-dialog',
    templateUrl: './assign-talent-agent-dialog.component.html',
    styleUrls: ['./assign-talent-agent-dialog.component.scss']
})
export class AssignTalentAgentDialogComponent implements OnInit {

    @ViewChild('stepper') stepper: MatStepper

    public readonly coachingsForm = new FormGroup({});
    public readonly distributionCriteriaForm = new FormGroup({
        locationControl: new FormControl(null, Validators.required),
        studiesEndControl: new FormControl(null, Validators.required),
        nationalityControl: new FormControl(null, Validators.required),
        languageStateControl: new FormControl(null)
    });
    public availableCoachingTypes: CoachingTypeData[];
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public requestTalentagent: boolean = false
    public updateCoachRequestDistributionDataLoading: boolean = false
    public loadingMissingCoachDistributionData: boolean = true
    public showDistributionCriteriaStep: boolean = true

    public endDateDropdownOptions = [{option: 'finished', label: 'Schon fertig'}, {
        option: '3months',
        label: '< 3 Monate'
    }, {option: '6months', label: '< 6 Monate'}, {option: 'later', label: '> 6 Monate'}];

    public skillLevel = [
        {'value': 'E', 'display': 'Anfänger (A1)'},
        {'value': 'D', 'display': 'Grundkenntnisse (A2)'},
        {'value': 'C', 'display': 'Fortgeschritten (B1)'},
        {'value': 'B', 'display': 'Fließend (B2)'},
        {'value': 'A', 'display': 'Verhandlungssicher (C1)'},
        {'value': 'O', 'display': 'Muttersprache (C2)'}
    ];

    public coachDistributionData: TalentCoachRequestDistributionData
    public showErrors = false;

    constructor(
        public dialogRef: MatDialogRef<AssignTalentAgentDialogComponent>,
        public mediaService: MediaService,
        private coachingTypeResource: CoachingTypeResource,
        private talentCoachResource: TalentCoachResource,
        private talentTalentProfileResource: TalentTalentProfileResource,
        public propagationService: PropagationService
    ) {
    }

    ngOnInit() {
        this.talentTalentProfileResource.getTalentDataRelevantForCoachDistribution().then(result => {
            this.coachDistributionData = result
            this.distributionCriteriaForm.controls.locationControl.setValue(result.talentCityId)
            this.distributionCriteriaForm.controls.studiesEndControl.setValue(result.studyEndDate)
            this.distributionCriteriaForm.controls.nationalityControl.setValue(result.talentOrigin)
            this.distributionCriteriaForm.controls.languageStateControl.setValue(result.germanSkillLevel)

            if (this.coachDistributionData.talentCityId != null && this.coachDistributionData.studyEndDate != null && (this.coachDistributionData.talentOrigin == "German" || this.coachDistributionData.germanSkillLevel != null)) {
                setTimeout(() => {this.stepper.next()})
                this.showDistributionCriteriaStep = false
            }
            setTimeout(() => {this.loadingMissingCoachDistributionData = false}, 12)
        })

        this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
            this.availableCoachingTypes = coachings;

            this.availableCoachingTypes.forEach(coaching => {
                if (coaching.title == 'Hilfe im Bewerbungsprozess') {
                    this.coachingsForm.addControl(coaching.title, new FormControl<boolean>({value: true, disabled: true}));
                } else {
                    this.coachingsForm.addControl(coaching.title, new FormControl<boolean>(false))
                }
            })
        })
    }

    requestTalentAgent() {
        this.requestTalentagent = true
        let choosenCoachingIds: number[] = [];

        Object.keys(this.coachingsForm.controls).forEach(controlName => {
            if (this.coachingsForm.controls[controlName].value == true) {
                choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.title == controlName)[0].id);
            }
        })

        this.talentCoachResource.requestTalentAgent(choosenCoachingIds).then(() => {

            this.dialogRef.close(true);
            this.requestTalentagent = false
            this.propagationService.coachRequested.next(true)
        })
    }

    updateCoachRequestDistributionData() {
        if (this.distributionCriteriaForm.invalid) {
            this.showErrors = true
            return
        }

        this.updateCoachRequestDistributionDataLoading = true

        let data = <TalentCoachRequestDistributionData>{
            studyEndDate: this.convertOptionToDate(this.distributionCriteriaForm.controls.studiesEndControl.value),
            talentOrigin: this.distributionCriteriaForm.controls.nationalityControl.value,
            talentCityId: this.distributionCriteriaForm.controls.locationControl.value,
            germanSkillLevel: this.distributionCriteriaForm.controls.languageStateControl.value
        }

        this.talentTalentProfileResource.updateRelevantDataForCoachDistribution(data).then(() => {
            setTimeout(() => {this.stepper.next()})
            this.updateCoachRequestDistributionDataLoading = false
        })
    }

    selectNationality(nationality: string) {
        if (nationality == 'German') {
            this.distributionCriteriaForm.controls.languageStateControl.clearValidators()
            this.distributionCriteriaForm.controls.languageStateControl.updateValueAndValidity()
        } else {
            this.distributionCriteriaForm.controls.languageStateControl.setValidators([Validators.required])
            this.distributionCriteriaForm.controls.languageStateControl.updateValueAndValidity()
        }
        this.distributionCriteriaForm.controls.nationalityControl.setValue(nationality)
    }

    convertOptionToDate(option){
        let date = new Date();
        let daysToAdd  = 0;
        switch(option.option){
            case 'finished': break;
            case '3months': daysToAdd = 90;break;
            case '6months': daysToAdd = 180; break;
            case 'later': daysToAdd = 270; break;
        }
        date.setDate(date.getDate() + daysToAdd)
        return date
    }
}
