import {LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {DateAdapter, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {
    MatLegacyPaginatorIntl as MatPaginatorIntl,
    MatLegacyPaginatorModule as MatPaginatorModule
} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {NgSelectModule} from '@ng-select/ng-select';
import {BrowserModule} from '@angular/platform-browser';
import {ClickOutsideModule} from 'ng-click-outside';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SynGenderPickerComponent} from './components/syn-gender-picker/syn-gender-picker.component';
import {SynPhoneInputComponent} from './components/syn-phone-input/syn-phone-input.component';
import {SynAutosaveWrapperComponent} from './components/syn-autosave-wrapper/syn-autosave-wrapper.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {PopulatedDropdownComponent} from './components/populated-dropdown/populated-dropdown.component';
import {HIDCheckComponent} from './components/hidcheck/hidcheck.component';
import {MatIconLoadingButtonComponent} from './components/mat-icon-loading-button/mat-icon-loading-button.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {EditImageDialogComponent} from './project-wide/edit-image-dialog/edit-image-dialog.component';
import {GenderPipe} from './pipes/gender.pipe';
import {SalutationPipe} from './pipes/salutation.pipe';
import {DegreeTypePipe} from './pipes/degree-type.pipe';
import {
    AccountResource,
    AdminApplicationResource,
    AdminCareerEventResource,
    AdminResource,
    AdminTalentProfileResource,
    AdminTalentResource,
    CoachingTypeResource,
    CompanyApplicationResource,
    CompanyResource,
    LanguageResource,
    LocationResource,
    LoginLoggingResource,
    NotificationResource,
    ProfessionFieldPreferenceResource,
    PublicCoachingTypeResource,
    PublicResource,
    PublicSubjectResource,
    Split2TalentOnboardingResource,
    CpdTalentOnboardingResource,
    StudyInfoResource,
    TalentAccountResource,
    TalentApplicationResource,
    TalentCareerEventResource,
    TalentCoachingOnboardingResource,
    TalentCoachResource,
    TalentCompanyProfileResource,
    TalentConsultantAppointmentRequestResource,
    TalentEmploymentPositionResource,
    TalentEvaluationResource,
    TalentEventOnboardingResource,
    TalentHiringConfirmationResource,
    TalentHomeResource,
    TalentImageResource,
    TalentMatchingStateResource,
    TalentNotificationConfigurationResource,
    TalentNotificationResource,
    TalentOnboardingResource,
    TalentPositionRelationResource,
    TalentProfessionFieldsResource,
    TalentProfileAttachmentResource,
    TalentRateTalentagentResource,
    TalentStudiesResource,
    TalentSupportResource,
    TalentTalentProfileResource,
    UniversityResource,
} from './generated/resources';
import {
    AccountResource as UniSignAccountResource,
    PublicResource as UniSignPublicResource
} from './generated/unisign/resources'
import {ProfileImageService} from './custom-resources/profile-image.service';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import localeDe from '@angular/common/locales/de';
import {SynPhoneComponent} from './components/syn-phone/syn-phone.component';
import {SynAsyncScrollerComponent} from './components/syn-async-scroller/syn-async-scroller.component';
import {ScrolledToBottomDirective} from './components/scrolled-to-bottom/scrolled-to-bottom.directive';
import {EmailAddressAvailabilityValidatorService} from './service/email-address-availability-validator.service';
import {SkillPipe} from './pipes/skill.pipe';
import {MatPaginatorIntlGer} from './custom-resources/mat-paginator-intl-ger';
import {LoadingContainerComponent} from './components/loading-container/loading-container.component';
import {FileValueAccessorDirective} from './directives/file-value-accessor.directive';
import {GermanDateAdapter} from './custom-resources/german-data-adapter';
import {NgxCurrencyModule} from 'ngx-currency';

import {Angulartics2Module} from 'angulartics2';
import {SliceStringLengthPipe} from './pipes/slice-string-length.pipe';
import {CareertypePipe} from './pipes/careertype.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {TalentFullNamePipe} from './pipes/talent-full-name.pipe';
import {WorkTypePipe} from './pipes/work-type.pipe';
import {UploadFileComponent} from './components/upload-file/upload-file.component';
import {HidLibCloneModule} from '../../../talent/src/app/utility/hid-lib-clone/hid-lib-clone.module';
import {EducationTypePipe} from './pipes/education-type.pipe';
import {WelcomeVideoComponent} from "./components/welcome-video/welcome-video.component";
import {EmploymentPositionOriginPipe} from './pipes/employment-position-origin.pipe';
import {SectorInterestComponent} from "../../../talent/src/app/utility/sector-interest/sector-interest.component";
import {
    MultipleCitySelectionComponent
} from "../../../talent/src/app/utility/multiple-city-selection/multiple-city-selection.component";
import {
    ApplicationProcessbarComponent
} from "../../../talent/src/app/utility/application-processbar/application-processbar.component";
import {DialogHeaderComponent} from "../../../talent/src/app/utility/dialog-header/dialog-header.component";
import {
    LocationAutocompleteInputComponent
} from "../../../talent/src/app/utility/location-autocomplete-input/location-autocomplete-input.component";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {
    LocationInputSearchComponent
} from "../../../talent/src/app/utility/location-input-search/location-input-search.component";
import {LoadingButtonDirective} from './directives/loading-button.directive';
import {StudySearchOnboardingComponent} from './components/study-search-onboarding/study-search-onboarding.component';
import {CompanyPictureComponent} from "../../../talent/src/app/utility/company-picture/company-picture.component";
import {CoachImageComponent} from "../../../talent/src/app/utility/coach-image/coach-image.component";
import {
    JumpingDotsLoadingAnimationComponent
} from './components/jumping-dots-loading-animation/jumping-dots-loading-animation.component';
import {ApplicationStatePipe} from './pipes/application-state.pipe';
import {CvUploadButtonComponent} from "../../../talent/src/app/utility/cv-upload-button/cv-upload-button.component";
import {CountdownComponent} from "../../../talent/src/app/utility/countdown/countdown.component";
import {
    StudyEndDateSelectionComponent
} from "../../../talent/src/app/utility/study-end-date-selection/study-end-date-selection.component";
import {LegalAPIResourceV2} from "./generated/cronos/resources";
import {InfoTextComponent} from "../../../talent/src/app/utility/info-text/info-text.component";
import {ChipComponent} from "../../../talent/src/app/utility/chip/chip.component";
import {ChipListComponent} from "../../../talent/src/app/utility/chip-list/chip-list.component";
import {IconLoadingSpinnerComponent} from "./components/icon-loading-spinner/icon-loading-spinner.component";
import {
    FieldDropdownContentComponent
} from "../../../talent/src/app/utility/field-dropdown/field-dropdown-content/field-dropdown-content.component";
import {
    LocationDropdownContentComponent
} from "../../../talent/src/app/utility/location-dropdown/location-dropdown-content/location-dropdown-content.component";
import {TaSearchBarComponent} from "../../../talent/src/app/utility/ta-search-bar/ta-search-bar.component";
import {LocationsMarkerComponent} from "../../../talent/src/app/utility/locations-marker/locations-marker.component";
import {
    PositionPartnerLabelComponent
} from "../../../talent/src/app/position/position-details-content-new/position-partner-label/position-partner-label.component";
import {RequestStatePipe} from "./pipes/request-state.pipe";
import {
    PositionFieldOfActivitesComponent
} from "../../../talent/src/app/utility/position-field-of-activites/position-field-of-activites.component";
import {
    FieldSearchInputComponent
} from "../../../talent/src/app/utility/field-search-input/field-search-input.component";
import {LoadingSkeletonComponent} from "../../../talent/src/app/utility/loading-skeletton/loading-skeleton.component";
import {ImageGalleryComponent} from "./components/image-gallery/image-gallery.component";
import {
    GermanSkillLevelComponent
} from "../../../talent/src/app/utility/german-skill-level/german-skill-level.component";
import {LoadingSpinnerDirective} from './directives/loading-spinner.directive';
import {
    CompanyLogoWithNameComponent
} from "../../../talent/src/app/utility/company-logo-with-name/company-logo-with-name.component";
import {
    CompanyPreviewListComponent
} from "../../../talent/src/app/utility/company-preview-list/company-preview-list.component";
import {
    InterestingEventCompaniesComponent
} from "../../../talent/src/app/utility/interesting-event-companies/interesting-event-companies.component";
import {ChatComponent} from "../../../talent/src/app/chat-overview/chat/chat.component";
import {ChatMessageComponent} from "../../../talent/src/app/chat-overview/chat-message/chat-message.component";
import {ChatMinimalComponent} from "../../../talent/src/app/chat-overview/chat-minimal/chat-minimal.component";
import {RouterModule} from "@angular/router";
import {A11yModule} from "@angular/cdk/a11y";
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {WorktimePipe} from './pipes/worktime.pipe';
import {HomeOfficePipe} from './pipes/home-office.pipe';
import {
    PositionsChipsSkeletonComponent
} from "../../../talent/src/app/utility/positions-chips-skeleton/positions-chips-skeleton.component";
import {InViewDirective} from 'projects/talent/src/app/utility/in-view.directive';
import {FileDragDropDirective} from "../../../talent/src/app/utility/directives/file-drag-drop.directive";

registerLocaleData(localeDe);

@NgModule({
    declarations: [
        ImageGalleryComponent,
        CountdownComponent,
        LoadingSkeletonComponent,
        PositionsChipsSkeletonComponent,
        PositionPartnerLabelComponent,
        CvUploadButtonComponent,
        LocationsMarkerComponent,
        LocationInputSearchComponent,
        SynGenderPickerComponent,
        SynPhoneInputComponent,
        SynPhoneComponent,
        CoachImageComponent,
        InfoTextComponent,
        ChipComponent,
        ChipListComponent,
        IconLoadingSpinnerComponent,
        FieldDropdownContentComponent,
        LocationDropdownContentComponent,
        TaSearchBarComponent,
        PositionFieldOfActivitesComponent,
        FieldSearchInputComponent,
        GermanSkillLevelComponent,
        CompanyPreviewListComponent,

        SynAutosaveWrapperComponent,
        ApplicationProcessbarComponent,
        SynAsyncScrollerComponent,
        ScrolledToBottomDirective,

        LoadingSpinnerComponent,

        PopulatedDropdownComponent,

        HIDCheckComponent,
        MatIconLoadingButtonComponent,
        SectorInterestComponent,
        MultipleCitySelectionComponent,
        LocationAutocompleteInputComponent,
        DialogHeaderComponent,
        CompanyPictureComponent,
        StudyEndDateSelectionComponent,
        InterestingEventCompaniesComponent,

        ChatComponent,
        ChatMessageComponent,
        ChatMinimalComponent,

        // pipe
        CareertypePipe,
        DegreeTypePipe,
        RequestStatePipe,
        GenderPipe,
        SalutationPipe,
        SkillPipe,
        SliceStringLengthPipe,
        SafeHtmlPipe,
        SkillPipe,
        TalentFullNamePipe,
        WorkTypePipe,
        EducationTypePipe,
        EmploymentPositionOriginPipe,

        // dialogs
        ConfirmDialogComponent,
        EditImageDialogComponent,
        LoadingContainerComponent,
        FileValueAccessorDirective,
        UploadFileComponent,
        WelcomeVideoComponent,
        StudySearchOnboardingComponent,
        JumpingDotsLoadingAnimationComponent,
        ApplicationStatePipe,
        IconLoadingSpinnerComponent,
        LoadingButtonDirective,
        LoadingSpinnerDirective,
        CompanyLogoWithNameComponent,
        InViewDirective,
        FileDragDropDirective,
        WorktimePipe,
        HomeOfficePipe,
    ],
    imports: [
        MatSelectModule,
        MatButtonModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        HttpClientModule,
        MatInputModule,
        ClickOutsideModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatTabsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        ImageCropperModule,
        MatBadgeModule,
        FlexLayoutModule,
        NgSelectModule,
        HttpClientModule,
        ResourceModule.forRoot(),
        NgxCurrencyModule,
        Angulartics2Module.forRoot(),
        HidLibCloneModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        MatRippleModule,
        MatChipsModule,
        RouterModule,
        A11yModule,
        MatAutocompleteModule,
    ],
    exports: [
        FieldDropdownContentComponent,
        CvUploadButtonComponent,
        CompanyLogoWithNameComponent,
        CompanyPreviewListComponent,
        RequestStatePipe,
        LocationsMarkerComponent,
        TaSearchBarComponent,
        PositionPartnerLabelComponent,
        LocationDropdownContentComponent,
        CompanyPictureComponent,
        CoachImageComponent,
        FieldSearchInputComponent,
        InfoTextComponent,
        ChipComponent,
        ChipListComponent,
        CountdownComponent,
        MatButtonModule,
        LoadingSkeletonComponent,
        PositionsChipsSkeletonComponent,
        NgxMatSelectSearchModule,
        LocationInputSearchComponent,
        SynGenderPickerComponent,
        SynPhoneInputComponent,
        SynAutosaveWrapperComponent,
        LocationAutocompleteInputComponent,
        ApplicationProcessbarComponent,
        MatProgressSpinnerModule,
        SectorInterestComponent,
        LoadingSpinnerComponent,
        PopulatedDropdownComponent,
        SynAsyncScrollerComponent,
        ScrolledToBottomDirective,
        GermanSkillLevelComponent,
        HIDCheckComponent,
        MatIconLoadingButtonComponent,
        MultipleCitySelectionComponent,
        ConfirmDialogComponent,
        SynPhoneComponent,
        UploadFileComponent,
        DialogHeaderComponent,
        CareertypePipe,
        DegreeTypePipe,
        GenderPipe,
        SalutationPipe,
        SkillPipe,
        HomeOfficePipe,
        SliceStringLengthPipe,
        SafeHtmlPipe,
        SkillPipe,
        TalentFullNamePipe,
        WorkTypePipe,
        WorktimePipe,
        EducationTypePipe,
        EmploymentPositionOriginPipe,
        MatCheckboxModule,
        HttpClientModule,
        MatInputModule,
        ClickOutsideModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatNativeDateModule,
        ImageCropperModule,
        MatBadgeModule,
        MatTreeModule,
        FlexLayoutModule,
        NgSelectModule,
        HttpClientModule,
        Angulartics2Module,
        LoadingContainerComponent,
        FileValueAccessorDirective,
        NgxCurrencyModule,
        UploadFileComponent,
        LoadingButtonDirective,
        LoadingSpinnerDirective,
        StudySearchOnboardingComponent,
        JumpingDotsLoadingAnimationComponent,
        ApplicationStatePipe,
        StudyEndDateSelectionComponent,
        IconLoadingSpinnerComponent,
        PositionFieldOfActivitesComponent,
        ImageGalleryComponent,
        InterestingEventCompaniesComponent,
        ImageGalleryComponent,
        ChatComponent,
        ChatMinimalComponent,
        ChatMessageComponent,
        InViewDirective,
        FileDragDropDirective
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlGer},
        {provide: DateAdapter, useClass: GermanDateAdapter},
        ProfileImageService,
        AdminApplicationResource,
        CompanyApplicationResource,
        EmailAddressAvailabilityValidatorService,
        CompanyResource,
        DatePipe,
        LanguageResource,
        TalentSupportResource,
        UniversityResource,
        TalentOnboardingResource,
        TalentTalentProfileResource,
        TalentImageResource,
        TalentCareerEventResource,
        StudyInfoResource,
        LocationResource,
        TalentProfileAttachmentResource,
        LanguageResource,
        TalentApplicationResource,
        Split2TalentOnboardingResource,
        CpdTalentOnboardingResource,
        AccountResource,
        NotificationResource,
        AdminResource,
        FileValueAccessorDirective,
        TalentNotificationConfigurationResource,
        AdminTalentResource,
        AdminCareerEventResource,
        AdminTalentProfileResource,
        TalentNotificationResource,
        TalentEmploymentPositionResource,
        CoachingTypeResource,
        TalentCoachResource,
        PublicResource,
        TalentHiringConfirmationResource,
        TalentMatchingStateResource,
        TalentAccountResource,
        ProfessionFieldPreferenceResource,
        TalentRateTalentagentResource,
        TalentEvaluationResource,
        TalentPositionRelationResource,
        PublicCoachingTypeResource,
        TalentProfessionFieldsResource,
        LoadingButtonDirective,
        TalentStudiesResource,
        PublicSubjectResource,
        TalentConsultantAppointmentRequestResource,
        CareertypePipe,
        TalentHomeResource,
        CareertypePipe,
        LegalAPIResourceV2,
        TalentCompanyProfileResource,
        TalentCoachingOnboardingResource,
        TalentEventOnboardingResource,
        UniSignPublicResource,
        UniSignAccountResource,
        LoginLoggingResource
    ]
})
export class UtilityModule {
    public static forRoot(environment: any): ModuleWithProviders<UtilityModule> {

        return {
            ngModule: UtilityModule,
            providers: [
                {
                    provide: 'env', // you can also use InjectionToken
                    useValue: environment
                }
            ]
        };
    }
}
