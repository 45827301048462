import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ConfirmProfileDetailsOnboardingComponent
} from './confirm-profile-details-onboarding/confirm-profile-details-onboarding.component';
import {CpdOCoachComponent} from './cpd-o-coach/cpd-o-coach.component';
import {CpdOCoachDataComponent} from './cpd-o-coach-data/cpd-o-coach-data.component';
import {CpdOContactDataComponent} from './cpd-o-contact-data/cpd-o-contact-data.component';
import {CpdOEveProgressComponent} from './cpd-o-eve-progress/cpd-o-eve-progress.component';
import {CpdOMatchingDataComponent} from './cpd-o-matching-data/cpd-o-matching-data.component';
import {CpdOPasswordComponent} from './cpd-o-password/cpd-o-password.component';
import {CpdOSelectSubjectComponent} from './cpd-o-select-subject/cpd-o-select-subject.component';
import {CpdOStepHeadlineComponent} from './cpd-o-step-headline/cpd-o-step-headline.component';
import {CpdOWelcomeComponent} from './cpd-o-welcome/cpd-o-welcome.component';
import {UtilityModule} from "utility";
import {RouterModule, Routes} from "@angular/router";
import {CpdOStudySearchComponent} from './cpd-o-study-search/cpd-o-study-search.component';
import {
    CpdOConfirmProfileDetailsComponent
} from './cpd-o-confirm-profile-details/cpd-o-confirm-profile-details.component';
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {CpdOButtonBarComponent} from "./cpd-o-button-bar/cpd-o-button-bar.component";
import { CpdOStudySearchCheckComponent } from './cpd-o-study-search-check/cpd-o-study-search-check.component';

const appRoutes: Routes = [
    {
        path: 'onboarding/cpd', component: ConfirmProfileDetailsOnboardingComponent, canActivate: [OnboardingAuthGuardService],
        children: [
            {path: 'cv-welcome', component: CpdOWelcomeComponent},
            {path: 'confirm-profile-details', component: CpdOConfirmProfileDetailsComponent},
            {path: 'subject', component: CpdOSelectSubjectComponent},
            {path: 'preferences', component: CpdOMatchingDataComponent},
            {path: 'contact-data', component: CpdOContactDataComponent},
            {path: 'coach-intent', component: CpdOCoachComponent},
            {path: 'coach-data', component: CpdOCoachDataComponent},
            {path: 'password', component: CpdOPasswordComponent}
        ]
    }];

@NgModule({
    declarations: [
        ConfirmProfileDetailsOnboardingComponent,
        CpdOButtonBarComponent,
        CpdOCoachComponent,
        CpdOCoachDataComponent,
        CpdOContactDataComponent,
        CpdOEveProgressComponent,
        CpdOMatchingDataComponent,
        CpdOPasswordComponent,
        CpdOSelectSubjectComponent,
        CpdOStepHeadlineComponent,
        CpdOWelcomeComponent,
        CpdOStudySearchComponent,
        CpdOConfirmProfileDetailsComponent,
        CpdOStudySearchCheckComponent
    ],
    imports: [
        UtilityModule,
        MatDialogModule,
        CommonModule,
        RouterModule.forChild(appRoutes),
        HidLibCloneModule
    ]
})
export class ConfirmProfileDetailsOnboardingModule {
}
