import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MediaService} from "../services/inno-utils/media.service";
import {TalentApplicationResource, TalentPositionDetailsData} from "utility";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {PositionService} from "../services/position.service";
import {BottomSheetService} from "../utility/slideable-bottom-sheets/bottom-sheet.service";

@Component({
    selector: 'app-application-cockpit-dialog',
    templateUrl: './application-cockpit-dialog.component.html',
    styleUrls: ['./application-cockpit-dialog.component.scss']
})
export class ApplicationCockpitDialogComponent implements OnInit {

    safeUrl: SafeUrl
    confirming: boolean = false

    constructor(
        public dialogRef: MatDialogRef<ApplicationCockpitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            relation: TalentPositionDetailsData,
            origin: any
        },
        public mediaService: MediaService,
        private sanitizer: DomSanitizer,
        private talentApplicationResource: TalentApplicationResource,
        private dialog: MatDialog,
        private positionService: PositionService,
        private bottomSheetService: BottomSheetService
    ) {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.relation.employmentPosition.webLink)
    }

    ngOnInit(): void {
    }

    onClose(result: boolean = false) {
            this.close(result)
    }

    createExternalApplication() {
        if (this.confirming) return
        this.confirming = true;
        this.positionService.sendExternApplication(this.data.origin, this.data.relation).then(() => {
            this.close(true);
            this.bottomSheetService.closeCurrentBottomSheet()
            }
        )
    }

    close(result: boolean = false) {
        this.dialogRef.close(result)
    }


}
