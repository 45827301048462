import {Injectable} from '@angular/core';
import {GDPRConsent} from "./local-storage.service";

export const COOKIE_NAME_USER_INFORMATION = 'uinf';
export const COOKIE_NAME_ACCESS_STRATEGY = 'astr';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    constructor() {
    }

    public getGDPRConsentFromCookies(): GDPRConsent {
        if (!this.cookieExists('initial')) {
            return null;
        }

        return {
            initial: this.getCookie('initial'),
            consent_ga: this.getCookie('consent_ga'),
            consent_linkedIn: this.getCookie('consent_linkedIn'),
            consent_pinterest: this.getCookie('consent_pinterest'),
            consent_facebook: this.getCookie('consent_facebook'),
            consent_google_ads: this.getCookie('consent_google_ads'),
            consent_tiktok: this.getCookie('consent_tiktok'),
            consent_google_oc: this.getCookie('consent_google_oc'),
            consent_facebook_oc: this.getCookie('consent_facebook_oc'),
            consent_piwik: this.getCookie('consent_piwik'),
            consent_ip_api: this.getCookie('consent_location'),
            consent_hotjar: this.getCookie('consent_hotjar')
        };
    }

    getCookieByteArrayBase64Decoded(name: string): any {
        const cookie = this.getCookieRaw(name);
        const cookieAsBased64Encoded = cookie.split('=')[1];
        return this.getByteArrayBase64Decoded(cookieAsBased64Encoded);
    }

    getByteArrayBase64Decoded(valueAsBased64Encoded: string): any {
        if (!valueAsBased64Encoded) {
            return null;
        }
        const valueAsBased64Decoded = atob(valueAsBased64Encoded);
        if (valueAsBased64Decoded) {
            return JSON.parse(valueAsBased64Decoded);
        }
        return null;
    }

    public getCookie(name: string) {
        const cookie = this.getCookieRaw(name);
        if (cookie) {
            return JSON.parse(cookie.split('=')[1]);
        }
        return null;
    }

    public cookieExists(name: string): boolean {
        return !!this.getCookieRaw(name);
    }

    private getCookieRaw(name: string) {
        const cookieArray = document.cookie.split(';');
        return cookieArray.find(e => e.indexOf(name) > -1);
    }
}
