<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <div class="card-wrapper">
    <div
      *ngIf="!(talentApplication.state == 'Failed' || talentApplication.state == 'Withdrawn' || talentApplication.state == 'Denied')"
      class="process-bar" fxLayout="row" fxLayoutAlign="space-between center">

      <div *ngIf="talentApplication.type === 'ExternalApplication'" class="applied-box" fxLayout="row" fxLayoutAlign="center center">
        <div>Bewerbung über <position-partner-label [origin]="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin"></position-partner-label></div>
      </div>

      <div *ngIf="talentApplication.type !== 'ExternalApplication'" fxLayoutAlign="space-around center" class="process-bar-box">
        <div class="process-step step-finished" [matTooltip]="draftText"
             [ngClass]="{'current-state': applicationStates.indexOf(talentApplication.state) == 0}">{{getHeaderStepText(draftText, '1.')}}
        </div>
        <div class="process-step" [matTooltip]="grantedText"
             [ngClass]="{'step-finished': applicationStates.indexOf(talentApplication.state) > 0, 'current-state': applicationStates.indexOf(talentApplication.state) == 1}">
          {{getHeaderStepText(grantedText, '2.')}}</div>
        <div class="process-step" [matTooltip]="InterestingText"
             [ngClass]="{'step-finished': applicationStates.indexOf(talentApplication.state) > 1, 'current-state': applicationStates.indexOf(talentApplication.state) == 2}">
          {{getHeaderStepText(InterestingText, '3.')}}</div>
        <div class="process-step" [matTooltip]="appProcessEnterText"
             [ngClass]="{'step-finished': applicationStates.indexOf(talentApplication.state) > 2, 'current-state': applicationStates.indexOf(talentApplication.state) == 3}">
          {{getHeaderStepText(appProcessEnterText, '4.')}}</div>
        <div class="process-step" [matTooltip]="contractSentText"
             [ngClass]="{'step-finished': applicationStates.indexOf(talentApplication.state) > 3, 'current-state': applicationStates.indexOf(talentApplication.state) == 4}">
          {{getHeaderStepText(contractSentText, '5.')}}</div>
        <div class="process-step" [matTooltip]="hiredText"
             [ngClass]="{'step-finished': applicationStates.indexOf(talentApplication.state) > 4, 'current-state': applicationStates.indexOf(talentApplication.state) == 5}">
          <i class="mdi mdi-24px mdi-flag"></i>
        </div>
      </div>
    </div>

    <div class="position-box">
      <div (click)="openPositionDetailsDialog(!mediaService.getMaxWidthQuery(959).matches)">
        <div class="position-information" fxLayout="row">
          <div class="company-picture mat-elevation-z1" fxLayout="column" fxLayoutAlign="start center">
            <img *ngIf="companyLogoAvailable" [src]="companyLogoUrl">
            <img *ngIf="!companyLogoAvailable" src="../../../assets/images/TA_KeinLogo3.2.jpg">

          </div>
          <div fxLayout="column" class="w-100">

            <div class="header-information" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxLayout="column">
                <div [matTooltip]="talentApplication.talentPositionRelation.employmentPosition.title" class="position-title">
                  {{talentApplication.talentPositionRelation.employmentPosition.title}}
                </div>

                <div class="company-name">
                  {{talentApplication?.talentPositionRelation?.employmentPosition?.hiringCompanyName}}
                </div>


              </div>
            </div>


            <div class="position-more-information" fxLayout="column" fxLayoutAlign="start start">

              <div fxLayout="row wrap" fxLayoutAlign="start center" class="table-row">
                <locations-marker
                        [positionId]="talentApplication.talentPositionRelation.employmentPosition.id" [locationDescription]="talentApplication?.talentPositionRelation?.employmentPosition?.locationDescription" [personalizesString]="talentApplication?.talentPositionRelation?.employmentPosition?.locationShortDescription"></locations-marker>
              </div>

              <div style="margin-top: 20px" fxHide fxShow.lt-md *ngIf="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin !== 'Uniwunder'" fxLayout="row" fxLayoutAlign="start center">
                <span style="font-size: 12px">via</span> <position-partner-label [origin]="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin"></position-partner-label>
              </div>
            </div>

          </div>
        </div>

        <div class="request-state-box" *ngIf="talentApplication.type !== 'ExternalApplication'">

          <div *ngIf="talentApplication.state == 'Draft'" fxLayout="row" fxLayoutAlign="start center" class="alert-box">
            <i class="fa fa-exclamation-triangle"></i>
            <div>Sobald du deinen <b>Lebenslauf hochgeladen</b> hast, wird deine Bewerbung abgeschickt!</div>
          </div>

          <div class="request-state" *ngIf="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin == 'Uniwunder'">{{talentApplication.state | requestState}}</div>
          <div *ngIf="talentApplication.state == 'Failed' && talentApplication.failedReason">Nachricht vom Unternehmen: "{{talentApplication.failedReason}}"</div>
          <div class="request-state" *ngIf="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin != 'Uniwunder' && isApplicationFinished()">Bewerbung nicht mehr Aktiv</div>
          <div class="request-state" *ngIf="talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin != 'Uniwunder' && !isApplicationFinished() && this.talentApplication?.state != 'Draft'">Bewerbung abgeschickt</div>

          <div
                  *ngIf="talentApplication.recruiter && (talentApplication.state == 'AppProcessEnter' || talentApplication.state == 'ContractSent' || talentApplication.state == 'Hired')"
                  class="recruiter-contact-data" fxLayout="column" fxLayoutAlign="start start">
            <div>Dein Ansprechpartner:</div>
            <div fxLayout="row wrap" fxLayoutAlign="start start" class="contact-data">
              <div fxLayout="row" fxLayoutAlign="start center">
                <i class="mdi mdi-email icon"></i>
                <div>{{talentApplication.recruiter.emailAddress}}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center">
                <i class="mdi mdi-phone icon"></i>
                <div>{{talentApplication.recruiter.phoneNumber}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="actions-box" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutGap="5px">

        <button mat-button *ngIf="!mediaService.getMaxWidthQuery(959).matches" (click)="openPositionDetailsDialog()" >zur Stellenbeschreibung</button>

        <button *ngIf="talentApplication.state == 'Draft'" mat-flat-button color="accent" class="ita_application_feed ita_upload_cv"
                (click)="uploadCv(talentApplication.talentPositionRelation.employmentPosition.id)">
          <i class="mdi mdi-upload"></i>Lebenslauf hochladen
        </button>

        <div fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="8px" fxLayout.gt-sm="row" class="buttons">
          <div class="button" *ngIf="!talentHasOutcomeReported && !isApplicationFinished()">
            <button class="hid-button warn inverse"
                    *ngIf="talentApplication.state != 'Draft'"
                    (click)="talentMarkedApplicationsAsFailed()">Ich wurde
              nicht eingestellt
            </button>
          </div>

          <div class="hid-btn-style ita_to-position-from-application"
               *ngIf="!talentHasOutcomeReported && !isApplicationFinished() && talentApplication.state != 'Draft'"
               matRipple (click)="talentMarkedApplicationsAsHired()" >
            <a style="font-size: 14px; min-height: 36px; overflow: hidden; color: white">Ich wurde eingestellt</a>
          </div>

          <div *ngIf="talentApplication.additionalFilesAddable">
            <button mat-flat-button color="accent"
                    class="width-100"
                                 (click)="openDemandAdditionalDocumentsDialog(talentApplication)">
              Dokumente nachreichen
          </button>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
