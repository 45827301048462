<dialog-header headline="Studiengang ändern"></dialog-header>

<mat-dialog-content fxLayout="column">
    <div id="content-wrapper" [class.is-ios]="mediaService.isIOs()" fxLayout="column" fxLayoutAlign="center center">
        <loading-spinner class="width-100" style="height: inherit" [loadingVar]="saving">
        <study-search-onboarding class="width-100" [control]="studyControl" (onSelect)="updateStudy()"></study-search-onboarding>
        </loading-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="primary-text-color">
    <button mat-button class="m-right10" (click)="close()">Abbrechen</button>
</mat-dialog-actions>
