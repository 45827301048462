import { Component, OnInit } from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";
import {Subject} from "rxjs";
import {FileService} from "../../../services/file.service";
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentOnboardingResource} from "utility";
import {EventCachedOBInputs, EventOnboardingService} from "../event-onboarding.service";

@Component({
  selector: 'app-event-ob-cv-upload',
  templateUrl: './event-ob-cv-upload.component.html',
  styleUrls: ['./event-ob-cv-upload.component.scss']
})
export class EventObCvUploadComponent implements OnInit {
  controlErrors: ValidationErrors
  fileValidSubject = new Subject()
  readonly cvFile = new FormControl<FileList>(null, this.fileService.uploadFileControlValidator());
  fileIsWordDocument: boolean = false;
  uploadTried: boolean = false;
  preSelectedFile: boolean = false;
  uploadingCv: boolean = false;


  constructor(
      public fileService: FileService,
      private onboardingService: EventOnboardingService,
      public mediaService: MediaService,
      private talentOnboardingResource: TalentOnboardingResource
  ) {
  }

  ngAfterViewInit(): void {
    this.updateValidSubject()
  }

  ngOnInit(): void {
    let file = this.onboardingService.getCv()

    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      this.cvFile.setValue(dataTransfer.files)
      this.cvFile.updateValueAndValidity()
      this.preSelectedFile = true
    }
    this.cvFile.valueChanges.subscribe(() => {
      this.updateValidSubject()
    });
  }

  updateValidSubject(): boolean {
    let valid = (this.cvFile.valid && !this.fileIsWordDocument) || this.preSelectedFile
    this.fileValidSubject.next(valid)
    return valid
  }

  checkForWordDocument(file) {
    if (!file) {
      this.fileIsWordDocument = false
    } else {
      this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
    }

    this.updateValidSubject()
  }

  set(fileEvent: Event) {
    let file = this.cvFile.value;

    this.checkForWordDocument(file)
    this.attemptUpload();
  }


  attemptUpload() {
    this.preSelectedFile = false
    this.uploadTried = true
    let valid = this.updateValidSubject()
    this.controlErrors = this.cvFile.errors

    if (valid) {
      this.uploadingCv = true
      this.fileService.uploadCV(this.cvFile.value[0]).subscribe(() => {
        this.onboardingService.updateInputs(<EventCachedOBInputs>{
          cvUploaded: true
        })
        this.onboardingService.setCV(this.cvFile.value[0])
        this.progress("upload")
      })

    } else {
      this.cvFile.setValue(null)
    }
  }

  skipNoCv() {
    // this.talentOnboardingResource.reportTalentSkipedCvUploadOnboarding()
    this.progress('skip')
  }

  progress(context: string) {
    this.onboardingService.delayedRouting("/onboarding/event/password", context)
  }
}
