import {Injectable} from '@angular/core';
import {
  delay,
  IpLocationData,
  OnboardingVersion,
  Origin,
  PublicEventData,
  RequestInfoResource,
  TalentCoachRequestDistributionData,
  TalentEventInterestingCompanyData,
  TalentEventOnboardingCreationData,
  TalentEventOnboardingResource
} from "utility";
import {LocalStorage} from "ngx-store";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {AnalyticsService, OnboardingType} from "../../services/analytics/analytics.service";
import bugsnag from "@bugsnag/js";
import {FormControl} from "@angular/forms";
import {LocalStorageService} from "../../services/local-storage.service";
import {TalentAuthService} from "../../services/talent.auth.service";

export class EventCachedOBInputs{
  firstName: string;
  eventId: number;
  lastName: string;
  phoneNumber: string;
  email: string;
  subjectId: number;
  studyEndDate: Date;
  wantsCoach: boolean;
  cvUploaded: boolean;
  talentCityId: number;
  talentOrigin: Origin;
  searchMode: "Nationwide" | "SpecificCities";
  preferredCityId: number;
  markedAsInterestingFieldIds: number[];
  fieldIds: number[] = [];
  germanSkillLevel: string;
  accessStrategy: "LOGIN" | "REGISTRATION " | null;
}

@Injectable({
  providedIn: 'root'
})

export class EventOnboardingService {
  @LocalStorage()
  cachedInputs = <EventCachedOBInputs>{
    firstName: null
  }

  @LocalStorage()
  private cvName: string


  /**CV gets stored in localstorage via b64-string and gets retreived on first get**/
  @LocalStorage()
  private storedCv;

  @LocalStorage()
  ipLocation: IpLocationData = {cityId: null, countryCode: null};

  protected  onboardingSplitName: OnboardingVersion = "EventOnboarding"
  interestingCompanyData: TalentEventInterestingCompanyData[] = []

  /**this gets called by the step-components instead of router. Its purpose is to be able to do the transition-animation in the main component.
   * The subject tells the main component that navigation is starting, so it can start the animation. then we just route.
   * **/
      //Should be changed in cooperation with marketing. consider documenting it.
  analyticsEventNameMapping = [
    {stepPath: 'contact-data', eventName: 'ContactData'},
    {stepPath: 'cv', eventName: 'CvUpload'},
    {stepPath: 'obState', eventName: 'ObState'},
    {stepPath: 'subject', eventName: 'SelectSubject'},
    {stepPath: 'preferences', eventName: 'MatchingData'},
    {stepPath: 'coach-intent', eventName: 'Coach'},
    {stepPath: 'coach-data', eventName: 'CoachData'},
    {stepPath: 'password', eventName: 'Password'},
  ]

  //Decided not to store this because if user comes back a lot later it might be illegal to pre-check the acceptance-box.
  acceptedPrivacy = false;
  routingStart = new Subject()

  eventData: PublicEventData
  constructor(
      private router: Router,
      private onboardingResource: TalentEventOnboardingResource,
      private authService: TalentAuthService,
      private deviceService: DeviceDetectorService,
      private analyticsService: AnalyticsService,
      private localStorageService: LocalStorageService,
      private requestInfoResource: RequestInfoResource,
  ) { }

  getEvent(): Promise<any> {
    return this.onboardingResource.getEventDetails(this.cachedInputs.eventId).then(result => {
      this.eventData = result
    })
  }

  getEventIdForUser(): Promise<number> {
    return new Promise<number>(resolve => {
      this.onboardingResource.getEventIdForUser().then(result => {resolve(result.value)})
    })
  }

  createEventOnboardingData(): TalentEventOnboardingCreationData {
    return <TalentEventOnboardingCreationData>{
      emailAddress: this.cachedInputs.email,
      firstName: this.cachedInputs.firstName,
      lastName: this.cachedInputs.lastName,
      phoneNumber: this.cachedInputs.phoneNumber,
      subjectId: this.cachedInputs.subjectId,
      studyEndDate: this.cachedInputs.studyEndDate,
      forcedFieldsOfActivity: this.cachedInputs.fieldIds,
      preferredFieldIds: this.cachedInputs.fieldIds? Array.from(new Set(this.cachedInputs.markedAsInterestingFieldIds.concat(this.cachedInputs.fieldIds)).values()) : this.cachedInputs.markedAsInterestingFieldIds,
      preferredCityId: this.cachedInputs.preferredCityId,
      searchMode: this.cachedInputs.searchMode,
      onboardingVersion: this.onboardingSplitName,
      ipLocation: this.ipLocation,

      browser: this.deviceService.browser,
      deviceType:this.deviceService.deviceType,
      browserVersion: this.deviceService.browser_version,
      browserLanguage: navigator.language,

      gclid: this.analyticsService.trafficOrigin.gclid,
        fbclid: this.analyticsService.trafficOrigin.fbclid,
      utmCampaign: this.analyticsService.trafficOrigin.utmCampaign,
      utmContent: this.analyticsService.trafficOrigin.utmContent,
      utmMedium: this.analyticsService.trafficOrigin.utmMedium,
      utmSource: this.analyticsService.trafficOrigin.utmSource,
      utmTerm: this.analyticsService.trafficOrigin.utm_term,

        consent_facebook_oc: this.localStorageService.gdprConsent.consent_facebook_oc,
        consent_google_oc: this.localStorageService.gdprConsent.consent_google_oc
    }
  }

  createAccount():Promise<any>{
      return this.onboardingResource.createTalentAccountAndLogin(
              this.createEventOnboardingData(),
              this.cachedInputs.eventId
          ).then(result =>{
          if (result?.accessToken) {
              this.authService.setToken(result.accessToken, result.refreshToken)
              this.acceptedPrivacy = true;
              this.authService.applyLogin("LoginSuccessful")
          }
  })
  }

  getOnboardingType():OnboardingType{
      return "Standard"
  }

  //pass an object that contains properties you want to update on CachedInputs. Keys have to match.
  updateInputs(data: EventCachedOBInputs){
    for(var property in this.cachedInputs){
      if(data[property] == null){
        data[property] = this.cachedInputs[property]
      }
    }
    this.cachedInputs = data;
  }

  delayedRouting(target:string, stepCompletionContext:string = "next"){
    this.routingStart.next()

    this.reportCurrentStepCompletion(stepCompletionContext)

    //This delay has to be the same as the opacity-transition duration in matze-ob-component.scss. It's purpose is to support the animation.
    delay(200).then(()=> {
      this.router.navigate([target]).then( () =>{
        this.reportCurrentStepPageview()
      })
    })
  }

  reportCurrentStepCompletion(stepCompletionContext: string) {
    let completedStepName = this.getStepNameForRoute(this.router.url);
    this.analyticsService.reportOnboardingStepCompletion(this.getOnboardingType(), this.onboardingSplitName, completedStepName, stepCompletionContext)
  }

  //Gets called by the first step of the onboarding
  reportCurrentStepPageview(){
    let completedStepName = this.getStepNameForRoute(this.router.url);
    this.analyticsService.reportOnboardingVirtualPageView(this.getOnboardingType(), completedStepName,this.onboardingSplitName)
  }

  updateStudyAndPreferencesForTalent(): Promise<any> {
    return this.onboardingResource.updateStudyAndPreferencesForTalent(this.createEventOnboardingData())
  }

  updateDistributionCriteriaForCoachRequest(data: TalentCoachRequestDistributionData): Promise<any> {
    return this.onboardingResource.updateDistributionCriteriaForCoachRequest(data)
  }

  finalizeTalentOnboardingForTalent(): Promise<any> {
    return this.onboardingResource.finalizeTalentOnboardingForTalent()
  }


  routeToPlattform(stepCompletionContext: string = 'next') {
    this.reportCurrentStepCompletion(stepCompletionContext)

    this.analyticsService.reportTalentQualifiedForCoachToDataLayerOld(
        this.onboardingSplitName,
        this.cachedInputs.wantsCoach,
        this.cachedInputs.cvUploaded,
        this.cachedInputs.studyEndDate
    )

    let redirectPath = 'home'
    this.router.navigateByUrl(redirectPath)
  }

  private getStepNameForRoute(route:string):string{
    let  lastPathSegment = route.split('/')[route.split('/').length -1]

    if(lastPathSegment.indexOf('?') > -1) {
      lastPathSegment = lastPathSegment.split("?")[0]
    }

    let eventName = this.analyticsEventNameMapping.find(e => e.stepPath == lastPathSegment)?.eventName
    if(!eventName || eventName === '') {
      console.error(`${lastPathSegment}: ${eventName}`)
      bugsnag.notify("There is an Onboarding-Step with last URL-Segment " +lastPathSegment +" that cannot be mapped to an analytics eventName (" + eventName + "). This will likely break the tracking")
      return undefined
    }
    return eventName
  }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  setCV(cv: File) {
    this.cvName = cv.name
    this.getBase64(cv).then(res => {
      this.storedCv = res
    })
  }

  getCv(): File {
    if (!this.storedCv) return null
    return this.dataURLtoFile(this.storedCv, this.cvName)
  }

  async getUserLocationIfConsented(): Promise<IpLocationData | null> {
    if (this.localStorageService.getGDPRConsent().consent_ip_api) {
      this.ipLocation = await this.requestInfoResource.getRequestLocation();
      return this.ipLocation;
    } else {
      return null;
    }
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }


}
