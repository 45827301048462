import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoachingOnboardingComponent} from './coaching-onboarding/coaching-onboarding.component';
import {CoButtonBarComponent} from './co-button-bar/co-button-bar.component';
import {CoCoachComponent} from './co-coach/co-coach.component';
import {CoCoachDataComponent} from './co-coach-data/co-coach-data.component';
import {CoContactDataComponent} from './co-contact-data/co-contact-data.component';
import {CoCvUploadComponent} from './co-cv-upload/co-cv-upload.component';
import {CoEveProgressComponent} from './co-eve-progress/co-eve-progress.component';
import {CoMatchingDataComponent} from './co-matching-data/co-matching-data.component';
import {CoPasswordComponent} from './co-password/co-password.component';
import {CoSelectSubjectComponent} from './co-select-subject/co-select-subject.component';
import {CoStepHeadlineComponent} from './co-step-headline/co-step-headline.component';
import {CoWelcomeComponent} from './co-welcome/co-welcome.component';
import {RouterModule, Routes} from "@angular/router";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {UtilityModule} from "utility";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";
import { CoObStateComponent } from './co-ob-state/co-ob-state.component';


const appRoutes: Routes = [
    {path: 'onboarding/coach/obState', component: CoObStateComponent, canActivate: [OnboardingAuthGuardService]},
    {
        path: 'onboarding/coach', component: CoachingOnboardingComponent,
        children: [
            {path: 'welcome', component: CoWelcomeComponent, canActivate: [OnboardingAuthGuardService]},
            {path: 'coach-intent', component: CoCoachComponent, canActivate: [OnboardingAuthGuardService]},
            {path: 'coach-data', component: CoCoachDataComponent, canActivate: [OnboardingAuthGuardService]},
            {path: 'contact-data', component: CoContactDataComponent, canActivate: [OnboardingAuthGuardService]},
            {path: 'password', component: CoPasswordComponent},
            {path: 'cv', component: CoCvUploadComponent, canActivate: [OnboardingAuthGuardService]},
            {path: 'subject', component: CoSelectSubjectComponent},
            {path: 'preferences', component: CoMatchingDataComponent},
            {path: 'password', component: CoPasswordComponent}
        ]
    }];

@NgModule({
    declarations: [
        CoachingOnboardingComponent,
        CoButtonBarComponent,
        CoCoachComponent,
        CoCoachDataComponent,
        CoContactDataComponent,
        CoCvUploadComponent,
        CoEveProgressComponent,
        CoMatchingDataComponent,
        CoPasswordComponent,
        CoSelectSubjectComponent,
        CoStepHeadlineComponent,
        CoWelcomeComponent,
        CoObStateComponent
    ],
    imports: [
        MatDialogModule,
        CommonModule,
        UtilityModule,
        RouterModule.forChild(appRoutes),
        HidLibCloneModule
    ]
})
export class CoachingOnboardingModule {
}
