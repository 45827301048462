<div class="company-card-wrapper">
    <div class="hero-image"
         [ngStyle]="primeCompany ?
         {'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + companyData.id + '/companyProfile/heroImage)'}
         : {}"
         fxLayout="row" fxLayoutAlign="start center">
        <div class="logo-container">
            <div class="company-logo"
                 [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + companyData.id + '/companyLogo)'}"
            ></div>
        </div>
    </div>

    <div
            fxLayout="column" fxLayoutAlign="space-between start"
            [ngClass]="{'prime-info-card': primeCompany}"
            class="company-info width-100">
        <div class="width-100" fxLayout="column" fxLayoutAlign="start start">
            <div fxLayout="column" fxLayoutAlign="start start" class="width-100">
                <p *ngIf="primeCompany" class="prime-tag">Premium-Unternehmen</p>
                <div *ngIf="primeCompany" class="prime-divider"></div>
                <h3 [ngClass]="{'white-font': primeCompany}">{{companyData.companyName}}</h3>
                <!--i [ngClass]="{'white-font': primeCompany}" *ngIf="primeCompany" class="mdi mdi-star"></i-->
            </div>
            <div *ngIf="companyData.location && companyData.location.length > 0"
                 fxLayout="row" fxLayoutAlign="start center" class="padding-vertical">
                <i class="mdi mdi-map-marker"></i>
                <p>{{companyData.location}}</p>
            </div>
            <!--div fxLayout="row no-wrap" fxLayoutAlign="start start">
                <div class="field-tag" [ngClass]="{'dark-yellow-font': primeCompany}">Softwareentwicklung</div>
                <div class="field-tag" [ngClass]="{'dark-yellow-font': primeCompany}">Marketing</div>
            </div-->
            <div class="center-content padding-vertical width-100">
                {{companyData.availableJobs}} Jobs verfügbar
            </div>
        </div>

        <div class="center-content width-100">
            <button [ngClass]="{'white-font': primeCompany, 'white-border': primeCompany}"
                    class="more-info-button" mat-stroked-button (click)="navigateToCompanyPage(companyData.id)">mehr erfahren</button>
        </div>
    </div>
</div>