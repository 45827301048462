import {Component, OnDestroy, OnInit} from '@angular/core';
import {TalentHomeResource} from "utility";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";

@Component({
  selector: 'matching-home-mobile-big',
  templateUrl: './matching-home-mobile-big.component.html',
  styleUrls: ['./matching-home-mobile-big.component.scss']
})
export class MatchingHomeMobileBigComponent implements OnInit, OnDestroy {

  loadingSubject = new BehaviorSubject<boolean>(true)
  matchesCount: number
  redirectPath: string = "/talent/positions"

  matchesDisplayCount: string

  constructor(
      private talentHomeResource: TalentHomeResource,
      public homeService: HomeService,
      public router: Router,
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getMatchesCount()
  }

  getMatchesCount() {
    this.loadingSubject.next(true)
    this.talentHomeResource.getMatchesCount().then(count => {
      this.matchesCount = count.value
      this.matchesCount > 99? this.matchesDisplayCount = '99+' : this.matchesDisplayCount = this.matchesCount.toString()
      this.loadingSubject.next(false)
    })
  }

  ngOnDestroy(): void {
  }

}
