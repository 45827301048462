<div class="opened-dropdown-wrapper" (click)="clickHandler($event)">
    <div class="relevant-fields section field-selected-list">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
            <div *ngFor="let field of relevantFieldsWithoutInterest.slice(0, showFullFieldsList? relevantFieldsWithoutInterest.length : 6 );"
                 (click)="addFieldToPreferencesAndStartHybridSearch(field)"
                 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="recommended-field">
                <div style="width: 100%; text-align: center">{{field.name}}</div>
            </div>

            <div *ngIf="!showFullFieldsList && relevantFieldsWithoutInterest.length > 6" (click)="showFullFieldsList = true" fxLayout="row" fxLayoutAlign="center center" class="chevron-down-wrapper">
                <span style="margin-right: 10px">Mehr Vorschläge ({{relevantFieldsWithoutInterest.length - 6}})</span> <i class="mdi mdi-chevron-down"></i>
            </div>
        </div>
    </div>

    <mat-divider style="margin: 20px 0px"></mat-divider>

    <field-search-input
            [ngClass]="{'mobile-dropdown': true}"
            style="width: 100%"
            label="Bereich hinzufügen"
            displayProperty="name"
            (modelChange)="addFieldToPreferencesAndStartHybridSearch($event)"
    >
    </field-search-input>
</div>
