import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TalentAccountResource, TalentHomeResource, TalentHomeStatusData} from "utility";
import {PropagationService} from "../services/propagation.service";
import {HomeService} from "./home-utils/home.service";
import {BehaviorSubject, fromEvent, Subscription} from "rxjs";
import {MediaService} from "../services/inno-utils/media.service";
import {TalentAuthService} from "../services/talent.auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  loadingSubject = new BehaviorSubject<boolean>(true)
  dashboardStatus: TalentHomeStatusData
  screenSizeChanged$: Subscription
  matchingIntervalId: number = null
  isGraduate: boolean

  isMobile: boolean = false
  constructor(
      private talentHomeResource: TalentHomeResource,
      private propagationService: PropagationService,
      private homeService: HomeService,
      private mediaService: MediaService,
      private ref: ChangeDetectorRef,
      private talentAccountResource: TalentAccountResource,
      public authService: TalentAuthService
  ) { }

  ngOnDestroy(): void {
        this.screenSizeChanged$?.unsubscribe()
    }

  ngOnInit(): void {

    this.talentAccountResource.createTalentFirstVisitedDashboardLogEntryIfNecessary()
    this.checkScreenSize()
    this.screenSizeChanged$ = fromEvent(window, 'resize').subscribe(() => this.checkScreenSize())

    this.homeService.registerSub(this.loadingSubject)

    this.propagationService.cvUploaded.subscribe(next => {
      this.getDashboardStatus()
    })

    this.homeService.homeLoaded.subscribe(next => {
      if (!next) return
      this.animateChildren('home-grid-desktop')
      this.animateChildren('home-grid-mobile')
    })

    this.getDashboardStatus()

    this.isGraduate = this.authService.getAccountInfo().isGraduate
  }

  checkScreenSize() {
    this.isMobile = this.mediaService.getMaxWidthQuery(960).matches
  }

  animateChildren(elementId: string) {
    let children = document.getElementById(elementId)?.children
    if (!children) return
    let maxIndex = children.length-1

    let i = 0
    let intervalId = setInterval(() => {
      children.item(i).classList.add('tile-animation')
      i++
      if (i > maxIndex) clearInterval(intervalId)
    }, 90)
  }

  getDashboardStatus() {
    this.talentHomeResource.getDashboardStatus().then(result => {
      this.dashboardStatus = result
      this.ref.detectChanges()
      this.loadingSubject.next(false)
    })
  }

}
