import {Component, Input, OnInit} from '@angular/core';
import {PositionChipData} from "utility";

@Component({
  selector: 'position-usp-chip',
  templateUrl: './position-usp-chip.component.html',
  styleUrls: ['./position-usp-chip.component.scss']
})
export class PositionUspChipComponent implements OnInit {

  @Input() chip: PositionChipData
  @Input() mobileDetailUsps: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
