import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NotificationData, NotificationResource, TalentNotificationResource, UtilityService} from "utility";
import {Router} from "@angular/router";
import {MediaService} from "../services/inno-utils/media.service";
import {LegacyPageEvent as PageEvent} from "@angular/material/legacy-paginator";

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public talentNotificationData: NotificationData[];
  public isLoaded = false;

  //pagination
  public pageSize: number = 5;
  public pageIndex: number = 0;
  public totalPages: number;
  public totalElements: number;

  constructor(
    public dialog: MatDialog,
    private talentNotificationResource: TalentNotificationResource,
    private utilityService: UtilityService,
    private notificationResource: NotificationResource,
    private router: Router,
    public mediaService: MediaService
  ) {
  }

  ngOnInit() {
    this.loadTalentNotifications();
    this.notificationResource.setUserNotificationsToSeen();
  }

  loadTalentNotifications() {
    this.talentNotificationResource.getAllNotificationsForTalent({page: this.pageIndex, pageSize: this.pageSize})
      .then(result => {
        this.totalPages = result.totalPages;
        this.totalElements = result.totalElements;
        this.talentNotificationData = result.content
        this.isLoaded = true;
      })
  }

  handlePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadTalentNotifications();
  }

  navigateToView(notificationType: string) {
    switch (notificationType) {
      case 'TalentCvUploadReminderNotification':
        this.router.navigate(['/talent/profile'])
        break;
      case 'TalentCoachAssignedNotification':
      case 'TalentCoachChangedNotification':
      case 'TalentCanAssessCareerCoachNotification':
      case 'TalentCoachUnassignedNotification':
        this.router.navigate(['/talent/agent'])
        break;
      case 'TalentEvaluationCreationNotification':
      case 'TalentEvaluationFinishedNotification':
      case 'TalentEvaluationApprovedNotification':
        this.router.navigate(['/talent/agent'], {queryParams: {tab: 1}});
        break;
      case 'TalentNewPositionProposalsNotification':
      case 'TalentNewPositionsInFeedNotification':
      case 'TalentOpenPositionProposalsNotification':
        this.router.navigateByUrl('/talent/positions')
        break;
      case 'TalentCoachRecommendedPositionNotification':
      case 'TalentPositionProposalRecommendedNotification':
      case 'TalentPositionRecommendedNotification':
      case 'TalentAdminRecommendedPrimePositionNotification':
        this.router.navigateByUrl('/talent/proposals/recommendations')
            break;
      case 'TalentFailedApplicationNotification':
      case 'TalentFailedApplicationJobFoundNotification':
      case 'TalentFailedApplicationPositionDeletedNotification':
        this.router.navigate(['/talent/applications'], {queryParams: {tab: 1}})
        break;
      case 'TalentOpenBookmarkedPositionProposalsNotification':
        this.router.navigateByUrl('/talent/proposals/bookmarks')
        break;
      case 'TalentGotHiredNotification':
      case 'TalentAdditionalApplicationDocumentsRequiredNotification':
      case 'TalentIsInterestingNotification':
        this.router.navigate(['/talent/applications'])
        break;
      default: this.router.navigateByUrl('/talent/positions')
    }
  }

  getNavigationTooltip(notificationType: string): string {
    switch (notificationType) {
      case 'TalentCvUploadReminderNotification':
        return 'Zum Profil';
      case 'TalentCoachAssignedNotification':
        return 'Zum Karrierecoach';
      case 'TalentNewPositionProposalsNotification':
        return 'Zu den Stellenvorschlägen';
      case 'TalentFailedApplicationNotification':
      case 'TalentFailedApplicationJobFoundNotification':
      case 'TalentFailedApplicationPositionDeletedNotification':
        return 'Zum Archiv';
      case 'TalentGotHiredNotification':
      case 'TalentIsInterestingNotification':
        return 'Zu den Bewerbungen';
      case 'TalentEvaluationCreationNotification':
      case 'TalentEvaluationFinishedNotification':
      case 'TalentEvaluationApprovedNotification':
        return 'Zur AECdisc®-Potenzialanalyse'
      default: return 'Zu den Stellenvorschlägen'
    }
  }
}
