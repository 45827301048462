import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PositionsComponent} from './positions.component';
import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "../services/auth-guard.service";
import {UtilityModule} from "utility";
import {PositionsDetailsComponent} from './positions-details/positions-details.component';
import {
    PositionsPreviewCardComponent
} from './positions-previews/positions-preview-card/positions-preview-card.component';
import {PositionsFavouriteButtonComponent} from './positions-favourite-button/positions-favourite-button.component';
import {PositionsCompanyImageComponent} from './positions-company-image/positions-company-image.component';
import {PositionsChipsComponent} from './positions-chips/positions-chips.component';
import {PositionsSingleChipComponent} from './positions-chips/positions-single-chip/positions-single-chip.component';
import {
    PositionsPreviewsPaginatorComponent
} from './positions-previews/positions-previews-paginator/positions-previews-paginator.component';
import {
    PositionsDetailsBenefitCardComponent
} from './positions-details/positions-details-benefits/positions-details-benefit-card/positions-details-benefit-card.component';
import {
    PositionsDetailsCompanyComponent
} from './positions-details/positions-details-company/positions-details-company.component';
import {
    PositionsDetailsBenefitsComponent
} from './positions-details/positions-details-benefits/positions-details-benefits.component';
import {
    PositionsDetailsInfoChipsComponent
} from './positions-details/positions-details-info-chips/positions-details-info-chips.component';
import {
    PositionsDetailsApplyButtonsComponent
} from './positions-details/positions-details-apply-buttons/positions-details-apply-buttons.component';
import {PositionsSearchbarFilter} from './positions-searchbar/positions-searchbar-filter/positions-searchbar-filter';
import {
    FilterLocationFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-location-form/filter-location-form.component';
import {
    FilterProfessionFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-profession-form/filter-profession-form.component';
import {
    FilterOtherFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-other-form/filter-other-form.component';
import {
    PositionsChipsSkeletonComponent
} from '../utility/positions-chips-skeleton/positions-chips-skeleton.component';
import {
    PositionsDetailsSkeletonComponent
} from './positions-details/positions-details-skeleton/positions-details-skeleton.component';
import {
    MobileFilterWrapperComponent
} from './positions-searchbar/positions-searchbar-filter/mobile-filter-wrapper/mobile-filter-wrapper.component';
import {
    FilterSelectableChipComponent
} from "./positions-searchbar/positions-searchbar-filter/filter-selectable-chip/filter-selectable-chip.component";
import {PositionSearchBarComponent} from "./positions-searchbar/position-search-bar/position-search-bar.component";
import {PositionsSearchOptionsComponent} from "./positions-searchbar/positions-search-options.component";
import {
    FilterSearchInputDropdownComponent
} from './positions-searchbar/positions-searchbar-filter/filter-search-input-dropdown/filter-search-input-dropdown.component';
import {PositionsPreviewsComponent} from "./positions-previews/positions-previews.component";
import {PositionUspChipsComponent} from './positions-details/position-usp-chips/position-usp-chips.component';
import {
    PositionUspChipComponent
} from './positions-details/position-usp-chips/position-usp-chip/position-usp-chip.component';
import {
    PositionsWrapperPreviewDetailsComponent
} from "../utility/positions-wrapper-preview-details/positions-wrapper-preview-details.component";
import {DynamicFontDirective} from "./dynamic-font.directive";

const appRoutes: Routes = [
    {
        path: 'talent/positions',
        component: PositionsComponent,
        canActivate: [AuthGuardService]
    }
    ]

@NgModule({
    declarations: [
        DynamicFontDirective,
        PositionsComponent,
        PositionsDetailsComponent,
        PositionsPreviewCardComponent,
        PositionsSearchOptionsComponent,
        PositionSearchBarComponent,
        PositionsFavouriteButtonComponent,
        PositionsCompanyImageComponent,
        PositionsChipsComponent,
        PositionsSingleChipComponent,
        PositionsPreviewsPaginatorComponent,
        PositionsDetailsBenefitCardComponent,
        PositionsDetailsCompanyComponent,
        PositionsDetailsBenefitsComponent,
        PositionsDetailsInfoChipsComponent,
        PositionsDetailsApplyButtonsComponent,
        PositionsSearchbarFilter,
        FilterLocationFormComponent,
        FilterProfessionFormComponent,
        FilterOtherFormComponent,
        PositionsDetailsSkeletonComponent,
        MobileFilterWrapperComponent,
        FilterSelectableChipComponent,
        FilterSearchInputDropdownComponent,
        PositionsPreviewsComponent,
        PositionUspChipsComponent,
        PositionUspChipComponent,
        PositionsWrapperPreviewDetailsComponent],

    imports: [
        CommonModule,
        UtilityModule,
        RouterModule.forChild(appRoutes),
    ],
    exports: [
        PositionsPreviewsComponent,
        PositionsPreviewCardComponent,
        PositionsDetailsComponent,
        PositionsWrapperPreviewDetailsComponent,
        DynamicFontDirective
    ]
})

export class PositionsModule {
}
