import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {LocalStorage} from "ngx-store";
import {SearchFilterData} from "utility";


export class SessionTracking {
    constructor(
        public utm_source: string,
        public utm_content: string,
        public utm_medium: string,
        public utm_campaign: string,
        public occurred: Date,
        public gclid?: string,
        public cutm_adacc?: string
    ) {
    }
}

export class GDPRConsent {
    constructor(
        public initial: boolean,
        public consent_ga: boolean,
        public consent_linkedIn: boolean,
        public consent_facebook: boolean,
        public consent_pinterest: boolean,
        public consent_google_ads: boolean,
        public consent_google_oc: boolean,
        public consent_facebook_oc: boolean,
        public consent_piwik: boolean,
        public consent_tiktok: boolean,
        public consent_ip_api: boolean,
        public consent_hotjar: boolean
    ) {
    }
}


//TODO tthe gdprconsentmanagment here is a mashup between storing cookies, redundantly using local storage for no reason and managing the current consent within the running application. See HID-Frontend for template how to do it properly
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() {
    }

    @LocalStorage() sessionTracking: SessionTracking[] = [];

    @LocalStorage() matchingProgressStep1Value: number;
    @LocalStorage() matchingProgressStep2Value: number;

    /** initial tells us if the user was asked already */
    @LocalStorage() gdprConsent: GDPRConsent = {
        initial: true,
        consent_ga: false,
        consent_linkedIn: false,
        consent_facebook: false,
        consent_pinterest: false,
        consent_google_ads: false,
        consent_google_oc: false,
        consent_facebook_oc: false,
        consent_piwik: false,
        consent_tiktok: false,
        consent_ip_api: false,
        consent_hotjar: false
    };

    @LocalStorage() searchFilterData: SearchFilterData;
    @LocalStorage() pageNum: number;

    setSearchFilterData(data: SearchFilterData) {
        this.searchFilterData = data
    }

    getSearchFilterData() : SearchFilterData {
        return this.searchFilterData
    }

    setPageNum(pageNum: number) {
        this.pageNum = pageNum
    }

    getPageNum(): number {
        return this.pageNum
    }

    updateGDPRConsent(consent: GDPRConsent) {
        consent.initial = false;

        //handle tiktok
        if (consent.consent_tiktok == true && !this.gdprConsent.consent_tiktok && environment.production) {
            this.addTikTokScriptToHead()
        }
        //if tiktok is being disabled, remove the script tag
        if (consent.consent_tiktok == false && this.gdprConsent.consent_tiktok == true) {
            this.removeTikTokScriptFromHead();
        }

        //handle hotjar
        //if hotjar is being disabled, remove the script tag
        if(consent.consent_hotjar == true && !this.gdprConsent.consent_hotjar){
            this.addHotjarScriptToHead()
        }
        if(consent.consent_hotjar == false && this.gdprConsent.consent_hotjar == true){
            this.removeHotjarScriptFromHead();
        }

        this.gdprConsent = consent;
    }

    getGDPRConsent(): GDPRConsent {
        return this.gdprConsent;
    }

    //TODO probably deprecated
    getLastSessionInfoUpdate(): SessionTracking {
        if (!this.sessionTracking || !this.sessionTracking.length || !(this.sessionTracking instanceof Array)) {
            return null
        }
        return this.sessionTracking[this.sessionTracking.length - 1]
    }

    //The tiktok script in question cant be injected via GTM for some reason, so we need to insert in / remove from head depending on the users consent. There are two seperate scripts linked to different tiktok accounts or something.
    addTikTokScriptToHead() {
        //Add Script 1
        let node = document.createElement('script'); // creates the script tag
        node.src = '../../assets/scripts/TikTokAnalyticsScript.js'; // sets the source
        node.type = 'text/javascript';
        node.async = true; // makes script run asynchronously
        node.charset = 'utf-8';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);

    }

    removeTikTokScriptFromHead() {
        //It gets the ID in the TikTokAnalyticsScript.js
        let node = document.getElementById("tikTokHeadScript")
        if (node) {
            node.parentNode.removeChild(node);
        }
    }

    //add or remove hotjar script in head based on consent in cookies

    addHotjarScriptToHead(){
        //Add Script 1
        let node = document.createElement('script'); // creates the script tag
        node.src = '../../assets/scripts/HotjarScript.js'; // sets the source
        node.type = 'text/javascript';
        node.async = true; // makes script run asynchronously
        node.charset = 'utf-8';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    removeHotjarScriptFromHead(){
        let node = document.getElementById("hotjarHeadScript")
        if(node) {
            node.parentNode.removeChild(node);
        }
    }

}
