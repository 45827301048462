<div class="width-100 flex center">
    <div class="flex row start content-width flex-1">
        <div
                (mouseenter)="toggleHover(true)"
                (mouseleave)="toggleHover(false)"
                id="previews"
                [ngClass]="{'scrollbar-hidden': !feedIsHovered, 'scrollbar-visible': feedIsHovered}"
                class="scrollable-container previews-wrapper flex row " #previews>
            <div class="flex column start width-100">
                <ng-content select="[preview]"></ng-content>

                <app-positions-previews
                        class="width-100"
                        [loading]="loading.value"
                        [origin]="origin"
                        [page]="page"
                        [totalPages]="totalPages"
                        (paginatorNextIndex)="paginatorNextIndex.next($event); scrollToTop()"
                        [positions]="positions">
                    <ng-content select="[postview]" postview></ng-content>
                </app-positions-previews>
            </div>
            <div *ngIf="!mediaService.getMaxWidthQuery(960).matches && displayScrollBlocker() && !feedIsHovered" class="scrollbar-blocker"></div>
        </div>

        <div class="details-wrapper" id="detailsWrapper" *ngIf="!mediaService.getMaxWidthQuery(960).matches">
            <div #detailsContainer></div>
        </div>
    </div>
</div>
