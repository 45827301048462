<mat-form-field color="accent" [appearance]="appearance" class="width-100 location-input">
    <mat-label *ngIf="showLabel">{{label}}</mat-label>
    <mat-select [formControl]="control" [disabled]="disabled">
        <loading-spinner [loadingVar]="loadingOptions">
            <div style="height: 200px; width: 100%" *ngIf="loadingOptions"></div>
        <mat-option>
            <ngx-mat-select-search [formControl]="searchControl" [placeholderLabel]="'Name oder PLZ'"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let city of options" [value]="city.id">
            {{city.name}}
        </mat-option>
        </loading-spinner>
    </mat-select>
</mat-form-field>




