import {Component, Input, OnInit} from '@angular/core';
import {TalentCoachData} from "utility";

@Component({
  selector: 'talent-agent-contact',
  templateUrl: './talent-agent-contact.component.html',
  styleUrls: ['./talent-agent-contact.component.scss']
})
export class TalentAgentContactComponent implements OnInit {

  @Input() talentCoachData: TalentCoachData;

  constructor() { }

  ngOnInit(): void {
  }

}
