import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountResource, UniSignAccountResource, UtilsService} from "utility";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {S2Fax2OnboardingService} from "../s2-fax2-onboarding.service";

@Component({
  selector: 'app-s2-fax2-o-password',
  templateUrl: './s2-fax2-o-password.component.html',
  styleUrls: ['./s2-fax2-o-password.component.scss']
})
export class S2Fax2OPasswordComponent implements OnInit {

    form: FormGroup

    constructor(
        private utilsService: UtilsService,
        private authService: TalentAuthService,
        public router: Router,
        private uniSignAccountResource: UniSignAccountResource,
        private accountResource: AccountResource,
        private obService: S2Fax2OnboardingService
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            password: new FormControl<string>(null, [Validators.required, this.utilsService.requirementsPasswordMatched(2, 6)])
        });
    }

    ngOnDestroy() {
    }

    forceValidation = new Subject;
    progressing = new Subject()
    progress() {
        this.forceValidation.next()
        if (!this.form.valid) return

        this.progressing.next()

        let password = this.form.controls.password.value
        //we dont need to wait for the result, we can just move on.
        this.uniSignAccountResource.changeOwnPassword({newPassword: password})
        this.accountResource.finalizeOnboarding().then(() => {
                this.authService.updateOnboardingCompleted(true).then(() => {
                    this.obService.delayedRouting("onboarding/s2fax2/cv")
                })
            }
        )
    }

    containsSmallLetter(): boolean {
        if (this.form.controls.password.value == null) return false;
        return (/[a-z]/.test(this.form.controls.password.value));
    }

    containsBigLetterOrNumber() {
        if (this.form.controls.password.value == null) return false;
        return (/[A-Z0-9]/.test(this.form.controls.password.value));
    }

}
