import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {LabelData, PublicSubjectResource, TalentOnboardingResource} from "utility";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {debounceTime} from "rxjs/operators";
import {ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";

@Component({
  selector: 'app-cpd-o-study-search',
  templateUrl: './cpd-o-study-search.component.html',
  styleUrls: ['./cpd-o-study-search.component.scss']
})
export class CpdOStudySearchComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() control: AbstractControl
    @Output() onSelect: EventEmitter<any> = new EventEmitter()

    @ViewChild('chipsWrapper') chipsWrapper: ElementRef

    selectedDegree: any = 'all'
    degreeTypes: string[]

    searchResults: LabelData[]
    mostFrequentlyUsedSubjects: LabelData[]
    subjectsExtractedFromCV: LabelData[]
    loadingOptions: boolean = false

    searched: boolean = false;
    searchedOneTime: boolean = false;
    showResults: boolean = false;
    chipsScrolledRight: boolean = false;
    chipsScrolledLeft: boolean = true;
    showSpinner: boolean = false;
    readonly searchControl = new FormControl<string>('')

    requestCount: number = 0
    subscription: Subscription
    oldvalue: string = ''

    constructor(
        public router: Router,
        private publicSubjectResource: PublicSubjectResource,
        private onboardingService: ConfirmProfileDetailsOnboardingService
    ) { }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe()
    }

    ngOnInit(): void {

        this.getAcademicTitles()

        this.subscription = this.searchControl.valueChanges.pipe(debounceTime(600)).subscribe(next => {
            if (this.oldvalue == next) return
            this.oldvalue = next
            this.loadSuggestions()
        })

        this.showResults = true

        if (this.onboardingService.cachedInputs.subjectId && this.onboardingService.cachedInputs.cvUploaded) {
            this.loadSubject(this.onboardingService.cachedInputs.subjectId)
        } else {
            this.loadMostFrequentlyUsedSubjects(this.control?.value)
        }



    }


    handleChipsScroll(e?: Event) {
        this.chipsScrolledRight = ( this.chipsWrapper.nativeElement.scrollLeft + this.chipsWrapper.nativeElement.clientWidth  +1) >= this.chipsWrapper.nativeElement.scrollWidth
        this.chipsScrolledLeft = this.chipsWrapper.nativeElement.scrollLeft == 0
    }

    scrollChipsRight() {
        this.chipsWrapper.nativeElement.scrollBy({ behavior: 'smooth', top: 0, left: 100})
    }

    scrollChipsLeft() {
        this.chipsWrapper.nativeElement.scrollBy({ behavior: 'smooth', top: 0, left: -100})
    }

    selectDegree(degree: string) {
        this.selectedDegree = degree
        this.loadSuggestions()
    }

    getAcademicTitles() {
        this.publicSubjectResource.getAcademicTitles().then(result => {
            this.degreeTypes = result
        })
    }

    loadSuggestions(forcedIds?: number[]) {
        if ((!this.searchControl.value || !this.searchControl.value?.length) && !forcedIds) {
            if (this.onboardingService.cachedInputs.subjectId)
                this.searchResults = this.subjectsExtractedFromCV
            else
                this.searchResults = this.mostFrequentlyUsedSubjects
        }

        if ((this.searchControl.value?.length < 3 || !this.searchControl.value) && !forcedIds) return

        if (this.searchControl.value?.length > 2 && !forcedIds) this.searchedOneTime = true

        let localRequestCount = ++this.requestCount
        this.loadingOptions = true;
        this.showSpinner = true;
        this.publicSubjectResource.getSubjectsLabelData({
            degreeType: this.selectedDegree != 'all'? this.selectedDegree : null,
            q: this.searchControl.value,
            page: 0,
            pageSize: forcedIds? 1 : 20,
            forcedIds: forcedIds
        }).then(result => {
            if (this.requestCount == localRequestCount) {
                this.showResults = true;
                if (forcedIds) result.content = result.content.filter(r => r.id == forcedIds[0])
                this.searchResults = result.content
                this.searched = true
                this.loadingOptions = false;
                this.showSpinner = false;
            }
        });
    }

    loadMostFrequentlyUsedSubjects(forcedId?: number) {
        let forcedSubjectId = forcedId? [forcedId] : null
        this.publicSubjectResource.getMostFrequentlyRepresentedSubjects({forcedIds: forcedSubjectId}).then(result => {
            this.mostFrequentlyUsedSubjects = result
            this.searchResults = result
        })
    }

    loadSubject(subjectId: number) {
        this.publicSubjectResource.getSingleSubjectById(subjectId).then(res => {
            let resultList = [res]
            this.searchResults = resultList
            this.subjectsExtractedFromCV = resultList
        })
    }

    setStudyControl(r) {
        this.showResults = false
        this.control.setValue(r.id)
        this.searchControl.setValue(r.name, {emitEvent: false})
        this.onSelect.emit(true)
    }
}
