import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, Validators} from "@angular/forms";
import {QuestionFormat} from "utility";

@Component({
  selector: 'text-line-question-input',
  templateUrl: './text-line-question-input.component.html',
  styleUrls: ['./text-line-question-input.component.scss']
})
export class TextLineQuestionInputComponent implements OnInit {

@Input() control: AbstractControl<any>
@Input() format: QuestionFormat
@Input() min: number
@Input() max: number
@Input() characterLimit: number
@Input() required: boolean
@Input() appearance: "legacy" | "outline" | "standard" | "fill" = "outline";

    type?: "number" | "text" = null
    ngOnInit(): void {

    if (this.min != null) this.control.addValidators(Validators.min(this.min))
    if (this.max != null) this.control.addValidators(Validators.max(this.max))
    if (this.required) this.control.addValidators(Validators.required)
    if (this.characterLimit != null) this.control.addValidators(Validators.maxLength(this.characterLimit))

    if (this.format == "integer") {
        this.type = "number"
        this.control.addValidators(Validators.pattern(/^[1-9]\d*$/))
    } else if (this.format == "numeric_text") {
        this.type = "text"
        this.control.addValidators(Validators.pattern(/^\d+$/))
    } else if(this.format == "decimal") {
        this.type = "number"
        this.control.addValidators(Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/))
    } else {
        this.type = "text"
    }
}
}

