<div class="flex column gap-sm custom-wrapper-padding" *ngIf="!loading">

    <div *ngIf="!searchService.checkIfResultsContainForcedIds()" class="no-forced-position-information center column flex padding">
        <i class="mdi mdi-emoticon-sad large-text"></i>
        <h3>Tut uns leid</h3>
        <p>Diese Stelle ist leider nicht mehr verfügbar. Suche ähnliche Stellen, die zu deinem Studiengang passen!</p>
        <button
                (click)="triggerClearSearch()"
                class="dark-stroked-button">Stellen finden</button>
    </div>

    <div class="flex column gap-sm" *ngFor="let position of positions; let i = index">
            <app-positions-preview-card
                    [position]="position"
                    [origin]="origin"
            ></app-positions-preview-card>
        <div class="divider"
             *ngIf="positions?.length - 1 > i && positionService.selectedPositionId.value != position.id &&
             (i < positions.length ? positionService.selectedPositionId.value != positions[i + 1].id : true)"></div>
        <div style="height: 1px"
             *ngIf="!(positions?.length - 1 > i && positionService.selectedPositionId.value != position.id &&
             (i < positions.length ? positionService.selectedPositionId.value != positions[i + 1].id : true))"></div>
    </div>

    <ng-content select="[postview]"></ng-content>
</div>

<div class="flex column gap-sm padding-lg" *ngIf="loading">
    <loading-skeleton [tileSize]="'small'" [count]="5" [roundedCorners]="true"></loading-skeleton>
</div>

<app-positions-previews-paginator *ngIf="positions?.length"
                                  [page]="page"
                                  [totalPages]="totalPages"
                                  [disabled]="loading"
                                  (pageIndex)="paginatorNextIndex.emit($event)">
</app-positions-previews-paginator>


