import { Injectable } from '@angular/core';
import {
  CareerEventPreviewDialogComponent
} from "../talent-profile/career-event-preview-dialog/career-event-preview-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
  AssignTalentAgentDialogComponent
} from "../coach/assign-talent-agent-dialog/assign-talent-agent-dialog.component";
import {MediaService} from "./inno-utils/media.service";

@Injectable({
  providedIn: 'root'
})
export class DialogOpenService {


  constructor(
      private dialog: MatDialog,
      private mediaService: MediaService
  ) { }

  openWorksamplePreviewDialog(file, fileId, dialogTitle: string = null) {
    this.dialog.open(CareerEventPreviewDialogComponent, {
      autoFocus: false,
      data: {
        fileId: fileId,
        type: this.getFileType(file.uploadedFileName? file.uploadedFileName : (file.originalFileName? file.originalFileName : 'pdf')),
      dialogTitle: dialogTitle},
      panelClass: ['fullscreen-dialog-only-mobile', 'no-padding-dialog', 'round-border-dialog', 'pdf-preview-dialog']
    });
  }

  openAssignDialog(): Promise<any> {
    const dialogRef = this.dialog.open(AssignTalentAgentDialogComponent, {
      width: this.mediaService.mobileQuery.matches ? '100vw' : '500px',
      maxWidth: '100vw',
      panelClass: ['full-width-mobile-dialog-new', 'release-info-dialog']
    })

    return new Promise<any>(resolve => {
      dialogRef.afterClosed().subscribe( result => resolve(result))
    })
  }

  private getFileType(fileName): string {
    let type = fileName.split('.')

    return type[type?.length-1]
  }
}
