import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormControl} from "@angular/forms";
import {StudiesData, TalentStudiesResource} from "utility";
import {MediaService} from "../../services/inno-utils/media.service";

@Component({
  selector: 'app-edit-subject-dialog',
  templateUrl: './edit-subject-dialog.component.html',
  styleUrls: ['./edit-subject-dialog.component.scss']
})
export class EditSubjectDialogComponent implements OnInit {

  readonly studyControl = new UntypedFormControl()
  saving: boolean = false

  constructor(
      @Inject(MAT_DIALOG_DATA) private data: number,
      public dialogRef: MatDialogRef<EditSubjectDialogComponent>,
      private talentStudiesResource: TalentStudiesResource,
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
  }

  updateStudy() {
    this.saving = true
    let data = <StudiesData>{
      subjectId: this.studyControl.value,
      id: this.data
    }

    this.talentStudiesResource.updateStudies(data).then(result => {
      this.close(true)
      this.saving = false
    })
  }

  close(result: boolean = false) {
    this.dialogRef.close(result)
  }

}
