import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventOnboardingComponent} from './event-onboarding/event-onboarding.component';
import {EventObContactDataComponent} from './event-ob-contact-data/event-ob-contact-data.component';
import {EventObCvUploadComponent} from './event-ob-cv-upload/event-ob-cv-upload.component';
import {EventObStateComponent} from './event-ob-state/event-ob-state.component';
import {EventObButtonBarComponent} from './event-ob-button-bar/event-ob-button-bar.component';
import {EventObCoachComponent} from './event-ob-coach/event-ob-coach.component';
import {EventObCoachDataComponent} from './event-ob-coach-data/event-ob-coach-data.component';
import {EventObEveProgressComponent} from './event-ob-eve-progress/event-ob-eve-progress.component';
import {EventObPasswordComponent} from './event-ob-password/event-ob-password.component';
import {EventObSelectSubjectComponent} from './event-ob-select-subject/event-ob-select-subject.component';
import {EventObStepHeadlineComponent} from './event-ob-step-headline/event-ob-step-headline.component';
import {EventObStepMatchingDataComponent} from './event-ob-step-matching-data/event-ob-step-matching-data.component';
import {RouterModule, Routes} from "@angular/router";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {UtilityModule} from "utility";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";

const appRoutes: Routes = [
  {
    path: 'onboarding/event', component: EventOnboardingComponent, canActivate: [OnboardingAuthGuardService],
    children: [
      {path: 'contact-data', component: EventObContactDataComponent},
      {path: 'cv', component: EventObCvUploadComponent},
      {path: 'obState', component: EventObStateComponent},
      {path: 'subject', component: EventObSelectSubjectComponent},
      {path: 'preferences', component: EventObStepMatchingDataComponent},
      {path: 'coach-intent', component: EventObCoachComponent},
      {path: 'coach-data', component: EventObCoachDataComponent},
      {path: 'password', component: EventObPasswordComponent}
    ]
  }];

@NgModule({
    declarations: [
        EventOnboardingComponent,
        EventObContactDataComponent,
        EventObCvUploadComponent,
        EventObStateComponent,
        EventObButtonBarComponent,
        EventObCoachComponent,
        EventObCoachDataComponent,
        EventObEveProgressComponent,
        EventObPasswordComponent,
        EventObSelectSubjectComponent,
        EventObStepHeadlineComponent,
        EventObStepMatchingDataComponent
    ],
    imports: [
      MatDialogModule,
      CommonModule,
      UtilityModule,
      RouterModule.forChild(appRoutes),
      HidLibCloneModule
    ]
})
export class EventOnboardingModule {
}
