<div id="buttons"  fxLayout="row" fxLayoutAlign="space-between center">

    <div id="buttons-inner" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button *ngIf="backButton" [disabled]="buttonsLocked" mat-button (click)="back()">Zurück</button>
        <button *ngIf="skipButton" [disabled]="buttonsLocked" mat-button color="accent" (click)="pressedSkip.emit()">Überspringen</button>
        <!--        <button [disabled]="buttonsLocked || !valid" mat-flat-button color="accent" (click)="pressedNext.emit()">-->
        <!--            <mat-progress-spinner *ngIf="buttonsLocked" mode="indeterminate" color="accent" diameter="25"></mat-progress-spinner>-->
        <!--            <span *ngIf="!buttonsLocked">Weiter</span>-->
        <!--        </button>-->

        <button [disabled]="!valid" [loadingBool]="buttonsLocked" mat-flat-button color="accent" (click)="pressedNext.emit()">Weiter</button>
    </div>

</div>