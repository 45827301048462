<div fxLayout="row" fxLayoutAlign="start end" class="message-wrapper width-100">

    <div *ngIf="messageData.sender.id != talentId"
         class="width-10"
         id="profile-picture"
         fxLayout="row"
         fxLayoutAlign="center center"
         [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}">
    </div>
    <div *ngIf="messageData.sender.id != talentId"
         fxLayout="column" fxLayoutAlign="end center">
        <div class="tail-left"></div>
    </div>

    <div [ngClass]="{'talent': messageData.sender.id == talentId}"
         class="message width-100"
         fxLayout="column" fxLayoutAlign="space-between start">
        <div class="text"
             fxLayout="row" fxLayoutAlign="stretch">
            {{messageData.text}}
        </div>
        <div class="width-100" fxLayout="row" [fxLayoutAlign]="messageData.sender.dtype == 'Talent'? 'end baseline' : 'space-between baseline'" fxLayoutGap="1rem">
            <div class="time" *ngIf="messageData.sender.dtype != 'Talent'">
                {{messageData.sender.name}}
            </div>
            <div [ngClass]="{'time-talent': messageData.sender.id == talentId}"
                 class="time">{{messageData.occurred | date: 'HH:mm'}}</div>
        </div>
    </div>

    <div *ngIf="messageData.sender.id == talentId" fxLayout="column" fxLayoutAlign="end center">
        <div class="tail-right"></div>
    </div>
</div>