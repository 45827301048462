import {ElementRef, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

export interface ScrollReport {
  height: number,
  scrollTop: number
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  scrollTop$: Observable<number>;

  scrollStateIndex$: Observable<number>;

  contentScroll$: Observable<ScrollReport>;

  profilePicChanged$: Observable<null>;

  headerPicChanged$: Observable<null>;

  countOfPendingUnlockRequests$: Observable<number>;

  private countOfPendingUnlockRequests: Subject<number>;

  private scrollTop: Subject<number>;
  private scrollStateIndex: Subject<number>;
  private contentScroll: Subject<ScrollReport>;
  private sideNavContent: ElementRef;

  private profilePicChanged: Subject<null>;

  private headerPicChanged: Subject<null>;

  constructor() {
    this.scrollTop = new Subject<number>();
    this.scrollTop$ = this.scrollTop.asObservable();

    this.countOfPendingUnlockRequests = new Subject<number>();
    this.countOfPendingUnlockRequests$ = this.countOfPendingUnlockRequests.asObservable();

    this.scrollStateIndex = new Subject<number>();
    this.scrollStateIndex$ = this.scrollStateIndex.asObservable();

    this.contentScroll = new Subject<any>();
    this.contentScroll$ = this.contentScroll.asObservable();

    this.profilePicChanged = new Subject<any>();
    this.profilePicChanged$ = this.profilePicChanged.asObservable();

    this.headerPicChanged = new Subject<any>();
    this.headerPicChanged$ = this.profilePicChanged.asObservable();
  }

  setSidenavElement(element: ElementRef) {
    this.sideNavContent = element;
  }

  reportProfilePicChange() {
    return this.profilePicChanged.next();
  }
}
