import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountResource, TalentOnboardingResource, UtilsService} from "utility";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";

@Component({
  selector: 'app-co-password',
  templateUrl: './co-password.component.html',
  styleUrls: ['./co-password.component.scss']
})
export class CoPasswordComponent implements OnInit {

  form: FormGroup

  constructor(
      private utilsService: UtilsService,
      private talentOnboardingResource: TalentOnboardingResource,
      private authService: TalentAuthService,
      public router: Router,
      private obService: CoachingOnboardingService
  ) {
  }


  ngOnInit() {
      this.form = new FormGroup({
          password: new FormControl<string>(null, [Validators.required, this.utilsService.requirementsPasswordMatched( 2, 6)])
      });
  }


  forceValidation = new Subject;
  progressing = new Subject()
  progress() {
    this.forceValidation.next()
    if(!this.form.valid)return

    this.progressing.next()

    this.obService.updateInputs(<CachedOBInputs>{
      password: this.form.controls.password.value
    })
      this.obService.delayedRouting("/onboarding/coach/cv")
  }

  containsSmallLetter(): boolean {
    if (this.form.controls.password.value == null) return false;
    return (/[a-z]/.test(this.form.controls.password.value));
  }

  containsBigLetterOrNumber() {
    if (this.form.controls.password.value == null) return false;
    return (/[A-Z0-9]/.test(this.form.controls.password.value));
  }

}
