import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  TalentApplicationData,
  TalentApplicationResource,
  TalentPositionInteractionOrigin,
  TalentPositionRelationResource
} from 'utility';
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {PositionService} from '../services/position.service';

@Component({
  selector: 'app-talent-applications',
  templateUrl: './talent-applications.component.html',
  styleUrls: ['./talent-applications.component.scss']
})
export class TalentApplicationsComponent implements OnInit, AfterViewInit {

  loadingGrantedRequests: boolean = false;
  grantedRequestsForTalentAvailable: boolean = false;
  loadingArchivedRequestForTalent: boolean = false;
  archivedRequestForTalentAvailable: boolean = false;

  grantedRequestsForTalentData: TalentApplicationData[] = [];
  archivedRequestsForTalentData: TalentApplicationData[] = [];

  //Paginator Settings
  pageSizeOptions: number[] = [5, 10, 25, 50];

  //Paginator Settings Granted
  pageIndexGranted: number = 0;
  pageSizeGranted: number = 5;
  totalElementsGranted: number = 0;

  //Paginator Settings Archived
  pageIndexArchived: number = 0;
  pageSizeArchived: number = 5;
  totalElementsArchived: number = 0;

  tabIndex: number = 0;

  cvAvailableQueryParam: string;
  tabQueryParam: string;
  queryParamSubscription: Subscription;

  private positionToBeApplied: number;

  constructor(
    private talentApplicationResource: TalentApplicationResource,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private positionService: PositionService,
    private talentPositionRelationResource: TalentPositionRelationResource
  ) {
  }

  ngOnInit() {
    this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
      this.cvAvailableQueryParam = queryParams.get("cv");
      this.tabQueryParam = queryParams.get("tab");
      this.positionToBeApplied = Number(queryParams.get('apply-for-position'));
    });

    this.tabQueryParam ? this.tabIndex = parseInt(this.tabQueryParam) : this.tabIndex = 0

    this.openPositionDetailsForApplication();
    this.loadApplications();
    this.loadArchivedApplications();
  }

  ngAfterViewInit() {
  }

  removeRequestFromArray(event) {
    //this reload the pages but in case of delete only the specific unlock request the totalElements have to be updated too
    this.loadApplications();
    this.loadArchivedApplications();
  }

  loadArchivedApplications() {
    this.loadingArchivedRequestForTalent = true;
    this.talentApplicationResource.getArchivedApplications({
      pageSize: this.pageSizeArchived,
      pageNum: this.pageIndexArchived
    }).then(result => {
      this.archivedRequestsForTalentData = result.content;
      this.totalElementsArchived = result.totalElements;

      this.archivedRequestForTalentAvailable = this.archivedRequestsForTalentData && this.archivedRequestsForTalentData.length > 0;
      this.loadingArchivedRequestForTalent = false;
    })
  }

  loadApplications() {
    this.loadingGrantedRequests = true;
    this.talentApplicationResource.getActiveApplications({
      pageSize: this.pageSizeGranted,
      pageNum: this.pageIndexGranted
    }).then(result => {
      this.grantedRequestsForTalentData = result.content;
      this.totalElementsGranted = result.totalElements;

      this.grantedRequestsForTalentAvailable = this.grantedRequestsForTalentData && this.grantedRequestsForTalentData.length > 0;

      this.loadingGrantedRequests = false;
    })
  }

  handlePageGranted(event: PageEvent): void {
    this.pageIndexGranted = event.pageIndex;
    this.pageSizeGranted = event.pageSize;
    this.loadApplications();
  }

  handlePageArchived(event: PageEvent): void {
    this.pageIndexArchived = event.pageIndex;
    this.pageSizeArchived = event.pageSize;
    this.loadArchivedApplications();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.router.navigateByUrl(`/talent/applications?tab=${event.index}`);
  }

  openPositionDetailsForApplication() {
    if(!this.positionToBeApplied || this.positionToBeApplied === 0) return

    const origin: TalentPositionInteractionOrigin = 'Recommendations';
    this.talentPositionRelationResource.getTalentPositionRelationByPositionId(this.positionToBeApplied, {origin}).then(
        response => {
          this.positionService.openPositionDetails(response.employmentPosition.id, origin).then( _ => {
            this.loadApplications();
          });
        }
    );
  }

}
