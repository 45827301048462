<div class="position-hint" [ngClass]="{'clickable': isModeSpecificCities, 'light-mode': lightMode}"
     style="display: inline-block" matTooltipClass="custom-tooltip" [matTooltip]="getToolTipText()">
    <span class="infomation-icon" >
        <i [ngStyle]="{'font-size.px': this.markerSize}"
           [ngClass]="{'light-mode': lightMode}"
           class="mdi mdi-map-marker"></i>
    </span>
    <span [ngStyle]="{'font-size.px': this.textSize}" *ngIf="!loadingPersonalizesString">
        {{personalizesString}}
    </span>
</div>
