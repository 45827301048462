import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, OnlineLectureInfoData, OnlineLectureLessonData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.OnlineLectureAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class OnlineLectureAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/formats/onlineLecture/content/{code}',
        endpoint: 'de.syntacton.cronos'
    })
    private _downloadOnlineLectureFormatContentMaster: IResourceMethodObservableStrict<null, null, {code: string}, Blob> | undefined
    downloadOnlineLectureFormatContentMaster(code: string): Promise<Blob> {
        if (!this._downloadOnlineLectureFormatContentMaster) throw new Error("resource has not been properly initialized")
        return this._downloadOnlineLectureFormatContentMaster(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/onlineLecture/{code}',
        endpoint: 'de.syntacton.cronos'
    })
    private _finishOnlineLecture: IResourceMethodObservableStrict<null, null, {code: string}, null> | undefined
    finishOnlineLecture(code: string): Promise<null> {
        if (!this._finishOnlineLecture) throw new Error("resource has not been properly initialized")
        return this._finishOnlineLecture(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{code}/appointment',
        endpoint: 'de.syntacton.cronos'
    })
    private _getAppointmentRequestForOnlineLecture: IResourceMethodObservableStrict<null, null, {code: string}, JsonWrappedValue<Date>> | undefined
    getAppointmentRequestForOnlineLecture(code: string): Promise<JsonWrappedValue<Date>> {
        if (!this._getAppointmentRequestForOnlineLecture) throw new Error("resource has not been properly initialized")
        return this._getAppointmentRequestForOnlineLecture(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{formatId}/info',
        endpoint: 'de.syntacton.cronos'
    })
    private _getOnlineLectureInfo: IResourceMethodObservableStrict<null, {code: string}, {formatId: number}, OnlineLectureInfoData> | undefined
    getOnlineLectureInfo(formatId: number, requestParams: {code: string}): Promise<OnlineLectureInfoData> {
        if (!this._getOnlineLectureInfo) throw new Error("resource has not been properly initialized")
        return this._getOnlineLectureInfo(null, requestParams, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{code}/lesson/{id}',
        endpoint: 'de.syntacton.cronos'
    })
    private _getOnlineLectureLessonByTokenAndId: IResourceMethodObservableStrict<null, null, {code: string, id: number}, OnlineLectureLessonData> | undefined
    getOnlineLectureLessonByTokenAndId(pathVariables: {code: string, id: number}): Promise<OnlineLectureLessonData> {
        if (!this._getOnlineLectureLessonByTokenAndId) throw new Error("resource has not been properly initialized")
        return this._getOnlineLectureLessonByTokenAndId(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/onlineLecture/{code}/appointment',
        endpoint: 'de.syntacton.cronos'
    })
    private _requestAppointmentForOnlineLecture: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {code: string}, null> | undefined
    requestAppointmentForOnlineLecture(requestBody: JsonWrappedValue<string>, code: string): Promise<null> {
        if (!this._requestAppointmentForOnlineLecture) throw new Error("resource has not been properly initialized")
        return this._requestAppointmentForOnlineLecture(requestBody, null, {code: code}).toPromise()
    }

}