import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ConfirmDialogComponent,
  CVInfoData,
  ProfileAttachmentData,
  StudiesData,
  TalentAccountInfoData,
  TalentCareerEventResource,
  TalentProfileAttachmentResource,
  TalentProfileData,
  TalentStudiesResource,
  TalentTalentProfileResource,
  UniversityResource,
  UtilityService,
  UtilsService
} from 'utility';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {EducationTypePipe} from '../../../../utility/src/lib/pipes/education-type.pipe';
import {MediaService} from '../services/inno-utils/media.service';
import {debounceTime} from 'rxjs/operators';
import {FileService} from "../services/file.service";
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {DialogOpenService} from "../services/dialog-open.service";
import {EditSubjectDialogComponent} from "./edit-subject-dialog/edit-subject-dialog.component";

@Component({
  selector: 'app-talent-profile',
  templateUrl: './talent-profile.component.html',
  styleUrls: ['./talent-profile.component.scss']
})
export class TalentProfileComponent implements OnInit, OnDestroy {

  @ViewChild('cvFileInputProfile') cvFileInputProfile: ElementRef
  @ViewChild('fileInputAttachements') fileInputAttachements: ElementRef

  readonly cvFile = new FormControl<FileList>(null, this.fileService.uploadFileControlValidator());
  savingCv: boolean = false;
  loadingPersonalData: boolean = false;
  loadingStudyInfo: boolean = false;
  loadingFiles: boolean = false;
  loadingCV: boolean = true;

  uploadedFiles: ProfileAttachmentData[]

  cv: Blob = new Blob();
  cvInfo: CVInfoData = {
    originalFileName: '',
    cvAvailable: false,
    uploadDate: null,
    fileType: null
  };

  endDateDropdownOptions = [{option: 'finished', label: 'Schon fertig'},{option: '3months', label: '< 3 Monate'}, {option: '6months', label: '< 6 Monate'}, {option: 'later', label: '> 6 Monate'} ];

  talentProfileDataInitialLoaded: boolean = false

  events = [];
  careerIconHeight = 50;

  readonly personalDataGroup = new FormGroup({
    firstName: new UntypedFormControl(null, Validators.required),
    lastName: new UntypedFormControl(null, Validators.required),
    cityId: new UntypedFormControl(null),
    phoneNumber: new UntypedFormControl(null, [Validators.required, this.utilsService.validatePhoneNumber()]),
  });
  readonly studyForm = new FormGroup({
    grade: new UntypedFormControl(null),
    endDateCategory: new UntypedFormControl(null),
    universityName: new UntypedFormControl({value: null, disabled: true}, [Validators.minLength(3), Validators.required])
  });
  gradeControlSubscription: Subscription;
  personalDataSub: Subscription;
  studyDataSubGrade: Subscription;
  studyDataSubEndDate: Subscription;
  mostRecentStudies: StudiesData

  accountInfo: TalentAccountInfoData

  readonly profileAttachmentfile = new FormControl(null, this.fileService.uploadFileControlValidator());
  savingProfileAttachment: boolean = false

  constructor(
    private dialog: MatDialog,
    private talentCareerEventResource: TalentCareerEventResource,
    private talentStudiesResource: TalentStudiesResource,
    private talentProfileResource: TalentTalentProfileResource,
    private educationTypePipe: EducationTypePipe,
    private utilityService: UtilityService,
    private utilsService: UtilsService,
    private workSampleResource: TalentProfileAttachmentResource,
    public fileService: FileService,
    public mediaService: MediaService,
    public universityResource: UniversityResource,
    private activatedRoute: ActivatedRoute,
    private talentProfileAttachmentResource: TalentProfileAttachmentResource,
    public dialogOpenService: DialogOpenService,
  ) {
  }

  ngOnDestroy(): void {
    this.gradeControlSubscription?.unsubscribe()
    this.personalDataSub?.unsubscribe()
    this.unsubscribeOnStudyDataChanges()
    }

  ngOnInit() {
    this.initializeVariables();
    this.loadPersonalData();
    this.getMostRecentStudies()
    this.loadCvInfo();
    this.getUploadedFiles()


    this.gradeControlSubscription = this.studyForm.controls.grade.valueChanges.subscribe((() => {
      if (this.studyForm.controls.grade?.value?.toString()?.indexOf(',') > -1) {
        this.studyForm.controls.grade.setValue(this.studyForm.controls.grade.value.replace(/,/gi, '.'));
      }
    }))
  }

  openEditStudyDialog() {
    let dialogRef = this.dialog.open(EditSubjectDialogComponent, {
      data: this.mostRecentStudies.id,
      width: this.mediaService.getMaxWidthQuery(600).matches? '100%' : '600px',
      panelClass: 'fullscreen-dialog-only-mobile2'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.getMostRecentStudies()
    })
  }

  saveProfileAttachment() {
    if (!this.profileAttachmentfile.valid) return
    this.savingProfileAttachment = true;
    let file = this.profileAttachmentfile.value[0];
    this.fileService.createProfileAttachment(file, file.name).subscribe(
        () => {
          this.savingProfileAttachment = false;
          this.profileAttachmentfile.setValue(null)
          this.fileInputAttachements.nativeElement.value = null
          this.getUploadedFiles()
        }
    )
  }

  unsubscribeOnStudyDataChanges() {
    this.studyDataSubGrade?.unsubscribe()
    this.studyDataSubEndDate?.unsubscribe()
  }

    subscribeOnStudyDataChanges() {
    this.studyDataSubGrade = this.studyForm.get("grade").valueChanges.subscribe(value => {
      if (this.studyForm.valid) this.updateMostRecentStudies()
    })

    this.studyDataSubEndDate = this.studyForm.get("endDateCategory").valueChanges.subscribe(value => {
      if (this.studyForm.valid) this.updateMostRecentStudies()
    })
  }

  getMostRecentStudies(): Promise<any> {
    this.loadingStudyInfo = true
     return this.talentProfileResource.getMostRecentStudiesForTalent().then(result => {
      this.mostRecentStudies = result
       this.unsubscribeOnStudyDataChanges()

      this.studyForm.patchValue({
        grade: result.grade,
        universityName: result.universityName,
        endDateCategory: this.convertDateToOption(result.endDate)
      })

       this.subscribeOnStudyDataChanges()

      this.loadingStudyInfo = false
    })
  }

  saveUniversity() {
    this.updateOnlyUniversity().then(() => this.disableUniversityEdit())
  }

  enableUniversityEdit() {
    this.studyForm.controls.universityName.enable()
  }

  disableUniversityEdit() {
    this.studyForm.controls.universityName.disable()
  }


  convertOptionToDate(option){
    if (!option) return null
    let date = new Date();
    let daysToAdd  = 0;
    switch(option.option){
      case 'finished': break;
      case '3months': daysToAdd = 90;break;
      case '6months': daysToAdd = 180; break;
      case 'later': daysToAdd = 270; break;
    }
    date.setDate(date.getDate() + daysToAdd)
    return date
  }

  convertDateToOption(date){
    if (!date) return null;
    let now = new Date();
    let finishDate = new Date(date)
    let daysuntilFinishDate  = (finishDate.getTime() - now.getTime()) / 1000 / 60 / 60 / 24;

    let option
    if (daysuntilFinishDate <= 0) option = 'finished';
    else if (daysuntilFinishDate > 0 && daysuntilFinishDate <= 90) option = '3months';
    else if (daysuntilFinishDate > 0 && daysuntilFinishDate <= 180) option = '6months';
    else if (daysuntilFinishDate > 0 && daysuntilFinishDate <= 270) option = 'later';

    return this.endDateDropdownOptions.find(o => o.option == option)
  }

  updateMostRecentStudies():Promise<any> {
    let data = <StudiesData>{
      id: this.mostRecentStudies.id,
      endDate: this.convertOptionToDate(this.studyForm.controls.endDateCategory.value),
      grade: this.studyForm.controls.grade.value,
      universityName: this.studyForm.controls.universityName.value
    }
    return this.talentStudiesResource.updateStudies(data)
  }

  updateOnlyUniversity(): Promise<any>{
    let data = <StudiesData>{
      id: this.mostRecentStudies.id,
      universityName: this.studyForm.controls.universityName.value
    }
    return this.talentStudiesResource.updateStudies(data)
  }

  getUploadedFiles() {
    this.loadingFiles = true
    this.talentProfileResource.getStoredFilesForTalentProfile().then(result => {
      this.uploadedFiles = result
      this.loadingFiles = false
    })
  }

  loadCvInfo(){
    this.loadingCV = true
    this.talentProfileResource.getCVInfo().then(res => {
          this.cvInfo = res;
          this.loadingCV = false
        }
    )
  }

  initializeVariables() {
    if (this.mediaService.getMaxWidthQuery(800).matches) this.careerIconHeight = 50;
  }

  subscribeOnPersonalValueChanges() {
    this.personalDataSub = this.personalDataGroup.valueChanges.pipe(debounceTime(1000)).subscribe(change => {
      this.savePersonalData()
    })
  }

  updateProfileAttachmentName(data: ProfileAttachmentData) {
    this.talentProfileAttachmentResource.updateProfileAttachment(data)
  }


  saveCurriculumVitae() {
    if (!this.cvFile.value[0] || this.cvFile.errors || !this.cvFile.value[0]?.size) return

    let file = this.cvFile.value[0];
    this.savingCv = true;
    this.fileService.uploadCV(file).subscribe(
        () => {
          this.fileService.getCVInfo().then(result => {this.cvInfo = result});
          this.savingCv = false;
          this.cvFile.setValue(null)
          this.cvFileInputProfile.nativeElement.value = null
          this.cvFile.updateValueAndValidity()
        }
    )
  }

  deleteWorksample(entry) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Eintrag löschen',
        message: 'Möchtest du den Eintrag wirklich löschen?',
        confirmButtonText: 'Löschen',
        confirmButtonColor: 'accent',
        onConfirm: () => this.workSampleResource.removeProfileAttachment(entry.id)
      },
      autoFocus: false,
      panelClass: ['full-width-mobile-dialog', 'round-border-dialog']
    });
    dialogRef.afterClosed().subscribe(deleted => {
      if (deleted) {
        this.uploadedFiles.splice(this.uploadedFiles.lastIndexOf(entry), 1)
      }
    });
  }

  loadPersonalData() {
    this.loadingPersonalData = true
    this.talentProfileResource.getContactData().then(result => {
      this.personalDataGroup.patchValue({
        firstName: result.firstName,
        lastName: result.lastName,
        phoneNumber: result.phoneNumber,
        cityId: result.cityId,
      });
      this.subscribeOnPersonalValueChanges()
      this.loadingPersonalData = false
      this.talentProfileDataInitialLoaded = true;
    });

  }

  savePersonalData() {
    if (!this.personalDataGroup.valid) return

    let profileData = <TalentProfileData>{
      firstName: this.personalDataGroup.controls.firstName.value,
      lastName: this.personalDataGroup.controls.lastName.value,
      phoneNumber: this.personalDataGroup.controls.phoneNumber.value,
      cityId: this.personalDataGroup.controls.cityId.value,
    };

    this.talentProfileResource.setContactData(profileData);
  }


  deleteCV() {
    this.fileService.deleteCV().then(deleted => {

      if (deleted) {
        this.loadCvInfo();
        this.cvFile.setValue(null)
      }
    })
  }
}
