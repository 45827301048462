import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {delay} from "utility";
import {EventCachedOBInputs, EventOnboardingService} from "../event-onboarding.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'app-event-onboarding',
  templateUrl: './event-onboarding.component.html',
  styleUrls: ['./event-onboarding.component.scss']
})
export class EventOnboardingComponent implements OnInit {


  constructor(
      public obService: EventOnboardingService,
      public router:Router,
      private activatedRoute: ActivatedRoute,
      private authService: TalentAuthService,
      private analyticsService: AnalyticsService
  ) {
    let trafficOrigin = {...this.analyticsService.trafficOrigin}
    trafficOrigin.entryGateway = "Karrieredinner"
    trafficOrigin.utm_term = "Karrieredinner"
    this.analyticsService.trafficOrigin = trafficOrigin
  }


  @HostListener("window:popstate", ['$event'])
  onBrowserBack(event){
    //prevent report pageview on browser back to onboarding split start component
    if (this.router.url != '/onboarding/event') this.obService.reportCurrentStepPageview()
    //browser back on welcome side, to route back and not show empty page
    if (this.router.url === '/onboarding/event/contact-data') this.back()
  }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.queryParamMap.get('eventId')) {
      let eventId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('eventId'));
      this.obService.updateInputs(<EventCachedOBInputs>{eventId: eventId})
    }

    if(this.authService.isAuthenticated() && this.authService.getAccountInfo().onboardingCompleted) {
      this.router.navigate(['/event-page/'+ this.obService.cachedInputs.eventId.toString()]);
    } else if (this.authService.isAuthenticated()) {

      if (this.obService.cachedInputs?.eventId) {
        this.obService.getEvent()
      } else {
        this.obService.getEventIdForUser().then(result => {
          this.obService.updateInputs(<EventCachedOBInputs>{eventId: result})
          this.obService.getEvent()
        })
      }
    } else {
      if (this.obService.cachedInputs?.eventId) {
        this.obService.getEvent()
      }
    }


      //this array contains all child-routes that are currently active. If its empty, none is active and therefore we gotta do an initial routing
      if(this.activatedRoute.children.length == 0) {
        this.router.navigate(["onboarding/event/contact-data"])
      }

      this.obService.routingStart.subscribe(() => {this.transitionAnimation()})
    }


  /**
   * Phase 1: Move router to the right
   * Phase 2: Activate transition, router will lose offset class and slide back into original spot
   */
  animationPhase = 0
  transitionAnimation(){
    this.animationPhase = 1;
    delay(50).then(() => {
      this.animationPhase == 2
      delay(200).then(() =>{
        this.animationPhase = 0; //activates the transition and moves the router back to its original position by removing the offset class
      })
    })
  }

  back(){
    history.back()
  }

  onFinalizeOnboardingProcess(): boolean {
    return !this.router.url.includes('event/obState')
    && !this.router.url.includes('event/contact-data')
    && !this.router.url.includes('event/cv')
    && !this.router.url.includes('event/subject')
    && !this.router.url.includes('event/password')
  }

}
