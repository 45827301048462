import {Component, OnDestroy, OnInit} from '@angular/core';
import {TalentHomeResource} from "utility";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'matching-home-desktop',
  templateUrl: './matching-home-desktop.component.html',
  styleUrls: ['./matching-home-desktop.component.scss']
})
export class MatchingHomeDesktopComponent implements OnInit, OnDestroy {

  loadingSubject = new BehaviorSubject<boolean>(true)
  matchesCount: number

  redirectPath: string = "/talent/positions"
  refreshIntervalId

  constructor(
      private talentHomeResource: TalentHomeResource,
      public homeService: HomeService,
      private talentAuthService: TalentAuthService,
      public router: Router,
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.initGetMatchesCount()
  }

  initGetMatchesCount() {
    this.loadingSubject.next(true)
    this.getMatchesCount().then(count => {
      this.loadingSubject.next(false)
    })

    this.refreshIntervalId = setInterval(() => {
      if (this.talentAuthService.isAuthenticated()) {
        this.getMatchesCount()
      } else {
        this.clearMatchesCountInterval()
      }
    }, 3000)
  }

  getMatchesCount(): Promise<any> {
    return this.talentHomeResource.getMatchesCount().then(count => {
      this.matchesCount = count.value
    })
  }

  ngOnDestroy(): void {
    this.clearMatchesCountInterval()
  }

  clearMatchesCountInterval() {
    clearInterval(this.refreshIntervalId)
  }
}
