import {Component, HostListener, OnInit} from '@angular/core';
import {FileService} from '../services/file.service';
import {CVInfoData, TalentCoachData, TalentCoachResource, TalentTalentProfileResource} from 'utility';
import {MediaService} from '../services/inno-utils/media.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MlpInfoDialogComponent} from "./mlp-info-dialog/mlp-info-dialog.component";
import {JudgeTalentAgentDialogComponent} from "./judge-talent-agent-dialog/judge-talent-agent-dialog.component";
import {Subscription} from "rxjs";
import {MatLegacyTabChangeEvent as MatTabChangeEvent} from "@angular/material/legacy-tabs";
import {AssignTalentAgentDialogComponent} from "./assign-talent-agent-dialog/assign-talent-agent-dialog.component";
import {PropagationService} from "../services/propagation.service";
import {PopUpService} from "../services/pop-up.service";
import {DialogOpenService} from "../services/dialog-open.service";

@Component({
  selector: 'app-talent-agent',
  templateUrl: './talent-agent.component.html',
  styleUrls: ['./talent-agent.component.scss']
})
export class TalentAgentComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.scollHeight = document.getElementsByTagName('html')[0].scrollTop;
    if (this.scollHeight > this.maxScrollHeight) this.maxScrollHeight = this.scollHeight
  }

  maxScrollHeight: number = 0
  scollHeight: number = 0

  public pageLoading: boolean = true;
  public coachAssignableFrom: Date;
  public talentCoachData: TalentCoachData;
  public tabQueryParam: string;
  public openAppointmentDialogQueryParam: string;
  public tabIndex: number = 0;

  cvInfo: CVInfoData

  private queryParamSubscription: Subscription

  constructor(
    public mediaService: MediaService,
    private route: ActivatedRoute,
    public fileService: FileService,
    public propagationService: PropagationService,
    private talentCoachResource: TalentCoachResource,
    private talentProfileResource: TalentTalentProfileResource,
    private router: Router,
    private dialog: MatDialog,
    private popUpService: PopUpService,
    private dialogOpenService: DialogOpenService
  ) {
  }

  ngOnInit() {
    this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
      this.tabQueryParam = queryParams.get("tab");
      this.openAppointmentDialogQueryParam = queryParams.get("openAppointmentDialog");
    })

    this.tabQueryParam ? this.tabIndex = parseInt(this.tabQueryParam) : this.tabIndex = 0

    this.loadTalentAgent();
    this.getCvInfo()

    this.propagationService.cvUploaded.subscribe(() => this.getCvInfo())
  }

  getCvInfo() {
    this.fileService.getCVInfo().then(result => {
      this.cvInfo = result
    })
  }

  showMlpInfoDialog(){
    this.dialog.open(MlpInfoDialogComponent,{ panelClass: 'full-width-mobile-dialog'})
  }

  openJudgeTalentAgentDialog() {
    let dialogRef = this.dialog.open(JudgeTalentAgentDialogComponent, {
      data: {
        talentAgentId: this.talentCoachData.talentAgent.id
      },
      autoFocus: false,
      maxWidth: '400px'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.talentCoachData.assessableForTalent = false
      }
    })
  }
  loadTalentAgent() {
    this.talentCoachResource.getTalentCoach().then(result => {
      this.talentCoachData = result

        // open appointmentdialog if user came from mail and hast still coach
        if (this.openAppointmentDialogQueryParam && this.talentCoachData.coachingId) this.openConsultantAppointmentDialog()

      if (this.talentCoachData.coachAssignableFrom) this.coachAssignableFrom = new Date(this.talentCoachData.coachAssignableFrom)
        this.pageLoading = false;
    })
  }

  openConsultantAppointmentDialog() {
    this.popUpService.openConsultantAppointmentDialog(false).then(result => {
      if(result) this.talentCoachData.appointmentRequestable = false
    })
  }

  routeToFlexperto() {
    window.open(this.talentCoachData.talentAgent.flexpertoLink, '_blank');
  }

  onTabChange(event: MatTabChangeEvent) {
    this.router.navigateByUrl(`/talent/agent?tab=${event.index}`);
  }

  openAssignDialog() {
    this.dialogOpenService.openAssignDialog().then(result => {
      if (result) {
        this.loadTalentAgent()
      }
    })
  }

}
