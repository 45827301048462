<div class="flex row-wrap" *ngIf="!mediaService.isMobile()">
    <div class="chip" *ngIf="getWeeklyHoursText()">
        <i class="mdi mdi-clock-outline"></i>
        <span>{{getWeeklyHoursText()}}</span>
    </div>
    <div class="chip" *ngIf="positionRelation.employmentPosition.employmentCategory" >
        <i class="mdi mdi-file-document-edit-outline"></i>
        <span>{{positionRelation.employmentPosition.employmentCategory | workType}}</span>
    </div>
    <div class="chip" *ngIf="positionRelation.employmentPosition.homeOffice">
        <i class="mdi mdi-home-account" ></i>
        <span>{{positionRelation.employmentPosition.homeOffice | homeOffice}}</span>
    </div>
    <div class="chip" *ngIf="getStartingDateText()">
        <i class="mdi mdi-calendar-month"></i>
        <span>{{getStartingDateText()}}</span>
    </div>

    <div class="chip" *ngIf="positionRelation.employmentPosition.salaryText">
        <i class="mdi mdi-cash"></i>
        <span>{{positionRelation.employmentPosition.salaryText}}</span>
    </div>
</div>

<div *ngIf="mediaService.isMobile()">
    <div class="mobile-container">
        <div class="flex row gap" *ngIf="getWeeklyHoursText()">
            <i class="mdi mdi-clock-outline"></i>
            <span>{{getWeeklyHoursText()}}</span>
        </div>
        <div class="flex row gap" *ngIf="positionRelation.employmentPosition.employmentCategory">
            <i class="mdi mdi-file-document-edit-outline"></i>
            <span>{{positionRelation.employmentPosition.employmentCategory | workType}}</span>
        </div>
        <div class="flex row gap" *ngIf="positionRelation.employmentPosition.homeOffice">
            <i class="mdi mdi-home-account"></i>
            <span>{{positionRelation.employmentPosition.homeOffice | homeOffice}}</span>
        </div>
        <div class="flex row gap" *ngIf="getStartingDateText()">
            <i class="mdi mdi-calendar-month"></i>
            <span>{{getStartingDateText()}}</span>
        </div>

        <div class="flex row gap" *ngIf="positionRelation.employmentPosition.salaryText">
            <i class="mdi mdi-cash"></i>
            <span>{{positionRelation.employmentPosition.salaryText}}</span>
        </div>

    </div>
</div>
