<div class="flex column gap padding-vertical" [ngClass]="{'center': benefits.length > 6, 'start': benefits.length <= 6}" *ngIf="benefits && benefits.length">
    <div [ngClass]="{'grid-container': benefits.length > 6, 'flex-container': benefits.length <= 6,
    'grid-expanded': extended || benefits.length <= 6, 'grid-collapsed': !extended && benefits.length > 6}">
        <app-positions-details-benefit-card [benefit]="benefit" *ngFor="let benefit of benefits"></app-positions-details-benefit-card>
    </div>

    <div class="extend-overlay flex column end" [ngClass]="{'overlay-expanded': extended, 'overlay-collapsed': !extended}" *ngIf="benefits.length > 6">
        <div class="flex column center width-100 extend-button" (click)="setExtended()">
            <span *ngIf="!extended">Alle {{benefits.length}} Benefits anzeigen</span>
            <span *ngIf="extended">Benefits verstecken</span>
            <i class="mdi mdi-chevron-down" *ngIf="!extended"></i>
            <i class="mdi mdi-chevron-up" *ngIf="extended"></i>
        </div>
    </div>
</div>
