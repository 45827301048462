import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {PublicResource, TalentPositionInteractionOrigin, TalentPositionPreviewData,} from "utility";
import {PositionService} from "../../../services/position.service";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-positions-preview-card',
  templateUrl: './positions-preview-card.component.html',
  styleUrls: ['./positions-preview-card.component.scss']
})
export class PositionsPreviewCardComponent implements OnInit {

  @Input() position: TalentPositionPreviewData
  @Input() origin: TalentPositionInteractionOrigin
  isInView: Boolean = false
  environmentVar = environment
  topjobImage: string = ''

  loadingTopjobImage: boolean = true

  constructor(
      public positionService: PositionService,
      public mediaService: MediaService,
      public publicResource: PublicResource,
      private http: HttpClient,
      private router: Router
  ) { }

  ngOnInit(): void {
    if(this.position && this.position.topjobSlotData){
      this.getTopjobImage()
    }
  }

  openPositionDetails(positionId: number) {
    if (!this.mediaService.isMobile() && !this.router.url.includes('company-profile')) window.scrollTo(0,260)
    this.positionService.openPositionDetails(positionId, this.origin)
  }

    setIsInView(event) {
      if (event === true) this.isInView = event
    }

  getTopjobImage() {
    this.getTopJobSlotHeaderImage(this.position.topjobSlotData.content.id)
        .then((blob) => {
          this.topjobImage = this.convertBlobToImageUrl(blob);
          this.loadingTopjobImage = false;
        })
        .catch((error) => {
          console.error('Error loading top job slot header image', error);
          this.loadingTopjobImage = false;
        });
  }

  getTopJobSlotHeaderImage(slotContentId: number): Promise<Blob> {
    const url = environment.apiUrl + `/public/topJobContents/${slotContentId}/headerImage`; // Adjust the URL as needed
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  convertBlobToImageUrl(blob: Blob): string {
    return URL.createObjectURL(blob);
  }
}
