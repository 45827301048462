<div class="no-freeze-spinner">

    <div id="no-freeze-spinner">
        <div>
            <i class="material-icons">
                work
            </i>
            <i class="material-icons">
                laptop
            </i>
            <i class="material-icons">
                phone
            </i>
            <div>
            </div>
        </div>
        <h1
                class="matching-spinner-label"
        >
            {{loadingMessageText}}</h1>
    </div>
</div>