<div class="flex column start width-100 page-height-no-footer">

    <div class="width-100 preview-wrapper">

        <div class="content-width flex column gap inner-wrapper">
            <div class="flex row gap start-center">
                <i class="mdi mdi-creation"></i>
                <h1 class="preview-header">Stellen Vorschau</h1>
            </div>
            <p>
                Hier sehen Talente dann eine Suchleiste und können genauer nach passenden Stellen filtern.
            </p>
        </div>
    </div>

    <app-positions-wrapper-preview-details
            *ngIf="!loading.value && previewPosition"
            class="width-100"
            [positions]="[previewPosition]"
            [totalPositions]="1"
            [page]="0"
            [totalPages]="1"
            [loading]="loading"
            origin="ElasticSearch"
    >
    </app-positions-wrapper-preview-details>
</div>
