<div class="main-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div class="sucsess-wrapper" *ngIf="!loading" fxLayout="column" fxLayoutAlign="start center">
        <img width="120px" src="assets/images/check_image.png" class="m-bottom15">
        <div class="check-headline">Deine Telefonnummer wurde bestätigt!</div>
        <div class="check-info">In Kürze wird dich dein Coach kontaktieren.</div>

        <button color="accent" mat-flat-button routerLink="/home">Zur Startseite</button>
    </div>
</div>