<div class="width-100 height-100" fxLayout="row" fxLayoutAlign="center center">
    <div class="height-100 width-100" fxLayout="column" fxLayoutAlign="space-between">
        <div class="chat-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">

            <button mat-icon-button (click)="goBackToMinimalChats()">
                <i class="mdi mdi-chevron-left back-button"></i>
            </button>

            <div id="profile-picture" fxLayout="row" fxLayoutAlign="center center"
                 [ngStyle]="{'background-image': chatPicture}">
            </div>

            <div class="chat-name" *ngIf="chatData.members.length == 2">
                {{chatService.getChatPartnerByChat(chatData)?.name}}
            </div>

            <div class="chat-name chat-name-clickable" *ngIf="chatData.members.length > 2" [routerLink]="'company-profile/' + chatData.company.id">
                Chat mit {{chatData.company.name}}
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between baseline" style="flex-grow: 1">
                <div class="chat-type" *ngIf="!mediaService.getMaxWidthQuery(600).matches">
                    <div [routerLink]="'talent/agent'" *ngIf="chatData.type == 'CoachingChat'">
                        Mein Karrierecoach
                    </div>
                    <div [routerLink]="'company-profile/' + chatData.company.id" *ngIf="chatData.type == 'ApplicantChat' && chatData.members.length == 2">
                        Recruiter für {{chatData.company.name}}
                    </div>
                </div>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div class="height-100 messages-container"
             fxLayout="column"
             fxLayoutAlign="start start"
             #messageContainer>
            <div class="width-100" *ngFor="let message of chatData.messages; let index = index">

                <div class="date" fxLayout="row" fxLayoutAlign="center center" *ngIf="isMessageOldestMessageOfDay(message, index)">
                    <span *ngIf="checkIfDateIsToday(message.occurred) == null">{{message.occurred | date: 'dd.MM.yyyy'}}</span>
                    <span *ngIf="checkIfDateIsToday(message.occurred) != null">{{checkIfDateIsToday(message.occurred)}}</span>
                </div>

                <div class="width-100 message"
                     [ngClass]="{'talent': message.sender.id == talentId, 'margin-top': !isPreviousMessageFromTheSameUser(message, index)}">
                    <app-chat-message [messageData]="message"
                                      [talentId]="talentId"></app-chat-message>
                </div>

            </div>
        </div>

        <mat-divider style="margin-top: 1rem"></mat-divider>

        <div class="write-message" fxLayout="column" fxLayoutAlign="start start">
            <div class="width-100" fxLayout="row" fxLayoutAlign="space-between end">
                <textarea class="textarea" matInput [formControl]="messageControl"
                          (keyup)="onKeyup($event)"
                          #textArea
                          placeholder="Schreibe eine Nachricht"></textarea>
                <button mat-icon-button (click)="sendMessage()">
                    <i class="mdi mdi-send"></i>
                </button>
            </div>
        </div>
    </div>
</div>