import { Component, OnInit } from '@angular/core';
import {TalentApplicationMinimalData, TalentHomeResource} from "utility";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";

@Component({
  selector: 'applications-home-mobile',
  templateUrl: './applications-home-mobile.component.html',
  styleUrls: ['./applications-home-mobile.component.scss']
})
export class ApplicationsHomeMobileComponent implements OnInit {

  loadingSubject = new BehaviorSubject<boolean>(true)
  applications: TalentApplicationMinimalData[] = []

  constructor(
      private homeService: HomeService,
      private talentHomeResource: TalentHomeResource
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getApplications()
  }

  getApplications() {
    this.loadingSubject.next(true)
    this.talentHomeResource.getApplicationsForDashboard().then(applications => {
      this.applications = applications
      this.loadingSubject.next(false)
    })
  }

}
