import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TalentTalentProfileResource} from "utility";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-career-event-preview-dialog',
  templateUrl: './career-event-preview-dialog.component.html',
  styleUrls: ['./career-event-preview-dialog.component.scss']
})
export class CareerEventPreviewDialogComponent implements OnInit {

  file: Blob;
  imageData;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: {fileId: any, type: string, dialogTitle?: string},
      private dialogRef: MatDialogRef<CareerEventPreviewDialogComponent>,
      private talentProfileResource: TalentTalentProfileResource,
      private http: HttpClient,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getFile();
  }

  getFile() {
    if (!this.isFileIdValid) {
      return;
    }

    this.http.get(`${environment.apiUrl}/talentProfile/storedFiles/${this.data.fileId}` , {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
     this.file = response.body
      if (['jpg', 'jpeg', 'png'].includes(this.data.type)) {
        this.blobToBase64(this.file).then(result => {
          this.imageData = this.sanitizer.bypassSecurityTrustUrl(result)
        });
      }
    });
  }

  blobToBase64(blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  get isFileIdValid(): boolean {
    return this.data.fileId > 0;
  }
}
