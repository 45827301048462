import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {FormControl, UntypedFormControl, Validators} from "@angular/forms";
import {MediaService} from "../../../services/inno-utils/media.service";
import {Level, Origin, TalentCoachRequestDistributionData} from "utility";
import {CachedOBInputs, S2Fax2OnboardingService} from '../s2-fax2-onboarding.service';

@Component({
  selector: 'app-s2-fax2-o-coach-data',
  templateUrl: './s2-fax2-o-coach-data.component.html',
  styleUrls: ['./s2-fax2-o-coach-data.component.scss']
})
export class S2Fax2OCoachDataComponent implements OnInit, OnDestroy {

    error: BehaviorSubject<any> = new BehaviorSubject<any>(false)
    readonly studyEndDateCtl = new FormControl<Date>(
        this.onboardingService.cachedInputs.studyEndDate ? new Date(this.onboardingService.cachedInputs.studyEndDate) : null,
        [Validators.required]
    );
    readonly nationalityCtr= new FormControl<Origin>(this.onboardingService.cachedInputs.talentOrigin, [Validators.required])
    readonly cityIdCtrl= new FormControl<number>(this.onboardingService.cachedInputs.talentCityId, [Validators.required])
    readonly germanSkillLevelCtr= new FormControl<Level>(this.onboardingService.cachedInputs.germanSkillLevel)
    ctrlSub: Subscription
    progressing: Subject<any> = new Subject<any>()

    constructor(
        public onboardingService: S2Fax2OnboardingService,
        public mediaService: MediaService) {
    }

    ngOnInit() {
        this.ctrlSub = this.studyEndDateCtl.valueChanges.subscribe(next => {
            new Date(null).getTime() == new Date(next).getTime()? this.progress() : null
        })
    }


    progress() {
        if(this.germanSkillLevelCtr.invalid || this.nationalityCtr.invalid || this.studyEndDateCtl.invalid || this.cityIdCtrl.invalid) {
            this.germanSkillLevelCtr.markAsTouched()
            this.nationalityCtr.markAsTouched()
            this.studyEndDateCtl.markAsTouched()
            this.cityIdCtrl.markAsTouched()
            return
        }

        this.progressing.next()

        this.onboardingService.updateInputs(<CachedOBInputs>{
            studyEndDate : this.studyEndDateCtl.value,
            germanSkillLevel : this.germanSkillLevelCtr.value,
            talentOrigin: this.nationalityCtr.value,
            talentCityId: this.cityIdCtrl.value
        })

        let data = <TalentCoachRequestDistributionData>{
            studyEndDate: this.studyEndDateCtl.value,
            talentOrigin: this.nationalityCtr.value,
            germanSkillLevel: this.germanSkillLevelCtr.value,
            talentCityId: this.cityIdCtrl.value
        }

        this.onboardingService.updateDistributionCriteriaForCoachRequest(data)
        this.onboardingService.delayedRouting( "/onboarding/s2fax2/password")
    }

    ngOnDestroy(): void {
        this.ctrlSub?.unsubscribe()
    }
}
