<div class="overview-wrapper" *ngIf="companyData && !loadingCompanyData" [ngClass]="{'on-company-profile': !this.router.url.includes('company-profile')}">

    <section
            class="hero-container"
            [ngStyle]="primeCompany ?
            {
            'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + companyId + '/companyProfile/heroImage)'
            } : {}
            "
            [ngClass]="{'basic-desktop-bg': !primeCompany && !mediaService.isMobile(),
                        'basic-mobile-bg': !primeCompany && mediaService.isMobile()
            }">

        <div class="hero-overlay"
             *ngIf="primeCompany"
             [ngStyle]="{'background-color': companyData.primaryColor}"
        ></div>

        <div class="hero-content"
             fxLayout="column" fxLayoutAlign="start center">
            <div class="hero-base-info" *ngIf="mediaService.isMobile() || primeCompany"
                 fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
                <div class="center-content width-100">
                    <div class="company-logo" *ngIf="primeCompany"
                         [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + companyId + '/companyLogo)'}"
                    ></div>
                </div>

                <!--img class="company-logo" *ngIf="primeCompany"
                    [src]="environmentVar.apiUrl + '/public/company/' + companyId + '/companyLogo'"
                -->
                <h1 [ngClass]="{'dark-font': !primeCompany}" class="company-header">{{companyData.companyName}}</h1>
                <h2 [ngClass]="{'dark-font': !primeCompany}" class="hero-tagline">{{companyData.slogan}}</h2>
            </div>
            <div class="hero-base-info width-100 height-100" *ngIf="!mediaService.isMobile() && !primeCompany"
                 fxLayout="row" fxLayoutAlign="center start">
                <div class="height-100" fxLayout="column" fxLayoutAlign="start center" fxFlex="40">
                    <h1 class="company-header" style="color: #313131;">{{companyData.companyName}}</h1>
                    <h2 class="hero-tagline" style="color: #313131;">{{companyData.slogan}}</h2>
                </div>
            </div>

            <div class="hero-contact-box" *ngIf="!primeCompany && companyData.contactPrompt"
                 fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
                <div fxLayout="column" class="width-100" fxLayoutAlign="start start">
                    <p>{{companyData.contactPrompt}}</p>
                </div>
            </div>

            <div class="hero-contact-box" *ngIf="primeCompany"
                 fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                 [ngStyle]="{'box-shadow': '0px 20px 40px ' + companyData.primaryColor + '33'}"
            >
                <div *ngIf="!mediaService.isMobile() && primeCompany" fxFlex="33" fxLayout="column" fxLayoutAlign="start center">
                    <div class="contact-image"
                         [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + companyId + '/companyProfile/contactImage)'
                         }">
                    </div>
                    <p class="contact-info">{{companyData.contactName}}</p>
                    <p class="contact-info">von {{companyData.companyName}}</p>
                </div>
                <div fxFlex.gt-sm="66" *ngIf="primeCompany" fxLayout="column" fxLayoutAlign="start start">
                    <h3>Interesse?</h3>
                    <p>{{companyData.contactPrompt}}</p>
                    <!--div class="width-100 center-content">
                        <button
                                [ngStyle]="primeCompany ? {'background-color': companyData.accentColor} : {}"
                                mat-flat-button class="contact-button">Kontakt aufnehmen!</button>
                    </div-->
                </div>
                <div *ngIf="!primeCompany" fxLayout="column" class="width-100" fxLayoutAlign="start start">
                    <p>{{companyData.contactPrompt}}</p>
                    <!--div class="width-100 center-content">
                        <button
                                [ngStyle]="primeCompany ? {'background-color': companyData.accentColor} : {}"
                                mat-flat-button class="contact-button">Kontakt aufnehmen!</button>
                    </div-->
                </div>
            </div>
        </div>

    </section>

    <div class="company-content-wrapper">
    <section fxLayout="column" fxLayoutAlign="end start" class="content-block  intro-section" *ngIf="companyData.introText">
        <div class="safe-formatting"  [innerHTML]="companyData.introText | safeHtml"></div>
        <div  class="width-100 center-content m-top15" *ngIf="showPosiitons">
            <button
                    (click)="scrollToJobs()"
                    mat-flat-button class="contact-button">
                Zu den Stellen!
            </button>
        </div>
    </section>

    <div style="padding: 20px;" *ngIf="primeCompany">
        <app-image-gallery
                *ngIf="imageUrls.length > 0 || videoUrl?.length > 0"
                [videoUrl]="videoUrl"
                [apiImageUrls]="imageUrls">
        </app-image-gallery>
    </div>

    <section class="content-block" *ngIf="companyData.cultureText">
        <h2 class="block-header">So arbeiten wir</h2>
        <div class="safe-formatting"  [innerHTML]="companyData.cultureText | safeHtml"></div>
        <div
                *ngIf="primeCompany "
                [ngStyle]="primeCompany && companyData.primaryColor ? {'background-image': 'linear-gradient(to bottom right, ' + companyData.primaryColor + ', ' + companyData.accentColor}
                 : {'background-color': '#4d4d4d'}"
                class="orb orb-01"></div>
        <div
                *ngIf="primeCompany "
                [ngStyle]="primeCompany && companyData.primaryColor ? {'background-image': 'linear-gradient(to bottom right, ' + companyData.primaryColor + ', ' + companyData.accentColor}
                 : {'background-color': '#4d4d4d'}"
                class="orb orb-02"></div>

        <div
                *ngIf="primeCompany "
                [ngStyle]="primeCompany && companyData.primaryColor ? {'background-image': 'linear-gradient(to bottom right, ' + companyData.primaryColor + ', ' + companyData.accentColor}
                 : {'background-color': '#4d4d4d'}"
                class="orb orb-05"></div>
    </section>

    <section class="content-block" *ngIf="companyData.locationText">
        <h2 class="block-header">Unser Standort</h2>
        <div class="safe-formatting"  [innerHTML]="companyData.locationText | safeHtml"></div>
    </section>

    <section class="content-block" *ngIf="companyData.officeText">
        <h2 class="block-header">Unser Büro</h2>
        <div class="safe-formatting"  [innerHTML]="companyData.officeText | safeHtml"></div>
        <div
                *ngIf="primeCompany "
                [ngStyle]="primeCompany && companyData.primaryColor ? {'background-image': 'linear-gradient(to bottom right, ' + companyData.primaryColor + ', ' + companyData.accentColor}
                 : {'background-color': '#4d4d4d'}"
                class="orb orb-03"></div>
        <div
                *ngIf="primeCompany "
                [ngStyle]="primeCompany && companyData.primaryColor ? {'background-image': 'linear-gradient(to bottom right, ' + companyData.primaryColor + ', ' + companyData.accentColor}
                 : {'background-color': '#4d4d4d'}"
                class="orb orb-04"></div>
    </section>

    <section class="content-block" *ngIf="companyData.benefitsText">
        <h2 class="block-header">Deine Vorteile</h2>
        <div class="safe-formatting" [innerHTML]="companyData.benefitsText | safeHtml"></div>
    </section>
</div>
</div>
<section id="jobContainer" #jobSection *ngIf="showPosiitons">
    <h2 class="block-header max-w1440">Jobs bei {{companyData?.companyName}}</h2>
    <app-positions-wrapper-preview-details
            [origin]="'CompanyProfile'"
            [loading]="loadingPositions"
            [positions]="positionsOfCompany"
            [page]="0"
            [totalPositions]="positionsOfCompany.length"
            [totalPages]="1"
    >
    </app-positions-wrapper-preview-details>

    <div class="position-placeholder" *ngIf="!authService.isAuthenticated() && !loadingPositions.value">
        Eingeloggte Talentagent-Nutzer sehen hier die Stellen von {{companyData?.companyName}}
    </div>
</section>
