import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaService} from "../services/inno-utils/media.service";
import {environment} from "../../environments/environment";
import {SearchService} from "../services/search.service";
import {TalentAuthService} from "../services/talent.auth.service";
import {PositionService} from "../services/position.service";
import {ActivatedRoute} from "@angular/router";
import {BottomSheetService} from "../utility/slideable-bottom-sheets/bottom-sheet.service";
import {Subscription} from "rxjs";
import {TalentAccountResource, TalentPositionRelationData} from "utility";
import {Location} from "@angular/common";

@Component({
    selector: 'app-positions',
    templateUrl: './positions.component.html',
    styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit, OnDestroy {

    public siteNoticeLink = environment.publicPageUrl + '/site-notice'
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public useRegulations = environment.publicPageUrl + '/use-regulations'

    positionIdsParam
    pageParam
    pageSizeParam

    removePositionSub: Subscription

    constructor(
        public mediaService: MediaService,
        public authService: TalentAuthService,
        public searchService: SearchService,
        public positionService: PositionService,
        private route: ActivatedRoute,
        public bottomSheetService: BottomSheetService,
        private location: Location,
        private talentAccountResource: TalentAccountResource
    ) {
        this.positionIdsParam = this.route.snapshot.queryParams['positionIds']
        this.pageParam = this.route.snapshot.queryParams['page']
        this.pageSizeParam = this.route.snapshot.queryParams['pageSize']

        this.removePositionSub = this.positionService.relationProceeded.subscribe(next => {
            if (next.action == "Applied") this.removePositionAfterApplication(next.relation)
        })

        //removes params from url after they have been declared and used
        this.location.replaceState(this.route.snapshot.url.map(segment => segment.path).join("/"))
    }

    async ngOnInit() {
        this.talentAccountResource.createTalentFirstVisitedFeedLogEntryIfNecessary()
        await this.searchService.updateLastFilter();
        this.searchService.searchFilterData.forcedPositionIds = this.positionIdsParam?.split(',').map(p => parseInt(p)) ?? [];
        this.searchService.page = this.pageParam ? this.pageParam : this.searchService.page;
        this.searchService.pageSize = this.pageSizeParam ? this.pageSizeParam : this.searchService.pageSize;
        this.searchService.triggerSearch(this.searchService.page, this.searchService.pageSize);
    }

    ngOnDestroy() {
        this.removePositionSub?.unsubscribe()
    }

    removePositionAfterApplication(position: TalentPositionRelationData) {
        this.positionService.removePositionFromList(position, this.searchService.searchPositions).then(() => {
            this.searchService.totalPositions -= 1
        })
    }
}
