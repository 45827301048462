<button mat-flat-button [color]="color"
        class="width-100 ita_upload_cv"
        [ngStyle]="{'border-radius.px': borderRadius}"
        [class]="buttonClass"
        (click)="cvInput.click()"
        [loadingBool]="savingCv">
    <i [style.color]="bttColor" class="mdi mdi-upload m-right10"></i>
    <span [style.color]="bttColor">Lebenslauf hochladen</span>
</button>

<div fxLayout="column" fxLayoutAlign="start center" class="width-100">
    <div *ngIf="cvFile" fxLayout="column" fxLayoutAlign="start center" class="width-100 text-center file-errors" style="padding: 0 10px">
        <small class="file-error" *ngIf="cvFile.errors != null && cvFile.errors['sizeTooBig']">
            Die Datei ist größer als 10MB, Bitte wähle eine kleinere Datei.
        </small>
        <small class="file-error" *ngIf="cvFile.errors != null && cvFile.errors['wrongExtension']">
            Falsches Format. Bitte wähle ein PDF-Dokument.
        </small>
        <small class="file-error" *ngIf="cvFile.errors != null && cvFile.errors['fileIsEmpty']">
            Die ausgewählte Datei ist leer.
        </small>
    </div>
</div>

<input style="display: none" #cvInput type="file" (change)="saveCurriculumVitae()"
       [accept]="fileService.whiteListedCvExtensionsStr"
       [formControl]="cvFile" id="file" class="inputfile">
