import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subject} from "rxjs";

@Component({
  selector: 'hid-checkbox',
  templateUrl: './hid-checkbox.component.html',
  styleUrls: ['./hid-checkbox.component.scss']
})
export class HidCheckboxComponent implements OnInit {

  @Input() model: boolean
  @Input() control: FormControl<boolean>
  @Input() inputClass: string = '';
  @Input() highlightErrors: Subject<boolean>;
  _highlightErrors;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (!this.control) {
      this.control = new FormControl<boolean>(false)
    }
    this.control.valueChanges.subscribe(value => {
      this.modelChange.emit(value)
    })

    if (this.highlightErrors) {
      this.highlightErrors.subscribe(res => {
        this._highlightErrors = res;
      })
    }
  }


}
