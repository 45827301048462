import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S2Fax2OEveProgressComponent } from './s2-fax2-o-eve-progress/s2-fax2-o-eve-progress.component';
import { S2Fax2OCvUploadComponent } from './s2-fax2-o-cv-upload/s2-fax2-o-cv-upload.component';
import { S2Fax2OMatchingDataComponent } from './s2-fax2-o-matching-data/s2-fax2-o-matching-data.component';
import { S2Fax2OPasswordComponent } from './s2-fax2-o-password/s2-fax2-o-password.component';
import { S2Fax2OSelectSubjectComponent } from './s2-fax2-o-select-subject/s2-fax2-o-select-subject.component';
import { S2Fax2OStepHeadlineComponent } from './s2-fax2-o-step-headline/s2-fax2-o-step-headline.component';
import { S2Fax2OWelcomeComponent } from './s2-fax2-o-welcome/s2-fax2-o-welcome.component';
import { S2Fax2OnboardingComponent } from './s2-fax2-onboarding/s2-fax2-onboarding.component';
import { S2Fax2OCoachComponent } from "./s2-fax2-o-coach/s2-fax2-o-coach.component";
import { S2Fax2OCoachDataComponent } from "./s2-fax2-o-coach-data/s2-fax2-o-coach-data.component";
import { S2Fax2OContactDataComponent } from "./s2-fax2-o-contact-data/s2-fax2-o-contact-data.component";
import { S2Fax2OButtonBarComponent } from "./s2-fax2-o-button-bar/s2-fax2-o-button-bar.component";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {UtilityModule} from "utility";
import {RouterModule, Routes} from "@angular/router";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import {S2FaxOnboardingModule} from "../s2-fax-onboarding/s2-fax-onboarding.module";

const appRoutes: Routes = [
    {
        path: 'onboarding/s2fax2', component: S2Fax2OnboardingComponent, canActivate: [OnboardingAuthGuardService],
        children: [
            {path: 'welcome', component: S2Fax2OWelcomeComponent},
            {path: 'subject', component: S2Fax2OSelectSubjectComponent},
            {path: 'preferences', component: S2Fax2OMatchingDataComponent},
            {path: 'contact-data', component: S2Fax2OContactDataComponent},
            {path: 'coach-intent', component: S2Fax2OCoachComponent},
            {path: 'coach-data', component: S2Fax2OCoachDataComponent},
            {path: 'password', component: S2Fax2OPasswordComponent},
            {path: 'cv', component: S2Fax2OCvUploadComponent}
        ]
    }];

@NgModule({
  declarations: [
    S2Fax2OButtonBarComponent,
    S2Fax2OCoachComponent,
    S2Fax2OCoachDataComponent,
    S2Fax2OContactDataComponent,
    S2Fax2OEveProgressComponent,
    S2Fax2OCvUploadComponent,
    S2Fax2OMatchingDataComponent,
    S2Fax2OPasswordComponent,
    S2Fax2OSelectSubjectComponent,
    S2Fax2OStepHeadlineComponent,
    S2Fax2OWelcomeComponent,
    S2Fax2OnboardingComponent
  ],
    imports: [
        MatDialogModule,
        CommonModule,
        UtilityModule,
        RouterModule.forChild(appRoutes),
        HidLibCloneModule,
        S2FaxOnboardingModule
    ]
})
export class S2Fax2OnboardingModule { }
