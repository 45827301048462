import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, GeneralConditionsOfParticipationTemplateData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.LegalAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class LegalAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/{formatId}/conditionsOfParticipation',
        endpoint: 'de.syntacton.cronos'
    })
    private _getCurrentConditionsOfParticipation: IResourceMethodObservableStrict<null, null, {formatId: number}, JsonWrappedValue<string>> | undefined
    getCurrentConditionsOfParticipation(formatId: number): Promise<JsonWrappedValue<string>> {
        if (!this._getCurrentConditionsOfParticipation) throw new Error("resource has not been properly initialized")
        return this._getCurrentConditionsOfParticipation(null, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/general/conditionsOfParticipation',
        endpoint: 'de.syntacton.cronos'
    })
    private _getCurrentGeneralConditionsOfParticipation: IResourceMethodObservableStrict<null, null, null, GeneralConditionsOfParticipationTemplateData> | undefined
    getCurrentGeneralConditionsOfParticipation(): Promise<GeneralConditionsOfParticipationTemplateData> {
        if (!this._getCurrentGeneralConditionsOfParticipation) throw new Error("resource has not been properly initialized")
        return this._getCurrentGeneralConditionsOfParticipation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/privacy',
        endpoint: 'de.syntacton.cronos'
    })
    private _getCurrentPrivacyText: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string>> | undefined
    getCurrentPrivacyText(): Promise<JsonWrappedValue<string>> {
        if (!this._getCurrentPrivacyText) throw new Error("resource has not been properly initialized")
        return this._getCurrentPrivacyText(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/{formatId}/privacy',
        endpoint: 'de.syntacton.cronos'
    })
    private _getCurrentPrivacyTextForFormat: IResourceMethodObservableStrict<null, null, {formatId: number}, JsonWrappedValue<string>> | undefined
    getCurrentPrivacyTextForFormat(formatId: number): Promise<JsonWrappedValue<string>> {
        if (!this._getCurrentPrivacyTextForFormat) throw new Error("resource has not been properly initialized")
        return this._getCurrentPrivacyTextForFormat(null, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/promotion/{promotionId}/privacy',
        endpoint: 'de.syntacton.cronos'
    })
    private _getCurrentPrivacyTextForPromotion: IResourceMethodObservableStrict<null, null, {promotionId: number}, JsonWrappedValue<string>> | undefined
    getCurrentPrivacyTextForPromotion(promotionId: number): Promise<JsonWrappedValue<string>> {
        if (!this._getCurrentPrivacyTextForPromotion) throw new Error("resource has not been properly initialized")
        return this._getCurrentPrivacyTextForPromotion(null, null, {promotionId: promotionId}).toPromise()
    }

}