<div class="loading-spinner-content-wrapper" [ngClass]="{'activateBackdrop': activateBackdrop}">
  <div class="loading-spinner-overlay" *ngIf="loadingVar && mode != 'skeleton'" fxLayoutAlign="center center">
    <div class="lds-ellipsis">
      <div [ngStyle]="{'background': color}"></div>
      <div [ngStyle]="{'background': color}"></div>
      <div [ngStyle]="{'background': color}"></div>
      <div [ngStyle]="{'background': color}"></div>
    </div>
  </div>
  <div class="loading-spinner-overlay-skeleton" *ngIf="loadingVar && mode == 'skeleton'" fxLayoutAlign="center center">
  </div>
  <ng-content></ng-content>

</div>



