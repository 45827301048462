
<div fxLayout="column" style="width: 100%">
    <app-s2-fax2-o-step-headline >Gute Entscheidung {{this.onboardingService?.cachedInputs?.firstName}}! 💪 In welcher Stadt wohnst du?</app-s2-fax2-o-step-headline>

    <div>
        <location-input-search
                [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                style="width: 100%"
                label="Name oder PLZ"
                [control]="cityIdCtrl"
        ></location-input-search>

        <small style="color: red; font-size: 12px" *ngIf="cityIdCtrl.errors && cityIdCtrl.touched">
            Bitte wähle deinen Wohnort aus, um fortzufahren.
        </small>
    </div>

    <app-s2-fax2-o-step-headline>Wann schließt du dein Studium ab? <br> <span>(Eine Schätzung reicht vollkommen aus)</span></app-s2-fax2-o-step-headline>

    <div  fxLayout="column" fxLayoutAlign="center center">
        <div class="step-content-ob" style="margin-top: 20px">
            <study-end-date-selection [control]="studyEndDateCtl"></study-end-date-selection>
        </div>
        <div *ngIf="this.studyEndDateCtl.touched && this.studyEndDateCtl.invalid"
             class="hid-validationError duplicate-mail-error"
             style="margin-top: 10px; padding: 5px 20px; text-align: center">
            Bitte gib dein ungefähres Studienende an
        </div>
    </div>

    <german-skill-level [germanSkillLevelCtrl]="germanSkillLevelCtr" [nationalityCtrl]="nationalityCtr">
        <app-s2-fax2-o-step-headline nationality>Welche Staatsbürgerschaft hast du?</app-s2-fax2-o-step-headline>
        <app-s2-fax2-o-step-headline language>Wie gut sprichst du Deutsch?</app-s2-fax2-o-step-headline>
    </german-skill-level>

</div>

<app-s2-fax2-o-button-bar (pressedNext)="progress()"></app-s2-fax2-o-button-bar>
