import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {EventOnboardingService} from "../event-onboarding.service";

@Component({
  selector: 'app-event-ob-eve-progress',
  templateUrl: './event-ob-eve-progress.component.html',
  styleUrls: ['./event-ob-eve-progress.component.scss']
})
export class EventObEveProgressComponent implements OnInit {


  @Input() currentstep = 1;
  @Input() totalsteps;
  handleStepsManually: boolean = false

  constructor(
      private obService: EventOnboardingService,
      private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    if(!this.totalsteps){
      this.totalsteps = 2* Math.floor(this.route.routeConfig.children.length/2);
    }else{
      this.handleStepsManually = true
    }

    this.router.events.subscribe(evt => {
      if(evt instanceof NavigationEnd){
        this.updateCurrentStep()
      }
    })

    this.updateCurrentStep()

  }

  updateCurrentStep(){
    if(!this.handleStepsManually){
      let lastUrlSegment = window.location.href.split("/")[window.location.href.split("/").length - 1]
      this.currentstep = this.route.routeConfig.children.map(step => step.path).indexOf(lastUrlSegment) +1
    }
  }

}
