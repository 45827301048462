import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
    selector: 'study-end-date-selection',
    templateUrl: './study-end-date-selection.component.html',
    styleUrls: ['./study-end-date-selection.component.scss']
})
export class StudyEndDateSelectionComponent implements OnInit {

    year: number
    month: number

    @Input() control: AbstractControl
    @Output() changeControl: EventEmitter<Date> = new EventEmitter<Date>()

    constructor() {
    }

    ngOnInit(): void {
        if (this.control.value) {
            let date = new Date(this.control.value)

            //for handling case talent has already finished study
            if (date.getTime() == 0) {
                this.month = 0
                this.year = new Date().getFullYear()
                return
            }

            this.year = date.getFullYear()
            let month = date.getMonth() / 3


            if (month < 1) this.month = 1;
            else if (month < 2) this.month = 4
            else if (month < 3) this.month = 7
            else this.month = 10

        } else {
            this.year = new Date().getFullYear()
        }
    }

    updateYear(sum: number) {
        this.year = this.year + sum
        this.setNewDate()
    }


    updateMonth(month: number) {
        this.month = month
        this.setNewDate()
    }

    setNewDate() {
        this.control.markAsDirty()
        this.control.markAsTouched()
        if (this.month == null || this.year == null) return

        let date
        this.month == 0 ? date = new Date() : date = new Date(this.year, this.month - 1, 1, 0, 0, 0, 0)

        this.control.setValue(date)
        this.changeControl.emit(this.control.value)
    }
}
