<div class="home-c-w">
    <home-headline routerLink="/talent/proposals/bookmarks" style="cursor: pointer" icon="heart-outline" iconBgColor="#00A0FF">Meine Merkliste ({{allBookmarks?.length}})</home-headline>
    <div class="home-c-c-w">
        <div>
            <div class="bookmarked" *ngFor="let b of allBookmarks">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="routeToPosition(b)" class="position-info">
                        <company-picture class="company-picture-small" [apiPositionLogoUrl]="b.employmentPosition.companyLogoUrl" [positionId]="b.employmentPosition.id"></company-picture>
                        <div class="title">{{b.employmentPosition.title}}</div>
                    </div>

                    <button style="color:white" class="apply-button" mat-flat-button color="primary" (click)="openApplicationDialog(b)">Jetzt Bewerben</button>
                </div>

                <!--            <div class="state">{{a.state}}</div>-->
            </div>
            <div class="nothing-found" *ngIf="!allBookmarks.length && !loadingSubject.value">Du hast leider noch keine Stellen als Merkliste markiert</div>
        </div>
    </div>
</div>