import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  /*
    TODO did noone tell you about ng-content??
    Try <dialog-header>gafasa</dialog-header>
    ->
    <div class="whatever"><ng-content></ng-content></div>
   */
  @Input() headline: string

  constructor() { }

  ngOnInit(): void {
  }

}
