<div
        [ngClass]="{'close-active': showCloseIndicator}"
        (click)="closeSheet()" class="close-indicator" id="close-indicator">
    <i class="mdi mdi-close"></i>
</div>

<div  #scrollableContent class="padding scrollable-content">
    <div class="flex center width-100">
        <div class="sheet-handle"></div>
    </div>
</div>
