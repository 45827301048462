<div class="main-wrapper">


    <app-co-eve-progress></app-co-eve-progress>


    <div style="max-width: 600px; margin: 0 auto; padding-bottom: 100px" [ngClass]="{'animation-offset': animationPhase === 1, 'transition-on': animationPhase > 1 }" class="router-wrapper">
        <router-outlet ></router-outlet>
    </div>
</div>
