import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-event-ob-button-bar',
  templateUrl: './event-ob-button-bar.component.html',
  styleUrls: ['./event-ob-button-bar.component.scss']
})
export class EventObButtonBarComponent implements OnInit {

  @Input()
  backButton = true;

  @Input()
  skipButton = false;

  @Input()
  buttonLockSubject: Subject<any>;

  @Input()
  validSubject: Subject<boolean>

  @Output()
  pressedSkip = new EventEmitter();

  @Output()
  pressedNext = new EventEmitter()

  buttonsLocked = false;

  @Input()
  valid = true;

  constructor() {
  }

  ngOnInit(): void {
    if (this.buttonLockSubject) {
      this.buttonLockSubject.subscribe(() => {
        this.buttonsLocked = true;
      })
    }

    if (this.validSubject) {
      this.valid = false;
      this.validSubject.subscribe(isValid => {
        this.valid = isValid
      })
    }
  }

  back() {
    history.back()
  }
}