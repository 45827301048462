import { Component, OnInit } from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-cv-incentive-dialog',
  templateUrl: './cv-incentive-dialog.component.html',
  styleUrls: ['./cv-incentive-dialog.component.scss']
})
export class CvIncentiveDialogComponent implements OnInit {

  constructor(
      private dialogRef: MatDialogRef<CvIncentiveDialogComponent>,
      private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  closeDialog(result: boolean = false) {
    this.dialogRef.close(result)
  }

  cvUploaded() {
    this.matSnackBar.open("Super! Dein Lebenslauf wurde hochgeladen!", null, {duration: 3000})
    this.closeDialog(true)
  }

}
