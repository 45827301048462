import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";
import {PublicEmploymentPositionResource, TalentPositionDetailsData, TalentPositionInteractionOrigin} from "utility";
import {BehaviorSubject, Subscription} from "rxjs";
import {PositionService} from "../../services/position.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-positions-details',
    templateUrl: './positions-details.component.html',
    styleUrls: ['./positions-details.component.scss']
})

export class PositionsDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

    sticky: boolean = false

    positionRelation: TalentPositionDetailsData
    loading: boolean = true


    @ViewChild('stickyContainer') stickyContainer: ElementRef = new ElementRef<any>(null)
    @ViewChild('mobileStickyContainer') mobileStickyContainer: ElementRef = new ElementRef<any>(null)

    stickyInitialized: boolean = false

    @Input() positionId: BehaviorSubject<number>
    @Input() splitId: number | null = null
    @Input() loadingPreviews: BehaviorSubject<boolean>
    @Input() origin?: TalentPositionInteractionOrigin;

    positionIdSubscription: Subscription

    intersectionObserver: IntersectionObserver

    loadingTopjobImage: boolean = true
    topJobContactImage: string = ''

    constructor(
        public mediaService: MediaService,
        private publicEmploymentPositionResource: PublicEmploymentPositionResource,
        private changeDetection: ChangeDetectorRef,
        private positionService: PositionService,
        private http: HttpClient
    ) {
    }

    ngOnDestroy(): void {
        this.positionIdSubscription?.unsubscribe()
    }

    ngOnInit(): void {
        this.getPositionDetails()
        this.subscribeOnPositionIdChange()
    }

    subscribeOnPositionIdChange() {
        this.positionIdSubscription = this.positionId.subscribe(() => {
            this.positionRelation = null
            if (this.intersectionObserver) this.unObserveIntersectionObserver()
            this.getPositionDetails()
        })
    }

    ngAfterViewChecked() {
        if (!this.stickyInitialized && this.stickyContainer?.nativeElement) {
            this.initIntersectionObserver()
            this.stickyInitialized = true
        }
    }

    unObserveIntersectionObserver() {
        if(this.stickyContainer?.nativeElement) this.intersectionObserver?.unobserve(this.stickyContainer.nativeElement)
        this.stickyInitialized = false
    }

    getPositionDetails() {
        if (this.positionId?.value) {
            this.loading = true
            this.changeDetection.detectChanges()
            this.publicEmploymentPositionResource.getEmploymentPositionById(this.positionId?.value, {
                origin: this.origin,
                splitId: this.splitId
            }).then(result => {
                if (this.positionId.value === this.positionService.selectedPositionId.value) {
                    this.positionRelation = result
                    if(this.positionRelation?.employmentPosition?.topJobSlot){
                        this.getTopjobContactImage()
                    }
                    this.loading = false
                }
            })
        } else {
            this.positionRelation = null
            this.loading = false
        }

    }

    initIntersectionObserver() {
        if (this.stickyContainer?.nativeElement) {
            this.intersectionObserver = new IntersectionObserver((entries: any) => {
                    entries.forEach((entry: any) => {

                        //function to add or remove styles based on if the apply button container becomes sticky
                        if (entry.boundingClientRect.top < 0 && entry.intersectionRatio < 1) {
                            if (!this.mediaService.getMaxWidthQuery(960).matches) {
                                this.sticky = true
                                this.stickyContainer.nativeElement.classList.add('sticky-apply-buttons')
                            } else {
                                this.mobileStickyContainer.nativeElement.classList.add('sticky-mobile-apply-buttons')
                            }

                        } else {
                            if (!this.mediaService.getMaxWidthQuery(960).matches) {
                                this.sticky = false
                                this.stickyContainer.nativeElement.classList.remove('sticky-apply-buttons')
                            } else {
                                this.mobileStickyContainer.nativeElement.classList.remove('sticky-mobile-apply-buttons')
                            }
                        }

                    })
                },
                {
                    threshold: [0, 1]
                }
            )

            this.intersectionObserver.observe(this.stickyContainer.nativeElement)
        }

    }


    getTopjobContactImage() {
        this.getTopJobSlotHeaderImage(this.positionRelation.employmentPosition.topJobSlot.content.id)
            .then((blob) => {
                this.topJobContactImage = this.convertBlobToImageUrl(blob);
                this.loadingTopjobImage = false;
            })
            .catch((error) => {
                console.error('Error loading top job slot header image', error);
                this.loadingTopjobImage = false;
            });
    }

    getTopJobSlotHeaderImage(slotContentId: number): Promise<Blob> {
        const url = environment.apiUrl + `/public/topJobContents/${slotContentId}/contactImage`; // Adjust the URL as needed
        return this.http.get(url, { responseType: 'blob' }).toPromise();
    }

    convertBlobToImageUrl(blob: Blob): string {
        return URL.createObjectURL(blob);
    }

    protected readonly postMessage = postMessage;
}
