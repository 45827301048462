import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CoachingTypeData, CoachingTypeResource, TalentOnboardingResource} from "utility";
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {EventCachedOBInputs, EventOnboardingService} from "../event-onboarding.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'app-event-ob-coach',
  templateUrl: './event-ob-coach.component.html',
  styleUrls: ['./event-ob-coach.component.scss']
})
export class EventObCoachComponent implements OnInit {


  public coachingsForm = new FormGroup({});
  public availableCoachingTypes: CoachingTypeData[];
  public selectedCoachingTypes: CoachingTypeData[];
  public privacyUrl = environment.publicPageUrl + '/privacy'
  public progessingSubject = new Subject()

  constructor(
      private coachingTypeResource: CoachingTypeResource,
      private onboardingService: EventOnboardingService,
      private onboardingResource: TalentOnboardingResource,
      private authService: TalentAuthService
  ) {
  }

  ngOnInit() {
    this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
      this.availableCoachingTypes = coachings;

      this.availableCoachingTypes.forEach(coaching => {
        if (coaching.title == 'Hilfe im Bewerbungsprozess') {
          this.coachingsForm.addControl(coaching.title, new FormControl({value: true, disabled: true}));
        } else {
          this.coachingsForm.addControl(coaching.title, new FormControl(false));
        }
      });

      this.coachingTypeResource.getSelectedCoachingTypes().then(coachings => {
        this.selectedCoachingTypes = coachings;

        this.selectedCoachingTypes.forEach(coaching => {
          this.coachingsForm.controls[coaching.title].setValue(true)
        })
      })
    })
  }

  ngOnDestroy() {
  }

  progress(wantsCoachValue: boolean) {
    this.progessingSubject.next()
    this.onboardingService.updateInputs(<EventCachedOBInputs>{
      wantsCoach: wantsCoachValue
    })

    let choosenCoachingIds: number[] = [];

    let context = 'next';
    if(!wantsCoachValue){
      context = 'skip'
    }

    if(wantsCoachValue) {
      Object.keys(this.coachingsForm.controls).forEach(controlName => {
        if (this.coachingsForm.controls[controlName].value == true) {
          choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.title == controlName)[0].id);
        }
      });

      this.onboardingResource.requestCoach(choosenCoachingIds).then(() => {
        this.onboardingService.delayedRouting("/onboarding/event/coach-data", context)
      });
    } else{
      this.onboardingService.finalizeTalentOnboardingForTalent()
      this.authService.updateOnboardingCompleted(true).then(() => {
        this.onboardingService.routeToPlattform()
      })



    }
  }

}
