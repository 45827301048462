import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {PositionService} from "../../services/position.service";
import {MediaService} from "../../services/inno-utils/media.service";
import {TalentPositionDetailsData} from "utility";
import {BottomSheetService} from "../../utility/slideable-bottom-sheets/bottom-sheet.service";

export class ExternalCTAClickReport{
  relation: TalentPositionDetailsData
  origin: 'Recommendations' | 'Matches' | 'Search' | 'Bookmarks'
}

@Component({
  selector: 'app-confirm-external-application-alert',
  templateUrl: './confirm-external-application-alert.component.html',
  styleUrls: ['./confirm-external-application-alert.component.scss']
})
export class ConfirmExternalApplicationAlertComponent implements OnInit {

  report: ExternalCTAClickReport
  confirming = false;
  confirmed = false;

  constructor(
      @Inject(MAT_DIALOG_DATA)data: ExternalCTAClickReport,
      public mediaService: MediaService,
      private positionService: PositionService,
      private dialogRef: MatDialogRef<ConfirmExternalApplicationAlertComponent>,
      private bottomSheetService: BottomSheetService
  ) {
    this.report = data
  }

  ngOnInit(): void {

  }

  confirm(){
    if (this.confirming) return
    this.confirming = true;
    this.positionService.sendExternApplication(this.report.origin, this.report.relation).then(() => {
      this.confirmed = true
      this.bottomSheetService.closeCurrentBottomSheet()
    })
  }

  close(){
    this.dialogRef.close(this.confirmed)
  }
}
