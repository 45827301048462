import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";
import {PositionService} from "../../services/position.service";
import {
  TalentPositionRelationData,
} from "utility";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {RecommendationService} from "../recommendation.service";

@Component({
  selector: 'app-positions-recommended',
  templateUrl: './positions-recommended.component.html',
  styleUrls: ['./positions-recommended.component.scss']
})
export class PositionsRecommendedComponent implements OnInit, OnDestroy {

  removePositionSub: Subscription
  selectedPositionId: number
  constructor(
      public mediaService: MediaService,
      public positionService: PositionService,
      public recommendationService: RecommendationService,
      private router: Router,
      private route: ActivatedRoute
  ) {
    this.removePositionSub = this.positionService.relationProceeded.subscribe(next => {
      if (next.action === "Applied") this.removePositionAfterApplication(next.relation)
    })

      this.selectedPositionId = this.route.snapshot.queryParams['positionId']
  }


  ngOnInit(): void {
        this.recommendationService.loadPositionRecommendations(this.selectedPositionId)
  }

  ngOnDestroy() {
    this.removePositionSub?.unsubscribe()
  }

    routeToSettings() {
        this.router.navigate(['/account'], {queryParams: {tab: 2}});
    }

  removePositionAfterApplication(position: TalentPositionRelationData) {
    this.positionService.removePositionFromList(position, this.recommendationService.recommendedPositions)
  }

}
