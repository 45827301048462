import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {MediaService} from "../../../services/inno-utils/media.service";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";
import {Level, Origin} from "utility";

@Component({
  selector: 'app-co-coach-data',
  templateUrl: './co-coach-data.component.html',
  styleUrls: ['./co-coach-data.component.scss']
})
export class CoCoachDataComponent implements OnInit {
  progressing: Subject<any> = new Subject<any>()
  error: BehaviorSubject<any> = new BehaviorSubject<any>(false)
  readonly studyEndDateCtl = new FormControl<Date>(
      this.onboardingService.cachedInputs.studyEndDate ? new Date(this.onboardingService.cachedInputs.studyEndDate) : null,
      [Validators.required]
  );
  nationalityCtr = new FormControl<Origin>(this.onboardingService.cachedInputs.talentOrigin, [Validators.required])
  talentCityCtrl = new FormControl<number>(this.onboardingService.cachedInputs.talentCityId, [Validators.required])
  germanSkillLevelCtr = new FormControl<Level>(this.onboardingService.cachedInputs.germanSkillLevel)
  ctrlSub: Subscription

  constructor(
      public onboardingService: CoachingOnboardingService,
      public mediaService: MediaService) {
  }

  ngOnInit() {
    this.ctrlSub = this.studyEndDateCtl.valueChanges.subscribe(next => {
      new Date(null).getTime() == new Date(next).getTime()? this.progress() : null
    })
  }


  progress() {
    if(this.germanSkillLevelCtr.invalid || this.nationalityCtr.invalid || this.studyEndDateCtl.invalid || this.talentCityCtrl.invalid) {
      this.germanSkillLevelCtr.markAsTouched()
      this.nationalityCtr.markAsTouched()
      this.studyEndDateCtl.markAsTouched()
      this.talentCityCtrl.markAsTouched()
      return
    }

    this.progressing.next()

    this.onboardingService.updateInputs(<CachedOBInputs>{
      studyEndDate : this.studyEndDateCtl.value,
      germanSkillLevel : this.germanSkillLevelCtr.value,
      talentOrigin: this.nationalityCtr.value,
      talentCityId: this.talentCityCtrl.value
    })

    this.onboardingService.delayedRouting( "/onboarding/coach/contact-data")

  }
}
