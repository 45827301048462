<div fxLayout="column" class="dialog">

    <div id="headline" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="font-size-16">Bewerte deinen Karrierecoach</h3>
        <i style="margin-right: 4px" class="mdi mdi-close mdi-24px clickable" matTooltip="Abbrechen"
           (click)="close()"></i>
    </div>

    <div id="content-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div class="explanation-text">Wir möchten jedem Absolventen auf Talentagent ein hochwertiges Coaching-Angebot bereitstellen.
            Hilf uns dabei und sag uns, wie gut dich dein Coach unterstützt!
        </div>

        <hid-star-rating class="m-top20 m-bottom5" size="50px" (valueChange)="selectedRating = $event"></hid-star-rating>

        <hid-textarea minRows="5" class="width-100 m-top10" label="Kommentar" [(model)]="ratingComment"></hid-textarea>


    </div>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="primary-text-color">
        <button mat-button class="m-right10" (click)="close()">Abbrechen</button>
        <button mat-flat-button color="accent"
                (click)="save()"
                [loadingBool]="saving">Speichern
        </button>
    </mat-dialog-actions>
</div>
