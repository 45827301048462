import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";
import {Subject} from "rxjs";

@Component({
  selector: 'robot-info-box',
  templateUrl: './robot-info-box.component.html',
  styleUrls: ['./robot-info-box.component.scss']
})
export class RobotInfoBoxComponent implements OnInit {

  @Input() icon: string
  @Input() buttonText: string
  @Input() blueBackground: boolean = false;
  @Input() buttonClass: string
  @Input() buttonLoading: Subject<boolean>;
  @Input() buttonPrimary: boolean = false;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  constructor(
      public mediaService: MediaService
  ) { }

  loading = false;
  ngOnInit(): void {
    if(this.buttonLoading){
      this.buttonLoading.subscribe(loading => {
        this.loading = loading
      })
    }
  }

}
