
<ng-content></ng-content>
<mat-form-field color="accent" [appearance]="appearance" class="width-100 search-input" *ngIf="control">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="control" [disabled]="disabled" [multiple]="multiple">
            <div style="height: 200px; width: 100%" *ngIf="loadingOptions"></div>
            <mat-option>
                <ngx-mat-select-search [formControl]="searchControl" [placeholderLabel]="'Nach Begriff suchen'"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let o of showedOptions" [value]="o?.value">
                {{o?.label}}
            </mat-option>
    </mat-select>
</mat-form-field>
