<div class="details-border details-padding flex column gap" *ngIf="!loading && positionRelation">


    <section class="flex column gap" [ngClass]="{'wave-background': mediaService.getMaxWidthQuery(960).matches}">
        <h1 class="details-header" *ngIf="!mediaService.getMaxWidthQuery(960).matches">
            {{positionRelation.employmentPosition.content.title}}
        </h1>

        <h1 class="header-title"  *ngIf="mediaService.getMaxWidthQuery(960).matches" appDynamicFont [minFontSize]="14" [maxFontSize]="24" [content]="(positionRelation.employmentPosition.content.title)">
            {{positionRelation.employmentPosition.content.title}}
        </h1>

        <div class="flex gap-sm position-info"
             [ngClass]="{'row': !mediaService.getMaxWidthQuery(960).matches, 'start-center': !mediaService.getMaxWidthQuery(960).matches, 'column': mediaService.getMaxWidthQuery(960).matches}">
            <div class="flex row start-center gap">
                <company-picture
                        [whiteBackground]="mediaService.getMaxWidthQuery(960).matches"
                        class="company-image" [apiPositionLogoUrl]="positionRelation.employmentPosition.companyLogoUrl" [positionId]="positionRelation.employmentPosition.id"></company-picture>
                <div
                        [ngClass]="{'white-text-important': mediaService.getMaxWidthQuery(960).matches}"
                        [innerHtml]="positionRelation.employmentPosition.company.name | safeHtml"></div>
            </div>
            <div class="divider-vertical" *ngIf="!mediaService.getMaxWidthQuery(960).matches"></div>
            <locations-marker [positionId]="positionRelation.employmentPosition.id"
                              [lightMode]="mediaService.getMaxWidthQuery(960).matches "
                              [personalizesString]="positionRelation?.employmentPosition?.locationShortDescription"
                              [locationDescription]="positionRelation?.employmentPosition?.locationDescription"></locations-marker>
        </div>

        <position-usp-chips
                *ngIf="positionRelation?.employmentPosition.employmentPositionOrigin == 'Uniwunder'"
                [mobileDetailUsps]="mediaService.isMobile()"
                [positionId]="positionRelation.employmentPosition.id"></position-usp-chips>
    </section>

    <div class="recommendation-text peppy-gradient margin flex row start-center"
         *ngIf="origin === 'Recommendations' && !positionRelation.employmentPosition.topJobSlot && positionRelation.relationData.recommendation?.recommendationText != ''">
        <i class="mdi mdi-star margin-right"></i>
        <p>{{positionRelation.relationData.recommendation?.recommendationText}}</p>
    </div>

    <div class="apply-buttons" #stickyContainer>
        <app-positions-details-apply-buttons
                [lightMode]="mediaService.getMaxWidthQuery(960).matches && !mediaService.getMaxWidthQuery(460).matches"
                [sticky]="sticky" [position]="positionRelation" [origin]="origin"></app-positions-details-apply-buttons>
    </div>

    <section
            *ngIf="positionRelation?.employmentPosition?.topJobSlot"
            class="flex row start-center">
        <img
                [ngStyle]="{
                     'filter': 'drop-shadow(0px 0.5rem 1rem ' + positionRelation?.employmentPosition?.secondaryColor + '33)'}"
                class="topjob-contact-image fast-fade-in"  [src]="topJobContactImage" alt="">

        <div
                [ngStyle]="{
                    'background-color': positionRelation?.employmentPosition?.primaryColor + '18'}"
                class="topjob-content-box">
            <div
                    class="company-color-blob"
                    [ngStyle]="{
                    'background-color': positionRelation?.employmentPosition?.primaryColor}"
                    *ngIf="!mediaService.isMobile()"></div>
            <p class="italic-light-text">"{{positionRelation?.employmentPosition?.topJobSlot.content.bannerText}}"</p>
            <p class="bold-text">{{positionRelation?.employmentPosition?.topJobSlot.content.contactName}}</p>
        </div>
    </section>

    <section *ngIf="positionRelation.employmentPosition.positionShortDescription">
        <div
                [ngClass]="{'padding-vertical-lg': mediaService.getMaxWidthQuery(960).matches}"
        >{{positionRelation.employmentPosition.positionShortDescription}}</div>
    </section>

    <section class="flex column gap-lg">
        <app-image-gallery [videoUrl]="positionRelation.employmentPosition.content.videoEmbedLink"
                           [splitId]="positionRelation.employmentPosition.content.id"
                           [apiImageUrls]="positionRelation.employmentPosition.content.positionApiImages"
                           [customerImages]="positionRelation.employmentPosition.content.positionCustomerImages">
        </app-image-gallery>
        <app-positions-details-info-chips [positionRelation]="positionRelation"></app-positions-details-info-chips>
    </section>

    <section class="flex column gap" *ngIf="positionRelation.employmentPosition.content.positionDescription">
        <h2 class="section-header">
            <i class="mdi mdi-check-decagram"></i>
            Diese Aufgaben erwarten dich
        </h2>
        <div [innerHtml]="positionRelation.employmentPosition.content.positionDescription | safeHtml"></div>
    </section>

    <section class="flex column gap" *ngIf="positionRelation.employmentPosition.content.positionRequirements">
        <h2 class="section-header">
            <i class="mdi mdi-account-heart"></i>
            Damit begeisterst du uns
        </h2>
        <div [innerHTML]="positionRelation.employmentPosition.content.positionRequirements | safeHtml"></div>
    </section>

    <section class="flex column gap" *ngIf="positionRelation.employmentPosition.content.benefitsIntro || positionRelation.employmentPosition.content.benefits?.length">
        <h2 class="section-header">
            <i class="mdi mdi-creation"></i>
            So begeistern wir dich
        </h2>

        <div [innerHtml]="positionRelation.employmentPosition.content.benefitsIntro"></div>

        <app-positions-details-benefits [benefits]="positionRelation.employmentPosition.content.benefits"></app-positions-details-benefits>
    </section>

    <section class="flex column gap" *ngIf="positionRelation.employmentPosition.content.miscellaneousDescription">
        <h2 class="section-header">
            <i class="mdi mdi-lightbulb-on-outline"></i>
            Weitere Informationen
        </h2>
        <div [innerHTML]="positionRelation.employmentPosition.content.miscellaneousDescription | safeHtml"></div>
    </section>

    <section class="flex column"
             *ngIf="positionRelation.employmentPosition.employmentPositionOrigin != 'Stepstone' && positionRelation.employmentPosition.company?.shortCompanyInfo">
        <app-positions-details-company [company]="positionRelation.employmentPosition.company"
                                       [companyDescription]="positionRelation.employmentPosition.content.companyDescription"
            [apiPositionLogoUrl]="positionRelation.employmentPosition.companyLogoUrl">
        </app-positions-details-company>
    </section>

    <section class="flex column" [ngClass]="{'gap-lg': !mediaService.getMaxWidthQuery(960).matches, 'gap-sm': mediaService.getMaxWidthQuery(960).matches, 'mobile-apply-buttons': mediaService.getMaxWidthQuery(960).matches}" #mobileStickyContainer>
        <div class="divider"></div>
        <div class="interested-text" *ngIf="!mediaService.getMaxWidthQuery(960).matches">
            Interessiert? Dann mach den ersten Schritt und bewirb dich jetzt!
        </div>
        <app-positions-details-apply-buttons class="padding-horizontal" [position]="positionRelation" [origin]="origin" [sticky]="mediaService.isMobile()"></app-positions-details-apply-buttons>
    </section>

</div>

<app-positions-details-skeleton *ngIf="loading || loadingPreviews?.value"></app-positions-details-skeleton>
