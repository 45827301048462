import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {delay} from "utility";
import {CVParsingOnboardingService} from "../cv-parsing-onboarding.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'app-cv-parsing-onboarding',
  templateUrl: './cv-parsing-onboarding.component.html',
  styleUrls: ['./cv-parsing-onboarding.component.scss']
})
export class CVParsingOnboardingComponent implements OnInit {


  constructor(
      public obService: CVParsingOnboardingService,
      public router:Router,
      private activatedRoute: ActivatedRoute,
      private authService: TalentAuthService
  ) { }


  @HostListener("window:popstate", ['$event'])
  onBrowserBack(event){
    //prevent report pageview on browser back to onboarding split start component
    if (this.router.url != '/onboarding/cvp') this.obService.reportCurrentStepPageview()
    //browser back on welcome side, to route back and not show empty page
    if (this.router.url === '/onboarding/cvp/cv-welcome') this.back()
  }

  ngOnInit(): void {
    //this array contains all child-routes that are currently active. If its empty, none is active and therefore we gotta do an initial routing
    if (this.authService.isAuthenticated() && !this.authService.getAccountInfo().onboardingCompleted) {
      this.router.navigate(["onboarding/cvp/coach-intent"])
    } else if(this.activatedRoute.children.length == 0) {
      this.router.navigate(["onboarding/cvp/cv-welcome"])
    }

    this.obService.routingStart.subscribe(() => {this.transitionAnimation()})
  }

  /**
   * Phase 1: Move router to the right
   * Phase 2: Activate transition, router will lose offset class and slide back into original spot
   */
  animationPhase = 0
  transitionAnimation(){
    this.animationPhase = 1;
    delay(50).then(() => {
      this.animationPhase == 2
      delay(200).then(() =>{
        this.animationPhase = 0; //activates the transition and moves the router back to its original position by removing the offset class
      })
    })
  }

  back(){
    history.back()
  }

}
