import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ApplicationStateTalentcardComponent
} from "./application-state-talentcard/application-state-talentcard.component";
import {UtilityModule} from "utility";
import {MatRippleModule} from "@angular/material/core";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    ApplicationStateTalentcardComponent,
  ],
    imports: [
        CommonModule,
        UtilityModule,
        MatRippleModule,
        RouterModule
    ],
  exports: [
    ApplicationStateTalentcardComponent

  ]
})
export class TalentCardModule { }
