<header style="padding-bottom: 0" fxLayout="column" fxLayoutAlign="start center" class="layout-padding-2x">
  <div id="logo-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <h2 *ngIf="!mediaService.isMobile()">Willkommen auf</h2>
    <img src="../../assets/images/uniwunder_ta.png" height="80px" width="300px"
         *ngIf="!mediaService.getMaxWidthQuery(355).matches"
         [ngStyle]="{'margin-top.px' : mediaService.isMobile() ? 10 : 0 }">
  </div>
  <p style="margin-bottom: 0">In diesem kurzen Video erklären wir dir, wie du die KarriereWelt am besten für dich nutzen kannst!
    Du kannst dir dieses Video selbstverständlich auch später noch ansehen, falls du direkt loslegen möchtest.
  </p>
</header>

<div class="video">
  <iframe style="border: 0px" [width]="getVideoResolutionWidth()" [height]="getVideoResolutionHeight()"
          src="https://player.vimeo.com/video/592777815" allow="autoplay; fullscreen" allowfullscreen>
  </iframe>
</div>

<div class="feedback-consent-wrapper" fxLayout="row" fxLayoutAlign="start center">
<mat-checkbox [formControl]="feedbackConsentControl" class="checkbox-smaller">Ich möchte bei der Verbesserung von Talentagent helfen</mat-checkbox>
  <i class="mdi mdi-information-outline font-size-20 m-left10" #tooltip="matTooltip" (click)="tooltip.toggle()"
     matTooltip="Hey! :) Wir wollen Talentagent weiter verbessern und brauchen dazu deine Hilfe! Wür würden von Zeit zu Zeit gern ein paar Gespräche mit dir führen um zu erfahren,
  worauf du besonders wert legst und was wir noch verbessern können"></i>
</div>

<footer fxLayout="row" fxLayoutAlign="center">
  <button mat-flat-button color="accent"
          (click)="navigate()"
          [loadingBool]="waitingUntilMatchingHasStarted">
    zur Plattform <i class="mdi mdi-arrow-right"></i>
  </button>
</footer>
