<div class="flex column gap padding width-100">
    <div class="flex column width-100">
        <position-searchbar></position-searchbar>
        <div *ngIf="!minimalMobileView" class="info-text flex row start-center gap-sm margin-top">
            <i class="mdi mdi-information"></i>
            <span>Tipp: Suchleiste frei lassen und <b>passende</b> Vorschläge für deinen Studiengang erhalten!</span>
        </div>
    </div>


    <div class="flex row-wrap start-center">
        <app-positions-searchbar-filter [type]="'Location'"></app-positions-searchbar-filter>
        <app-positions-searchbar-filter [type]="'Profession'"></app-positions-searchbar-filter>
        <app-positions-searchbar-filter [type]="'Other'"></app-positions-searchbar-filter>
        <div
                *ngIf="false"
                (click)="clearFilterAndSearch()" class="flex row start-center gap clickable hover-dark-overlay">
            <i class="mdi mdi-filter-off large-text color-regular"></i>
            <div
                    *ngIf="!minimalMobileView"
                    class="reset-text">Filter zurücksetzen</div>
        </div>
    </div>
</div>
