import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {QuestionFormat} from "utility";

@Component({
    selector: 'date-question',
    templateUrl: './date-question.component.html',
    styleUrls: ['./date-question.component.scss']
})
export class DateQuestionComponent implements OnInit {

    @Input() control: AbstractControl<any>
    @Input() format: QuestionFormat
    @Input() min: Date
    @Input() max: Date

    minDate: Date
    maxDate: Date

    dateInit: boolean = false
    ngOnInit(): void {
        this.initDate()
    }

    initDate() {
        this.minDate = this.min ? new Date(this.min) : null
        this.maxDate = this.max ? new Date(this.max) : null
        this.control.addValidators(this.dateRangeValidator(this.minDate, this.maxDate))
        this.dateInit = true
    }

    dateRangeValidator(minDate?: Date, maxDate?: Date) {
        return (control: FormControl) => {
            const value = control.value;
            if (!value) {
                return null;
            }

            const date = new Date(value);

            if (minDate && date < minDate) {
                return {dateRange: {minDate: minDate}};
            }

            if (maxDate && date > maxDate) {
                return {dateRange: {maxDate: maxDate}};
            }

            return null;
        };
    }
}


