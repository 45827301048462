<div id="mainwrapper" [ngClass]="{'no-header': !showNav()}" fxLayout="column">
    <rocket-animation *ngIf="showRocketAnimation"></rocket-animation>
    <div fxLayout="row" id="navbar" fxLayoutAlign="space-between center">
        <div class="nav-elements" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="tp-container" [ngStyle]="{'padding.px': mediaService.getMaxWidthQuery(600).matches? 0 : 20 }">
                <img src="../assets/images/uniwunder_ta.png" id="logo-desktop" (click)="navigateToStart()">
                <img src="../assets/images/uniwunder_ta.png" id="logo-mobile" (click)="navigateToStart()">
            </div>
            <div class="desctop-nav" fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-md *ngIf="authService.isAuthenticated()">
                <div [ngClass]="{'nav-marker': router.url.includes('/home')}" fxLayout="row" fxLayoutAlign="center center" matRipple class="ta-tab" routerLink="home">Home</div>
                <div [ngClass]="{'nav-marker': router.url === '/talent/positions'}" fxLayout="row" fxLayoutAlign="center center" matRipple class="ta-tab" routerLink="talent/positions">Stellen</div>

                <div [ngClass]="{'nav-marker': router.url.includes('/talent/proposals/recommendations')}" fxLayout="row" fxLayoutAlign="center center" matRipple class="ta-tab" routerLink="talent/proposals/recommendations">
                    <span   [matBadge]="propagationService.numRecommendedProposals == 0 ? '' : propagationService.numRecommendedProposals"
                            matBadgeColor="accent"
                            matBadgeSize="small"
                            [matBadgeOverlap]="true"
                    >Persönliche Empfehlungen</span>
                </div>
                <div [ngClass]="{'nav-marker': router.url === '/talent/proposals/bookmarks'}" fxLayout="row" fxLayoutAlign="center center" matRipple class="ta-tab" routerLink="talent/proposals/bookmarks">
                                        <span   [matBadge]="propagationService.numBookmarkedProposals == 0 ? '' : propagationService.numBookmarkedProposals"
                                                matBadgeColor="accent"
                                                matBadgeSize="small"
                                                [matBadgeOverlap]="true"
                                        >Merkliste</span>
                </div>

                <div [ngClass]="{'nav-marker': router.url === '/events'}" fxHide.lt-lg fxLayout="row" fxLayoutAlign="center center" matRipple class="ta-tab" routerLink="events">
                                        <span>Events</span>
                </div>

                <div [ngClass]="{'nav-marker': router.url.includes('/events') || router.url.includes('/company-overview') || router.url.includes('/talent/profile') || router.url === '/talent/applications' || router.url.includes('/talent/agent')}" class="ta-tab" fxLayout="row" fxLayoutAlign="center center" [matMenuTriggerFor]="moreMenu">Mehr</div>
                <mat-menu #moreMenu="matMenu" class="more-menu">
                    <button mat-menu-item routerLink="events" fxHide fxShow.lt-lg>Events & Messen</button>
                    <button mat-menu-item routerLink="company-overview">Unternehmen</button>
                    <button mat-menu-item routerLink="talent/applications">Bewerbungen</button>
                    <button mat-menu-item routerLink="talent/agent">Karrierecoach</button>
                </mat-menu>
            </div>

            <div fxHide.lt-sm="true" fxLayout="row" *ngIf="!authService.isAuthenticated()"
                 [fxHide]="router.url === '/onboarding'" fxLayoutGap.gt-xs="20px" fxLayoutGap.lt-sm="10px" class="menu">
                <button *ngIf="router.url != '/' && router.url != '/talent/login'" mat-button
                        style="padding: 0; min-width: 0"
                        routerLink="/talent/login" class="ita_login ita_nav">Login
                </button>
                <button mat-button style="padding: 0; min-width: 0" (click)="navigateToMainPage()">Hauptseite</button>
                <button mat-button style="padding: 0; min-width: 0" class="md-primary ita_registration ita_nav"
                        routerLink="/onboardingStart">Registrieren
                </button>
                <button mat-button style="padding: 0 5px 0 0; min-width: 0"
                        class="ita_to-business ita_nav"
                        (click)="routeToTalentAgent()"> Für Unternehmen
                </button>
            </div>

            <div fxLayout="row" fxLayoutAlign="end"
                 *ngIf="authService.isAuthenticated()" style="height: 100%">

                <div fxLayout="row" fxLayoutAlign="end center">
                    <mat-menu id="notification-menu" class="header-menu" #notificationMenu="matMenu" xPosition="before"
                              backdropClass="test">
                        <div *ngIf="notifications.length == 0" fxLayout="row" fxLayoutAlign="center center"
                             class="notification-item no-notification">
                            Du hast noch keine Benachrichtigungen.
                        </div>
                        <div class="menu-notification"
                             *ngFor="let notification of notifications"
                             (click)="navigateFromNotificationToUrl(notification.type)">
                            <div fxLayout="row" fxLayoutAlign="center center" class="notification-item"
                                 [ngClass]="{'newItem': isNotificationUnseen(notification)}">

                                <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px" fxFlex="100"
                                     class="text-block">
                                    <div>{{notification.shortMessage}}</div>
                                    <div class="text-block-time">
                                        <small style="color: grey">
                                            {{notification.creationDate | date: 'dd.MM.yyyy'}}
                                            um {{notification.creationDate | date:'HH:mm'}}
                                            Uhr
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center" routerLink="/notifications"
                             class="notification-last-item">
                            <span class="hid-link" style="padding:5px">Alle anzeigen</span>
                        </div>
                    </mat-menu>

                    <button *ngIf="!mediaService.getMaxWidthQuery(600).matches"
                            mat-icon-button
                            matTooltip="Meine Chats"
                            fxLayout="row" fxLayoutAlign="center center"
                            style="margin-right: 0.75rem" (click)="openChatOverlay = !openChatOverlay; $event.stopImmediatePropagation()">
<!--                            routerLink="/chat-overview" (click)="routeOrRefreshChatMessages()">-->
                        <mat-icon [matBadge]="unseenChatMessagesCount.toString()"
                                  [matBadgeHidden]="unseenChatMessagesCount==0"
                                  matBadgeColor="warn">forum</mat-icon>
                        <div *ngIf="unseenChatMessagesCount != 0" class="badge-pulsate"></div>
                    </button>

                    <button *ngIf="!mediaService.getMaxWidthQuery(600).matches" fxLayout="row"
                            fxLayoutAlign="center center"
                            mat-icon-button [matMenuTriggerFor]="notificationMenu"
                            id="bell" matTooltip="Benachrichtigungen"
                            [ngClass]="{'ringer': newNotificationsCount > 0}"
                            (click)="setNotificationsToSeen()"
                            (menuClosed)="countUnseenNotifications()">
                        <mat-icon
                                matBadge="{{newNotificationsCount|| 0}} "
                                matBadgeSize="medium"
                                matBadgeColor="accent"
                                matBadgePosition="above after"
                                matBadgeOverlap="true"
                                [matBadgeHidden]="!badgeVisible">notifications
                        </mat-icon>
                    </button>


                    <mat-menu #accountMenu="matMenu" class="header-menu">
                        <button mat-menu-item routerLink="/account"><i class="mdi mdi-cog"></i> Einstellungen</button>
                        <button mat-menu-item routerLink="talent/profile"><i class="mdi mdi-account"></i> Profil & CV</button>

                        <a mat-menu-item *ngIf="mediaService.getMaxWidthQuery(959).matches" [href]="useRegulations" target="_blank" class="footer-link"><i class="mdi mdi-text-box-check-outline"></i> Nutzungsbedingungen</a>
                        <a mat-menu-item *ngIf="mediaService.getMaxWidthQuery(959).matches" [href]="privacyUrl" target="_blank" class="footer-link"><i class="mdi mdi-security"></i> Datenschutz</a>
                        <a mat-menu-item *ngIf="mediaService.getMaxWidthQuery(959).matches" [href]="siteNoticeLink" target="_blank" class="footer-link"><i class="mdi mdi-information-outline"></i> Impressum</a>
                        <button mat-menu-item (click)="logout()"><i class="mdi mdi-logout"></i> Abmelden</button>
                    </mat-menu>

                    <div fxLayout="row" fxLayoutAlign="end center">

                        <button *ngIf="mediaService.getMaxWidthQuery(600).matches"
                                mat-icon-button
                                matTooltip="Meine Chats"
                                fxLayout="row" fxLayoutAlign="center center"
                                style="margin-right: 1rem" (click)="openChatOverlay = true; ; $event.stopImmediatePropagation()">
<!--                                routerLink="/chat-overview" (click)="routeOrRefreshChatMessages()">-->
                            <mat-icon [matBadge]="unseenChatMessagesCount.toString()"
                                      [matBadgeHidden]="unseenChatMessagesCount==0"
                                      matBadgeColor="warn">forum</mat-icon>
                            <div *ngIf="unseenChatMessagesCount!=0" class="badge-pulsate"></div>
                        </button>

                        <button *ngIf="mediaService.getMaxWidthQuery(600).matches" fxLayout="row"
                                fxLayoutAlign="center center"
                                mat-icon-button id="bell" routerLink="/notifications">
                            <mat-icon
                                    matBadge="{{newNotificationsCount|| 0}} "
                                    matBadgeSize="medium"
                                    matBadgePosition="above after"
                                    matBadgeOverlap="true"
                                    matBadgeColor="accent"
                                    [matBadgeHidden]="!badgeVisible">notifications
                            </mat-icon>
                        </button>


                        <button mat-button [matMenuTriggerFor]="accountMenu" matTooltip="Account"
                                style="font-size: 18px;">
                            <i class="mdi mdi-account-circle"></i>
                            <span fxHide.lt-sm="true">
              {{authService.getAccountInfo()?.firstName}} {{authService.getAccountInfo()?.lastName}}
            </span>
                        </button>
                    </div>
                </div>

            </div>
        </div>

<!--        <five-star-rating-overlay class="rating-component"></five-star-rating-overlay>-->
        <app-npsscore-overlay *ngIf="popUpService.hasOrganicLogin" class="rating-component"></app-npsscore-overlay>

    </div>
<!--    <div class="error-banner" *ngIf="showNav()">-->
<!--        <div>-->
<!--            Aktuell kommt es leider zu Einschränkungen in der Funktionsweise von Talentagent.-->
<!--            In wenigen Stunden können wir dich wieder bei der Suche nach deinem Traumjob unterstützen!-->
<!--        </div>-->
<!--    </div>-->

    <div *ngIf="showMobileNav" class="mobile-nav-wrapper">
        <div fxLayout="column" *ngIf="!authService.isAuthenticated()"
             fxLayoutGap.gt-xs="20px" fxLayoutGap.lt-sm="10px" class="mobile-nav"
             [ngStyle]="{'height' : (router.url != '/' && router.url != '/talent/login' && router.url != '/?preventRedirect=true')? '191px' : '143px'}">
            <!--TODO in typescript und mit ngclass-->
            <button *ngIf="router.url != '/' && router.url != '/talent/login' && router.url != '/?preventRedirect=true'"
                    mat-button style="padding: 0; min-width: 0"
                    routerLink="/talent/login" (click)="showMobileNav = false">
                Login
            </button>
            <button mat-button style="padding: 0; min-width: 0" (click)="navigateToMainPage()">Hauptseite</button>
            <button mat-button style="padding: 0; min-width: 0" class="md-primary" routerLink="/onboardingStart"
                    (click)="showMobileNav = false">Registrieren
            </button>
            <button mat-button style="padding: 0 5px 0 0; min-width: 0; color: #0078ff!important;"
                    (click)="routeToTalentAgent(); showMobileNav = false"> Für Unternehmen
            </button>
        </div>
    </div>

    <mobile-navigation *ngIf="showNav() && this.authService.isAuthenticated()" fxHide.gt-md></mobile-navigation>

        <div  [ngClass]="{'white-background': router.url.includes('onboarding') ||
                       router.url == '/welcome' ||
                       router.url == '/introduction-video'}" >


            <!--div
                    *ngIf="(router.url.includes('/positions') || router.url.includes('/proposals')) && !mediaService.isMobile()"
                    class="peppy-gradient-container">
                <div class="peppy-gradient"></div>
                <div class="white-gradient-blend"></div>
            </div-->


            <div class="color-background" *ngIf="(router.url.includes('/hybrid')) || router.url.includes('/company-overview')"></div>

            <div class="chat-overlay mat-elevation-z10" *ngIf="openChatOverlay" #chatOverlay (clickOutside)="hideChatOverlay()">
                <app-chat-overlay (hideOverlay)="hideChatOverlay()"></app-chat-overlay>
            </div>

            <div
                    id="content-wrapper" fxLayout="row" fxLayoutAlign="center start"
                 [ngClass]="{'on-welcome': this.router.url == '/welcome' || this.router.url == '/introduction-video',
                 'no-footer': this.router.url.indexOf('onboarding')> -1,
                 'onboarding': this.router.url.indexOf('onboarding') > -1,
                 'overflow-visible': router.url.includes('/event-page'),
                  'grey-background': this.router.url.indexOf('new-profile') > -1,
                  'remove-bottom-padding': this.router.url.includes('/company-profile'),
                  'positions-wrapper': this.router.url.includes('/talent/positions') || this.router.url.includes('/talent/proposals') || this.router.url.includes('/position-preview/')}"
                 [ngStyle]="{'padding' : router.url.includes('/event-page') || router.url.includes('/company-overview') || router.url.includes('/events') || isOnLoginPage() || (this.router.url.includes('/chat-overview') && mediaService.getMaxWidthQuery(600).matches) || this.router.url.indexOf('onboarding') > -1 ? '0' : null, 'max-width': (isOnLoginPage() || router.url.includes('klarsolar')) || router.url.includes('/event') || this.router.url.includes('onboarding') || router.url.includes('/company') ? 'unset' : '1464px'}">

                <div *ngIf="this.router.url=='/talent/proposals/matches' && !
                mediaService.getMaxWidthQuery(1360).matches" style="width: 200px;"></div>
                <div style="position: relative; z-index: 1"
                     [ngClass]="{'no-max-width': !showNav() || router.url.includes('/talent/positions') || router.url.includes('/company-profile') ||router.url.includes('/talent/proposals') || router.url.includes('/event') || this.router.url.includes('/position-preview/'),
                     'max-1280': showNav() && router.url.includes('/home'),
                     'hybrid-wrapper': router.url.includes('/hybrid'), 'router-outlet-wrapper': !router.url.includes('/hybrid') && !router.url.includes('/company-overview')}">
                    <router-outlet ></router-outlet>
                </div>
                <div *ngIf="this.router.url=='/talent/proposals/matches' &&
                !mediaService.getMaxWidthQuery(1160).matches" style="width: 200px;">
                    <search-highlight></search-highlight>
                </div>


<!--                <img *ngIf="!router.url.includes('/hybrid') && !router.url.includes('/event') && !router.url.includes('/company')" fxHide.lt-md src="../../../assets/images/background/bg-big-circle.svg" class="big-circle">-->
<!--                <img *ngIf="!router.url.includes('/hybrid') && !router.url.includes('/event') && !router.url.includes('/company')" fxHide.lt-md src="../../../assets/images/background/bg-small-circle-group.svg" class="small-circle">-->
<!--                <div fxHide.lt-md  *ngIf="showNav()" class="ta-round-drop lauro-bot"></div>-->

            </div>


            <ta-footer
                    [siteNoticeLink]="siteNoticeLink"
                    [privacyUrl]="privacyUrl"
                    [useRegulations]="useRegulations"
                    *ngIf="showFooter()"></ta-footer>

        </div>

</div>
