
<div class="whoAreU s2o-stepwrapper" fxLayout="column" fxLayoutAlign="start center" [ngClass]="{'smallerEve': smallerEve}">
    <div class="eve-rapper">
        <img src="../../../../assets/images/eve-dropshadow.svg" class="eve-image">
    </div>

    <div class="eve-bubble">
        <div class="triangle-top"></div>
        Hey! ✌🏻 Ich bin Eve und unterstütze dich beim Anlegen deines Profils, um dich mit deinem Traumjob zu matchen. Und wer bist du?
    </div>

    <div class="step-content-ob m-bottom25">
        <hid-input-field [control]="form.controls['firstName']"
                         label="Vorname"
                         [forceValidation]="error"
                         name="firstName"
                         type="text"
                         (keydown.enter)="progress()"
                         autocomplete="given-name"
                         [showValidatorIcons]="true">
        </hid-input-field>
    </div>


</div>
<div class="mob-bottom-buttons-wrapper">
    <button class="startButton" mat-flat-button color="accent" (click)="progress()">Los geht`s</button>
</div>
