<div class="hid-input-container" #wrapper *ngIf="show">
<textarea
  #textAreaElement
  *ngIf="maxLength"
  autosize
  [minRows]="minRows"
  [placeholder]="placeholder"
  [maxLength]="maxLength"
  class="hid-textarea {{inputFieldClass}}"
  [formControl]="control"
  id="{{dynamicId}}"
  [name]="name"
  [ngClass]="{'filled': control.value != null && control.value.toString().length > 0, 'padding-bottom': charCounterShow, 'margin-top': wrappedLabel}"
></textarea>
  <textarea
    #textAreaElement2
    *ngIf="!maxLength"
    autosize
    [minRows]="minRows"
    [placeholder]="placeholder"
    class="hid-textarea {{inputFieldClass}}"
    [formControl]="control"
    id="b{{dynamicId}}"
    [name]="name"
    [ngClass]="{'filled': control.value != null && control.value.toString().length > 0, 'padding-bottom': charCounterShow, 'margin-top': wrappedLabel}"
  ></textarea>
  <div *ngIf="charCounterShow"
       class="char-counter">{{control.value?.toString()?.length ? control.value?.toString()?.length : 0}}
    /{{maxLength}}</div>
  <label for="{{dynamicId}}" #labelContainer [ngClass]="{'wrapped-label': wrappedLabel, 'with-placeholder': placeholder?.length}"
         (click)="setFocusToTextArea()">{{label}}</label>
</div>
