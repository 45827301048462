<div mat-dialog-title class="dialog-title">
    <div fxLayout="row" fxLayoutAlign="end center" >
        <button mat-icon-button mat-dialog-close>
            <i class="white mdi mdi-close"></i>
        </button>
    </div>
    <div class="width-100 title-content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" >
        <div class="headline">Lade jetzt deinen Lebenslauf hoch</div>
    </div>
</div>

<mat-dialog-content>
    <div class="dialog-content">Wenn du deinen Lebenslauf hochlädst,
        können wir dir viel bessere Stellen vorschlagen.
        <br><br>Wenn du noch keinen Lebenslauf hast,
        kannst du dir auch unsere <a href="assets/files/Lebenslaeufe.zip">Lebenslaufvorlagen</a> herunterladen und einen erstellen.
        Es lohnt sich :)
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <cv-upload-button [buttonClass]="'ita_cv_upload_popup'" (uploaded)="cvUploaded()"></cv-upload-button>
</mat-dialog-actions>
