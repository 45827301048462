import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'share-talent-agent-to-friends',
  templateUrl: './share-talent-agent-to-friends.component.html',
  styleUrls: ['./share-talent-agent-to-friends.component.scss']
})
export class ShareTalentAgentToFriendsComponent implements OnInit {

  @Input() shareText: string;
  @Input() set utmParams(params: string) {
    params? this.params = params : this.params = "?utm_source=tp-share&utm_medium=tp-proposals-completed-view&utm_campaign=tp-proposals-completed-share";
  }

  @Output() selfDestroy: EventEmitter<any> = new EventEmitter();

  params
  shareLink
  encodedShareLink;

  constructor(public utilityService: UtilityService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.shareLink = window.location.origin + '/onboarding' + this.params;
    this.encodedShareLink = encodeURIComponent(this.shareLink);
  }

  destroySelf() {
    this.selfDestroy.emit();
  }


  sharelinkToClipBoard() {
    this.utilityService.copyToClipboard(this.shareLink);
    this.snackBar.open('Link in Zwischenablage kopiert', null, {duration: 4000});
    this.destroySelf();
  }

}
