<dialog-header headline="Füge deinen Lebenlauf hinzu"></dialog-header>

<mat-dialog-content fxLayout="column">
  <div id="content-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
      <i class="mdi mdi-file-pdf" style="margin-right: 10px; color: darkred; font-size: 18px"></i>
      <p>Lade deinen Lebenslauf im PDF-Format hoch</p>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.lt-sm="column" style="margin: 10px 30px; width: 100%" fxLayoutGap="10px">
      <input type="file" [accept]="fileService.whiteListedCvExtensionsStr" [formControl]="file" id="file" class="inputfile">
      <label for="file">{{(getFileName().length > 15) ? (getFileName() | slice:0:15) + '...' : (getFileName())}}</label>
    </div>

    <div id="file-errors" *ngIf="file" fxLayout="column">
      <sub style="color: red;" *ngIf="fileIsWordDocument">
        Bei der angegebenen Datei handelt es sich um ein Worddokument.<br/>
        Bitte konvertiere dieses zu einem PDF-Dokument und lade es dann hoch (<a
              href="https://praxistipps.chip.de/word-dokumente-in-pdf-umwandeln_3039" target="_blank">Anleitung</a>)!
      </sub>

      <small style="color: red" *ngIf="file.errors != null && file.errors['sizeTooBig']">Die Datei ist größer als 10MB,
        Bitte wähle eine kleinere Datei
      </small>
      <small style="color: red" *ngIf="file.errors != null && file.errors['wrongExtension'] && !fileIsWordDocument">
        Falsches Format. Bitte wähle ein PDF-Dokument.
      </small>
      <small *ngIf="file.errors != null && file.errors['fileIsEmpty']">
        Die ausgewählte Datei ist leer.
      </small>
    </div>

    <div id="draft-list" class="info-box w-100" *ngIf="drafts && drafts.length > 0" fxLayout="row"
         fxLayoutAlign="center center">
      <i class="fa fa-info-circle"></i>

      <div fxLayout="column" fxLayoutAlign="center center">
        <p>Folgende Bewerbungen werden dadurch abgeschickt:</p>
        <ul>
          <li *ngFor="let draft of drafts">{{draft.talentPositionRelation.employmentPosition.title}}</li>
        </ul>
      </div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="primary-text-color">
  <button mat-button class="m-right10" (click)="close()">Abbrechen</button>

  <button mat-flat-button color="accent"
          class="ita_upload_cv_profile"
          [disabled]="!isValidFile()"
          (click)="save()"
          [loadingBool]="saving">Speichern
  </button>

</mat-dialog-actions>
