import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnimationService {

  rocketAnmination: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
  ) { }

  showRocketAnimation(): Promise<any> {
    return new Promise((resolve) => {
      this.rocketAnmination.next(true)
      setTimeout(() => {
        this.rocketAnmination.next(false)
        resolve(true)
      }, 1000)
    })
    }

  handleOnScrollAnimation(queryClass: string, animationClass: string, reverseAnimation = false){

    const relevantElements = document.querySelectorAll(queryClass)
    const observer = new IntersectionObserver((entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass)
        } else{
          if(reverseAnimation) entry.target.classList.remove(animationClass)
        }
      })
    })

    relevantElements.forEach((element: any) => {
      observer.observe(element)
    })
  }

  handleOnScrollWithCrossIntersection(intersectionId: string,
                                      queryId: string,
                                      animationClass: string,
                                      reverseAnimation = false){
    const intersectionElement = document.getElementById(intersectionId)
    const queryElement = document.getElementById(queryId)
    const observer = new IntersectionObserver((interSectionElement: any) => {
        if (interSectionElement.isIntersecting) {
          queryElement.classList.add(animationClass)
        } else{
          if(reverseAnimation) queryElement.classList.remove(animationClass)
        }
    })
    observer.observe(intersectionElement)
  }

}
