import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {PositionService} from "../../services/position.service";
import {TalentPositionInteractionOrigin, TalentPositionPreviewData} from "utility";
import {MediaService} from "../../services/inno-utils/media.service";
import {Router} from "@angular/router";
import {PositionsDetailsComponent} from "../../search/positions-details/positions-details.component";
import {BehaviorSubject, Subscription} from "rxjs";
import {SearchService} from "../../services/search.service";
import {TalentAuthService} from "../../services/talent.auth.service";

@Component({
  selector: 'app-positions-wrapper-preview-details',
  templateUrl: './positions-wrapper-preview-details.component.html',
  styleUrls: ['./positions-wrapper-preview-details.component.scss']
})
export class PositionsWrapperPreviewDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() positions: TalentPositionPreviewData[]
    @Input() totalPositions: number
    @Input() page: number
    @Input() totalPages: number
    @Input() loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    @Input() origin: TalentPositionInteractionOrigin

    @Output() paginatorNextIndex: EventEmitter<number> = new EventEmitter<number>()

    @ViewChild('detailsContainer', { read: ViewContainerRef, static: false }) detailsContainer: ViewContainerRef;
    @ViewChild('previews') previews: ElementRef;


    feedIsHovered = false;
    idSubscription: Subscription = new Subscription();
    loadingSubscription: Subscription = new Subscription();

  constructor(
      public positionService: PositionService,
      public searchService: SearchService,
      public authService: TalentAuthService,
      public mediaService: MediaService,
      public router: Router,
  ) { }

    ngAfterViewInit(): void {
        this.idSubscription = this.positionService.selectedPositionId.subscribe(next => {
            this.reloadDetails()
        })
        this.loadingSubscription = this.loading.subscribe(next => {
          this.scrollToTop()
        })
    }

  ngOnInit(): void {
      if (this.positions.findIndex(p => p.id == this.positionService.selectedPositionId.value) < 0) {
          this.positionService.selectedPositionId.next(null)
      }
  }

  ngOnDestroy() {
    this.idSubscription.unsubscribe()
      this.loadingSubscription.unsubscribe()
  }

    scrollToTop() {
      if (this.mediaService.isMobile()) window.scrollTo(0, 0)
      document.getElementById("previews").scrollTo(0, 0)
  }

    toggleHover(state: boolean) {
        this.feedIsHovered = state;
    }

    reloadDetails() {
      if (!this.detailsContainer) return
        this.detailsContainer?.clear();
        if (this.positionService.selectedPositionId !== null) {
            const componentRef = this.detailsContainer.createComponent(PositionsDetailsComponent);
            componentRef.instance.positionId = this.positionService.selectedPositionId;
            componentRef.instance.splitId = this.positionService.selectedSplitId;
            componentRef.instance.origin = this.origin;
            if (!this.mediaService.isMobile()) componentRef.instance.loadingPreviews = this.loading
        }
    }

    displayScrollBlocker(): boolean {
      return this.previews?.nativeElement?.scrollHeight > this.previews?.nativeElement?.clientHeight
    }

    protected readonly document = document;
}
