import { Component, OnInit } from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {TalentAuthService} from "../../services/talent.auth.service";
import {MediaService} from "../../services/inno-utils/media.service";
import {PropagationService} from "../../services/propagation.service";

@Component({
  selector: 'mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit {

  showSubNav: boolean = false

  constructor(
      public  propagationService: PropagationService,
      public router: Router,
      public authService: TalentAuthService,
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
  }

  toggleSubMenu() {
    this.showSubNav = !this.showSubNav

    if (this.showSubNav) {
      document.body.style.overflowY = "hidden"
      let sub = this.router.events.subscribe(next => {
        if (next instanceof NavigationStart) {
          this.showSubNav = false
          sub.unsubscribe()
        }
      })
    }  else {
      document.body.style.overflowY = "auto"
    }


  }

  routeTo(url: string, tabIndex?: number) {
    document.body.style.overflowY = "auto"
    if (tabIndex != null) {
      this.router.navigate([url], {queryParams: {tab: tabIndex}})
    } else {
      this.router.navigate([url])
    }
  }
}
