import {Component, OnDestroy, OnInit} from '@angular/core';
import {TalentHomeResource, TalentPositionDetailsData} from "utility";
import {Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {HomeService} from "../../home-utils/home.service";
import {PositionService} from "../../../services/position.service";

@Component({
  selector: 'bookmarks-home-mobile',
  templateUrl: './bookmarks-home-mobile.component.html',
  styleUrls: ['./bookmarks-home-mobile.component.scss']
})
export class
BookmarksHomeMobileComponent implements OnInit, OnDestroy {

  loadingSubject = new BehaviorSubject<boolean>(true)
  bookmarks: TalentPositionDetailsData[] = []

  bookmarkOrRejectRelationSub: Subscription

  constructor(
      private talentHomeResource: TalentHomeResource,
      private homeService: HomeService,
      public router: Router,
      private positionService: PositionService
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getApplications()
    this.subscribeBookmarkOrRejectRelation()
  }

  subscribeBookmarkOrRejectRelation() {
    this.bookmarkOrRejectRelationSub = this.positionService.relationProceeded.subscribe(proceeded => {
      if (proceeded.action == "BookmarkRemoved" || proceeded.action == "Rejected" || proceeded.action == 'Applied') {
        this.bookmarks.splice(this.bookmarks.findIndex(r => r.employmentPosition.id == proceeded.relation.positionId), 1)

      }
    })
  }


  getApplications() {
    this.loadingSubject.next(true)
    this.talentHomeResource.getBookmarksForDashboard().then(bookmarkedRelations => {
      this.bookmarks = bookmarkedRelations
      this.loadingSubject.next(false)
    })
  }

  ngOnDestroy(): void {
  }
}
