<div class="dialog-container">

    <div class="dialog-title" fxLayout="column" fxLayoutAlign="center center" mat-dialog-title>
        <div class="headline font-size-16 width-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
                <img src="assets/images/logo-mini.png" height="32px" width="32px">


            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <div><b>Bewerben über {{data.relation.employmentPosition.employmentPositionOrigin | employmentPositionOrigin}}</b></div>
            </div>

            <i class="mdi mdi-close white" (click)="onClose(false)"></i>
        </div>
        <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" fxLayoutAlign.lt-sm="center center">
            <button mat-button (click)="close(false)">Abbrechen</button>
            <button mat-flat-button color="accent"
                    (click)="createExternalApplication()"
                    [disabled]="confirming"
                    [loadingBool]="confirming">Ich habe mich beworben
            </button>
        </div>
    </div>

    <div mat-dialog-content class="dialog-content" [ngClass]="{'ios-content': mediaService.isIOs()}">
        <iframe id="applicationFrame" style="border: none; height: calc(100% - 6px)" [src]="safeUrl" width="100%" name="Bewerben" title="Partnerbewerbung"></iframe>
    </div>
</div>


