import {Component, Input, OnInit} from '@angular/core';
import {BenefitData} from "utility";

@Component({
  selector: 'app-positions-details-benefit-card',
  templateUrl: './positions-details-benefit-card.component.html',
  styleUrls: ['./positions-details-benefit-card.component.scss']
})
export class PositionsDetailsBenefitCardComponent implements OnInit {

    @Input() benefit: BenefitData
  constructor() { }

  ngOnInit(): void {
  }

}
