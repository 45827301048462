import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from "../../../services/search.service";
import {MediaService} from "../../../services/inno-utils/media.service";
import {SearchFilterData} from "utility";
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
  selector: 'position-searchbar',
  templateUrl: './position-search-bar.component.html',
  styleUrls: ['./position-search-bar.component.scss']
})
export class PositionSearchBarComponent implements OnInit {

  @Input() placeholder: string = "Was suchst du? (Position, Branche, Stichwort, ...)"
  @Input() searchString: string = ""
  @Input() keyWordMinLength: number = 3
  lastQuery: string = ""

  keywordValid: boolean = false
  invalidInputTry: boolean = false

  constructor(
      private searchService: SearchService,
      public mediaService: MediaService,
      private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.searchString = this.searchService.searchFilterData.searchQuery
    const searchFilter = this.localStorageService.getSearchFilterData() as SearchFilterData
    if(searchFilter){
      this.lastQuery = searchFilter.searchQuery
    }
    this.searchService.loading.subscribe(loading => {
      this.searchString = this.searchService.searchFilterData.searchQuery
    })
    this.checkIfKeywordIsValid()
  }

  clear(){
    this.searchString = '';
  }

  sendKeyword(){
    if(this.searchService.loading.value){
      return;
    }
    this.searchService.searchFilterData.searchQuery = this.searchString
    this.lastQuery = this.searchString
    this.searchService.triggerSearch()
  }

  checkIfKeywordIsValid() {
    if (this.searchString) {
      if (this.searchString.length > 2) {
        this.keywordValid = true
        this.searchService.searchFilterData.searchQuery = this.searchString
      } else {
        this.keywordValid = false
      }
    }
  }

}
