<div id="share-menu" fxLayout="column" fxLayoutAlign="center center">
  <div layout="column" class="w-100" fxLayout="center center">
    <h3>Talentagent teilen</h3>
    <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="w-100">
      <a href="https://wa.me/?text={{shareText}}{{encodedShareLink}}" data-action="share/whatsapp/share"
         class="icon" id="wa" (click)="destroySelf()"></a>
      <a href="https://telegram.me/share/url?url= {{shareLink}}&text={{encodedShareLink}}" class="icon"
         id="te" (click)="destroySelf()"></a>
      <a id="mail" (click)="destroySelf()" class="icon"
         href="mailto:?subject=Cooles Angebot für dich?&body={{shareText}} {{shareLink}}"></a>
      <a (click)="sharelinkToClipBoard()" class="icon" id="li"></a>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="w-100" style="margin-top:80px;">
      <button (click)="destroySelf()" class="hid-button inverse warn"><i class="fa fa-times"></i> Schließen
      </button>
    </div>
  </div>
</div>
