import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TalentTalentProfileResource, TokenAction, UtilityService} from 'utility';
import {TalentAuthService} from '../../services/talent.auth.service';
import {DeviceDetectorService} from "ngx-device-detector";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
    selector: 'app-one-time-token-login-component',
    templateUrl: './one-time-token-login.component.html',
    styleUrls: ['./one-time-token-login.component.scss']
})
export class OneTimeTokenLoginComponent implements OnInit {

    error: string = null


    constructor(
        private activatedRoute: ActivatedRoute,
        private talentAuthService: TalentAuthService,
        private utilityService: UtilityService,
        private router: Router,
        private talentTalentProfileResource: TalentTalentProfileResource,
        private deviceService: DeviceDetectorService,
    ) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .subscribe(params => {
                const loginCode = params['token'] // direct link
                const messageUuid = params['messageUuid'] // message identifier
                const sessionAttribution = params['sessionAttribution'] ?? params['utm_campaign'] // authorization code (OAuth)
                const reason: TokenAction =  params['reason'] ?? params['utm_source']

                if (loginCode) {
                    this.talentAuthService.codeLogin(loginCode, sessionAttribution, messageUuid, reason)
                        .pipe(
                            catchError((error: HttpErrorResponse) => {
                                this.error = error.error?.error ?? error.message
                                this.router.navigate(['/onetimetoken/expired'])
                                return throwError(() => new Error('invalid code'))
                            })
                        )
                        .subscribe(() => {
                            this.route()
                        })
                }
            })
    }

    route() {
        this.talentAuthService.reloadAccountInfo().then(() => {
            this.activatedRoute.queryParams.subscribe(params => {

                let reason: TokenAction =  params['reason'] ?? params['utm_source']
                let path = "";
                let tab = 0;
                let proposalId = null;
                let positionId = null;
                let eventId = null;
                let instantEventReg = null
                let sendNpsWithValue = null;
                let openAppointmentDialog = null

                switch (reason) {
                    case 'ShowTutorialVideo':
                        path = '/welcome';
                        break;
                    case 'BookmarkedProposals':
                        path = `/talent/proposals/bookmarks`;
                        tab = 4
                        break;
                    case 'ProposalRecommendation':
                            path = `/talent/proposals/recommendations`;
                        if ('positionId' in params) {
                            positionId = params['positionId']
                        }
                        break;
                    case 'TalentProfile':
                        path = '/talent/profile';
                        break;
                    case 'CvUpload':
                        path = '/talent/profile';
                        break;
                    case 'UploadAdditionalDocuments':
                        path = '/talent/applications';
                    case 'CompleteOnboarding':
                        if ('positionId' in params) {
                            positionId = params['positionId'];
                            path = '/onboarding/application';
                        } else path = '/onboarding';
                        break;
                    case 'AssessCareerCoach':
                        path = 'talent/agent';
                        break;
                    case 'TalentAgentAssignment':
                        path = 'talent/agent';
                        openAppointmentDialog = "true"
                        break;
                    case 'NotificationSettings':
                        path = 'account';
                        tab = 2;
                        break;
                    case 'CoachPage':
                        path = 'talent/agent';
                        break;
                    case 'TalentEvaluation':
                        path = 'talent/agent';
                        tab = 1;
                        break;
                    case 'Search':
                        path = `/talent/positions`;
                        break;
                    case 'SendNpsWithValue':
                        sendNpsWithValue = params['sendNpsWithValue'];
                        break;
                    case 'PhoneNumberValidation':
                        path = `/phoneNumberValidation`
                        break;
                    case 'EventInvite':
                        if ('eventId' in params) {
                            eventId = params['eventId'];
                            if (eventId == 87256501 || eventId == "87256501") instantEventReg = "true"
                            path = '/event-page/' + eventId;
                        } else path = '/events';
                        break;
                    case 'EventReminder':
                        if ('eventId' in params) {
                            eventId = params['eventId'];
                            path = '/event-page/' + eventId;
                        } else path = '/events';
                        break;
                    default:
                        path = "/talent/positions";
                        break;
                }

                let firstName = JSON.parse(localStorage.getItem("account_info"))?.firstName;
                let actionParam = null;
                let originParam = 'origin' in params ? params['origin'].toString() : 'Recommendations';

                if ('action' in params) actionParam = params['action'].toString();
                this.router.navigate(
                    [path],
                    {
                        queryParams: {
                            action: actionParam,
                            proposalId: proposalId,
                            positionId: positionId,
                            sendNpsWithValue: sendNpsWithValue,
                            origin: originParam,
                            tab: tab,
                            instantReg: instantEventReg,
                            openAppointmentDialog: openAppointmentDialog
                        }
                    });
                this.reportDevice()
                this.utilityService.showSnackBar(`Willkommen zurück ${firstName}. Du wurdest automatisch eingeloggt!`);
            })
        })

    }

    reportDevice() {
        this.talentTalentProfileResource.logTalentDevice({
            browser: this.deviceService.browser,
            browserVersion: this.deviceService.browser_version,
            deviceType: this.deviceService.deviceType
        })
    }
}
