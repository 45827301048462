import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {ChatData, ChatMessageData, ProfileImageService, TalentChatResource} from "utility";
import {FormControl} from "@angular/forms";
import {MediaService} from "../../services/inno-utils/media.service";
import {FileService} from "../../services/file.service";
import {ChatService} from "../../services/chat.service";
import {takeUntil} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked, OnDestroy {

  chatPicture: string = 'url(../assets/images/blurred_user.png)'
  chatPictureSub: Subscription

  scrolled: boolean = false
  adjusted: boolean = false
  today: Date = new Date()

  @Input() talentId: number
  @Input() chatData: ChatData
  @Input() fullName: string
  @Input() firstName: string

  @Output() setChatDataToNull: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild("messageContainer") messageContainer: ElementRef
  @ViewChild('textArea') textArea: ElementRef

  scrollHeight: number
  textAreaHeight: number

  messageControl = new FormControl<string>(null)

  constructor(
      private chatResource: TalentChatResource,
      public mediaService: MediaService,
      private profileImageService: ProfileImageService,
      private fileService: FileService,
      public chatService: ChatService
  ) {
  }

  ngAfterViewChecked(): void {
      if (!this.adjusted && this.mediaService.getMaxWidthQuery(600).matches) {
        this.adjustMessageContainerSize()
        this.adjusted = true
      }
      if(!this.scrolled || this.scrollHeight != this.messageContainer.nativeElement.scrollHeight) {
        this.textAreaHeight = this.textArea.nativeElement.offsetHeight
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight
        this.scrollHeight = this.messageContainer.nativeElement.scrollHeight
        this.scrolled = true
      }

      if(this.textArea.nativeElement.offsetHeight != this.textAreaHeight) this.shrinkOrExpandMessageContainer()
   }

  ngOnInit(): void {
    this.scrolled = false
    this.adjusted = false
    // this.chatChangeSubscription = this.chatData.subscribe(() => {
    //   this.scrolled = false
    //   this.adjusted = false
    // })
    this.getPartnerProfilePicture()
  }

  ngOnDestroy() {
    this.chatPictureSub?.unsubscribe()
  }

  sendMessage(): void {
    if (this.messageControl.value == null || this.messageControl.value.trim() == "") return

    this.chatResource.sendChatMessage(this.chatData.id, {message: this.messageControl.value}).then()

    let newMessage: ChatMessageData = {
      chatId: null,
      id: null,
      text: this.messageControl.value,
      sender: {
        id: this.talentId, name: this.fullName,
        dtype: 'Talent', studyName: null
      },
      occurred: new Date(),
      senderFirstName: this.firstName,
      seenList: []
    }

    this.chatData.messages.push(newMessage)
    this.messageControl.setValue(null)
    this.adjustTextAreaSize()
    this.messageContainer.nativeElement.style.height = this.messageContainer.nativeElement.style.maxHeight
  }

  goBackToMinimalChats(): void {
    this.setChatDataToNull.emit()
  }

  isMessageOldestMessageOfDay(chatMessage: ChatMessageData, index: number): boolean {
    if(index == 0) return true
    let messageDateBefore = new Date(this.chatData.messages[index-1].occurred)
    let messageDate = new Date(chatMessage.occurred)

    return !(messageDateBefore.getDate() == messageDate.getDate() &&
        messageDateBefore.getMonth() == messageDate.getMonth() &&
        messageDateBefore.getFullYear() == messageDate.getFullYear())
  }

  checkIfDateIsToday(date: Date): string {
    let dateToCheck = new Date(date)
    if(
        dateToCheck.getDate() == this.today.getDate() &&
        dateToCheck.getMonth() == this.today.getMonth() &&
        dateToCheck.getFullYear() == this.today.getFullYear()
    ) return "Heute"
    if(
        dateToCheck.getDate() == this.today.getDate() - 1 &&
        dateToCheck.getMonth() == this.today.getMonth() &&
        dateToCheck.getFullYear() == this.today.getFullYear()
    ) return "Gestern"
    return null

  }

  getPartnerProfilePicture(): void {
    if (this.chatData.members.length > 2) return this.getCompanyLogoImage()

    this.chatPictureSub = this.chatService.getPartnerProfilePicture(this.chatData, this.chatData.type).subscribe(result => {
      if (result.size > 0) {
        this.chatPicture = "url(" + URL.createObjectURL(result)+ ")"
      } else {
        this.chatPicture = 'url(../assets/images/blurred_user.png)'
      }
    })
  }

  getCompanyLogoImage(): void {
    this.chatPicture =  'url(' + environment.apiUrl + '/public/company/' + this.chatData.company.id + '/companyLogo)'
  }

  onKeyup(event: KeyboardEvent) {
    if (event.key === "Enter" && !(event.shiftKey)) this.sendMessage()
    this.adjustTextAreaSize()
  }

  adjustTextAreaSize(): void {
    this.textArea.nativeElement.style.height = "4rem"
    this.textArea.nativeElement.style.height = this.textArea.nativeElement.scrollHeight + "px"
  }

  adjustMessageContainerSize(): void {
    this.messageContainer.nativeElement.style.height = window.innerHeight - 220 + "px"
    this.messageContainer.nativeElement.style.maxHeight = window.innerHeight - 220 + "px"

  }

  shrinkOrExpandMessageContainer(): void {
    let heightDifference: number
    if (this.textArea.nativeElement.offsetHeight > this.textAreaHeight) {
      heightDifference = this.textArea.nativeElement.offsetHeight - this.textAreaHeight
      this.messageContainer.nativeElement.style.height =  this.messageContainer.nativeElement.offsetHeight - heightDifference + "px"
      this.textAreaHeight = this.textArea.nativeElement.offsetHeight
    }
    if (this.textArea.nativeElement.offsetHeight < this.textAreaHeight) {
      heightDifference = this.textAreaHeight - this.textArea.nativeElement.offsetHeight
      this.messageContainer.nativeElement.style.height =  this.messageContainer.nativeElement.offsetHeight + heightDifference + "px"
      this.textAreaHeight = this.textArea.nativeElement.offsetHeight
    }
  }

  isPreviousMessageFromTheSameUser(chatMessage: ChatMessageData, index: number): boolean {
    if (index == 0) return true
    let messageBefore = this.chatData.messages[index]
    return messageBefore?.sender.id == chatMessage?.sender.id

  }

}
