<div class="opened-dropdown-wrapper" (click)="$event.stopPropagation()">

    <form   class="radio-group padding-vertical">
        <fieldset id="nationWideGroup">
            <label class="radio-label margin-bottom-sm">
                <input type="radio"
                       [formControl]="wholeGermanyCtr"
                       name="nationWideGroup"
                       value="nationWide"
                       [disabled]="loadingLocations"
                       [checked]="wholeGermanyCtr.value == 'nationWide'">
                <span class="custom-radio"></span>
                <span class="color-regular">Ich suche deutschlandweit</span>
            </label>
            <label class="radio-label">
                <input type="radio"
                       [formControl]="wholeGermanyCtr"
                       name="nationWideGroup"
                       value="specificCities"
                       [disabled]="loadingLocations"
                       [checked]="wholeGermanyCtr.value == 'specificCities'">
                <span class="custom-radio"></span>
                <span class="color-regular">Ich suche in folgenden Städten</span>
            </label>
        </fieldset>
    </form>

    <location-autocomplete-input
            [ngClass]="{'mobile-dropdown': true, 'no-click': wholeGermanyCtr.value == 'nationWide'}"
            style="width: 100%"
            label="Stadt hinzufügen (Name/PLZ)"
            displayProperty="name"
            mode="Object"
            (modelChange)="selectCity($event)"
    ></location-autocomplete-input>

    <div *ngIf="!loadingLocations"  class="section padding-vertical"
         [ngClass]="{'max-height-section': !mediaService.isMobile(), 'no-click': wholeGermanyCtr.value == 'nationWide'}">
            <chip-list *ngIf="citiesCtr.value?.length" [list]="citiesCtr.value" displayProperty="name" (onRemove)="removePreferredCity($event)"></chip-list>
    </div>
    <div style="overflow: hidden; padding: 0.5rem 0;" *ngIf="loadingLocations">
        <app-positions-chips-skeleton></app-positions-chips-skeleton>
    </div>
</div>




