import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MediaService} from "../../../../../talent/src/app/services/inno-utils/media.service";
import {delay, TalentOnboardingResource, TalentTalentProfileResource, UtilityService} from "utility";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'matching-loading-screen',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss']
})
export class WelcomeVideoComponent implements OnInit, OnDestroy {

  interval;

  readonly feedbackConsentControl = new FormControl<boolean>(true)

  allowRoutingToProfile: Boolean = false
  waitingUntilMatchingHasStarted: Boolean = false

  constructor(
    public router: Router,
    public mediaService: MediaService,
    private talentProfileResource: TalentTalentProfileResource,
    private talentOnboardingResource: TalentOnboardingResource,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.getTalentFeedbackConsent()
  }

  getTalentFeedbackConsent() {
    this.talentProfileResource.getFeedbackConsent().then(result => {
      this.feedbackConsentControl.setValue(result.value)

      this.feedbackConsentControl.valueChanges.subscribe(next => {
        this.updateTalentFeedbackConsent(next)
      })
    })
  }

  updateTalentFeedbackConsent(newFeedbackConsent) {
    this.talentProfileResource.updateFeedbackConsent({value: newFeedbackConsent}).then( result => {
      if (newFeedbackConsent) {
        //Toasts are blocking proceed button
        //this.utilityService.showSnackBar('Vielen Dank für deine Zustimmung')
      } else {
        //Toasts are blocking proceed button
        //this.utilityService.showSnackBar('Zustimmung zurückgezogen')
      }
        })
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  navigate() {
    this.waitingUntilMatchingHasStarted = true
    this.navigateIfMatchingIsInProgress();
  }

  navigateIfMatchingIsInProgress() {
    if (this.router.url.includes("introduction-video")) {
      this.router.navigateByUrl('/talent/positions')
    } else {
      this.talentOnboardingResource.getMatchingTimestamp().then(result => {
        if(result) {
          this.waitingUntilMatchingHasStarted = false
          this.router.navigateByUrl('/talent/positions')
        } else {
          delay(2000).then(() => {
            this.navigateIfMatchingIsInProgress();
          })
        }
      })
    }

  }

  getVideoResolutionWidth() {
    if (this.mediaService.isMobile()) return 370;
    return 600;
  }

  getVideoResolutionHeight() {
    if (this.mediaService.isMobile()) return 208;
    return 338;
  }
}
