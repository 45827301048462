import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CoachingTypeData, CoachingTypeResource, TalentOnboardingResource} from "utility";
import {environment} from "../../../../environments/environment";
import {Subject, Subscription} from "rxjs";
import {CachedOBInputs, S2Fax2OnboardingService} from "../s2-fax2-onboarding.service";

@Component({
  selector: 'app-s2-fax2-o-coach',
  templateUrl: './s2-fax2-o-coach.component.html',
  styleUrls: ['./s2-fax2-o-coach.component.scss']
})
export class S2Fax2OCoachComponent implements OnInit, OnDestroy {

    readonly coachingsForm = new FormGroup({});
    public availableCoachingTypes: CoachingTypeData[];
    public selectedCoachingTypes: CoachingTypeData[];
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public progessingSubject = new Subject()

    constructor(
        private coachingTypeResource: CoachingTypeResource,
        private onboardingService: S2Fax2OnboardingService,
        private onboardingResource: TalentOnboardingResource
    ) {
    }

    ngOnInit() {
        this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
            this.availableCoachingTypes = coachings;

            this.availableCoachingTypes.forEach(coaching => {
                if (coaching.title == 'Hilfe im Bewerbungsprozess') {
                    this.coachingsForm.addControl(coaching.title, new FormControl({value: true, disabled: true}));
                } else {
                    this.coachingsForm.addControl(coaching.title, new FormControl(false));
                }
            });

            this.coachingTypeResource.getSelectedCoachingTypes().then(coachings => {
                this.selectedCoachingTypes = coachings;

                this.selectedCoachingTypes.forEach(coaching => {
                    this.coachingsForm.controls[coaching.title].setValue(true)
                })
            })
        })
    }

    ngOnDestroy() {
    }

    progress(wantsCoachValue: boolean) {
        this.progessingSubject.next()
        this.onboardingService.updateInputs(<CachedOBInputs>{
            wantsCoach: wantsCoachValue
        })

        let choosenCoachingIds: number[] = [];

        let context = 'next';
        if(!wantsCoachValue){
            context = 'skip'
        }

        if(wantsCoachValue) {
            Object.keys(this.coachingsForm.controls).forEach(controlName => {
                if (this.coachingsForm.controls[controlName].value == true) {
                    choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.title == controlName)[0].id);
                }
            });

            this.onboardingResource.requestCoach(choosenCoachingIds).then(() => {
                this.onboardingService.delayedRouting("/onboarding/s2fax2/coach-data", context)
            });
        } else{
            this.onboardingService.delayedRouting("/onboarding/s2fax2/password", context)
        }
    }

}

