import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {UnsubData, TokenFeedbackData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.MailmarketingAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class MailmarketingAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/mailmarketing/unsubscribe',
        endpoint: 'de.syntacton.cronos'
    })
    private _unsubscribeFromMailMarketing: IResourceMethodObservableStrict<UnsubData, null, null, TokenFeedbackData> | undefined
    unsubscribeFromMailMarketing(requestBody: UnsubData): Promise<TokenFeedbackData> {
        if (!this._unsubscribeFromMailMarketing) throw new Error("resource has not been properly initialized")
        return this._unsubscribeFromMailMarketing(requestBody, null, null).toPromise()
    }

}