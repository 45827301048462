<div fxLayout="column" fxLayoutAlign="start center" class="wrapper">
    <div class="coach-welcome-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <div class="coach-picture"
             fxLayout="row" fxLayoutAlign="center center"
             [ngStyle]="{'background-image': 'url(' + talentAgentImageUrl + ')'}">
        </div>

        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
            <div class="headline">{{coachData?.firstName}} {{coachData?.lastName}}</div>
            <div class="subline">Dein Karrierecoach bei Talentagent</div>
        </div>
    </div>

    <div class="coach-welcome-content">

        <span class="welcome-headline">Einladung von {{coachData?.firstName}} {{coachData?.lastName}}</span>
        <br><br>
        Willkommen auf Talentagent!<br><br>
        Dein Coach {{coachData?.firstName}} {{coachData?.lastName}} hat dich auf unsere Plattform eingeladen, damit dir der perfekte Jobeinstieg gelingt.
        Nach deiner Anmeldung schlagen wir dir passende Stellenangebote vor.
        <br><br>
        {{coachData?.firstName}} kann dich bei der Jobsuche unterstützen und dich ggf. direkt mit Personalentscheidern bei ausgewählten,
        renommierten Unternehmen vernetzen. Dazu kann er die von dir auf der Plattform eingegebenen Daten einsehen.
        Mehr Infos dazu findest du in unserer  <a [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
        <br><br>
        Starte jetzt deine Anmeldung und erhalte bereits in 5 Minuten die ersten Jobangebote.
        <br><br>
        Viel Spaß mit Talentagent!
    </div>

    <button mat-flat-button color="accent" (click)="startOnboarding()">Anmeldung starten</button>

    <div class="mlp-logo">
        <img src="../../../../assets/images/mlp_logo-slogan.png">
    </div>
</div>
