import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {TalentEventInterestingCompanyData, TalentEventResource} from "utility";
import {
  EventCompanyProfileDialogComponent
} from "../event-company-profile-dialog/event-company-profile-dialog.component";
import {EventOnboardingService} from "../../onboardings/event-onboarding/event-onboarding.service";

@Component({
  selector: 'interesting-event-companies',
  templateUrl: './interesting-event-companies.component.html',
  styleUrls: ['./interesting-event-companies.component.scss']
})
export class InterestingEventCompaniesComponent implements OnInit {

  @Input() eventId: number

  companies: TalentEventInterestingCompanyData[]

  constructor(
      private dialog: MatDialog,
      private talentEventResource: TalentEventResource,
      private eventOnboardingService: EventOnboardingService
  ) { }

  ngOnInit(): void {
    this.getTalentEventCompanies()
  }

  openCompanyProfileDialog(data: TalentEventInterestingCompanyData) {
    this.dialog.open(EventCompanyProfileDialogComponent,
        {
          panelClass: ['full-width-mobile-dialog', 'full-height-mobile-dialog', 'no-x-scroll'],
          data: data
        }
    )
  }
  toggleInterestForCompany(data: TalentEventInterestingCompanyData) {
    this.talentEventResource.updateInterestingCompanyForTalent({companyId: data.company.company.id, eventId: data.eventId}, {interesting: !data.interesting})
    data.interesting = !data.interesting
    this.updateInterestingCompanies()
  }


  getTalentEventCompanies() {
    this.talentEventResource.getCompaniesForTalentWithInterestingState(this.eventId).then(result => {
      this.companies = result
      this.updateInterestingCompanies()
    })
  }

  updateInterestingCompanies() {
    this.eventOnboardingService.interestingCompanyData = this.companies.filter(it => it.interesting)
  }

}
