<div class="overview-wrapper">
    <div class="spotlight-section" fxLayout="row" fxLayoutAlign="start start">
        <div class="black-backdrop"></div>
        <i class="mdi mdi-chevron-left left-slider" (click)="skipSpotlight(currentSpotlightOnDisplay - 1)"></i>
        <i class="mdi mdi-chevron-right right-slider" (click)="skipSpotlight(currentSpotlightOnDisplay + 1)"></i>
        <div class="spotlight-container"
             *ngFor="let spotlight of spotlightBundle; let i = index"
             [ngClass]="{'no-display': currentSpotlightOnDisplay != i}"
             [ngStyle]="spotlight.heroImage ?
            {
            'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + spotlight.id + '/companyProfile/heroImage)'
            } : {}
            ">
            <div class="spotlight-content"
                 fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
                 fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
                <div class="spotlight-base-info">
                    <h3><i class="mdi mdi-star-outline"></i> Unternehmen Spotlight:</h3>
                    <div class="spotlight-logo"
                         [ngStyle]="{'background-image': 'url(' + environmentVar.apiUrl + '/public/company/' + spotlight.id + '/companyLogo)'}"
                    ></div>
                    <h2 class="spotlight-tagline">{{spotlight.slogan}}</h2>
                    <div
                            (click)="openProfileWithJobAnchor(spotlight.id)"
                            class="jobs-available">{{spotlight.availableJobs}} Jobs verfügbar</div>
                </div>
                <div class="spotlight-intro-box" >
                    <h4 style="margin-top: 0;"><strong style="font-size: 1.3rem; font-weight: 500;">{{spotlight.companyName}}</strong></h4>
                    <div [innerHTML]="spotlight.introText | safeHtml"></div>
                    <div class="center-content">
                        <button
                                [ngStyle]="{'background-color': spotlight.accentColor}"
                                mat-flat-button class="spotlight-button" (click)="navigateToCompanyPage(spotlight.id)">Mehr erfahren</button>
                    </div>
                </div>
            </div>
            <div class="spotlight-overlay" [ngStyle]="{'background-color': spotlight.primaryColor}"></div>
        </div>
    </div>

    <div class="search-company-wrapper">
        <h2 *ngIf="companies.length > 0">{{totalCompanies}} Unternehmen gefunden <span *ngIf="searchTag.length > 0">mit Begriff '{{searchTag}}'</span></h2>
        <h2 *ngIf="companies.length == 0">Keine Unternehmen mit Suchbegriff <span *ngIf="searchTag.length > 0">'{{searchTag}}'</span> gefunden</h2>
        <div class="center-content width-100">
            <ta-search-bar
                    class="searchbar"
                    [keyWordMinLength]="0"
                    (search)="addSearchTag($event)"
                    [placeholder]="'Firmenname oder Standort'">
            </ta-search-bar>
        </div>

        <div class="grid-tile-main-container">
            <app-company-overview-card *ngFor="let company of companies" [companyData]="company"></app-company-overview-card>
        </div>
        <icon-loading-spinner *ngIf="loadingCompanies"></icon-loading-spinner>
        <div class="center-content" *ngIf="showMoreCompaniesBtn">
            <button [disabled]="loadingCompanies" mat-flat-button class="more-companies-button" (click)="showMoreCompanies()">
                Mehr anzeigen
            </button>
        </div>
    </div>

</div>