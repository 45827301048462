<div class="image-section" *ngIf="showGallery()" [ngClass]="{'padding-vertical-lg': !mediaService.isMobile()}">
    <div *ngIf="showImageVideoSelection()">
        <div *ngIf="showImages || videoUrl?.length > 0" class="gallery-nav-wrapper">
            <div class="gallery-image"
                 style="background-color: #c4302b"
                 *ngIf="videoUrl?.length > 0"
                 (click)="showVideo = true"
                 [ngClass]="{'active-image': showVideo}" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-play" style="font-size: 46px; color: white"></i>
            </div>

            <div    [ngStyle]="{'background-image': 'url(' + getBackgroundImageUrl(image) + ')'}"
                    (click)="activeImageIndex = i; showVideo = false"
                    [ngClass]="{'active-image': activeImageIndex == i && !showVideo}"
                    class="gallery-image"
                    *ngFor="let image of positionImages; let i = index">
            </div>
        </div>
    </div>

    <div *ngIf="!showVideo" class="display-wrapper">
        <div class="image-wrapper">
            <img class="display-image"  [src]="positionImages[activeImageIndex].url">
            <div class="display-text" *ngIf="positionImages[activeImageIndex]?.description">{{positionImages[activeImageIndex].description}}</div>
        </div>
    </div>

    <div *ngIf="showVideo" class="video-display-wrapper">
        <div  class="display-video" *ngIf="safeUrl && showVideo" [ngClass]="{'video-aspect-ratio': videoUrl?.length > 0}">
            <iframe style="max-width: 100%; width: 100%;" height="100%" [src]="safeUrl" frameborder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>
