<div id="profilepic"
     fxLayout="row"
     fxLayoutAlign="center end"
     (click)="fileInput.click()"
     [class.mat-elevation-z2]="elevated"
     [ngStyle]="{'background-image': profilePictureAvailable ? 'url(' + profilePicture + ')' : 'url(' + './../assets/images/blurred_user.png' + ')'}"
     class="profile-pic">
    <div class="overlay" fxLayout="row" fxLayoutAlign="center center">
        <i class="mdi mdi-24px mdi-pencil"></i>
    </div>

    <input #fileInput type="file" style="visibility: hidden; width: 0" [formControl]="file"
           accept="image/png, image/jpeg, image/jpg, image/gif"
           (change)="editProfilePicDialog($event)"/>
</div>
