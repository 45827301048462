import {Component, Input, OnInit} from '@angular/core';
import {PositionChipData, PublicEmploymentPositionResource} from "utility";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'position-usp-chips',
  templateUrl: './position-usp-chips.component.html',
  styleUrls: ['./position-usp-chips.component.scss']
})
export class PositionUspChipsComponent implements OnInit {

    chips: PositionChipData[] = []

    loading: boolean = false
    splitId?: number = null

    @Input() positionId: number
    @Input() mobileDetailUsps: boolean = false

  constructor(
      private publicEmploymentPositionResource: PublicEmploymentPositionResource,
      private route: ActivatedRoute
  ) {

      this.route.snapshot.queryParams['splitId'] ? this.splitId = parseInt(this.route.snapshot.queryParams['splitId']) : this.splitId = null

  }

  ngOnInit(): void {
        this.loadChips()
    }

    loadChips() {
        this.loading = true
        this.publicEmploymentPositionResource.getEmploymentPositionUspsById(this.positionId, {splitId: this.splitId}).then(result => {
            this.chips = result
            this.loading = false
        })
    }

}
