import { Component, OnInit } from '@angular/core';
import {TalentCoachData, TalentCoachResource, UtilityService} from "utility";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MediaService} from "../../../services/inno-utils/media.service";
import {Router} from "@angular/router";
import {PopUpService} from "../../../services/pop-up.service";
import {
  AssignTalentAgentDialogComponent
} from "../../../coach/assign-talent-agent-dialog/assign-talent-agent-dialog.component";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'coach-home-mobile',
  templateUrl: './coach-home-mobile.component.html',
  styleUrls: ['./coach-home-mobile.component.scss']
})
export class CoachHomeMobileComponent implements OnInit {


  loadingSubject = new BehaviorSubject<boolean>(true)
  talentCoachData: TalentCoachData

  constructor(
      private talentCoachResource: TalentCoachResource,
      private dialog: MatDialog,
      public mediaService: MediaService,
      private router: Router,
      private homeService: HomeService,
      private popUpService: PopUpService,
      public authService: TalentAuthService
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.loadTalentAgent()
  }

  loadTalentAgent() {
    this.loadingSubject.next(true)
    this.talentCoachResource.getTalentCoach().then(result => {
      this.talentCoachData = result
      this.loadingSubject.next(false)
    })
  }

  openConsultantAppointmentDialog() {
    this.popUpService.openConsultantAppointmentDialog(false).then(result => {
      if (result) this.talentCoachData.appointmentRequestable = false
    })
  }

  getHeadline() {
    if (!this.talentCoachData?.talentAgent && !this.talentCoachData?.requestedAt) {
      return 'Erhalte einen kostenfreien Karrierecoach'
    } else if (!this.talentCoachData?.talentAgent && this.talentCoachData?.requestedAt) {
      return 'Wir suchen deinen Coach'
    } else {
      return 'Dein Coach'
    }
  }

  openAssignDialog() {
    const dialogRef = this.dialog.open(AssignTalentAgentDialogComponent, {
      width: this.mediaService.mobileQuery.matches ? '100vw' : '500px',
      maxWidth: '100vw',
      panelClass: ['full-width-mobile-dialog', 'release-info-dialog']
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTalentAgent()
      }
    })
  }

}