import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PublicContactData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.ContactApiControllerV2
 */

@Injectable()
@ResourceParams({})
export class ContactApiResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/contact/authentication',
        endpoint: 'de.syntacton.cronos'
    })
    private _getContactAuthenticationInformationByToken: IResourceMethodObservableStrict<null, {code: string}, null, JsonWrappedValue<string>> | undefined
    getContactAuthenticationInformationByToken(requestParams: {code: string}): Promise<JsonWrappedValue<string>> {
        if (!this._getContactAuthenticationInformationByToken) throw new Error("resource has not been properly initialized")
        return this._getContactAuthenticationInformationByToken(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/contact/{contactDataAccessToken}',
        endpoint: 'de.syntacton.cronos'
    })
    private _getContactDataByAccessToken: IResourceMethodObservableStrict<null, null, {contactDataAccessToken: string}, PublicContactData> | undefined
    getContactDataByAccessToken(contactDataAccessToken: string): Promise<PublicContactData> {
        if (!this._getContactDataByAccessToken) throw new Error("resource has not been properly initialized")
        return this._getContactDataByAccessToken(null, null, {contactDataAccessToken: contactDataAccessToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/contact/phoneNumber/verify/sms',
        endpoint: 'de.syntacton.cronos'
    })
    private _sendAuthenticationSmsToVerifyContactPhoneNumber: IResourceMethodObservableStrict<null, {code: string, phoneNumber: string}, null, null> | undefined
    sendAuthenticationSmsToVerifyContactPhoneNumber(requestParams: {code: string, phoneNumber: string}): Promise<null> {
        if (!this._sendAuthenticationSmsToVerifyContactPhoneNumber) throw new Error("resource has not been properly initialized")
        return this._sendAuthenticationSmsToVerifyContactPhoneNumber(null, requestParams, null).toPromise()
    }

}