import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {
  CoachingTypeData,
  CoachingTypeResource,
  TalentOnboardingResource
} from "utility";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-co-coach',
  templateUrl: './co-coach.component.html',
  styleUrls: ['./co-coach.component.scss']
})
export class CoCoachComponent implements OnInit {

  coachingsForm = new FormGroup({});
  public availableCoachingTypes: CoachingTypeData[];
  public selectedCoachingTypes: CoachingTypeData[];
  public privacyUrl = environment.publicPageUrl + '/privacy'
  progessingSubject: Subject<any> = new Subject()

  constructor(
      private coachingTypeResource: CoachingTypeResource,
      private onboardingService: CoachingOnboardingService,
      private talentOnboardingResource: TalentOnboardingResource,
  ) {
  }

  ngOnInit() {

    this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
      this.availableCoachingTypes = coachings;

      this.availableCoachingTypes[this.availableCoachingTypes.findIndex(i => i.title == 'Persönlichkeitsanalyse (AECdisc)')].title = 'Bestimmung deiner Stärken (AECdisc)'
      this.availableCoachingTypes[this.availableCoachingTypes.findIndex(i => i.title == 'Coaching Gehaltsverhandlung')].title = 'Erfolgreiche Gehaltsverhandlung führen'

      this.availableCoachingTypes.forEach(coaching => {
        if (coaching.title == 'Hilfe im Bewerbungsprozess') {
          this.coachingsForm.addControl(coaching.title, new FormControl({value: true, disabled: true}));
        } else {
          this.coachingsForm.addControl(coaching.title, new FormControl(false));
        }
      });
    })
  }

  progress() {
    this.progessingSubject.next()
    let choosenCoachingIds: number[] = [];

    Object.keys(this.coachingsForm.controls).forEach(controlName => {
      if (this.coachingsForm.controls[controlName].value == true) {
        choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.title == controlName)[0].id);
      }
    });

    this.onboardingService.updateInputs(<CachedOBInputs>{
      choosenCoachingIds: choosenCoachingIds
    })

    this.onboardingService.delayedRouting('onboarding/coach/coach-data')
  }
}
