import {Injectable} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {debounceTime} from 'rxjs/operators';
import {PublicResource as UniSignPublicResource} from "../generated/unisign/resources";

@Injectable({
  providedIn: 'root'
})
export class EmailAddressAvailabilityValidatorService {

    constructor(private unisignPublicResource: UniSignPublicResource) {
    }

  debouncedSubject = new Subject<string>();
  validatorSubject = new Subject();

  //provide subject to listen to pending validation if needed. you can provide null if you dont want to
  createValidator(validationPending: BehaviorSubject<boolean>) {
    this.debouncedSubject
      .pipe(debounceTime(1000))
      .subscribe(model => {
          this.unisignPublicResource.getEmailAvailableForRegistration({email: model}).then(res => {
              if (res.available) {
                  this.validatorSubject.next(null)
              } else {
                  this.validatorSubject.next({emailTaken: true})
              }

              if (validationPending) {
                  validationPending.next(false)
              }
          });

      });

    return (control: AbstractControl) => {
      if (validationPending) {
        validationPending.next(true)
      }
      this.debouncedSubject.next(control.value);

      let prom = new Promise<any>((resolve, reject) => {
        this.validatorSubject.subscribe(
          (result) => resolve(result)
        );
      });

      return prom
    };
  }
}
