import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'careertype'
})
export class CareertypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (args == null) args = ""
    var res = ""
    switch (value) {
      case "Job":
        res = "Beschäftigung";
        break;
      case "Apprenticeship":
        res = "Ausbildung";
        break;
      case "Internship":
        res = "Praktikum";
        break;
      case 'FullStudies':
        res = "Studium";
        break;
      case "Training":
        res = "Weiterbildung";
        break;
      case "Volunteering":
        res = "Ehrenamt";
        break;
      case 'Education':
        res = 'Schulausbildung';
        break;
      case 'Freelancing':
        res = 'Freiberufliche Tätigkeit';
        break;
      case 'SemesterAbroad':
        res = 'Auslandssemester';
        break;
      case 'IntegratedDegreeProgram':
        res = 'Duales Studium';
        break;
    }
    if (args == 'conjugate') {
      if (['Beschäftigung', 'Ausbildung', 'Weiterbildung', 'Schulausbildung', 'Freiberufliche Tätigkeit'].indexOf(res) > -1) {
        res = "e " + res
      } else {
        res = " " + res
      }
    }
    return res

  }

}
