<div fxLayout="column" fxLayoutAlign="center center">
    <app-s2-fax2-o-step-headline >Lade jetzt deinen Lebenslauf hoch, damit du dich bewerben kannst.</app-s2-fax2-o-step-headline>

    <div class="upload-container" fxLayout="column" fxLayoutAlign="start center">
        <div *ngIf="cvFile.value != null" class="work-sample" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" >
            <div class="work-sample-file" style="background-color: var(--secondary)">
                <div fxLayout="row" fxFlex="1 1" class="action-bar" fxLayoutAlign="center center">
                    <button mat-icon-button (click)="this.cvFile.setValue(null)"><i class="mdi mdi-delete"></i></button>
                </div>
            </div>
            <div>{{cvFile?.value?.name? cvFile?.value?.name : 'Lebenslauf'}}</div>
        </div>

        <label *ngIf="cvFile.value == null" class="ita_profile_box ita_upload_cv" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" [for]="'cvFileInputFax'" style="position:relative;">
            <div class="work-sample-file-new upload-cv" fxLayout="column" fxLayoutAlign="center center">
                <i class="mdi mdi-file-upload-outline"></i>
                <div class="upload-button-text">Lebenslauf Hochladen</div>
            </div>
        </label>
        <input type="file"
               (change)="set($event)"
               [accept]="fileService.whiteListedCvExtensionsStr"
               [formControl]="cvFile" id="cvFileInputFax" #cvFileInputFax class="inputfile">

        <div id="file-errors" *ngIf="uploadTried" fxLayout="column" class="m-top10">
            <small style="color: red" *ngIf="controlErrors!= null && controlErrors['sizeTooBig']">
                Die Datei ist größer als 10MB, Bitte wähle eine kleinere Datei.
            </small>
            <small style="color: red" *ngIf="controlErrors != null && controlErrors['wrongExtension']">
                Falsches Format. Bitte wähle ein PDF-Dokument.
            </small>
            <small *ngIf="controlErrors != null && controlErrors['fileIsEmpty']">
                Die ausgewählte Datei ist leer.
            </small>
        </div>
    </div>

    <div class="hint">*Du kannst deinen Lebenslauf auch später hochladen,</div>
    <div class="hint">falls du ihn gerade nicht zur Hand hast.</div>
</div>


<app-s2-fax2-o-button-bar
        (pressedNext)="attemptUpload()"
        [backButton]="false"
        [skipButton]="true"
        (pressedSkip)="skipNoCv()"
        [validSubject]="fileValidSubject"
>

</app-s2-fax2-o-button-bar>
