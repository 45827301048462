<div style="min-height:90%">
    <div
            id="filter-other-inner-container"
            class="flex column gap filter-form-inner-container">
        <section
                class="flex column offset-padding-horizontal">
            <h2>Was ist dir bei deinem Job wichtig?</h2>
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Deine Arbeitszeit</h3>
                    <div *ngIf="(workingHoursFrom != 5 || workingHoursTo != 48)" class="filter-count">{{this.chosenTimeBenefits.length + 1}}</div>
                    <div *ngIf="(workingHoursFrom == 5 && workingHoursTo == 48) && this.chosenTimeBenefits.length > 0"
                         class="filter-count">{{this.chosenTimeBenefits.length}}</div>
                </div>
                <button
                        (click)="resetWorkTimes()"
                        mat-icon-button class="delete-button">
                    <i class="mdi mdi-filter-off"></i>
                </button>
            </div>

            <div
                    *ngIf="workTimeExpanded"
                    [ngClass]="{'fast-fade-out': removingWorkTime}"
                    class="flex row start-center fast-fade-in inputs">
                <input
                        [value]="workingHoursFrom"
                        (change)="updateWorkingHoursFrom($event)"
                        min="5" [max]="workingHoursTo"
                        class="standard-input margin-right" style="width: 4rem" type="number">
                <span class="margin-right">-</span>
                <input
                        [value]="workingHoursTo"
                        (change)="updateWorkingHoursTo($event)"
                        [min]="workingHoursFrom" max="48"
                        class="standard-input margin-right" style="width: 4rem" type="number">
                <div>
                    Stunden pro Woche
                </div>
            </div>

            <mat-slider
                    *ngIf="workTimeExpanded" min="5" max="48" step="1">
                <input [(value)]="workingHoursFrom"
                       (input)="updateWorkingHoursFromOnDrag($event)"
                       (valueChange)="lastChangedWorkingHours = 'from'" matSliderStartThumb>
                <input [(value)]="workingHoursTo"
                       (input)="updateWorkingHoursToOnDrag($event)"
                       (valueChange)="lastChangedWorkingHours = 'to'" matSliderEndThumb>
            </mat-slider>


            <div
                    *ngIf="workTimeExpanded && !loadingTimeBenefits"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in">
                <button *ngFor="let benefit of timeBenefits"
                        [ngClass]="{'light-selectable-chip': !chosenTimeBenefits.includes(benefit),
                        'dark-selectable-chip': chosenTimeBenefits.includes(benefit)}"
                        (click)="toggleTimeBenefit(benefit)">{{benefit.name}}</button>
            </div>
            <app-positions-chips-skeleton class="margin-bottom-sm" *ngIf="loadingTimeBenefits"></app-positions-chips-skeleton>
            <app-positions-chips-skeleton *ngIf="loadingTimeBenefits"></app-positions-chips-skeleton>

            <div
                    [ngClass]="{'fast-flip': workTimeExpanded, 'reverse-flip': !workTimeExpanded}"
                    (click)="expandOrMinimizeSection('workTime')"
                    class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down"></i>
            </div>
        </section>

        <section class="flex column offset-padding-horizontal background-light-grey">
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Anstellungsart</h3>
                    <div *ngIf="selectedEmploymentTypes.length > 0" class="filter-count">{{selectedEmploymentTypes.length}}</div>
                </div>
                <button (click)="deselectAllEmploymentTypes()" mat-icon-button class="delete-button">
                    <i class="mdi mdi-filter-off"></i>
                </button>
            </div>

            <div
                    *ngIf="employmentTypesExpanded"
                    [ngClass]="{'fast-fade-out': removingEmploymentTypes}"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in">
                <button *ngFor="let employmentType of employmentTypes"
                        (click)="changeEmploymentType(employmentType)"
                        [ngClass]="{'light-selectable-chip': !selectedEmploymentTypes.includes(employmentType),
                        'dark-selectable-chip': selectedEmploymentTypes.includes(employmentType)}"
                        class="light-selectable-chip">{{employmentType | workType}}</button>
            </div>
            <div
                    [ngClass]="{'fast-flip': employmentTypesExpanded, 'reverse-flip': !employmentTypesExpanded}"
                    (click)="expandOrMinimizeSection('employmentTypes')" class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down"></i>
            </div>
        </section>


        <section class="flex column offset-padding-horizontal">
            <div class="flex row space-between-center">
                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Weitere Benefits und Vorteile</h3>
                    <div *ngIf="chosenBenefits.length > 0" class="filter-count">{{chosenBenefits.length}}</div>
                </div>
                <button
                        (click)="deselectAllBenefits()"
                        mat-icon-button class="delete-button">
                    <i class="mdi mdi-filter-off"></i>
                </button>
            </div>

            <!--
            <div class="flex" (focusout)="onFocusOut($event)">
                <input #benefitInput (focus)="onFocus()" (blur)="onBlur($event)" (change)="loadBenefits()" [(ngModel)]="benefitQuery"
                       placeholder="Benefits und Vorteile suchen" class="standard-input margin-bottom" type="text">
                <div class="benefits-container padding" *ngIf="isFocused" (mousedown)="preventBlur($event)" (touchstart)="preventBlur($event)">
                    <div *ngFor="let benefit of searchedBenefits"
                         class="flex column
                          hover-dark-overlay clickable
                          benefit padding-vertical-sm" (click)="addBenefit(benefit)">
                        <span class="margin-bottom-sm">{{benefit.name}}</span>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            -->

            <app-filter-search-input-dropdown
                    *ngIf="benefitsExpanded"
                    (focusIn)="scrollToBottom($event)"
                    (queryChange)="loadBenefits($event)"
                    [query]="benefitQuery"
                    [items]="benefitsLabelData"
                    (itemClick)="addBenefitAsLabelData($event)"
                    class="fast-fade-in"
                    [selectedItems]="chosenBenefits"
                    [ngClass]="{'fast-fade-out': removingBenefits}">
            </app-filter-search-input-dropdown>

            <div
                    *ngIf="benefitsExpanded"
                    [ngClass]="{'fast-fade-out': removingBenefits}"
                    class="flex row-wrap-gap-sm gap-sm fast-fade-in margin-top">
                <button *ngFor="let benefit of chosenBenefits"
                        (click)="removeBenefit(benefit)"
                        class="dark-removeable-chip">
                    {{benefit.name}}
                    <i class="mdi mdi-close"></i>
                </button>
            </div>
            <div
                    [ngClass]="{'fast-flip': benefitsExpanded, 'reverse-flip': !benefitsExpanded}"
                    (click)="expandOrMinimizeSection('benefits')"
                    class="flex width-100 center clickable hover-dark-overlay">
                <i class="mdi mdi-chevron-down"></i>
            </div>
        </section>

        <section class="filter-cta-placeholder">

        </section>
    </div>
</div>

<section class="flex row gap center width-100 offset-padding-horizontal filter-absolute-ctas" [ngClass]="{'filter-sticky-ctas': stickyCtaButtons}">
    <button (click)="clearFilterAndSearch()" class="light-utility-button width-50">
                 <span
                         *ngIf="!mediaService.isMobile()">Filter zurücksetzen</span>
        <span
                *ngIf="mediaService.isMobile()">Zurücksetzen</span>
    </button>
    <button (click)="applyFilterAndSearch()" class="primary-utility-button width-50">
                     <span
                             *ngIf="!mediaService.isMobile()">Filter anwenden</span>
        <span
                *ngIf="mediaService.isMobile()">Anwenden</span>
    </button>
</section>
