import { Component, OnInit, OnDestroy } from '@angular/core';
import {CompanyData, CompanyResource, TalentCompanyDisplayData, TalentCompanyProfileResource} from "utility";
import {Params, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {MediaService} from "../../services/inno-utils/media.service";

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit, OnDestroy {

  page: number = 0
  pageSize: number = 15
  companies: TalentCompanyDisplayData[] = []
  searchTag: string = ''
  spotlightBundle:  TalentCompanyDisplayData[] = []
  environmentVar
  spotLightCounter: number = 0
  currentSpotlightOnDisplay: number = 0
  spotlightInterval
  totalCompanies: number = 0
  loadingCompanies: boolean = false
  showMoreCompaniesBtn: boolean = true

  constructor(
      private talentCompanyProfileResource: TalentCompanyProfileResource,
      private router: Router,
      public mediaService: MediaService
  ) {
    this.environmentVar = environment
  }

  ngOnInit(): void {
    this.showMoreCompanies()
    this.loadSpotlightBundle()
  }

  ngOnDestroy(): void {
    clearInterval(this.spotlightInterval)
  }

  addSearchTag(event: any){
    this.searchTag = event
    this.page = 0
    this.companies = []
    this.showMoreCompanies()
  }

  loadSpotlightBundle(){
    this.talentCompanyProfileResource.getSpotlightBundle().then(res => {
      this.spotlightBundle = res
      this.spotlightInterval = setInterval(() => {
        this.changeSpotlightOnDisplay()
      }, 20000)
    })
  }

  navigateToCompanyPage(companyId: number){
    this.router.navigateByUrl('/company-profile/' + companyId)
  }

  changeSpotlightOnDisplay(){
    this.spotLightCounter+=1
    let spotLightNumber = this.spotlightBundle.length
    this.currentSpotlightOnDisplay = this.spotLightCounter % spotLightNumber
  }

  skipSpotlight(newSpotlight: number){
    if(newSpotlight <= this.spotlightBundle.length - 1 && newSpotlight >= 0){
      this.currentSpotlightOnDisplay = newSpotlight
    }
    else if (newSpotlight < 0) {
      this.currentSpotlightOnDisplay = this.spotlightBundle.length - 1
    }
    else{
      this.currentSpotlightOnDisplay = 0
    }
    clearInterval(this.spotlightInterval)
    this.spotlightInterval = setInterval(() => {
      this.changeSpotlightOnDisplay()
    }, 20000)
  }

  showMoreCompanies(){
    this.loadingCompanies = true
    this.talentCompanyProfileResource.searchCompanyProfiles({query: this.searchTag, pageNum: this.page, pageSize: this.pageSize})
        .then(res => {
      this.companies = this.companies.concat(res.content)
      this.totalCompanies = res.totalElements
      this.loadingCompanies = false
      this.showMoreCompaniesBtn = res.totalPages > (this.page+1);
      this.page = this.page + 1
    })

  }

  openProfileWithJobAnchor(companyId: number){
    const queryParams: Params = { jobAnchor: 'true' };
    this.router.navigate(
        ['/company-profile/' + companyId],
        {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
  }
}
