import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {TalentHomeResource, TalentPositionRelationResource} from "utility";

@Injectable({
    providedIn: 'root'
})
export class PropagationService {

    public cvUploaded: Subject<any> = new Subject()
    public coachRequested: Subject<any> = new Subject()
    public applicationSent: Subject<any> = new Subject()
    public coachAppointmentRequested: Subject<any> = new Subject()

    public bookmarkedPositionIds: number[] = []
    public numBookmarkedProposals = 0
    public numRecommendedProposals = 0

    constructor(
        private talentPositionRelationResource: TalentPositionRelationResource,
        private talentHomeResource: TalentHomeResource,
    ) {
    }

    public refreshBookmarkedProposalsCount(): Promise<any> {
        return this.talentPositionRelationResource.findBookmarkedPositionIds().then(result => {
            // this.bookmarkedPositionIds = result.value
            this.numBookmarkedProposals = result.value.length
        })
    }

    getInitialBookmarkedProposalsCount(): Promise<any> {
        return this.talentPositionRelationResource.findBookmarkedPositionIds().then(result => {
            this.bookmarkedPositionIds = result.value
        })
    }

    public refreshRecommendedProposalCount(): Promise<any> {
        return this.talentHomeResource.getRecommendedCount().then(result => {
            this.numRecommendedProposals = result.value
        })
    }

    public refreshAllBadges(): Promise<any> {
        return Promise.all([
            this.refreshRecommendedProposalCount(),
            this.refreshBookmarkedProposalsCount()
        ])
    }
}
