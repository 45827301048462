
<ng-content select="[nationality]"></ng-content>
<div class="m-bottom15 font-size-11" style="margin-bottom: 15px; text-align: center">(Diese Frage ist wichtig für das Arbeitsrecht)</div>

<div class="width-100 nationality-wrapper" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <div (click)="selectNationality('German')" [ngClass]="{'selected': nationalityCtrl.value == 'German'}">Deutsch</div>
    <div (click)="selectNationality('Eu')" [ngClass]="{'selected': nationalityCtrl.value == 'Eu'}">anderes EU-Land</div>
    <div (click)="selectNationality('NotEu')" [ngClass]="{'selected': nationalityCtrl.value == 'NotEu'}">nicht EU-Bürger</div>
</div>

<small style="color: red; font-size: 12px" *ngIf="nationalityCtrl.errors && this.nationalityCtrl.touched">
    Bitte wähle deine Staatsbürgerschaft aus.
</small>


<div class="width-100 m-bottom20" *ngIf="nationalityCtrl.value && nationalityCtrl.value != 'German'">
    <ng-content select="[language]"></ng-content>
    <hid-dropdown class="width-100"
                  [control]="germanSkillLevelCtrl"
                  [mode]="'Object'"
                  [identifyingProperty]="'value'"
                  [displayProperty]="'display'"
                  [options]="skillLevel" label="Wähle ein Level aus...">
    </hid-dropdown>

    <small *ngIf="germanSkillLevelCtrl.errors && germanSkillLevelCtrl.touched"
           class="hid-validationError duplicate-mail-error"
           style="margin-top: 20px; padding: 5px 20px; text-align: center">
        Bitte wähle aus, wie gut du Deutsch sprichst.
    </small>
</div>