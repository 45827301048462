function WinkAnimation(canvas, anim_container, dom_overlay_container) {
	(function (cjs, an) {

		var p; // shortcut to reference prototypes
		var lib = {};
		var ss = {};
		var img = {};
		lib.ssMetadata = [];


		(lib.AnMovieClip = function () {
			this.actionFrames = [];
			this.ignorePause = false;
			this.gotoAndPlay = function (positionOrLabel) {
				cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
			}
			this.play = function () {
				cjs.MovieClip.prototype.play.call(this);
			}
			this.gotoAndStop = function (positionOrLabel) {
				cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
			}
			this.stop = function () {
				cjs.MovieClip.prototype.stop.call(this);
			}
		}).prototype = p = new cjs.MovieClip();
// symbols:
// helper functions:

		function mc_symbol_clone() {
			var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
			clone.gotoAndStop(this.currentFrame);
			clone.paused = this.paused;
			clone.framerate = this.framerate;
			return clone;
		}

		function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
			var prototype = cjs.extend(symbol, cjs.MovieClip);
			prototype.clone = mc_symbol_clone;
			prototype.nominalBounds = nominalBounds;
			prototype.frameBounds = frameBounds;
			return prototype;
		}


		(lib.Tween29 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.06, 0.0556, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween28 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween27 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.0271, 0.0062, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.4);


		(lib.Tween26 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween25 = function (mode, startPosition, loop, reversed) {
			
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.0327, 0.0184, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween24 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween23 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(-0.0299, 0.058, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.4, 3.5);


		(lib.Tween22 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween21 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.0175, 0.0149, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.4, 3.5);


		(lib.Tween20 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.5, 3.5);


		(lib.Tween19 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0.04, -0.0118, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.6, -1.7, 3.3, 3.4);


		(lib.Tween18 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(0, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-1.7, -1.7, 3.4, 3.5);


		(lib.Tween17 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D6B796").s().p("Al9IuQieifAAjfIAAlfQAAjgCeieQCeieDfAAQDfAACfCeQCeCeAADgIAAFfQAADfieCfQifCejfAAQjfAAieieg");
			this.shape.setTransform(0.0089, 0.0366, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-56, -71.5, 112.1, 143.1);


		(lib.Tween16 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D6B796").s().p("Al9IuQieifAAjfIAAlfQAAjgCeieQCeieDfAAQDfAACfCeQCeCeAADgIAAFfQAADfieCfQifCejfAAQjfAAieieg");

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-54, -71.6, 108, 143.2);


		(lib.Tween15 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D99333").s().p("AlNBNQhChTgehnIgQhWQDyieDTAnQCvAgB+CcQBsCEAYCWQAKBDgNAsQgPAtglAJQgpACgoAAQm6AAjEj2g");
			this.shape.setTransform(-0.1614, 0.9681, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-46.6, -30.5, 93.2, 61.1);


		(lib.Tween14 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D99333").s().p("AlNBNQhChTgehnIgQhWQDyieDTAnQCvAgB+CcQBsCEAYCWQAKBDgNAsQgPAtglAJQgpACgoAAQm6AAjEj2g");
			this.shape.setTransform(0.0047, 0.0136);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-44.5, -32.2, 89.1, 64.5);


		(lib.Tween13 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#1D1D1B").s().p("AgWA7QgKgJAAgOIAAhHQAAgOAKgJQAKgKAMAAQANAAAKAKQAKAJAAAOIAABHQAAAOgKAJQgKAKgNAAQgMAAgKgKg");
			this.shape.setTransform(0.0231, 0.0098, 1, 1, -6.4341);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-3.6, -6.8, 7.300000000000001, 13.7);


		(lib.Tween12 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#1D1D1B").s().p("AgWA7QgKgJAAgOIAAhHQAAgOAKgJQAKgKAMAAQANAAAKAKQAKAJAAAOIAABHQAAAOgKAJQgKAKgNAAQgMAAgKgKg");
			this.shape.setTransform(0, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-3.2, -6.8, 6.5, 13.7);


		(lib.Tween11 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D99333").s().p("AlCTkQiJgLhhgUQi5k2gvlwQg0mPBzm1QgbgngehAQg9iAgSh8Qg6mOGKjUQg9DrEVAvQBZAQCCgFQBEgCBUgFQAVAABTgHQBbgGBMAFQDxAQCkB4IABADQBOCDAZBRQAcBWAAB7QAABIgREFQgUE6gJERQgLFVjSC/QiqCakjAyQiHAXiYAAQhWAAhbgHg");
			this.shape.setTransform(0.0199, -0.0064, 0.9624, 1);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-83.6, -125.9, 167.3, 251.9);


		(lib.Tween10 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#D99333").s().p("AlCTkQiJgLhhgUQi5k2gvlwQg0mPBzm1QgbgngehAQg9iAgSh8Qg6mOGKjUQg9DrEVAvQBZAQCCgFQBEgCBUgFQAVAABTgHQBbgGBMAFQDxAQCkB4IABADQBOCDAZBRQAcBWAAB7QAABIgREFQgUE6gJERQgLFVjSC/QiqCakjAyQiHAXiYAAQhWAAhbgHg");
			this.shape.setTransform(0.0339, -0.0064);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-86.9, -125.9, 173.9, 251.9);


		(lib.Tween9 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#CC7E2F").s().p("AgdWrQmNgslcjaQlnjjjWlmQCiAZDtgyQDrgxDchmQDqhtCLiGQCaiWgBiWQABkxAPkbQAQj7AChTQADh+gXhTQgYhVhJh/IgCgCQCjB3BTDdQAmBkAbC3QATB6AZD6QA9JAA1DsQBjGvC/CIQDtCoGUj6QjvFil7CgQkQBzkuAAQhcAAhdgKg");
			this.shape.setTransform(0.0157, 0.0206, 0.9624, 1);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-129.7, -146.1, 259.5, 292.29999999999995);


		(lib.Tween8 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#CC7E2F").s().p("AgdWrQmNgslcjaQlnjjjWlmQCiAZDtgyQDrgxDchmQDqhtCLiGQCaiWgBiWQABkxAPkbQAQj7AChTQADh+gXhTQgYhVhJh/IgCgCQCjB3BTDdQAmBkAbC3QATB6AZD6QA9JAA1DsQBjGvC/CIQDtCoGUj6QjvFil7CgQkQBzkuAAQhcAAhdgKg");
			this.shape.setTransform(0, 0.0206);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-134.8, -146.1, 269.6, 292.29999999999995);


		(lib.Tween7 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#CC7E2F").s().p("AkdLPIg+wUIHPhtQAogQAqg4QBUhvAGjGQBSB9geJzQgQE4gfEgIlREXg");
			this.shape.setTransform(0.0026, 0, 0.9624, 1);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-33.5, -81.6, 67, 163.2);


		(lib.Tween6 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#CC7E2F").s().p("AkdLPIg+wUIHPhtQAogQAqg4QBUhvAGjGQBSB9geJzQgQE4gfEgIlREXg");
			this.shape.setTransform(0.0018, 0);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-34.8, -81.6, 69.6, 163.2);


		(lib.Tween3 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#F6F6F6").s().p("AhnA6QgjgZgTgrQgFgNAGgMQAFgMAMgFIAJgCID+geQANgCAKAIQALAJACANIgBAIQgGAvgZAfQgjAthFAIQgQACgOAAQg5AAgogbg");
			this.shape.setTransform(0.006, 0.0063);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-16, -8.5, 32, 17.1);


		(lib.Tween1 = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#1D1D1B").s().p("AgWA7QgJgJAAgOIAAhHQAAgOAJgJQAKgKAMAAQAOAAAJAKQAJAJAAAOIAABHQAAAOgJAJQgJAKgOAAQgMAAgKgKg");
			this.shape.setTransform(0.025, 0.025);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = p = new cjs.MovieClip();
		p.nominalBounds = new cjs.Rectangle(-3.2, -6.8, 6.5, 13.7);


		(lib.Path = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = true;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_1
			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A27B76").s().p("AgbC0IjElgQBhA0BtgFQBjgEBcg0QAdBzAVBcQg2BRhKAqQg4AhgxAAIgSgCg");
			this.shape.setTransform(22.4, 18.1538);

			this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

			this._renderFirstFrame();

		}).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0, 44.8, 36.3), null);


// stage content:
		(lib.WinkAnimation = function (mode, startPosition, loop, reversed) {
			if (loop == null) {
				loop = false;
			}
			if (reversed == null) {
				reversed = false;
			}
			var props = new Object();
			props.mode = mode;
			props.startPosition = startPosition;
			props.labels = {};
			props.loop = loop;
			props.reversed = reversed;
			cjs.MovieClip.apply(this, [props]);

			// Ebene_36
			this.instance = new lib.Tween28("synched", 0);
			this.instance.setTransform(256.2, 218.9);

			this.instance_1 = new lib.Tween29("synched", 0);
			this.instance_1.setTransform(251.15, 221.35);
			this.instance_1._off = true;

			this.shape = new cjs.Shape();
			this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape.setTransform(256.225, 218.925);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance}]}).to({state: [{t: this.instance_1}]}, 3).to({state: [{t: this.instance_1}]}, 10).to({state: [{t: this.instance}]}, 3).to({state: [{t: this.shape}]}, 12).to({state: [{t: this.shape}]}, 1).to({state: [{t: this.shape}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance).to({
				_off: true,
				x: 251.15,
				y: 221.35
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 256.2,
				y: 218.9
			}, 3, cjs.Ease.quadOut).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_1).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 256.2,
				y: 218.9
			}, 3, cjs.Ease.quadOut).wait(15));

			// Ebene_37
			this.instance_2 = new lib.Tween18("synched", 0);
			this.instance_2.setTransform(261.05, 215.2);

			this.instance_3 = new lib.Tween19("synched", 0);
			this.instance_3.setTransform(255.55, 217.2);
			this.instance_3._off = true;

			this.shape_1 = new cjs.Shape();
			this.shape_1.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape_1.setTransform(261.05, 215.225);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_2}]}).to({state: [{t: this.instance_3}]}, 3).to({state: [{t: this.instance_3}]}, 10).to({state: [{t: this.instance_2}]}, 3).to({state: [{t: this.shape_1}]}, 12).to({state: [{t: this.shape_1}]}, 1).to({state: [{t: this.shape_1}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_2).to({
				_off: true,
				x: 255.55,
				y: 217.2
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 261.05,
				y: 215.2
			}, 3, cjs.Ease.quadOut).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_3).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 261.05,
				y: 215.2
			}, 3, cjs.Ease.quadOut).wait(15));

			// Ebene_38
			this.instance_4 = new lib.Tween20("synched", 0);
			this.instance_4.setTransform(253.3, 213.45);

			this.instance_5 = new lib.Tween21("synched", 0);
			this.instance_5.setTransform(247.7, 216.3);
			this.instance_5._off = true;

			this.shape_2 = new cjs.Shape();
			this.shape_2.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape_2.setTransform(253.325, 213.475);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_4}]}).to({state: [{t: this.instance_5}]}, 3).to({state: [{t: this.instance_5}]}, 10).to({state: [{t: this.instance_4}]}, 3).to({state: [{t: this.shape_2}]}, 12).to({state: [{t: this.shape_2}]}, 1).to({state: [{t: this.shape_2}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_4).to({
				_off: true,
				x: 247.7,
				y: 216.3
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 253.3,
				y: 213.45
			}, 3, cjs.Ease.quadOut).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_5).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 253.3,
				y: 213.45
			}, 3, cjs.Ease.quadOut).wait(15));

			// Ebene_39
			this.instance_6 = new lib.Tween22("synched", 0);
			this.instance_6.setTransform(191.95, 218.5);

			this.instance_7 = new lib.Tween23("synched", 0);
			this.instance_7.setTransform(187.35, 228.15);
			this.instance_7._off = true;

			this.shape_3 = new cjs.Shape();
			this.shape_3.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape_3.setTransform(191.975, 218.525);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_6}]}).to({state: [{t: this.instance_7}]}, 3).to({state: [{t: this.instance_7}]}, 10).to({state: [{t: this.instance_6}]}, 3).to({state: [{t: this.shape_3}]}, 12).to({state: [{t: this.shape_3}]}, 1).to({state: [{t: this.shape_3}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_6).to({
				_off: true,
				x: 187.35,
				y: 228.15
			}, 3, cjs.Ease.quadIn).wait(10).to({_off: false, x: 191.95, y: 218.5}, 3).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_7).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 191.95,
				y: 218.5
			}, 3).wait(15));

			// Ebene_40
			this.instance_8 = new lib.Tween24("synched", 0);
			this.instance_8.setTransform(194.4, 212.95);

			this.instance_9 = new lib.Tween25("synched", 0);
			this.instance_9.setTransform(189.1, 222.4);
			this.instance_9._off = true;

			this.shape_4 = new cjs.Shape();
			this.shape_4.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape_4.setTransform(194.425, 212.975);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_8}]}).to({state: [{t: this.instance_9}]}, 3).to({state: [{t: this.instance_9}]}, 10).to({state: [{t: this.instance_8}]}, 3).to({state: [{t: this.shape_4}]}, 12).to({state: [{t: this.shape_4}]}, 1).to({state: [{t: this.shape_4}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_8).to({
				_off: true,
				x: 189.1,
				y: 222.4
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 194.4,
				y: 212.95
			}, 3, cjs.Ease.quadOut).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_9).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 194.4,
				y: 212.95
			}, 3, cjs.Ease.quadOut).wait(15));

			// Ebene_41
			this.instance_10 = new lib.Tween26("synched", 0);
			this.instance_10.setTransform(186.8, 215.15);

			this.instance_11 = new lib.Tween27("synched", 0);
			this.instance_11.setTransform(181.8, 225.45);
			this.instance_11._off = true;

			this.shape_5 = new cjs.Shape();
			this.shape_5.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
			this.shape_5.setTransform(186.825, 215.175);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_10}]}).to({state: [{t: this.instance_11}]}, 3).to({state: [{t: this.instance_11}]}, 10).to({state: [{t: this.instance_10}]}, 3).to({state: [{t: this.shape_5}]}, 12).to({state: [{t: this.shape_5}]}, 1).to({state: [{t: this.shape_5}]}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_10).to({
				_off: true,
				x: 181.8,
				y: 225.45
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 186.8,
				y: 215.15
			}, 3, cjs.Ease.quadOut).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_11).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 186.8,
				y: 215.15
			}, 3, cjs.Ease.quadOut).wait(15));

			// eye_right
			this.instance_12 = new lib.Tween1("synched", 0);
			this.instance_12.setTransform(247.55, 195.95);

			this.timeline.addTween(cjs.Tween.get(this.instance_12).to({
				regX: 0.2,
				regY: 0.4,
				scaleX: 1.2985,
				scaleY: 0.3115,
				skewX: -34.4732,
				skewY: -36.4345,
				x: 240.3,
				y: 199.55
			}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				regX: 0,
				regY: 0,
				scaleX: 1,
				scaleY: 1,
				skewX: 0,
				skewY: 0,
				x: 247.55,
				y: 195.95
			}, 3, cjs.Ease.quadOut).to({startPosition: 0}, 12).to({startPosition: 0}, 1).to({startPosition: 0}, 1).wait(1));

			// eye_left
			this.instance_13 = new lib.Tween12("synched", 0);
			this.instance_13.setTransform(203.5, 195.75);

			this.instance_14 = new lib.Tween13("synched", 0);
			this.instance_14.setTransform(196.2, 204.3);
			this.instance_14._off = true;

			this.timeline.addTween(cjs.Tween.get(this.instance_13).to({
				_off: true,
				x: 196.2,
				y: 204.3
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 203.5,
				y: 195.75
			}, 3, cjs.Ease.quadOut).to({startPosition: 0}, 12).to({startPosition: 0}, 1).to({startPosition: 0}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_14).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 203.5,
				y: 195.75
			}, 3, cjs.Ease.quadOut).wait(15));

			// bangs
			this.instance_15 = new lib.Tween14("synched", 0);
			this.instance_15.setTransform(240.15, 135.9);

			this.instance_16 = new lib.Tween15("synched", 0);
			this.instance_16.setTransform(226.1, 139.75);
			this.instance_16._off = true;

			this.timeline.addTween(cjs.Tween.get(this.instance_15).to({
				_off: true,
				x: 226.1,
				y: 139.75
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 240.15,
				y: 135.9
			}, 3, cjs.Ease.quadOut).to({startPosition: 0}, 12).to({startPosition: 0}, 1).to({startPosition: 0}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_16).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 240.15,
				y: 135.9
			}, 3, cjs.Ease.quadOut).wait(15));

			// mouth
			this.instance_17 = new lib.Tween3("synched", 0);
			this.instance_17.setTransform(230.1, 234.6);

			this.timeline.addTween(cjs.Tween.get(this.instance_17).to({
				scaleY: 1.3941,
				rotation: -6.4345,
				x: 227,
				y: 239.9
			}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				scaleY: 1,
				rotation: 0,
				x: 230.1,
				y: 234.6
			}, 3, cjs.Ease.quadOut).to({startPosition: 0}, 12).to({startPosition: 0}, 1).to({startPosition: 0}, 1).wait(1));

			// head
			this.instance_18 = new lib.Tween16("synched", 0);
			this.instance_18.setTransform(226.3, 194.7);

			this.instance_19 = new lib.Tween17("synched", 0);
			this.instance_19.setTransform(218.75, 200.65);
			this.instance_19._off = true;

			this.timeline.addTween(cjs.Tween.get(this.instance_18).to({
				_off: true,
				x: 218.75,
				y: 200.65
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				x: 226.3,
				y: 194.7
			}, 3, cjs.Ease.quadOut).to({startPosition: 0}, 12).to({startPosition: 0}, 1).to({startPosition: 0}, 1).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_19).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				x: 226.3,
				y: 194.7
			}, 3, cjs.Ease.quadOut).wait(15));

			// _Path_
			this.instance_20 = new lib.Path();
			this.instance_20.setTransform(229.95, 280.25, 1, 1, 0, 0, 0, 22.4, 18.2);
			this.instance_20.alpha = 0.4297;

			this.timeline.addTween(cjs.Tween.get(this.instance_20).wait(31));

			// Ebene_46
			this.shape_6 = new cjs.Shape();
			this.shape_6.graphics.f("#1F918E").s().p("AAeocIC5CEIhyBaIATE+IlOIdg");
			this.shape_6.setTransform(249.375, 336.975);

			this.timeline.addTween(cjs.Tween.get(this.shape_6).wait(31));

			// Ebene_47
			this.shape_7 = new cjs.Shape();
			this.shape_7.graphics.f("#1F918E").s().p("Ah1AGIAUk+IhyhaIC5iFIDuQvg");
			this.shape_7.setTransform(206.7, 337.525);

			this.timeline.addTween(cjs.Tween.get(this.shape_7).wait(31));

			// Ebene_49
			this.shape_8 = new cjs.Shape();
			this.shape_8.graphics.f("#E67A1A").s().p("AhYA9IAbmwIB1AAIAhG0IhZEzg");
			this.shape_8.setTransform(227.875, 353.875);

			this.timeline.addTween(cjs.Tween.get(this.shape_8).wait(31));

			// Ebene_50
			this.shape_9 = new cjs.Shape();
			this.shape_9.graphics.f("#E67A1A").s().p("Ag3BbIgpg0IBniAIBaCAIgiA0g");
			this.shape_9.setTransform(227.25, 308.65);

			this.timeline.addTween(cjs.Tween.get(this.shape_9).wait(31));

			// Ebene_51
			this.shape_10 = new cjs.Shape();
			this.shape_10.graphics.f("#D6B796").s().p("AilCAQgdgWgagcIgVgYIApjxQALgRC+ANQBfAGBdAJIA1DbIgRAXQgXAdgZAXQhSBMhUAEIgJAAQhQAAhXhGg");
			this.shape_10.setTransform(228.175, 278.5409);

			this.timeline.addTween(cjs.Tween.get(this.shape_10).wait(31));

			// Ebene_52
			this.shape_11 = new cjs.Shape();
			this.shape_11.graphics.f("#FFFFFF").s().p("AjxoRIHjgLIj0Q5g");
			this.shape_11.setTransform(228.175, 336.975);

			this.timeline.addTween(cjs.Tween.get(this.shape_11).wait(31));

			// Ebene_53
			this.shape_12 = new cjs.Shape();
			this.shape_12.graphics.f("#1EB5BA").s().p("Am7HtQgXAAgTgRQgSgQgGgaIi2s8QgIglAUgeQAUgfAhAAITlAAQAgAAAUAdQAVAdgHAlIiUM7QgFAcgTARQgSASgYAAg");
			this.shape_12.setTransform(225.4248, 341.825);

			this.timeline.addTween(cjs.Tween.get(this.shape_12).wait(31));

			// Ebene_54
			this.instance_21 = new lib.Tween6("synched", 0);
			this.instance_21.setTransform(268.05, 211.65);

			this.instance_22 = new lib.Tween7("synched", 0);
			this.instance_22.setTransform(264.2, 212, 1.0819, 1, -3.7051);
			this.instance_22._off = true;

			this.shape_13 = new cjs.Shape();
			this.shape_13.graphics.f("#CC7E2F").s().p("AkdLPIg+wUIHPhtQAogQAqg4QBUhvAGjGQBSB9geJzQgQE4gfEgIlREXg");
			this.shape_13.setTransform(268.0518, 211.65);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_21}]}).to({state: [{t: this.instance_22}]}, 3).to({state: [{t: this.instance_22}]}, 10).to({state: [{t: this.instance_21}]}, 3).to({state: [{t: this.shape_13}]}, 12).to({state: [{t: this.shape_13}]}, 2).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_21).to({
				_off: true,
				scaleX: 1.0819,
				rotation: -3.7051,
				x: 264.2,
				y: 212
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				scaleX: 1,
				rotation: 0,
				x: 268.05,
				y: 211.65
			}, 3).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_22).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				scaleX: 1,
				rotation: 0,
				x: 268.05,
				y: 211.65
			}, 3).wait(15));

			// Ebene_55
			this.instance_23 = new lib.Tween8("synched", 0);
			this.instance_23.setTransform(288.95, 225.55);

			this.instance_24 = new lib.Tween9("synched", 0);
			this.instance_24.setTransform(286.8, 224.45, 1.0819, 1, -3.7051);
			this.instance_24._off = true;

			this.shape_14 = new cjs.Shape();
			this.shape_14.graphics.f("#CC7E2F").s().p("AgdWrQmNgslcjaQlnjjjWlmQCiAZDtgyQDrgxDchmQDqhtCLiGQCaiWgBiWQABkxAPkbQAQj7AChTQADh+gXhTQgYhVhJh/IgCgCQCjB3BTDdQAmBkAbC3QATB6AZD6QA9JAA1DsQBjGvC/CIQDtCoGUj6QjvFil7CgQkQBzkuAAQhcAAhdgKg");
			this.shape_14.setTransform(288.95, 225.5706);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_23}]}).to({state: [{t: this.instance_24}]}, 3).to({state: [{t: this.instance_24}]}, 10).to({state: [{t: this.instance_23}]}, 3).to({state: [{t: this.shape_14}]}, 12).to({state: [{t: this.shape_14}]}, 2).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_23).to({
				_off: true,
				scaleX: 1.0819,
				rotation: -3.7051,
				x: 286.8,
				y: 224.45
			}, 3, cjs.Ease.quadIn).wait(10).to({
				_off: false,
				scaleX: 1,
				rotation: 0,
				x: 288.95,
				y: 225.55
			}, 3).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_24).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				_off: true,
				scaleX: 1,
				rotation: 0,
				x: 288.95,
				y: 225.55
			}, 3).wait(15));

			// Ebene_56
			this.instance_25 = new lib.Tween10("synched", 0);
			this.instance_25.setTransform(209.85, 163.95);

			this.instance_26 = new lib.Tween11("synched", 0);
			this.instance_26.setTransform(200.75, 168.3, 1.0819, 1, -3.7051, 0, 0, 0.1, 0);
			this.instance_26._off = true;

			this.shape_15 = new cjs.Shape();
			this.shape_15.graphics.f("#D99333").s().p("AlCTkQiJgLhhgUQi5k2gvlwQg0mPBzm1QgbgngehAQg9iAgSh8Qg6mOGKjUQg9DrEVAvQBZAQCCgFQBEgCBUgFQAVAABTgHQBbgGBMAFQDxAQCkB4IABADQBOCDAZBRQAcBWAAB7QAABIgREFQgUE6gJERQgLFVjSC/QiqCakjAyQiHAXiYAAQhWAAhbgHg");
			this.shape_15.setTransform(209.8839, 163.9436);

			this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_25}]}).to({state: [{t: this.instance_26}]}, 3).to({state: [{t: this.instance_26}]}, 10).to({state: [{t: this.instance_26}]}, 1).to({state: [{t: this.instance_26}]}, 1).to({state: [{t: this.instance_25}]}, 1).to({state: [{t: this.shape_15}]}, 12).to({state: [{t: this.shape_15}]}, 2).wait(1));
			this.timeline.addTween(cjs.Tween.get(this.instance_25).to({
				_off: true,
				regX: 0.1,
				scaleX: 1.0819,
				rotation: -3.7051,
				x: 200.75,
				y: 168.3
			}, 3, cjs.Ease.quadIn).wait(12).to({
				_off: false,
				regX: 0,
				scaleX: 1,
				rotation: 0,
				x: 209.85,
				y: 163.95
			}, 1).to({_off: true}, 12).wait(3));
			this.timeline.addTween(cjs.Tween.get(this.instance_26).to({_off: false}, 3, cjs.Ease.quadIn).wait(10).to({startPosition: 0}, 0).to({
				regY: 0.1,
				scaleX: 1.0546,
				rotation: -2.4706,
				x: 207.1,
				y: 168.75
			}, 1).to({scaleX: 1.0273, rotation: -1.2363, x: 209.35, y: 166.4}, 1).to({
				_off: true,
				regX: 0,
				regY: 0,
				scaleX: 1,
				rotation: 0,
				x: 209.85,
				y: 163.95
			}, 1).wait(15));

			this._renderFirstFrame();

		}).prototype = p = new lib.AnMovieClip();
		p.nominalBounds = new cjs.Rectangle(355.4, 288, 76.90000000000003, 103.10000000000002);
// library properties:
		lib.properties = {
			id: '69C1454A17365D478E6E3E6943D12530',
			width: 500,
			height: 500,
			fps: 30,
			color: "#FFFFFF",
			opacity: 0.00,
			manifest: [],
			preloads: []
		};


// bootstrap callback support:

		(lib.Stage = function (canvas) {
			createjs.Stage.call(this, canvas);
		}).prototype = p = new createjs.Stage();

		p.setAutoPlay = function (autoPlay) {
			this.tickEnabled = autoPlay;
		}
		p.play = function () {
			this.tickEnabled = true;
			this.getChildAt(0).gotoAndPlay(this.getTimelinePosition())
		}
		p.stop = function (ms) {
			if (ms) this.seek(ms);
			this.tickEnabled = false;
		}
		p.seek = function (ms) {
			this.tickEnabled = true;
			this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000);
		}
		p.getDuration = function () {
			return this.getChildAt(0).totalFrames / lib.properties.fps * 1000;
		}

		p.getTimelinePosition = function () {
			return this.getChildAt(0).currentFrame / lib.properties.fps * 1000;
		}

		an.bootcompsLoaded = an.bootcompsLoaded || [];
		if (!an.bootstrapListeners) {
			an.bootstrapListeners = [];
		}

		an.bootstrapCallback = function (fnCallback) {
			an.bootstrapListeners.push(fnCallback);
			if (an.bootcompsLoaded.length > 0) {
				for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
					fnCallback(an.bootcompsLoaded[i]);
				}
			}
		};

		an.compositions = an.compositions || {};
		an.compositions['69C1454A17365D478E6E3E6943D12530'] = {
			getStage: function () {
				return exportRoot.stage;
			},
			getLibrary: function () {
				return lib;
			},
			getSpriteSheet: function () {
				return ss;
			},
			getImages: function () {
				return img;
			}
		};

		an.compositionLoaded = function (id) {
			an.bootcompsLoaded.push(id);
			for (var j = 0; j < an.bootstrapListeners.length; j++) {
				an.bootstrapListeners[j](id);
			}
		}

		an.getComposition = function (id) {
			return an.compositions[id];
		}


		an.makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
			var lastW, lastH, lastS = 0.25;
			window.addEventListener('resize', resizeCanvas);
			resizeCanvas();

			function resizeCanvas() {
				var w = lib.properties.width, h = lib.properties.height;
				var iw = window.innerWidth, ih = window.innerHeight;
				var pRatio = window.devicePixelRatio || 0.25, xRatio = iw / w, yRatio = ih / h, sRatio = 0.25;
				if (isResp) {
					if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
						sRatio = lastS;
					} else if (!isScale) {
						if (iw < w || ih < h)
							sRatio = Math.min(xRatio, yRatio);
					} else if (scaleType == 1) {
						sRatio = Math.min(xRatio, yRatio);
					} else if (scaleType == 2) {
						sRatio = Math.max(xRatio, yRatio);
					}
				}
				domContainers[0].width = w * pRatio * sRatio;
				domContainers[0].height = h * pRatio * sRatio;
				domContainers.forEach(function (container) {
					container.style.width = w * sRatio + 'px';
					container.style.height = h * sRatio + 'px';
				});
				stage.scaleX = pRatio * sRatio;
				stage.scaleY = pRatio * sRatio;
				lastW = iw;
				lastH = ih;
				lastS = sRatio;
				stage.tickOnUpdate = false;
				stage.update();
				stage.tickOnUpdate = true;
			}
		}
		an.handleSoundStreamOnTick = function (event) {
			if (!event.paused) {
				var stageChild = stage.getChildAt(0);
				if (!stageChild.paused || stageChild.ignorePause) {
					stageChild.syncStreamSounds();
				}
			}
		}
		an.handleFilterCache = function (event) {
			if (!event.paused) {
				var target = event.target;
				if (target) {
					if (target.filterCacheList) {
						for (var index = 0; index < target.filterCacheList.length; index++) {
							var cacheInst = target.filterCacheList[index];
							if ((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)) {
								cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
							}
						}
					}
				}
			}
		}


	})(createjs = window.createjs || {}, AdobeAn = window.AdobeAn || {});
	var createjs, AdobeAn;
	var stage, exportRoot;
	this.init = function() {
		
		canvas = document.getElementById("canvas");
		anim_container = document.getElementById("animation_container");
		dom_overlay_container = document.getElementById("dom_overlay_container");
		var comp=AdobeAn.getComposition("69C1454A17365D478E6E3E6943D12530");
		var lib=comp.getLibrary();
		this.handleComplete({},comp);
	}
	this.handleComplete = function(evt,comp) {
		
		//This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
		var lib = comp.getLibrary();
		var ss = comp.getSpriteSheet();
		exportRoot = new lib.WinkAnimation();
		stage = new lib.Stage(canvas);
		//Registers the "tick" event listener.
		this.fnStartAnimation = function () {
			stage.addChild(exportRoot);
			createjs.Ticker.framerate = lib.properties.fps;
			createjs.Ticker.addEventListener("tick", stage);
		}
		//Code to support hidpi screens and responsive scaling.
		AdobeAn.makeResponsive(false, 'both', false, 1, [canvas, anim_container, dom_overlay_container]);
		AdobeAn.compositionLoaded(lib.properties.id);
		this.fnStartAnimation();
	}
	this.play = function() {
		var comp=AdobeAn.getComposition("69C1454A17365D478E6E3E6943D12530");
		var lib = comp.getLibrary();
		var ss = comp.getSpriteSheet();
		exportRoot = new lib.WinkAnimation();
		//Registers the "tick" event listener.
		this.fnStartAnimation = function () {
			stage.addChild(exportRoot);
			createjs.Ticker.framerate = lib.properties.fps;
			createjs.Ticker.addEventListener("tick", stage);
		}
		//Code to support hidpi screens and responsive scaling.
		AdobeAn.makeResponsive(false, 'both', false, 1, [canvas, anim_container, dom_overlay_container]);
		AdobeAn.compositionLoaded(lib.properties.id);
		this.fnStartAnimation();
	}
}