<img *ngIf="origin === 'Campusjaeger'" src="../../../../assets/images/partnerLogos/workwise-logo.png" style="max-height: 35px;">
<div *ngIf="origin === 'Pluss'" fxLayout="row" fxLayoutAlign="start center">
    <img  src="../../../../assets/images/partnerLogos/pluss.png">
    <span style="    color: #4b5860;">Pluss</span>
</div>
<img *ngIf="origin === 'Absolventa'" src="../../../../assets/images/partnerLogos/absolventa.png">
<img *ngIf="origin === 'Joblift'" src="../../../../assets/images/partnerLogos/joblift.png">
<img *ngIf="origin === 'Jobware'" src="../../../../assets/images/partnerLogos/jobware.svg">
<img *ngIf="origin === 'Stellenanzeigen'" src="../../../../assets/images/partnerLogos/stellenanzeigenDE.png" style="max-height: 25px">
<img *ngIf="origin === 'Experteer'" src="../../../../assets/images/partnerLogos/experteer.gif" style="max-height: 25px">
<img *ngIf="origin === 'JobFeed'" src="../../../../assets/images/partnerLogos/jobfeed.jpg" style="max-height: 25px">
