import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-s2-fax2-o-button-bar',
  templateUrl: './s2-fax2-o-button-bar.component.html',
  styleUrls: ['./s2-fax2-o-button-bar.component.scss']
})
export class S2Fax2OButtonBarComponent implements OnInit {

    @Input()
    padding: string = "0 24px"
    @Input()
    backLabel: string = "Zurück"
    @Input()
    skipLabel: string = "Überspringen"
    @Input()
    nextLabel: string = "Weiter"

    @Input()
    smallActionButton = false

    @Input()
    backButton = true;
    @Input()
    skipButton = false;

    @Input()
    skipCoachButton = false

    @Input()
    buttonLockSubject: Subject<any>;

    @Input()
    skipButtonLockSubject: Subject<boolean>;

    @Input()
    validSubject : Subject<boolean>

    @Output()
    pressedSkip = new EventEmitter();

    @Output()
    pressedNext = new EventEmitter()

    buttonsLocked = false;
    skipLock = false;

    @Input()
    valid = true;

    constructor() { }

    ngOnInit(): void {
        if(this.buttonLockSubject){
            this.buttonLockSubject.subscribe(() => {
                this.buttonsLocked = true;
            })
        }

        if(this.skipButtonLockSubject){
            this.skipButtonLockSubject.subscribe(next => {
                this.skipLock = next;
            })
        }

        if(this.validSubject){
            this.valid = false;
            this.validSubject.subscribe(isValid =>{
                this.valid = isValid
            })
        }
    }

    back(){
        history.back()
    }
}
