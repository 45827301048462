<div class="dialog-container" id="dialog-content">

    <div [ngClass]="{'small-header': scrollPosition > 50}" class="header dialog-title" fxLayout="column"
         fxLayoutAlign="center center" mat-dialog-title>
        <div class="headline font-size-16 width-100" fxLayout="row" fxLayoutAlign="space-between center"
             fxLayoutGap="15px">
            <div></div>
            <div fxLayout="column" fxLayoutAlign="start start" class="header-text">
                <!--div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="step-info">
                    <div><b>Bewerben</b></div>
                    <div> - </div>
                    <div>Schritt {{stepper.selectedIndex + 1}} / {{stepperLength}}</div>
                </div-->

                <div [ngClass]="{'hide-text': scrollPosition > 50}" fxLayout="row" fxLayoutAlign="center center"
                     fxLayoutGap="15px" class="position-info">
                    <div style="height: 40px; width: 40px; overflow: hidden; border-radius: 5px;">
                        <company-picture class="company-logo"
                                         [apiPositionLogoUrl]="data?.companyLogo" [positionId]="data?.positionId">
                        </company-picture>
                    </div>

                    <div><b>{{data.companyName}}</b></div>
                </div>
                <div class="position-title">{{data.positionTitle}}</div>
                <div *ngIf="data.positionLocations.length < 5" [ngClass]="{'hide-text': scrollPosition > 50}"
                     fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="position-info">
                    <div *ngFor="let location of data.positionLocations">{{location.name}}</div>
                </div>
                <div *ngIf="data.positionLocations.length > 4" [ngClass]="{'hide-text': scrollPosition > 50}"
                     fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="position-info">
                    <div>{{data.positionLocations[0].name}} + {{data.positionLocations.length - 1}} weitere Standorte
                    </div>
                </div>
            </div>
            <i class="mdi mdi-close" (click)="onClose(applicationSend)"></i>
        </div>
    </div>

    <div mat-dialog-content class="dialog-content top-spacer" [ngClass]="{'ios-content': mediaService.isIOs()}">
        <loading-spinner [loadingVar]="loadingCv || loadingWorksamples">
            <div class="content width-100" fxLayout="column">
                <div class="cv-content center-content width-100">
                    <div class="dialog-content-wrapper">
                        <div
                                fxLayout="column" fxLayoutAlign="start start">
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                                <h3>Dein Lebenslauf / CV</h3>
                                <i *ngIf="cvAlreadyAvailable || cvFileControl.valid"
                                   class="mdi mdi-check-circle-outline"></i>
                                <i *ngIf="!cvAlreadyAvailable && !cvFileControl.valid"
                                   class="mdi mdi-alert-circle-outline"></i>
                            </div>

                            <div *ngIf="!cvAlreadyAvailable">
                                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                                    <div *ngIf="!cvFileControl.valid || fileIsWordDocument">
                                        Es ist noch kein Lebenslauf vorhanden!
                                    </div>

                                    <div fxLayout="column" fxLayoutAlign="start start" fxLayout.lt-sm="column"
                                         fxLayoutGap="10px">
                                        <input type="file" [accept]="whiteListedExtensionsStr" (change)="set($event)"
                                               [formControl]="cvFileControl"
                                               id="fileInput-apply"
                                               class="inputfile ita_upload_cv_welcome_file">
                                        <label for="fileInput-apply"
                                               class="ita_application_dialog ita_upload_cv upload-button">
                                            {{getFileName() ? (getFileName() | slice:0:20) : 'Lebenslauf hochladen'}}
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="!cvFileControl?.value" fxLayout="row" fxLayoutAlign="start start"
                                     fxLayoutGap="5px"
                                     class="attachments-info-wrapper">
                                    <i class="mdi mdi-information-outline font-size-16"></i>
                                    <div class="attachments-info">Du kannst deinen CV auch nachträglich hochladen, dann
                                        wird deine Bewerbung ersteinmal als Entwurf gespeichert.
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="cvAlreadyAvailable">

                                <div *ngIf="loadingPdf" class="width-100 center-content">
                                    <div id="loading"></div>
                                </div>

                                <iframe *ngIf="showPdf && !loadingPdf" toolbar="0" id="pdf-preview" [src]="pdfUrl"
                                        type="application/pdf" width="800" height="350px"
                                        class="hide-on-mobile pdf-teaser">
                                </iframe>

                                <div class="width-100 m-bottom15" fxLayout="row wrap"
                                     fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div>Dieser CV wird mitgeschickt:</div>
                                    <div *ngIf="!cvFileControl.value"
                                         [matTooltip]="cvInfo.originalFileName"
                                         class="bold">{{cvInfo.originalFileName | sliceStringLength:30}}</div>
                                    <div *ngIf="cvFileControl.value"
                                         [matTooltip]="getFileName()"
                                         class="bold">{{getFileName() | sliceStringLength:30}}</div>

                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <input type="file" [accept]="whiteListedExtensionsStr" (change)="set($event)"
                                               [formControl]="cvFileControl"
                                               id="file"
                                               #inputFileCv
                                               class="inputfile ita_upload_cv_welcome_file">
                                        <label for="file" class="ita_upload_cv_welcome_file">
                                            <button (click)="inputFileCv.click()" class="ita_upload_cv_welcome_file"
                                                    mat-icon-button matTooltip="einen anderen Lebenslauf auswählen">
                                                <i class="mdi mdi-pencil"></i>
                                            </button>
                                        </label>

                                        <button (click)="dialogOpenService.openWorksamplePreviewDialog(cvInfo, cvInfo.id, 'Dein Lebenslauf')"
                                                class="ita_upload_cv_welcome_file hide-on-desktop" mat-icon-button
                                                matTooltip="einen anderen Lebenslauf auswählen">
                                            <i style="color: #777777" class="mdi mdi-eye"></i>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div fxLayout="column" fxLayoutAlign="start start" class="m-top10">

                            <sub style="color: red;" *ngIf="fileIsWordDocument">
                                Bei der angegebenen Datei handelt es sich um ein Worddokument.<br/>
                                Bitte konvertiere dieses zu einem PDF-Dokument und lade es dann hoch (<a
                                    href="https://praxistipps.chip.de/word-dokumente-in-pdf-umwandeln_3039"
                                    target="_blank">Anleitung</a>)!
                            </sub>

                            <small style="color: red"
                                   *ngIf="cvFileControl.errors != null && cvFileControl.errors['sizeTooBig']">Die Datei
                                ist
                                größer
                                als
                                50MB,
                                Bitte wähle eine kleinere Datei
                            </small>
                            <small style="color: red"
                                   *ngIf="cvFileControl.errors != null && cvFileControl.errors['wrongExtension']">
                                Falsches Format. Bitte wähle ein PDF-Dokument oder ein Bild.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="attachments width-100 center-content" [formGroup]="attachments" *ngIf="position?.employmentPositionOrigin != 'Campusjaeger'">
                    <div class="dialog-content-wrapper">


                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <h3>Deine Anhänge <span *ngIf="!position?.applicationRequirementsInfo">(optional)</span>
                            </h3>
                            <i *ngIf="(position?.applicationRequirementsInfo && position?.applicationRequirementsInfo != '') && getLengthOfAttachments() < 2"
                               class="mdi mdi-alert-circle-outline"></i>
                            <i *ngIf="!((position?.applicationRequirementsInfo && position?.applicationRequirementsInfo != '') && getLengthOfAttachments() < 2)"
                               class="mdi mdi-check-circle-outline"></i>
                        </div>

                        <div class="attachments-info m-bottom10">Alle Dokumente aus deinem Profil werden automatisch
                            mitgeschickt. Optional kannst du zusätzliche Anhänge für diese Bewerbung hinzufügen.
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px"
                             class="m-top15 m-bottom15"
                             *ngIf="position?.applicationRequirementsInfo && position?.applicationRequirementsInfo != '' && getLengthOfAttachments() < 2">
                            <i style="color: red" class="mdi mdi-information"></i>
                            <div>{{position.applicationRequirementsInfo}}</div>
                        </div>

                        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <div *ngFor="let worksample of worksamples" fxLayout="row" fxLayoutAlign="center center"
                                 class="old-uploaded-attachements">
                                <i class="mdi mdi-file font-size-20"></i>
                                <div>{{worksample.uploadedFileName | sliceStringLength: 20}}</div>
                            </div>
                        </div>

                        <div *ngFor="let uploadBox of (attachments.controls | keyvalue); index as i" fxLayout="row"
                             fxLayoutAlign="space-between center" class="uploaded-attachements">

                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <i *ngIf="uploadBox?.value?.value" class="mdi mdi-file font-size-20"></i>
                                    <div [matTooltip]="uploadBox?.value?.value[0].name" #tooltip="matTooltip"
                                         (click)="tooltip.toggle()"
                                         *ngIf="uploadBox?.value?.value">{{uploadBox?.value?.value[0].name | sliceStringLength: 20}} </div>
                                </div>

                                <input type="file" [formControlName]="uploadBox.key" [id]="uploadBox.key"
                                       (change)="addAttachment()"
                                       class="inputfile">
                                <label *ngIf="!uploadBox?.value.value" [for]="uploadBox.key" fxLayout="row"
                                       fxLayoutAlign="start center" style="height: 38px">
                                    <i class="mdi mdi-plus m-right10"></i>
                                    <span>Anhang hinzufügen</span>
                                </label>
                            </div>

                            <button *ngIf="uploadBox?.value.value" mat-button matTooltip="Anhang entfernen"
                                    (click)="removeAttachment(uploadBox.key)">
                                <i class="mdi mdi-close" style="color: #ffffff"></i></button>
                        </div>

                    </div>
                </div>

                <div *ngIf="attachmentsToBig" class="warn m-top10">So können wir deine Bewerbung nicht abschicken!
                    Deine Anhänge sind zusammen größer als 10MB!
                </div>

            </div>
        </loading-spinner>

        <div class="content question-section center-content" fxLayout="column" fxLayoutGap="15px"
             [formGroup]="positionAnswerForm" *ngIf="positionQuestions?.length > 0">
            <div class="dialog-content-wrapper" *ngIf="formGenerated">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <h3>{{position?.company?.name}} hat noch ein paar Fragen an dich</h3>
                    <i *ngIf="!questionsCompleted()" class="mdi mdi-alert-circle-outline"></i>
                    <i *ngIf="questionsCompleted()" class="mdi mdi-check-circle-outline"></i>
                </div>

                <div class="tool-question-headline" *ngIf="containsToolQuestions">Hast du schon Erfahrung mit folgenden Tools bzw. in folgenden Bereichen gesammelt?</div>

                <div *ngFor="let question of positionQuestions ; let i = index">
                    <search-dropdown *ngIf="question.type == 'PositionQuestionSelect' && positionAnswerForm.get(question.id.toString()).hasValidator(Validators.required)"
                                     [multiple]="question.multiple"
                                     [control]="positionAnswerForm.get(question.id.toString())"
                                     [options]="question.options">
                        <div class="m-bottom5">{{question.question}}</div>
                    </search-dropdown>


                    <text-line-question-input
                            *ngIf="question.type == 'PositionQuestionInputLine' && positionAnswerForm.get(question.id.toString()).hasValidator(Validators.required)"
                            [control]="positionAnswerForm.get(question.id.toString())"
                            [format]="question.format"
                            [min]="question.minNumber"
                            [max]="question.maxNumber"
                            [characterLimit]="question.characterLimit">
                        <div class="m-bottom5">{{question.question}}</div>
                    </text-line-question-input>

                    <text-question-input
                            *ngIf="question.type == 'PositionQuestionTextarea' && positionAnswerForm.get(question.id.toString()).hasValidator(Validators.required)"
                            [control]="positionAnswerForm.get(question.id.toString())"
                            [characterLimit]="question.characterLimit">
                        <div class="m-bottom5">{{question.question}}</div>
                    </text-question-input>

                    <date-question
                            *ngIf="question.type == 'PositionQuestionDate' && positionAnswerForm.get(question.id.toString()).hasValidator(Validators.required)"
                            [control]="positionAnswerForm.get(question.id.toString())"
                            [min]="question.minDate"
                            [format]="question.format"
                            [max]="question.maxDate">
                        <div class="m-bottom5">{{question.question}}</div>
                    </date-question>
                </div>


<!--                privacy disclaimer workwise-->
                <div *ngIf="position?.employmentPositionOrigin == 'Campusjaeger'" class="workwise-privacy">
                    Diese Stelle wird über unseren Partner Workwise angeboten.
                    Mit Abschicken der Bewerbung wird diese an Workwise übermittelt und du erhältst dort ein Konto zur weiteren Verwaltung deiner Bewerbung. Du akzeptierst hiermit die <a href="https://www.workwise.io/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> von Workwise und die <a href="https://www.workwise.io/datenschutz" target="_blank">Datenschutzerklärung</a> von Workwise. Weiteres findest du in unserer <a [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
                </div>
            </div>
        </div>

        <div class="dialog-content-wrapper">
            <mat-divider *ngIf="positionQuestions.length > 0"></mat-divider>
            <div style="margin: 20px 0px 120px 0px">
                <div fxLayoutAlign="center center" fxLayout="row" *ngIf="!(position?.employmentPositionOrigin == 'Campusjaeger')">
                    <i class="mdi mdi-information-outline font-size-16 attachments-info m-right10"></i>
                    <div class="attachments-info">Wir reviewen deine Bewerbung bevor wir sie an das Unternehmen
                        schicken. Falls etwas nicht passt, bekommst du nochmal Bescheid.
                    </div>
                </div>

                <div *ngIf="positionPrivacyDisclaimer && positionPrivacyDisclaimer.showDisclaimer && !applicationSend && !loadingAccept"
                     class="privacy-agreement m-bottom40"
                     fxLayout="column"
                     fxLayoutAlign="start start"
                     fxLayoutGap="10px">
                    <info-text>
                        Diese Stelle wird über einen Vermittlungspartner angeboten. Hierfür gelten zusätzliche
                        Datenschutzhinweise.
                    </info-text>

                        <mat-checkbox [formControl]="acceptPrivacyControl">
                            <div [ngClass]="{'error-color': (!acceptPrivacyControl.valid && acceptPrivacyControl.touched)}">
                                <div style="font-size: 12px;">
                                    Mit Abschicken der Bewerbung willigen Sie ein, dass Ihre Daten sowohl dem Vermittlungspartner
                                    als auch dem zur Stellenanzeige gehörenden Unternehmen zur Verfügung gestellt werden.
                                    Bitte beachten Sie daher auch die <a class="link" [href]="positionPrivacyDisclaimer.partnerPrivacyUrl" target="_blank"
                                                                         style="font-size: 12px">
                                    Datenschutzhinweise
                                </a> unseres Vermittlungspartners.
                                </div>
                            </div>
                        </mat-checkbox>
                    </div>

                    <div fxLayoutAlign="center center" fxLayout="row" *ngIf="this.position?.homeOffice !== 'OnlyRemote'" class="m-top20">
                        <i class="mdi mdi-information-outline font-size-16 attachments-info m-right10"></i>
                        <div class="attachments-info">
                            {{ getFormattedLocations() }}
                        </div>
                    </div>
                </div>
            </div>

    </div>


    <div mat-dialog-actions class="dialog-actions" *ngIf="!applicationSend">
        <div *ngIf="showMissingQuestionsError" class="error">
            Du musst erst alle Fragen beantworten, bevor du die Bewerbung abschicken kannst!
        </div>
        <div class="width-100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button
                    *ngIf="!loadingAccept"
                    [ngClass]="positionTrackingClass != 'ita_positionId-' ? positionTrackingClass : ''"
                    [disabled]="((position?.applicationRequirementsInfo && position?.applicationRequirementsInfo != '') && getLengthOfAttachments() < 2 ) || loadingAccept || acceptPrivacyControl.value == false || !isValidCvFile() || attachmentsToBig || (positionQuestions.length > 0 && !questionsCompleted())"
                    class="white a_preview-modal ita_application_sent ita_positionType_internal main-cta-button secondary-button"
                    (click)="onAccept()">
                <span style="font-size: 16px;">Bewerbung abschicken</span>
            </button>
            <button
                    *ngIf="loadingAccept"
                    [disabled]="true"
                    mat-flat-button
                    class="main-cta-button "
                    style="color: white !important;"
            >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="sk-chase">
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                    </div>
                    <span style="font-size: 16px;">

                    Bewerbung wird abgeschickt...</span>
                </div>
            </button>
        </div>

    </div>
</div>
