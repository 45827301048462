<div class="wrapper" #wrapper>
    <hid-input-field [control]="searchControl"
                     [label]="label"
                     [showValidatorIcons]="false"
                     [autocomplete]="'off'"
                     (inputFocused)="toggleMatMenuIfNecessary()"
    >
    </hid-input-field>

    <div [fxHide]="!showSmallLoadingSpinner" fxLayoutAlign="end center">
        <i class="input-icon validator-icon fas fa-spinner"></i>
    </div>
    <div style="width: 100%; position: relative; bottom: 4px; opacity: 0;" [matMenuTriggerFor]="menuFields" #menuTriggerFields="matMenuTrigger"></div>
</div>

<mat-menu #menuFields="matMenu" yPosition="below" class="location-mat-menu" autofocus>
    <div [ngStyle]="{'width': wrapper.clientWidth + 'px'}" class="option" [ngClass]="{'selected': isSelected(option)}" fxLayout="row" fxLayoutAlign="start center" mat-menu-item
         *ngFor="let option of options" (click)="setModel(option, $event)">
        <span>{{option[this.displayProperty]}}</span>
    </div>
</mat-menu>

