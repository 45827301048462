import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {CoachingTypeData, CoachingTypeResource, TalentOnboardingResource} from "utility";
import {environment} from "../../../../environments/environment";
import {Subject, Subscription} from "rxjs";
import {CachedOBInputs, S2FaxOnboardingService} from "../s2-fax-onboarding.service";

@Component({
  selector: 'app-s2-fax-o-coach',
  templateUrl: './s2-fax-o-coach.component.html',
  styleUrls: ['./s2-fax-o-coach.component.scss']
})
export class S2FaxOCoachComponent implements OnInit, OnDestroy {


    readonly coachingsForm = new FormGroup({}, this.comparisonValidator());
    $skipLockSubject: Subscription
    public availableCoachingTypes: CoachingTypeData[] = [
        {
            id: 5074691,
            title: "Persönlichkeitsanalyse",
            description: "Finde heraus, welcher Job zu dir passt"
        },
        {
            id: 5074690,
            title: "Lebenslauf-Check",
            description: "Ein Experte optimiert deinen Lebenslauf"
        },
        {
            id: 5074692,
            title: "Gehaltsverhandlungs-Coaching",
            description: "So holst du 3.000€ mehr Gehalt raus"
        },
        // {
        //     id: 5074689,
        //     title: "Hilfe im Bewerbungsprozess",
        //     description: "Ein HR-Experte beantwortet deine individuellen Fragen"
        // },
        // {
        //     id: 5074693,
        //     title: "Finanz-Coaching zum Jobeinstieg",
        //     description: "Lerne, wie du deine Finanzen richtig organisierst"
        // }
    ];

    public selectedCoachingTypes: CoachingTypeData[];
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public progessingSubject = new Subject()
    public skipLockSubject = new Subject()

    constructor(
        private coachingTypeResource: CoachingTypeResource,
        private onboardingService: S2FaxOnboardingService,
        private onboardingResource: TalentOnboardingResource,
    ) {
    }

    ngOnInit() {
        this.availableCoachingTypes.forEach(coaching => {
            this.coachingsForm.addControl(coaching.id.toString(), new FormControl(false));
        });

        this.coachingsForm.valueChanges.subscribe(next => {
                if ( Object.keys(this.coachingsForm.controls).some(controlName => this.coachingsForm.controls[controlName].value)) {
                    this.skipLockSubject.next(true)
                } else {
                    this.skipLockSubject.next(false)
                }
            }
        )

        this.coachingTypeResource.getSelectedCoachingTypes().then(coachings => {
            this.selectedCoachingTypes = this.availableCoachingTypes.filter(c=>coachings.map(co=>co.id).includes(c.id))

            this.selectedCoachingTypes.forEach(coaching => {
                this.coachingsForm.controls[coaching.id.toString()].setValue(true)
            })
        })

    }

    comparisonValidator() : ValidatorFn{
        return (group: FormGroup): ValidationErrors => {
            let validStates: boolean[] = []
            for (const key in group.controls) { validStates.push(group.controls[key].value);}

            if (validStates.includes(true)) {
                return null;
            } else {
                return {noCoachingSelected: true};
            }
        };
    }

    ngOnDestroy() {
        this.$skipLockSubject?.unsubscribe()
    }

    progress(wantsCoachValue: boolean) {

        this.onboardingService.updateInputs(<CachedOBInputs>{
            wantsCoach: wantsCoachValue
        })

        let choosenCoachingIds: number[] = [];

        let context = 'next';
        if(!wantsCoachValue){
            context = 'skip'
        }

        if(wantsCoachValue) {
            this.coachingsForm.updateValueAndValidity()
            if (this.coachingsForm.invalid) {
                this.coachingsForm.markAsTouched()
                return
            }
            this.progessingSubject.next()

            Object.keys(this.coachingsForm.controls).forEach(controlName => {
                if (this.coachingsForm.controls[controlName].value == true) {
                    choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.id.toString() == controlName)[0].id);
                }
            });

            this.onboardingResource.requestCoach(choosenCoachingIds).then(() => {
                this.onboardingService.delayedRouting("/onboarding/s2fax/coach-data", context)
            });
        } else{
            this.progessingSubject.next()

            this.onboardingService.delayedRouting("/onboarding/s2fax/password", context)
        }
    }

}
