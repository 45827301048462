<div class="home-c-w" routerLink="/talent/applications">
    <home-headline icon="file-outline">Bewerbungen ({{applications?.length}})</home-headline>
    <div class="home-c-c-w">
        <div>
            <div class="application" *ngFor="let a of applications">
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <div class="app-point"></div>
                    <div class="title">{{a.positionTitle | sliceStringLength:40}}</div>
                </div>

                <div class="state">{{a.state | applicationState}}</div>
            </div>
            <div class="nothing-found" *ngIf="!applications.length && !loadingSubject?.value">
                <i style="font-size: 50px" class="mdi mdi-mailbox-open-up"></i>
                <div>Im Durchschnitt finden unsere Talente nach 6-8 Bewerbungen ihren Traumjob</div>

                <button style="border-radius: 50px; margin-top: 16px" class="position" mat-flat-button color="primary" (click)="router.navigateByUrl('/talent/positions')">Zu den Stellen</button>
            </div>
        </div>


        <div class="more" *ngIf="applications.length">zu deinen Bewerbungen</div>
    </div>
</div>
