import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetContainer, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Router} from "@angular/router";
import {BottomSheetService} from "../bottom-sheet.service";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-slideable-bottom-sheet',
  templateUrl: './slideable-bottom-sheet.component.html',
  styleUrls: ['./slideable-bottom-sheet.component.scss']
})
export class SlideableBottomSheetComponent implements OnInit, OnDestroy {

  @ViewChild(MatBottomSheetContainer) bottomSheetContainer: MatBottomSheetContainer;
  @ViewChild('scrollableContent', { static: true }) scrollableContent: ElementRef<HTMLDivElement>;

  bottomSheetContainerElement: HTMLElement;
  dialogMaxed: boolean = false;
  initialTouchY: number;
  currentTouchY: number;
  initialTouchTime: number;
  currentTouchTime: number;
  dragging: boolean = false;
  showCloseIndicator: boolean = false;

  onPopStateBind = this.onPopState.bind(this)
  expansionEnabled: boolean = true

  constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private viewContainerRef: ViewContainerRef,
      private _bottomSheetRef: MatBottomSheetRef<SlideableBottomSheetComponent>,
      public bottomSheetService: BottomSheetService,
      private location: PlatformLocation,
      private router: Router) {}

  ngOnInit(): void {
    history.pushState(null, '', '/talent/positions');
    if (this.data && this.data.component) {
      const componentRef = this.viewContainerRef.createComponent(this.data.component);
      if (this.data.componentInputs) {
        Object.keys(this.data.componentInputs).forEach(key => {
          componentRef.instance[key] = this.data.componentInputs[key];
          if (key === 'expansionEnabled') this.expansionEnabled = componentRef.instance['expansionEnabled']
        });
      }
    }
    this.location.onPopState(() => {
      this.replaceBackUrl();
    });

    window.addEventListener('popstate', this.onPopStateBind);

    /*this.bottomSheetService.pauseScrollAndResize.subscribe((pause: boolean) => {
      this.pauseScrollAndResize = pause;
    });*/

  }

  ngOnDestroy() {
    window.removeEventListener('popstate', this.onPopStateBind);
  }

  private onPopState(event: PopStateEvent) {
    console.log('popstate event triggered', event);
    this.clearHistoryAndNavigate();
  }

  private clearHistoryAndNavigate() {
    history.pushState(null, '', '/talent/positions');
    window.location.replace('/talent/positions');
  }

  private replaceBackUrl() {
    window.location.replace('/talent/positions');
    history.forward();
  }

  ngAfterViewInit(): void {
    this.bottomSheetContainerElement = document.getElementsByClassName('mat-bottom-sheet-container')[0] as HTMLElement;
    this.bottomSheetContainerElement.style.maxHeight = '90dvh';
    this.bottomSheetContainerElement.style.borderRadius = '2rem 2rem 0 0';
    this.bottomSheetContainerElement.style.overflowY = 'auto';
    this.bottomSheetContainerElement.style.overflowX = 'clip';
    this.bottomSheetContainerElement.style.minHeight = '90dvh';
    this.bottomSheetContainerElement.style.padding = '0.5rem 0 0 0';

    this.bottomSheetContainerElement.addEventListener('scroll', this.onBottomSheetScroll.bind(this));
    this.bottomSheetContainerElement.addEventListener('touchstart', this.onTouchStart.bind(this));
    this.bottomSheetContainerElement.addEventListener('touchmove', this.onTouchMove.bind(this));
    this.bottomSheetContainerElement.addEventListener('touchend', this.onTouchEnd.bind(this));

    this.bottomSheetContainerElement.addEventListener('touchmove', this.onTouchMove.bind(this));
    this.bottomSheetContainerElement.addEventListener('touchend', this.onTouchEnd.bind(this));

    if(!this.expansionEnabled){
      this.bottomSheetContainerElement.style.borderRadius = '2rem 2rem 0 0 !important';
    }
  }

  onBottomSheetScroll(event: Event): void {
    const scrollPosition = (event.target as HTMLElement).scrollTop;
    if(this.bottomSheetService.pauseScrollAndResize.value) {
        return;
    }
    window.requestAnimationFrame(() => {
      if (scrollPosition > 100 && !this.dialogMaxed && this.expansionEnabled) {
        this.bottomSheetService.sheetExpanded.next(true)
        this.bottomSheetContainerElement.style.transition = '0.25s ease-in-out';
        this.bottomSheetContainerElement.style.maxHeight = '100dvh';
        if(this.expansionEnabled) this.bottomSheetContainerElement.style.borderRadius = '0';
        this.bottomSheetContainerElement.style.transform = 'translateY(0)'
        this.dialogMaxed = true;
      } else if (this.dialogMaxed && scrollPosition < 50) {
        this.bottomSheetService.sheetExpanded.next(false)
        this.bottomSheetContainerElement.style.transition = '0.25s ease-in-out';
        this.bottomSheetContainerElement.style.maxHeight = '100dvh';
        this.bottomSheetContainerElement.style.transform = 'translateY(10dvh)'
        if(this.expansionEnabled) this.bottomSheetContainerElement.style.borderRadius = '2rem 2rem 0 0';
        this.dialogMaxed = false;
      }
    });
  }

  onTouchStart(event: TouchEvent): void {
    this.initialTouchTime = new Date().getTime();
    if (!this.dialogMaxed) {
      this.bottomSheetService.sheetFullHeight.next(true)
      this.bottomSheetContainerElement.style.maxHeight = '100dvh';
      this.bottomSheetContainerElement.style.minHeight = '100dvh';
      this.bottomSheetContainerElement.style.height = '100dvh';
      this.bottomSheetContainerElement.style.transform = 'translateY(10dvh)'
    }
    if (!this.dialogMaxed && this.bottomSheetContainerElement.scrollTop === 0) {
      this.initialTouchY = event.touches[0].clientY;
      this.dragging = true;
      this.bottomSheetContainerElement.style.transition = 'none'; // Disable transition during drag
    }
  }

  onTouchMove(event: TouchEvent): void {
    if (this.bottomSheetService.pauseScrollAndResize.value) {
      return;
    }
    if (this.dragging) {
      this.currentTouchY = event.touches[0].clientY;
      const dragDistance = this.currentTouchY - this.initialTouchY;
      if (dragDistance > 0) { // dragging down
        event.preventDefault(); // Prevent default behavior (page reload)
        this.bottomSheetContainerElement.style.minHeight = 'inherit';
        this.showCloseIndicator = dragDistance > window.innerHeight * 0.2;
        const newHeight = 100 - (dragDistance / window.innerHeight * 100);
        this.bottomSheetContainerElement.style.height = `${newHeight}dvh`;
      } else {
        this.bottomSheetContainerElement.style.minHeight = '100dvh';
        this.bottomSheetContainerElement.style.transform = 'translateY(10dvh)'
        this.showCloseIndicator = false;
      }
    }
  }

  onTouchEnd(event: TouchEvent): void {
    this.currentTouchTime = new Date().getTime();
    if (this.dragging) {
      this.dragging = false;
      const dragDistance = this.currentTouchY - this.initialTouchY;
      const dragTime = this.currentTouchTime - this.initialTouchTime;
      if (dragDistance > window.innerHeight * 0.2) { // threshold to close the sheet
        this.closeSheet();
        this.bottomSheetContainerElement.style.minHeight = 'inherit';
      } else {
        this.showCloseIndicator = false;
        this.bottomSheetContainerElement.style.minHeight = '100dvh';
        this.bottomSheetContainerElement.style.transform = 'translateY(10dvh)'
        this.bottomSheetContainerElement.style.transition = '0.25s ease-in-out';
        this.bottomSheetContainerElement.style.height = '100dvh';
      }

      // Detect fast flick
      // if ((dragDistance < -10 && dragTime < 200)) {
      //   this.growSheetToFullHeight()
      // }

      if (dragDistance < -10 && this.expansionEnabled) {
        this.growSheetToFullHeight()
      }

      //catch lost expansion because of tiny flick
      // if((event.target as HTMLElement).scrollTop > 200){
      //   this.growSheetToFullHeight()
      // }
    }
  }

  growSheetToFullHeight(){
    if(this.bottomSheetService.pauseScrollAndResize.value) {
      return;
    }
    this.bottomSheetService.sheetExpanded.next(true)
    this.bottomSheetContainerElement.style.transition = '0.25s ease-in-out';
    this.bottomSheetContainerElement.style.transform = 'translateY(0)'
    this.bottomSheetContainerElement.style.maxHeight = '100dvh';
    this.bottomSheetContainerElement.style.height = '100dvh';
    if(this.expansionEnabled) this.bottomSheetContainerElement.style.borderRadius = '0';
    this.dialogMaxed = true;
  }

  closeSheet(): void {
    if (this.bottomSheetContainerElement.style.height == '100dvh') {
      this.bottomSheetContainerElement.style.transition = 'none';
      this.bottomSheetContainerElement.style.transform = 'translateY(0)'
      this.bottomSheetContainerElement.style.minHeight = '90dvh';
      this.bottomSheetContainerElement.style.maxHeight = '90dvh';
      this.bottomSheetContainerElement.style.height = '90dvh';
    }else {
      this.bottomSheetContainerElement.style.transition = 'none';
      this.bottomSheetContainerElement.style.transform = 'translateY(0)'
      let currentHeight = this.bottomSheetContainerElement.style.height.toString().replace('dvh', '') ;
      let newHeight = `calc(${currentHeight}dvh - 10dvh)`;
      this.bottomSheetContainerElement.style.minHeight = newHeight;
      this.bottomSheetContainerElement.style.maxHeight = newHeight;
      this.bottomSheetContainerElement.style.height = newHeight;

    }
    this._bottomSheetRef.dismiss();
  }
}
