<!-- scroll-target is an autofocus hack to simulate an offset the scrollIntoView function -->
<div class="hid-input-container" #wrapper *ngIf="show"
     [ngClass]="{'required':required,'validationForced': validationForced, 'validatorIcons': showValidatorIcons || isPassword, 'hideLabelWhenFilled': hideLabelWhenFilled, 'highlighted': highlighted}">
  <div id="scroll-target" #scrollTarget></div>

  <div class="lock-overlay" *ngIf="lock"></div>

  <input
      #input
      class="hid-input {{inputFieldClass}}"
      (focus)="focus()"
      [placeholder]="placeholder"
      id="{{dynamicId}}"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [type]="type"
      [step]="step"
      [attr.disabled]="disabled ? '' : null"
      (blur)="reportTouch()"
      *ngIf="control && !matAutocomplete"
      [readOnly]="readonly"
      [formControl]="control"
      (change)="updateControl($event)"
      [name]="name"
      (focusout)="focusOut.emit(true)"
      (focusin)="focusIn.emit(true)"
      [ngStyle]="{'height.px': inputHeight}"
      [ngClass]="{'filled': control.value != null && control.value.toString().length > 0}">

    <input
        #input
        class="hid-input {{inputFieldClass}}"
        (focus)="focus()"
        [placeholder]="placeholder"
        id="{{dynamicId}}"
        [matAutocomplete]="matAutocomplete"
        [autocomplete]="autocomplete"
        [pattern]="pattern"
        [type]="type"
        [step]="step"
        [attr.disabled]="disabled ? '' : null"
        (blur)="reportTouch()"
        *ngIf="control && matAutocomplete"
        [readOnly]="readonly"
        [formControl]="control"
        (change)="updateControl($event)"
        [name]="name"
        (focusout)="focusOut.emit(true)"
        (focusin)="focusIn.emit(true)"
        [ngStyle]="{'height.px': inputHeight}"
        [ngClass]="{'filled': control.value != null && control.value.toString().length > 0}">

  <!---->
  <!---->

  <i *ngIf="!showValidatorIcons && showClearButton && control.value && control.value?.toString()?.length > 0" (click)="this.control.setValue('')"
     class="mdi mdi-close clear-icon clickable input-icon"></i>

  <i *ngIf="showValidatorIcons" class="input-icon validator-icon fas fa-spinner"
     [ngClass]="{'show': validationPending.value}"></i>
  <i *ngIf="showValidatorIcons" class="input-icon validator-icon fas fa-minus-circle text-red"
     [ngClass]="{'show': !control.valid && !validationPending.value}"></i>
  <i *ngIf="showValidatorIcons" class="input-icon  validator-icon far fa-check-circle text-green"
     [ngClass]="{'show': control.valid && !validationPending.value}"></i>

  <!-- Validicons are not supported in combination with password mode -->
  <i *ngIf="isPassword && !showValidatorIcons && type === 'password'"(click)="togglePasswordVisibility()" class="input-icon far fa-eye text-grey"
     [ngClass]="{'show': control.valid && !validationPending.value}"></i>
  <i *ngIf="isPassword && !showValidatorIcons && type === 'text'"(click)="togglePasswordVisibility()" class="input-icon far fa-eye-slash text-grey"
     [ngClass]="{'show': control.valid && !validationPending.value}"></i>
  <label *ngIf="label" class="small-label" [ngStyle]="{'top.px': (inputHeight-23)/2 }" [for]="dynamicId" >{{label}}</label>


</div>
