import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {GDPRConsentComponent} from "./gdprconsent/gdprconsent.component";


//TODO in TA why not use a matdialog? this is just copypasta from HID
@Injectable({
  providedIn: 'root'
})
export class GDPRConsentDialogService {

  constructor(private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  openConsentDialog(initOnDetailedView: boolean) {
    const comp = this.resolver
      .resolveComponentFactory(GDPRConsentComponent)
      .create(this.injector);

    comp.instance.detailedView = initOnDetailedView;

    comp.instance.close.subscribe(() => {
      this.close(comp)
    });

    this.appRef.attachView(comp.hostView);

    const dom = (comp.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(dom);

  }

  close(comp: any) {
    comp.instance.initialized = false;

    this.appRef.detachView(comp.hostView);
    comp.destroy()
  }
}
