import {Component, Input, OnInit} from '@angular/core';
import {EditImageDialogComponent, ProfileImageService} from "utility";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NavigationService} from "../../services/navigation.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'talent-profile-picture',
  templateUrl: './talent-profile-picture.component.html',
  styleUrls: ['./talent-profile-picture.component.scss']
})
export class TalentProfilePictureComponent implements OnInit {

  profilePictureAvailable: boolean = false
  profilePicture;

  @Input() elevated: boolean = true

  readonly file = new FormControl<File>(null);


  constructor(
      private dialog: MatDialog,
      public profileImageService: ProfileImageService,
      private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.loadProfilePic()
  }

  loadProfilePic() {
    this.profileImageService.getOwnProfilePic().subscribe(result => {

      if (result.size == null || result.size == 0) this.profilePictureAvailable = false;

          else {
            this.profilePictureAvailable = true;
            let blob = new Blob([result], {type: 'image/png'})
            this.profilePicture = URL.createObjectURL(blob)
          }
        }
    );
  }

  editProfilePicDialog(event) {
    if (!this.file.value) return
    let dialogRef = this.dialog.open(EditImageDialogComponent, {
      data: {
        file: this.file.value,
        changeEvent: event,
        title: 'Profilbild ändern',
        aspectRatio: 1,
        roundCropping: true,
        onSave: null,
        imageData: null
      },
      panelClass: 'full-width-mobile-dialog'
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.profileImageService.uploadProfilePicture(data.file, data.fileName).subscribe(() => {
              this.loadProfilePic();
              this.navigationService.reportProfilePicChange();
            }
        );
      }
    });
  }

}
