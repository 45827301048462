import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {TalentTalentProfileResource} from 'utility';
import {ActivatedRoute, Router} from '@angular/router';
import {TalentAuthService} from '../../services/talent.auth.service';
import {environment} from '../../../environments/environment';
import {FormControl} from '@angular/forms';
import {DeviceDetectorService} from "ngx-device-detector";
import {UnisignService} from "../../services/unisign.service";
import {OAuthPKCE} from "../../utility/OAuthPKCE/OAuthPKCE";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

export interface LogoClass {
  companyId: number,
  companyLogo: string | ArrayBuffer
}

@Component({
  selector: 'app-login-student',
  templateUrl: './login-student.component.html',
  styleUrls: ['./login-student.component.scss']
})
export class LoginStudentComponent implements OnInit, OnDestroy {

  redirectPath = '/home';
  fallback = false;

  page: number = 0;
  size: number = 3;
  totalElements: number;
    private listener?: (event: MessageEvent) => void;
    @ViewChild("iframe") iframe?: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  state = null;
  error = "";
  loading = true;
  apiUrl = '';
  fromOnboarding: boolean = false;

  usernameControl = new FormControl("");
  passwordControl = new FormControl("");

  companies: any[] = [];

  announcementInfoBoxEnabled: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private authService: TalentAuthService,
      private router: Router,
      private deviceService: DeviceDetectorService,
      private talentTalentProfileResource: TalentTalentProfileResource,
      public unisignSerivce: UnisignService,
      private domSanitizer: DomSanitizer

  ) {
  }

  ngOnInit() {
      this.apiUrl = environment.apiUrl;
    this.route.snapshot.queryParamMap.get('preventRedirect') ? this.fromOnboarding = true : this.fromOnboarding = false;

    if (this.fromOnboarding && this.authService.isAuthenticated()) {
      this.authService.logout();
    }

    if (this.authService.isAuthenticated() && this.authService.getAccountInfo().onboardingCompleted) {
      this.router.navigateByUrl(this.redirectPath);
    }


    //TODO get from localstorage and save before redirect to /login in case of unauthorizied user
    const redirectUri = this.route.snapshot.queryParamMap.get('redirectUri');
    if (redirectUri) {
      this.redirectPath = redirectUri;
    }


      this.route.queryParams
          .subscribe(params => {
                  const authCode = params['code'] // authorization code (OAuth)
                  if (authCode) {
                      console.log(authCode)
                      const codeVerifier = localStorage.getItem("code_verifier")
                      localStorage.removeItem("code_verifier")
                      if (window.frameElement) {
                          (parent as any).handleLoginResult(authCode, codeVerifier)
                      } else {
                          this.handleLoginResult(authCode, codeVerifier)
                      }
                  }
              }
          )



      this.listener = (event: MessageEvent) => {
          // Security check: verify the origin of the message
          if (event.origin !== environment.uniSignUrl) {
              return;
          }
          // Check if the iframe is requesting credentials
          if (event.data === 'ready') {
              this.loading = false;
          } else if (event.data === 'error') {
              this.error = "E-Mail oder Passwort falsch"
              this.iframeLogin()
          } else if (event.data === 'logout') {
              this.iframeLogin()
          } else if (event.data === 'blocked') {
              this.loading = false;
              this.fallback = true;
          }
      };
      // Event listener for messages from the iframe
      window.addEventListener('message', this.listener);

      if(this.route.snapshot.queryParamMap.get('logout') == 'true') {
          this.iframeLogout()
      } else if (!window.frameElement) {
          this.iframeLogin()
      }
  }

    ngOnDestroy(): void {
        if (this.listener)
            window.removeEventListener('message', this.listener);
    }

    performUnisignLogin() {
      this.loading = true
        if(!this.fallback) {
            this.iframe!.nativeElement.contentWindow!.postMessage(
                {username: this.usernameControl.value, password: this.passwordControl.value},
                environment.uniSignUrl // Target origin
            )
        } else {
            this.authService.login(this.usernameControl.value, this.passwordControl.value).subscribe(result => {
                switch (result) {
                    case "LoginFailedCredentialsIncorrect":
                        this.error = "E-Mail oder Passwort falsch"
                        break;
                    case "LoginFailedRoleIncorrect":
                        this.error = "Du versuchst dich mit einem Account anzumelden, welcher kein Talent-Account ist."
                        break;
                    case "LoginSuccessful":
                    case "TokenUpdated":
                        this.router.navigateByUrl(this.redirectPath);
                        break;
                    default:
                        this.error = ""
                        break;
                }

                this.loading = false
            })
        }
    }

  reportDevice() {
    this.talentTalentProfileResource.logTalentDevice({
      browser:  this.deviceService.browser,
      browserVersion: this.deviceService.browser_version,
      deviceType: this.deviceService.deviceType
    });
  }

  routeToCompanyLogin() {
    window.open('https://arbeitgeber.talentagent.de/login');
  }

    iframeSrc: SafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("about:blank")

    iframeLogout() {
        this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `${environment.uniSignUrl}/logout?iframe=true`
        );
    }

    iframeLogin() {
        this.loading = true;
        const codeVerifier = OAuthPKCE.generateCodeVerifier()
        localStorage.setItem("code_verifier", codeVerifier)
        OAuthPKCE.generateCodeChallenge(codeVerifier).then(codeChallenge => {
            (window as any).handleLoginResult = this.handleLoginResult.bind(this)
            // https://datatracker.ietf.org/doc/html/rfc7636#section-4.3
            this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `${environment.uniSignUrl}/oauth2/authorize?iframe=true&response_type=code&client_id=${environment.frontendUnisignClientId}&redirect_uri=${environment.appLoginRedirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`
            );
        })
    }

  handleLoginResult(authCode: string, codeVerifier: string | null) {
      console.log("handleLoginResult: authcode: " + authCode + " codeVerifier: " + codeVerifier)
    this.authService.oauthLogin(authCode, codeVerifier).pipe(
        catchError((error: HttpErrorResponse) => {
            this.error = error.error?.error ?? error.message
            this.authService.authenticatedSubject.next("LoginFailedCredentialsIncorrect");
            this.loading = false
            return throwError(() => new Error('oauth error'))
        })
    )
      .subscribe(
        (state) => {
            console.log("afterHandleLoginResultSubscribe")
          this.handleAfterLoginStep(state);
        }
      );
  }

  private handleAfterLoginStep(state) {
    this.state = state;
    if (state == "LoginSuccessful" || state == "TokenUpdated") {
      this.reportDevice();

      this.router.navigateByUrl(this.redirectPath);

    } else {
      if (state == "LoginFailedRoleIncorrect") this.iframeLogout();
      this.loading = false;
      this.passwordControl.setValue('');
    }
  }

  routeToOnboarding() {
    this.router.navigateByUrl('/onboarding');
  }
}
