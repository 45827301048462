import { Component, OnInit } from '@angular/core';
import {TalentApplicationMinimalData, TalentApplicationResource, TalentTalentProfileResource} from "utility";
import {AbstractControl, FormControl, ValidatorFn} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FileService} from "../../../services/file.service";
import {EventOnboardingService} from "../event-onboarding.service";

@Component({
  selector: 'app-event-ob-state',
  templateUrl: './event-ob-state.component.html',
  styleUrls: ['./event-ob-state.component.scss']
})
export class EventObStateComponent implements OnInit {


  loading = true;

  constructor(
      public onboardingService: EventOnboardingService
  ) {

  }

  ngOnInit(): void {
  }

  progress(context: string) {
    this.onboardingService.delayedRouting("/onboarding/event/preferences", context)
  }

  routeToOnboarding() {
    this.progress('next')
  }
}
