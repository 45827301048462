import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {TalentAccountResource, TalentPositionRelationResource} from "utility";
import {AnimationService} from "../../services/animation.service";
import {TalentAuthService} from "../../services/talent.auth.service";

@Injectable({
  providedIn: 'root'
})
export class HomeService implements OnDestroy {

  subjectList: BehaviorSubject<boolean>[] = []
  subscriptionList: Subscription[] = []
  homeLoaded = new BehaviorSubject<boolean>(false)

  constructor(
      private talentPositionRelationResource: TalentPositionRelationResource,
      private animationService: AnimationService,
      private talentAccountResource: TalentAccountResource,
      private authService: TalentAuthService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  routeToStaufenbiel(context: "Appointment" | "Fair") {
    this.talentAccountResource.createTalentClickedStaufenbielPromotionLogEntryIfNecessary()

    if (context == "Appointment") {
      window.open("https://www.absolventenkongress.de/jobmesse/muenchen?btps=Dashbord_Uniwunder&btpca=meunchen&btpm=extern&btpco=Uniwunder_Dashboard-v2", '_blank');
    } else {
      window.open("https://www.absolventenkongress.de/jobmesse/muenchen?btps=Dashbord_Uniwunder&btpca=meunchen&btpm=extern&btpco=Uniwunder_Dashboard", '_blank');
    }
  }

  registerSub(newSub: BehaviorSubject<boolean>) {
    this.subjectList.push(newSub)
    this.subscriptionList.push(newSub.subscribe(() => this.checkSub()))
    }

    checkSub() {
    if (this.subjectList.findIndex(s => s.value) < 0) {
      this.homeLoaded.next(true)
      this.unsubscribeAll()
      this.subjectList = []
    }
    }

    unsubscribeAll() {
      this.subscriptionList.forEach(el => el.unsubscribe())
      this.subjectList = []
    }
}
