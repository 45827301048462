<div class="home-c-w">
    <home-headline icon="file-outline"><span style="font-weight: bold; font-size: 12px">{{'Next Step: Lebenslauf'}}</span></home-headline>
    <div class="home-c-c-w" fxLayout="column" fxLayoutAlign="start center">
                <i style="font-size: 30px; color: #dc4343" class="mdi mdi-file-pdf"></i>
                <div class="text-center m-bottom5">Lade deinen Lebenslauf hoch um bessere Vorschläge zu erhalten und Bewerbungen zu verschicken!</div>

                <cv-upload-button style="margin-top: 10px" [bttColor]="'#ffffff'" [borderRadius]="50" [buttonClass]="'ita_dashboard'" [buttonClass]="'ita_dashboard ' + authService.splitIdClass()"></cv-upload-button>
                <a class="link" href="assets/files/Lebenslaeufe.zip">Kein Lebenslauf? Wir haben Vorlagen</a>
    </div>

</div>