<div style="padding-left: 24px">
    <dialog-header headline="Account deaktivieren"></dialog-header>
</div>
    <mat-horizontal-stepper linear #stepper [@.disabled]="true">
        <mat-step>
            <div class="reason-paragraph box-content" fxLayout="column"
                 fxLayoutAlign="start start">
                <div class="headline">Warum möchtest du deinen Account deaktivieren?</div>
                <mat-radio-group [formControl]="reasonControl" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <mat-radio-button value="FoundJob">Ich habe einen Job gefunden.</mat-radio-button>
                    <mat-radio-button value="LostInterest">Ich habe keine Verwendung mehr für Talentagent.</mat-radio-button>
                    <mat-radio-button value="ToMuchMails">Ich erhalte zuviele Nachrichten von Talentagent.</mat-radio-button>
                    <button mat-flat-button color="accent" style="position: relative; left: 16px" (click)="navigateToNotificationSettings()" *ngIf="reasonControl.value == 'ToMuchMails'">Benachrichtigungen anpassen</button>
                    <mat-radio-button value="DislikesTalentpool">Ich bin mit der Leistung von Talentagent unzufrieden.
                    </mat-radio-button>
                    <mat-radio-button value="FearsDataTheft">Ich Sorge mich um die Sicherheit meiner Daten.</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="actions-box">
                <button mat-flat-button color="accent" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step *ngIf="reasonControl.value == 'FoundJob'">
            <div class="foundJob-paragraph box-content" fxLayout="column"
                 fxLayoutAlign="start start">
                <div>
                    <div class="headline">Hast du deinen neuen Job über Talentagent gefunden?</div>
                    <mat-radio-group [formControl]="jobFoundByTalentAgentControl" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <mat-radio-button [value]=true>Ja</mat-radio-button>
                        <mat-radio-button [value]=false>Nein</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="select-company">
                    <hid-dropdown *ngIf="jobFoundByTalentAgentControl.value"
                                  [control]="applicationSelectControl"
                                  [options]="applicationsWithPositon"
                                  [displayProperty]="'name'"
                                  [identifyingProperty]="'id'"
                                  [mode]="'Object'"
                                  label="Für welche Stelle wurdest du eingestellt?*">
                    </hid-dropdown>
                </div>
            </div>

            <div class="actions-box">
                <button mat-button matStepperPrevious>zurück</button>
                <button [disabled]="jobFoundByTalentAgentControl.value && !applicationSelectControl.value" mat-flat-button color="accent" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <div class="anomymize-paragraph box-content" fxLayout="column"
                 fxLayoutAlign="start start">
                <div class="headline">Sollen alle personenbezogenen Daten von dir gelöscht werden?</div>
                <mat-radio-group [formControl]="anonymizeControl" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <mat-radio-button [value]=true>Ja</mat-radio-button>
                    <mat-radio-button [value]=false>Nein</mat-radio-button>
                </mat-radio-group>

            </div>
            <div class="actions-box">
                <button mat-button matStepperPrevious>zurück</button>

                <button mat-flat-button color="warn"
                        (click)="delete()"
                        [loadingBool]="deleteLoading">Jetzt Account deaktivieren
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
