<div class="background" fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="announcementInfoBoxEnabled" class="announcement-info-box mat-elevation-z8" fxLayout="column" fxLayoutAlign="center center">
    Liebe Talente, <br /><br />
    wir haben derzeit eine kleines Problem in der Applikation, daher könnte es zur Einschränkung beim Login kommen.
    Wir arbeiten daran und bitten vielmals um Verständnis.<br /><br />
    Eurer Uniwunder-Team
  </div>
  <div class="login-wrapper mat-elevation-z8" fxLayout="column" fxLayoutAlign="center center">
    <img class="logo" src="../../../assets/images/logo-zentriert.png" style="height: 80px">

    <div class="login-content" fxLayout.lt-sm="column">
      <form fxLayout="column" class="form">
        <hid-input-field
          [hideLabelWhenFilled]="true"
          [control]="usernameControl"
          label="Deine Email"
          name="username"
          type="email"
          (keydown.enter)="performUnisignLogin()"
        >
        </hid-input-field>
        <hid-input-field
          [hideLabelWhenFilled]="true"
          [control]="passwordControl"
          type="password"
          label="Dein Passwort"
          name="password"
          (keydown.enter)="performUnisignLogin()"
        >
        </hid-input-field>
        <a class="hid-link font-size-12" (click)="unisignSerivce.routeToForgotPassword()">Passwort vergessen</a>
      </form>
      <div *ngIf="error.length > 0" class="warning m-top10 font-size-14">{{error}}</div>
      <span class="warning m-top10 font-size-14" *ngIf="state == 'LoginFailedCredentialsIncorrect'">Benutzername oder Passwort falsch</span>
      <span class="warning m-top10 font-size-14" *ngIf="state == 'LoginFailedRoleIncorrect'">
        Sie versuchen sich als Talent anzumelden, ihr Account gehört aber nicht zu einem Talent.</span>
      <div fxLayout="column" fxLayoutAlign="start center">
        <button mat-flat-button color="accent" class="login-button ita_login ita_header" style="width: 100%"
                        (click)="performUnisignLogin()"
                        [loadingBool]="loading"
                        type="submit">Login
        </button>
        <span class="font-size-12">Du hast noch keinen Account? Jetzt <a class="font-size-12 hid-link ita_registration"
                                                                         (click)="routeToOnboarding()">Registrieren</a></span>

        <a class="font-size-12 hid-link m-top20" (click)="routeToCompanyLogin()">Zum Login für Unternehmen</a>
      </div>

    </div>
  </div>
</div>
<iframe class="login-iframe" #iframe [src]="iframeSrc"></iframe>


