import {Component, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LabelData, TalentOnboardingResource} from "utility";
import {MediaService} from "../../../services/inno-utils/media.service";
import {CachedOBInputs, ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
  selector: 'app-cpd-o-matching-data',
  templateUrl: './cpd-o-matching-data.component.html',
  styleUrls: ['./cpd-o-matching-data.component.scss']
})
export class CpdOMatchingDataComponent implements OnInit {

    readonly form = new FormGroup({
        mode: new FormControl(this.onboardingService.cachedInputs.searchMode? this.onboardingService.cachedInputs.searchMode : 'Nationwide', Validators.required),
        preferredCityId: new FormControl(this.onboardingService.cachedInputs.preferredCityId ? this.onboardingService.cachedInputs.preferredCityId : null, this.onboardingService.cachedInputs.searchMode == 'SpecificCities' ? Validators.required : null)
    })
    loadingPreference: boolean = true;
    preferenceData: LabelData[] = []
    selectedPreferenceData: LabelData[] = []

    locationSub: Subscription
    error: Subject<any> = new Subject<any>()

    constructor(
        public onboardingService: ConfirmProfileDetailsOnboardingService,
        public mediaService: MediaService,
        private analyticsService: AnalyticsService,
        private talentAuthService: TalentAuthService,
        private talentOnboardingResource: TalentOnboardingResource,
    ) {}

    ngOnInit() {
        this.initForm()
        this.getPreferencesOptions()
    }

    ngOnDestroy() {
        this.locationSub.unsubscribe()
    }

    initForm() {
        this.locationSub = this.form.controls.mode.valueChanges.subscribe(next => {
            if (next == 'SpecificCities') {
                this.form.controls.preferredCityId.setValidators(Validators.required)
            } else {
                this.form.controls.preferredCityId.setValidators(null)
            }
            this.form.controls.preferredCityId.updateValueAndValidity()
        })

        this.form.controls.preferredCityId.updateValueAndValidity()

        this.onboardingService.getUserLocationIfConsented().then((location) => {
            if (location && !this.form.controls.preferredCityId.value) {
                this.form.controls.preferredCityId.setValue(location.cityId);
            }
        });
    }

    selectPreference(option: LabelData) {
        if (this.selectedPreferenceData.findIndex(p => p.id == option.id) > -1) {
            this.selectedPreferenceData.splice(this.selectedPreferenceData.findIndex(p => p.id == option.id), 1)
        } else {
            this.selectedPreferenceData.push(option)
        }
    }

    getPreferencesOptions() {
        if (!this.onboardingService.cachedInputs.subjectId) return
        this.talentOnboardingResource.getRelevantProfessionFieldsForSubject({
            dkzsubjectId: this.onboardingService.cachedInputs.subjectId
        }).then(result => {
            this.preferenceData = result
            if (this.onboardingService.cachedInputs.markedAsInterestingFieldIds) this.selectedPreferenceData = this.preferenceData.filter(p => this.onboardingService.cachedInputs.markedAsInterestingFieldIds.includes(p.id))
            this.loadingPreference = false
        })
    }

    errorSubject = new Subject()
    progress() {
        if(!this.form.valid){
            this.errorSubject.next()
            return
        }

        if(!this.form.valid){
            this.error.next();
            return
        }

        this.onboardingService.updateInputs(<CachedOBInputs>{
            markedAsInterestingFieldIds : this.selectedPreferenceData.map(p => p.id),
            searchMode : this.form.controls.mode.value,
            preferredCityId : this.form.controls.preferredCityId.value,
        })


        if (this.talentAuthService.isAuthenticated()) {
            this.onboardingService.createOrUpdateTalentAccount()
            if (this.analyticsService.trafficOrigin.invitedFromToken) {
                this.onboardingService.delayedRouting("/onboarding/cpd/password")
            } else {
                this.onboardingService.delayedRouting("/onboarding/cpd/coach-intent")
            }
        } else {
            this.onboardingService.delayedRouting("/onboarding/cpd/contact-data")
        }
    }

}

