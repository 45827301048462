

<div fxLayout="column" fxLayoutAlign="center center" class="root-wrapper">
    <!--
    <img src="../../../../assets/images/uniwunder_ta.png" class="uniwunder-logo" height="70" style="margin-bottom:20px;"/>
    -->
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="content-main-wrapper" fxLayout="column" fxLayoutAlign="space-between center">
            <div class="content-inner-wrapper">
                <!-- CV Available    *ngIf="hasCv" -->
                <div fxLayout="column" fxLayoutAlign="start center">
                    <img style="width: 160px" src="../../../../assets/images/check_image.png" alt="Bewerbung erfolgreich abgeschickt">
                    <div class="state-heading">Danke für deine Anfrage! Wir melden uns bei dir, sobald ein Coach verfügbar ist.</div>

                    <div class="text-block">
                        Wenn du derzeit auf Jobsuche bist, teile uns bitte noch den Bereich mit, in dem du nach einer Stelle suchst. Dadurch können wir dir auf unserer Jobplattform "Talentagent" aus über 10.000 Stellenangeboten passende Vorschläge machen.
                    </div>

                    <button mat-flat-button color="accent" (click)="routeToOnboarding()" >Job nach der Uni finden</button>


                    <span *ngIf="!hasCv && !loading" class="text-block">
                   Vergiss nicht, deinen Lebenslauf hochzuladen. Keine Sorge, falls er noch nicht perfekt ist, dein Coach wird dir nützliche Tipps zur Verbesserung geben können. 😇
                </span>
                    <div *ngIf="!hasCv && !loading">
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-sm="column" style="width: 100%"
                             fxLayoutGap="10px">
                            <div *ngIf="!saving">
                                <input [disabled]="" type="file" [accept]="['.pdf']" (change)="set($event)" [formControl]="fileControl" id="file"
                                       class="inputfile ita_upload_cv_welcome_file">
                                <label for="file"
                                       class="ita_upload_cv_welcome_file label">{{(getFileName().length > 20) ? (getFileName() | slice:0:20) + '...' : (getFileName())}}</label>
                            </div>
                            <div *ngIf="saving" id="loading"></div>
                        </div>
                        <div id="file-errors" *ngIf="fileControl" fxLayout="column">

                            <sub style="color: red;" *ngIf="fileIsWordDocument">
                                Bei der angegebenen Datei handelt es sich um ein Worddokument.<br/>
                                Bitte konvertiere dieses zu einem PDF-Dokument und lade es dann hoch (<a
                                    href="https://praxistipps.chip.de/word-dokumente-in-pdf-umwandeln_3039" target="_blank">Anleitung</a>)!
                            </sub>

                            <small style="color: red" *ngIf="fileControl.errors != null && fileControl.errors['sizeTooBig']">Die Datei ist
                                größer als 10MB,
                                Bitte wähle eine kleinere Datei
                            </small>
                            <small style="color: red"
                                   *ngIf="fileControl.errors != null && fileControl.errors['wrongExtension'] && !fileIsWordDocument">
                                Falsches Format. Bitte wähle ein PDF-Dokument.
                            </small>
                            <small *ngIf="fileControl.errors != null && fileControl.errors['fileIsEmpty']">
                                Die ausgewählte Datei ist leer.
                            </small>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
