import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cv-parsing-o-step-headline',
  templateUrl: './cv-parsing-o-step-headline.component.html',
  styleUrls: ['./cv-parsing-o-step-headline.component.scss']
})
export class CVParsingOStepHeadlineComponent implements OnInit {

  @Input() marginTop: number = 10

  constructor() { }

  ngOnInit(): void {
  }

}