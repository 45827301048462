import {Component, Input, OnInit} from '@angular/core';
import {ChatMessageData} from "utility";
import {FileService} from "../../services/file.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {


  @Input() messageData: ChatMessageData
  @Input() talentId: number

  imageUrl: string = "../assets/images/blurred_user.png"


  constructor(
      private fileService: FileService,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getProfilePicture()
  }

  getProfilePicture() {
    if (this.messageData.sender.dtype === 'CompanyRecruiter') {
      let sub = this.fileService.getRecruiterProfilePicture(this.messageData.sender.id).subscribe(result => {
        if (result.size) this.imageUrl = URL.createObjectURL(result)
        sub.unsubscribe()
      })

    } else if (this.messageData.sender.dtype === 'Consultant') {
      let sub = this.fileService.getTalentAgentProfilePicture().subscribe(result => {
        if (result.size) this.imageUrl = URL.createObjectURL(result)
        sub.unsubscribe()
      })

    }
  }

}
