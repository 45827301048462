import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'degreeType'
})
export class DegreeTypePipe implements PipeTransform {

  transform(value: any, ): any {
        switch (value) {
          case 'Bachelor':
            return 'Bachelor';
          case 'Master':
            return 'Master';
          case 'Magister':
            return 'Magister';
          case 'Diploma':
            return 'Diplom';
          case 'Promotion':
            return 'Promotion';
          case 'StateExam':
            return 'Staatsexamen'
          case 'Other':
            return 'Sonstige'
          default: return value
        }
    }
}
