import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";
import {PositionService} from "../../services/position.service";
import {PropagationService} from "../../services/propagation.service";
import {TalentPositionPreviewData, TalentPositionRelationData, TalentPositionRelationResource} from "utility";
import {BehaviorSubject, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-positions-bookmarked',
  templateUrl: './positions-bookmarked.component.html',
  styleUrls: ['./positions-bookmarked.component.scss']
})
export class PositionsBookmarkedComponent implements OnInit, OnDestroy {

    bookmarkedPositions: TalentPositionPreviewData[]
    loadingBookmarkedProposals: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    totalProposalsBookmarked: number
    totalPages: number
    pageIndex: number = 0
    pageSize: number = 20

    removePositionSub: Subscription

  constructor(
      public mediaService: MediaService,
      public positionService: PositionService,
      public propagationService: PropagationService,
      private talentPositionRelationResource: TalentPositionRelationResource,
      private router: Router
  ) {
        this.removePositionSub = this.positionService.relationProceeded.subscribe(next => {
            if (next.action === "Applied") this.removePositionAfterApplication(next.relation)
        })
  }

  ngOnInit(): void {
        this.loadBookmarkedProposals()
  }

    ngOnDestroy() {
        this.removePositionSub?.unsubscribe()
    }


    loadBookmarkedProposals() {
        this.loadingBookmarkedProposals.next(true);
        this.bookmarkedPositions = []
        let url = this.router.url
        this.talentPositionRelationResource.getBookmarkedPositionProposals({
            pageNum: this.pageIndex,
            pageSize: this.pageSize
        }).then(result => {
            this.bookmarkedPositions = result.content;
            //not update selected position id page changed since loading start
            if (this.bookmarkedPositions?.length && url == this.router.url) this.positionService.selectedPositionId.next(this.bookmarkedPositions[0].id)
            this.totalPages = result.totalPages
            this.totalProposalsBookmarked = result.totalElements;
            this.loadingBookmarkedProposals.next(false);
        });
    }

    removePositionAfterApplication(positionToRemove: TalentPositionRelationData) {
        this.positionService.removePositionFromList(positionToRemove, this.bookmarkedPositions).then()
    }
}
