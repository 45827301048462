import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CachedOBInputs, S2FaxOnboardingService} from "../../s2-fax-onboarding/s2-fax-onboarding.service";
import {
    CachedOBInputs as CoachingOBInputs,
    CoachingOnboardingService
} from "../../coaching-onboarding/coaching-onboarding.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {
    COOKIE_NAME_ACCESS_STRATEGY,
    COOKIE_NAME_USER_INFORMATION,
    CookieService
} from "../../../services/cookie.service";


@Component({
  selector: 'app-onboarding-entry',
  templateUrl: './onboarding-entry.component.html',
  styleUrls: ['./onboarding-entry.component.scss']
})
export class OnboardingEntryComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private s2FaxObService: S2FaxOnboardingService,
        private coachingOnboardingService: CoachingOnboardingService,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService
    ) {
        let trafficOrigin = {...this.analyticsService.trafficOrigin}
        trafficOrigin.entryGateway = "Standard"
        trafficOrigin.utm_term = "Standard"
        this.analyticsService.trafficOrigin = trafficOrigin
    }

    ngOnInit(): void {

        // const userInformationFromCookie = this.cookieService.getCookieByteArrayBase64Decoded(COOKIE_NAME_USER_INFORMATION);
        // const accessStrategyFromCookie = this.cookieService.getCookieByteArrayBase64Decoded(COOKIE_NAME_ACCESS_STRATEGY);

        const userInformationFromCookie = this.cookieService.getByteArrayBase64Decoded(
            this.route.snapshot.queryParamMap.get(COOKIE_NAME_USER_INFORMATION)
        );
        const accessStrategyFromCookie = this.cookieService.getByteArrayBase64Decoded(
            this.route.snapshot.queryParamMap.get(COOKIE_NAME_ACCESS_STRATEGY)
        );

        if (userInformationFromCookie && accessStrategyFromCookie === "REGISTRATION") {
            const data = <CachedOBInputs>{
                firstName: userInformationFromCookie.firstName,
                lastName: userInformationFromCookie.lastName,
                email: userInformationFromCookie.email,
                accessStrategy: accessStrategyFromCookie
            };
            this.s2FaxObService.updateInputs(data);
            this.coachingOnboardingService.updateInputs(data as CoachingOBInputs)
        }

            let fieldString = this.activatedRoute.snapshot.queryParams['fieldId']

            let fieldStrings = fieldString?.split(",")
            let fieldIds: number[] = []
            fieldStrings?.forEach(el => fieldIds.push(Number(el)))

            if (fieldStrings) {
                this.s2FaxObService.updateInputs(<CachedOBInputs>{
                    markedAsInterestingFieldIds: fieldIds,
                    fieldIds: fieldIds
                })
            }

            if (this.route.snapshot.queryParams['agent']) {
                this.router.navigate(['/onboarding/coach-welcome'], {queryParamsHandling: 'merge'})
            } else {
         // if (Math.random() < 0.5) {
         //        this.router.navigate(['/onboarding/cpd']) //Split CPD OB
         // } else {
             this.router.navigate(['/onboarding/cvp']) //Split cv-parsing OB

         // }
            }
        }
    }
