import {Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaService {


  constructor(
    private meta: Meta,
  ) {
  }

  setMetaTag(tag: string, text: string) {
    this.removeMetaTag(tag)

    if (this.meta.getTag(tag) == null) {
      this.meta.addTag({name: tag, content: text});
    } else {
      this.meta.updateTag({name: tag, content: text});
    }
  }

  removeMetaTag(tag: string) {
    this.meta.removeTag(`name =${tag}`)
  }
}


