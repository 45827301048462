import {Component, Input, OnInit} from '@angular/core';
import {LabelData} from "utility";

@Component({
  selector: 'company-logo-with-name',
  templateUrl: './company-logo-with-name.component.html',
  styleUrls: ['./company-logo-with-name.component.scss']
})
export class CompanyLogoWithNameComponent implements OnInit {

  @Input() company: LabelData

  constructor() { }

  ngOnInit(): void {
  }

}
