import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, KeyValuePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TalentAccountComponent} from './talentaccount/talent-account.component';
import {LoginStudentComponent} from './login/login-student/login-student.component';

import {TalentComponent} from './talent.component';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JwtModule} from '@auth0/angular-jwt';
import {LocationPreferencesModePipe} from './utility/location-preferences-mode.pipe';
import {AuthGuardService} from './services/auth-guard.service';
import {TalentAuthService} from './services/talent.auth.service';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {OneTimeTokenLoginComponent} from './login/one-time-token-login/one-time-token-login.component';
import {NotFoundComponent} from './other/not-found/not-found.component';
import {SupportDialogComponent} from './dialogs/support-dialog/support-dialog.component';
import {RequestErrorInterceptorService} from './services/request-error-interceptor.service';
import {OneTimeTokenExpiredComponent} from './login/one-time-token-expired/one-time-token-expired.component';
import {AddCvDialogComponent} from './talent-profile/add-cv-dialog/add-cv-dialog.component';
import bugsnag from '@bugsnag/js';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {MatRippleModule} from '@angular/material/core';
import {HidLibCloneModule} from './utility/hid-lib-clone/hid-lib-clone.module';
import {TalentApplicationsComponent} from './applications/talent-applications.component';
import {
    TalentCreateApplicationDialogComponent
} from './dialogs/talent-create-application-dialog/talent-create-application-dialog.component';
import {InnoUtilsModule} from './services/inno-utils/inno-utils.module';
import {EducationTypePipe} from '../../../utility/src/lib/pipes/education-type.pipe';
import {TalentProfileComponent} from './talent-profile/talent-profile.component';
import {
    BenefitResource,
    PublicEmploymentPositionResource,
    PublicEventResource,
    RequestInfoResource,
    TalentChatResource,
    TalentElasticSearchResource,
    TalentEventResource,
    TalentLocationPreferencesResource,
    UtilityModule
} from 'utility';
import {GDPRConsentDialogService} from './other/gdprconsent/gdprconsent-dialog.service';
import {GDPRConsentComponent} from './other/gdprconsent/gdprconsent/gdprconsent.component';
import {CookieService} from './services/cookie.service';
import {TalentAgentComponent} from './coach/talent-agent.component';
import {RequestTalentAgentComponent} from './coach/request-talent-agent/request-talent-agent.component';
import {MediatorService} from './services/mediator.service';
import {CoachingOfferCardComponent} from './coach/coaching-offer-card/coaching-offer-card.component';
import {
    AssignTalentAgentDialogComponent
} from './coach/assign-talent-agent-dialog/assign-talent-agent-dialog.component';
import {WelcomeVideoComponent} from '../../../utility/src/lib/components/welcome-video/welcome-video.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {
    RejectPositionProposalDialogComponent
} from './dialogs/reject-position-proposal-dialog/reject-position-proposal-dialog.component';
import {
    ShareTalentAgentToFriendsComponent
} from './utility/share-talent-agent-to-friends/share-talent-agent-to-friends.component';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {MlpInfoDialogComponent} from './coach/mlp-info-dialog/mlp-info-dialog.component';
import {
    TalentAccountDeactivationDialogComponent
} from './dialogs/talent-account-deactivation-dialog/talent-account-deactivation-dialog.component';
import {FeedExplanationDialogComponent} from './dialogs/feed-explanation-dialog/feed-explanation-dialog.component';
import {PositionDetailsDesktopComponent} from "./position/position-details-desktop/position-details-desktop.component";
import {JudgeTalentAgentDialogComponent} from './coach/judge-talent-agent-dialog/judge-talent-agent-dialog.component';
import {HidStarRatingComponent} from "./utility/hid-star-rating/hid-star-rating.component";
import {
    PositionLocationInfoDialogComponent
} from './dialogs/position-location-info-dialog/position-location-info-dialog.component';
import {
    ApplicationTargetInfoDialogComponent
} from './dialogs/application-target-info-dialog/application-target-info-dialog.component';
import {AECdiscTalentanalysisComponent} from './coach/aecdisc-talentanalysis/aecdisc-talentanalysis.component';
import {
    WithdrawApplicationDialogComponent
} from './dialogs/withdraw-application-dialog/withdraw-application-dialog.component';
import {TalentAgentContactComponent} from './coach/talent-agent-contact/talent-agent-contact.component';
import {CoachingOffersComponent} from './coach/coaching-offers/coaching-offers.component';
import {PositionSupportDialogComponent} from './dialogs/position-support-dialog/position-support-dialog.component';
import {RocketAnimationComponent} from './utility/rocket-animation/rocket-animation.component';
import {MobileNavigationComponent} from './utility/mobile-navigation/mobile-navigation.component';
import {FooterComponent} from './utility/footer/footer.component';
import {ShortProfileComponent} from './utility/short-profile/short-profile.component';
import {
    ApplicationProcessbarSmallComponent
} from './utility/application-processbar-small/application-processbar-small.component';
import {TalentProfilePictureComponent} from './utility/talent-profile-picture/talent-profile-picture.component';
import {
    PositionDetailsContentNewComponent
} from './position/position-details-content-new/position-details-content-new.component';
import {WorkTypeComponent} from './utility/work-type/work-type.component';
import {RecommendationBigComponent} from './utility/recommendation-big/recommendation-big.component';
import {LocationDropdownComponent} from './utility/location-dropdown/location-dropdown.component';
import {RobotInfoBoxComponent} from './utility/robot-info-box/robot-info-box.component';
import {CoachTeaserComponent} from './utility/coach-teaser/coach-teaser.component';
import {SearchRadiusSelectComponent} from './utility/search-radius-select/search-radius-select.component';
import {
    PrimeRecommendationRedirectDialogComponent
} from './dialogs/prime-recommendation-redirect-dialog/prime-recommendation-redirect-dialog.component';
import {MatchingProgressBarComponent} from './utility/matching-progress-bar/matching-progress-bar.component';
import {
    ConfirmExternalApplicationAlertComponent
} from './dialogs/confirm-external-application-alert/confirm-external-application-alert.component';
import {NPSScoreOverlayComponent} from './other/npsscore-overlay/npsscore-overlay.component';
import {
    MatchingReactivationPromptDialogComponent
} from './dialogs/matching-reactivation-prompt-dialog/matching-reactivation-prompt-dialog.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {
    CareerEventPreviewDialogComponent
} from './talent-profile/career-event-preview-dialog/career-event-preview-dialog.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {FiveStarRatingOverlayComponent} from './other/five-star-rating-overlay/five-star-rating-overlay.component';
import {
    UploadAdditionalDocumentsForApplicationDialogComponent
} from './talent-card/application-state-talentcard/upload-additional-documents-for-application-dialog/upload-additional-documents-for-application-dialog.component';
import {ApplicationCockpitDialogComponent} from './application-cockpit-dialog/application-cockpit-dialog.component';
import {CvIncentiveDialogComponent} from './dialogs/cv-incentive-dialog/cv-incentive-dialog.component';
import {InformationDialogComponent} from './utility/information-dialog/information-dialog.component';
import {EditSubjectDialogComponent} from './talent-profile/edit-subject-dialog/edit-subject-dialog.component';
import {
    ConsultantAppointmentDialogComponent
} from './dialogs/consultant-appointment-dialog/consultant-appointment-dialog.component';
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ShareTalentagentBoxComponent} from './utility/share-talentagent-box/share-talentagent-box.component';
import {
    PositionFieldOfActivitiesDialogComponent
} from './dialogs/position-field-of-activities/position-field-of-activities-dialog.component';
import {ShuffleProgressComponent} from './utility/shuffle-progress/shuffle-progress.component';
import {OnboardingParentModule} from "./onboardings/onboarding-parent/onboarding-parent.module";
import {HomeModule} from "./home/home.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {EventOverviewComponent} from './events/event-overview/event-overview.component';
import {TalentCardModule} from "./talent-card/talent-card.module";
import {CoachingOnboardingModule} from "./onboardings/coaching-onboarding/coaching-onboarding.module";
import {CompanyPreviewModule} from "./company-preview/company-preview.module";
import {CompanyProfileModule} from "./company-profiles/company-profile.module";
import {EventOnboardingModule} from "./onboardings/event-onboarding/event-onboarding.module";
import {
    EventCompanyProfileDialogComponent
} from "./utility/event-company-profile-dialog/event-company-profile-dialog.component";
import {ChatModule} from "./chat-overview/chat.module";
import {PhoneNumberValidationModule} from "./phone-number-validation/phone-number-validation.module";
import {NgxPiwikProModule, NgxPiwikProRouterModule} from "@piwikpro/ngx-piwik-pro";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {SlideableBottomSheetsModule} from "./utility/slideable-bottom-sheets/slideable-bottom-sheets.module";
import {MatSlider, MatSliderModule, MatSliderRangeThumb, MatSliderThumb} from "@angular/material/slider";
import {PositionsRecommendedComponent} from "./recommendations/positions-recommended/positions-recommended.component";
import {PositionsBookmarkedComponent} from "./bookmarks/positions-bookmarked/positions-bookmarked.component";
import {PositionsModule} from "./search/positions.module";
import {PositionPreviewComponent} from './position-preview/position-preview.component';
import {MatButton} from "@angular/material/button";
import {LocalStorageService} from "./services/local-storage.service";
import {SearchService} from "./services/search.service";
import { SearchDropdownComponent } from './utility/PositionQuestions/search-dropdown/search-dropdown.component';
import { TextQuestionInputComponent } from './utility/PositionQuestions/text-question-input/text-question-input.component';
import { TextLineQuestionInputComponent } from './utility/PositionQuestions/text-line-question-input/text-line-question-input.component';
import { DateQuestionComponent } from './utility/PositionQuestions/date-question/date-question.component';
import { FileUploadQuestionComponent } from './utility/PositionQuestions/file-upload-question/file-upload-question.component';

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

export function errorHandlerFactory() {
    let releaseStage = 'production';
    if (!environment.production) {
        return new ErrorHandler()
    }
    return new BugsnagErrorHandler(bugsnag.start({
        apiKey: 'de97dc0e12fcd4e91556d1ce10c59bf4',
        releaseStage: releaseStage
    }))
}

const appRoutes: Routes = [
    {path: 'talent/login', component: LoginStudentComponent},
    {path: 'position-preview/:position', component: PositionPreviewComponent},
    {path: 'account', component: TalentAccountComponent, canActivate: [AuthGuardService]},
    {path: 'account/:tab', component: TalentAccountComponent, canActivate: [AuthGuardService]},
    {
        path: 'talent/proposals/recommendations',
        component: PositionsRecommendedComponent,
        canActivate: [AuthGuardService]
    },
    {path: 'talent/proposals/search', redirectTo: 'talent/positions'},
    {path: 'talent/proposals/hybrid', redirectTo: 'talent/positions'},
    {
        path: 'talent/proposals/bookmarks',
        component: PositionsBookmarkedComponent,
        canActivate: [AuthGuardService]
    },

    {
        path: 'events',
        component: EventOverviewComponent,
        canActivate: [AuthGuardService]
    },
    {path: 'talent/profile', component: TalentProfileComponent, canActivate: [AuthGuardService]},
    {path: 'talent/applications', component: TalentApplicationsComponent, canActivate: [AuthGuardService]},
    {path: 'talent/agent/request', component: RequestTalentAgentComponent, canActivate: [AuthGuardService]},
    {path: 'talent/agent', component: TalentAgentComponent, canActivate: [AuthGuardService]},
    {path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService]},
    {path: 'onetimetoken/login', component: OneTimeTokenLoginComponent},
    {path: 'onetimetoken/expired', component: OneTimeTokenExpiredComponent},
    {path: 'welcome', component: WelcomeVideoComponent, canActivate: [AuthGuardService]},
    {path: 'introduction-video', component: WelcomeVideoComponent, canActivate: [AuthGuardService]},
    {path: '404', component: NotFoundComponent},
    {path: '', component: LoginStudentComponent, pathMatch: 'full'},
    {path: '**', redirectTo: '/404'},
];

@NgModule({
    declarations: [

        //states:
        LoginStudentComponent,
        TalentAccountComponent,
        //pipes:

        //dialogs
        HidStarRatingComponent,
        TalentComponent,
        LocationPreferencesModePipe,
        NotificationsComponent,
        OneTimeTokenLoginComponent,
        EventCompanyProfileDialogComponent,
        NotFoundComponent,
        SupportDialogComponent,
        OneTimeTokenExpiredComponent,
        AddCvDialogComponent,
        TalentApplicationsComponent,
        TalentCreateApplicationDialogComponent,
        TalentProfileComponent,
        GDPRConsentComponent,
        TalentAgentComponent,
        RequestTalentAgentComponent,
        CoachingOfferCardComponent,
        AssignTalentAgentDialogComponent,
        RejectPositionProposalDialogComponent,
        ShareTalentAgentToFriendsComponent,
        MlpInfoDialogComponent,
        TalentAccountDeactivationDialogComponent,
        PositionDetailsDesktopComponent,
        FeedExplanationDialogComponent,
        JudgeTalentAgentDialogComponent,
        PositionLocationInfoDialogComponent,
        ApplicationTargetInfoDialogComponent,
        AECdiscTalentanalysisComponent,
        WithdrawApplicationDialogComponent,
        TalentAgentContactComponent,
        CoachingOffersComponent,
        PositionSupportDialogComponent,
        RocketAnimationComponent,
        MobileNavigationComponent,
        FooterComponent,
        ShortProfileComponent,
        ApplicationProcessbarSmallComponent,
        TalentProfilePictureComponent,
        PositionDetailsContentNewComponent,
        WorkTypeComponent,
        RecommendationBigComponent,
        LocationDropdownComponent,
        RobotInfoBoxComponent,
        CoachTeaserComponent,
        SearchRadiusSelectComponent,
        PrimeRecommendationRedirectDialogComponent,
        MatchingProgressBarComponent,
        ConfirmExternalApplicationAlertComponent,
        NPSScoreOverlayComponent,
        MatchingReactivationPromptDialogComponent,
        NPSScoreOverlayComponent,
        CareerEventPreviewDialogComponent,
        FiveStarRatingOverlayComponent,
        UploadAdditionalDocumentsForApplicationDialogComponent,
        ApplicationCockpitDialogComponent,
        CvIncentiveDialogComponent,
        InformationDialogComponent,
        EditSubjectDialogComponent,
        InformationDialogComponent,
        ConsultantAppointmentDialogComponent,
        ShareTalentagentBoxComponent,
        PositionFieldOfActivitiesDialogComponent,
        ShuffleProgressComponent,
        EventOverviewComponent,
        PositionsRecommendedComponent,
        PositionsBookmarkedComponent,
        PositionPreviewComponent,
        SearchDropdownComponent,
        TextQuestionInputComponent,
        TextLineQuestionInputComponent,
        DateQuestionComponent,
        FileUploadQuestionComponent,
    ],
    providers: [
        {provide: ErrorHandler, useFactory: errorHandlerFactory},
        AuthGuardService,
        TalentAuthService,
        {provide: TalentAuthService},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestErrorInterceptorService,
            multi: true,
        },
        DatePipe,
        EducationTypePipe,
        GDPRConsentDialogService,
        CookieService,
        MediatorService,
        KeyValuePipe,
        MatButton,
        PublicEventResource,
        TalentEventResource,
        BenefitResource,
        TalentElasticSearchResource,
        TalentChatResource,
        PublicEmploymentPositionResource,
        TalentLocationPreferencesResource,
        LocalStorageService,
        SearchService,
        MatSlider,
        MatSliderThumb,
        MatSliderRangeThumb,
        RequestInfoResource,
        Document
    ],
    imports: [
        BrowserModule,
        HidLibCloneModule,
        BrowserAnimationsModule,
        MatMenuModule,
        CompanyProfileModule,
        CommonModule,
        HomeModule,
        PhoneNumberValidationModule,
        MatTooltipModule,
        CoachingOnboardingModule,
        EventOnboardingModule,
        FormsModule,
        ReactiveFormsModule,
        MatRippleModule,
        MatBottomSheetModule,
        CompanyPreviewModule,
        ChatModule,
        UtilityModule.forRoot(environment),
        UtilityModule,
        RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
        JwtModule.forRoot({ // https://github.com/auth0/angular2-jwt
                config: {
                    tokenGetter: tokenGetter,
                    allowedDomains: [environment.apiHost, environment.uniSignHost],
                    disallowedRoutes: [environment.uniSignUrl + '/oauth2/token']
                }
            }
        ),
        InnoUtilsModule,

        OnboardingParentModule,
        NgxMatSelectSearchModule,
        NgxExtendedPdfViewerModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatSliderModule,
        NgxMatNativeDateModule,
        DragDropModule,
        TalentCardModule,
        NgxPiwikProModule.forRoot(environment.piwikContainerId, environment.piwikContainerUrl),
        NgxPiwikProRouterModule,
        SlideableBottomSheetsModule,
        PositionsModule
    ],
    exports: [
        LocationPreferencesModePipe,
        TalentProfileComponent,
        PositionPreviewComponent
    ],
    bootstrap: [
        TalentComponent
    ]
})
export class TalentModule {
}
