import {Component, Input, OnInit} from '@angular/core';
import {PositionChipData} from "utility";
import {HomeOfficePipe} from "../../../../../../utility/src/lib/pipes/home-office.pipe";

@Component({
  selector: 'app-positions-single-chip',
  templateUrl: './positions-single-chip.component.html',
  styleUrls: ['./positions-single-chip.component.scss'],
})
export class PositionsSingleChipComponent implements OnInit {

  @Input() chip: PositionChipData
  @Input() selected: boolean
    @Input() topjob: boolean = false

  constructor(
      private homeOfficePipe: HomeOfficePipe
  ) { }

  ngOnInit(): void {
  }

  getText(chip: PositionChipData) {
      if (chip.type == "HomeOffice") {
          return this.homeOfficePipe.transform(chip.title)
      }
      else if (chip.type == "WorkingHours") {
          return chip.title.replace(" Wochenstunden", "h")
      }
      else return chip.title
  }

}
