import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
      private snackBar: MatSnackBar,
  ) {
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 3000});
  }

  copyToClipboard(textToCopy: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showSnackBar('In Zwischenablage kopiert');
  }

  getPositionImagePath(positionId): string {
    let number = positionId % 11
    switch (number) {
        // case 0: return '/assets/images/company_default/0_b.jpg';
        // case 1: return '/assets/images/company_default/1_b.jpg';
        // case 2: return '/assets/images/company_default/2_b.jpg';
        // case 3: return '/assets/images/company_default/3_b.jpg';
        // case 4: return '/assets/images/company_default/4_b.jpg';
        // case 5: return '/assets/images/company_default/5_b.jpg';
        // case 6: return '/assets/images/company_default/6_b.jpg';
        // case 7: return '/assets/images/company_default/7_b.jpg';
        // case 8: return '/assets/images/company_default/8_b.jpg';
        // case 9: return '/assets/images/company_default/9_b.jpg';
        // case 10: return '/assets/images/company_default/10_b.jpg';
      default:
        return '/assets/images/company_default/test.jpg'
        // default: return '/assets/images/TA_KeinLogo3.2.jpg'
    }
  }

}
