
<app-co-step-headline >Wobei darf dich dein Karrierecoach unterstützen? 🚀</app-co-step-headline>
<div *ngIf="availableCoachingTypes" class="content" fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="coachingsForm" fxLayout="column">
        <mat-checkbox *ngFor="let coaching of availableCoachingTypes"
                      [formControlName]="coaching.title">{{coaching.title}}
            <i class="mdi mdi-information-outline" matTooltip="{{coaching.description}}"></i>
        </mat-checkbox>
    </form>

    <p class="hint" style="font-size: 12px; margin-top: 35px">
        *Unsere Karrierecoaches sind Berater unseres Partnerunternehmens
        <span class="mlp-hover" matTooltipClass="custom-tooltip" matTooltip="MLP ist Ansprechpartner in allen Karriere- und Finanzfragen und auf die Unterstützung von Akademikern spezialisiert. Für unsere Plattform stellt MLP exklusiv geschulte Berater zur Verfügung, die dich bei Fragen rund um den Jobeinstieg unterstützen können.">
            <span>MLP <i class="mdi mdi-information"></i></span>
            </span>
    </p>
    <span class="hint"  style="font-size: 12px; text-align: center">Bei Inanspruchnahme der Leistung werden deine Daten zum Zwecke der Kontaktaufnahme und zur Durchführung der Leistung an einen Coach weitergegeben. Darüber hinaus willigst du ein, dass der Coach Zugriff auf die von dir auf talentagent.de eingegebenen Daten erhält.
        Diese Einwilligung zum Zugriff kannst du jederzeit widerrufen. Möchtest du <span style="text-decoration: underline; font-size: 12px">keine</span>
        Einwilligung erteilen bzw. keine Coaching-Leistung in Anspruch nehmen,
        überspringe diesen Schritt. Alles Weitere zur Datenverarbeitung durch den Karrierecoach findest du in unserer
        <a class="font-size-12" [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
        </span>
</div>

<app-co-button-bar [skipButton]="false" [backButton]="true" [buttonLockSubject]="progessingSubject" (pressedNext)="progress()"></app-co-button-bar>
