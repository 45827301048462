import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from "@angular/core";

@Injectable()
export class MatPaginatorIntlGer extends MatPaginatorIntl {

  itemsPerPageLabel = 'Einträge pro Seite:';
  nextPageLabel = 'nächste Seite';
  previousPageLabel = 'vorherige Seite';
  firstPageLabel = 'erste Seite';
  lastPageLabel = 'letzte Seite';

  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }

    length = Math.max(length, 0);
    const
      startIndex = page * pageSize;
    const
      endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };
}


