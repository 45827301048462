import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {
    TalentApplicationData,
  TalentApplicationResource,
  TalentTalentProfileResource
} from 'utility';
import {FormControl} from '@angular/forms';
import {FileService} from '../../services/file.service';

@Component({
  selector: 'app-add-cv-dialog',
  templateUrl: './add-cv-dialog.component.html',
  styleUrls: ['./add-cv-dialog.component.scss']
})
export class AddCvDialogComponent implements OnInit {

  drafts: TalentApplicationData[];

  readonly file = new FormControl<File[]>(null, this.fileService.uploadFileControlValidator());

  saving = false;
  fileIsWordDocument: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddCvDialogComponent>,
    public fileService: FileService,
    private talentResource: TalentTalentProfileResource,
    private applicationResource: TalentApplicationResource,
  ) {
  }

  ngOnInit() {
    this.applicationResource.getApplicationsByStates({states: ["Draft"]}).then(applications => {
      this.drafts = applications
    })
  }

  isValidFile() {
    return this.file.valid == true && !this.fileIsWordDocument;
  }

  save() {
    if (!this.file.value[0]) return

    this.saving = true;

    this.fileService.uploadCV(this.file.value[0]).subscribe(
      () => {
        this.dialogRef.close(true);
        this.saving = false;
      }
    )
  }

  close() {
    this.dialogRef.close(false);
  }

  getFileName() { return this.file.value != null ? this.file.value[0].name : 'Datei wählen'; }
}
