import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, DownloadTokenInfoData, ValidatePhoneNumberInfoData, ValidatePhoneNumberProcessData, EnterOneClickSignupData, SignupCodeFeedbackData, UpdateRegistrationStateFeedbackData, TokenState} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.ActionLinkAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class ActionLinkAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/actions/updateRegistrationState/cancel',
        endpoint: 'de.syntacton.cronos'
    })
    private _cancelRegistration: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, null, UpdateRegistrationStateFeedbackData> | undefined
    cancelRegistration(requestBody: JsonWrappedValue<string>): Promise<UpdateRegistrationStateFeedbackData> {
        if (!this._cancelRegistration) throw new Error("resource has not been properly initialized")
        return this._cancelRegistration(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/actions/updateRegistrationState/confirm',
        endpoint: 'de.syntacton.cronos'
    })
    private _confirmRegistration: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, null, UpdateRegistrationStateFeedbackData> | undefined
    confirmRegistration(requestBody: JsonWrappedValue<string>): Promise<UpdateRegistrationStateFeedbackData> {
        if (!this._confirmRegistration) throw new Error("resource has not been properly initialized")
        return this._confirmRegistration(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actions/enterOneClickSignup',
        endpoint: 'de.syntacton.cronos'
    })
    private _enterPromotionByOneClickSignup: IResourceMethodObservableStrict<EnterOneClickSignupData, null, null, TokenState> | undefined
    enterPromotionByOneClickSignup(requestBody: EnterOneClickSignupData): Promise<TokenState> {
        if (!this._enterPromotionByOneClickSignup) throw new Error("resource has not been properly initialized")
        return this._enterPromotionByOneClickSignup(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/actions/enterToken',
        endpoint: 'de.syntacton.cronos'
    })
    private _getEnterPromotionTokenInfo: IResourceMethodObservableStrict<null, {code: string}, null, SignupCodeFeedbackData> | undefined
    getEnterPromotionTokenInfo(requestParams: {code: string}): Promise<SignupCodeFeedbackData> {
        if (!this._getEnterPromotionTokenInfo) throw new Error("resource has not been properly initialized")
        return this._getEnterPromotionTokenInfo(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/download/{code}/content',
        endpoint: 'de.syntacton.cronos'
    })
    private _getGiveawayContent: IResourceMethodObservableStrict<null, null, {code: string}, Blob> | undefined
    getGiveawayContent(code: string): Promise<Blob> {
        if (!this._getGiveawayContent) throw new Error("resource has not been properly initialized")
        return this._getGiveawayContent(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/download/{code}/info',
        endpoint: 'de.syntacton.cronos'
    })
    private _getGiveawayInfo: IResourceMethodObservableStrict<null, null, {code: string}, DownloadTokenInfoData> | undefined
    getGiveawayInfo(code: string): Promise<DownloadTokenInfoData> {
        if (!this._getGiveawayInfo) throw new Error("resource has not been properly initialized")
        return this._getGiveawayInfo(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/actions/promotioninfo',
        endpoint: 'de.syntacton.cronos'
    })
    private _getValidationInfoByCode: IResourceMethodObservableStrict<null, {code: string}, null, ValidatePhoneNumberInfoData> | undefined
    getValidationInfoByCode(requestParams: {code: string}): Promise<ValidatePhoneNumberInfoData> {
        if (!this._getValidationInfoByCode) throw new Error("resource has not been properly initialized")
        return this._getValidationInfoByCode(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actios/validatePhoneNumber/assignToProm',
        endpoint: 'de.syntacton.cronos'
    })
    private _validatePhoneNumberByAssignToPromotionCode: IResourceMethodObservableStrict<null, {code: string, authCode: string}, null, ValidatePhoneNumberProcessData> | undefined
    validatePhoneNumberByAssignToPromotionCode(requestParams: {code: string, authCode: string}): Promise<ValidatePhoneNumberProcessData> {
        if (!this._validatePhoneNumberByAssignToPromotionCode) throw new Error("resource has not been properly initialized")
        return this._validatePhoneNumberByAssignToPromotionCode(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actions/validatePhoneNumber/promRegState',
        endpoint: 'de.syntacton.cronos'
    })
    private _validatePhoneNumberByPromRegStateCode: IResourceMethodObservableStrict<null, {code: string, authCode: string}, null, ValidatePhoneNumberProcessData> | undefined
    validatePhoneNumberByPromRegStateCode(requestParams: {code: string, authCode: string}): Promise<ValidatePhoneNumberProcessData> {
        if (!this._validatePhoneNumberByPromRegStateCode) throw new Error("resource has not been properly initialized")
        return this._validatePhoneNumberByPromRegStateCode(null, requestParams, null).toPromise()
    }

}