import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() loadingVar: Boolean;
  @Input() mode: 'default' | 'skeleton' = 'default';
  @Input() activateBackdrop: Boolean = true
  @Input() color: string = '#ff9800'

  constructor() {
  }

  ngOnInit() {
  }

}
