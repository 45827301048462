<div id="mobile-menu" fxHide.gt-sm [ngClass]="{'mobile-menu-ios': mediaService.isIOs()}"
     fxLayout="row"
     fxLayoutAlign="space-evenly center">

    <div *ngIf="showSubNav" fxLayout="column" fxLayoutAlign="end center" class="sub-nav-wrapper" fxLayoutGap="20px">
<!--        <div  [ngClass]="{'active' : router.url.includes('/klarsolar')}"-->
<!--              fxLayout="column"-->
<!--              fxLayoutAlign="end center"-->
<!--              class="sub-nav-element"-->
<!--              (click)="routeTo('/klarsolar')">-->
<!--            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>-->
<!--                <i class="mdi mdi-24px mdi-leaf"></i>-->
<!--            </div>-->
<!--            <div class="menu-item-text">-->
<!--                Klarsolar-->
<!--            </div>-->
<!--        </div>-->

        <div  [ngClass]="{'active' : router.url.includes('/event')}"
              fxLayout="column"
              fxLayoutAlign="end center"
              class="sub-nav-element"
              (click)="routeTo('/events')">
            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>
                <i class="mdi mdi-24px mdi-account-group"></i>
            </div>
            <div class="menu-item-text">
                Events & Messen
            </div>
        </div>

        <div  [ngClass]="{'active' : router.url.includes('/company-overview')}"
              fxLayout="column"
              fxLayoutAlign="end center"
              class="sub-nav-element"
              (click)="routeTo('/company-overview')">
            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>
                <i class="mdi mdi-24px mdi-office-building"></i>
            </div>
            <div class="menu-item-text">
                Unternehmensprofile
            </div>
        </div>

        <div  [ngClass]="{'active' : router.url.includes('/talent/profile')}"
              fxLayout="column"
              fxLayoutAlign="end center"
              class="sub-nav-element"
              (click)="routeTo('/talent/profile')">
            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>
                <i class="mdi mdi-24px mdi-file-account"></i>
            </div>
            <div class="menu-item-text">
                Profil und CV
            </div>
        </div>

        <div  [ngClass]="{'active': (router.url.includes('/talent/applications'))}"
              fxLayout="column"
              fxLayoutAlign="end center"
              class="sub-nav-element"
              (click)="routeTo('/talent/applications')">
            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>
                <i class="mdi mdi-24px mdi-file-move"></i>
            </div>
            <div class="menu-item-text">
                Bewerbungen
            </div>
        </div>

        <div  [ngClass]="{'active': (router.url.includes('/talent/agent'))}"
              fxLayout="column"
              fxLayoutAlign="end center"
              class="sub-nav-element"
              (click)="routeTo('/talent/agent')">
            <div class="ta-round-drop" fxLayout="row" fxLayoutAlign="center center" matRipple>
                <i class="mdi mdi-24px mdi-account-child"></i>
            </div>
            <div class="menu-item-text">
                Karrierecoach
            </div>
        </div>
    </div>

    <div [ngClass]="{'active': router.url === '/home'}"
         class="mobile-menu-item"
         fxLayout="column"
         fxLayoutAlign="end center"
         routerLink="/home"
         matRipple>
        <i class="mdi mdi-home mobile-menu-icon"></i>
        <div class="menu-item-text">
            Home
        </div>
    </div>

    <div id="mobile-menu-positions" [ngClass]="{'active': (router.url === '/talent/proposals/recommendations') && !showSubNav }"
         class="mobile-menu-item" fxLayout="column"
         fxLayoutAlign="end center"
         routerLink="/talent/proposals/recommendations"
         matRipple>
            <i class="mdi mdi-account-star-outline mobile-menu-icon"
               [matBadge]="propagationService.numRecommendedProposals == 0 ? '' : propagationService.numRecommendedProposals"
               matBadgeColor="accent"
               matBadgeSize="small"
               [matBadgeOverlap]="true"></i>
        <div class="menu-item-text">
            Empfehlungen
        </div>
    </div>


    <div [ngClass]="{'active': router.url === '/talent/positions'}"
         class="mobile-menu-item"
         fxLayout="column"
         fxLayoutAlign="end center"
         routerLink="/talent/positions"
         matRipple>
        <i class="mdi mdi-magnify mobile-menu-icon"></i>
        <div class="menu-item-text">Stellen</div>
    </div>

    <div [ngClass]="{'active': router.url === '/talent/proposals/bookmarks'}"
         class="mobile-menu-item"
         fxLayout="column"
         fxLayoutAlign="end center"
         routerLink="/talent/proposals/bookmarks"
         matRipple>
        <i class="mdi mdi-heart mobile-menu-icon"
           [matBadge]="propagationService.numBookmarkedProposals == 0 ? '' : propagationService.numBookmarkedProposals"
           matBadgeColor="accent"
           matBadgeSize="small"
           [matBadgeOverlap]="true"></i>
        <div class="menu-item-text">Merkliste</div>
    </div>

    <div class="mobile-menu-item"
         fxLayout="column"
         [ngClass]="{'active': showSubNav}"
         fxLayoutAlign="center center"
         style="padding-bottom: 0; width: 12%"
         (click)="toggleSubMenu()"
         matRipple>
        <i class="mdi mdi-24px mdi-dots-horizontal mobile-menu-icon"></i>
    </div>
</div>

<div class="overlay" *ngIf="showSubNav">
</div>
