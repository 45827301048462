import {Component, Input, OnInit} from '@angular/core';
import {AddressData, LabelData, PublicEventCompanyData} from "utility";

@Component({
  selector: 'company-preview-list',
  templateUrl: './company-preview-list.component.html',
  styleUrls: ['./company-preview-list.component.scss']
})
export class CompanyPreviewListComponent implements OnInit {

  @Input() companies: PublicEventCompanyData[]
  constructor(
  ) { }

  ngOnInit(): void {
  }

  getAddressText(data: AddressData): string {
    return `Adresse: ${data.streetName??''} ${data.streetNumber??''}, ${data.zipCode??''} ${data.cityName??''} `
  }

}
