import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rocket-animation',
  templateUrl: './rocket-animation.component.html',
  styleUrls: ['./rocket-animation.component.scss']
})
export class RocketAnimationComponent implements OnInit {
  rotateRocket: number = 30

  constructor() {
    let pi = Math.PI
   this.rotateRocket = 90-(Math.atan(window.innerHeight/window.innerWidth) * (180/pi))
  }


  ngOnInit(): void {
  }

}
