import { Component, OnInit } from '@angular/core';
import {ShareService} from "../../../services/share.service";

@Component({
  selector: 'share-home-mobile',
  templateUrl: './share-home-mobile.component.html',
  styleUrls: ['./share-home-mobile.component.scss']
})
export class ShareHomeMobileComponent implements OnInit {

  constructor(
      private shareService: ShareService
  ) { }

  ngOnInit(): void {
  }

  openShareOverlay() {
    this.shareService.showSharingButtons('Hey, schau dir mal Talentagent an: ', '?utm_source=talentagent-share&utm_medium=website-share&utm_campaign=dashboard-share')
  }
}
