import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CVParsingOButtonBarComponent} from "./cv-parsing-o-button-bar/cv-parsing-o-button-bar.component";
import { CVParsingCoachComponent } from './cv-parsing-o-coach/cv-parsing-o-coach.component';
import { CVParsingOCoachDataComponent } from './cv-parsing-o-coach-data/cv-parsing-o-coach-data.component';
import { CVParsingOContactDataComponent } from './cv-parsing-o-contact-data/cv-parsing-o-contact-data.component';
import { CVParsingOMatchingDataComponent } from './cv-parsing-o-matching-data/cv-parsing-o-matching-data.component';
import { CVParsingOPasswordComponent } from './cv-parsing-o-password/cv-parsing-o-password.component';
import { CVParsingOSelectSubjectComponent } from './cv-parsing-o-select-subject/cv-parsing-o-select-subject.component';
import { CVParsingOWelcomeComponent } from './cv-parsing-o-welcome/cv-parsing-o-welcome.component';
import { CVParsingOEveProgressComponent } from './cv-parsing-o-eve-progress/cv-parsing-o-eve-progress.component';
import { CVParsingOStepHeadlineComponent } from './cv-parsing-o-step-headline/cv-parsing-o-step-headline.component';
import { CVParsingOnboardingComponent } from './cv-parsing-onboarding/cv-parsing-onboarding.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {UtilityModule} from "utility";
import {RouterModule, Routes} from "@angular/router";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import {
  CVParsingOStudySearchOnboardingComponent
} from "./cv-parsing-o-select-subject/cv-parsing-o-study-search-onboarding/c-v-parsing-o-study-search-onboarding.component";


const appRoutes: Routes = [
    {
  path: 'onboarding/cvp', component: CVParsingOnboardingComponent, canActivate: [OnboardingAuthGuardService],
  children: [
    {path: 'cv-welcome', component: CVParsingOWelcomeComponent},
    {path: 'subject', component: CVParsingOSelectSubjectComponent},
    {path: 'preferences', component: CVParsingOMatchingDataComponent},
    {path: 'contact-data', component: CVParsingOContactDataComponent},
    {path: 'coach-intent', component: CVParsingCoachComponent},
    {path: 'coach-data', component: CVParsingOCoachDataComponent},
    {path: 'password', component: CVParsingOPasswordComponent}
  ]
}];

@NgModule({
  declarations: [

    CVParsingOButtonBarComponent,
    CVParsingCoachComponent,
    CVParsingOCoachDataComponent,
    CVParsingOContactDataComponent,
    CVParsingOMatchingDataComponent,
    CVParsingOPasswordComponent,
    CVParsingOSelectSubjectComponent,
    CVParsingOWelcomeComponent,
    CVParsingOEveProgressComponent,
    CVParsingOStepHeadlineComponent,
    CVParsingOnboardingComponent,
    CVParsingOStudySearchOnboardingComponent
  ],
    exports: [
        CVParsingOEveProgressComponent,
        CVParsingOStepHeadlineComponent
    ],
  imports: [
    MatDialogModule,
    CommonModule,
    UtilityModule,
    RouterModule.forChild(appRoutes),
    HidLibCloneModule
  ]
})
export class CVParsingOnboardingModule { }
