<div style="margin-bottom: 3rem" class="flex row gap-sm center">
    <button mat-icon-button [disabled]="page == 0 || disabled" (click)="selectPage(page - 1 )">
        <i class="mdi mdi-chevron-left"></i>
    </button>

    <div class="flex row center gap-sm" [ngClass]="{'disabled-numbers': disabled}">
        <div class="paginator-number" (click)="selectPage(0)" *ngIf="page > 1 && totalPages > 3">
            1
        </div>
        <div *ngIf="page > 2 && totalPages > 4">...</div>


        <div class="paginator-number" (click)="selectPage(page - 2)" *ngIf="page > totalPages - 2 && totalPages > 2">
            {{ page - 1 }}
        </div>
        <div class="paginator-number" (click)="selectPage(page - 1)" *ngIf="page > 0 && totalPages > 1">
            {{ page }}
        </div>
        <div class="paginator-number selected-page">
            {{ page + 1 }}
        </div>
        <div class="paginator-number" (click)="selectPage(page + 1)" *ngIf="page + 1 < totalPages && totalPages > 1">
            {{ page + 2 }}
        </div>
        <div class="paginator-number" (click)="selectPage(page + 2)" *ngIf="page < 1 && totalPages > 2">
            {{ page + 3 }}
        </div>

        <div *ngIf="page < totalPages - 3 && totalPages > 4">...</div>
        <div class="paginator-number" (click)="selectPage(totalPages - 1)" *ngIf="page < totalPages - 2 && totalPages > 3">
            {{ totalPages }}
        </div>
    </div>

    <button mat-icon-button [disabled]="page + 1 == totalPages || disabled" (click)="selectPage(page + 1)">
        <i class="mdi mdi-chevron-right"></i>
    </button>
</div>
