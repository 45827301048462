<div fxLayout="row" fxLayoutAlign=" center">
    <div class="country-select-wrapper">
        <button
                (click)="handleCountryCodeDropdown()"
                class="button-country-code">
            <label>{{label}}</label>
            <span class="button-text"> <span style="margin-right: 3px;" class="fi fi-{{this.selectedEUCountryCode}}"></span>{{" +" + this.phoneUtil.getCountryCodeForRegion(this.selectedEUCountryCode)}}</span>
            <i class="mdi mdi-chevron-down button-text"></i>
        </button>

        <div *ngIf="showCountryCodeDropdown"
             (clickOutside)="onClickOutside($event)"
             fxLayout="column" fxLayoutAlign="start start"
             class="phone-number-pre-select">
            <div  *ngFor="let country of euCountries"
                  (click)="onClickCode(country.code)"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="phone-number-option-wrapper">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span style="margin-right: 10px;" class="fi fi-{{country.code}}"></span>
                    {{country.name}} ({{country.code.toUpperCase()}})
                </div>
                <p style="margin: 0">{{" +" + this.phoneUtil.getCountryCodeForRegion(country.code)}}</p></div>
        </div>
    </div>

    <hid-input-field [forceValidation]="forceValidation" [showValidatorIcons]="showValidatorIcons" [showClearButton]="showClearButton"
                     [inputFieldClass]="inputFieldClass + ' phone-number-input'"
                     class="hid-input-field-position"
                     name={{name}} type={{type}} [control]="subControl"
                     [disabled]="disabled"></hid-input-field>
</div>
