<dialog-header [headline]="data.title || 'Bild festlegen'"></dialog-header>

<mat-dialog-content fxLayout="column">

  <div class="width-100" fxLayout="row" fxLayoutAlign="center start">
    <img  *ngIf="!imageFileName" class="picture-preview" src="../../assets/images/blurred_user.png" style="border-radius: 100%">
    <div class="picture-preview">
      <image-cropper class="image-cropper"
                     *ngIf="imageFileName"
                     [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="data.aspectRatio"
                     format="png"
                     [roundCropper]="data.roundCropping"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded()"
                     (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </div>


  <div fxLayout="column" fxLayoutAlign="start center" style="margin: 20px 0">
    <label for="image-input" class="hid-button primary" *ngIf="imageFileName">
      <i class="mdi mdi-pencil"></i>
      {{imageFileName}}
    </label>
    <label for="image-input" class="hid-button primary" *ngIf="!imageFileName">
      <i class="mdi mdi-upload"></i>
      Bitte auswählen
    </label>

    <input type="file" style="visibility: hidden; width: 0" id="image-input" [formControl]="file"
           accept="image/png, image/jpeg, image/jpg, image/gif"
           (change)="fileChangeEvent($event)"/>
    <div>
      <small style="color: red" *ngIf="file.errors != null && file.errors['sizeTooBig']">Die Datei ist größer als 10MB,
        Bitte wähle ein kleineres Bild
      </small>
    </div>

    <div>
      <small style="color: red" *ngIf="file.errors != null && file.errors['wrongExtension']">
        Falsche Dateiendung. Bitte wähle ein Bild mit .jpg, .gif oder .png als Format.
      </small>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="primary-text-color button-bar">
  <button mat-button (click)="close()">Abbrechen</button>
  <button mat-flat-button color="accent"
          (click)="finish()"
          [disabled]="file.invalid"
          [loadingBool]="saving">Speichern
  </button>
</mat-dialog-actions>
