import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {delay} from "../../services/inno-utils/general-utils.service";
import {CityData, LocationResource} from "utility";
import {MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";
import {debounceTime} from "rxjs/operators";
import {MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";
import {MatLegacyInput as MatInput} from "@angular/material/legacy-input";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
    selector: 'location-autocomplete-input',
    templateUrl: './location-autocomplete-input.component.html',
    styleUrls: ['./location-autocomplete-input.component.scss']
})
export class LocationAutocompleteInputComponent implements OnInit {

    @Input() initialValue: string = null;
    @Input() label: string;
    @Input() displayProperty: string;
    @Input() showValidatorIcons: boolean;
    @Input() forceValidation: Subject<boolean>;
    @Input() disabled: boolean = false;
    @Input() mode: string = 'Normal';
    @Input() autocomplete: boolean = false;

    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Output() openingAttempted: EventEmitter<number> = new EventEmitter();

    @ViewChild('triggerInput') triggerInput: MatInput
    @ViewChild('auto') autoComplete: MatAutocomplete

    searchControl = new UntypedFormControl(null)

    focusInputSubject: Subject<boolean> = new Subject<boolean>()

    options = [];
    oldControlValue: any;

    loadingOptions: boolean;
    showSmallLoadingSpinner: boolean = false;

    clickedOption: string = '';
    requestIndex: number = 0;

    constructor(private locationResource: LocationResource) {
    }

    ngOnInit() {

        if (this.initialValue != null) this.searchControl.setValue(this.initialValue);

        this.oldControlValue = this.searchControl.value;

        this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(event => {
            if (this.oldControlValue == event) return
            if (this.clickedOption == this.searchControl.value) return;

            this.showSmallLoadingSpinner = true;
            this.oldControlValue = event;
            this.loadingOptions = true;

            let localRequestIndex = ++this.requestIndex;
            this.loadSuggestions().then(() => {
                if (localRequestIndex != this.requestIndex) {
                    this.showSmallLoadingSpinner = false;
                    return
                }
                if (this.options.length == 0) {
                    this.autoComplete.showPanel = false
                } else if (this.searchControl.value) {
                    this.autoComplete.showPanel = true;
                }

                this.showSmallLoadingSpinner = false;
            });

        });

        if (this.displayProperty == null) this.displayProperty = 'name';
    }

    openMenuByFocusId() {
        if (!this.searchControl.value) return
        let optionsTmp = [...this.options]
        this.options = []
        delay(100).then(() => {
            this.autoComplete.showPanel = true
            this.options = optionsTmp
        })
    }

    async loadSuggestions() {
        this.options = [];
        await this.locationResource.getCitiesByQueryOrZipCode({
            query: this.searchControl.value,
            page: 0,
            pageSize: 10,
        }).then(result => {
            if ('content' in result) {
                for (let o of result.content) {
                    this.options.push(o);
                }
            }
            this.loadingOptions = false;
        });
    }


    setModel(option: CityData, event: any | null = null) {

        event?.stopPropagation()

        if (!option[this.displayProperty]) console.warn('Display property unknown');

        this.showSmallLoadingSpinner = false;
        this.clickedOption = option[this.displayProperty];

        this.searchControl.setValue(option[this.displayProperty]);

        if (this.mode == 'Object') {
            this.modelChange.emit(option)
        } else {
            this.modelChange.emit(option.id)
        }
        this.autoComplete.showPanel = false
        if (this.autocomplete) {
            this.searchControl.setValue(option.name)
        } else {
            this.searchControl.setValue("")
        }
        this.showSmallLoadingSpinner = false;
    }
}
