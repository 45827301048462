<div mat-dialog-title fxLayout="row" fxLayoutAlign="end center" style="z-index: 1">
    <i class="mdi mdi-close mdi-24px white" matTooltip="Abbrechen" mat-dialog-close></i>
</div>

<div class="redirect-content" fxLayout="column" fxLayoutAlign="start center" [ngClass]="{'on-ios': mediaService.isIOs()}">
    <div class="headline">Wir haben dir einen neuen Vorschlag gemacht!</div>

    <div class="text">Hey {{talentFirstName}}! Wir haben passende Stelle für dich gefunden, schaue sie dir doch gleich mal an.</div>
</div>

<mat-dialog-actions align="center" style="padding-bottom: 30px">
    <button style="background-color: white; color: var(--light-orange)" mat-flat-button color="primary"
            (click)="navigateToRecommendation()">
        Zu meinem Vorschlag
    </button>
</mat-dialog-actions>
