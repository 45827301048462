import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from "../../services/search.service";

@Component({
  selector: 'app-positions-search-options',
  templateUrl: './positions-search-options.component.html',
  styleUrls: ['./positions-search-options.component.scss']
})
export class PositionsSearchOptionsComponent implements OnInit {

  @Input() minimalMobileView: boolean = false

  constructor(
      private searchService: SearchService
  ) { }

  ngOnInit(): void {
  }

  clearFilterAndSearch(){
    this.searchService.clearFilterAndSearch()
  }
}
