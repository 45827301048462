import {Component, Input, OnInit} from '@angular/core';
import {CompanyData, TalentCompanyDisplayData} from "utility";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-company-overview-card',
  templateUrl: './company-overview-card.component.html',
  styleUrls: ['./company-overview-card.component.scss']
})
export class CompanyOverviewCardComponent implements OnInit {

  @Input() companyData: TalentCompanyDisplayData
  primeCompany: boolean = false
  environmentVar

  constructor(
      private router: Router
  ) {
    this.environmentVar = environment
  }

  ngOnInit(): void {
    this.primeCompany = this.companyData.primeCompany
  }

  navigateToCompanyPage(companyId: number){
    this.router.navigateByUrl('/company-profile/' + companyId)
  }
}
