import {Component, forwardRef, OnInit} from '@angular/core';
import {TalentApplicationMinimalData, TalentHomeResource} from "utility";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";

@Component({
  selector: 'applications-home-desktop',
  templateUrl: './applications-home-desktop.component.html',
  styleUrls: ['./applications-home-desktop.component.scss']
})
export class ApplicationsHomeDesktopComponent implements OnInit {

  loadingSubject = new BehaviorSubject<any>(true)
  applications: TalentApplicationMinimalData[] = []

  constructor(
      private talentHomeResource: TalentHomeResource,
      private homeService: HomeService,
      public router: Router
  ) {
  }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getApplications()
  }

  getApplications(): Promise<any> {
    this.loadingSubject.next(true)
    // return new Promise<any>(resolve => {
    //   setTimeout(() => {
    //     this.loadingSubject.next(false)
    //     resolve(true)
    //   }, 20000)
    // })
    return this.talentHomeResource.getApplicationsForDashboard().then(applications => {
      this.applications = applications
      this.loadingSubject.next(false)
    })
  }

}
