<div class="rocket-animation">
    <div [ngStyle]="{'transform': 'rotate(' + rotateRocket + 'deg)'}" class="rocket-wrapper">
        <img class="rocket" src="../assets/images/BookmarkedCelebration/RocketFilledFlame.svg">
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
        <div class="blast-wrapper">
            <div class="rocket-blast"></div>
        </div>
    </div>
</div>
