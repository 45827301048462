<div mat-dialog-title>Bewerbung bestätigen</div>
<div mat-dialog-content >
    <div fxLayout="column" *ngIf="!confirmed">
    <div style="font-weight: bold">{{report.relation.employmentPosition.title}}</div>
    <div>Hast du dich für diese Stelle beworben?</div>
</div>
    <application-processbar class="layout-padding" *ngIf="confirmed"></application-processbar>
</div>

<div mat-dialog-actions fxLayout="row" [fxLayoutAlign]="mediaService.isMobile() ? 'center center' : 'end center'" fxLayoutGap="10px" style="margin-top: 20px; margin-bottom: 5px">
    <button *ngIf="!confirmed" mat-dialog-close mat-button>Nein, ich wollte doch nicht</button>

    <button mat-flat-button color="accent"
            (click)="confirm()"
            *ngIf="!confirmed"
            [loadingBool]="confirming">Ja, ich habe mich beworben
    </button>

    <button mat-flat-button color="accent" *ngIf="confirmed" (click)="close()">Weiter gehts!</button>
</div>
