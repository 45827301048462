import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-positions-previews-paginator',
  templateUrl: './positions-previews-paginator.component.html',
  styleUrls: ['./positions-previews-paginator.component.scss']
})
export class PositionsPreviewsPaginatorComponent implements OnInit, OnDestroy{

  @Input() page: number
  @Input() totalPages: number = 0
  @Input() disabled: boolean = false
  @Output() pageIndex: EventEmitter<number> = new EventEmitter<number>()


  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  selectPage(page: number) {
    this.page = page
    this.pageIndex.emit(page)
  }

}
