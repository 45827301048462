import {Component} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {TalentEventResource} from "utility";

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss']
})
export class EventOverviewComponent {
  constructor(
    private sanitizer: DomSanitizer,
    talentEventResource: TalentEventResource,
  ) {
    talentEventResource.getStudyArea().then((studyArea: string | undefined) => {
      if (studyArea) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://www.careerfairy.io/next-livestreams/partnership/uniwunder?studyArea=" + studyArea
        );
      } else {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.careerfairy.io/next-livestreams/partnership/uniwunder");
      }
    });
  }

  url = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.careerfairy.io/next-livestreams/partnership/uniwunder");
}
