import {Injectable} from '@angular/core';
import {TalentAuthService} from "./talent.auth.service";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OnboardingAuthGuardService implements CanActivate {

    constructor(
        public auth: TalentAuthService,
        public router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        let resolvedRoute = route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
        return new Observable<boolean>((resolve) => {
            if (!this.auth.isAuthenticated()) {
                resolve.next(true)
                resolve.complete()
            } else {
                this.auth.reloadAccountInfo().then(() => {
                    let accInfo = this.auth.getAccountInfo()
                    if (accInfo?.onboardingCompleted && accInfo?.hasPlatformVisited) {
                        this.router.navigate(['home']);
                        resolve.next(false);
                    } else if (
                        ['/onboarding/coach/welcome',
                            '/onboarding/coach/coach-intent',
                            '/onboarding/coach/coach-data',
                            '/onboarding/coach/contact-data',
                            '/onboarding/coach/cv'].includes(resolvedRoute)
                ) {
                        this.router.navigate(['onboarding/coach/obState']);
                        resolve.next(false)
                    }
                else
                    {
                        resolve.next(true)
                    }
                    resolve.complete()
                })
            }

        })
    }


}
