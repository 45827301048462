<div class="stepper-small">
    <div fxLayout="row" fxLayoutAlign="center center" class="progress-list">
        <div *ngFor="let _ of [].constructor(totalsteps / 2), let i = index">
            <div class="circle"
                 [ngClass]="{'active': i + 1 <= currentstep}"></div>
        </div>

        <img src="../../../../assets/images/eve-dropshadow.svg" class="eve-image">

        <div *ngFor="let _ of [].constructor(totalsteps / 2), let i = index">
            <div  class="circle"
                  [ngClass]="{'active': i + 1 + totalsteps / 2 <= currentstep}"></div>
        </div>
    </div>
</div>