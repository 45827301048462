<div class="dialog-container" fxLayout="column" fxLayoutAlign="start center" *ngIf="availableCoachingTypes">
    <div class="close-button">
        <i class="mdi mdi-close mdi-24px clickable" matTooltip="Abbrechen" (click)="dialogRef.close(false)"></i>
    </div>

    <div class="content-wrapper" fxLayout="column" fxLayoutAlign="start center">
        <div class="header" fxLayout="column" fxLayoutAlign="center center">
            <div class="headline">Dein persönlicher Karrierecoach</div>
        </div>

        <div class="triangle-down"></div>

        <mat-stepper linear #stepper>
            <mat-step>
                <div fxLayout="column" [ngClass]="{'hidden': loadingMissingCoachDistributionData}">
                    <p style="color: grey">Damit wir einen passenden Karrierecoach für dich finden können, brauchen wir noch ein paar Informationen von dir.</p>

                    <div *ngIf="coachDistributionData && coachDistributionData.talentCityId == null">
                        <h4>In welcher Stadt wohnst du?</h4>

                        <location-autocomplete-input
                                [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                                style="width: 100%"
                                [autocomplete]="true"
                                label="Name oder PLZ"
                                displayProperty="name"
                                (modelChange)="distributionCriteriaForm.controls['locationControl'].setValue($event)">
                        </location-autocomplete-input>

                        <small style="color: red; font-size: 12px"
                               *ngIf="distributionCriteriaForm.controls['locationControl'].errors && showErrors">
                            Bitte wähle deinen Wohnort aus, um fortzufahren.
                        </small>
                    </div>

                    <div *ngIf="coachDistributionData && coachDistributionData.germanSkillLevel == null">
                        <h4>Welche Staatsbürgerschaft hast du?</h4>

                        <div class="m-bottom15 font-size-11">(Diese Frage ist wichtig für das Arbeitsrecht)</div>

                        <div class="width-100 nationality-wrapper" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
                            <div (click)="selectNationality('German')"
                                 [ngClass]="{'selected': distributionCriteriaForm.controls.nationalityControl.value == 'German'}">
                                Deutsch
                            </div>
                            <div (click)="selectNationality('Eu')"
                                 [ngClass]="{'selected': distributionCriteriaForm.controls.nationalityControl.value == 'Eu'}">
                                anderes EU-Land
                            </div>
                            <div (click)="selectNationality('NotEu')"
                                 [ngClass]="{'selected': distributionCriteriaForm.controls.nationalityControl.value == 'NotEu'}">
                                nicht EU-Bürger
                            </div>
                        </div>

                        <small style="color: red; font-size: 12px"
                               *ngIf="distributionCriteriaForm.controls['nationalityControl'].errors && showErrors">
                            Bitte wähle deine Staatsbürgerschaft aus.
                        </small>

                        <div *ngIf="!['German', 'Unknown'].includes(distributionCriteriaForm.controls.nationalityControl.value)"
                             class="width-100 m-bottom20"
                             [ngClass]="{'mobile-dropdown': mediaService.isMobile()}">
                            <div class="text-center m-top10 m-bottom15">Wie gut sprichst du Deutsch?</div>
                            <hid-dropdown class="width-100"
                                          [control]="distributionCriteriaForm.controls['languageStateControl']"
                                          [mode]="'Object'"
                                          [identifyingProperty]="'value'"
                                          [displayProperty]="'display'"
                                          [options]="skillLevel" label="Wähle ein Level aus...">
                            </hid-dropdown>
                            <small style="color: red; font-size: 12px"
                                   *ngIf="distributionCriteriaForm.controls['languageStateControl'].errors && showErrors">
                                Bitte wähle aus, wie gut du Deutsch sprichst.
                            </small>
                        </div>
                    </div>

                    <div *ngIf="coachDistributionData && coachDistributionData.studyEndDate == null">
                        <h4>Wann schließt du dein aktuelles Studium ab?</h4>
                        <hid-dropdown
                                label="Abschlusszeitpunkt (voraussichtlich)"
                                [options]="endDateDropdownOptions" displayProperty="label" identifyingProperty="option"
                                [control]="distributionCriteriaForm.controls['studiesEndControl']"></hid-dropdown>
                        <small style="color: red; font-size: 12px"
                               *ngIf="distributionCriteriaForm.controls['studiesEndControl'].errors && showErrors">
                            Bitte wähle deinen Aubschlusszeitpunkt aus
                        </small>
                    </div>
                </div>


            </mat-step>
            <mat-step fxLayout="column" fxLayoutAlign="start center">
                <div class="w-100" fxLayout="column" fxLayoutAlign="start center">
                    <h4>Wie soll dich dein Coach unterstützen?</h4>

                    <form [formGroup]="coachingsForm" fxLayout="column">
                        <mat-checkbox *ngFor="let coaching of availableCoachingTypes"
                                      [formControlName]="coaching.title">{{coaching.title}}</mat-checkbox>
                    </form>
                </div>


                <p class="hint" style="font-size: 12px; margin-top: 35px; padding: 0 16px">
                    *Unsere Karrierecoaches sind Berater unseres Partnerunternehmens <span class="mlp-hover"><span>MLP <i
                        class="mdi mdi-information"></i></span>
    <div class="mlp-info">
      MLP ist Ansprechpartner in allen Karriere- und Finanzfragen und auf die Unterstützung von Akademikern spezialisiert. Für unsere Plattform stellt MLP exklusiv geschulte Berater zur Verfügung, die dich bei Fragen rund um den Jobeinstieg unterstützen können.
    </div></span>. Bei Inanspruchnahme der Leistung werden deine Daten zum Zwecke der Kontaktaufnahme und zur Durchführung der Leistung an einen Coach
                    weitergegeben. Darüber hinaus willigst du ein, dass der Coach Zugriff auf die von dir auf talentagent.de eingegebenen Daten erhält.
                    Diese Einwilligung zum Zugriffkannst du jederzeit widerrufen. Möchtest du <span
                        style="text-decoration: underline">keine</span>
                    Einwilligung erteilenbzw. keine Coaching-Leistung in Anspruch nehmen,
                    überspringe diesen Schritt. Alles Weitere zur Datenverarbeitung durch den Karrierecoach findest du in
                    unserer
                    <a class="font-size-12" [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
                </p>
            </mat-step>
        </mat-stepper>

        <div class="actions w-100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            <button mat-button (click)="dialogRef.close(false)">Abbrechen</button>

            <button mat-flat-button color="accent"
                    *ngIf="stepper.selectedIndex == 0"
                    (click)="updateCoachRequestDistributionData()"
                    [loadingBool]="updateCoachRequestDistributionDataLoading">Weiter
            </button>


            <button mat-flat-button color="accent"
                    *ngIf="stepper.selectedIndex == 1"
                    (click)="requestTalentAgent()"
                    id="request-talentagent-btn"
                    [loadingBool]="requestTalentagent">Coach anfordern
            </button>
        </div>
    </div>

</div>

