import {Component, OnInit} from '@angular/core';
import {TalentHomeResource} from "utility";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {HomeService} from "../../home-utils/home.service";
import {PropagationService} from "../../../services/propagation.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'recommendations-home-mobile-big',
  templateUrl: './recommendations-home-mobile-big.component.html',
  styleUrls: ['./recommendations-home-mobile-big.component.scss']
})
export class RecommendationsHomeMobileBigComponent implements OnInit {

  loadingSubject = new BehaviorSubject<boolean>(true)

  constructor(
      private talentHomeResource: TalentHomeResource,
      private homeService: HomeService,
      public router: Router,
      public propagationService: PropagationService,
      public authService: TalentAuthService
  ) { }

  ngOnInit(): void {
    this.homeService.registerSub(this.loadingSubject)
    this.getMatchesCount()
  }

  getMatchesCount() {
    this.loadingSubject.next(true)
    this.propagationService.refreshRecommendedProposalCount().then(() => {
      this.loadingSubject.next(false)
    })
  }

}
