import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {EmailAddressAvailabilityValidatorService, UtilsService} from "utility";
import {CachedOBInputs, S2Fax2OnboardingService} from '../s2-fax2-onboarding.service';
import {UnisignService} from "../../../services/unisign.service";

@Component({
  selector: 'app-s2-fax2-o-contact-data',
  templateUrl: './s2-fax2-o-contact-data.component.html',
  styleUrls: ['./s2-fax2-o-contact-data.component.scss']
})
export class S2Fax2OContactDataComponent implements OnInit {

    public privacyUrl = environment.publicPageUrl + '/privacy'
    public useRegulationsUrl = environment.publicPageUrl + '/use-regulations'
    emailValidationPending = new BehaviorSubject<boolean>(false);

    readonly form = new FormGroup({
        firstName: new FormControl<string>(this.obService.cachedInputs.firstName, [Validators.required, this.obService.noWhitespaceValidator]),
        lastName: new FormControl<string>(this.obService.cachedInputs.lastName, [Validators.required, this.obService.noWhitespaceValidator]),
        acceptPrivacy: new FormControl<boolean>(this.obService.acceptedPrivacy, [Validators.requiredTrue]),
        contactEmailAddress: new FormControl<string>(this.obService.cachedInputs.email, [Validators.required, Validators.email],
            [this.emailAvailabilityValidatorService.createValidator(this.emailValidationPending)]),
        phoneNumber: new FormControl<string>(this.obService.cachedInputs.phoneNumber, [Validators.required]),
    });
    nextStepClicked: boolean = false
    submitFailed: boolean = false

    constructor(
        public obService: S2Fax2OnboardingService,
        public analyticsService: AnalyticsService,
        private utilsService: UtilsService,
        private emailAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
        private unisignService: UnisignService
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    progressPending = false;
    checkError: Subject<any> = new Subject<any>()
    progressingSubject = new Subject();

    progress() {
        this.checkError.next();
        if(!this.form.valid){
            this.progressPending = false;
            this.submitFailed = true
            return
        }

        this.progressingSubject.next()

        this.obService.updateInputs(<CachedOBInputs>{
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            phoneNumber: this.form.controls.phoneNumber.value,
            email: this.form.controls.contactEmailAddress.value
        })

        this.obService.createAccount().then(
            () => {
                //Skip coach step if invited by coach
                if(this.analyticsService.trafficOrigin.invitedFromToken){
                    this.obService.delayedRouting( "/onboarding/s2fax2/password")
                }else{
                    this.obService.delayedRouting("/onboarding/s2fax2/coach-intent")
                }
            }
        )
    }

    forgotPassword() {
        this.unisignService.routeToForgotPassword()
    }
}

