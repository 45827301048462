import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl} from "@angular/forms";

let nextid = 1

@Component({
  selector: 'hid-textarea',
  templateUrl: './hid-textarea.component.html',
  styleUrls: ['./hid-textarea.component.scss']
})
export class HidTextareaComponent implements OnInit {


  @Input() label: string;
  @Input() placeholder: string;
  @Input() model: any;
  @Input() control: AbstractControl;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() show: boolean = true;
  @Input() inputFieldClass: string = '';
  @Input() charCounterShow: boolean = false;

  @Input() minRows: number = 2;
  @Input() maxLength?: number = null;

  //Set true if label has 2 lines
  @Input() wrappedLabel: boolean = false;


  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() inputFocused: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<null> = new EventEmitter();

  dynamicId = "hid-textarea-" + nextid++;
  @ViewChild("labelContainer") labelContainer: ElementRef;
  @ViewChild("textAreaElement") textAreaElement: ElementRef;
  @ViewChild("textAreaElement2") textAreaElement2: ElementRef;

  constructor() {
    if (!this.control) {
      this.control = new UntypedFormControl()
    }

    if (!this.placeholder?.length) this.placeholder = ""

    this.control.valueChanges.subscribe(change => {
      this.emit()
    })
  }

  ngOnInit() {

  }

  setFocusToTextArea() {
    if (this.textAreaElement) this.textAreaElement.nativeElement.focus()
    if (this.textAreaElement2) this.textAreaElement2.nativeElement.focus()
  }


  emit() {
    this.modelChange.emit(this.control.value);
  }


}
