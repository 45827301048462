<div class="main-wrapper">
    <loading-spinner [loadingVar]="loading">
        <div class="aecd-header">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="start center">
                <img [src]="'../../../assets/images/aecdisc_logo.png'" class="m-bottom20">
                <div class="aecd-explanation">Die AECdisc® Potenzialanalyse hilft dir dabei, deine Stärken,
                    Schwächen und Wünsche besser zu
                    verstehen damit du
                    einen Job findest, der wirklich zu dir passt und dich erfüllt. Über Talentagent bekommst du die
                    Chance,
                    die Potenzialanalyse KOSTENFREI zu erhalten. Da der Test dir ein sehr umfassendes und komplexes Ergebnis
                    gibt,
                    wird einer unserer Karrierecoaches dein Ergebnis gemeinsam mit dir auswerten.
                </div>
            </div>

            <!--no coach requested-->
            <div class="request-coach" *ngIf="!talentCoachData?.requestedAt">
                <div class="request-text">Du möchtest eine Potenzialanalyse machen? Dann fordere jetzt einen
                    persönlichen Karrierecoach an!
                </div>
                <button class="white ita_coach_aecdisc ita_request_coach" mat-flat-button color="primary" (click)="openAssignDialog()">
                    Jetzt Karrierecoach anfordern!
                </button>
            </div>

            <!--coach requested-->
            <div class="request-coach" *ngIf="talentCoachData?.requestedAt && !talentCoachData?.talentAgent">
                <div class="request-text">Wir suchen derzeit deinen persönlichen Karrierecoach für dich.
                    Dein Coach kann dir dann einen Code geben, damit du die AECdisc® Potenzialanalyse machen
                    kannst.
                </div>
            </div>

            <!--coach but code not present-->
            <div class="request-coach" *ngIf="talentCoachData?.talentAgent && !aecdCode">
                <div class="request-text">Damit du die AECdisc® Potenzialanalyse machen kannst, benötigst du einen
                    Zugangscode, welchen
                    du von deinem Karrierecoach erhältst. Nimm direkt Kontakt zu
                    {{talentCoachData.talentAgent.firstName}} {{talentCoachData.talentAgent.lastName}} auf und frage nach dem Zugang.
                </div>
            </div>


            <!--coach and code present-->
            <div class="request-coach" *ngIf="talentCoachData?.talentAgent && aecdCode && !doneTest">
                <div class="request-text">
                    Dein Karrierecoach hat dir einen AECdisc®-Code zukommen lassen. Gehe damit zu AECdisc® und mache den Test. Danach kann es bis zu 24h dauern, bis uns das Ergebnis vorliegt. Dieses wertest du dann gemeinsam mit deinem Coach aus. Wir benachrichtigen dich, wenn es soweit ist.
                </div>

                <button class="white" mat-flat-button color="primary" (click)="routeToAecdiscTest()">Zum AECdisc®-Test</button>

                <div fxLayout="column" fxLayoutAlign="start center" class="aecdisc-code-wrapper">
                    <div>Dein persönlicher Zugangs-Code</div>
                    <div class="aecdisc-code" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                        <div>{{aecdCode}}</div>
                        <button mat-icon-button matTooltip="Kopieren" (click)="utilityService.copyToClipboard(aecdCode)"><i class="mdi mdi-content-copy"></i></button>
                    </div>
                </div>
            </div>

            <!--            test done but no result-->
            <div class="request-coach" *ngIf="doneTest && !testResult">
                <div class="request-text">
                    Das Ergebnis deiner Potenzialanalyse ist bei uns eingegangen. Dein Karrierecoach {{talentCoachData?.talentAgent?.firstName}} {{talentCoachData?.talentAgent?.lastName}}
                    wird mit dir gemeinsam die umfangreichen Ergebnisse durchgehen, damit du alles verstehst.
                    Anschließend steht dir das Ergebnis hier zum freien Download zur Verfügung.
                </div>
            </div>

            <!-- test done and  result-->
            <div class="request-coach" *ngIf="doneTest && testResult">
                <div class="request-text">
                    Dein Karrierecoach hat dir dein Ergebnis freigeben.<br>
                </div>

                <button class="white" mat-flat-button color="primary" (click)="openAECdiscResult()">
                    <i class="mdi mdi-open-in-new"></i>
                    Testergebnis öffnen
                </button>

            </div>

<!--            <div *ngIf="!doneTest">-->
<!--                <iframe-->
<!--                        style="max-width: 100%; width: 560px"-->
<!--                        height="315" src="https://www.youtube.com/embed/G4uKBZa3z-I"-->
<!--                        title="YouTube video player" frameborder="0"-->
<!--                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                        allowfullscreen>-->
<!--                </iframe>-->
<!--            </div>-->
        </div>
    </loading-spinner>
</div>
