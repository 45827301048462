import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {AbstractControl, FormControl} from "@angular/forms";
import {CityData, LocationResource} from "utility";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'location-input-search',
  templateUrl: './location-input-search.component.html',
  styleUrls: ['./location-input-search.component.scss']
})
export class LocationInputSearchComponent implements OnInit, OnDestroy {

  @Input() control: AbstractControl<number>

  @Input() showLabel: boolean = true;
  @Input() label: string = "Wähle eine Stadt";
  @Input() appearance: string = "outline";
  @Input() forceValidation: Subject<boolean>;
  @Input() disabled: boolean = false;

  searchControl = new FormControl<string>("")
  options: CityData[] = [];
  oldControlValue: any;

  validateSub: Subscription

  loadingOptions: boolean = true;
  clickedOption: CityData;
  requestIndex: number = 0;

  constructor(private locationResource: LocationResource) {
  }

  ngOnDestroy(): void {
        this.validateSub?.unsubscribe()
    }

  ngOnInit() {

    this.validateSub = this.forceValidation?.subscribe(() => {
      this.control.updateValueAndValidity()
    })

    this.oldControlValue = this.searchControl.value;

    this.loadSuggestions()

    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(event => {
      if (this.oldControlValue == event) return
      if (this.clickedOption?.name == this.searchControl.value) return;

      this.oldControlValue = event;

      let localRequestIndex = ++this.requestIndex;
      this.loadSuggestions().then(() => {
        if (localRequestIndex != this.requestIndex) {
          return
        }
      });
    });

    this.control.valueChanges.subscribe(cityId => {
      if (!this.options.some((city) => city.id === cityId)) {
        this.loadSuggestions();
      }
    });
  }

  async loadSuggestions() {
    this.loadingOptions = true;
    await this.locationResource.getCitiesByQueryOrZipCode({
      query: this.searchControl.value,
      forcedIds: [this.control.value],
      page: 0,
      pageSize: 10,
    }).then(result => {
      if ('content' in result) {
        this.options = result.content;
      }
      this.loadingOptions = false;
    });
  }
}
