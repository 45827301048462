<app-cv-parsing-o-step-headline><div fxLayout="row" fxLayoutAlign="center start"><div class="font-size-20">Welches kostenfreie Angebot möchtest du nutzen?</div></div></app-cv-parsing-o-step-headline>
<div class="mini-headline">Du kannst beliebig viele wählen.</div>
<div *ngIf="availableCoachingTypes" class="content" fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="coachingsForm" fxLayout="column">
        <div class="mlp-checkbox" *ngFor="let coaching of availableCoachingTypes; index as i">
            <div *ngIf="i == 1" class="popular">BELIEBT</div>
            <input [formControlName]="coaching.id" type="checkbox" [id]="'mlpCheck' + i" [name]="'mlpCheck' + i">
            <label [for]="'mlpCheck' + i">
                <div class="checkbox-label" fxLayout="row" fxLayoutAlign="start center" [class.error]="coachingsForm.invalid && coachingsForm.touched">
                    <img alt="emoticon" class="emoticon" *ngIf="coaching.id == 5074689" src="../../../../assets/images/onboarding_coachingTypes/detective_1f575-fe0f.png">
                    <img alt="emoticon" class="emoticon" *ngIf="coaching.id == 5074690" src="../../../../assets/images/onboarding_coachingTypes/chart-increasing_1f4c8.png">
                    <img alt="emoticon" class="emoticon" *ngIf="coaching.id == 5074691" src="../../../../assets/images/onboarding_coachingTypes/detective_1f575-fe0f.png">
                    <img alt="emoticon" class="emoticon" *ngIf="coaching.id == 5074692" src="../../../../assets/images/onboarding_coachingTypes/handshake_1f91d.png">
                    <img alt="emoticon" class="emoticon" *ngIf="coaching.id == 5074693" src="../../../../assets/images/onboarding_coachingTypes/chart-increasing_1f4c8.png">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="headline">{{coaching.title}}</div>
                        <div class="description">{{coaching.description}}</div>
                    </div>
                </div>
            </label>
        </div>

    </form>

    <p class="hint" style="font-size: 12px; margin-top: 35px">
        *Die Leistung wird von Karrierecoaches/Beratern unseres Partnerunternehmens
        <span class="mlp-hover" matTooltipClass="custom-tooltip" matTooltip="MLP ist Ansprechpartner in allen Karriere- und Finanzfragen und auf die Unterstützung von Akademikern spezialisiert. Für unsere Plattform stellt MLP exklusiv geschulte Berater zur Verfügung, die dich bei Fragen rund um den Jobeinstieg unterstützen können.">
            <span>MLP <i class="mdi mdi-information"></i></span>
        </span> angeboten
    </p>
    <span class="hint"  style="font-size: 12px; text-align: center">Bei Inanspruchnahme der Leistung werden deine Daten zum Zwecke der Kontaktaufnahme und zur Durchführung der Leistung an einen Coach weitergegeben. Darüber hinaus willigst du ein, dass der Coach Zugriff auf die von dir auf talentagent.de eingegebenen Daten erhält.
        Diese Einwilligung zum Zugriff kannst du jederzeit widerrufen. Möchtest du <span style="text-decoration: underline; font-size: 12px">keine</span>
        Einwilligung erteilen bzw. keine Coaching-Leistung in Anspruch nehmen,
        überspringe diesen Schritt. Alles Weitere zur Datenverarbeitung durch den Karrierecoach findest du in unserer
        <a class="font-size-12" [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
        </span>
</div>

<app-cv-parsing-o-button-bar
        padding="0 6px"
        nextLabel="Weiter"
        [smallActionButton]="true"
        (pressedNext)="progress(true)"
        (pressedSkip)="progress(false)"
        [skipButton]="true"
        [backButton]="false"
        [buttonLockSubject]="progessingSubject"
        [skipButtonLockSubject]="skipLockSubject">
</app-cv-parsing-o-button-bar>
