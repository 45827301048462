import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MediaService} from "../../../../services/inno-utils/media.service";
import {HomeOffice} from "utility";
import {SearchService} from "../../../../services/search.service";
import {BottomSheetService} from "../../../../utility/slideable-bottom-sheets/bottom-sheet.service";
import {PositionService} from "../../../../services/position.service";

@Component({
  selector: 'app-filter-location-form',
  templateUrl: './filter-location-form.component.html',
  styleUrls: ['./filter-location-form.component.scss']
})
export class FilterLocationFormComponent implements OnInit {

  @Output() closeFilter: EventEmitter<any> = new EventEmitter<any>()

  homeOfficeType: HomeOffice[] = [
      'HomeOfficeAvailable',
      'OnlyOffice',
      'OnlyRemote'
  ]

  selectedLocationIds: number[] = []
  selectedHomeOfficeTypes: HomeOffice[] = []
  stickyCtaButtons: boolean = false


  constructor(
      public mediaService: MediaService,
      public searchService: SearchService,
      private bottomSheetService: BottomSheetService,
      private positionService: PositionService,
  ) {
    this.bottomSheetService.sheetFullHeight.subscribe(next => {
      this.stickyCtaButtons = next
    })
  }

  ngOnInit(): void {
    this.selectedLocationIds  = this.searchService.searchFilterData.positionLocationIds
    this.selectedHomeOfficeTypes = this.searchService.searchFilterData.homeOffice
  }

  changeHomeOfficeType(type: HomeOffice | null) {
    if (type == null) {
      this.selectedHomeOfficeTypes = []
      return
    }

    if (this.selectedHomeOfficeTypes.includes(type)) {
      this.selectedHomeOfficeTypes = this.selectedHomeOfficeTypes.filter(t => t !== type)
      return
    } else {
      this.selectedHomeOfficeTypes.push(type)
    }
  }

  updateLocations(locationIds: number[]) {
    this.selectedLocationIds = locationIds
  }

  nationWideChange(nationWide: boolean) {
    if (nationWide) {
      this.selectedLocationIds = []
    }
    else {
      this.selectedLocationIds = this.searchService.searchFilterData.positionLocationIds
    }
  }

  applyFilterAndSearch(){
    this.searchService.searchFilterData.positionLocationIds = this.selectedLocationIds
    this.searchService.searchFilterData.homeOffice = this.selectedHomeOfficeTypes
    this.closeFilter.emit()
    this.searchService.triggerSearch()
  }

  clearFilterAndSearch(){
    this.selectedLocationIds = []
    this.selectedHomeOfficeTypes = []
    this.searchService.searchFilterData.positionLocationIds = []
    this.searchService.searchFilterData.homeOffice = []
    this.closeFilter.emit()
    this.searchService.triggerSearch()
  }

  clearLocations(){
    this.selectedLocationIds = []
    //this.searchService.searchFilterData.positionLocationIds = []
  }

}
