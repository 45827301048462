import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {MediaService} from "../../../services/inno-utils/media.service";
import {Origin, TalentCoachRequestDistributionData} from "utility";
import {EventCachedOBInputs, EventOnboardingService} from "../event-onboarding.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
    selector: 'app-event-ob-coach-data',
    templateUrl: './event-ob-coach-data.component.html',
    styleUrls: ['./event-ob-coach-data.component.scss']
})
export class EventObCoachDataComponent implements OnInit {


    error: BehaviorSubject<any> = new BehaviorSubject<any>(false)
    studyEndDateCtl = new FormControl<Date>(null, [Validators.required])
    nationalityCtr = new FormControl<Origin>(null, [Validators.required])
    talentCityCtrl = new FormControl<number>(null, [Validators.required])
    germanSkillLevelCtr = new FormControl<string>(null)
    ctrlSub: Subscription
    progressing: Subject<any> = new Subject<any>()

    constructor(
        public onboardingService: EventOnboardingService,
        public mediaService: MediaService,
        private authService: TalentAuthService) {
    }

    ngOnInit() {
        this.onboardingService.cachedInputs.studyEndDate ? this.studyEndDateCtl.setValue(new Date(this.onboardingService.cachedInputs.studyEndDate)) : null
        this.germanSkillLevelCtr.setValue(this.onboardingService.cachedInputs.germanSkillLevel)
        this.nationalityCtr.setValue(this.onboardingService.cachedInputs.talentOrigin)
        this.talentCityCtrl.setValue(this.onboardingService.cachedInputs.talentCityId)

        this.ctrlSub = this.studyEndDateCtl.valueChanges.subscribe(next => {
            new Date(null).getTime() == new Date(next).getTime() ? this.progress() : null
        })
    }


    progress() {

        if (this.germanSkillLevelCtr.invalid || this.nationalityCtr.invalid || this.studyEndDateCtl.invalid || this.talentCityCtrl.invalid) {
            this.germanSkillLevelCtr.markAsTouched()
            this.nationalityCtr.markAsTouched()
            this.studyEndDateCtl.markAsTouched()
            this.talentCityCtrl.markAsTouched()
            return
        }

        this.progressing.next()

        this.onboardingService.updateInputs(<EventCachedOBInputs>{
            studyEndDate: this.studyEndDateCtl.value,
            germanSkillLevel: this.germanSkillLevelCtr.value,
            talentOrigin: this.nationalityCtr.value,
            talentCityId: this.talentCityCtrl.value
        })

        let data = <TalentCoachRequestDistributionData>{
            studyEndDate: this.studyEndDateCtl.value,
            talentOrigin: this.nationalityCtr.value,
            germanSkillLevel: this.germanSkillLevelCtr.value,
            talentCityId: this.talentCityCtrl.value
        }

        this.onboardingService.updateDistributionCriteriaForCoachRequest(data)

        this.onboardingService.finalizeTalentOnboardingForTalent()
        this.authService.updateOnboardingCompleted(true).then(() => {
            this.onboardingService.routeToPlattform()
        })
    }
}
