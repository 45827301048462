import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PhoneNumberUtil} from "google-libphonenumber";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }



    requirementsPasswordMatched(minScore: number = 3, minlength: number = 8):ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return {passwordEmpty: true};
            }
            if (control.value.length < minlength) {
                return {passwordTooShort: true};
            }

            let securityScore = 0;

            if (/[a-z]/.test(control.value)) securityScore++; // at least one lowercase letter
            if (/[A-Z]/.test(control.value) || /[0-9]/.test(control.value)) securityScore++; // at least one uppercase letter
            // if (/[^A-Za-z0-9]/.test(password)) securityScore++; // at least one special char

            if (securityScore < minScore) {
                return {securityRequirementsNotMatched: true};
            }


            return null;
        }
    }


  validatePhoneNumber(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      let regExp = RegExp('^(\\(?([\\d \\-\\)\\–\\+\\/\\(]+){6,}\\)?([ .\\-–\\/]?)([\\d]+))$');
      if (!control.value) {
        return {isNull: true};
      }
      if (regExp.test(control.value) == false) {
        return {isNotPhoneNumber: true};
      }
      return null;
    }

  }

    checkPhoneNumberReturnErrors(phoneNumber:string, countryCode: string):any{
        let noLettersRegExp = RegExp('^[^a-zA-Z]*$')
        let phoneUtil = PhoneNumberUtil.getInstance();

        if (noLettersRegExp.test(phoneNumber) == false) {
            return {isNotPhoneNumber: true}
        }
        try {
            let number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode)
            if(!phoneUtil.isValidNumberForRegion(number,countryCode)) {
                return {isNotPhoneNumber: true}
            }
        } catch (e) {
            return {isNotPhoneNumber: true}
        }
        return null;
    }

}

export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
