<div class="standard-container" fxLayout="column" fxLayoutAlign="start start">
  <div class="notification-box" fxLayout="column">
    <div class="header layout-padding-2x w-100" fxLayout="row" fxLayoutAlign="start center">
      <h3 class="font-size-16">Benachrichtigungen</h3>
    </div>

    <div *ngIf="isLoaded">
      <div fxLayout="column" fxLayoutAlign="center start"
           fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-around center" class="notification-card-item w-100"  *ngFor="let notification of talentNotificationData"  (click)="navigateToView(notification.type)">
          <div class="w-100">
            <div fxHide.lt-sm="true" fxLayout="row" fxLayoutAlign.lt-md="column" fxLayoutAlign="start center">
              <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center" class="logo-container">
                <img src="../../assets/images/Benachrichtigungen.png" class="logo">
              </div>

              <div fxFlex="70" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="center start"
                   class="text-wrapper" fxHide.lt-sm="true">
                <div class="m-bottom5"><b>{{notification.shortMessage}}</b></div>
                <div>{{notification.message}}</div>
                <div fxHide.lt-sm="true">
                  <div class="font-size-12" style="color: #808080">
                    {{notification.creationDate | date:'dd.MM.yyyy'}} um {{notification.creationDate |
                    date:'HH:mm'}}Uhr
                  </div>
                </div>
              </div>

              <div fxHide.lt-sm="true" fxFlex="10" fxLayout="column" fxLayoutAlign="center end"
                   fxLayoutAlign.lt-sm="center center">
                <button type="button" mat-icon-button
                        [matTooltip]="getNavigationTooltip(notification.type)"
                        (click)="navigateToView(notification.type)">
                  <i class="mdi mdi-share"></i>
                </button>
              </div>
            </div>

            <!-- mobile view -->
            <div fxHide.gt-xs="true" fxLayout="row" fxLayoutAlign="start center" class="mobile-notification-card">
              <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center" class="logo-container">
                <img src="../../assets/images/Benachrichtigungen.png" class="logo">
              </div>

              <div>
                <div class="m-bottom5"><b>{{notification.shortMessage}}</b></div>
                <div>{{notification.message}}</div>
              </div>


              <div fxFlex="10" fxLayout="column" fxLayoutAlign="center end" fxLayoutAlign.lt-sm="center center">
                <button type="button" mat-icon-button
                        [matTooltip]="getNavigationTooltip(notification.type)"
                        (click)="navigateToView(notification.type)">
                  <i class="mdi mdi-share"></i>
                </button>
              </div>
            </div>
            <!-- mobile view end-->

          </div>
        </div>
      </div>

      <div *ngIf="talentNotificationData.length < 1" class="no-notification">
        Du hast noch keine Benachrichtigungen.
      </div>
    </div>
  </div>
  <mat-paginator [pageSize]="pageSize"
                 class="ta-round-drop m-top20 width-100"
                 [showFirstLastButtons]="true"
                 [length]="totalElements"
                 [hidePageSize]="true"
                 [pageIndex]="pageIndex"
                 (page)="handlePage($event)">
  </mat-paginator>
</div>
