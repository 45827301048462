import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {LabelData, TalentOnboardingResource} from "utility";
import {Router} from "@angular/router";
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentAuthService} from "../../../services/talent.auth.service";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";

@Component({
    selector: 'app-co-matching-data',
    templateUrl: './co-matching-data.component.html',
    styleUrls: ['./co-matching-data.component.scss']
})
export class CoMatchingDataComponent implements OnInit {

    readonly form = new FormGroup({
        mode: new FormControl<CachedOBInputs['searchMode']>(this.onboardingService.cachedInputs.searchMode ? this.onboardingService.cachedInputs.searchMode : 'Nationwide', Validators.required),
        preferredCityId: new FormControl<number>(this.onboardingService.cachedInputs.preferredCityId ? this.onboardingService.cachedInputs.preferredCityId : null, this.onboardingService.cachedInputs.searchMode == 'SpecificCities' ? Validators.required : null)
    })
    loadingPreference: boolean = true;
    preferenceData: LabelData[] = []
    selectedPreferenceData: LabelData[] = []

    locationSub: Subscription
    error: Subject<any> = new Subject<any>()

    constructor(
        private router: Router,
        public onboardingService: CoachingOnboardingService,
        private authService: TalentAuthService,
        public mediaService: MediaService,
        private talentOnboardingResource: TalentOnboardingResource) {
    }

    ngOnInit() {
        this.initForm()
        this.getPreferencesOptions()
    }

    ngOnDestroy() {
        this.locationSub.unsubscribe()
    }

    initForm() {

        this.locationSub = this.form.controls.mode.valueChanges.subscribe(next => {
            if (next == 'SpecificCities') {
                this.form.controls.preferredCityId.setValidators(Validators.required)
            } else {
                this.form.controls.preferredCityId.setValidators(null)
            }
            this.form.controls.preferredCityId.updateValueAndValidity()
        })

        this.form.controls.preferredCityId.updateValueAndValidity()

        this.onboardingService.getUserLocationIfConsented().then((location) => {
            if (location && !this.form.controls.preferredCityId.value) {
                this.form.controls.preferredCityId.setValue(location.cityId);
            }
        });
    }

    selectPreference(option: LabelData) {
        if (this.selectedPreferenceData.findIndex(p => p.id == option.id) > -1) {
            this.selectedPreferenceData.splice(this.selectedPreferenceData.findIndex(p => p.id == option.id), 1)
        } else {
            this.selectedPreferenceData.push(option)
        }
    }

    getPreferencesOptions() {
        if (!this.onboardingService.cachedInputs.subjectId) return
        this.talentOnboardingResource.getRelevantProfessionFieldsForSubject({
            dkzsubjectId: this.onboardingService.cachedInputs.subjectId
        }).then(result => {
            this.preferenceData = result
            if (this.onboardingService.cachedInputs.markedAsInterestingFieldIds) this.selectedPreferenceData = this.preferenceData.filter(p => this.onboardingService.cachedInputs.markedAsInterestingFieldIds.includes(p.id))
            this.loadingPreference = false
        })
    }

    errorSubject = new Subject()
    progressingSubject = new Subject()

    progress() {
        if (!this.form.valid) {
            this.errorSubject.next()
            return
        }

        if (!this.form.valid) {
            this.error.next();
            return
        }

        this.progressingSubject.next()

        this.onboardingService.updateInputs(<CachedOBInputs>{
            markedAsInterestingFieldIds: this.selectedPreferenceData.map(p => p.id),
            searchMode: this.form.controls.mode.value,
            preferredCityId: this.form.controls.preferredCityId.value,
        })

        this.onboardingService.updateStudyAndPreferences()
        this.authService.updateOnboardingCompleted(true).then(() => {
            this.onboardingService.routeToPlattform()
        })
    }
}
