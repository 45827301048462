import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, ExpertProgressData, DisplayQuestionData, QuestionCategoryData, DisplayCommentData, CreateAnswerData, FilterQuestionData, CreateQuestionData, PagedData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.QuestionController
 */

@Injectable()
@ResourceParams({})
export class QuestionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer/{answerId}/comment/create',
        endpoint: 'de.syntacton.cronos'
    })
    private _addCommentToAnswer: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {answerId: number}, null> | undefined
    addCommentToAnswer(requestBody: JsonWrappedValue<string>, answerId: number): Promise<null> {
        if (!this._addCommentToAnswer) throw new Error("resource has not been properly initialized")
        return this._addCommentToAnswer(requestBody, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/{questionId}/comment/create',
        endpoint: 'de.syntacton.cronos'
    })
    private _addCommentToQuestion: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {questionId: number}, null> | undefined
    addCommentToQuestion(requestBody: JsonWrappedValue<string>, questionId: number): Promise<null> {
        if (!this._addCommentToQuestion) throw new Error("resource has not been properly initialized")
        return this._addCommentToQuestion(requestBody, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer',
        endpoint: 'de.syntacton.cronos'
    })
    private _answerQuestion: IResourceMethodObservableStrict<CreateAnswerData, null, null, null> | undefined
    answerQuestion(requestBody: CreateAnswerData): Promise<null> {
        if (!this._answerQuestion) throw new Error("resource has not been properly initialized")
        return this._answerQuestion(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/ask',
        endpoint: 'de.syntacton.cronos'
    })
    private _askQuestion: IResourceMethodObservableStrict<CreateQuestionData, null, null, null> | undefined
    askQuestion(requestBody: CreateQuestionData): Promise<null> {
        if (!this._askQuestion) throw new Error("resource has not been properly initialized")
        return this._askQuestion(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/answer/{answerId}/delete',
        endpoint: 'de.syntacton.cronos'
    })
    private _deleteAnswer: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    deleteAnswer(answerId: number): Promise<null> {
        if (!this._deleteAnswer) throw new Error("resource has not been properly initialized")
        return this._deleteAnswer(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/comment/{commentId}/delete',
        endpoint: 'de.syntacton.cronos'
    })
    private _deleteComment: IResourceMethodObservableStrict<null, null, {commentId: number}, null> | undefined
    deleteComment(commentId: number): Promise<null> {
        if (!this._deleteComment) throw new Error("resource has not been properly initialized")
        return this._deleteComment(null, null, {commentId: commentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/{questionId}/delete',
        endpoint: 'de.syntacton.cronos'
    })
    private _deleteQuestion: IResourceMethodObservableStrict<null, null, {questionId: number}, null> | undefined
    deleteQuestion(questionId: number): Promise<null> {
        if (!this._deleteQuestion) throw new Error("resource has not been properly initialized")
        return this._deleteQuestion(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer/{answerId}/edit',
        endpoint: 'de.syntacton.cronos'
    })
    private _editAnswer: IResourceMethodObservableStrict<CreateAnswerData, null, {answerId: number}, null> | undefined
    editAnswer(requestBody: CreateAnswerData, answerId: number): Promise<null> {
        if (!this._editAnswer) throw new Error("resource has not been properly initialized")
        return this._editAnswer(requestBody, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/comment/{commentId}/edit',
        endpoint: 'de.syntacton.cronos'
    })
    private _editComment: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {commentId: number}, null> | undefined
    editComment(requestBody: JsonWrappedValue<string>, commentId: number): Promise<null> {
        if (!this._editComment) throw new Error("resource has not been properly initialized")
        return this._editComment(requestBody, null, {commentId: commentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/{questionId}/edit',
        endpoint: 'de.syntacton.cronos'
    })
    private _editQuestion: IResourceMethodObservableStrict<CreateQuestionData, null, {questionId: number}, null> | undefined
    editQuestion(requestBody: CreateQuestionData, questionId: number): Promise<null> {
        if (!this._editQuestion) throw new Error("resource has not been properly initialized")
        return this._editQuestion(requestBody, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/answer/{answerId}/comments',
        endpoint: 'de.syntacton.cronos'
    })
    private _getAnswerComments: IResourceMethodObservableStrict<null, null, {answerId: number}, DisplayCommentData[]> | undefined
    getAnswerComments(answerId: number): Promise<DisplayCommentData[]> {
        if (!this._getAnswerComments) throw new Error("resource has not been properly initialized")
        return this._getAnswerComments(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/expertProgress',
        endpoint: 'de.syntacton.cronos'
    })
    private _getExpertProgress: IResourceMethodObservableStrict<null, null, null, ExpertProgressData[]> | undefined
    getExpertProgress(): Promise<ExpertProgressData[]> {
        if (!this._getExpertProgress) throw new Error("resource has not been properly initialized")
        return this._getExpertProgress(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/own',
        endpoint: 'de.syntacton.cronos'
    })
    private _getOwnQuestions: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<DisplayQuestionData>> | undefined
    getOwnQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        if (!this._getOwnQuestions) throw new Error("resource has not been properly initialized")
        return this._getOwnQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/own/unanswered',
        endpoint: 'de.syntacton.cronos'
    })
    private _getOwnUnansweredQuestions: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<DisplayQuestionData>> | undefined
    getOwnUnansweredQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        if (!this._getOwnUnansweredQuestions) throw new Error("resource has not been properly initialized")
        return this._getOwnUnansweredQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/popular',
        endpoint: 'de.syntacton.cronos'
    })
    private _getPopularQuestions: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<DisplayQuestionData>> | undefined
    getPopularQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        if (!this._getPopularQuestions) throw new Error("resource has not been properly initialized")
        return this._getPopularQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/popular/unanswered',
        endpoint: 'de.syntacton.cronos'
    })
    private _getPopularUnansweredQuestions: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<DisplayQuestionData>> | undefined
    getPopularUnansweredQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        if (!this._getPopularUnansweredQuestions) throw new Error("resource has not been properly initialized")
        return this._getPopularUnansweredQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/category',
        endpoint: 'de.syntacton.cronos'
    })
    private _getQuestionCategories: IResourceMethodObservableStrict<null, null, null, QuestionCategoryData[]> | undefined
    getQuestionCategories(): Promise<QuestionCategoryData[]> {
        if (!this._getQuestionCategories) throw new Error("resource has not been properly initialized")
        return this._getQuestionCategories(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/category/{questionId}',
        endpoint: 'de.syntacton.cronos'
    })
    private _getQuestionCategory: IResourceMethodObservableStrict<null, null, {questionId: number}, QuestionCategoryData> | undefined
    getQuestionCategory(questionId: number): Promise<QuestionCategoryData> {
        if (!this._getQuestionCategory) throw new Error("resource has not been properly initialized")
        return this._getQuestionCategory(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/{questionId}/comments',
        endpoint: 'de.syntacton.cronos'
    })
    private _getQuestionComments: IResourceMethodObservableStrict<null, null, {questionId: number}, DisplayCommentData[]> | undefined
    getQuestionComments(questionId: number): Promise<DisplayCommentData[]> {
        if (!this._getQuestionComments) throw new Error("resource has not been properly initialized")
        return this._getQuestionComments(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/questions',
        endpoint: 'de.syntacton.cronos'
    })
    private _getQuestionsByFilter: IResourceMethodObservableStrict<FilterQuestionData, {page: number, pageSize: number}, null, PagedData<DisplayQuestionData>> | undefined
    getQuestionsByFilter(requestBody: FilterQuestionData, requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        if (!this._getQuestionsByFilter) throw new Error("resource has not been properly initialized")
        return this._getQuestionsByFilter(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/{questionId}',
        endpoint: 'de.syntacton.cronos'
    })
    private _getSingleQuestion: IResourceMethodObservableStrict<null, null, {questionId: number}, DisplayQuestionData> | undefined
    getSingleQuestion(questionId: number): Promise<DisplayQuestionData> {
        if (!this._getSingleQuestion) throw new Error("resource has not been properly initialized")
        return this._getSingleQuestion(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/accept',
        endpoint: 'de.syntacton.cronos'
    })
    private _markAnswerAsAccepted: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    markAnswerAsAccepted(answerId: number): Promise<null> {
        if (!this._markAnswerAsAccepted) throw new Error("resource has not been properly initialized")
        return this._markAnswerAsAccepted(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/reset',
        endpoint: 'de.syntacton.cronos'
    })
    private _resetAnswerVote: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    resetAnswerVote(answerId: number): Promise<null> {
        if (!this._resetAnswerVote) throw new Error("resource has not been properly initialized")
        return this._resetAnswerVote(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/reset',
        endpoint: 'de.syntacton.cronos'
    })
    private _resetQuestionVote: IResourceMethodObservableStrict<null, null, {questionId: number}, null> | undefined
    resetQuestionVote(questionId: number): Promise<null> {
        if (!this._resetQuestionVote) throw new Error("resource has not been properly initialized")
        return this._resetQuestionVote(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/unaccept',
        endpoint: 'de.syntacton.cronos'
    })
    private _unmarkAnswerAsAccepted: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    unmarkAnswerAsAccepted(answerId: number): Promise<null> {
        if (!this._unmarkAnswerAsAccepted) throw new Error("resource has not been properly initialized")
        return this._unmarkAnswerAsAccepted(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/down',
        endpoint: 'de.syntacton.cronos'
    })
    private _voteAnswerDown: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    voteAnswerDown(answerId: number): Promise<null> {
        if (!this._voteAnswerDown) throw new Error("resource has not been properly initialized")
        return this._voteAnswerDown(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/up',
        endpoint: 'de.syntacton.cronos'
    })
    private _voteAnswerUp: IResourceMethodObservableStrict<null, null, {answerId: number}, null> | undefined
    voteAnswerUp(answerId: number): Promise<null> {
        if (!this._voteAnswerUp) throw new Error("resource has not been properly initialized")
        return this._voteAnswerUp(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/down',
        endpoint: 'de.syntacton.cronos'
    })
    private _voteQuestionDown: IResourceMethodObservableStrict<null, null, {questionId: number}, null> | undefined
    voteQuestionDown(questionId: number): Promise<null> {
        if (!this._voteQuestionDown) throw new Error("resource has not been properly initialized")
        return this._voteQuestionDown(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/up',
        endpoint: 'de.syntacton.cronos'
    })
    private _voteQuestionUp: IResourceMethodObservableStrict<null, null, {questionId: number}, null> | undefined
    voteQuestionUp(questionId: number): Promise<null> {
        if (!this._voteQuestionUp) throw new Error("resource has not been properly initialized")
        return this._voteQuestionUp(null, null, {questionId: questionId}).toPromise()
    }

}