import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SlideableBottomSheetComponent} from "./slideable-bottom-sheet/slideable-bottom-sheet";


@NgModule({
  declarations: [
      SlideableBottomSheetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
      SlideableBottomSheetComponent
  ]
})
export class SlideableBottomSheetsModule { }
