<div class="home-m-big-w">
 <div class="home-m-big-headline" fxLayout="row" fxLayoutAlign="start center">
     <i style="font-size: 20px; margin-right: 10px" class="mdi mdi-heart"></i>
     <div>Teile Talentagent!</div>
 </div>

    <div class="home-m-big-c-w" fxLayout="column" fxLayoutAlign="start center">
        <div class="text-center font-size-12">Zeig deinen Freunden und Kommilitonen, wie einfach auch sie ihren ersten Job bekommen.</div>

        <button style="border-radius: 50px; margin-top: 16px; background-color: #C54A7D; color: #ffffff"  mat-flat-button (click)="openShareOverlay()">

            <i style="color: #ffffff; margin-right: 5px" class="mdi mdi-share"></i>
            Teilen
        </button>
    </div>
</div>
