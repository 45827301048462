import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {S2FaxOnboardingService} from "../s2-fax-onboarding.service";

@Component({
  selector: 'app-s2-fax-o-eve-progress',
  templateUrl: './s2-fax-o-eve-progress.component.html',
  styleUrls: ['./s2-fax-o-eve-progress.component.scss']
})
export class S2FaxOEveProgressComponent implements OnInit {

  @Input() instantApplication: boolean = false;
  @Input() currentstep = 1;
  @Input() totalsteps;
  handleStepsManually: boolean = false

  constructor(private obService: S2FaxOnboardingService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    if(!this.totalsteps){
      this.totalsteps = 2* Math.floor(this.route.routeConfig.children.length/2);
    }else{
      this.handleStepsManually = true
    }

    this.router.events.subscribe(evt => {
      if(evt instanceof NavigationEnd){
        this.updateCurrentStep()
      }
    })

    this.updateCurrentStep()

  }

  updateCurrentStep(){
    if(!this.handleStepsManually){
      let lastUrlSegment = window.location.href.split("/")[window.location.href.split("/").length - 1]
      this.currentstep = this.route.routeConfig.children.map(step => step.path).indexOf(lastUrlSegment) +1
    }
  }

}
