<div class="flex column start width-100">

        <div class="width-100 favourites-wrapper">

            <div class="content-width flex column gap inner-wrapper">
                <div class="flex row gap start-center">
                    <i class="mdi mdi-heart"></i>
                    <h1 class="favourites-header">Deine Merkliste</h1>
                </div>
                <p>
                    Hier findest du alle Stellen, die du als interessant markiert hast.
                    Nutze die Chance und bewirb dich, um die Unternehmen kennenzulernen und deinem Traumjob einen Schritt näher zu sein!
                </p>
            </div>

        </div>


    <app-positions-wrapper-preview-details
            class="width-100"
            [positions]="bookmarkedPositions"
            [totalPositions]="totalProposalsBookmarked"
            [page]="pageIndex"
            [totalPages]="totalPages"
            [loading]="loadingBookmarkedProposals"
            origin="Bookmarks"
    >
    </app-positions-wrapper-preview-details>

    <div *ngIf="!loadingBookmarkedProposals.value && bookmarkedPositions?.length == 0" class="padding flex column center center-text width-100">
        <p class="margin-bottom">Du hast dir keine Stellen gemerkt. Finde jetzt bei unseren Stellenangeboten deinen Traumjob!</p>
        <button class="primary-button" routerLink="/talent/positions">Zu unseren Stellen</button>
    </div>
</div>
