
<!-- Step -->
<div *ngIf="!showPositionsFoundAnimation">
    <app-co-step-headline>Was hast du zuletzt studiert?</app-co-step-headline>
    <study-search-onboarding style="width: 100%" [control]="control" (onSelect)="progress()"></study-search-onboarding>
</div>

<!--Transition animation to next step -->
<div class="position-count-animation-wrapper" [ngClass]="{'no-display': !showPositionsFoundAnimation}"
     fxLayout="column" fxLayoutAlign="center center">
    <img id="eve-image" src="../../../../assets/images/eve-dropshadow.svg">
    <div class="count-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div style="" id="available-position-count" fxLayout="row"></div>
        <div id="available-position-text">Stellen habe ich für dich gefunden!</div>
    </div>
</div>