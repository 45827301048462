import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {MediaService} from "../../services/inno-utils/media.service";
import {
  PublicCompanyProfileData,
  PublicResource,
  TalentEmploymentPositionResource,
  TalentPositionPreviewData
} from "utility";
import {PositionService} from "../../services/position.service";
import {BehaviorSubject} from "rxjs";
import {TalentAuthService} from "../../services/talent.auth.service";

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {

  primeCompany: boolean = false
  videoUrl: string = ''
  environmentVar
  imageUrls: string[] = []
  companyData: PublicCompanyProfileData

  @Input() companyId: number = 0
  @Input() showPosiitons: boolean = true

  loadingCompanyData: boolean = false
  loadingPositions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  showAllPositions: boolean = false
  showMorePositionsButton: boolean = false
  fromPreview: boolean = false
  previewImageIds: number[] = []
  positionsOfCompany: TalentPositionPreviewData[] = [];

  constructor(
      private route: ActivatedRoute,
      private publicResource: PublicResource,
      public mediaService: MediaService,
      public authService: TalentAuthService,
      public router: Router,
      private talentEmploymentPositionResource: TalentEmploymentPositionResource,
      private positionService: PositionService
  ) {
    if (!this.companyId) this.companyId = parseInt(this.route.snapshot.params['companyId'])
    this.environmentVar = environment
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.fromPreview = params['fromPreview'];
      const data = params['data'];
      const primeCompany = params['primeCompany'] === 'true'; // Convert the string 'true'/'false' to boolean

      if (this.fromPreview && data) {
        this.companyData = JSON.parse(decodeURIComponent(data));
        this.primeCompany = primeCompany
        if(this.companyData.videoLinkUnsafe) this.videoUrl = this.companyData.videoLinkUnsafe
        this.previewImageIds = params['imageIds'] ? params['imageIds'].split(',').map(id => Number(id)) : [];

        this.generateImageUrls()
        this.loadPositions()

      } else {
        this.getCompany();
      }
    });
  }



  loadPositions(){
    if(this.authService.isAuthenticated()){
      this.loadingPositions.next(true)
      this.talentEmploymentPositionResource.getEmploymentPositionsForCompany(this.companyId).then(res => {
        this.positionsOfCompany = res
          if(this.positionsOfCompany?.length) this.positionService.selectedPositionId.next(this.positionsOfCompany[0]?.id)

        this.loadingPositions.next(false)
        if(this.route.snapshot.queryParams['jobAnchor']){
          this.scrollToJobs()
        }
      })
    }
  }

  getCompany() {
    this.loadingCompanyData = true

    this.publicResource.getCompanyProfileByCompanyId(this.companyId).then(result => {
      this.companyData = result
      this.primeCompany = result.primeCompany
      this.loadingCompanyData = false
      if(result.videoLinkUnsafe) this.videoUrl = result.videoLinkUnsafe
      this.generateImageUrls()
      this.loadPositions()
    })
  }

  generateImageUrls(){
    let imgUrl = ''
    if(this.fromPreview){
      this.previewImageIds.forEach(id => {
        imgUrl = this.environmentVar.apiUrl +  '/public/company/' + this.companyId + '/companyProfile/galleryImages/' +  id
        this.imageUrls.push(imgUrl)
      })
    }
    else {
      this.companyData.galleryImages.forEach(img => {
        imgUrl = this.environmentVar.apiUrl +  '/public/company/' + this.companyId + '/companyProfile/galleryImages/' +  img.uploadedDocumentId
        this.imageUrls.push(imgUrl)
      })
    }
  }

  scrollToJobs(): void {
    document.getElementById('jobContainer').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
