import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MediatorService {

  public talentAgentRequestedEvent = new Subject()

  constructor() {
  }
}
