import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-co-step-headline',
  templateUrl: './co-step-headline.component.html',
  styleUrls: ['./co-step-headline.component.scss']
})
export class CoStepHeadlineComponent implements OnInit {

  @Input() marginTop: number = 10

  constructor() { }

  ngOnInit(): void {
  }

}
