import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {PositionQuestionSelectOptionData} from "utility";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit, OnDestroy {

    @Input() control: AbstractControl<number>

    @Input() label: string = "Bitte auswählen";
    @Input() appearance: "legacy" | "outline" | "standard" | "fill" = "outline";
    @Input() forceValidation: Subject<boolean>;
    @Input() disabled: boolean = false;
    @Input() multiple: boolean = false;
    @Input() options: PositionQuestionSelectOptionData[] = [];

    searchControl = new FormControl<string>("")
    oldControlValue: any;

    validateSub: Subscription

    loadingOptions: boolean = false;
    showedOptions: PositionQuestionSelectOptionData[] = [];

    constructor() {
    }

    ngOnDestroy(): void {
        this.validateSub?.unsubscribe()
    }

    ngOnInit() {
        this.showedOptions = this.options
        this.validateSub = this.forceValidation?.subscribe(() => {
            this.control.updateValueAndValidity()
        })

        this.oldControlValue = this.searchControl.value;

        this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe(event => {
            this.loadingOptions=true
            if (this.oldControlValue == event) return
            this.oldControlValue = event;
            this.showedOptions = this.options.filter(it => it.label.includes(event))
            this.loadingOptions=false
        });
    }
}
