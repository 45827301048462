<app-event-status-page *ngIf="onboardingService?.cachedInputs?.eventId" [eventId]="onboardingService.cachedInputs.eventId" (loadingEventState)="loading = $event"></app-event-status-page>

<div *ngIf="!loading && onboardingService?.cachedInputs?.eventId">
    <div class="text-block">
        Wir haben auch eine Stellenplattform! Du kannst direkt mit deinen eingegeben Daten dein Profil vervollständigen und auf tausende Jobs zugreifen. Wir geben die sogar Personalisierte Stellenvorschläge passend zu deinem Studiengang und deinen Preferenzen. Schau doch mal rein :).
    </div>
    <div class="width-100" fxLayout="column" fxLayoutAlign="center center">
        <button [disabled]="!onboardingService?.interestingCompanyData?.length" mat-flat-button color="accent" (click)="routeToOnboarding()" >Profil vervollständigen</button>
        <info-text class="m-top10"
                *ngIf="!onboardingService?.interestingCompanyData?.length">
            <div class="info-text">
                Bevor du dein Profil vervollständigen kannst, musst du Unternehmen mit dem <i class="mdi mdi-star-outline"></i> als interessant markieren
            </div>
        </info-text>
    </div>
</div>


