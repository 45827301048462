import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TalentEventInterestingCompanyData} from "utility";

@Component({
  selector: 'app-event-company-profile-dialog',
  templateUrl: './event-company-profile-dialog.component.html',
  styleUrls: ['./event-company-profile-dialog.component.scss']
})
export class EventCompanyProfileDialogComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: TalentEventInterestingCompanyData,
      public dialogRef: MatDialogRef<EventCompanyProfileDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close()
  }

}
