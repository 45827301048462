import { Component, OnInit } from '@angular/core';
import {TalentAuthService} from "../../../services/talent.auth.service";
import {TalentAccountInfoData} from "utility";

@Component({
  selector: 'cv-upload-home-desktop',
  templateUrl: './cv-upload-home-desktop.component.html',
  styleUrls: ['./cv-upload-home-desktop.component.scss']
})
export class CvUploadHomeDesktopComponent implements OnInit {

  accountInfo: TalentAccountInfoData

  constructor(
      public authService: TalentAuthService
  ) { }

  ngOnInit(): void {
    this.accountInfo = this.authService.getAccountInfo()
  }
}
