<div mat-dialog-content style="max-width: 500px" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center">
<img src="assets/images/mlp_logo-slogan.png" class="mlp-logo">
  </div>
  <div>
    Bei unserem Gesamtangebot werden wir von MLP als Partner unterstützt, der die Rolle eines Karrierecoaches einnimmt: Ausgewählte MLP Berater, die über eine Qualifizierung an der MLP Corporate University verfügen, unterstützen euch als Bewerber optional mit fundiertem Erfahrungswissen. <br> Dabei werden eure Fragen rund um das Thema Berufseinstieg beantwortet und Hilfestellungen angeboten. <br> Nach erfolgreicher Stellenzusage entstehen zudem oft weitere wirtschaftliche und finanzielle Fragestellungen, bei denen der MLP-Berater euch auf Wunsch auch unterstützt.
  </div>

  <div style="margin-top:10px; font-style: italic; color: #777">Mehr Informationen zu MLP findest du  <a class="link-classic" href="https://mlp-se.de/unternehmensprofil/portraet/" target="_blank">hier</a></div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>Schließen</button>
</div>
