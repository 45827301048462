import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {S2FaxOButtonBarComponent} from "./s2-fax-o-button-bar/s2-fax-o-button-bar.component";
import { S2FaxOCoachComponent } from './s2-fax-o-coach/s2-fax-o-coach.component';
import { S2FaxOCoachDataComponent } from './s2-fax-o-coach-data/s2-fax-o-coach-data.component';
import { S2FaxOContactDataComponent } from './s2-fax-o-contact-data/s2-fax-o-contact-data.component';
import { S2FaxOCvUploadComponent } from './s2-fax-o-cv-upload/s2-fax-o-cv-upload.component';
import { S2FaxOMatchingDataComponent } from './s2-fax-o-matching-data/s2-fax-o-matching-data.component';
import { S2FaxOPasswordComponent } from './s2-fax-o-password/s2-fax-o-password.component';
import { S2FaxOSelectSubjectComponent } from './s2-fax-o-select-subject/s2-fax-o-select-subject.component';
import { S2FaxOWelcomeComponent } from './s2-fax-o-welcome/s2-fax-o-welcome.component';
import { S2FaxOEveProgressComponent } from './s2-fax-o-eve-progress/s2-fax-o-eve-progress.component';
import { S2FaxOStepHeadlineComponent } from './s2-fax-o-step-headline/s2-fax-o-step-headline.component';
import { S2FaxOnboardingComponent } from './s2-fax-onboarding/s2-fax-onboarding.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {UtilityModule} from "utility";
import {RouterModule, Routes} from "@angular/router";
import {HidLibCloneModule} from "../../utility/hid-lib-clone/hid-lib-clone.module";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";


const appRoutes: Routes = [
  {path: 'onboarding/s2',  pathMatch: 'full', redirectTo: 'onboarding/s2fax'},
    {
  path: 'onboarding/s2fax', component: S2FaxOnboardingComponent, canActivate: [OnboardingAuthGuardService],
  children: [
    {path: 'welcome', component: S2FaxOWelcomeComponent},
    {path: 'subject', component: S2FaxOSelectSubjectComponent},
    {path: 'preferences', component: S2FaxOMatchingDataComponent},
    {path: 'contact-data', component: S2FaxOContactDataComponent},
    {path: 'coach-intent', component: S2FaxOCoachComponent},
    {path: 'coach-data', component: S2FaxOCoachDataComponent},
    {path: 'password', component: S2FaxOPasswordComponent},
    {path: 'cv', component: S2FaxOCvUploadComponent}
  ]
}];

@NgModule({
  declarations: [

    S2FaxOButtonBarComponent,
    S2FaxOCoachComponent,
    S2FaxOCoachDataComponent,
    S2FaxOContactDataComponent,
    S2FaxOCvUploadComponent,
    S2FaxOMatchingDataComponent,
    S2FaxOPasswordComponent,
    S2FaxOSelectSubjectComponent,
    S2FaxOWelcomeComponent,
    S2FaxOEveProgressComponent,
    S2FaxOStepHeadlineComponent,
    S2FaxOnboardingComponent
  ],
    exports: [
        S2FaxOEveProgressComponent,
        S2FaxOStepHeadlineComponent
    ],
  imports: [
    MatDialogModule,
    CommonModule,
    UtilityModule,
    RouterModule.forChild(appRoutes),
    HidLibCloneModule
  ]
})
export class S2FaxOnboardingModule { }
