import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ChatData, ChatMinimalData, TalentChatResource} from "utility";
import {TalentAuthService} from "../../services/talent.auth.service";

@Component({
  selector: 'app-chat-overlay',
  templateUrl: './chat-overlay.component.html',
  styleUrls: ['./chat-overlay.component.scss']
})
export class ChatOverlayComponent implements OnInit {

  chatsPreview: ChatMinimalData[]
  chatData: ChatData

  talentId: number
  talentFullName: string
  talentFirstName: string

  loadingPreviews: boolean = true
  loadingChat: boolean = false

  @Output() hideOverlay: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild("chatContainer") chatContainer: ElementRef

  constructor(
      private chatResource: TalentChatResource,
      private authService: TalentAuthService
  ) { }

  ngOnInit(): void {
    this.loadTalentInfo()
    this.loadChatPreviews()
  }

  loadTalentInfo(): void {
    this.talentId = this.authService.getAccountInfo().id
    this.talentFullName = this.authService.getAccountInfo().firstName + " " + this.authService.getAccountInfo().lastName
    this.talentFirstName = this.authService.getAccountInfo().firstName
  }

  loadChatPreviews(): void {
    this.chatResource.getChatMinimalDataForOverview().then(result => {
      this.chatsPreview = result
      this.loadingPreviews = false
    })
  }

  loadChatContent(chatId: number): Promise<any> {
    if (this.loadingChat) return
    this.loadingChat = true
    this.chatContainer?.nativeElement?.classList.remove("hide-animation")
    return this.chatResource.getChatForOverview(chatId).then(result => {
      this.chatData = result
      this.loadingChat = false
    })
  }

  hideChat(): void {
    this.chatContainer.nativeElement.classList.add("hide-animation")
    setTimeout(() => this.chatData = null, 200)
  }

}
