<div id="main-wrapper">

    <div *ngIf="!loadingCV && !cvInfo?.cvAvailable" class="settigs-section cv-upload-banner ita_profile_hint ita_upload_cv" (click)="cvFileInputProfile.click()">
        Dein Lebenslauf fehlt noch! Damit du dich auf Stellen bewerben kannst, ist ein Lebenslauf notwendig. Jetzt Hochladen!
    </div>

    <div id="profile-wrapper" class="settigs-section ">
        <loading-spinner [loadingVar]="loadingPersonalData">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
            <div style="width: 170px; height: 170px; margin: 20px;">
                <talent-profile-picture></talent-profile-picture>
            </div>
            <div class="input-box" fxLayout="column"  fxLayoutAlign="start center" fxLayoutGap="5px">
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <hid-input-field
                            class="study-input personal-input"
                            [control]="personalDataGroup.controls['firstName']"
                            label="Vorname"
                            style="margin: 0 8px"
                            [inputHeight]="54"
                            type="text">
                    </hid-input-field>

                    <hid-input-field
                            class="study-input personal-input"
                            [control]="personalDataGroup.controls['lastName']"
                            label="Nachname"
                            [inputHeight]="54"
                            style="margin: 0 8px"
                            type="text">
                    </hid-input-field>
                </div>

                <div  fxLayout="row wrap" fxLayoutAlign="center center">
                        <hid-input-field
                                class="study-input personal-input"
                                [control]="personalDataGroup.controls['phoneNumber']"
                                label="Telefonnummer"
                                type="text"
                                style="margin: 0 8px"
                                [inputHeight]="54">
                        </hid-input-field>

                    <location-input-search
                            *ngIf="talentProfileDataInitialLoaded"
                            [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                            class="study-input personal-input"
                            label="Dein Wohnort"
                            style="position: relative; top: 9px; min-width: 313px; margin: 0 8px"
                            [control]="personalDataGroup.controls['cityId']"
                    ></location-input-search>
                </div>


            </div>
        </div>
        </loading-spinner>
    </div>

    <div class="settigs-section ">
        <loading-spinner [loadingVar]="loadingStudyInfo">
        <div class="m-bottom20">
                <div class="headline">Dein aktueller Studiengang</div>
                <div class="explanation">(Notwendig für passende Stellenvorschläge)</div>
            </div>

        <div class="study-wrapper">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" class="subject-input study-input width-100">
                <div class="label">Dein Studiengang</div>
                <div>{{mostRecentStudies?.subjectName}}</div>
                <button mat-icon-button (click)="openEditStudyDialog()"><i class="mdi mdi-pencil"></i></button>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" class="uni-input" *ngIf="mostRecentStudies?.id">
                <hid-autocomplete-input-field label="Wie heißt deine Hochschule?"
                                              [showValidatorIcons]="false"
                                              class="study-input"
                                              style="width: 100%"
                                              [showSmallLoadingSpinner]="false"
                                              [control]="studyForm.controls['universityName']"
                                              [loadingFunctionConfig]="{resource: universityResource, functionName: 'getUniversitiesByQuery'}"
                                              displayProperty="name">
                </hid-autocomplete-input-field>

                <button *ngIf="studyForm.controls.universityName.disabled" mat-icon-button (click)="enableUniversityEdit()">
                    <i class="mdi mdi-pencil"></i>
                </button>

                <button *ngIf="!studyForm.controls.universityName.disabled" mat-icon-button [disabled]="studyForm.controls.universityName.invalid" (click)="saveUniversity()">
                    <i class="mdi mdi-content-save"></i>
                </button>
            </div>

            <hid-dropdown
                    *ngIf="mostRecentStudies?.id"
                    style="position:relative"
                    label="Abschlusszeitpunkt (voraussichtlich)"
                    [options]="endDateDropdownOptions"
                    displayProperty="label"
                    identifyingProperty="option"
                    class="study-input"
                    [control]="studyForm.controls['endDateCategory']"></hid-dropdown>

            <hid-input-field
                    *ngIf="mostRecentStudies?.id"
                    class="study-input"
                    [control]="studyForm.controls['grade']"
                    [label]="'Note'"
                    pattern="[0-9]+([\.,][0-9]+)?" type="number" step="0.1">
            </hid-input-field>

        </div>
        </loading-spinner>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-between start" class="settigs-section upload-box">
        <loading-spinner [loadingVar]="loadingFiles" class="width-100">

        <div class="m-bottom20 width-100" fxLayout="column" fxLayoutAlign="center start">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between start">
                <div>
                    <div class="headline">Lebenslauf und Anhänge</div>
                    <div class="explanation">(Notwendig für deine Bewerbung)</div>
                </div>
            </div>
        </div>

        <div class="w-100 upload-row" fxLayout="column" fxLayoutAlign="center center">
            <div class="upload-container" fxLayout="column" fxLayoutAlign="start center" style="padding-bottom: 25px">
                <div *ngIf="cvInfo.uploadDate != null" class="work-sample" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" >
                    <div class="work-sample-file" style="background-color: var(--secondary)">
                        <div fxLayout="row" fxFlex="1 1" class="action-bar">
                            <div>
                                <button (click)="this.dialogOpenService.openWorksamplePreviewDialog(cvInfo, cvInfo.id)" mat-icon-button><i class="mdi mdi-eye"></i></button>
                            </div>
                            <div>
                                <button mat-icon-button (click)="deleteCV()"><i class="mdi mdi-delete"></i></button>
                            </div>
                        </div>
                    </div>
                    <div>Lebenslauf</div>
                </div>

                <label *ngIf="cvInfo.uploadDate == null" class="work-sample ita_profile_box ita_upload_cv" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" [for]="savingCv? '' : 'cvFileInputProfile'" style="position:relative;">
                    <div class="work-sample-file-new upload-cv" fxLayout="column" fxLayoutAlign="center center">
                        <i class="mdi mdi-plus-circle"></i>
                    </div>
                    <div style=" color: var(--secondary);">Lebenslauf Hochladen</div>
                    <mat-spinner *ngIf="savingCv" color="accent" [diameter]="50"></mat-spinner>
                </label>
                <input type="file"
                       (change)="saveCurriculumVitae()"
                       [accept]="fileService.whiteListedCvExtensionsStr"
                       [formControl]="cvFile" id="cvFileInputProfile" #cvFileInputProfile class="inputfile">

                <div id="file-errors" *ngIf="cvFile" fxLayout="column" class="m-top10">
                    <small style="color: red" *ngIf="cvFile.errors != null && cvFile.errors['sizeTooBig']">
                        Die Datei ist größer als 10MB, Bitte wähle eine kleinere Datei.
                    </small>
                    <small style="color: red" *ngIf="cvFile.errors != null && cvFile.errors['wrongExtension']">
                        Falsches Format. Bitte wähle ein PDF-Dokument.
                    </small>
                    <small *ngIf="cvFile.errors != null && cvFile.errors['fileIsEmpty']">
                        Die ausgewählte Datei ist leer.
                    </small>
                </div>
            </div>

            <div class="upload-container width-100" fxLayout="row wrap"
                 fxLayoutAlign="center start">

                <div *ngFor="let file of uploadedFiles" class="work-sample" fxLayout="column"
                     fxLayoutAlign="end center" fxLayoutGap="10px">
                    <div class="work-sample-file">
                        <div fxLayout="row" fxFlex="1 1" class="action-bar">
                            <div>
                                <button (click)="this.dialogOpenService.openWorksamplePreviewDialog(file, file.storedFileId)" mat-icon-button><i
                                        class="mdi mdi-eye"></i></button>
                            </div>
                            <div>
                                <button mat-icon-button (click)="deleteWorksample(file)"><i
                                        class="mdi mdi-delete"></i></button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px"
                         class="file-name">
                        <span [matTooltip]="file.description">{{file.description}}</span>
                        <i [matMenuTriggerFor]="fileMenu" class="mdi mdi-pencil"></i>
                    </div>

                    <mat-menu #fileMenu="matMenu" yPosition="below">
                        <div class="fileEdit" fxLayout="row" fxLayoutAlign="center center" (click)="$event.stopPropagation()">
                            <input #fileInput [(ngModel)]="file.description" (blur)="updateProfileAttachmentName(file)" >
                        </div>
                    </mat-menu>
                </div>

                <label class="work-sample" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" [for]="savingProfileAttachment? '' : 'fileInputAttachements'" style="position:relative;">
                    <div class="work-sample-file-new" fxLayout="column" fxLayoutAlign="center center">
                        <i class="mdi mdi-plus-circle"></i>
                    </div>
                    <div style=" color: var(--light-grey);">neuer Anhang</div>
                    <mat-spinner *ngIf="savingProfileAttachment" color="accent" [diameter]="50"></mat-spinner>
                </label>
                <input #fileInputAttachements [disabled]="savingProfileAttachment" type="file" (change)="saveProfileAttachment()" [formControl]="profileAttachmentfile" id="fileInputAttachements" class="inputfile">

            </div>

            <div *ngIf="profileAttachmentfile" fxLayout="column">
                <small style="color: red" *ngIf="profileAttachmentfile.errors != null && profileAttachmentfile.errors['sizeTooBig']">Die Datei ist größer als 10MB,
                    Bitte wähle eine kleinere Datei
                </small>
                <small style="color: red" *ngIf="profileAttachmentfile.errors != null && profileAttachmentfile.errors['wrongExtension']">
                    Falsche Dateiendung. Bitte wähle ein PDF Dokument aus.
                </small>
            </div>
            </div>
        </loading-spinner>
        </div>
    </div>
