import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

import {Subscription} from "rxjs";
import {LocalStorage} from "ngx-store";
import {LocalStorageService} from "../local-storage.service";
import {TalentAuthService} from "../talent.auth.service";
import {DataLayerService,} from "@piwikpro/ngx-piwik-pro";
import {InputFieldPrepopulationData, TalentAccountInfoData} from "utility";

export type OnboardingType = "Standard" | "Instant" | "CoachInvite"| "Cherry"

export class TrafficIdentificationData {
    utmCampaign: string;
    utmContent: string;
    utmMedium: string;
    utmSource: string;
    utm_term: string;
    gclid: string;
    fbclid: string;
    invitedFromToken: string;
    entryGateway: 'Standard' | 'Cherry' | 'Karrieredinner'
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    @LocalStorage()
    trafficOrigin = <TrafficIdentificationData>{};

    queryParameterSub: Subscription


    constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataLayerService: DataLayerService,
    private authService: TalentAuthService
    ) {
  }

    reportTalentPlatformVisitedToDataLayer() {
        let auth = this.authService.getAccountInfo() as TalentAccountInfoData
        this.pushEventToDataLayer({event: 'OnboardingEnd', firstName: auth.firstName, lastName: auth.lastName, email: auth.emailAddress,phoneNumber: auth.phoneNumber}, true);
  }

  reportTalentQualifiedForCoachToDataLayerOld(obName: string, coachRequested?: boolean, cvUploaded?: boolean, studyEndDate?: Date, languageLevel?: boolean) {
      let in6Months = new Date()
      in6Months.setDate(in6Months.getDate()+180);

      if ((studyEndDate && studyEndDate < in6Months) && coachRequested && cvUploaded) {
          this.pushEventToDataLayer({event: 'QualifiedForCoach', onboardingName: obName}, true);
      }

      if ((studyEndDate && studyEndDate < in6Months) && coachRequested && languageLevel) {
          this.pushEventToDataLayer({event: 'QualifiedForCoach_graduate_request_language', onboardingName: obName}, true);
      }
  }

  /** reports an Onboardingstep Completion to the dataLayer**/
  reportOnboardingStepCompletion(onboardingType: OnboardingType, onboardingSplit: string,stepName: string, completionContext: string ) {
      let event = {
          event: 'StepCompletion',
          onboardingType:onboardingType,
          stepName:stepName,
          onboardingSplit:onboardingSplit,
          completionContext:completionContext
      };
      this.pushEventToDataLayer(event, true)
  }


  /** reports a custom event to the dataLayer. This is used to track events that are not initiate from an redirect.**/
    reportCustomOnboardingEvent(onboardingType: OnboardingType, onboardingSplit: string, stepName: string, eventContext: string ) {
        let event = {
            event: 'CustomOnboardingEvent',
            onboardingType:onboardingType,
            stepName:stepName,
            onboardingSplit:onboardingSplit,
            eventContext:eventContext
        };
        this.pushEventToDataLayer(event, true)
    }

    reportCVParsingOnboardingParsingData(prepopulationData: InputFieldPrepopulationData | null) {
        let event = {
            event: 'CVParsingOnboardingData',
            parsingData: prepopulationData
        }
        this.pushEventToDataLayer(event, true)
    }



  /**reports an Onboarding page-view based on the routers current url. If you need other Pageviews you can abstract this function.**/
  reportOnboardingVirtualPageView(onboardingType: OnboardingType, stepName:string, onboardingSplitName: string){
    let event = <any>this.generateVirtualPageView()
      event.onboardingSplit = onboardingSplitName;
      event.onboardingType = onboardingType;
      event.entryGateway = this.trafficOrigin.entryGateway;
      event.stepName = stepName;
      this.pushEventToDataLayer(event, false)
  }

    grabAndStoreUrlParams(){
        let params = new URLSearchParams(window.location.search)

            let data = <TrafficIdentificationData>{
                utmCampaign: params.get('utm_campaign'),
                utmContent: params.get('utm_content'),
                utmMedium: params.get('utm_medium'),
                utmSource: params.get('utm_source'),
                utm_term: params.get('utm_term'),
                gclid: params.get('gclid'),
                fbclid: params.get('fbclid'),
                invitedFromToken: params.get('agent'),
                entryGateway: null
            }


            for(var property in this.trafficOrigin){
                if(data[property] == null){
                    data[property] = this.trafficOrigin[property]
                }
            }
            this.trafficOrigin = data;

    }

    private generateVirtualPageView(): any{
        return {
            event: 'VirtualPageview',
            virtualPagePath: this.router.url,
            consent_facebook: this.localStorageService.gdprConsent.consent_facebook,
            consent_ga: this.localStorageService.gdprConsent.consent_ga,
            consent_linkedIn: this.localStorageService.gdprConsent.consent_linkedIn,
            consent_pinterest: this.localStorageService.gdprConsent.consent_pinterest,
            consent_google_ads: this.localStorageService.gdprConsent.consent_google_ads,
            consent_google_oc: this.localStorageService.gdprConsent.consent_google_oc,
            consent_facebook_oc: this.localStorageService.gdprConsent.consent_facebook_oc,
            consent_tiktok: this.localStorageService.gdprConsent.consent_tiktok,
            consent_hotjar: this.localStorageService.gdprConsent.consent_hotjar,
            consent_piwik: this.localStorageService.gdprConsent.consent_piwik,
            pageTitle: document.title,
            utmSource: this.trafficOrigin.utmSource,
            utmContent: this.trafficOrigin.utmContent,
            utmMedium: this.trafficOrigin.utmMedium,
            utmCampaign: this.trafficOrigin.utmCampaign,
            utm_term: this.trafficOrigin.utm_term
        }
    }

    /** general report virtual page view method. Will be fired at every NavigationEnd event except for onboarding pages
     * this Process dont use Piwik because routings are tracked automaticly by PiwikPro
     * **/
    reportVirtualPageView(){
        this.pushEventToDataLayer(
            this.generateVirtualPageView(), false
        )
    }

    pushEventToDataLayer(event: any, pushToPiwik: boolean = false){
        (<any>window).dataLayer.push(event);
        if (pushToPiwik) {
            this.dataLayerService.push(event)
        }
    }

    init(){
        this.initGtm()
        //track regular page views. Onboarding page views get tracked in a different function
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (!(this.router.url.includes('/onboarding'))) {
                    this.reportVirtualPageView()
                }
            }
        })
    }

  private initGtm() {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
      this.pushEventToDataLayer(
          {
              originalLocation: document.location.protocol + '//' +
                  document.location.hostname +
                  document.location.pathname +
                  document.location.search
          }
      )
  }
}
