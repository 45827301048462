<location-autocomplete-input
        [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
        style="width: 100%"
        label="Stadt hinzufügen (Name oder PLZ)"
        [disabled]="disabled"
        [initialValue]="initialValue"
        displayProperty="name"
        mode="Object"
        (modelChange)="pushCityToPreferredCities($event)"
        [forceValidation]="forceValidation">
</location-autocomplete-input>

<small style="color: red; font-size: 12px" *ngIf="control.errors && showErrors">
    Bitte wähle mindestens eine Stadt aus, um fortzufahren.
</small>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-top: 10px;" *ngIf="!disabled">
    <div *ngFor="let city of control.value" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="preferred-city">
        <div>{{city.name}}</div>
        <i class="mdi mdi-close" (click)="removePreferredCity(city)"></i>
    </div>
</div>
