 <div class="favourite-icon" *ngIf="type == 'Icon'"
     (click)="$event.stopImmediatePropagation(); flipBookmark()">
    <i class="mdi mdi-heart-outline"></i>
    <i class="mdi mdi-heart heart-overlay" [ngClass]="{'active': isBookmarked()}"></i>
</div>

<button class="favourite-button" *ngIf="type == 'Button'"

        [ngClass]="{'light-button': !isBookmarked() && color == 'Grey', 'dark-button': isBookmarked(),
        'light-teal-button': !isBookmarked() && color == 'Teal'}"
        (click)="flipBookmark()">
    <div class="flex row center gap-sm" *ngIf="!isBookmarked()">
        <i class="mdi mdi-heart-outline"></i>
        <span *ngIf="origin != 'Recommendations' || !mediaService.getMaxWidthQuery(1220).matches ">Merken</span>
    </div>

    <div class="flex row center gap-sm" *ngIf="isBookmarked()">
        <i class="mdi mdi-heart"></i>
        <span *ngIf="origin != 'Recommendations' || !mediaService.getMaxWidthQuery(1220).matches">Gemerkt</span>
    </div>
</button>
