import {Component, OnInit} from '@angular/core';
import {AssignTalentAgentDialogComponent} from "../assign-talent-agent-dialog/assign-talent-agent-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MediaService} from "../../services/inno-utils/media.service";
import {MediatorService} from "../../services/mediator.service";
import {Router} from "@angular/router";
import {TalentCoachData, TalentCoachResource, TalentEvaluationResource, UtilityService} from "utility";
import {TalentEvaluationData} from 'projects/utility/src/lib/generated/data/talent-evaluation-data';
import {environment} from "../../../environments/environment";
import {FileService} from "../../services/file.service";
import {DialogOpenService} from "../../services/dialog-open.service";

@Component({
  selector: 'aecdisc-talentanalysis',
  templateUrl: './aecdisc-talentanalysis.component.html',
  styleUrls: ['./aecdisc-talentanalysis.component.scss']
})
export class AECdiscTalentanalysisComponent implements OnInit {

  loading: boolean = false
  talentCoachData: TalentCoachData
  aecdCode: string
  doneTest: boolean = false
  testResult: boolean = false

  talentEvaluationData: TalentEvaluationData

  constructor(
      public mediaService: MediaService,
      private fileService: FileService,
      private dialog: MatDialog,
      private router: Router,
      private mediatorService: MediatorService,
      private talentCoachResource: TalentCoachResource,
      private talentEvaluationResource: TalentEvaluationResource,
      public utilityService: UtilityService,
      private dialogOpenService: DialogOpenService
  ) { }

  ngOnInit(): void {
    this.loadTalentAgent()
    this.getTalentEvaluation()
  }

  openAssignDialog() {
    this.dialogOpenService.openAssignDialog().then(result => {
      if (result) {
        this.mediatorService.talentAgentRequestedEvent.next()
        this.router.navigate(['talent/agent'])
      }
    })
  }

  loadTalentAgent() {
    this.loading = true
    this.talentCoachResource.getTalentCoach().then(result => {
      this.talentCoachData = result
      this.loading = false
    })
  }

  getTalentEvaluation() {
    this.talentEvaluationResource.getTalentEvaluation().then(result => {
      // this.talentEvaluationData = result
      this.talentEvaluationData = {
        password: null,
        testResultId: null,
        approved: null,
        evaluationId: 5,
        aecDiscIdentifier: 'dd',
        creationDate: new Date(),
        evaluationGroupId: 3,
        status: 'PENDING'
      }
      this.aecdCode = result.password
      if (result.testResultId) this.doneTest = true
      if (result.approved) this.testResult = true
    })
  }

  routeToAecdiscTest() {
    window.open(environment.aecDiscUrl, '_blank')
  }

  openAECdiscResult() {
    this.fileService.getEvaluationResult().subscribe(blob => {
      window.open(URL.createObjectURL(blob), '_blank')
    })
  }

  routeToFlexperto() {
    window.open(this.talentCoachData.talentAgent.flexpertoLink, '_blank');
  }
}
