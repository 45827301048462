import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
    ApplicationOutcomeReportedByTalentData, ApplicationState,
    ConfirmDialogComponent,
    TalentApplicationData,
    TalentHiringConfirmationResource,
    UtilityService
} from 'utility';
import {MediaService} from '../../services/inno-utils/media.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AddCvDialogComponent} from '../../talent-profile/add-cv-dialog/add-cv-dialog.component';
import {PositionService} from "../../services/position.service";
import {UploadAdditionalDocumentsForApplicationDialogComponent} from "./upload-additional-documents-for-application-dialog/upload-additional-documents-for-application-dialog.component";
import {Router} from "@angular/router";


@Component({
    selector: 'application-state-talentcard',
    templateUrl: './application-state-talentcard.component.html',
    styleUrls: ['./application-state-talentcard.component.scss']
})
export class ApplicationStateTalentcardComponent implements OnInit {

    @Input() talentApplication: TalentApplicationData;
    @Output() removeApplication: EventEmitter<any> = new EventEmitter();
    @Output() reload: EventEmitter<any> = new EventEmitter();

    stateText: string = '';

    draftText: string = 'Bewerbung erstellt';
    grantedText: string = 'Abgeschickt';
    InterestingText: string = 'Kontaktaufnahme';
    appProcessEnterText: string = 'Auswahlverfahren';
    hiredText: string = 'Eingestellt';
    contractSentText: string = 'Vertrag zugeschickt';

    talentHasOutcomeReported: boolean = true;
    companyLogoAvailable: boolean = false;
    companyLogoUrl;

    applicationStates: ApplicationState[] = ['Draft', 'Sent', 'Interesting', 'AppProcessEnter',  'ContractSent', 'Hired'];

    constructor(
        public mediaService: MediaService,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private confirmResource: TalentHiringConfirmationResource,
        private utilityService: UtilityService,
        private positionService: PositionService,
        private router : Router,
        @Inject('env') public env
    ) {
    }

    ngOnInit() {
        this.getApplicationReportState()
        this.getStateText()
        this.setProcessbarText();
        this.loadCompanyLogo();
    }

    setProcessbarText() {
        if (this.mediaService.mobileQuery.matches) {
            this.grantedText = 'Abgeschickt';
            this.InterestingText = 'Kontaktaufnahme';
            this.appProcessEnterText = 'Auswahlverfahren';
            this.hiredText = 'Eingestellt';
            this.contractSentText = 'Vertrag verschickt';
        }
    }

    loadCompanyLogo() {
        if (this.talentApplication.talentPositionRelation.employmentPosition.companyLogoUrl != null) {
            this.companyLogoUrl = this.talentApplication.talentPositionRelation.employmentPosition.companyLogoUrl
            this.companyLogoAvailable = true;
        } else {
            this.http.get(this.env.apiUrl + `/talent/positions/${this.talentApplication.talentPositionRelation.employmentPosition.id}/company/logo`, {
                headers: new HttpHeaders(),
                responseType: 'blob'
            }).subscribe((result) => {
                if (result.size > 0) {
                    this.companyLogoAvailable = true;
                }
                this.companyLogoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
            });
        }
    }

    getHeaderStepText(stateText: string, alternativeText: string): string {
        if (this.mediaService.getMaxWidthQuery(959).matches) {
            return alternativeText
        } else return stateText
    }

    openPositionDetailsDialog(preventOpening: boolean = false) {
        if(preventOpening) return
        if (!this.mediaService.getMaxWidthQuery(959).matches) {
            this.router.navigate(["/talent/positions"], {queryParams: {
                    positionIds: this.talentApplication?.talentPositionRelation?.employmentPosition?.id
                }})
        } else {
            this.positionService.openPositionDetails(this.talentApplication?.talentPositionRelation?.employmentPosition?.id, "Bookmarks")

        }
    }

    talentMarkedApplicationsAsFailed() {
        this.dialog.open(ConfirmDialogComponent, {
                data: {
                    onConfirm: () => this.reportApplicationOutcome(false),
                    title: `Deine Bewerbung für diese Stelle ist gescheitert?`,
                    message: `Bitte gib an, ob deine Bewerbung für diese Stelle gescheitert ist`,
                    confirmButtonText: 'Ich wurde nicht eingestellt',
                    confirmButtonColor: 'primary'
                }, panelClass: ['full-width-mobile-dialog', 'round-border-dialog']
            }
        );
    }

    talentMarkedApplicationsAsHired() {
        this.dialog.open(ConfirmDialogComponent, {
                data: {
                    onConfirm: () => this.reportApplicationOutcome(true),
                    title: `Du wurdest für diese Stelle eingestellt?`,
                    message: `Bitte gib an, ob das Unternehmen dich für diese Stelle eingestellt hat`,
                    confirmButtonText: 'Ich wurde eingestellt',
                    confirmButtonColor: 'primary'
                }, panelClass: ['full-width-mobile-dialog', 'round-border-dialog']
            }
        );
    }

    reportApplicationOutcome(hired: boolean): Promise<any> {
        let data: ApplicationOutcomeReportedByTalentData = {
            hired: hired,
            applicationId: this.talentApplication.id
        }

        return this.confirmResource.reportApplicationOutcome(data).then(result => {
            this.reload.emit(true)
            this.utilityService.showSnackBar('Danke für deine Rückmeldung')
        })
    }

    getApplicationReportState()  {
        this.confirmResource.hastTalentReportedAnOutcomeForApplication(this.talentApplication.id).then(result => {
            this.talentHasOutcomeReported = result
        })
    }

    isApplicationFinished(): boolean {
        let finishedStates = ['Hired', 'Failed', 'Withdrawn', 'Denied']
        return finishedStates.includes(this.talentApplication?.state?.toString())
    }

    getStateText() {
        switch (this.talentApplication.state) {

            case 'Granted':
            case 'Sent':
                this.stateText = 'Deine Bewerbung wurde an das Unternehmen geschickt.';
                break;

            case 'Hired':
                this.stateText = 'Glückwunsch zu deinem neuen Job! Es freut uns, dass wir dir helfen könnten. Lass uns gerne wissen wenn du Fragen zum Thema Karriere hast oder wir dir anders helfen können.';
                break;

            case 'AppProcessEnter':
                this.stateText = 'Das Unternehmen hat angegeben, dass du im Bewerbungsverfahren bist. Das ist eine Sache zwischen dir und der Firma. Wir unterstützen dich jedoch gerne, wenn du unsere Hilfe brauchst!'
                break;

            case 'Withdrawn':
                this.stateText = 'Du hast deine Kontaktdaten zurückgezogen. Falls die Firma dich trotzdem noch kontaktiert, weise sie einfach höflich darauf hin.';
                break;

            case 'Interesting':
                this.stateText = 'Top! Das Unternehmen hat dich als interessanten Kandidaten identifiziert und möchte Kontakt mit dir aufnehmen. Sei bereit, wenn das Telefon bimmelt.';
                break;

            case 'Failed':
                this.stateText = 'Leider hat es diesmal nicht geklappt. Aber das klappt schon noch! ';
                break;
        }

        if (this.talentApplication.talentPositionRelation.employmentPosition.employmentPositionOrigin != 'Uniwunder') this.stateText = 'Deine Bewerbung ist beim Unternehmen eingegangen.'
    }

    uploadCv(positionId: number) {
        let dialogRef = this.dialog.open(AddCvDialogComponent, {
            panelClass: ['full-width-mobile-dialog', 'round-border-dialog'],
            autoFocus: false
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.talentApplication.state = 'Sent';
                //this.loadEmploymentPosition(positionId)
                this.reload.emit();
                this.utilityService.showSnackBar('Deine Bewerbung wurde erfolgreich abgeschickt!')
            }
        })
    }

    openDemandAdditionalDocumentsDialog(talentApplication: TalentApplicationData) {
        let dialogRef = this.dialog.open( UploadAdditionalDocumentsForApplicationDialogComponent, {
            data: talentApplication,
            autoFocus: false,
            panelClass: ["full-width-mobile-dialog"]
        })

        let sub = dialogRef.afterClosed().subscribe(next => {
            if (next) talentApplication
            sub.unsubscribe()
        })
    }
}
