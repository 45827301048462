<div class="home-c-w" (click)="openShareOverlay()">
    <home-headline iconBgColor="#C54A7D" icon="heart">Teile Talentagent</home-headline>
    <div class="home-c-c-w" fxLayout="column" fxLayoutAlign="start center">
                <i style="font-size: 40px; color: #C54A7D" class="mdi mdi-heart"></i>
                <div class="text-center">Zeig deinen Freunden und Kommilitonen, wie einfach auch sie ihren ersten Job bekommen.</div>

                <button style="border-radius: 50px; margin-top: 16px; background-color: #C54A7D; color: #ffffff"  mat-flat-button >

                    <i style="color: #ffffff" class="mdi mdi-share"></i>
                    mit Freunden teilen
                </button>
    </div>
</div>