import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CoachingTypeData, CoachingTypeResource, TalentCoachResource} from 'utility';
import {Router} from '@angular/router';
import {MediatorService} from '../../services/mediator.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MediaService} from '../../services/inno-utils/media.service';
import {AssignTalentAgentDialogComponent} from '../assign-talent-agent-dialog/assign-talent-agent-dialog.component';
import { MlpInfoDialogComponent } from '../mlp-info-dialog/mlp-info-dialog.component';
import {DialogOpenService} from "../../services/dialog-open.service";

@Component({
  selector: 'app-request-talent-agent',
  templateUrl: './request-talent-agent.component.html',
  styleUrls: ['./request-talent-agent.component.scss']
})
export class RequestTalentAgentComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.scrolled = document.body.scrollTop > (window.innerHeight * 0.5)
      || document.getElementsByTagName('html')[0].scrollTop > (window.innerHeight * 0.5)
  }

  public coachingsForm = new FormGroup({});
  public availableCoachingTypes: CoachingTypeData[];
  public scrolled: boolean = false;

  constructor(
    public mediaService: MediaService,
    private coachingTypeResource: CoachingTypeResource,
    private talentCoachResource: TalentCoachResource,
    private mediatorService: MediatorService,
    private router: Router,
    private dialog: MatDialog,
    private dialogOpenService: DialogOpenService
  ) {
  }

  ngOnInit() {
    this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
      this.availableCoachingTypes = coachings;

      this.availableCoachingTypes.forEach(coaching => {
        this.coachingsForm.addControl(coaching.title, new FormControl<boolean>(false));
      });
    });
  }
  showMlpInfoDialog(){
    this.dialog.open(MlpInfoDialogComponent,{ panelClass: 'full-width-mobile-dialog'})
  }

  openAssignDialog() {
    this.dialogOpenService.openAssignDialog().then(result => {
      if (result) {
        this.mediatorService.talentAgentRequestedEvent.next()
        this.router.navigate(['talent/agent'])
      }
    })
  }
}
