import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {TalentAuthService} from "./talent.auth.service";
import {HidSnackbarService} from "../utility/hid-lib-clone/hid-snackbar/hid-snackbar.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RequestErrorInterceptorService implements HttpInterceptor {

  constructor(
    private snackBar: HidSnackbarService,
    private dialog: MatDialog,
    private authService: TalentAuthService,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        let errorMsg = "Ein Fehler ist aufgetreten. Bitte wende dich an den Support, wenn das Problem bestehen bleibt.";

        if (err.status == 500) {
          errorMsg = "Ein Serverfehler ist aufgetreten, das kann Funktionseinschränkungen zur Folge haben. In dringenden Fällen wende dich an uns.";
        }

        if (err.status == 404) {
          errorMsg = "Eine Serverressource konnte nicht geladen werden. Stelle sicher dass Deine Verbindung in Ordnung ist und lade die Seite ggf. neu";
        }
        if (err.status == 400) {
          errorMsg = "Eine Anfrage konnte nicht verarbeitet werden. Prüfe etwaige Eingaben auf Vollständigkeit und kontaktiere uns, wenn das Problem bestehen bleibt.";
        }
        //this is not a correct handling for 403, but this was needed as  a quick fix and currently has no negative consequence. However, you may wanna remove 403 later
        if (err.status == 401 || err.status == 403) {
          errorMsg = "Deine Sitzung ist leider abgelaufen. Bitte logge dich erneut ein."
          this.authService.logout();
        }

        if (this.authService.isAuthenticated() || err.status == 401) {
          // this.snackBar.openSnackBar(errorMsg, "Schließen", 10000, true)
        }
      }
      return throwError(err)
      }
    ));
  }
}
