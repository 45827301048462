import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  FieldOfActivityPreferenceSetting,
  LabelData,
  ProfessionFieldPreferenceResource,
} from "utility";
import {MediaService} from "../../../services/inno-utils/media.service";

@Component({
  selector: 'field-dropdown-content',
  templateUrl: './field-dropdown-content.component.html',
  styleUrls: ['./field-dropdown-content.component.scss']
})

export class FieldDropdownContentComponent implements OnInit {

  relevantFieldsWithoutInterest: LabelData[] = []
  showFullFieldsList: boolean = false
  updatingFields: boolean = false

  @Output() onFieldSelect: EventEmitter<LabelData> = new EventEmitter<LabelData>()

  constructor(
      private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
      public mediaService: MediaService,
  ) { }

  ngOnInit(): void {
    this.getProfessionFieldPreferences()
  }

  getProfessionFieldPreferences() {
    this.professionFieldPreferenceResource.getRelevantProfessionFieldPreferencesForTalent().then(result => {
      if (result) {
        this.relevantFieldsWithoutInterest = result.filter(it => it.preferenceScore != <FieldOfActivityPreferenceSetting>"Interesting").map(value => <LabelData>{
          id: value.fieldId,
          name: value.fieldName
        })
      }
    })
  }

  clickHandler(event) {
    event.stopPropagation()
  }

  addFieldToPreferencesAndStartHybridSearch(field: LabelData){
    this.updateTalentFieldPreferences(field)
    this.onFieldSelect.emit(field)
  }

  updateTalentFieldPreferences(field: LabelData) {
    this.updatingFields = true
    // this.talentMatchingPreferenceResource.updateProfessionFieldPreferences([field.id]).then(result =>{
      this.updatingFields = false
      this.getProfessionFieldPreferences()
    // })
  }
}
