<div  fxLayout="column" fxLayoutAlign="center center">
    <app-cv-parsing-o-step-headline>Suchst du Jobs in einer bestimmten Stadt oder sogar deutschlandweit?</app-cv-parsing-o-step-headline>

    <div class="step-content-ob">
        <mat-radio-group [formControl]="form.controls['mode']"  fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 20px;">
            <!--      <mat-radio-button [value]="'Worldwide'">Ich suche weltweit</mat-radio-button>-->
            <mat-radio-button [value]="'Nationwide'">Ich suche deutschlandweit</mat-radio-button>
            <mat-radio-button [value]="'SpecificCities'">Ich suche in der folgenden Stadt ...</mat-radio-button>
        </mat-radio-group>

        <location-input-search
                [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                (click)="form.controls.mode.setValue('SpecificCities')"
                style="width: 100%"
                label="Name oder PLZ"
                [control]="form.controls.preferredCityId"
                [forceValidation]="error"
        ></location-input-search>
        <div *ngIf="form.controls.mode.value == 'SpecificCities'" class="hint" style="transform: translateY(-20px);">Du kannst das Suchgebiet später noch genauer festlegen</div>
    </div>

    <app-cv-parsing-o-step-headline [marginTop]="30">
        Für welche Bereiche interessierst du dich besonders?
    </app-cv-parsing-o-step-headline>
    <!--    <sector-interest *ngIf="options" [options]="options"></sector-interest>-->
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div class="preference-option" [ngClass]="{'selected': selectedPreferenceData.indexOf(option) > -1 }"
             *ngFor="let option of preferenceData"
             (click)="selectPreference(option)">{{option.name}}</div>
    </div>
</div>

<app-cv-parsing-o-button-bar (pressedNext)="progress()"></app-cv-parsing-o-button-bar>
