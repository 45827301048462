<div class="robot-wrapper">
    <div class="robot-inner-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" fxLayout.lt-md="column"
         [ngStyle]="{'background': blueBackground? '#00C8BE' : '#ffffff'}">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <img class="avatar-image" src="../../../assets/images/avatar.svg">
            <div class="avatar-text"><ng-content></ng-content></div>
        </div>

        <div *ngIf="buttonText || icon">
            <div *ngIf="!buttonLoading">
            <button (click)="clickEvent.emit(true)" mat-button color="accent" *ngIf="!buttonPrimary" [class]="buttonClass">
                <i *ngIf="icon" [class]="'mdi mdi-' + icon" [ngClass]="{'m-right8': !mediaService.getMaxWidthQuery(959).matches}"></i>
                <span *ngIf="icon">{{buttonText}}</span>
            </button>


            <button (click)="clickEvent.emit(true)" mat-flat-button color="primary" *ngIf="buttonPrimary" [class]="buttonClass">
                <i *ngIf="icon" [class]="'mdi mdi-' + icon" [ngClass]="{'m-right8': !mediaService.getMaxWidthQuery(959).matches}"></i>
                <span *ngIf="icon">{{buttonText}}</span>
            </button>
            </div>

            <div *ngIf="buttonLoading">
                <button mat-flat-button color="accent"
                        [class]="buttonClass"
                        (click)="clickEvent.emit(true)"
                        *ngIf="buttonPrimary"
                        [loadingBool]="loading">
                    <i *ngIf="icon" [class]="'mdi mdi-' + icon" [ngClass]="{'m-right8': !mediaService.getMaxWidthQuery(959).matches}"></i>
                    <span *ngIf="icon">{{buttonText}}</span>
                </button>
            </div>
        </div>

    </div>
</div>
