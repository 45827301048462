import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.unisign.api.LoginCodeController
 */

@Injectable()
@ResourceParams({})
export class LoginCodeResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/code/generate/{emailAddress}',
        endpoint: 'com.uniwunder.unisign'
    })
    private _generateCode: IResourceMethodObservableStrict<null, {validForMinutes: number, singleUse: boolean}, {emailAddress: string}, JsonWrappedValue<string>> | undefined
    generateCode(emailAddress: string, requestParams: {validForMinutes: number, singleUse: boolean}): Promise<JsonWrappedValue<string>> {
        if (!this._generateCode) throw new Error("resource has not been properly initialized")
        return this._generateCode(null, requestParams, {emailAddress: emailAddress}).toPromise()
    }

}