<div class="home-wrapper">
    <!--    <div class="headline" fxHide fxShow.gt-md>Home</div>-->
    <div *ngIf="!isMobile" class="home-grid-desktop" id="home-grid-desktop">

<!--        <app-jooble-tile-desktop *ngIf="isGraduate === false" class="box1"></app-jooble-tile-desktop>-->
<!--        <share-home-desktop *ngIf="!(isGraduate === false)"  class="box1"></share-home-desktop>-->
        <career-fairy-tile-desktop class="box1"></career-fairy-tile-desktop>

        <div class="box2">
            <matching-home-desktop></matching-home-desktop>
<!--            <mat-carousel-->
<!--                    *ngIf="dashboardStatus"-->
<!--                    timings="250ms ease-in"-->
<!--                    [autoplay]="true"-->
<!--                    interval="8000"-->
<!--                    color="white"-->
<!--                    maxWidth="auto"-->
<!--                    [maintainAspectRatio]="false"-->
<!--                    [loop]="true"-->
<!--                    [hideArrows]="true"-->
<!--                    [hideIndicators]="false"-->
<!--                    [useKeyboard]="true"-->
<!--                    [useMouseWheel]="false"-->
<!--                    orientation="ltr"-->
<!--            >-->
<!--                <mat-carousel-slide-->
<!--                        *ngIf="dashboardStatus?.openRecommendations"-->
<!--                        #matCarouselSlide-->
<!--                        overlayColor="#00000040"-->
<!--                        [hideOverlay]="false"-->
<!--                >-->
<!--                    <recommendations-home-desktop></recommendations-home-desktop>-->
<!--                </mat-carousel-slide>-->

<!--                <mat-carousel-slide-->
<!--                    #matCarouselSlide-->
<!--                    overlayColor="#00000040"-->
<!--                    [hideOverlay]="false"-->
<!--                >-->
<!--                    <matching-home-desktop></matching-home-desktop>-->
<!--                </mat-carousel-slide>-->


<!--            </mat-carousel>-->
        </div>
        <applications-home-desktop *ngIf="dashboardStatus?.cvActuallyUploaded"
                                   class="box3"></applications-home-desktop>
        <cv-upload-home-desktop *ngIf="!dashboardStatus?.cvActuallyUploaded"
                                class="box3"></cv-upload-home-desktop>
        <bookmarks-home-desktop class="box4"></bookmarks-home-desktop>
        <coach-home-desktop class="box5"></coach-home-desktop>
        <share-home-desktop  class="box6"></share-home-desktop>
<!--        <staufenbiel-tile-desktop class="box6"></staufenbiel-tile-desktop>-->
    </div>

    <div *ngIf="isMobile && (dashboardStatus?.openRecommendations || !dashboardStatus?.cvActuallyUploaded)" class="home-grid-mobile" id="home-grid-mobile">
        <cv-upload-home-mobile
                *ngIf="!dashboardStatus?.openRecommendations && !dashboardStatus?.cvActuallyUploaded"
                class="box1"></cv-upload-home-mobile>
        <recommendations-home-mobile-big *ngIf="dashboardStatus?.openRecommendations"
                                         class="box1"></recommendations-home-mobile-big>
        <matching-home-mobile-big

                class="box2" ></matching-home-mobile-big>
        <bookmarks-home-mobile class="box3"></bookmarks-home-mobile>
        <applications-home-mobile class="box4"></applications-home-mobile>
        <coach-home-mobile class="box5"></coach-home-mobile>
        <!--        <explanation-home-mobile  class="box7"></explanation-home-mobile>-->

        <career-fairy-tile-mobile class="box6"></career-fairy-tile-mobile>
<!--        <share-home-mobile *ngIf="!(isGraduate === false)" class="box7"></share-home-mobile>-->


<!--        <event-all-tile-mobile class="box8"></event-all-tile-mobile>-->
                <share-home-mobile  class="box7"></share-home-mobile>
        <!--        <staufenbiel-tile-mobile class="box8"></staufenbiel-tile-mobile>-->
        <!--        <event-tile-mobile [eventId]="74131776" class="box8"></event-tile-mobile>-->

    </div>

    <!-- I know this is quite ugly - the only reason why I did it not properly is that this grid is quite a bit hacky tracky anyways (looking at you Max) and the whole Dashboard is going to be reworked soon anyways. -->
    <div *ngIf="isMobile && !(dashboardStatus?.openRecommendations || !dashboardStatus?.cvActuallyUploaded)" class="home-grid-mobile-small" id="home-grid-mobile">
        <matching-home-mobile-big
                class="box1" ></matching-home-mobile-big>
        <bookmarks-home-mobile class="box2"></bookmarks-home-mobile>
        <applications-home-mobile class="box3"></applications-home-mobile>
        <coach-home-mobile class="box4"></coach-home-mobile>
        <career-fairy-tile-mobile class="box5"></career-fairy-tile-mobile>
        <share-home-mobile  class="box6"></share-home-mobile>
    </div>
</div>
