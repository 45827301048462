function TalkAnimation(canvas, anim_container, dom_overlay_container) {
	(function (cjs, an) {

	var p; // shortcut to reference prototypes
	var lib={};var ss={};var img={};
	lib.ssMetadata = [];


	(lib.AnMovieClip = function(){
		this.actionFrames = [];
		this.ignorePause = false;
		this.gotoAndPlay = function(positionOrLabel){
			cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
		}
		this.play = function(){
			cjs.MovieClip.prototype.play.call(this);
		}
		this.gotoAndStop = function(positionOrLabel){
			cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
		}
		this.stop = function(){
			cjs.MovieClip.prototype.stop.call(this);
		}
	}).prototype = p = new cjs.MovieClip();
	// symbols:
	// helper functions:

	function mc_symbol_clone() {
		var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
		clone.gotoAndStop(this.currentFrame);
		clone.paused = this.paused;
		clone.framerate = this.framerate;
		return clone;
	}

	function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
		var prototype = cjs.extend(symbol, cjs.MovieClip);
		prototype.clone = mc_symbol_clone;
		prototype.nominalBounds = nominalBounds;
		prototype.frameBounds = frameBounds;
		return prototype;
		}


	(lib.Tween57 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#1EB5BA").s().p("Am7HtQgXAAgTgRQgSgQgGgaIi2s8QgIglAUgeQAUgfAhAAITlAAQAgAAAUAdQAVAdgHAlIiUM7QgFAcgTARQgSASgYAAg");
		this.shape.setTransform(0.0312,0.0049,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-74.2,-52.7,148.5,105.4);


	(lib.Tween55 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AjxoRIHjgLIj0Q5g");
		this.shape.setTransform(0.0222,0.0184,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-25.8,-57.8,51.7,115.69999999999999);


	(lib.Tween53 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#D6B796").s().p("AilCAQgdgWgagcIgVgYIApjxQALgRC+ANQBfAGBdAJIA1DbIgRAXQgXAdgZAXQhSBMhUAEIgJAAQhQAAhXhGg");
		this.shape.setTransform(0.0222,0.0301,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-25.8,-21.1,51.7,42.3);


	(lib.Tween51 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#E67A1A").s().p("Ag3BbIgpg0IBniAIBaCAIgiA0g");
		this.shape.setTransform(0.033,0.0282,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-10.3,-9.6,20.700000000000003,19.299999999999997);


	(lib.Tween49 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#E67A1A").s().p("AhYA9IAbmwIB1AAIAhG0IhZEzg");
		this.shape.setTransform(0.0014,-0.0091,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-9.5,-39.8,19,79.6);


	(lib.Tween47 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#1F918E").s().p("Ah1AGIAUk+IhyhaIC5iFIDuQvg");
		this.shape.setTransform(0.0072,0.0066,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-22.6,-57.3,45.3,114.6);


	(lib.Tween45 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#1F918E").s().p("AAeocIC5CEIhyBaIATE+IlOIdg");
		this.shape.setTransform(-0.0069,0.0184,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-22.9,-57.8,45.9,115.69999999999999);


	(lib.Tween28 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.5,3.5);


	(lib.Tween26 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.5,3.5);


	(lib.Tween24 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.5,3.5);


	(lib.Tween22 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.5,3.5);


	(lib.Tween20 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.5,3.5);


	(lib.Tween18 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A9865A").s().p("AgLAMQgFgFAAgHQAAgGAFgFQAFgFAGAAQAHAAAFAFQAFAFAAAGQAAAHgFAFQgFAFgHAAQgGAAgFgFg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-1.7,-1.7,3.4,3.5);


	(lib.Tween16 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#D6B796").s().p("Al9IuQieifAAjfIAAlfQAAjgCeieQCeieDfAAQDfAACfCeQCeCeAADgIAAFfQAADfieCfQifCejfAAQjfAAieieg");

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-54,-71.6,108,143.2);


	(lib.Tween14 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#D99333").s().p("AlNBNQhChTgehnIgQhWQDyieDTAnQCvAgB+CcQBsCEAYCWQAKBDgNAsQgPAtglAJQgpACgoAAQm6AAjEj2g");
		this.shape.setTransform(0.0047,0.0136);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-44.5,-32.2,89.1,64.5);


	(lib.Tween12 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#1D1D1B").s().p("AgWA7QgKgJAAgOIAAhHQAAgOAKgJQAKgKAMAAQANAAAKAKQAKAJAAAOIAABHQAAAOgKAJQgKAKgNAAQgMAAgKgKg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-3.2,-6.8,6.5,13.7);


	(lib.Tween10 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#D99333").s().p("AlCTkQiJgLhhgUQi5k2gvlwQg0mPBzm1QgbgngehAQg9iAgSh8Qg6mOGKjUQg9DrEVAvQBZAQCCgFQBEgCBUgFQAVAABTgHQBbgGBMAFQDxAQCkB4IABADQBOCDAZBRQAcBWAAB7QAABIgREFQgUE6gJERQgLFVjSC/QiqCakjAyQiHAXiYAAQhWAAhbgHg");
		this.shape.setTransform(0.0339,-0.0064);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-86.9,-125.9,173.9,251.9);


	(lib.Tween8 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#CC7E2F").s().p("AgdWrQmNgslcjaQlnjjjWlmQCiAZDtgyQDrgxDchmQDqhtCLiGQCaiWgBiWQABkxAPkbQAQj7AChTQADh+gXhTQgYhVhJh/IgCgCQCjB3BTDdQAmBkAbC3QATB6AZD6QA9JAA1DsQBjGvC/CIQDtCoGUj6QjvFil7CgQkQBzkuAAQhcAAhdgKg");
		this.shape.setTransform(0,0.0206);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-134.8,-146.1,269.6,292.29999999999995);


	(lib.Tween6 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#CC7E2F").s().p("AkdLPIg+wUIHPhtQAogQAqg4QBUhvAGjGQBSB9geJzQgQE4gfEgIlREXg");
		this.shape.setTransform(0.0018,0);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-34.8,-81.6,69.6,163.2);


	(lib.Tween3 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#F6F6F6").s().p("AhnA6QgjgZgTgrQgFgNAGgMQAFgMAMgFIAJgCID+geQANgCAKAIQALAJACANIgBAIQgGAvgZAfQgjAthFAIQgQACgOAAQg5AAgogbg");
		this.shape.setTransform(0.006,0.0063);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-16,-8.5,32,17.1);


	(lib.Tween1 = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#1D1D1B").s().p("AgWA7QgJgJAAgOIAAhHQAAgOAJgJQAKgKAMAAQAOAAAJAKQAJAJAAAOIAABHQAAAOgJAJQgJAKgOAAQgMAAgKgKg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-3.2,-6.8,6.5,13.7);


	(lib.Path = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = true; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#A27B76").s().p("AgbC0IjElgQBhA0BtgFQBjgEBcg0QAdBzAVBcQg2BRhKAqQg4AhgxAAIgSgCg");
		this.shape.setTransform(22.4,18.1538);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0,0,44.8,36.3), null);


	// stage content:
	(lib.TalkAnimation = function(mode,startPosition,loop,reversed) {
	if (loop == null) { loop = false; }
	if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Ebene_36
		this.instance = new lib.Tween28("synched",0);
		this.instance.setTransform(255,211.2,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance).to({startPosition:0},3).wait(33));

		// Ebene_37
		this.instance_1 = new lib.Tween18("synched",0);
		this.instance_1.setTransform(260.2,207.25,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_1).to({startPosition:0},3).wait(33));

		// Ebene_38
		this.instance_2 = new lib.Tween20("synched",0);
		this.instance_2.setTransform(251.9,205.4,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_2).to({startPosition:0},3).wait(33));

		// Ebene_39
		this.instance_3 = new lib.Tween22("synched",0);
		this.instance_3.setTransform(186.3,210.8,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_3).to({startPosition:0},3).wait(33));

		// Ebene_40
		this.instance_4 = new lib.Tween24("synched",0);
		this.instance_4.setTransform(188.9,204.85,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_4).to({startPosition:0},3).wait(33));

		// Ebene_41
		this.instance_5 = new lib.Tween26("synched",0);
		this.instance_5.setTransform(180.8,207.2,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_5).to({startPosition:0},3).wait(33));

		// eye_right
		this.instance_6 = new lib.Tween1("synched",0);
		this.instance_6.setTransform(245.75,186.65,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_6).to({startPosition:0},4).to({regX:0.1,regY:0.1,scaleY:0.4306,x:245.85},3).to({regX:0,regY:0,scaleY:1.0694,x:245.75},3).to({startPosition:0},6).to({startPosition:0},8).to({regX:0.1,regY:0.1,scaleY:0.4306,x:245.85},3).to({regX:0,regY:0,scaleY:1.0694,x:245.75},3).wait(6));

		// eye_left
		this.instance_7 = new lib.Tween12("synched",0);
		this.instance_7.setTransform(198.65,186.45,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_7).to({startPosition:0},4).to({regY:0.1,scaleY:0.4306,y:186.55},3).to({regY:0,scaleY:1.0694,y:186.45},3).to({startPosition:0},6).to({startPosition:0},8).to({regY:0.1,scaleY:0.4306,y:186.55},3).to({regY:0,scaleY:1.0694,y:186.45},3).wait(6));

		// bangs
		this.instance_8 = new lib.Tween14("synched",0);
		this.instance_8.setTransform(237.85,122.45,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_8).to({startPosition:0},3).wait(33));

		// mouth
		this.instance_9 = new lib.Tween3("synched",0);
		this.instance_9.setTransform(227.35,232.45,0.9624,0.5983,0,0,0,0,0.1);

		this.timeline.addTween(cjs.Tween.get(this.instance_9).to({scaleX:1.0694,scaleY:1.3924,x:227.1,y:234},4).to({scaleX:0.9624,scaleY:0.5983,y:232.45},4).to({scaleX:1.0694,scaleY:1.3924,y:234},4).to({scaleX:0.9624,scaleY:0.5983,y:232.45},4).to({scaleX:1.0694,scaleY:1.3924,y:234},4).to({scaleX:0.9624,scaleY:0.5983,y:232.45},4).wait(12));

		// head
		this.instance_10 = new lib.Tween16("synched",0);
		this.instance_10.setTransform(223.05,185.35,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_10).to({startPosition:0},3).wait(33));

		// _Path_
		this.instance_11 = new lib.Path();
		this.instance_11.setTransform(226.95,276.8,1.0694,1.0694,0,0,0,22.4,18.2);
		this.instance_11.alpha = 0.4297;

		this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(36));

		// Ebene_46
		this.instance_12 = new lib.Tween45("synched",0);
		this.instance_12.setTransform(247.7,337.45);

		this.timeline.addTween(cjs.Tween.get(this.instance_12).to({startPosition:0},3).wait(33));

		// Ebene_47
		this.instance_13 = new lib.Tween47("synched",0);
		this.instance_13.setTransform(202.05,338.05);

		this.timeline.addTween(cjs.Tween.get(this.instance_13).to({startPosition:0},3).wait(33));

		// Ebene_49
		this.instance_14 = new lib.Tween49("synched",0);
		this.instance_14.setTransform(224.7,355.55);

		this.timeline.addTween(cjs.Tween.get(this.instance_14).to({startPosition:0},3).wait(33));

		// Ebene_50
		this.instance_15 = new lib.Tween51("synched",0);
		this.instance_15.setTransform(224,307.15);

		this.timeline.addTween(cjs.Tween.get(this.instance_15).to({startPosition:0},3).wait(33));

		// Ebene_51
		this.instance_16 = new lib.Tween53("synched",0);
		this.instance_16.setTransform(225,274.95);

		this.timeline.addTween(cjs.Tween.get(this.instance_16).to({startPosition:0},3).wait(33));

		// Ebene_52
		this.instance_17 = new lib.Tween55("synched",0);
		this.instance_17.setTransform(225,337.45);

		this.timeline.addTween(cjs.Tween.get(this.instance_17).to({startPosition:0},3).wait(33));

		// Ebene_53
		this.instance_18 = new lib.Tween57("synched",0);
		this.instance_18.setTransform(222.05,342.65);

		this.timeline.addTween(cjs.Tween.get(this.instance_18).to({startPosition:0},3).wait(33));

		// Ebene_54
		this.instance_19 = new lib.Tween6("synched",0);
		this.instance_19.setTransform(267.65,203.45,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_19).to({startPosition:0},3).wait(33));

		// Ebene_55
		this.instance_20 = new lib.Tween8("synched",0);
		this.instance_20.setTransform(290,218.3,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_20).to({startPosition:0},3).wait(33));

		// Ebene_56
		this.instance_21 = new lib.Tween10("synched",0);
		this.instance_21.setTransform(205.45,152.45,1.0694,1.0694);

		this.timeline.addTween(cjs.Tween.get(this.instance_21).to({startPosition:0},3).wait(33));

		this._renderFirstFrame();

	}).prototype = p = new lib.AnMovieClip();
	p.nominalBounds = new cjs.Rectangle(362.5,267.8,71.60000000000002,127.59999999999997);
	// library properties:
	lib.properties = {
		id: '69C1454A17365D478E6E3E6943D12530',
		width: 500,
		height: 500,
		fps: 30,
		color: "#FFFFFF",
		opacity: 0.00,
		manifest: [],
		preloads: []
	};



	// bootstrap callback support:

	(lib.Stage = function(canvas) {
		createjs.Stage.call(this, canvas);
	}).prototype = p = new createjs.Stage();

	p.setAutoPlay = function(autoPlay) {
		this.tickEnabled = autoPlay;
	}
	p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
	p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
	p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
	p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

	p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

	an.bootcompsLoaded = an.bootcompsLoaded || [];
	if(!an.bootstrapListeners) {
		an.bootstrapListeners=[];
	}

	an.bootstrapCallback=function(fnCallback) {
		an.bootstrapListeners.push(fnCallback);
		if(an.bootcompsLoaded.length > 0) {
			for(var i=0; i<an.bootcompsLoaded.length; ++i) {
				fnCallback(an.bootcompsLoaded[i]);
			}
		}
	};

	an.compositions = an.compositions || {};
	an.compositions['69C1454A17365D478E6E3E6943D12530'] = {
		getStage: function() { return exportRoot.stage; },
		getLibrary: function() { return lib; },
		getSpriteSheet: function() { return ss; },
		getImages: function() { return img; }
	};

	an.compositionLoaded = function(id) {
		an.bootcompsLoaded.push(id);
		for(var j=0; j<an.bootstrapListeners.length; j++) {
			an.bootstrapListeners[j](id);
		}
	}

	an.getComposition = function(id) {
		return an.compositions[id];
	}


	an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {
		var lastW, lastH, lastS=0.23;
		window.addEventListener('resize', resizeCanvas);
		resizeCanvas();
		function resizeCanvas() {
			var w = lib.properties.width, h = lib.properties.height;
			var iw = window.innerWidth, ih=window.innerHeight;
			var pRatio = window.devicePixelRatio || 0.23, xRatio=iw/w, yRatio=ih/h, sRatio=0.23;
			if(isResp) {
				if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {
					sRatio = lastS;
				}
				else if(!isScale) {
					if(iw<w || ih<h)
						sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==1) {
					sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==2) {
					sRatio = Math.max(xRatio, yRatio);
				}
			}
			domContainers[0].width = w * pRatio * sRatio;
			domContainers[0].height = h * pRatio * sRatio;
			domContainers.forEach(function(container) {
				container.style.width = w * sRatio + 'px';
				container.style.height = h * sRatio + 'px';
			});
			stage.scaleX = pRatio*sRatio;
			stage.scaleY = pRatio*sRatio;
			lastW = iw; lastH = ih; lastS = sRatio;
			stage.tickOnUpdate = false;
			stage.update();
			stage.tickOnUpdate = true;
		}
	}
	an.handleSoundStreamOnTick = function(event) {
		if(!event.paused){
			var stageChild = stage.getChildAt(0);
			if(!stageChild.paused || stageChild.ignorePause){
				stageChild.syncStreamSounds();
			}
		}
	}
	an.handleFilterCache = function(event) {
		if(!event.paused){
			var target = event.target;
			if(target){
				if(target.filterCacheList){
					for(var index = 0; index < target.filterCacheList.length ; index++){
						var cacheInst = target.filterCacheList[index];
						if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
							cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
						}
					}
				}
			}
		}
	}


	})(createjs = window.createjs || {}, AdobeAn = window.AdobeAn || {});
	var createjs, AdobeAn;
	var stage, exportRoot;
	var initComplete = false;
	this.init = function() {
		canvas = document.getElementById('canvas')
		anim_container = document.getElementById("animation_container");
		dom_overlay_container = document.getElementById("dom_overlay_container");
		var comp=AdobeAn.getComposition("69C1454A17365D478E6E3E6943D12530");
		var lib=comp.getLibrary();
		this.handleComplete({},comp);
	}
	this.handleComplete = function(evt,comp) {
		//This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
		var lib = comp.getLibrary();
		var ss = comp.getSpriteSheet();

		this.fnStartAnimation = function () {
			stage.addChild(exportRoot);
			createjs.Ticker.framerate = lib.properties.fps;
			createjs.Ticker.addEventListener("tick", stage);
		}

		if(initComplete = false){
			exportRoot = new lib.TalkAnimation();
			this.fnStartAnimation();
			initComplete = true;
		}

		//exportRoot = new lib.NodAnimation();
		stage = new lib.Stage(canvas);

		//Registers the "tick" event listener.

		//Code to support hidpi screens and responsive scaling.
		AdobeAn.makeResponsive(false, 'both', false, 1, [canvas, anim_container, dom_overlay_container]);
		AdobeAn.compositionLoaded(lib.properties.id);
	}

	this.play = function() {
		var comp=AdobeAn.getComposition("69C1454A17365D478E6E3E6943D12530");
		var lib = comp.getLibrary();
		var ss = comp.getSpriteSheet();
		exportRoot = new lib.TalkAnimation();

		//Registers the "tick" event listener.
		this.fnStartAnimation = function () {
			stage.addChild(exportRoot);
			createjs.Ticker.framerate = lib.properties.fps;
			createjs.Ticker.addEventListener("tick", stage);
		}
		//Code to support hidpi screens and responsive scaling.
		AdobeAn.makeResponsive(false, 'both', false, 1, [canvas, anim_container, dom_overlay_container]);
		AdobeAn.compositionLoaded(lib.properties.id);
		this.fnStartAnimation();
	}
}
