import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, ValidatorFn} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {PhoneNumber, PhoneNumberUtil} from "google-libphonenumber";
import {UtilsService} from "utility";

@Component({
  selector: 'hid-input-phone-number',
  templateUrl: './hid-input-phone-number.component.html',
  styleUrls: ['./hid-input-phone-number.component.scss']
})
export class HidInputPhoneNumberComponent implements OnInit {

    blockCountryCodeDropdownClose: boolean
    showCountryCodeDropdown: boolean
    phoneUtil = PhoneNumberUtil.getInstance();

    @Input() control: AbstractControl
    @Input() forceValidation: Subject<boolean>
    @Input() showValidatorIcons: boolean
    @Input() showClearButton: boolean = false
    @Input() inputFieldClass: string = ''
    @Input() disabled: boolean
    @Input() name: string
    @Input() label: string
    @Input() type: string

    selectedEUCountryCode: string = "de"

    subControl: UntypedFormControl = new UntypedFormControl(null, this.subValidator())

    euCountries: EUCountry[] = [
        {code: "be", name: "Belgien"},
        {code: "bg", name: "Bulgarien"},
        {code: "dk", name: "Dänemark"},
        {code: "de", name: "Deutschland"},
        {code: "ee", name: "Estland"},
        {code: "fi", name: "Finnland"},
        {code: "fr", name: "Frankreich"},
        {code: "gr", name: "Griechenland"},
        {code: "ie", name: "Irland"},
        {code: "it", name: "Italien"},
        {code: "hr", name: "Kroatien"},
        {code: "lv", name: "Lettland"},
        {code: "lt", name: "Litauen"},
        {code: "lu", name: "Luxemburg"},
        {code: "mt", name: "Malta"},
        {code: "nl", name: "Niederlande"},
        {code: "at", name: "Österreich"},
        {code: "pl", name: "Polen"},
        {code: "pt", name: "Portugal"},
        {code: "ro", name: "Rumänien"},
        {code: "se", name: "Schweden"},
        {code: "sk", name: "Slowakei"},
        {code: "si", name: "Slowenien"},
        {code: "es", name: "Spanien"},
        {code: "cz", name: "Tschechien"},
        {code: "hu", name: "Ungarn"},
        {code: "cy", name: "Zypern"}
    ]

    constructor(
        public utilsService: UtilsService
    ) {    }

    ngOnInit(): void {
        this.control.setValidators(this.subValidator())
        if(this.control.value) {
            this.subControl.setValue(this.phoneUtil.parseAndKeepRawInput(this.control.value, this.selectedEUCountryCode.toUpperCase()).getNationalNumber().toString())
            this.control.markAsTouched()
            this.subControl.markAsTouched()
        }
    }

    subValidator():ValidatorFn{
        return (subControl: AbstractControl): { [key: string]: any } | null => {
            if(!subControl.value || this.utilsService.checkPhoneNumberReturnErrors(subControl.value, this.selectedEUCountryCode) !== null) {
                if(this.control?.value != subControl.value) this.control?.setValue(subControl.value) //for error handling
                return {isNotPhoneNumber: true}
            }
            let number = this.phoneUtil.parseAndKeepRawInput(this.subControl.value, this.selectedEUCountryCode.toUpperCase())
            let finalNumber = "+" + number.getCountryCode() + number.getNationalNumber()
            if (this.control.value != finalNumber) this.control.setValue(finalNumber)
            return null
        }
    }

    handleCountryCodeDropdown(){
        this.blockCountryCodeDropdownClose = true
        this.showCountryCodeDropdown = !this.showCountryCodeDropdown;
        setInterval(() => {
            this.blockCountryCodeDropdownClose = false
        }, 500)
    }

    onClickOutside($event: any){
        if(this.blockCountryCodeDropdownClose) return
        this.showCountryCodeDropdown = false
    }

    onClickCode(countryCode:string) {
        this.selectedEUCountryCode = countryCode
        if (this.blockCountryCodeDropdownClose) return
        this.showCountryCodeDropdown = false
        this.subControl.updateValueAndValidity()
    }
}

export interface EUCountry {
    code: string,
    name: string
}

