import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'homeOffice'
})
@Injectable({
    providedIn: 'root'
})
export class HomeOfficePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    switch (value) {
      case "HomeOfficeAvailable":
        return "teilweise Home-Office";
      case "OnlyOffice":
        return "100% vor Ort";
      case "OnlyRemote":
        return "Home-Office";
    }
  }


}
