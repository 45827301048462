import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {delay} from "utility";

@Component({
  selector: 'ta-search-bar',
  templateUrl: './ta-search-bar.component.html',
  styleUrls: ['./ta-search-bar.component.scss']
})
export class TaSearchBarComponent implements OnInit {

  @Output() search: EventEmitter<string> = new EventEmitter<string>()
  @Output() searchStringChange: EventEmitter<string> = new EventEmitter<string>()

  @Input() placeholder: string = "Titel, Skill, Schwerpunkt (Java, Controller...)"
  @Input() searchString: string = ""
  @Input() keyWordMinLength: number = 3

  keywordValid: boolean = false
  invalidInputTry: boolean = false

  constructor() { }

  ngOnInit(): void {
    this.checkIfKeywordIsValid()
  }

  inputChange() {
    this.searchStringChange.emit(this.searchString)
  }

  clear(){
    this.searchString = '';
    this.inputChange();
  }

  sendKeyword(){
    if(this.searchString.length < this.keyWordMinLength){
      return
    }
    else{
      if(this.keywordValid){
        this.search.emit(this.searchString)
        this.searchString = '';
      }
      else{
        this.invalidInputTry = true
        delay(1000).then( () =>{
          this.invalidInputTry = false
        })
      }
    }
  }

  checkIfKeywordIsValid(){
    if(this.searchString){
      if(this.searchString.length > 2){
        this.keywordValid = true
      }
      else{
        this.keywordValid = false
      }
    }
  }

}
