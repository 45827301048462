<div id="buttons" [style.padding]="padding" fxLayout="row" fxLayoutAlign="space-between center">

    <div id="buttons-inner" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
        <button *ngIf="backButton" [disabled]="buttonsLocked" mat-button (click)="back()">{{backLabel}}</button>
        <button *ngIf="skipButton" [disabled]="buttonsLocked || skipLock" mat-button color="accent" (click)="pressedSkip.emit()">{{skipLabel}}</button>
        <div style="position:relative;">
            <button [disabled]="!valid" [loadingBool]="buttonsLocked" [class.small-action-button]="smallActionButton" mat-flat-button color="accent" (click)="pressedNext.emit()"><span>{{nextLabel}}</span></button>
        </div>
    </div>

</div>
