import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyProfilePreviewComponent} from "./company-profile-preview/company-profile-preview.component";
import {RouterModule, Routes} from "@angular/router";
import {UtilityModule} from "utility";
import {CompanyProfileModule} from "../company-profiles/company-profile.module";

const appRoutes: Routes = [
    {path: 'company-profile-preview/:companyId', component: CompanyProfilePreviewComponent},
]

@NgModule({
    declarations: [
        CompanyProfilePreviewComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        UtilityModule,
        CompanyProfileModule
    ]
})
export class CompanyPreviewModule {
}
