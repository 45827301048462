import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberValidationComponent } from './phone-number-validation/phone-number-validation.component';
import {UtilityModule} from "utility";
import {RouterModule, Routes} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {AuthGuardService} from "../services/auth-guard.service";



const appRoutes: Routes = [
    {
        path: 'phoneNumberValidation', component: PhoneNumberValidationComponent, canActivate: [AuthGuardService]
    }
]

@NgModule({
  declarations: [PhoneNumberValidationComponent],
    exports: [
        RouterModule,
    ],
    imports: [
        RouterModule.forChild(appRoutes),
        BrowserAnimationsModule,
        CommonModule,
        UtilityModule,
        BrowserModule
    ]
})
export class PhoneNumberValidationModule { }
