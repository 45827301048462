import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationState'
})
export class ApplicationStatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'Draft':
        return 'Bewerbungsentwurf';
      case 'Pending':
      case 'Granted':
      case 'Sent':
        return 'Bewerbung Abgeschickt';
      case 'Denied':
      case 'Failed':
        return 'Fehlgeschlagen';
      case 'Withdrawn':
        return 'Zurückgezogen';
      case 'Interesting':
        return 'Als Interessant markiert'
      case 'AppProcessEnter':
        return 'Im Bewerbungsprozess'
      case 'ContractSent':
        return 'Vertrag verschickt'
      case 'Hired':
        return 'Eingestellt'
      default: return value
    }
  }

}
