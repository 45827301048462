export const environment = {
    production: true,
    apiHost: '$$API_HOST$$',
    apiUrl: 'https://$$API_HOST$$/main',
    cronosApiUrl: 'https://$$CRONOS_HOST$$',
    publicPageUrl: 'https://$$PUBLICFE_HOST$$',
    aecDiscUrl: 'https://$$AECDISC_URL$$',
    piwikContainerUrl: '$$PIWIK_CONTAINER_URL$$',
    piwikContainerId: '$$PIWIK_CONTAINER_ID$$',
    uniSignUrl: 'https://$$UNISIGN_HOST$$',
    frontendUnisignClientId: '$$FRONTEND_UNISIGN_CLIENT$$',
    uniSignHost: '$$UNISIGN_HOST$$',
    appLoginRedirectUri: 'https://$$TALENTS_FE_URL$$/talent/login',
};
