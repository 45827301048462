    <div class="home-c-headline-w">
        <div class="home-c-headline">
            <div class="home-c-icon" [ngStyle]="{'background-color': iconBgColor}">
                <i [class]="getIcon()" [ngStyle]="{'color': iconColor}"></i>
            </div>
            <div class="home-c-headline-text" [ngStyle]="{'color': iconBgColor}">
                <ng-content></ng-content>
            </div>
        </div>
        <div></div>
    </div>