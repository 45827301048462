<div
        id="positionContainer"
        class="flex column start width-100">

    <div
            class="searchbar-wrapper searchbar flex row center">
        <app-positions-search-options
                class="width-100 cap-width"></app-positions-search-options>
    </div>

    <div
            *ngIf="(positionService.searchBarFixed) && mediaService.isMobile()"
            [ngClass]="{'hide': positionService.hideMobileSearchbar, 'searchbar-fixed': true}"
            class="searchbar-wrapper searchbar flex row center" id="searchbar">
        <app-positions-search-options
                [minimalMobileView]="true"
                class="width-100 cap-width"></app-positions-search-options>
    </div>

    <app-positions-wrapper-preview-details

            class="width-100"
            [positions]="searchService.searchPositions"
            [totalPositions]="searchService.totalPositions"
            [page]="searchService.page"
            [totalPages]="searchService.totalPages.value"
            [loading]="searchService.loading"
            (paginatorNextIndex)="searchService.triggerSearch($event)"
            origin="ElasticSearch">
        <div class="preview-text" preview>
            <div class="padding-horizontal" *ngIf="searchService.searchPositions?.length > 0 && !searchService.loading.value">
                Hey {{authService.getAccountInfo()?.firstName}}, <span class="bold">{{searchService.totalPositions}} Jobs</span> haben wir passend zu deinen Angaben gefunden:
            </div>

<!--            <div class="padding-horizontal" *ngIf="searchService.searchPositions?.length == 0 && !searchService.loading.value">-->
<!--                Hey {{authService.getAccountInfo().firstName}}, wir haben leider <span class="bold">keine Jobs</span> mit deinen Angaben gefunden-->
<!--            </div>-->

            <div *ngIf="searchService.matchingPositions?.length == 0 && searchService.searchPositions?.length == 0 && !searchService.loading.value "
                 class="flex column center gap no-jobs-info">
                <i class="mdi mdi-heart-search"></i>
                <div class="text-center">
                    Ohh, leider haben wir <span class="bold">keine Stellenangebote</span> für dich gefunden.
                    Versuche es doch mal mit anderen Filtern.
                </div>
                <button (click)="searchService.clearFilterAndSearch()" class="grey-button flex row center gap-sm margin-top">
                    <i class="mdi mdi-filter-off"></i>
                    <span>Filter zurücksetzen</span>
                </button>
            </div>
        </div>
        <div class="flex column center gap no-jobs-info" postview
             *ngIf="!searchService.loading.value && !searchService.loadingMatches.value && (searchService.page+1) == searchService.totalPages.value && searchService.totalPositions &&
              searchService.matchingPositions.length > 0">
            <i class="mdi mdi-heart-search"></i>
            <div class="text-center margin-bottom">
                Wir haben keine weiteren Ergebnisse für deine Suche. Hier sind weitere Stellen die zu dir und deinem Studiengang passen.
            </div>
                <app-positions-preview-card *ngFor="let position of searchService.matchingPositions; let i = index"
                                            class="width-100"
                        [position]="position"
                        [origin]="'ElasticSearch'"
                ></app-positions-preview-card>
        </div>
        <div
                postview
                *ngIf="!searchService.loading.value && !searchService.loadingMatches.value && (searchService.page+1) == searchService.totalPages.value && searchService.totalPositions &&
                searchService.matchingPositions.length == 0"
                class="flex column center gap no-jobs-info">
            <i class="mdi mdi-heart-search"></i>
            <div class="text-center">
                Ohh, leider haben wir <span class="bold">keine weiteren Stellenangebote</span> für deine Suche mehr.
                Versuche es doch mal mit anderen Filtern.
            </div>
            <button (click)="searchService.clearFilterAndSearch()" class="grey-button flex row center gap-sm margin-top">
                <i class="mdi mdi-filter-off"></i>
                <span>Filter zurücksetzen</span>
            </button>
        </div>
    </app-positions-wrapper-preview-details>
</div>
