import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {AccountSyncData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.unisign.api.SyncController
 */

@Injectable()
@ResourceParams({})
export class SyncResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/updates',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getAccountUpdates: IResourceMethodObservableStrict<null, {lastSyncId: number}, null, AccountSyncData[]> | undefined
    getAccountUpdates(requestParams: {lastSyncId: number}): Promise<AccountSyncData[]> {
        if (!this._getAccountUpdates) throw new Error("resource has not been properly initialized")
        return this._getAccountUpdates(null, requestParams, null).toPromise()
    }

}