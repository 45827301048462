import {Injectable} from '@angular/core';
import {
    LabelData,
    PagedData,
    ProfessionFieldPreferenceResource,
    SearchFilterData,
    TalentElasticSearchResource,
    TalentLocationPreferencesResource,
    TalentPositionPreviewData,
} from "utility";
import {BehaviorSubject} from "rxjs";
import {PositionService} from "./position.service";
import {BottomSheetService} from "../utility/slideable-bottom-sheets/bottom-sheet.service";
import {MediaService} from "./inno-utils/media.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  pageSize: number = 25
  page: number = 0

  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  loadingMatches: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  totalPositions: number = 0
  totalPages: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  interestingFieldOptions: LabelData[] = []
  selectedField: LabelData = null
  filterUpdated: boolean = false

  public searchPositions: TalentPositionPreviewData[] = []
  public matchingPositions: TalentPositionPreviewData[] = []
  public searchFilterData: SearchFilterData = {
    benefits: [],
    employmentCategories: ["PermanentEmployment"],
    homeOffice: [],
    positionLocationIds: [],
    professionFieldIds: [],
    searchQuery: null,
    searchRadius: 50,
    sort: 'ProfessionScore',
    workingHourFrom: 5,
    workingHourTo: 48,
    forcedPositionIds: [],
    onlyUnseen: false,
      origins: []
  }






  constructor(
      private elasticSearchResource: TalentElasticSearchResource,
      private positionService: PositionService,
      private localStorageService: LocalStorageService,
      private bottomSheetService: BottomSheetService,
      private mediaService: MediaService,
      private locationPreferencesResource: TalentLocationPreferencesResource,
      private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
      private router: Router
  ) {

  }



  triggerSearch(page: number = 0, pageSize: number = 25) {
    if(this.loading.value) return
    this.localStorageService.setSearchFilterData(this.searchFilterData)
    this.localStorageService.setPageNum(page)
    if(!this.searchFilterData.homeOffice) this.searchFilterData.homeOffice = []
    this.page = page
    this.pageSize = pageSize
    if(this.mediaService.isMobile()){
      //this.positionService.searchBarFixed = false
      //this.positionService.hideMobileSearchbar = false
      this.bottomSheetService.closeCurrentBottomSheet()
    }
    this.loadPositions()
  }

    async updateLastFilter(): Promise<void> {
        if (this.localStorageService.getSearchFilterData()) {
            this.searchFilterData = this.localStorageService.getSearchFilterData() as SearchFilterData;
        } else {
            const locationPreferences = await this.locationPreferencesResource.getTalentLocationPreferences();
            this.searchFilterData.positionLocationIds = locationPreferences.specificCities.map(r => r.id);
            const professionFieldPreferences = await this.professionFieldPreferenceResource.getInterestingProfessionFieldPreferencesForTalent()
            this.searchFilterData.professionFieldIds = professionFieldPreferences.map(r => r.id)
        }
        this.searchFilterData.forcedPositionIds = [];

        if (this.localStorageService.getPageNum() && this.mediaService.isMobile()) {
            this.page = this.localStorageService.getPageNum();
            this.filterUpdated = true
        }
    }

  clearFilterAndSearch() {
    if(this.loading.value) return
    this.searchFilterData.positionLocationIds = []
    this.searchFilterData.searchQuery = ''
    this.selectedField = null
    this.searchFilterData.professionFieldIds = this.interestingFieldOptions.map(i => i.id)
    this.searchFilterData.employmentCategories = []
    this.searchFilterData.benefits = []
    this.searchFilterData.homeOffice = []
    this.searchFilterData.workingHourFrom = 5
    this.searchFilterData.workingHourTo = 48
    this.localStorageService.setSearchFilterData(this.searchFilterData)
    this.loadPositions()
  }

  getLocationFilterCount(): number{
    return this.searchFilterData.positionLocationIds.length + this.searchFilterData.homeOffice.length
  }

  getFieldFilterCount(): number {
    return this.searchFilterData.professionFieldIds.length
  }

  getMoreFilterCount(): number {
    let employmentCategoryCount = this.searchFilterData.employmentCategories?.length ? this.searchFilterData.employmentCategories.length : 0
    let benefits = this.searchFilterData.benefits?.length ? this.searchFilterData.benefits.length : 0
    let workingHours = (this.searchFilterData.workingHourFrom != 5 || this.searchFilterData.workingHourTo != 48) ? 1 : 0
    return benefits + employmentCategoryCount + workingHours
  }

  //private functions
  private loadPositions() {
    this.loading.next(true)
    let url = this.router.url
    if(this.checkIfResultsContainForcedIds()) this.searchFilterData.forcedPositionIds = []
    this.elasticSearchResource.searchPositions(this.searchFilterData,
        {pageNum: this.page, pageSize: this.pageSize})
        .then((result: PagedData<TalentPositionPreviewData>) => {
            //not update selected position id page changed since loading start
            if (result.content?.length && url == this.router.url) {
              this.positionService.selectedPositionId.next(result.content[0].id)
            }
            this.searchPositions = result.content
            if (this.page == 0 || (this.mediaService.isMobile() && this.filterUpdated)) {
                this.totalPositions = result.totalElements
                this.filterUpdated = false
            }
            this.totalPages.next(result.totalPages)
            //NOTE -- I romved this if statement. I would defensively start loading matches from the beginning. If this causes performance issues later down the line we can add it back in. if (this.page+1 == this.totalPages.value)
            if(this.matchingPositions.length == 0) this.loadMatches()
            this.loading.next(false)
    })
  }

  loadMatches() {
      this.loadingMatches.next(true)
      this.elasticSearchResource.getMatchingPositionsWithElasticSearch(this.searchFilterData).then((result: PagedData<TalentPositionPreviewData>) => {
          this.matchingPositions = result.content
          this.loadingMatches.next(false)
      })
  }

  checkIfResultsContainForcedIds(): boolean{
    if(this.searchFilterData.forcedPositionIds.length == 0) return true
    return this.searchPositions.some(p => this.searchFilterData.forcedPositionIds.includes(p.id))
  }

}

