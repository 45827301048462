import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TalentCoachRatingData, TalentCoachResource} from "utility";

@Component({
  selector: 'app-judge-talent-agent-dialog',
  templateUrl: './judge-talent-agent-dialog.component.html',
  styleUrls: ['./judge-talent-agent-dialog.component.scss']
})
export class JudgeTalentAgentDialogComponent implements OnInit {

  saving = false;
  selectedRating: number;
  talentAgentId: number
  ratingComment: string

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<JudgeTalentAgentDialogComponent>,
      private talentCoachResource: TalentCoachResource
  ) {
  }

  ngOnInit() {
    this.talentAgentId = this.data.talentAgentId
  }

  save() {

    let data: TalentCoachRatingData = {
      rating: this.selectedRating,
      ratingComment: this.ratingComment
    }

    this.saving = true;
    this.talentCoachResource.assessTalentCoach(data).then(result => {
      this.close(true)
      this.saving = false
    })
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }
}
