import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {CachedOBInputs, S2FaxOnboardingService} from "../../s2-fax-onboarding/s2-fax-onboarding.service";

@Component({
    selector: 'app-cherry-obgateway',
    templateUrl: './cherry-obgateway.component.html',
    styleUrls: ['./cherry-obgateway.component.scss']
})
export class CherryOBGatewayComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private obService: S2FaxOnboardingService
    ) {
        let trafficOrigin = {...this.analyticsService.trafficOrigin}
        trafficOrigin.entryGateway = "Cherry"
        trafficOrigin.utm_term = "Cherry"
        this.analyticsService.trafficOrigin = trafficOrigin
    }


    ngOnInit(): void {

        let fieldString = this.activatedRoute.snapshot.queryParams['fieldId']

        let fieldStrings = fieldString?.split(",")
        let fieldIds: number[] = []
        fieldStrings?.forEach(el => fieldIds.push(Number(el)))

        if (fieldStrings) {
            this.obService.updateInputs(<CachedOBInputs>{
                markedAsInterestingFieldIds: fieldIds,
                fieldIds: fieldIds
            })
        }

        // if (Math.random() < 0.5) {
        //     this.router.navigate(['/onboarding/cpd']) //Split CPD OB
        // } else {
            this.router.navigate(['/onboarding/cvp']) //Split CVP OB
        // }
    }


    ngOnDestroy() {
    }

}
