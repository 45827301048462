import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {PositionService} from "../../services/position.service";
import {TalentPositionInteractionOrigin, TalentPositionPreviewData} from "utility";
import {MediaService} from "../../services/inno-utils/media.service";
import {BottomSheetService} from "../../utility/slideable-bottom-sheets/bottom-sheet.service";
import {SearchService} from "../../services/search.service";

@Component({
    selector: 'app-positions-previews',
    templateUrl: './positions-previews.component.html',
    styleUrls: ['./positions-previews.component.scss']
})
export class PositionsPreviewsComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() positions: TalentPositionPreviewData[]
    @Input() loading: boolean
    @Input() totalPages: number
    @Input() page: number
    @Input() origin: TalentPositionInteractionOrigin
    @Output() paginatorNextIndex: EventEmitter<number> = new EventEmitter<number>()
    @ViewChild('previews', {static: true}) previews!: ElementRef;

    topDistancePreviews: number = 0;

    lastScrollTop = 0;
    scrollThreshold = 150;
    updateHeightBound = this.updateHeight.bind(this)

    constructor(
        public positionService: PositionService,
        private mediaService: MediaService,
        private bottomSheetService: BottomSheetService,
        public searchService: SearchService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        if (!this.mediaService.getMaxWidthQuery(960).matches) {
            // Attach scroll event listener
            window.addEventListener('scroll', this.updateHeightBound);
            // Initial height adjustment
            this.updateHeight();
        } else {
            window.addEventListener('scroll', this.onMobileScroll.bind(this));
            this.onMobileScroll()
        }
    }

    ngOnDestroy() {
        // Detach scroll event listener
        if (!this.mediaService.isMobile()) {
            window.removeEventListener('scroll', this.updateHeightBound);
        }
    }

    onMobileScroll(): void {
        const st = window.scrollY;
        if (Math.abs(st - this.lastScrollTop) > this.scrollThreshold) {
            if(this.bottomSheetService.skipMobileScroll){
                this.lastScrollTop = st;
                return
            }
            this.positionService.hideMobileSearchbar = st > this.lastScrollTop;
            if (st < 260  /*|| this.loading */) {
                this.positionService.hideMobileSearchbar = false
                this.positionService.searchBarFixed = false
            } else {
                this.positionService.searchBarFixed = true
            }
            this.lastScrollTop = st;
        }
    }


    updateHeight() {
        const previews = document.getElementById('previews');
        if (!previews || this.loading) return
        this.topDistancePreviews = previews.getBoundingClientRect().top;
        if (this.topDistancePreviews > 260) {
            this.topDistancePreviews = 260;
        }
        if (this.topDistancePreviews >= 0) previews.style.height = `calc(100dvh - ${this.topDistancePreviews}px)`;

    }

    triggerClearSearch(){
        this.searchService.searchFilterData.forcedPositionIds = []
        this.searchService.clearFilterAndSearch()
    }
}
