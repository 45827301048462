<div class="home-c-w">
    <home-headline iconBgColor="#FFFFFF" iconColor="#344B46" icon="star"></home-headline>
    <div class="home-c-c-w" fxLayout="column" fxLayoutAlign="start center">
        <img class="logo" src="assets/images/career-fairy/career-fairy-logo.svg" alt="CareerFairy">
        <div class="text-center">Erhalte in Livestreams exklusive Einblicke in Unternehmen und finde spannende Jobangebote</div>

        <button routerLink="/events" style="border-radius: 999999px; background-color: #00D2AA; color: #ffffff" mat-flat-button>
            Jetzt entdecken
        </button>
    </div>
</div>
