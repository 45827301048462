import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ChatData,
  ChatMinimalData,
} from "utility";
import {FileService} from "../../services/file.service";
import {ChatService} from "../../services/chat.service";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-chat-minimal',
  templateUrl: './chat-minimal.component.html',
  styleUrls: ['./chat-minimal.component.scss']
})
export class ChatMinimalComponent implements OnInit, OnDestroy {

  profilePictureUrl: string = 'url(../assets/images/blurred_user.png)'
  profilePictureSub: Subscription

  lastMessage: string

  @Input() chatMinimalData: ChatMinimalData
  @Input() talentId: number


  constructor(
      private fileService: FileService,
      public chatService: ChatService
  ) { }

  ngOnInit(): void {
    this.getLastMessageSentText()
    this.getPartnerProfilePicture()
  }

  ngOnDestroy() {
    this.profilePictureSub?.unsubscribe()
  }

  getLastMessageSentText(): void {
    let sender = ""
    if (this.chatMinimalData.lastMessage.sender.id == this.talentId) {
      sender = "Du"
    } else {
      sender = this.chatMinimalData.lastMessage.senderFirstName
    }

    this.lastMessage = sender + ": " + this.chatMinimalData.lastMessage.text
  }

  getPartnerProfilePicture(): void {
    if (this.chatMinimalData.members.length > 2) return this.getCompanyLogoImage()

    this.profilePictureSub = this.chatService.getPartnerProfilePicture(this.chatMinimalData, this.chatMinimalData.type).subscribe(result => {
      if (result.size > 0) {
        this.profilePictureUrl = "url(" + URL.createObjectURL(result)+ ")"
      } else {
        this.profilePictureUrl = 'url(../assets/images/blurred_user.png)'
      }
    })
  }

  getCompanyLogoImage(): void {
    this.profilePictureUrl =  'url(' + environment.apiUrl + '/public/company/' + this.chatMinimalData.company.id + '/companyLogo)'
  }


}
