
<div class="home-c-w-m" style="padding: 18px 6px" fxLayout="column" fxLayoutAlign="space-around center" *ngIf="!talentCoachData?.talentAgent && !talentCoachData?.requestedAt">
    <div class="home-c-headline-w">{{getHeadline()}}</div>
    <div class="request-content" fxLayout="column" fxLayoutAlign="center center">
        <button style="background-color: #A3825B !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" [class]="authService.splitIdClass()" class="mlp-button ita_request_coach ita_dashboard" (click)="openAssignDialog()">Coach anfordern</button>
    </div>
    <img src="assets/images/mlp-logo.png" width="30px">
</div>

<div class="home-c-w-m" fxLayout="column" fxLayoutAlign="start center" *ngIf="talentCoachData?.requestedAt && !talentCoachData?.talentAgent" routerLink="/talent/agent">
    <div class="home-c-headline-w">{{getHeadline()}}</div>
    <img src="assets/images/mlp-logo.png" class="mlp-logo-waiting" width="60px">
    <div fxLayout="column" fxLayoutAlign="center center" class="waiting-box">
        <div class="waiting-logo-wrapper">
            <i class="mdi mdi-account-search"></i>
        </div>
        <div style="color: var(--mlp-blue)" *ngIf="!talentCoachData?.coachAssignableNow">mehr erfahren</div>
    </div>
</div>

<div class="home-c-w-m" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="talentCoachData?.talentAgent">
    <div *ngIf="talentCoachData?.talentAgent" class="coach-name" routerLink="talent/agent">
        <div>Dein Coach:</div>
        <div>{{talentCoachData?.talentAgent?.firstName}} {{talentCoachData?.talentAgent?.lastName}}</div>
    </div>
    <div class="talent-agent-image" routerLink="talent/agent">
        <coach-image [questionSize]="24"></coach-image>
    </div>

    <div class="button-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="6px">
        <button *ngIf="!talentCoachData?.appointmentRequestable" style="background-color: #033D5D !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" class="mlp-button" routerLink="/talent/agent">zu meinem Coach</button>
        <button *ngIf="talentCoachData?.appointmentRequestable" style="background-color: #A3825B !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" class="mlp-button ita_coach_appointment ita_dashboard" (click)="openConsultantAppointmentDialog()" [class]="authService.splitIdClass()">Termin ausmachen</button>
    </div>
</div>
