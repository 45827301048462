import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TalentAuthService} from './services/talent.auth.service';
import {NavigationService} from './services/navigation.service';
import {
    NotificationData,
    NotificationResource,
    ProfileImageService,
    TalentAccountResource,
    TalentChatResource,
    TalentCoachData,
    TalentCoachResource,
    TalentNotificationResource,
} from 'utility';
import {MediaService} from './services/inno-utils/media.service';
import {AnalyticsService} from './services/analytics/analytics.service';
import {LocalStorageService} from './services/local-storage.service';
import {GDPRConsentDialogService} from './other/gdprconsent/gdprconsent-dialog.service';
import {Subscription} from 'rxjs';
import {CookieService} from './services/cookie.service';
import {FileService} from './services/file.service';
import {MediatorService} from './services/mediator.service';
import {environment} from "../environments/environment";
import {MetaService} from "./services/meta.service";
import {AnimationService} from "./services/animation.service";
import {PopUpService} from "./services/pop-up.service";
import {PropagationService} from "./services/propagation.service";
import {PositionService} from "./services/position.service";
import {ChatService} from "./services/chat.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-talent',
    templateUrl: './talent.component.html',
    styleUrls: ['./talent.component.scss']
})
export class TalentComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

    public mobileQuery: MediaQueryList;
    public showMobileNav: boolean = false;

    public notifications: NotificationData[] = [];
    public newNotificationsCount: number = 0;
    public badgeVisible: boolean = true;

    public unseenChatMessagesCount: number = 0;
    public profilePicture;
    public noProfilePicBool: boolean = true;
    public showRocketAnimation: boolean = false;

    public talentCoachData: TalentCoachData;
    public talentAgentImageUrl: any;
    public talentAgentImageUrlPlaceHolder = './../../assets/images/blurred_user.png';

    public siteNoticeLink = environment.publicPageUrl + '/imprint'
    public privacyUrl = environment.publicPageUrl + '/privacy'
    public useRegulations = environment.publicPageUrl + '/use-regulations'

    intervalActive = true

    openChatOverlay: boolean = false

    public _mobileQueryListener: () => void;
    preventRedirect: boolean = false;

    private currentScrollIndex: number = 0;
    private routerSubscription: Subscription;
    private talentAgentSubscription: Subscription;
    private firstPlattformVisitedSub: Subscription;

    @ViewChild("chatOverlay") chatOverlay: ElementRef


    constructor(public authService: TalentAuthService,
                public router: Router,
                public route: ActivatedRoute,
                public mediaService: MediaService,
                public analyticsService: AnalyticsService,
                public changeDetectorRef: ChangeDetectorRef,
                public media: MediaMatcher,
                private navigationService: NavigationService,
                private profileImageService: ProfileImageService,
                private talentNotificationResource: TalentNotificationResource,
                private notificationResource: NotificationResource,
                private cdRef: ChangeDetectorRef,
                private localStorageService: LocalStorageService,
                private gdprConsentDialogService: GDPRConsentDialogService,
                private cookieService: CookieService,
                private talentCoachResource: TalentCoachResource,
                private fileService: FileService,
                private mediatorService: MediatorService,
                private metaService: MetaService,
                private animationService: AnimationService,
                public popUpService: PopUpService,
                private talentAccountResource: TalentAccountResource,
                public propagationService: PropagationService,
                private positionService: PositionService,
                private chatResource: TalentChatResource,
                private chatService: ChatService,
                private snackbar: MatSnackBar
    ) {

        this.analyticsService.grabAndStoreUrlParams()

        this.analyticsService.init();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0)

                if (this.router.url == '/talent/login') this.createTalentFirstPlatformVisitSubIfNecessary()
            }
        })


        this.mobileQuery = media.matchMedia('(max-width: 1180px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        if (this.mediaService.isIOs()) {
            this.metaService.setMetaTag('viewport', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
        }

        navigationService.scrollStateIndex$.subscribe(scrolledTo => {
            this.currentScrollIndex = scrolledTo;
        })

        if (this.router.url.indexOf('datenschutz') === -1 && this.localStorageService.getGDPRConsent().initial) {
            const gdprConsent = this.cookieService.getGDPRConsentFromCookies()

            if (gdprConsent == null) this.gdprConsentDialogService.openConsentDialog(false);

            else this.localStorageService.updateGDPRConsent(gdprConsent)
        }
    }

    ngAfterViewInit(): void {
        let parts = document.cookie.split(`;`);
        let partsWithVarify = parts.filter(p=>p.includes("varifyData="))

            if(partsWithVarify.length && partsWithVarify[0].split("varifyData=")[1] && partsWithVarify[0].split("varifyData=")[1] != 'undefined' ) {
                let eventJson = JSON.parse(decodeURIComponent(partsWithVarify[0].split("varifyData=")[1]))
                if(eventJson) (<any>window).dataLayer.push(eventJson);
            }
    }

    relationProceededSub: Subscription

    subscribeRelationProceeded() {
        this.relationProceededSub = this.positionService.relationProceeded.subscribe(proceeded => {
            if (proceeded.action == "Bookmarked" || proceeded.action == "BookmarkRemoved" || proceeded.action == "Rejected" || proceeded.action == 'Applied')  {
                this.propagationService.refreshAllBadges();
            }
        })
    }

    reportTalentPlatformVisitedToDataLayer() {
        if(!this.authService.isAuthenticated()) return
        this?.firstPlattformVisitedSub?.unsubscribe()
        this.firstPlattformVisitedSub = null
        this.talentAccountResource.createTalentFirstVisitedPlatformLogEntryIfNecessary().then(result => {
                if (result.value) this.analyticsService.reportTalentPlatformVisitedToDataLayer()
            }
        )
    }


    createTalentFirstPlatformVisitSubIfNecessary() {
        if (!this.authService.getAccountInfo()?.platformVisited && !this.firstPlattformVisitedSub?.closed) {
            this.firstPlattformVisitedSub = this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    if (!(this.router.url.includes('/onboarding') || this.router.url.includes('/talent/login') || this.router.url == "/") ) {
                        this.reportTalentPlatformVisitedToDataLayer()
                    }
                }
            })
        }
    }

    showNav() {
        let routeWithoutParams = this.router.url.split('?')[0];
        return this.router.url.indexOf('onboarding') == -1
            && routeWithoutParams !== '/privacy'
            && routeWithoutParams !== '/use-regulations'
            && routeWithoutParams !== '/welcome'
            && routeWithoutParams !== '/404'
            && routeWithoutParams !== '/talent/login'
            && routeWithoutParams !== '/';
    }

    ngOnInit() {

        this.createTalentFirstPlatformVisitSubIfNecessary()


        this.subscribeRelationProceeded()
        this.analyticsService.grabAndStoreUrlParams()

        this.animationService.rocketAnmination.subscribe(next => {
            this.showRocketAnimation = next
        })

        if (this.localStorageService.gdprConsent.consent_tiktok) this.localStorageService.addTikTokScriptToHead()
        if (this.localStorageService.gdprConsent.consent_hotjar) this.localStorageService.addHotjarScriptToHead()



        this.navigationService.profilePicChanged$.subscribe(() => {
            this.loadProfilePic()
        });

        if (this.authService.isAuthenticated()) {
            this.loadAllData();
            this.checkForObc()
            this.propagationService.refreshAllBadges()
            this.propagationService.getInitialBookmarkedProposalsCount()
        }

        this.authService.onLoginSuccess(() => {
            this.loadAllData();
            this.popUpService.initPopupHandling()
            this.propagationService.refreshAllBadges()
        });

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.route.snapshot.queryParamMap.get('preventRedirect') ? this.preventRedirect = true : this.preventRedirect = false;
                if (this.preventRedirect && this.authService.isAuthenticated()) {
                    this.authService.logout();
                }

                if (this.authService.isAuthenticated()) {
                    this.loadTalentAgent();
                }
            }
        })

        this.talentAgentSubscription = this.mediatorService.talentAgentRequestedEvent.subscribe(event => {
            this.loadTalentAgent();
        });

        this.updateNotificationsAndPositionAfterInterval();
        this.updateUnseenMessagesAfterInterval()
        this.updateRecommendationCountAfterInterval()

    }

    isOnLoginPage(): boolean {
        let routeWithoutParams = this.router.url.split('?')[0];
        return (routeWithoutParams == '/' || routeWithoutParams == '/talent/login');
    }

    checkForObc() {
      /*
        for current splits, i think its ok to not throw back to OB.
        this.authService.reloadAccountInfo().then(result => {
            //TODO why do you have a gateway if you dont use it? Just go there and let the gateway choose the OB, like you do with new user...
            if (!this.authService.getAccountInfo().onboardingCompleted) {
                switch(this.onboardingService.obSplitVersion){
                    case 2: this.router.navigateByUrl('onboarding2/coach-intent');break;
                    case 3: break;
                    default: this.router.navigateByUrl('onboarding/matching'); break;
                }
            }
        })
    */
    }

    ngDoCheck(): void {
        this.cdRef.detectChanges();
    }

    navigateToStart() {
        this.router.navigateByUrl('/home');
    }

    loadAllData() {
        this.loadProfilePic();
        this.loadNewestThreeNotifications();
        this.countUnseenNotifications();
        this.getUnseenChatMessagesCount();
    }

    loadNewestThreeNotifications() {
        this.talentNotificationResource.getNotificationsForTalent().then(result => {
            this.notifications = result;
        });
    }

    countUnseenNotifications() {
        this.notificationResource.getCountOfUnseenNotificationsForUser().then(result => {
                this.newNotificationsCount = result;
                this.badgeVisible = result > 0;
            }
        );
    }

    getUnseenChatMessagesCount() {
        this.chatResource.getUnseenChatCount().then(result => {
            if (this.unseenChatMessagesCount < result.value) {
                if(!this.mediaService.getMaxWidthQuery(600).matches) {
                    this.openChatOverlay = true
                } else {
                    this.snackbar.open("Du hast neue Nachrichten", "Ok")
                }
            }
            this.unseenChatMessagesCount = result.value

        })
    }

    hideChatOverlay(): void {
        this.chatOverlay.nativeElement.classList.add("hide-chat-overlay")
        setTimeout(() => this.openChatOverlay = false, 200)
    }

    routeOrRefreshChatMessages() {
            this.chatService.refreshChat.next(true)
    }

    updateNotificationsAndPositionAfterInterval() {
        setInterval(
            () => {
                if (this.authService.isAuthenticated() && this.showNav()) {
                    this.countUnseenNotifications();
                    this.loadNewestThreeNotifications();
                }
            }, 300000
        )
    }

    updateUnseenMessagesAfterInterval(): void {
        setInterval(
            () => {
                if (this.authService.isAuthenticated()) {
                    this.chatResource.getUnseenChatCount().then(result => {
                        this.unseenChatMessagesCount = result.value

                    })
                }
            }, 15000
        )
    }

    updateRecommendationCountAfterInterval(): void {
        setInterval(
            () => {
                if (this.authService.isAuthenticated()) {
                    this.propagationService.refreshRecommendedProposalCount()
                }
            }, 15000
        )
    }

    loadTalentAgent() {
        this.talentCoachResource.getTalentCoach().then(result => {
            this.talentCoachData = result

            if (this.talentCoachData.talentAgent != null) {
                this.loadTalentAgentImageUrl(this.talentCoachData.talentAgent.id)
            }
        })
    }

    loadTalentAgentImageUrl(talentAgentId: number) {
        this.fileService.getTalentAgentProfilePicture().subscribe((result) => {
            if (result.size > 0) {
                this.talentAgentImageUrl = URL.createObjectURL(result);
            }
        });
    }


    loadProfilePic() {
        this.profileImageService.getOwnProfilePic().subscribe(b64 => {
            if (b64.size == null || b64.size == 0) this.noProfilePicBool = true;

            else {
                this.noProfilePicBool = false;
                let blob = new Blob([b64], {type: 'image/png'})
                this.profilePicture = URL.createObjectURL(blob)
            }
        })
    }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this.relationProceededSub?.unsubscribe()
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.routerSubscription?.unsubscribe();
        this.talentAgentSubscription?.unsubscribe();
        this.talentAgentSubscription?.unsubscribe();
    }

    setNotificationsToSeen() {
        this.notificationResource.setUserNotificationsToSeen()
    }

    routeToTalentAgent() {
        window.open(environment.publicPageUrl + '/company', '_blank');
    }

    navigateToMainPage() {
        window.location.href = 'https://hochschulinitiative-deutschland.de/';
    }

    showFooter() {
        if (this.isOnLoginPage()) {
            return true;
        }
        if(this.mediaService.isMobile() && this.router.url.startsWith('/talent/positions')) return false
        
        return (
            this.router.url !== '/404'
            && !this.router.url.startsWith('/position-details/')
            && !this.router.url.startsWith('/position-preview/')
            && !this.router.url.startsWith('/events')
            && !(this.router.url.includes('/proposals') && this.mediaService.isMobile())
            && this.router.url.indexOf('onboarding') == -1
            && this.showFooterForChatOverview()
        )
    }

    showFooterForChatOverview(): boolean {
        if (this.router.url == '/chat-overview') {
            if (this.mediaService.getMaxWidthQuery(600).matches) return false
        }
        return true
    }

    isNotificationUnseen(notification: NotificationData) {
        return notification.unseen
    }

    navigateFromNotificationToUrl(notificationType) {

        switch (notificationType) {
            case 'TalentNewPositionProposalsNotification':
            case 'TalentNewPositionsInFeedNotification':
            case 'TalentOpenPositionProposalsNotification':
            case 'TalentNewApplicationNotification': //Legacy
                this.router.navigateByUrl('/talent/positions');
                break;

            case 'TalentCoachAssignedNotification':
            case 'TalentCoachChangedNotification':
            case 'TalentCoachUnassignedNotification':
            case 'TalentCanAssessCareerCoachNotification':
                this.router.navigate(['talent/agent'])
                break;

            case 'TalentEvaluationCreationNotification':
            case 'TalentEvaluationFinishedNotification':
            case 'TalentEvaluationApprovedNotification':
                this.router.navigateByUrl('/talent/agent?tab=0');
                break;

            case 'TalentCvUploadReminderNotification':
                this.router.navigate(['/talent/profile']);
                break;

            case 'TalentOpenBookmarkedPositionProposalsNotification':
                this.router.navigateByUrl('/talent/proposals/bookmarks')
                break;

            case 'TalentCoachRecommendedPositionNotification':
            case 'TalentPositionRecommendedNotification':
            case 'TalentPositionProposalRecommendedNotification':
            case 'TalentAdminRecommendedPrimePositionNotification':
                this.router.navigateByUrl('/talent/proposals/recommendations')
                break;

            case 'TalentAdditionalApplicationDocumentsRequiredNotification':
            case 'TalentFailedApplicationNotification':
                this.router.navigateByUrl('/talent/applications?tab=0');
                break;

            case 'TalentFailedApplicationJobFoundNotification':
            case 'TalentFailedApplicationPositionDeletedNotification':
                this.router.navigateByUrl('/talent/applications?tab=0');
                break;

            case 'TalentGotHiredNotification':
                this.router.navigateByUrl('/talent/applications');
                break;

            default:
                this.router.navigateByUrl('/talent/positions');
        }
    }
}
