<mat-tab-group color="accent" class="custom-group max-w1064" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChange($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Aktuelle Bewerbungen</span>
    </ng-template>
    <robot-info-box>
      Hier findest du alle deine Bewerbungen und kannst ihren Status verfolgen. Bei Bewerbungen über Partnerplattformen können wir dir leider nicht immer aktuelle Updates geben.
    </robot-info-box>
    <loading-spinner [loadingVar]="loadingGrantedRequests">
        <div  fxLayout="column" fxLayoutGap=" 20px" class="m-top20">
          <application-state-talentcard *ngFor="let talentRequest of grantedRequestsForTalentData"
                                        (removeApplication)="removeRequestFromArray($event)"
                                        (reload)="loadApplications()"
                                        [talentApplication]="talentRequest"></application-state-talentcard>
        </div>
      <div *ngIf="!grantedRequestsForTalentData?.length" fxLayout="row" fxLayoutAlign="center center" style="color: var(--light-grey)"
           class="no-requests-found">
        <span class="no-requests-found-text">Du hast noch keine laufenden Bewerbungen.</span>
      </div>
      <div class="paginator">
        <mat-paginator
                class="custom-paginator"
                [pageSize]="pageSizeGranted"
                       [showFirstLastButtons]="true"
                       [length]="totalElementsGranted"
                       [hidePageSize]="true"
                       [pageIndex]="pageIndexGranted"
                       (page)="handlePageGranted($event)">
        </mat-paginator>
      </div>
    </loading-spinner>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Archiv</span>
    </ng-template>
    <loading-spinner [loadingVar]="loadingArchivedRequestForTalent">
        <div  fxLayout="column" fxLayoutGap=" 20px">
          <application-state-talentcard
                  *ngFor="let talentRequest of archivedRequestsForTalentData"
                  (removeApplication)="removeRequestFromArray($event)"
                                        [talentApplication]="talentRequest"></application-state-talentcard>
        </div>
      <div *ngIf="!archivedRequestsForTalentData?.length" fxLayout="row" fxLayoutAlign="center center" style="color: var(--light-grey)"
           class="no-requests-found">
        <span class="no-requests-found-text">Du hast noch keine archivierten Bewerbungen.</span>
      </div>
      <div class="paginator">
        <mat-paginator [pageSize]="pageSizeArchived"
                       class="custom-paginator"
                       [showFirstLastButtons]="true"
                       [length]="totalElementsArchived"
                       [hidePageSize]="true"
                       [pageIndex]="pageIndexArchived"
                       (page)="handlePageArchived($event)">
        </mat-paginator>
      </div>
    </loading-spinner>
  </mat-tab>
</mat-tab-group>
