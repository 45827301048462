<dialog-header [headline]="data? 'Du hast einen Karrierecoach!' : 'Gespräch vereinbaren'"></dialog-header>

<mat-dialog-content fxLayout="column">

    <form #f="ngForm" class="wrapper">
<!--        <div *ngIf="data" class="coach-title">Du hast einen Karrierecoach erhalten!</div>-->

        <div *ngIf="data" class="coach-wrapper" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <div class="coach-image">
                <coach-image questionSize="30"></coach-image>
            </div>
            <div class="coach-text">Ich bin {{talentCoachData?.talentAgent?.firstName}}, ich berate dich zu Themen wie Jobsuche, Lebenslauf,
                Gehaltsverhandlung und was nach deiner Einstellung kommt. Lass mich wissen, wann wir sprechen können.</div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="select-slot">
            <div class="headline text-center">Wann hast du Zeit für unser erstes Gespräch?</div>
            <div class="slots-wrapper" fxLayout="row wrap" fxLayoutAlign="center start">
                <div [class.selected]="slotControl.value == slot" *ngFor="let slot of slots" class="slot" (click)="selectSlot(slot)">
                    <div>{{slot.start | date: "E dd.MM"}}, {{slot.start | date: "HH:mm"}}</div>
                </div>
            </div>

            <div *ngIf="loadingSlots" class="slots-wrapper" fxLayout="row wrap" fxLayoutAlign="center start">
                <div *ngFor="let i of [0,0,0,0,0,0]" class="slot-scelett"></div>
            </div>

            <div *ngIf="(firstSlots?.length < allSlots?.length) && !moreSlots" class="mlp-colored-link" (click)="showMoreSlots()">mehr anzeigen</div>

            <input style="width: 0; height: 0; opacity: 0" matInput [ngxMatDatetimePicker]="picker" [ngxMatDatetimePickerFilter]="weekendFilter" placeholder="Wähle einen Gesprächstermin" [formControl]="ownSlotControl"
                   [min]="minDate" [disabled]="false">
            <div (click)="openPicker(picker)" class="slot" style="width: unset" [class.selected]="ownSlotControl.value" >
                <div *ngIf="ownSlotControl.value">{{ownSlotControl.value | date: "E dd.MM"}}, {{ownSlotControl.value | date: "HH:mm"}}</div>
                <div *ngIf="!ownSlotControl.value">schlage einen Termin vor</div>
            </div>

                <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                         [stepHour]="1" [stepMinute]="30" [stepSecond]="1"
                                         [touchUi]="false"  [enableMeridian]="false"
                                         [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="button-bar mlp-button">
    <button mat-flat-button class="mlp-button-flat" [loadingBool]="requesting" (click)="requestAppointment()"
                    [disabled]="(slotControl.invalid && ownSlotControl.invalid) || picker.opened">
        Geprächstermin anfragen
    </button>
</mat-dialog-actions>