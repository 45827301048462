import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl} from "@angular/forms";
import {LabelData, ProfessionFieldPreferenceData, TalentProfessionFieldsResource} from "utility";
import {delay} from "../../services/inno-utils/general-utils.service";
import {MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";

@Component({
  selector: 'field-search-input',
  templateUrl: './field-search-input.component.html',
  styleUrls: ['./field-search-input.component.scss']
})
export class FieldSearchInputComponent implements OnInit, AfterViewInit {


  @Input() label: string;
  @Input() displayProperty: string;

  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('menuTriggerFields') menuTriggerStudy: MatMenuTrigger
  @ViewChild('menuFields') menuStudy: MatMenu

  readonly searchControl = new UntypedFormControl(null)

  options: LabelData[] = [];
  oldControlValue: any;

  loadingOptions: boolean;
  showSmallLoadingSpinner: boolean = false;
  clickedOption: string = '';

  requestIndex: number = 0;

  constructor(
      private talentProfessionFieldResource: TalentProfessionFieldsResource
  ) {
  }

  ngOnInit() {

    this.loadSuggestions()

    this.oldControlValue = this.searchControl.value;

    this.searchControl.valueChanges.subscribe(event => {
      if (this.clickedOption == this.searchControl.value) return;

      if (this.oldControlValue != event) {
        this.showSmallLoadingSpinner = true;
        this.oldControlValue = event;
        this.loadingOptions = true;

        delay(500).then(() => {
          let localRequestIndex = ++this.requestIndex;
          this.loadSuggestions().then(() => {
            if (localRequestIndex != this.requestIndex) {
              this.showSmallLoadingSpinner = false;
              return
            }
            this.toggleMatMenuIfNecessary()
            this.showSmallLoadingSpinner = false;
          });
        });
      }
    });

    if (this.displayProperty == null) this.displayProperty = 'name';
  }

  async loadSuggestions() {
    await this.talentProfessionFieldResource.getFilteredProfessionFields({
      q: this.searchControl.value,
      forceIds: []
    }).then(result => {
      this.options = []
      for (let o of result) {
        this.options.push(o);
      }
      this.loadingOptions = false;
    });
  }

  toggleMatMenuIfNecessary() {
    if (this.options.length != 0 && !this.menuTriggerStudy.menuOpen) {
      this.menuTriggerStudy.openMenu()
    } else if (this.options.length == 0 && this.menuTriggerStudy.menuOpen) {
      this.menuTriggerStudy.closeMenu()
    }
  }


  isSelected(option) {
    return this.searchControl.value?.id == option?.id;
  }

  setModel(option: LabelData, event: Event) {
    event.stopPropagation()
    //if (!option[this.displayProperty]) console.warn('Display property unknown');

    this.clickedOption = option[this.displayProperty];
    this.modelChange.emit(option)
    this.searchControl.setValue(null);
    this.showSmallLoadingSpinner = false;
    this.menuTriggerStudy.closeMenu()
  }

  ngAfterViewInit(): void {
    this.menuStudy.focusFirstItem = () => {}
  }
}
