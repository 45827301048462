import {AccountResource, LoginLoggingResource, SessionAttribution, TalentAccountInfoData, TokenAction} from 'utility';
import {forwardRef, Inject, Injectable} from "@angular/core";
import {catchError, take, tap} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {interval, Subject, throwError} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {environment} from "../../environments/environment";

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ACCOUNT_INFO = 'account_info';

export type LoginState =
    'LoginSuccessful'
    | 'LoginFailedCredentialsIncorrect'
    | 'LoginFailedRoleIncorrect'
    | 'LoginExpired'
    | 'Logout'
    | 'TokenUpdated';

@Injectable({
  providedIn: 'root'
})
export class TalentAuthService {

  authenticatedSubject = new Subject<LoginState>();

  private tokenRefresher = interval(60 * 1000);

  constructor(
      @Inject(forwardRef(() => 'env')) private env,
      protected http: HttpClient,
      private accountResource: AccountResource,
      private route: ActivatedRoute,
      protected router: Router,
      private jwtHelper: JwtHelperService,
      @Inject(forwardRef(() => LoginLoggingResource)) private loginLoggingResource: LoginLoggingResource
  ) {
    this.refreshTokenIfNeeded();
    this.tokenRefresher.subscribe(() => {
      this.refreshTokenIfNeeded();
    });
  }

  private refreshTokenIfNeeded() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken || !refreshToken.length) {
      return;
    }
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    if (accessToken == null || this.getRemainingTokenLifetimeSec(accessToken) < 60 * 60 * 24) {

      const formData = new FormData();
      formData.append('client_id', environment.frontendUnisignClientId)
      formData.append('grant_type', "refresh_token")
      formData.append('refresh_token', refreshToken)
      this.http.post(environment.uniSignUrl + '/oauth2/token', formData).subscribe(
          response => {
            this.setToken(response['access_token'], response['refresh_token'])
          },
          error => {
            localStorage.removeItem(refreshToken)
          }
      )

    }
  }

  private getRemainingTokenLifetimeSec(accessToken: string) {
    const now = new Date();
    const remainingTokenLifetimeSec = (this.jwtHelper.getTokenExpirationDate(accessToken).getTime() - now.getTime()) / 1000;
    return remainingTokenLifetimeSec
  }

  onLoginStateChange(callback: (result) => any) {
    this.authenticatedSubject.subscribe(
        (result) => callback(result)
    )
  }

  onLoginSuccess(callback: () => any) {
    this.authenticatedSubject.subscribe(
        (result) => {
          if (result == "TokenUpdated" || result == "LoginSuccessful") {
            callback()
          }
        }
    )
  }

  reloadAccountInfo() {
    return this.accountResource.getAccountInfo().then((accountInfo) => {

      if ((accountInfo as TalentAccountInfoData).accountLiveTimeSeconds != undefined) {
        accountInfo['regDate'] = new Date(new Date().getTime() - ((accountInfo as TalentAccountInfoData).accountLiveTimeSeconds * 1000))
      }

      localStorage.setItem(ACCOUNT_INFO, JSON.stringify(accountInfo));
    });
  }

  logout() {
    this.clearUserLoginSession();
    this.authenticatedSubject.next('Logout');
      this.router.navigate(['/'], {queryParams: {logout: true}});
  }

  setToken(newToken, refreshToken) {
    localStorage.setItem(ACCESS_TOKEN, newToken);
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
    } else {
      localStorage.removeItem(REFRESH_TOKEN);
    }
  }

  applyLogin(loginState: LoginState) {
    this.reloadAccountInfo().then(() => {
          this.authenticatedSubject.next(loginState);
        }
    )
    return this.authenticatedSubject.asObservable();
  }

  getAccountInfo(): TalentAccountInfoData {
    return  <TalentAccountInfoData>JSON.parse(localStorage.getItem(ACCOUNT_INFO));
  }

  updateOnboardingCompleted(onboardingCompleted: boolean): Promise<any> {
    return new Promise<any>(resolve => {
        this.reloadAccountInfo().then(() => {
            let accountInfo = JSON.parse(localStorage.getItem(ACCOUNT_INFO))
            accountInfo['onboardingCompleted'] = onboardingCompleted
            localStorage.setItem(ACCOUNT_INFO, JSON.stringify(accountInfo));
            resolve(true)
        })
    })

  }

  clearUserLoginSession() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCOUNT_INFO);
    localStorage.removeItem('ngx_talentData');
  }

  private isExpired() {
    try {
      const isExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(ACCESS_TOKEN));

      if (isExpired) {
        this.clearUserLoginSession();
        this.authenticatedSubject.next('LoginExpired');
      }
      return isExpired;
    } catch (e) {
      this.clearUserLoginSession();
    }

    return true;
  }

  codeLogin(code: string, sessionAttribution: SessionAttribution, messageUuid: string | null, tokenAction: TokenAction) {
    const formData = new FormData();
    formData.append('client_id', environment.frontendUnisignClientId)
    formData.append('grant_type', "urn:ietf:params:oauth:grant-type:custom_code")
    formData.append('code', code)
    return this.http.post(environment.uniSignUrl + "/oauth2/token", formData).pipe(
        tap((response: any) => {
          this.setToken(response['access_token'], response['refresh_token'])
          this.loginLoggingResource.trackUserLogin({origin: sessionAttribution? sessionAttribution : "Unknown", messageUuid: messageUuid, tokenAction: tokenAction}).then(() => {
            this.applyLogin("LoginSuccessful")
          })
        })
    )
  }

  oauthLogin(code: string, codeVerifier: string | null) {
    const formData = new FormData();
    formData.append('client_id', environment.frontendUnisignClientId)
    formData.append('grant_type', "authorization_code")
    formData.append('redirect_uri', environment.appLoginRedirectUri)
    formData.append('code', code)
    if (codeVerifier) {
      formData.append('code_verifier', codeVerifier)
    }

    this.http.post(environment.uniSignUrl + "/oauth2/token", formData).pipe(
        tap((response: any) => {
            let roleFromToken = this.jwtHelper.decodeToken(response.access_token).role as string;
            if (roleFromToken == "Student") {
                this.setToken(response['access_token'], response['refresh_token']);
                this.loginLoggingResource.trackUserLogin({origin: "OrganicLogin"}).then(() => {
                    this.applyLogin("LoginSuccessful")
                })
            } else {
                this.authenticatedSubject.next("LoginFailedRoleIncorrect");
            }
        }),
        catchError((error: HttpErrorResponse) => {
            this.authenticatedSubject.next("LoginFailedCredentialsIncorrect");
            return throwError(() => new Error('login error'))
        })
    ).subscribe(() => {})

   return this.authenticatedSubject.asObservable().pipe(take(1))
  }

  login(email: string, password: string, expectedRole: string = "Student") {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    // cannot use URLSearchParams due to compatibility issues with IE
    let formParamsStr = [
      'username=' + encodeURIComponent(email),
      'password=' + encodeURIComponent(password),
      'grant_type=password',
      'client_id=' + environment.frontendUnisignClientId
    ].join('&');

    this.http.post<any>(this.env.uniSignUrl + '/oauth2/token', formParamsStr, httpOptions).subscribe(
        (response) => {
          // user exists with credentials either as recruiter or talent.
          // now, we have to check if the user is attempting a login from the correct frontend
          if (response.access_token != null) {

            let roleFromToken = this.jwtHelper.decodeToken(response.access_token).role as string;

            if (roleFromToken == expectedRole) {
              this.setToken(response['access_token'], response['refresh_token']);
              this.loginLoggingResource.trackUserLogin({origin: "OrganicLogin"}).then(() => {
                this.applyLogin("LoginSuccessful")
              })
            } else {
              this.authenticatedSubject.next("LoginFailedRoleIncorrect");
            }
          }
        },
        (error) => {
          this.authenticatedSubject.next("LoginFailedCredentialsIncorrect");
        }
    );

    return this.authenticatedSubject.asObservable().pipe(take(1));
  }



  redirectToHome() {
    this.router.navigate(['/talent/login']);
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const info = this.getAccountInfo();

    if (token == null) {
      return false;
    }

    if (info == null || info.role != "Talent") {
      return false;
    }

    return !this.isExpired();
  }


  splitIdClass(): string {
    let accountInfo = this.getAccountInfo()
    if (accountInfo?.splitVersion == 1) return 'split1'
    if (accountInfo?.splitVersion == 0) return 'split0'
    return ""
  }
}
