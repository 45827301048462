<div class="flex row space-between-center">
    <div class="title" [ngClass]="{'title-hidden': !sticky}" *ngIf="!mediaService.getMaxWidthQuery(1080).matches">
        {{position.employmentPosition.title}}
    </div>


    <div class="flex row gap-sm sticky-buttons" [ngClass]="{'sticky-buttons-move': sticky,
    'start': !sticky && !mediaService.getMaxWidthQuery(1080).matches,
    'center-start': mediaService.getMaxWidthQuery(959).matches && sticky,
    'end-start': sticky && !mediaService.getMaxWidthQuery(1080).matches}">
        <div class="flex column center gap-sm">
            <button
                    [ngClass]="{'disabled': position?.relationData?.currentState == 'Accepted'
                             || position?.relationData?.currentState == 'Rejected' || position?.relationData?.currentState == 'Withdrawn'}"
                    class="secondary-button width-max" (click)="handleApplyClick()">
                <i
                        class="mdi mdi-open-in-new margin-right-sm" *ngIf="position.employmentPosition.employmentPositionOrigin != 'Uniwunder' && !mediaService.isMobile()"></i>
                <span *ngIf="position.employmentPosition.employmentPositionOrigin != 'Stepstone'"><span *ngIf="!mediaService.getMaxWidthQuery(370).matches">Jetzt</span> Bewerben</span>
                <span *ngIf="position.employmentPosition.employmentPositionOrigin == 'Stepstone' && !mediaService.isMobile() && !sticky">
                    Ganze Stelle auf Stepstone ansehen</span>
                <span *ngIf="position.employmentPosition.employmentPositionOrigin == 'Stepstone' && (mediaService.isMobile() || sticky)">
                    Zu Stepstone</span>
            </button>
            <div class="text-smaller text-center width-max" *ngIf="position.employmentPosition.employmentPositionOrigin == 'Uniwunder'">Kein Anschreiben nötig</div>
            <div class="text-smaller text-center width-max" *ngIf="position.employmentPosition.employmentPositionOrigin != 'Uniwunder'">Via {{position.employmentPosition.employmentPositionOrigin | employmentPositionOrigin}}</div>
        </div>

        <div class="flex start row gap-sm" [ngClass]="{'row-wrap-gap-sm': sticky || mediaService.isMobile()}">
            <app-positions-favourite-button [type]="'Button'" [positionRelation]="position.relationData" [origin]="origin"></app-positions-favourite-button>

            <button
                    [matTooltip]="'Empfehlung ablehnen'"
                    class="grey-stroked-button" (click)="handleRemoveClick()" *ngIf="origin === 'Recommendations'">
                <span *ngIf="!mediaService.getMaxWidthQuery(1320).matches ">Ablehnen</span>
                <i *ngIf="mediaService.getMaxWidthQuery(1320).matches"
                   class="mdi mdi-trash-can"></i>
            </button>
        </div>

    </div>
</div>
