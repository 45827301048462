import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
