import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountResource, TalentOnboardingResource, UniSignAccountResource, UtilsService} from "utility";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {EventOnboardingService} from "../event-onboarding.service";
import {TalentAuthService} from "../../../services/talent.auth.service";

@Component({
  selector: 'app-event-ob-password',
  templateUrl: './event-ob-password.component.html',
  styleUrls: ['./event-ob-password.component.scss']
})
export class EventObPasswordComponent implements OnInit {

    form: FormGroup

  constructor(
      private utilsService: UtilsService,
      private talentOnboardingResource: TalentOnboardingResource,
      private authService: TalentAuthService,
      private accountRes: AccountResource,
      public router: Router,
      private accountResource: UniSignAccountResource,
      private obService: EventOnboardingService,
  ) {
  }

  ngOnInit() {
      this.form = new FormGroup({
          password: new FormControl<string>(null, [Validators.required, this.utilsService.requirementsPasswordMatched(2, 6)])
      });
  }


  ngOnDestroy() {
  }

  forceValidation = new Subject;
  progressing = new Subject()
  progress() {
    this.forceValidation.next()
    if(!this.form.valid)return

    this.progressing.next()

    let password = this.form.controls.password.value
    //we dont need to wait for the result, we can just move on.


      this.accountResource.changeOwnPassword({newPassword: password})
      this.accountRes.finalizeOnboarding().then(() => {
              this.authService.updateOnboardingCompleted(true).then(() => {
                  this.obService.delayedRouting("/onboarding/event/obState")
              })
          }
      )
  }

  containsSmallLetter(): boolean {
    if (this.form.controls.password.value == null) return false;
    return (/[a-z]/.test(this.form.controls.password.value));
  }

  containsBigLetterOrNumber() {
    if (this.form.controls.password.value == null) return false;
    return (/[A-Z0-9]/.test(this.form.controls.password.value));
  }

}
