import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CompanyProfileComponent} from "./company-profile/company-profile.component";
import {CompanyOverviewCardComponent} from "./company-overview-card/company-overview-card.component";
import {CompanyOverviewComponent} from "./company-overview/company-overview.component";
import {RouterModule, Routes} from "@angular/router";
import {CompanyProfilePreviewComponent} from "../company-preview/company-profile-preview/company-profile-preview.component";
import {UtilityModule} from "utility";
import {TalentModule} from "../talent.module";
import {AuthGuardService} from "../services/auth-guard.service";
import {TalentCardModule} from "../talent-card/talent-card.module";
import {PositionsModule} from "../search/positions.module";

const appRoutes: Routes = [
  {path: 'company-overview', component: CompanyOverviewComponent, canActivate: [AuthGuardService]},
  {path: 'company-profile/:companyId', component: CompanyProfileComponent, canActivate: [AuthGuardService]},
]

@NgModule({
  declarations: [
      CompanyProfileComponent,
    CompanyOverviewCardComponent,
    CompanyOverviewComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        UtilityModule,
        TalentCardModule,
        PositionsModule
    ],
  exports: [
    CompanyProfileComponent,
    CompanyOverviewCardComponent,
    CompanyOverviewComponent
  ]
})
export class CompanyProfileModule { }
