import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {FileService} from "../../../../talent/src/app/services/file.service";
import {TalentPositionDetailsData} from "../generated/data";

@Injectable({
  providedIn: 'root'
})
export class ProfileImageService {

  whiteListedExtensions = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

  apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('env') private env,
    private fileService: FileService
  ) {
    this.apiUrl = env.apiUrl;
  }

  uploadProfilePicture(file, fileName): Observable<Object> {
    return this.uploadFile(file, fileName, "/talent/profilePicture");
  }

  private uploadFile(imageBytes, fileName, path): Observable<Object> {

    const params = new HttpParams().set('fileName', fileName);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    //Dev note: if you save the file here it works as png! id gets destroyed somewhere on the server
    return this.http.post(this.apiUrl + path, imageBytes, options);
  }

  getOwnProfilePic(): Observable<Blob> {
    return this.http.get(this.apiUrl + "/talent/profilePicture", {responseType: "blob"});
  }

  getTalentProfilePic(id): Observable<Blob> {
    return this.http.get(this.apiUrl + "/talents/" + id + "/profilePic", {responseType: "blob"});
  }

  getRecommendationImageUrl(talentPositionRelation: TalentPositionDetailsData): Promise<string> {
    return new Promise(resolve => {
      if(talentPositionRelation?.relationData?.recommendation?.userType == 'Consultant') {
        this.loadTalentAgentImageUrl().then(result => {
          resolve(result)
        })
      }

      if (talentPositionRelation?.relationData?.recommendation?.userType == 'Admin')
        this.loadAdminImageUrl(talentPositionRelation?.relationData?.recommendation?.userId).then(result => {
          resolve(result)
        })

      if (talentPositionRelation?.relationData?.recommendation?.userType == 'CompanyRecruiter')
        this.loadAdminImageUrl(11685138).then(result => {
          resolve(result)
        })
    })

  }

  loadAdminImageUrl(adminId: number): Promise<string> {
    return new Promise((resolve) =>
        this.fileService.getAdminPicture(adminId).subscribe((result) => {
          if (result.size > 0) {
            resolve(URL.createObjectURL(result));
          } else {
            resolve("./../../assets/images/blurred_user.png")
          }
        })
    )
  }

  loadTalentAgentImageUrl(): Promise<string> {
    return new Promise((resolve) =>
    this.fileService.getTalentAgentProfilePicture().subscribe((result) => {
      if (result.size > 0) {
        resolve(URL.createObjectURL(result));
      } else {
        resolve("./../../assets/images/blurred_user.png")
      }
    })
    )
  }

}
