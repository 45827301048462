import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentPositionDetailsData} from "utility";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-positions-details-info-chips',
    templateUrl: './positions-details-info-chips.component.html',
    styleUrls: ['./positions-details-info-chips.component.scss']
})
export class PositionsDetailsInfoChipsComponent implements OnInit {

    @Input() positionRelation: TalentPositionDetailsData

    constructor(
        public mediaService: MediaService,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit(): void {
    }

    getWeeklyHoursText(): string {
        const { weeklyHoursFrom: from, weeklyHoursTo: to } = this.positionRelation.employmentPosition;

        if (from === null && to === null) return null;
        if (from === to) return `${to}h`;
        if (from !== null && to !== null) return `${from} - ${to}h`;

        return `${from ?? to}h`;
    }

    getStartingDateText(): string {
        let now = new Date()
        const startingDate = this.positionRelation.employmentPosition.startingDate
        let dateString = new Date(startingDate) <= now? "ab sofort" : this.datePipe.transform(startingDate, 'dd.MM.yy')
        return `Startdatum: ${dateString}`
    }
}
