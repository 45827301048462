<mat-tab-group>
  <mat-tab label="Karrierecoach">
<div id="main-wrapper" fxLayout="row"
     fxLayoutAlign="space-around start">

  <div fxLayout="column" fxLayoutAlign="start" class="w-100 h-100">
    <div id="main-talent-agent-wrapper" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center start" id="talent-agent-information" fxLayoutGap="30px" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
        <div>
          <div class="coach-image-preview"></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" class="information-content" fxLayoutAlign.lt-sm="start center">

          <div class="title" fxLayout="row" fxLayoutAlign="start">
            <h3 class="font-size-20" style="margin-top: 0">Dein persönlicher Karrierecoach</h3>
          </div>

          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="start center">
            <div class="explanation">
              Es handelt sich hierbei um einen Karriere- und Finanzberater unseres Partners <span class="pointer" style="color: #0a8acd; white-space: nowrap" (click)="showMlpInfoDialog()">MLP <i class="mdi mdi-information"></i></span>, der speziell für unsere Plattform geschult wurde,
              um dich bei der Jobsuche und der Karriereplanung zu unterstützen.
              Selbstverständlich <b>kostenfrei</b>!
            </div>
            <button id="request-talentagent-btn" mat-flat-button color="primary" class="white" (click)="openAssignDialog()">
              Jetzt Anfordern!
            </button>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="start center">
        <h3 style="margin: 25px 0">So kann dich dein Coach unterstützen</h3>

        <coaching-offers [coachRequested]="false" ></coaching-offers>

      </div>

      <div fxLayout="row" fxLayoutAlign="center start" id="button-bar-mobile-downside"
           class="button-bar-wrapper downside w-100"
           fxLayoutGap="10px" [ngClass]="{'downside-ios': mediaService.isIOs(), 'visible': scrolled}"
           *ngIf="mediaService.getMaxWidthQuery(540).matches">

        <button mat-flat-button color="accent"
                (click)="openAssignDialog()">Jetzt Anfordern!
        </button>
      </div>
    </div>
  </div>
</div>
  </mat-tab>
  <mat-tab label="AECdisc®-Potenzialanalyse">
    <aecdisc-talentanalysis></aecdisc-talentanalysis>
  </mat-tab>
</mat-tab-group>
