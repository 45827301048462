import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'requestState'
})
export class RequestStatePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 'Pending':
        return 'Bewerbung verschickt';
      case 'Granted':
        return 'Bewerbung verschickt';
      case 'Hired':
        return 'Eingestellt';
      case 'ContractSent':
        return 'Arbeitsvertrag verschickt';
      case 'AppProcessEnter':
        return 'Im Auswahlverfahren';
      case 'Failed':
        return 'Abgelehnt';
      case 'Interesting':
        return 'Als Interessant markiert';
      case 'WithdrawnByTalent':
        return 'Kontaktdaten zurückgezogen';
    }
  }

}
