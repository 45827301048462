<div class="whoAreU" id="cpd-confirm-cv-information-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div class="mob-headline">Profil überprüfen</div>
    <div class="step-content-ob">

        <div class="m-bottom20">
            <div class="custom-label">Vorname</div>
            <hid-input-field [control]="form.controls['firstName']"
                             name="firstName"
                             type="text"
                             autocomplete="given-name"
                             [forceValidation]="checkError"
                             [showValidatorIcons]="false"
                             [showClearButton]="true">
            </hid-input-field>
        </div>

        <div class="m-bottom20">
            <div class="custom-label">Nachname</div>
            <hid-input-field [control]="form.controls['lastName']"
                             type="text"
                             name="lastName"
                             autocomplete="family-name"
                             [showValidatorIcons]="false"
                             [showClearButton]="true">
            </hid-input-field>
        </div>

        <div class="m-bottom20">
            <div class="custom-label">E-Mail Adresse</div>
            <hid-input-field
                    [forceValidation]="checkError"
                    [validationPending]="emailValidationPending" name="email"
                    [autocomplete]="'off'"
                    [showValidatorIcons]="false"
                    [showClearButton]="true"
                    autocomplete="email"
                    [control]="form.controls['contactEmailAddress']" type="email">
            </hid-input-field>
        </div>

        <span class="hid-validationError duplicate-mail-error"
              style="margin-bottom: 10px"
              *ngIf="form.controls['contactEmailAddress'].errors &&
          form.controls['contactEmailAddress'].errors['emailTaken'] &&
          !form.controls['contactEmailAddress'].value.includes('@mlp.de')">
        Diese Email-Adresse ist bereits vergeben.<br>
        <a routerLink="/talent/login">Jetzt anmelden</a> oder
        <a (click)="forgotPassword()">Neues Passwort anfordern</a>
    </span>

        <span class="hid-validationError"
              style="margin-bottom: 10px"
              *ngIf="form.controls['contactEmailAddress'].errors &&
          form.controls['contactEmailAddress'].errors['emailTaken'] &&
          form.controls['contactEmailAddress'].value.includes('@mlp.de')">
        Emailadressen, die mit @mlp.de enden sind nicht erlaubt.
    </span>

        <div class="m-bottom20">
            <div class="custom-label">Telefonnummer</div>
            <hid-input-phone-number
                    [control]="form.controls['phoneNumber']"
                    name="phoneNumber"
                    type="tel"
                    [showValidatorIcons]="false"
                    [showClearButton]="true"
                    [forceValidation]="checkError">
            </hid-input-phone-number>
        </div>


        <div>
            <div class="custom-label" style="margin-top: -7px">Wohnort</div>
            <location-input-search
                    [showLabel]="false"
                    [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                    style="width: 100%"
                    [control]="form.controls.cityIdCtrl"
            ></location-input-search>

            <small style="color: red; font-size: 12px"
                   *ngIf="(form.controls.cityIdCtrl.errors || !form.controls.cityIdCtrl.value) && form.controls.cityIdCtrl.touched">
                Bitte wähle deinen Wohnort aus, um fortzufahren.
            </small>
        </div>

        <app-cpd-o-study-search-check style="width: 100%" [control]="form.controls.subjectId"></app-cpd-o-study-search-check>
        <small style="color: red; font-size: 12px"
               *ngIf="!form.controls.subjectId.value && form.controls.subjectId.touched">
            Bitte wähle deinen (aktuellen) Studiengang aus.
        </small>

        <div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="10px" class="privacy">
            <mat-checkbox [formControl]="form.controls.acceptPrivacy" style="max-width: 350px;" #checkbox>
                <div [ngClass]="{'hid-validationError': (!form.controls.acceptPrivacy.valid && nextStepClicked)}"
                     class="use-regulations">Ich akzeptiere die <a class="font-size-12" [href]="useRegulationsUrl"
                                                                   target="_blank">Nutzerbestimmungen</a> und
                    die
                    Bestimmungen zum <a class="font-size-12" [href]="privacyUrl" target="_blank"> Datenschutz</a> und
                    bin mit den dort erklärten
                    Einwilligungen
                    einverstanden.
                </div>
            </mat-checkbox>

        </div>

        <span class="hid-validationError duplicate-mail-error"
              style="margin-top: 10px; padding: 5px 20px; text-align: center"
              *ngIf="!form.controls.acceptPrivacy.value && submitFailed"
        >
            Du musst den Nutzerbestimmungen zustimmen, um fortzufahren
        </span>


    </div>

    <app-cpd-o-button-bar [longButton]="true" [valid]="!emailValidationPending.value"
                          nextLabel="Bestätigen"
                          (pressedNext)="progress()" [buttonLockSubject]="progressingSubject">
    </app-cpd-o-button-bar>
</div>


<!--Transition animation to next step -->
<div class="position-count-animation-wrapper" [ngClass]="{'no-display': !showPositionsFoundAnimation}"
     fxLayout="column" fxLayoutAlign="center center">
    <img id="eve-image" src="../../../../assets/images/eve-dropshadow.svg">
    <div class="count-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div style="" id="available-position-count" fxLayout="row"></div>
        <div id="available-position-text">Stellen habe ich für dich gefunden!</div>
    </div>
</div>
