<app-cpd-o-step-headline >Lege noch schnell dein Passwort fest und schon kann's losgehen!</app-cpd-o-step-headline>

<div  fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="form" class="input-wrapper">
        <hid-input-field label="Passwort" type="password"
                         [control]="form.controls['password']"
                         [forceValidation]="forceValidation"></hid-input-field>
    </form>

    <div fxLayout="column" fxLayoutAlign="start start" id="password-hints">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': form.controls.password.value && form.controls.password.value.length > 5}">
            <i class="fas fa-check"></i>
            <div class="text-pw">Passwort muss mindestens <b>6 Zeichen lang</b> sein</div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': containsSmallLetter()}"><i class="fas fa-check">

        </i>
            <div class="text-pw">Passwort muss <b>einen Kleinbuchstaben</b> enthalten</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': containsBigLetterOrNumber()}">
            <i class="fas fa-check"></i>
            <div class="text-pw">Passwort muss <b>einen Großbuchstaben oder eine Zahl</b> enthalten</div>
        </div>
    </div>
</div>

<app-cpd-o-button-bar (pressedNext)="progress()" [buttonLockSubject]="progressing"></app-cpd-o-button-bar>
