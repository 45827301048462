import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appDynamicFont]'
})
export class DynamicFontDirective {
  @Input() minFontSize: number = 14;
  @Input() maxFontSize: number = 24;
  @Input() maxLines: number = 3;
  @Input() content: string = '';
  //this indicates how much the font is scaled based on the specific horizontal space of the font
  @Input() scalingFactor: number = 1.2;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.resizeFont();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeFont();
  }

  private resizeFont() {
    const element = this.el.nativeElement;
    const parentWidth = element.parentElement.offsetWidth;
    const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
    const maxHeight = lineHeight * this.maxLines;
    const estimatedFontSize = this.calculateFontSize(parentWidth, this.content.length, maxHeight);

    this.renderer.setStyle(element, 'font-size', `${estimatedFontSize}px`);
    this.renderer.setStyle(element, 'line-height', '1.2');
    this.renderer.setStyle(element, 'overflow', 'hidden');
  }

  private calculateFontSize(containerWidth: number, textLength: number, containerHeight: number): number {
    let fontSize = ((containerWidth * 3) / textLength) * this.scalingFactor;
    if(fontSize < this.minFontSize) {
      return this.minFontSize;
    }
    else if(fontSize > this.maxFontSize) {
      return this.maxFontSize;
    }
    return fontSize;
  }
}
