import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cpd-o-step-headline',
  templateUrl: './cpd-o-step-headline.component.html',
  styleUrls: ['./cpd-o-step-headline.component.scss']
})
export class CpdOStepHeadlineComponent {
    @Input() marginTop: number = 10

    constructor() { }

    ngOnInit(): void {
    }

}
