import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {delay, TalentOnboardingResource} from "utility";
import {CachedOBInputs, ConfirmProfileDetailsOnboardingService} from "../confirm-profile-details-onboarding.service";

@Component({
    selector: 'app-cpd-o-select-subject',
    templateUrl: './cpd-o-select-subject.component.html',
    styleUrls: ['./cpd-o-select-subject.component.scss']
})
export class CpdOSelectSubjectComponent implements OnInit {


    readonly control = new FormControl<number>(this.obService.cachedInputs.subjectId, [Validators.required]);

    constructor(
        private obService: ConfirmProfileDetailsOnboardingService,
        private onboardingResource: TalentOnboardingResource,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    progress() {
        this.obService.updateInputs(<CachedOBInputs>{subjectId: this.control.value});

        this.getAvailablePositionsForSubject().then(res => {
            if (res > 16) {
                this.showPositionsFoundAnimation = true
                delay(100).then(() => {
                    this.animatePositionCount(res)
                    delay(3200).then(() => {
                        this.obService.delayedRouting("/onboarding/cpd/preferences")
                    })
                })
            } else {
                this.obService.delayedRouting("/onboarding/cpd/preferences")
            }
        })

    }


    //ANIMATION STUFF//
    showPositionsFoundAnimation = false;

    availablePositions;

    getAvailablePositionsForSubject(): Promise<number> {
        return new Promise(resolve => {
            if (!this.obService.cachedInputs?.subjectId) {
                resolve(null)
                return null
            }

            this.onboardingResource.getAvailablePositionsCountForSubject(
                {subjectId: this.obService.cachedInputs?.subjectId}
            ).then(result => {
                this.availablePositions = result.matchingPositionsCount
                resolve(this.availablePositions)
            })
        })
    }

    availablePositionContent: string[] = []


    animatePositionCount(availablePositionCount: number) {
        let wrapper = document.getElementById('available-position-count')
        wrapper.innerHTML = ''
        let textElement = document.getElementById('available-position-text')

        textElement.setAttribute("class", "")
        this.availablePositionContent = availablePositionCount.toString().split('')
        for (let i = 0; i < this.availablePositionContent.length; i++) {
            let element = document.createElement('div', {});
            element.classList.add('count-number');
            let text = document.createTextNode(this.availablePositionContent[i]);
            element.appendChild(text);
            wrapper.appendChild(element)
        }
        this.changeDetectorRef.detectChanges()

        setTimeout(() => {
            textElement.classList.add('animate-position-text-fade-in')
            setTimeout(() => {
                textElement.classList.add('animate-position-text-fade-out')
            }, (3500 - (this.availablePositionContent.length * 200) - 400))
        }, (this.availablePositionContent.length * 200))
    }
}
