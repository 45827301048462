<form style="min-height:90%">
    <div class="flex column gap filter-form-inner-container">
        <section class="flex column offset-padding-horizontal">
            <div class="flex row space-between-center">

                <div class="flex row start-center">
                    <h3 class="filter-header margin-right">Wo willst du arbeiten?</h3>
                    <div *ngIf="selectedLocationIds.length > 0" class="filter-count">{{selectedLocationIds.length}}</div>
                </div>
                <button
                        (click)="clearLocations()"
                        mat-icon-button class="delete-button">
                    <i class="mdi mdi-filter-off"></i>
                </button>
            </div>
            <location-dropdown-content
            [locationIds]="searchService.searchFilterData.positionLocationIds"
            (nationWideEmitter)="nationWideChange($event)"
            (locationIdsChange)="updateLocations($event)"
            ></location-dropdown-content>
            <div class="small-text">
                Wir suchen in einem <b class="small-text">Umkreis von 50km</b> von den eingegebenen Städten
            </div>
        </section>

        <div class="divider"></div>

        <section class="flex column offset-padding-horizontal">
            <h3 class="filter-header">Willst du im Home-Office arbeiten?</h3>
            <div class="flex row-wrap-gap-sm gap-sm">
                <app-filter-selectable-chip *ngFor="let type of homeOfficeType"
                                            [value]="selectedHomeOfficeTypes.includes(type)"
                                            [text]="type | homeOffice"
                                            (click)="changeHomeOfficeType(type)">
                </app-filter-selectable-chip>
                <app-filter-selectable-chip [value]="selectedHomeOfficeTypes.length == 0"
                                            [text]="'Egal'"
                                            (click)="changeHomeOfficeType(null)"></app-filter-selectable-chip>
            </div>
        </section>

        <section class="filter-cta-placeholder">

        </section>


    </div>
</form>
<section class="flex row gap width-100 offset-padding-horizontal filter-absolute-ctas" [ngClass]="{'filter-sticky-ctas': stickyCtaButtons}">
    <button (click)="clearFilterAndSearch()"  class="light-utility-button width-50">
                <span
                        *ngIf="!mediaService.isMobile()">Filter zurücksetzen</span>
        <span
                *ngIf="mediaService.isMobile()">Zurücksetzen</span>
    </button>
    <button (click)="applyFilterAndSearch()" class="primary-utility-button width-50">
                <span
                        *ngIf="!mediaService.isMobile()">Filter anwenden</span>
        <span
                *ngIf="mediaService.isMobile()">Anwenden</span>
    </button>
</section>