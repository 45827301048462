import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-s2-fax2-o-step-headline',
  templateUrl: './s2-fax2-o-step-headline.component.html',
  styleUrls: ['./s2-fax2-o-step-headline.component.scss']
})
export class S2Fax2OStepHeadlineComponent implements OnInit {
    @Input() marginTop: number = 10

    constructor() { }

    ngOnInit(): void {
    }

}