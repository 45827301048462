import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from "../../environments/environment";
import {saveAs} from 'file-saver';
import {
    ConfirmDialogComponent,
    CVInfoData,
    InputFieldPrepopulationData,
    TalentTalentProfileResource,
    UtilityService
} from "utility";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";
import {PropagationService} from "./propagation.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    whiteListedCvExtensions = [".pdf"];
    whiteListedCvExtensionsStr = this.whiteListedCvExtensions.join(",");

    constructor(private http: HttpClient, @Inject('env') private env,
                private talentProfileResource: TalentTalentProfileResource,
                private dialog: MatDialog,
                public propagationService: PropagationService,
                private utilityService: UtilityService) {
    }

    getCVInfo(): Promise<CVInfoData> {
        return this.talentProfileResource.getCVInfo()
    }

    deleteCV(): Promise<any> {
        return new Promise(resolve => {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: 'Eintrag löschen',
                    message: 'Möchtest du den Eintrag wirklich löschen?',
                    confirmButtonText: 'Löschen',
                    confirmButtonColor: 'warn',
                    onConfirm: () => this.talentProfileResource.deleteCV()
                },
                panelClass: ['full-width-mobile-dialog', 'round-border-dialog']
            });

            let sub = dialogRef.afterClosed().subscribe(deleted => {

                if (deleted) {
                    this.utilityService.showSnackBar('Erfolgreich gelöscht');
                }

                resolve(deleted)
                sub.unsubscribe()
            });
        })
    }

    uploadFileControlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value || !control.value[0]) return {isNull: true};

            if (control.value[0].size >= 10000000) return {sizeTooBig: true};
            if (control.value[0].size == 0) return {fileIsEmpty: true};

            if (!this.whiteListedCvExtensions.some((extension, index, number) => {
                return control.value[0].name.includes(extension)
            })) {
                return {wrongExtension: true};
            }

            return null;
        }
    }

    // send file as form data
    uploadCareerEventAttachement(file: File, careerEventId): Observable<Blob> {

        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);

        return this.http.post(this.env.apiUrl + '/talentProfile/careerEvents/' + careerEventId + '/attachments', formData, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        });
    }


    createProfileAttachment(file: File, description: string): Observable<Object> {
        let options = {
            headers: new HttpHeaders(
            )
        };
        let formData: FormData = new FormData();

        formData.append('file', file);
        formData.append('description', description);
        formData.append('fileName', file.name);

        return this.http.post(this.env.apiUrl + '/talentProfile/profileAttachments/file', formData, options);
    }

    addAttachmentsToApplication(applicationId: number, attachments: FormGroup): Promise<any> {
        const attachmentsFormData = this.getApplicationAttachmentsFormData(attachments)

        return this.http.post(`${environment.apiUrl}/talent/application/${applicationId}/attachment`, attachmentsFormData, {
            headers: new HttpHeaders(),
            responseType: "json"
        }).toPromise()
    }

    getApplicationAttachmentsFormData(attachments: FormGroup) {
        let formData: FormData = new FormData();

        Object.keys(attachments.controls).forEach((controlName, id) => {
            if (attachments.controls[controlName].value) {
                formData.append(`files`, attachments.controls[controlName].value[0], attachments.controls[controlName].value[0].name);
            }
        })

        return formData
    }


    getCompanyLogoForPosition(positionId): Observable<Blob> {
        return this.http.get(this.env.apiUrl + `/talent/positions/${positionId}/company/logo`, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        });
    }

    uploadCV(file) {
        let formData: FormData = new FormData();
        let options = {
            headers: new HttpHeaders()
        };

        formData.append('file', file);
        formData.append('fileName', file.name);

        this.http.post(this.env.apiUrl + '/talentProfile/CurriculumVitae/file', formData, options).subscribe(() => {
                this.propagationService.cvUploaded.next(true)
            }
        );

        return this.propagationService.cvUploaded.asObservable()
    }

    parseCVWithoutAccount(file): Observable<InputFieldPrepopulationData> {
        let formData: FormData = new FormData();
        let options = {
            headers: new HttpHeaders()
        };

        formData.append('file', file);
        formData.append('fileName', file.name);

        return this.http.post(this.env.apiUrl + '/onboarding/CurriculumVitae/parse', formData, options).pipe(
            map(response => response as InputFieldPrepopulationData)
        )
    }

    getTalentAgentProfilePicture(): Observable<any> {
        return this.http.get(this.env.apiUrl + "/talent/me/coach/profilePicture", {
            headers: new HttpHeaders(),
            responseType: 'blob'
        });
    }

    getRecruiterProfilePicture(recruiterId: number): Observable<any> {
        return this.http.get(this.env.apiUrl + "/chat-overview/recruiterPicture/" + recruiterId, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        })
    }

    getCoachProfilePictureByInviteLinkToken(inviteToken: string): Observable<any> {
        return this.http.get(this.env.apiUrl + "/onboarding/coach/profilePicture", {
            headers: new HttpHeaders(),
            responseType: 'blob',
            params:  new HttpParams().set('token', inviteToken)
        });
    }



    getAdminPicture(adminId: number): Observable<any> {
        return this.http.get(this.env.apiUrl + `/talents/admins/${adminId}/profilePicture`, {
            headers: new HttpHeaders(),
            responseType: 'blob'
        })
    }

    getFileNameFromHeaders(headers: HttpHeaders): string {
        let contentDisposition = headers.get("Content-Disposition");
        if (!contentDisposition) return "cv.pdf";
        return headers.get("Content-Disposition").split(";")[1].trim().split("=")[1];
    }

    downloadEvaluationResult() {
        this.http.get(`${this.env.apiUrl}/evaluation/result`, {
            headers: new HttpHeaders(),
            responseType: 'arraybuffer',
            observe: 'response'
        }).subscribe((response: HttpResponse<ArrayBuffer>) => {

            let blob = new Blob([response.body]);

            if (blob.size > 0) {
                saveAs(blob, this.getFileNameFromHeaders(response.headers));
            }
        });
    }

    getEvaluationResult(): Observable<Blob> {

        let subject = new Subject<Blob>();

        this.http.get(`${this.env.apiUrl}/evaluation/result`, {
            headers: new HttpHeaders(),
            responseType: 'blob',
            observe: 'response'
        }).subscribe((response: HttpResponse<Blob>) => {
            subject.next(response.body)
        });

        return subject.asObservable()
    }
}
