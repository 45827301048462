import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";
import {CityData} from "utility";
import {AbstractControl, FormControl} from "@angular/forms";
import {Subject} from "rxjs";

@Component({
  selector: 'multiple-city-selection',
  templateUrl: './multiple-city-selection.component.html',
  styleUrls: ['./multiple-city-selection.component.scss']
})
export class MultipleCitySelectionComponent implements OnInit {

  @Input() forceValidation: Subject<any>
  @Input() disabled: Boolean = false
  @Input() initialValue = null
  @Input() control: AbstractControl

  showErrors: Boolean = false

  constructor(
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
    if (this.forceValidation) {
      this.forceValidation.subscribe(next => {
        this.showErrors = true
      })
    }
  }


  removePreferredCity(city: CityData) {
    let preferredCities = this.control.value
    let index = preferredCities.findIndex(c1 => c1.id == city.id)
    preferredCities.splice(index, 1)

    this.control.setValue(preferredCities)
  }

  pushCityToPreferredCities(event: CityData) {
    if (event === null) return
    let actualCities = []
    if (this.control.value) actualCities = actualCities.concat(this.control.value)

    if (actualCities.findIndex(city => city?.id == event.id) > -1) return
    actualCities.push(event)

    this.control.setValue(actualCities)
  }

}
