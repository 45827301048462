<dialog-header [headline]="data.title"></dialog-header>

<mat-dialog-content fxLayout="column">
  <p>{{data.message}}</p>
  <p style="font-size: small; color: gray">{{data.description}}</p>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="primary-text-color button-bar">
  <button mat-button mat-dialog-close>Abbrechen</button>

  <button mat-flat-button [color]="data.confirmButtonColor"
          [ngClass]="data.trackingClasses.join(' ')"
          (click)="confirm()"
          [loadingBool]="confirming">{{data.confirmButtonText}}
  </button>
</mat-dialog-actions>


