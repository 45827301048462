import {Injectable} from '@angular/core';
import {SlideableBottomSheetComponent} from "./slideable-bottom-sheet/slideable-bottom-sheet";
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {BehaviorSubject, Subject, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BottomSheetService {

    sheetFullHeight: Subject<boolean> = new Subject<boolean>();
    sheetExpanded: Subject<boolean> = new Subject<boolean>();
    public pauseScrollAndResize: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentBottomSheetRef: MatBottomSheetRef<SlideableBottomSheetComponent, any> | null = null;
    skipMobileScroll: boolean = false;
    dismissSubscription: Subscription | null = null;

    constructor(
        private bottomSheet: MatBottomSheet,
    ) {
    }

    openBottomSheet(component: any, componentInputs?: any): Promise<MatBottomSheetRef<SlideableBottomSheetComponent, any>> {
        this.skipMobileScroll = true
        return new Promise<MatBottomSheetRef<SlideableBottomSheetComponent, any>>(resolve => {
            if (this.currentBottomSheetRef) {
                this.currentBottomSheetRef.afterDismissed().toPromise().then(() => {
                    this.pauseScrollAndResize.next(false)
                    this.openNewBottomSheet(component, componentInputs);
                    resolve(this.currentBottomSheetRef);
                }).catch(error => {
                    console.warn('Warning after dismissing bottom sheet:', error);
                    this.pauseScrollAndResize.next(false)
                    resolve(this.currentBottomSheetRef);
                });
                this.bottomSheet.dismiss();
            } else {
                this.currentBottomSheetRef = this.openNewBottomSheet(component, componentInputs);
                resolve(this.currentBottomSheetRef);
            }
        });
    }

    private openNewBottomSheet(component: any, componentInputs?: any) {
        try {
            this.currentBottomSheetRef = null
            let ref = this.bottomSheet.open(SlideableBottomSheetComponent, {
                data: { component, componentInputs }
            });
            this.currentBottomSheetRef = ref;
            this.dismissSubscription = ref.afterDismissed().subscribe(() => {
                this.skipMobileScroll = false
                this.pauseScrollAndResize.next(false)
                this.dismissSubscription.unsubscribe()
            });
            return ref;
        } catch (e) {
            console.error('Error opening bottom sheet:', e);
        }
    }

    public closeCurrentBottomSheet() {
        this.skipMobileScroll = false
        this.pauseScrollAndResize.next(false)
        if (this.currentBottomSheetRef) {
            this.currentBottomSheetRef.dismiss();
            this.currentBottomSheetRef = null;
        }
    }

    public getCurrentBottomSheetRef() {
        console.log('currentBottomSheetRef: ', this.currentBottomSheetRef);
        return this.bottomSheet._openedBottomSheetRef;
    }

    public scrollBottomSheetToBottom() {
        if (this.currentBottomSheetRef) {
            setTimeout(() => {
                const bottomSheetContainer = document.querySelector('.mat-bottom-sheet-container');
                if (bottomSheetContainer) {
                    bottomSheetContainer.scrollTop = bottomSheetContainer.scrollHeight;
                }
            }, 0);
        }
    }
}
