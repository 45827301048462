import {Component, Input, OnInit} from '@angular/core';
import {TalentPositionInteractionOrigin, TalentPositionRelationData} from "utility";
import {PositionService} from "../../services/position.service";
import {PropagationService} from "../../services/propagation.service";
import {TalentAuthService} from "../../services/talent.auth.service";
import {MediaService} from "../../services/inno-utils/media.service";

@Component({
  selector: 'app-positions-favourite-button',
  templateUrl: './positions-favourite-button.component.html',
  styleUrls: ['./positions-favourite-button.component.scss']
})
export class PositionsFavouriteButtonComponent implements OnInit {

  @Input() type: 'Icon' | 'Button' = 'Icon'
  @Input() color: 'Teal' | 'Grey' = 'Grey'

    @Input() positionRelation: TalentPositionRelationData
    @Input() origin: TalentPositionInteractionOrigin

    loading: boolean = false

  constructor(
      public positionService: PositionService,
      public propagationService: PropagationService,
      private authService: TalentAuthService,
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
  }

  isBookmarked(): boolean {
      return this.positionRelation? this.propagationService.bookmarkedPositionIds.includes(this.positionRelation.positionId) : false
  }

    flipBookmark() {
        if (!this.authService.isAuthenticated()) return
        this.loading = true;
        const positionId = this.positionRelation.positionId
        if(!this.propagationService.bookmarkedPositionIds.includes(this.positionRelation.positionId)){
            this.propagationService.bookmarkedPositionIds.push(positionId)
            this.positionService.bookmarkProposal(this.positionRelation, this.origin).then(() =>{
                this.positionService.relationProceeded.next({relation: this.positionRelation, action: "Bookmarked"})
            })
        }else{
            this.propagationService.bookmarkedPositionIds.splice(this.propagationService.bookmarkedPositionIds.indexOf(positionId), 1)
            this.positionService.removeBookmark(this.positionRelation).then(() =>{
                this.positionService.relationProceeded.next({relation: this.positionRelation, action: "BookmarkRemoved"})
            })
        }
        this.positionRelation.currentState = 'Bookmarked'
    }

}
