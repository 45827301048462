import {Injectable} from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import {Platform} from "@angular/cdk/platform";

@Injectable()
export class GermanDateAdapter extends NativeDateAdapter {
  constructor(platform: Platform) {
    super('de-DE', platform);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
