import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";

@Component({
  selector: 'app-co-welcome',
  templateUrl: './co-welcome.component.html',
  styleUrls: ['./co-welcome.component.scss']
})
export class CoWelcomeComponent implements OnInit {

  smallerEve: boolean = false
  readonly form = new FormGroup({
    firstName: new FormControl<string>(this.obService.cachedInputs.firstName, Validators.required)
  });
  error: Subject<any> = new Subject<any>()

  constructor(
      private obService: CoachingOnboardingService) {
  }

  ngOnInit(): void {
    this.obService.reportCurrentStepPageview()
  }

  progress(){
    if(!this.form.valid){
      this.error.next();
      return
    }

    this.obService.updateInputs(<CachedOBInputs>{firstName: this.form.controls.firstName.value});
    this.obService.delayedRouting('onboarding/coach/coach-intent')
  }

}
