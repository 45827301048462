<div class="partipicipants-wrapper">
    <div class="partipicipants">
        <ng-content></ng-content>
    </div>
    <div class="width-100 grid-tile-main-container">
        <div *ngFor="let data of companies" class="company" (click)="openCompanyProfileDialog(data)">
            <button class="like-button" color="primary" mat-icon-button (click)="$event.stopImmediatePropagation(); toggleInterestForCompany(data)">
                <i *ngIf="!data.interesting" class="mdi mdi-star-outline"></i>
                <i *ngIf="data.interesting" class="mdi mdi-star"></i>
            </button>
            <company-logo-with-name [company]="data.company.company"></company-logo-with-name>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="see-company"><i class="mdi mdi-eye-outline"></i><div class="text">Ansehen</div></div>
        </div>
    </div>
</div>