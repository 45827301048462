<div class="home-m-big-w">
    <div class="home-m-big-headline" >
        <i style="font-size: 20px; margin-right: 10px" class="mdi mdi-file-pdf"></i>
        <div>{{'Next Step: Lebenslauf'}}</div>
    </div>

    <div class="home-m-big-c-w" fxLayout="column" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="center start">
            <i class="mdi mdi-file-pdf big-file-pdf"></i>
            <div class="description">Lade deinen Lebenslauf hoch um bessere Vorschläge zu erhalten und Bewerbungen zu verschicken!</div>
        </div>
        <cv-upload-button style="margin-top: 20px" [bttColor]="'#ffffff'" [borderRadius]="50" [buttonClass]="'ita_dashboard ' + authService.splitIdClass()"></cv-upload-button>
    </div>
</div>
