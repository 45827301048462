<div class="home-c-w-m" (click)="router.navigateByUrl('/talent/proposals/recommendations')">
    <div class="home-c-headline-w">Persönliche Vorschläge</div>
    <div class="home-c-c-w-m" fxLayout="row" fxLayoutAlign="center center">
        <div class="rec-count">{{propagationService.numRecommendedProposals}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
<!--        <div class="recommendation-image-wrapper">-->
<!--            <div class="recommendation-image"></div>-->
<!--        </div>-->
        <div style="text-align: start">Wir haben neue Stellenvorschläge für dich, schau sie dir direkt an</div>
    </div>
</div>
