<div class="flex column start width-100 ">

    <div class="width-100 recommended-wrapper">

        <div class="content-width flex column gap inner-wrapper">
            <div class="flex row gap start-center">
                <i class="mdi mdi-creation"></i>
                <h1 class="recommended-header">Deine persönlichen Empfehlungen</h1>
            </div>
            <p>
                Wir sehen uns dein Profil an und unterbreiten dir dann ausgewählte Vorschläge.

<!--                <span *ngIf="!recommendationService.loading.value && !recommendationService.recommendedPositions?.length">Aktuell haben wir nichts Neues für dich, gib uns noch ein bisschen Zeit! Wenn du ungeduldig wirst,-->
<!--                    schreib uns einfach ;)</span>-->
            </p>

            <div class="margin-vertical"
                 id="sms-container">
                Du bekommst zuviele E-Mails / Sms?
                <a class="margin-left bold-text hover-dark-overlay" (click)="routeToSettings()">Benachrichtigungen
                anpassen</a>
            </div>
        </div>
    </div>

    <app-positions-wrapper-preview-details
            class="width-100"
            [positions]="recommendationService.recommendedPositions"
            [page]="recommendationService.pageIndex"
            [totalPositions]="recommendationService.totalRecommendations"
            [totalPages]="recommendationService.totalPages"
            [loading]="recommendationService.loading"
            (paginatorNextIndex)="this.recommendationService.loadPositionRecommendations(null, $event)"
            origin="Recommendations"
    >
    </app-positions-wrapper-preview-details>
    <div *ngIf="!recommendationService.loading.value && recommendationService.recommendedPositions.length == 0" class="padding-horizontal flex column center width-100 center-text">
        <p class="cap-width-sm">Aktuell haben wir nichts Neues für dich, gib uns noch ein bisschen Zeit! Wenn du ungeduldig wirst,
            schreib uns einfach oder suche jetzt schon deinen Traumjob in unserer Stellensuche!</p>
        <button class="primary-button" routerLink="/talent/positions">Zu unseren Stellen</button>
    </div>
</div>
