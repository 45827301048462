<loading-spinner style="position: relative; top: 70px" [loadingVar]="pageLoading"></loading-spinner>
<mat-tab-group class="custom-group max-w1064" color="accent" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Karrierecoach">
        <div *ngIf="!pageLoading" id="main-wrapper" [ngClass]="{'mat-elevation-z2': !mediaService.isMobile()}"
             fxLayout="row"
             fxLayoutAlign="space-around start">
            <div fxLayout="column" fxLayoutAlign="start" class="w-100" style="height: 100%;">
<!--                mobile-->
                <div class="main-talent-agent-wrapper" fxLayout="column" fxLayoutAlign="start center"
                     *ngIf="mediaService.mobileQuery.matches">
                    <div class="mlp-logo-wrapper-mobile pointer" (click)="showMlpInfoDialog()"
                         matTooltip="Mehr über MLP"
                         fxLayout="row" fxLayoutAlign="start center">
                        <div class="mlp-logo"></div>
                        <i class="mdi  mdi-information-outline"></i>
                    </div>

                    <h1 class="your-coach-headline">Dein Karrierecoach</h1>

                    <div fxLayout="column" fxLayoutAlign="start center" class="talent-agent-info">

                        <div class="mat-elevation-z2 talent-agent-image">
                            <coach-image></coach-image>
                        </div>

                        <div *ngIf="talentCoachData?.talentAgent != null" fxLayout="column" fxLayoutAlign="start center"
                             class="information-content">
                            <div
                                    class="talent-agent-name">{{talentCoachData?.talentAgent?.firstName}} {{talentCoachData?.talentAgent?.lastName}}</div>
                            <span class="text-center">{{talentCoachData?.talentAgent?.title}}</span>
                            <div fxLayout="column" fxLayoutAlign="start center">
                                <div class="coach-about-me">
                                    <cite *ngIf="talentCoachData?.talentAgent?.aboutMe"><q>{{talentCoachData?.talentAgent?.aboutMe}}</q></cite>
                                </div>
                            </div>

                            <!--Contact Information-->
                            <talent-agent-contact [talentCoachData]="talentCoachData"></talent-agent-contact>

                            <div *ngIf="talentCoachData?.talentAgent?.flexpertoLink">
                                <button class="create-appointment mlp-button ita_coach_profile ita_coach_appointment" color="primary" mat-stroked-button
                                        (click)="routeToFlexperto()">
                                    Flexperto
                                </button>
                            </div>

                            <div *ngIf="talentCoachData?.assessableForTalent" class=" m-top10 m-bottom10">
                                <button mat-stroked-button color="accent" (click)="openJudgeTalentAgentDialog()"
                                        class="mlp-button">Bewerte deinen Karrierecoach
                                </button>
                            </div>

                            <div *ngIf="talentCoachData?.talentAgent != null && talentCoachData?.appointmentRequestable" class=" m-top10 m-bottom10">
                                <button class="create-appointment mlp-button-flat ita_coach_profile ita_coach_appointment" color="primary" mat-flat-button
                                        (click)="openConsultantAppointmentDialog()">
                                    <span class="font-size-12">Gespräch ausmachen</span>
                                </button>
                            </div>


                        </div>

                        <!--            coach requested but not received-->
                        <div *ngIf="talentCoachData?.talentAgent == null && talentCoachData?.requestedAt" fxLayout="column" fxLayoutAlign="start center"
                             class="information-content">
                            <h4 style="font-weight: bold">Noch ein wenig Geduld ...</h4>
                            <p class="patience-explanation" *ngIf="talentCoachData.coachAssignableNow">
                                Du bekommst eine Benachrichtigung, sobald wir den perfekten Coach für Dich gefunden
                                haben!
                            </p>
                            <p class="patience-explanation" *ngIf="!talentCoachData.coachAssignableNow && talentCoachData.coachAssignableFrom">
                                Du bekommst erst dann einen Karrierecoach, wenn du maximal 6 Monate vor dem Ende deines Studiums stehst.
                                Aktuell erhälst du nicht vor dem <span style="font-weight: bold; font-style:italic">{{coachAssignableFrom | date: 'dd.MM.yyyy'}}</span> deinen Coach.
                            </p>
                        </div>

                        <div class="information-content"
                             fxLayout="column"
                             fxLayoutAlign="start center"
                             *ngIf="talentCoachData?.talentAgent == null && !talentCoachData?.requestedAt">
                            <div class="text-center m-bottom20">
                                Es handelt sich hierbei um einen Karriere- und Finanzberater unseres Partners <span class="pointer" style="color: #0a8acd; white-space: nowrap" (click)="showMlpInfoDialog()">MLP <i class="mdi mdi-information"></i></span>, der speziell für unsere Plattform geschult wurde,
                                um dich bei der Jobsuche und der Karriereplanung zu unterstützen.
                                Selbstverständlich <b>kostenfrei</b>!
                            </div>

                            <div>
                                <button class="create-appointment mlp-button ita_coach_profile ita_request_coach" color="primary" mat-stroked-button
                                        (click)="openAssignDialog()">
                                    Coach anfordern
                                </button>
                            </div>

                            <div class="m-top30" *ngIf="!cvInfo?.uploadDate && talentCoachData?.talentAgent == null && !talentCoachData?.requestedAt"
                            fxLayout="column" fxLayoutAlign="start center">
                                <div class="text-center m-bottom20">
                                    Um dir einen passenden Karrierecoach zu vermitteln, brauchen wir deinen Lebenslauf!
                                    (Dein Lebenslauf hilft uns dabei, einzuschätzen, welcher Karrierecoach am besten zu dir passt!)
                                </div>

                                <div>
                                    <cv-upload-button [buttonClass]="'ita_coach_profile'"></cv-upload-button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!--        desktop-->
                <div class="main-talent-agent-wrapper" fxLayout="column" fxLayoutAlign="start start"
                     *ngIf="!mediaService.mobileQuery.matches">

                    <div class="mlp-logo-wrapper-mobile pointer" (click)="showMlpInfoDialog()"
                         matTooltip="Mehr über MLP" fxLayout="row" fxLayoutAlign="start center">
                        <div class="mlp-logo"></div>
                        <i class="mdi  mdi-information-outline"></i>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-around start" class="width-100" fxLayoutGap="30px">

                        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <div class="width-100 text-center">
                                <h1 class="your-coach-headline">Dein Karrierecoach</h1>
                            </div>

                            <div class="mat-elevation-z2 talent-agent-image">
                                <coach-image></coach-image>
                            </div>

                            <div class="coach-about-me" *ngIf="talentCoachData?.talentAgent">
                                <cite *ngIf="talentCoachData?.talentAgent?.aboutMe"><q>{{talentCoachData?.talentAgent?.aboutMe}}</q></cite>
                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                            <!--                    Coach Information-->
                            <div fxLayout="column" fxLayoutAlign="start center" class="talent-agent-info">
                                <div *ngIf="talentCoachData?.talentAgent != null" fxLayout="column"
                                     fxLayoutAlign="start start" fxLayoutGap="5px"
                                     class="information-content">

                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <div class="talent-agent-name font-size-20">
                                            {{talentCoachData?.talentAgent?.firstName}} {{talentCoachData?.talentAgent?.lastName}}
                                        </div>
                                    </div>

                                    <span>{{talentCoachData?.talentAgent?.title}}</span>

                                    <!--          contact-->
                                    <talent-agent-contact class="m-top20" [talentCoachData]="talentCoachData"></talent-agent-contact>


                                    <div *ngIf="talentCoachData?.talentAgent?.flexpertoLink">
                                        <button class="create-appointment mlp-button ita_coach_profile ita_coach_appointment" color="primary" mat-stroked-button
                                                (click)="routeToFlexperto()">
                                            Termin vereinbaren
                                        </button>
                                    </div>

                                    <div *ngIf="talentCoachData?.assessableForTalent" class=" m-top10 m-bottom10">
                                        <button style="width: 245px;" mat-stroked-button color="accent" (click)="openJudgeTalentAgentDialog()"
                                                class="mlp-button">Bewerte deinen Karrierecoach
                                        </button>
                                    </div>

                                    <div *ngIf="talentCoachData?.talentAgent != null && talentCoachData?.appointmentRequestable" class=" m-top10 m-bottom10 ita_coach_profile ita_coach_appointment">
                                        <button style="width: 245px;" mat-stroked-button color="accent" (click)="openConsultantAppointmentDialog()"
                                                class="mlp-flat-button">Gespräch ausmachen
                                        </button>
                                    </div>
                                </div>

                                <!--            coach requested but not received-->
                                <div *ngIf="talentCoachData?.talentAgent == null && talentCoachData?.requestedAt" fxLayout="column"
                                     fxLayoutAlign="start center"
                                     class="information-content">
                                    <h4 style="font-weight: bold">Noch ein wenig Geduld ...</h4>
                                    <p class="patience-explanation" *ngIf="talentCoachData.coachAssignableNow">
                                        Du bekommst eine Benachrichtigung, sobald wir den perfekten Coach für Dich gefunden
                                        haben!
                                    </p>
                                    <p class="patience-explanation" *ngIf="!talentCoachData.coachAssignableNow && talentCoachData.coachAssignableFrom">
                                        Du bekommst erst dann einen Karrierecoach, wenn du maximal 6 Monate vor dem Ende deines Studiums stehst.
                                        Aktuell erhälst du nicht vor dem <span style="font-weight: bold; font-style: italic">{{coachAssignableFrom | date: 'dd.MM.yyyy'}}</span> deinen Coach.
                                    </p>
                                </div>

                                <div class="information-content"
                                     fxLayout="column"
                                     fxLayoutAlign="start center"
                                     *ngIf="talentCoachData?.talentAgent == null && !talentCoachData?.requestedAt">
                                    <div class="text-center m-bottom20">
                                        Es handelt sich hierbei um einen Karriere- und Finanzberater unseres Partners <span class="pointer" style="color: #0a8acd; white-space: nowrap" (click)="showMlpInfoDialog()">MLP <i class="mdi mdi-information"></i></span>, der speziell für unsere Plattform geschult wurde,
                                        um dich bei der Jobsuche und der Karriereplanung zu unterstützen.
                                        Selbstverständlich <b>kostenfrei</b>!
                                    </div>

                                    <div>
                                        <button class="create-appointment mlp-button ita_coach_profile ita_request_coach" color="primary" mat-stroked-button
                                                (click)="openAssignDialog()">
                                            Coach anfordern
                                        </button>
                                    </div>
                                </div>

                                <div class="m-top30" *ngIf="!cvInfo?.uploadDate && talentCoachData?.talentAgent == null && !talentCoachData?.requestedAt" fxLayout="column" fxLayoutAlign="start center">
                                    <div class="text-center m-bottom20">
                                        Um dir einen passenden Karrierecoach zu vermitteln, brauchen wir deinen Lebenslauf!
                                        (Dein Lebenslauf hilft uns dabei, einzuschätzen, welcher Karrierecoach am besten zu dir passt!)
                                    </div>

                                    <div>
                                        <cv-upload-button [buttonClass]="'ita_coach_profile'"></cv-upload-button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" class="support-wrapper">
                    <div fxLayout="column" fxLayoutAlign="start center">
                        <h3 *ngIf="talentCoachData?.talentAgent != null" class="help-headertext">Wie kann ich dich
                            unterstützen?</h3>
                        <h3 *ngIf="talentCoachData?.talentAgent == null && talentCoachData?.requestedAt"
                            class="help-headertext">
                            Wie soll dich dein Karrierecoach unterstützen?
                        </h3>

                        <h3 *ngIf="talentCoachData?.talentAgent == null && !talentCoachData?.requestedAt"
                            class="help-headertext">
                            So kann dich dein Karrierecoach unterstützen
                        </h3>

                        <coaching-offers [coachRequested]="talentCoachData?.requestedAt == null? false:true" ></coaching-offers>

                    </div>

                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="AECdisc®-Potenzialanalyse">
        <aecdisc-talentanalysis></aecdisc-talentanalysis>
    </mat-tab>

</mat-tab-group>



