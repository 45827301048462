import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PerfectMatchCvData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.PerfectMatchApiControllerV2
 */

@Injectable()
@ResourceParams({})
export class PerfectMatchApiResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/perfectMatch/{code}/cv/infos',
        endpoint: 'de.syntacton.cronos'
    })
    private _getJobApplicationInfos: IResourceMethodObservableStrict<null, null, {code: string}, PerfectMatchCvData> | undefined
    getJobApplicationInfos(code: string): Promise<PerfectMatchCvData> {
        if (!this._getJobApplicationInfos) throw new Error("resource has not been properly initialized")
        return this._getJobApplicationInfos(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/{code}/cv/infos',
        endpoint: 'de.syntacton.cronos'
    })
    private _submitJobApplication: IResourceMethodObservableStrict<PerfectMatchCvData, null, {code: string}, null> | undefined
    submitJobApplication(requestBody: PerfectMatchCvData, code: string): Promise<null> {
        if (!this._submitJobApplication) throw new Error("resource has not been properly initialized")
        return this._submitJobApplication(requestBody, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/{code}/cv/upload',
        endpoint: 'de.syntacton.cronos',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadCvFile: IResourceMethodObservableStrict<{file: File}, null, {code: string}, JsonWrappedValue<('SERVER_ERROR' | 'FILE_TYPE_NOT_ALLOWED' | 'SUCCESS')>> | undefined
    uploadCvFile(requestBody: {file: File}, code: string): Promise<JsonWrappedValue<('SERVER_ERROR' | 'FILE_TYPE_NOT_ALLOWED' | 'SUCCESS')>> {
        if (!this._uploadCvFile) throw new Error("resource has not been properly initialized")
        return this._uploadCvFile(requestBody, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/newContact/cv/upload',
        endpoint: 'de.syntacton.cronos',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadCvFileForNewContact: IResourceMethodObservableStrict<{firstName: string, lastName: string, birthDate: Date, emailAddress: string, applicationLevel: ('Internship' | 'Consultant' | 'Trainee' | 'Other'), phoneNumber: string, studyEndDate: Date, zipCode: string, locationName: string, file?: File}, null, null, null> | undefined
    uploadCvFileForNewContact(requestBody: {firstName: string, lastName: string, birthDate: Date, emailAddress: string, applicationLevel: ('Internship' | 'Consultant' | 'Trainee' | 'Other'), phoneNumber: string, studyEndDate: Date, zipCode: string, locationName: string, file?: File}): Promise<null> {
        if (!this._uploadCvFileForNewContact) throw new Error("resource has not been properly initialized")
        return this._uploadCvFileForNewContact(requestBody, null, null).toPromise()
    }

}