import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {TalentAuthService} from "./talent.auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: TalentAuthService,
    public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if(route.parent.routeConfig?.path.includes('company-profile')){
      return true
    }

    if (
        route.parent.routeConfig?.path.includes('onboarding/application')
        && route.url[0]?.path.includes('contact-data')
    ) {
      const positionId = route.queryParamMap.get('positionId');

      if (!positionId) {
        return false;
      }

      if (this.auth.isAuthenticated()) {
        this.router.navigate(
            ['talent/applications'],
            { queryParams: { 'apply-for-position': positionId} }
        );

        return true;
      }
      return true;
    }

    if (!this.auth.isAuthenticated()) {
        this.router.navigate(['/'], {queryParams: {logout: true}});;
      return false;
    }

    if ((route.url[2]?.path.includes('matches') || route.url[2]?.path.includes('search'))) {
      this.router.navigate(['/talent/positions']);
      return false;
    }

    let accountInfo = this.auth.getAccountInfo()


    //manage routing for logged in users but not finieshed onboarding
    if (this.auth.isAuthenticated() && !accountInfo.onboardingCompleted) {

      let url: string = "onboarding"

      switch (accountInfo.onboardingVersion) {
        case "InstantOb": url = "/onboarding/application/instantApplicationState"; break;
        case "EventOnboarding": url = "/onboarding/event/obState"; break;
        case "CoachingOnboarding": url = "/onboarding/coach/obState"; break;
      }

      this.router.navigateByUrl(url)

      return false
    }

    return true;
  }
}
