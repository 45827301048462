import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'home-headline',
  templateUrl: './home-headline.component.html',
  styleUrls: ['./home-headline.component.scss']
})
export class HomeHeadlineComponent implements OnInit {

  @Input() icon: string
  @Input() iconColor: string = '#ffffff'
  @Input() iconBgColor: string = '#4d4d4d'

  constructor() {
  }

  ngOnInit(): void {
  }

  getIcon(): string {
    return `mdi mdi-${this.icon}`
  }

}
