import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaService} from "./media.service";

@NgModule({
  declarations: [],
  providers:[
    MediaService
  ],
  imports: [
    CommonModule
  ],
  exports: [
  ]
})
export class InnoUtilsModule {


}

export function arrayContains(array: any[], value: any) {
  return array.indexOf(value) > -1
}

export function pushOrRemove(array: any[], value:any){
  if(arrayContains(array, value)){
    array.splice(array.indexOf(value),1)
  }else{
    array.push(value)
  }
}
