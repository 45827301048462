<div class="home-c-w">
    <home-headline routerLink="/talent/agent" iconColor="#033D5D" style="cursor: pointer" icon="swap-horizontal-bold" iconBgColor="#ffffff">
        <span style="color: #FFFFFF" *ngIf="!talentCoachData?.talentAgent">{{getHeadline()}}</span>
        <div *ngIf="talentCoachData?.talentAgent" class="coach-name">
            <div>Dein Coach:</div>
            <div>{{talentCoachData?.talentAgent?.firstName}} {{talentCoachData?.talentAgent?.lastName}}</div>
        </div>
    </home-headline>
    <div class="home-c-c-w" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!talentCoachData?.talentAgent && !talentCoachData?.requestedAt">
<!--    <div class="home-c-c-w" fxLayout="row" fxLayoutAlign="space-around center" *ngIf="false">-->
        <div class="coach-image-preview" fxLayout="column" fxLayoutAlign="end ">
            <img src="assets/images/careerCoach/BeraterPNG.png">
        </div>
        <div class="request-content" fxLayout="column" fxLayoutAlign="start center">
            <div class="request-box" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="6px">
                <div>Lebenslaufcheck</div>
                <div>Persönlichkeitsanalyse</div>
                <div>Coaching Gehaltsverhandlung</div>
                <img src="assets/images/mlp-logo.png" width="30px">
            </div>
            <button style="background-color: #A3825B !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" [class]="authService.splitIdClass()" class="mlp-button ita_request_coach ita_dashboard" (click)="openAssignDialog()">Coach anfordern</button>
        </div>
    </div>

    <div class="home-c-c-w" fxLayout="column" fxLayoutAlign="center center" *ngIf="talentCoachData?.requestedAt && !talentCoachData?.talentAgent">
        <img src="assets/images/mlp-logo.png" class="mlp-logo-waiting" width="60px">
        <div class="waiting-logo-wrapper">
            <i class="mdi mdi-account-search"></i>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="4px" *ngIf="!talentCoachData.coachAssignableNow && talentCoachData.coachAssignableFrom">
            <i class="mdi mdi-information-outline" style="font-size: 12px; color: var(--light-grey)"></i>
            <p class="patience-explanation">
                Du bekommst erst dann einen Karrierecoach, wenn du maximal 6 Monate vor dem Ende deines Studiums stehst.
                Aktuell erhälst du nicht vor dem <span style="font-weight: bold; font-style: italic">{{coachAssignableFrom | date: 'dd.MM.yyyy'}}</span> deinen Coach.
            </p>
        </div>
    </div>

    <div class="home-c-c-w" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="talentCoachData?.talentAgent">
        <div class="talent-agent-image">
            <coach-image [questionSize]="24"></coach-image>
        </div>

        <div class="button-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="6px">
            <button style="background-color: #033D5D !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" class="mlp-button" routerLink="/talent/agent">zu meinem Coach</button>
            <button *ngIf="talentCoachData?.appointmentRequestable" style="background-color: #A3825B !important; border-radius: 20px; font-size: 12px" mat-flat-button color="primary" class="mlp-button ita_coach_appointment ita_dashboard" [class]="authService.splitIdClass()" (click)="openConsultantAppointmentDialog()">Termin ausmachen</button>
        </div>
    </div>
</div>