<div class="flex column gap-sm light-container"
     [ngClass]="{'start': !mediaService.isMobile(), 'center': mediaService.isMobile()}">
    <div class="flex gap"
         [ngClass]="{'row': !mediaService.isMobile(), 'start': !mediaService.isMobile(), 'column': mediaService.isMobile(), 'center': mediaService.isMobile()}">
        <div class="flex column gap-sm">

            <div class="flex gap"
                 [ngClass]="{'row': !mediaService.isMobile, 'start-center': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
                <i class="mdi mdi-domain"></i>
                <h2 class="company-title" [innerHtml]="'Über ' + company.name | safeHtml"></h2>
            </div>

            <div [innerHtml]="companyDescription | safeHtml">
            </div>
        </div>

        <company-picture class="company-image" [apiPositionLogoUrl]="apiPositionLogoUrl" [companyId]="company.id"></company-picture>
    </div>

    <div class="flex row" *ngIf="company.showCompanyProfile">
        <a class="grey-stroked-button" style="text-decoration: none" [routerLink]="createCompanyProfileLink()" target="_blank" *ngIf="!mediaService.getMaxWidthQuery(800).matches">
            Zu unserem Unternehmensprofil
        </a>
        <a class="grey-stroked-button" style="text-decoration: none" (click)="openCompanyProfileDialog()" *ngIf="mediaService.getMaxWidthQuery(800).matches">
            Zu unserem Unternehmensprofil
        </a>
    </div>

</div>
