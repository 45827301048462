import { Component, OnInit } from '@angular/core';
import {TalentAccountResource} from "utility";
import {TalentAuthService} from "../../services/talent.auth.service";

@Component({
  selector: 'app-phone-number-validation',
  templateUrl: './phone-number-validation.component.html',
  styleUrls: ['./phone-number-validation.component.scss']
})
export class PhoneNumberValidationComponent implements OnInit {

    loading: boolean = true

  constructor(
      private talentAccountResource: TalentAccountResource,
      private talentAuthService: TalentAuthService
  ) { }

  ngOnInit(): void {
        if (this.talentAuthService.isAuthenticated()) {
            this.validatePhoneNumber()
        }
  }

  validatePhoneNumber() {
        this.talentAccountResource.validatePhoneNumber().then(result => {
            if (result) this.loading = false
        })
  }

}
