import { Component, OnInit } from '@angular/core';
import {ProfileImageService, TalentApplicationResource} from "utility";

@Component({
  selector: 'application-processbar',
  templateUrl: './application-processbar.component.html',
  styleUrls: ['./application-processbar.component.scss']
})
export class ApplicationProcessbarComponent implements OnInit {

  noProfilePicBool: boolean = true
  applicationCount: number = 0
  public profilePicture;

  constructor(
      private applicationResource: TalentApplicationResource,
      private profileImageService: ProfileImageService
  ) { }

  ngOnInit(): void {
    this.loadProfilePic()
    this.getApplicationCount()
  }

  scaleText(): boolean {
    return this.applicationCount !== 0;
  }

  getProcessbarWidth(): number {
    if (this.applicationCount === 0) return 0
    if (this.applicationCount >= 10) return 100

    return this.applicationCount/10*100
  }

  getApplicationCount() {
    this.applicationResource.getApplicationCountByTalent().then(result => {
      this.applicationCount = result.value
    })
  }

  loadProfilePic() {
    this.profileImageService.getOwnProfilePic().subscribe(b64 => {
      if (b64.size == null || b64.size == 0) this.noProfilePicBool = true;

      else {
        this.noProfilePicBool = false;
        let blob = new Blob([b64], {type: 'image/png'})
        this.profilePicture = URL.createObjectURL(blob)
      }
    })
  }

}
