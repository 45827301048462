<div fxLayout="column" style="width: 100%">
    <app-event-ob-step-headline>Gute Entscheidung {{this.onboardingService?.cachedInputs?.firstName}}! 💪 In welcher Stadt wohnst du?</app-event-ob-step-headline>

    <div>
        <location-input-search
                [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                style="width: 100%"
                label="Name oder PLZ"
                [control]="talentCityCtrl"
        ></location-input-search>

        <small style="color: red; font-size: 12px" *ngIf="talentCityCtrl.errors && talentCityCtrl.touched">
            Bitte wähle deinen Wohnort aus, um fortzufahren.
        </small>
    </div>

    <app-event-ob-step-headline>Wann schließt du dein Studium ab? <br> <span>(Eine Schätzung reicht vollkommen aus)</span></app-event-ob-step-headline>

    <div  fxLayout="column" fxLayoutAlign="center center">
        <div class="step-content-ob" style="margin-top: 20px">
            <study-end-date-selection [control]="studyEndDateCtl"></study-end-date-selection>
        </div>
        <div *ngIf="this.error.value"
             class="hid-validationError duplicate-mail-error"
             style="margin-top: 10px; padding: 5px 20px; text-align: center">
            Bitte gib dein ungefähres Studienende an
        </div>
    </div>

    <german-skill-level [germanSkillLevelCtrl]="germanSkillLevelCtr" [nationalityCtrl]="nationalityCtr">
        <app-event-ob-step-headline nationality>Welche Staatsbürgerschaft hast du?</app-event-ob-step-headline>
        <app-event-ob-step-headline language>Wie gut sprichst du Deutsch?</app-event-ob-step-headline>
    </german-skill-level>
</div>

<app-event-ob-button-bar (pressedNext)="progress()"></app-event-ob-button-bar>
