import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import { OnboardingEntryComponent } from './onboarding-entry/onboarding-entry.component';
import { CoachInviteWelcomeComponent } from './coach-invite-welcome/coach-invite-welcome.component';
import {UtilityModule} from "utility";
import {OnboardingAuthGuardService} from "../../services/onboarding-auth-guard.service";
import { CherryOBGatewayComponent } from './cherry-obgateway/cherry-obgateway.component';
import {S2FaxOnboardingModule} from "../s2-fax-onboarding/s2-fax-onboarding.module";
import {S2Fax2OnboardingModule} from "../s2-fax2-onboarding/s2-fax2-onboarding.module";
import {CVParsingOnboardingModule} from "../cv-parsing-onboarding/cv-parsing-onboarding.module";
import {
    ConfirmProfileDetailsOnboardingModule
} from "../confirm-profile-details-onboarding/confirm-profile-details-onboarding.module";


const routes = [
  {path: 'onboarding', component: OnboardingEntryComponent, canActivate: [OnboardingAuthGuardService]},
  {path: 'onboarding-ch', component: CherryOBGatewayComponent, canActivate: [OnboardingAuthGuardService]},
  {path: 'onboardingStart', redirectTo: 'onboarding'},
  {path: 'onboarding/coach-welcome', component: CoachInviteWelcomeComponent},
]

@NgModule({
  declarations: [
      OnboardingEntryComponent,
      CoachInviteWelcomeComponent,
      CherryOBGatewayComponent],
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      S2FaxOnboardingModule,
      S2Fax2OnboardingModule,
      CVParsingOnboardingModule,
      ConfirmProfileDetailsOnboardingModule,
      UtilityModule
  ]
})
export class OnboardingParentModule { }

