import {Component, Input, OnInit} from '@angular/core';
import {CoachingTypeData, CoachingTypeResource} from "utility";
import {MediaService} from "../../services/inno-utils/media.service";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'coaching-offers',
  templateUrl: './coaching-offers.component.html',
  styleUrls: ['./coaching-offers.component.scss']
})
export class CoachingOffersComponent implements OnInit {

  @Input() coachRequested: boolean = false

  public selectedCoaching: CoachingTypeData
  public availableCoachingTypes: CoachingTypeData[];
  public selectedCoachingTypes: CoachingTypeData[];
  public coachingsForm = new FormGroup({});

  loadingCoachings: boolean = true


  constructor(
      public mediaService: MediaService,
      private coachingTypeResource: CoachingTypeResource
  ) { }

  ngOnInit(): void {
    this.loadAndInitCoachings()
  }

  getBackgroundImageUrl(coachingTitle: string) {
    switch (coachingTitle) {
      case 'Hilfe im Bewerbungsprozess': return '../../assets/images/careerCoach/applicationDark.jpg';
      case 'Lebenslaufcheck': return '../../assets/images/careerCoach/cvDark.jpg';
      case 'Persönlichkeitsanalyse (AECdisc)': return '../../assets/images/careerCoach/AECDark.jpg';
      case 'Coaching Gehaltsverhandlung': return '../../assets/images/careerCoach/salaryDark.jpg';
      case 'Finanzcoaching zum Jobeinstieg': return '../../assets/images/careerCoach/financeDark.jpg';

    }
  }

  getIconUrl(coachingTitle: string) {
    switch (coachingTitle) {
      case 'Hilfe im Bewerbungsprozess': return '../../assets/images/careerCoach/HandshakeWhite.svg';
      case 'Lebenslaufcheck': return '../../assets/images/careerCoach/cvIconWhite.svg';
      case 'Persönlichkeitsanalyse (AECdisc)': return '../../assets/images/careerCoach/aecdiscLogoWhite.svg';
      case 'Coaching Gehaltsverhandlung': return '../../assets/images/careerCoach/salaryIconWhite.svg';
      case 'Finanzcoaching zum Jobeinstieg': return '../../assets/images/careerCoach/financeIconWhite.svg';

    }
  }

  loadAndInitCoachings() {
    this.loadingCoachings = true
    this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
      this.availableCoachingTypes = coachings;
      this.selectedCoaching = coachings[0]

      this.availableCoachingTypes.forEach(coaching => {
        this.coachingsForm.addControl(coaching.title, new FormControl<boolean>(false));
      });

      this.coachingTypeResource.getSelectedCoachingTypes().then(coachings => {
        this.selectedCoachingTypes = coachings;

        this.selectedCoachingTypes.forEach(coaching => {
          this.coachingsForm.controls[coaching.title].setValue(true);
          if (coaching.title == "Hilfe im Bewerbungsprozess") {
            this.coachingsForm.controls[coaching.title].disable()
          }
        });

        this.coachingsForm.valueChanges.subscribe(() => {
          let choosenCoachingIds: number[] = [];

          Object.keys(this.coachingsForm.controls).forEach(controlName => {
            if (this.coachingsForm.controls[controlName].value == true) {
              choosenCoachingIds.push(this.availableCoachingTypes.filter(coaching => coaching.title == controlName)[0].id);
            }
          })

          this.coachingTypeResource.updateSelectedCoachings({value: choosenCoachingIds});
        })
      })

      this.loadingCoachings = false
    })
  }

}
