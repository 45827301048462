import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {FileService} from "../../services/file.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'cv-upload-button',
  templateUrl: './cv-upload-button.component.html',
  styleUrls: ['./cv-upload-button.component.scss']
})
export class CvUploadButtonComponent implements OnInit {

  readonly cvFile = new FormControl<File[]>(null, this.fileService.uploadFileControlValidator())
  savingCv: boolean = false

  @Output() uploaded: EventEmitter<any> = new EventEmitter()
  @Input() buttonClass: string
  @Input() color: ThemePalette = 'accent'
  @Input() bttColor: string = '#4d4d4d'
  @Input() borderRadius: number = 3

  constructor(
      public fileService: FileService,
      private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  saveCurriculumVitae() {
    if (!this.cvFile.value[0] || this.cvFile.errors) return

    let file = this.cvFile.value[0];
    this.savingCv = true;
    this.fileService.uploadCV(
        file,
    ).subscribe(
        () => {
          this.savingCv = false;
          this.uploaded.emit(true)
        }
    )
  }
}
