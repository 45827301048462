import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";
import {Subject} from "rxjs";
import {MediaService} from "../../../services/inno-utils/media.service";
import {FileService} from "../../../services/file.service";
import {TalentOnboardingResource} from "utility";
import {CachedOBInputs, CoachingOnboardingService} from "../coaching-onboarding.service";

@Component({
  selector: 'app-co-cv-upload',
  templateUrl: './co-cv-upload.component.html',
  styleUrls: ['./co-cv-upload.component.scss']
})
export class CoCvUploadComponent implements OnInit, AfterViewInit {

  controlErrors: ValidationErrors
  fileValidSubject = new Subject()
  cvFile = new FormControl<FileList>(null, this.fileService.uploadFileControlValidator());
  fileIsWordDocument: boolean = false;
  uploadTried: boolean = false;
  preSelectedFile: boolean = false;
  loading: boolean = false;


  constructor(
      public fileService: FileService,
      private onboardingService: CoachingOnboardingService,
      public mediaService: MediaService,
      private talentOnboardingResource: TalentOnboardingResource
  ) {
  }

  ngAfterViewInit(): void {
    this.updateValidSubject()
  }

  ngOnInit(): void {
    let file = this.onboardingService.getCv()

    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      this.cvFile.setValue(dataTransfer.files)
      this.preSelectedFile = true
    }
    this.cvFile.valueChanges.subscribe(() => {
      this.updateValidSubject()
    });
  }

  updateValidSubject(): boolean {
    let valid = (this.cvFile.valid && !this.fileIsWordDocument) || this.preSelectedFile
    this.fileValidSubject.next(valid)
    return valid
  }

  checkForWordDocument(file) {
    if (!file) {
      this.fileIsWordDocument = false
    } else {
      this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
    }

    this.updateValidSubject()
  }

  set(fileEvent: Event) {
    let file = this.cvFile.value[0];
    this.checkForWordDocument(file)
    this.createAccount().then(next => {
      this.attemptUpload();
    })
  }

  createAccount():Promise<any> {
    if (this.loading) return
    this.loading = true;
    return this.onboardingService.createAccount()
  }


  attemptUpload() {
    this.preSelectedFile = false
    this.uploadTried = true
    let valid = this.updateValidSubject()
    this.controlErrors = this.cvFile.errors

    if (valid) {
      this.fileService.uploadCV(this.cvFile.value[0])
      this.onboardingService.updateInputs(<CachedOBInputs>{
        cvUploaded: true
      })
      this.onboardingService.setCV(this.cvFile.value[0])
      this.progress("upload")
    } else {
      this.cvFile.setValue(null)
    }
  }

  skipNoCv() {
    this.createAccount().then(result => {
      this.talentOnboardingResource.reportTalentSkipedCvUploadOnboarding()
      this.progress('skip')
    })

  }

  progress(context: string) {
    this.onboardingService.delayedRouting("/onboarding/coach/obState", context)
  }
}
