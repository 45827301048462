import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {CachedOBInputs, S2Fax2OnboardingService} from "../s2-fax2-onboarding.service";

@Component({
  selector: 'app-s2-fax2-o-welcome',
  templateUrl: './s2-fax2-o-welcome.component.html',
  styleUrls: ['./s2-fax2-o-welcome.component.scss']
})
export class S2Fax2OWelcomeComponent implements OnInit {

    smallerEve: boolean = false
    readonly form = new FormGroup({
        firstName: new FormControl<string>(this.obService.cachedInputs.firstName, Validators.required)
    });
    error: Subject<any> = new Subject<any>()

    constructor(
        private obService: S2Fax2OnboardingService
    ) { }

    ngOnInit(): void {
        this.obService.reportCurrentStepPageview()
    }

    progress(){
        if(!this.form.valid){
            this.error.next();
            return
        }

        this.obService.updateInputs(<CachedOBInputs>{firstName: this.form.controls.firstName.value});
        this.obService.delayedRouting('/onboarding/s2fax2/subject')
    }

}
