import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailAddressAvailabilityValidatorService, UtilsService} from "utility";
import {CachedOBInputs, CVParsingOnboardingService} from "../cv-parsing-onboarding.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {UnisignService} from "../../../services/unisign.service";
import {FileService} from "../../../services/file.service";

@Component({
  selector: 'app-cv-parsing-o-contact-data',
  templateUrl: './cv-parsing-o-contact-data.component.html',
  styleUrls: ['./cv-parsing-o-contact-data.component.scss']
})
export class CVParsingOContactDataComponent implements OnInit {

  public privacyUrl = environment.publicPageUrl + '/privacy'
  public useRegulationsUrl = environment.publicPageUrl + '/use-regulations'
  emailValidationPending = new BehaviorSubject<boolean>(false);

  readonly form = new FormGroup({
    firstName: new FormControl<string>(this.obService.cachedInputs.firstName, [Validators.required, this.obService.noWhitespaceValidator]),
    lastName: new FormControl<string>(this.obService.cachedInputs.lastName, [Validators.required, this.obService.noWhitespaceValidator]),
    acceptPrivacy: new FormControl<boolean>(this.obService.acceptedPrivacy, [Validators.requiredTrue]),
    contactEmailAddress: new FormControl<string>(this.obService.cachedInputs.email, [Validators.required, Validators.email],
      [this.emailAvailabilityValidatorService.createValidator(this.emailValidationPending)]),
    phoneNumber: new FormControl<string>(this.obService.cachedInputs.phoneNumber, [Validators.required]),
  });
  nextStepClicked: boolean = false
  submitFailed: boolean = false

  constructor(
      public obService: CVParsingOnboardingService,
      public analyticsService: AnalyticsService,
      private utilsService: UtilsService,
      private emailAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
      private unisignService: UnisignService,
      private fileService: FileService
) {
  }

  ngOnInit() {
  }


  ngOnDestroy() {
  }

  progressPending = false;
  checkError: Subject<any> = new Subject<any>()
  progressingSubject = new Subject();

  progress() {
    this.checkError.next();
    this.form.updateValueAndValidity()
    if(!this.form.valid){
      this.progressPending = false;
      this.submitFailed = true
      return
    }

    this.progressingSubject.next()

    this.obService.updateInputs(<CachedOBInputs>{
      firstName: this.form.controls.firstName.value,
      lastName: this.form.controls.lastName.value,
      phoneNumber: this.form.controls.phoneNumber.value,
      email: this.form.controls.contactEmailAddress.value
    })

    this.obService.createAccount().then(
        () => {
          if (this.obService.getCv()) this.fileService.uploadCV(this.obService.getCv())
          //Skip coach step if invited by coach
          if(this.analyticsService.trafficOrigin.invitedFromToken){
            this.obService.delayedRouting( "/onboarding/cvp/password")
          }else{
            this.obService.delayedRouting("/onboarding/cvp/coach-intent")
          }
        }
    )
  }

  forgotPassword() {
      this.unisignService.routeToForgotPassword()
  }

}

