import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {ShareTalentAgentToFriendsComponent} from '../utility/share-talent-agent-to-friends/share-talent-agent-to-friends.component';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }


  showSharingButtons(shareText: string = null, utmParams: string = null) {
    const comp = this.resolver
      .resolveComponentFactory(ShareTalentAgentToFriendsComponent)
      .create(this.injector);


    comp.instance.selfDestroy.subscribe(() => {
      this.destroyOverlay(comp);
    });
    comp.instance.shareText = shareText;
    comp.instance.utmParams = utmParams;

    this.appRef.attachView(comp.hostView);

    const dom = (comp.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(dom);
  }

  destroyOverlay(comp: any) {
    comp.instance.initialized = false;
    setTimeout(() => {
      this.appRef.detachView(comp.hostView);
      comp.destroy();
    }, 300);

  }
}
