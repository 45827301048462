<dialog-header headline="Dokumente nachreichen"></dialog-header>

<mat-dialog-content style="max-width: 700px">
    <p class="hint">{{data.company.name}} braucht noch Dokumente von dir um deine Bewerbung bewerten zu können. Lade jetzt die erforderlichen Dokumente als PDF-Datei hoch.</p>

    <div class="requirements" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div class="requirement" *ngFor="let requirement of requirements" fxLayout="row" fxLayoutAlign="start start">
            <div class="dot"></div>
            <div>{{requirement}}</div>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="10px" style="margin: 30px 0">
        <div *ngFor="let control of attachementsGroup.controls | keyvalue; let i = index" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px" class="m-top10">
            <label *ngIf="!control.value.value" class="work-sample" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" [for]="control.key" style="position:relative;">
                <div class="work-sample-file-new" fxLayout="column" fxLayoutAlign="center center">
                    <i class="mdi mdi-plus-circle"></i>
                </div>
                <div style=" color: var(--light-grey);">neuer Anhang</div>
            </label>


            <div *ngIf="control.value.value" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div class="work-sample-file">
                    <div fxLayout="row" fxFlex="1 1" class="action-bar">
                        <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
                            <button mat-icon-button (click)="removeControlFromGroup(control.key)"><i
                                    class="mdi mdi-delete"></i></button>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px"
                     class="file-name">
                    <span style="max-width: 80px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis" [matTooltip]="control?.value.value">{{control?.value.value[0].name}}</span>
                </div>
            </div>

            <input #input type="file" [formControl]="control.value" [id]="control.key" class="inputfile">
        </div>
    </div>

    <div class="errors">
        <small style="color: red" *ngIf="errors != null && errors['sizeTooBig']">Die Datei ist größer als 10MB,
            Bitte wähle eine kleinere Datei
        </small>
        <small style="color: red" *ngIf="errors != null && errors['wrongExtension']">
            Falsche Dateiendung. Bitte wähle ein PDF Dokument aus.
        </small>
    </div>


</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px">
    <button mat-button (click)="close()">schließen</button>
    <button mat-flat-button color="accent"
            [disabled]="!isUploadButtonValid()"
            class="ita_application_sent ita_positionType_external"
            (click)="upload()"
            [loadingBool]="uploading">Dokument(e) hochladen
    </button>
</mat-dialog-actions>
