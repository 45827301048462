import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../talent/src/environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {LabelData} from "utility";
import {MediaService} from "../../../../../talent/src/app/services/inno-utils/media.service";


interface SafeImageData {
    url: SafeResourceUrl,
    description: string
}

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})

export class ImageGalleryComponent implements OnInit {

    showVideo: boolean = false
    showImages: boolean = false
    environmentVar
    activeImageIndex: number = 0
    @Input() videoUrl: string = ''
    @Input() splitId?: number = null
    @Input() apiImageUrls: string[] = []
    @Input() customerImages: LabelData[] = []
    safeUrl: SafeResourceUrl

    positionImages: SafeImageData[] = []

    constructor(
        private sanitizer: DomSanitizer,
        public mediaService: MediaService
    ) {
        this.environmentVar = environment
    }

    ngOnInit(): void {
        if (this.videoUrl?.length > 0) {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
            this.showVideo = true
        }

        this.positionImages = this.getPositionImage()
        if (this.positionImages.length) this.showImages = true
    }

    getBackgroundImageUrl(url): string {
        return url?.url.changingThisBreaksApplicationSecurity
    }

    getContentCount(): number {
        let count = 0
        if (this.videoUrl?.length > 0) count++
        if (this.positionImages?.length) count = count + this.positionImages.length
        return count
    }

    getPositionImage(): SafeImageData[] {
        let customerImages = this.customerImages.map(img => {
            return <SafeImageData>{
                url: this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/public/contentSplits/${this.splitId}/picture/${img.id}`),
                description: img.name
            };
        })

        let apiImages = this.apiImageUrls.map(img => {
            return <SafeImageData>{
                url: this.sanitizer.bypassSecurityTrustResourceUrl(img),
                description: null
            };
        })

        return apiImages.concat(customerImages)
    }


    showImageVideoSelection()
        :
        boolean {
        return this.getContentCount() > 1
    }

    showGallery()
        :
        boolean {
        return this.getContentCount() > 0
    }

}
