import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TalentAuthService} from "../../services/talent.auth.service";
import {MediaService} from "../../services/inno-utils/media.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-prime-recommendation-redirect-dialog',
  templateUrl: './prime-recommendation-redirect-dialog.component.html',
  styleUrls: ['./prime-recommendation-redirect-dialog.component.scss']
})
export class PrimeRecommendationRedirectDialogComponent implements OnInit {

  talentFirstName: string = ''

  constructor(
      public dialogRef: MatDialogRef<PrimeRecommendationRedirectDialogComponent>,
      public mediaService: MediaService,
      public router: Router,
      private authService: TalentAuthService,
  ) {
    this.talentFirstName = this.authService.getAccountInfo().firstName
  }

  ngOnInit(): void {
  }

  navigateToRecommendation() {
    this.router.navigate(["/talent/proposals/recommendations"])
    this.dialogRef.close()
  }
}
