import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {ShareService} from "../../services/share.service";
import {MediaService} from "../../services/inno-utils/media.service";
import {TalentAuthService} from "../../services/talent.auth.service";

@Component({
  selector: 'ta-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() siteNoticeLink
  @Input() privacyUrl
  @Input() useRegulations

  constructor(
      public router: Router,
      private shareService: ShareService,
      public mediaService: MediaService,
      public authService: TalentAuthService
  ) { }

  ngOnInit(): void {
  }

  openSocialMediaUrl(url: string) {
    window.open(url, '_blank')
  }

  initShare() {
    this.shareService.showSharingButtons('Hey, schau dir mal Talentagent an: ');
  }


  navigateToMainPage() {
    window.location.href = 'https://hochschulinitiative-deutschland.de/';
  }
}
