import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
    AccountInfoData,
    RejectPositionProposalData,
    TalentApplicationResource,
    TalentPositionDetailsData,
    TalentPositionInteractionOrigin,
    TalentPositionPreviewData,
    TalentPositionRelationData,
    TalentPositionRelationResource
} from "utility";
import {Router} from "@angular/router";
import {MediaMatcher} from "@angular/cdk/layout";
import {
    TalentCreateApplicationDialogComponent
} from "../dialogs/talent-create-application-dialog/talent-create-application-dialog.component";
import {
    ConfirmExternalApplicationAlertComponent
} from "../dialogs/confirm-external-application-alert/confirm-external-application-alert.component";
import {BehaviorSubject, Subject} from "rxjs";
import {ApplicationCockpitDialogComponent} from "../application-cockpit-dialog/application-cockpit-dialog.component";
import {PropagationService} from "./propagation.service";
import {MediaService} from "./inno-utils/media.service";
import {BottomSheetService} from "../utility/slideable-bottom-sheets/bottom-sheet.service";
import {PositionsDetailsComponent} from "../search/positions-details/positions-details.component";
import {TalentAuthService} from "./talent.auth.service";

@Injectable({
    providedIn: 'root'
})
export class PositionService {

    accountInfo: AccountInfoData
    mobileQuery: MediaQueryList;
    selectedPositionId: BehaviorSubject<number> = new BehaviorSubject<number>(null)
    selectedSplitId: number | null = null

    relationProceeded: Subject<{ relation: TalentPositionRelationData, action: 'Bookmarked' | 'BookmarkRemoved' | 'Rejected' | 'Applied' }> = new Subject()

    hideMobileSearchbar = false;
    searchBarFixed = false;

    constructor(
        public router: Router,
        private dialog: MatDialog,
        private media: MediaMatcher,
        private mediaService: MediaService,
        private talentPositionRelationResource: TalentPositionRelationResource,
        private talentAuthService: TalentAuthService,
        public propagationService: PropagationService,
        private talentApplicationResource: TalentApplicationResource,
        private bottomSheetService: BottomSheetService,
    ) {
        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    }

    markAsClicked(positionId: number, origin: TalentPositionInteractionOrigin) {
            this.talentPositionRelationResource.markPositionProposalsAsClicked({
                positionIds: [positionId],
                origin: origin
            });
    }


    //This could be way more abstract but i just went along with it (ask max)
    removePositionFromList(data: TalentPositionRelationData, relationList: TalentPositionPreviewData[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                if (!relationList.some(item => item.id === data.positionId)) return

                let foundElement = relationList.find(el => el.id == data.positionId);
                let index = relationList.indexOf(foundElement)
                if (index >= 0) relationList.splice(index, 1)

                let nextIndex = index
                if (index > (relationList.length - 1)) nextIndex = index - 1
                this.selectedPositionId.next(
                    nextIndex >= 0 ? relationList[nextIndex].id : null)

                resolve()
            }
            catch(error) {
                reject(error)
            }

        })

    }



    bookmarkProposal(talentPositionRelation: TalentPositionRelationData, origin: TalentPositionInteractionOrigin): Promise<null> {
        return new Promise<null>(resolve => {
            this.talentPositionRelationResource.bookmarkPositionProposal({
                positionId: talentPositionRelation?.positionId,
                origin: origin
            }).then(() => {
                talentPositionRelation.isBookmarked = true;
                resolve(null)
            });
        })
    }

    removeBookmark(talentPositionRelation: TalentPositionRelationData) {
        return new Promise<null>(resolve => {
            this.talentPositionRelationResource.removeBookmark({
                positionId: talentPositionRelation?.positionId
            }).then(() => {
                talentPositionRelation.isBookmarked = false;
                resolve(null)
            })
        })
    }

    getAccountInfo(): AccountInfoData {
        this.accountInfo = this.talentAuthService.getAccountInfo()
        return this.accountInfo
    }

    rejectMatchingPosition(talentPositionRelation: TalentPositionDetailsData, origin: TalentPositionInteractionOrigin): Promise<any> {
        return new Promise<any>(resolve => {

            const rejectData = <RejectPositionProposalData>{
                positionId: talentPositionRelation.employmentPosition.id,
                positionRejectedReasons: ['OtherReason'],
                comment: null
            }

            this.talentPositionRelationResource.rejectPositionProposal(rejectData, {origin: origin}).then(result => {
                resolve(true)
                this.propagationService.refreshRecommendedProposalCount();
                this.propagationService.refreshRecommendedProposalCount();
                this.bottomSheetService.closeCurrentBottomSheet()

                
            });
        })
    }

    confirmExternalApplication(talentPositionRelation: TalentPositionDetailsData, origin: TalentPositionInteractionOrigin): Promise<any> {
        return new Promise(resolve => {
            let dialogRef = this.dialog.open(ConfirmExternalApplicationAlertComponent, {
                data: {
                    relation: talentPositionRelation,
                    origin: origin
                },
                panelClass: ['full-width-mobile-dialog', 'round-border-dialog'],
                autoFocus: false
            });

            let sub = dialogRef.afterClosed().subscribe(confirmed => {
                sub.unsubscribe()
                if (confirmed === true) {
                    //if you applied to a bookmarked position it is removed from the favorite list
                    this.propagationService.refreshBookmarkedProposalsCount();
                    this.propagationService.applicationSent.next(true)
                    this.relationProceeded.next({relation: talentPositionRelation.relationData, action: "Applied"})
                }
                resolve(confirmed === true)
            })
        })
    }

    sendExternApplication(origin, relation: TalentPositionDetailsData): Promise<any> {
        return this.talentApplicationResource.createExternalApplication(relation.employmentPosition.id,{
            origin: origin,
        }).then(() => {
                this.relationProceeded.next({relation: relation.relationData, action: "Applied"})
            }
        )
    }

    trackTalentCtaClickedForPosition(positionId: number, origin: TalentPositionInteractionOrigin): Promise<any> {
        return this.talentPositionRelationResource
            .trackTalentCtaClickedForPosition(positionId, {origin: origin})
    }

    //Bookmarks as default because origin is always given to the function, just in case user bookmarks url in browser or navigating back to position details on mobile via browser history can cause an empty origin input. but in this case it is a bookmark
    applyForPosition(talentPositionRelation: TalentPositionDetailsData, origin: TalentPositionInteractionOrigin = "Bookmarks"): Promise<boolean> {
        this.trackTalentCtaClickedForPosition(talentPositionRelation.employmentPosition.id, origin)
            .then(() => {
            })
        return new Promise(resolve => {
            if (talentPositionRelation.employmentPosition.applicationMode === 'InApp' && talentPositionRelation.employmentPosition.employmentPositionOrigin != "Campusjaeger") {
                let dialogRef = this.dialog.open(
                    TalentCreateApplicationDialogComponent,
                    {
                        data: {
                            talentPositionRelation: talentPositionRelation,
                            companyName: talentPositionRelation.employmentPosition?.hiringCompanyName ? talentPositionRelation.employmentPosition.hiringCompanyName : '',
                            positionId: talentPositionRelation.employmentPosition.id,
                            positionTitle: talentPositionRelation.employmentPosition.content.title,
                            origin: origin,
                            companyLogo: talentPositionRelation.employmentPosition.companyLogoUrl,
                            positionLocations: talentPositionRelation.employmentPosition?.locations ? talentPositionRelation.employmentPosition.locations : []
                        },
                        // width: this.mediaService.getMaxWidthQuery(959).matches ? '100vw' : '700px',
                        maxWidth: '100vw',
                        autoFocus: false,
                        disableClose: true,
                        panelClass: ['apply-dialog']
                    }
                );

                dialogRef.afterClosed().subscribe(result => {
                    if (result) this.propagationService.applicationSent.next(true)
                        resolve(result)
                })
            } else if (['Joblift', 'Absolventa', 'JobFeed'].includes(talentPositionRelation.employmentPosition.employmentPositionOrigin)) {
                window.open(talentPositionRelation.employmentPosition.webLink, '_blank');
                let dialogRef = this.dialog.open(ConfirmExternalApplicationAlertComponent, {
                    data: {
                        relation: talentPositionRelation,
                        origin: origin
                    }
                })
                let sub = dialogRef.afterClosed().subscribe(res => {
                    this.propagationService.applicationSent.next(true)
                    sub.unsubscribe()
                    resolve(res)
                })
            } else {
                let dialogRef = this.dialog.open(ApplicationCockpitDialogComponent, {
                        data: {relation: talentPositionRelation, origin: origin},
                        maxWidth: '100vw',
                        autoFocus: false,
                        disableClose: true,
                        panelClass: ['apply-cockpit-dialog']
                    }
                )

                let sub = dialogRef.afterClosed().subscribe(res => {
                    if (res) this.propagationService.applicationSent.next(true)
                    sub.unsubscribe()
                    resolve(res)
                })
            }
        })
    }

    openPositionDetails(positionId: number, origin: TalentPositionInteractionOrigin): Promise<any> {
        this.selectedPositionId.next(positionId)

        if (this.talentAuthService.isAuthenticated()) this.markAsClicked(positionId, origin)

        return new Promise(resolve => {


            if (this.mediaService.getMaxWidthQuery(960).matches) {
                this.bottomSheetService.openBottomSheet(PositionsDetailsComponent, {positionId: this.selectedPositionId, origin: origin}).then(result => {
                    let sub = result.afterDismissed().subscribe(result => {
                        if (result) this.propagationService.applicationSent.next(true)
                        sub.unsubscribe()
                        resolve(result)
                    })
                })
            } else {
                resolve(null)
            }
        }
    )


    }
}
