<div id="footer">
    <div id="footer-info-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column-reverse" >
        <div *ngIf="!mediaService.getMaxWidthQuery(959).matches" style="opacity: 50%">
            <i class="mdi mdi-copyright"></i> Uniwunder GmbH
        </div>
        <a
                *ngIf=" !mediaService.getMaxWidthQuery(959).matches && (router.url == '/' || router.url.includes('preventRedirect') || router.url.includes('talent/login'))"
                class="footer-link" (click)="navigateToMainPage()">Hauptseite</a>
        <a *ngIf="!mediaService.getMaxWidthQuery(959).matches" [href]="useRegulations" target="_blank" class="footer-link">Nutzungsbedingungen</a>
        <a *ngIf="!mediaService.getMaxWidthQuery(959).matches" [href]="privacyUrl" target="_blank" class="footer-link">Datenschutz</a>
        <a *ngIf="!mediaService.getMaxWidthQuery(959).matches" [href]="siteNoticeLink" target="_blank" class="footer-link">Impressum</a>
        <div *ngIf="!mediaService.getMaxWidthQuery(959).matches" fxHide.lt-md style="opacity: 0;">
            <i class="mdi mdi-copyright"></i> Uniwunder GmbH
        </div>
    </div>
</div>
