import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {CachedOBInputs, S2FaxOnboardingService} from "../s2-fax-onboarding.service";

@Component({
  selector: 'app-s2-fax-o-welcome',
  templateUrl: './s2-fax-o-welcome.component.html',
  styleUrls: ['./s2-fax-o-welcome.component.scss']
})
export class S2FaxOWelcomeComponent implements OnInit {

  smallerEve: boolean = false
  readonly form = new FormGroup({
    firstName: new FormControl<string>(this.obService.cachedInputs.firstName, Validators.required)
  });
  error: Subject<any> = new Subject<any>()

  constructor(
      private obService: S2FaxOnboardingService
  ) { }

  ngOnInit(): void {
    this.obService.reportCurrentStepPageview()
  }

  progress(){
    if(!this.form.valid){
      this.error.next();
      return
    }

    this.obService.updateInputs(<CachedOBInputs>{firstName: this.form.controls.firstName.value});
    this.obService.delayedRouting('/onboarding/s2fax/subject')
  }

}
