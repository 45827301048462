import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {ChatData, ChatMemberData, ChatMinimalData} from "utility";
import {FileService} from "./file.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  refreshChat: Subject<boolean> = new Subject<boolean>()

  constructor(
      private fileService: FileService
  ) { }

  getChatPartnerByChat(chat: ChatData | ChatMinimalData): ChatMemberData {
    return chat?.members.find(member => member.dtype != 'Talent')
  }

  getPartnerProfilePicture(chat: ChatData | ChatMinimalData, type: string): any {
    if (type == "CoachingChat") {
      return this.fileService.getTalentAgentProfilePicture()
    }
    if (type == "ApplicantChat") {
      return this.fileService.getRecruiterProfilePicture(this.getChatPartnerByChat(chat).id)
    }
  }

}
