import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements AfterViewInit {
    @Output() inView: EventEmitter<boolean> = new EventEmitter();

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.inView.emit(entry.isIntersecting);
            });
        }, { threshold: [0] });

        observer.observe(this.el.nativeElement);
    }
}
