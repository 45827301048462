import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ConsultantProfileDisplayData, TalentOnboardingResource} from "utility";
import {FileService} from "../../../services/file.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
  selector: 'app-coach-invite-welcome',
  templateUrl: './coach-invite-welcome.component.html',
  styleUrls: ['./coach-invite-welcome.component.scss']
})
export class CoachInviteWelcomeComponent implements OnInit {

  public privacyUrl = environment.publicPageUrl + '/privacy'
  coachData: ConsultantProfileDisplayData
  loadingCoach: boolean = true
  talentAgentImageUrl = './../../assets/images/blurred_user.png';

  screenHeight: number = 0
  screenWidth: number = 0

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
      private fileService: FileService,
      private route: ActivatedRoute,
      private router: Router,
      private analyticsService: AnalyticsService,
  public talentOnboardingResource: TalentOnboardingResource) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      this.getCoachInfo()
      this.getCoachImage()
    })
  }

  getCoachImage() {
    this.fileService.getCoachProfilePictureByInviteLinkToken(this.analyticsService?.trafficOrigin?.invitedFromToken).subscribe(next => {
      if (next.size > 0) {
        this.talentAgentImageUrl = URL.createObjectURL(next);
      }
    })
  }

  getCoachInfo() {
    this.loadingCoach = true
    this.talentOnboardingResource.getCoachInfo({token: (this.analyticsService?.trafficOrigin?.invitedFromToken)}).then(result => {
      this.coachData = result
      this.loadingCoach = false
    })

  }

  startOnboarding(){
    this.router.navigate(["/onboarding"])
  }

  ngOnDestroy() {
  }

}
