import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, Validators} from "@angular/forms";

@Component({
  selector: 'text-question-input',
  templateUrl: './text-question-input.component.html',
  styleUrls: ['./text-question-input.component.scss']
})
export class TextQuestionInputComponent implements OnInit {

    @Input() control: AbstractControl<string>
    @Input() characterLimit: number
    @Input() appearance: "legacy" | "outline" | "standard" | "fill" = "outline";

    ngOnInit(): void {
    }
}
