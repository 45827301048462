import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../services/inno-utils/media.service";

@Component({
  selector: 'loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss']
})
export class LoadingSkeletonComponent implements OnInit {

  arr = Array
  @Input() tileSize: 'small' | 'normal' = 'normal'
  @Input() count: number = 10
  @Input() roundedCorners: boolean = false

  constructor(
      public mediaService: MediaService
  ) { }

  ngOnInit(): void {
  }

}
