

  <div class="wrapper" #wrapper>
    <hid-input-field [control]="searchControl"
                     [label]="label"
                     [showValidatorIcons]="showValidatorIcons"
                     [forceValidation]="forceValidation"
                     [disabled]="disabled"
                     [autocomplete]="'off'"
                     [matAutocomplete]="auto"
                     (inputFocused)="openMenuByFocusId()"
    >
    </hid-input-field>

    <div [fxHide]="!showSmallLoadingSpinner" fxLayoutAlign="end center">
      <i class="input-icon validator-icon fas fa-spinner"></i>
    </div>

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="location-mat-menu">
          <mat-option *ngFor="let option of options; let index = index" (onSelectionChange)="setModel(option)" (click)="setModel(option, $event)">
              {{option[this.displayProperty]}}
          </mat-option>
      </mat-autocomplete>

<!--      <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false"  class="location-mat-menu">-->
<!--        <div class="loading-overlay center-content" *ngIf="loadingOptions">-->
<!--          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>-->
<!--        </div>-->
<!--        <ng-container>-->
<!--        <div [ngStyle]="{'width': wrapper.clientWidth + 'px'}" class="option" fxLayout="row" mat-menu-item-->
<!--             *ngFor="let option of options" (click)="setModel(option, $event)">-->
<!--          {{option[this.displayProperty]}}-->
<!--        </div>-->
<!--        </ng-container>-->

<!--      </mat-menu>-->
  </div>
