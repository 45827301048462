
<div class="whoAreU s2o-stepwrapper" fxLayout="column" fxLayoutAlign="start center" [ngClass]="{'smallerEve': smallerEve}">
    <div class="eve-rapper">
        <img src="../../../../assets/images/eve-dropshadow.svg" class="eve-image">
    </div>

    <div class="eve-bubble">
        <div class="triangle-top"></div>
        Hey! ✌🏻 Ich bin Eve und unterstütze dich beim Anlegen deines Profils, um dich mit deinem Traumjob zu matchen.
    </div>


</div>

<app-cv-parsing-o-step-headline>Express-Anmeldung mit Lebenslauf</app-cv-parsing-o-step-headline>

<div class="upload-container" fxLayout="column" fxLayoutAlign="start center">

    <div *ngIf="cvFile.value != null" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" >
        <div class="work-sample-file-after-upload" fxLayout="column" fxLayoutAlign="center center">
            <div class="delete-icon">
                <button mat-icon-button (click)="this.cvFile.setValue(null)"><i class="mdi mdi-delete"></i></button>
            </div>
            <div class="done-icon-container">
                <mat-icon class="done-icon">done</mat-icon>
            </div>
            <div class="uploaded-text">Datei hochgeladen</div>
            <div class="upload-cv-text">
                <i class="mdi mdi-file-upload-outline"></i>
                {{cvFile?.value[0]?.name ? cvFile?.value[0]?.name : 'Lebenslauf'}}
            </div>
        </div>
    </div>

    <label *ngIf="cvFile.value == null" class="ita_profile_box ita_upload_cv" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px" [for]="'cvFileInputFax'" style="position:relative;">
        <div app-file-drag-drop (fileDropped)="cvFile.setValue($event, null)" class="work-sample-file-new upload-cv" fxLayout="column" fxLayoutAlign="center center">
            <i class="mdi mdi-file-upload-outline"></i>
            <div class="upload-cv-text">Lebenslauf hochladen (pdf)</div>
            <div class="upload-button-text">Datei wählen</div>
        </div>
    </label>
    <input type="file"
               (change)="set($event)"
               [accept]="fileService.whiteListedCvExtensionsStr"
               [formControl]="cvFile" id="cvFileInputFax" #cvFileInputFax class="inputfile">

    <div id="file-errors" *ngIf="uploadTried" fxLayout="column" class="m-top10">
        <small style="color: red" *ngIf="controlErrors!= null && controlErrors['sizeTooBig']">
            Die Datei ist größer als 10MB, Bitte wähle eine kleinere Datei.
        </small>
        <small style="color: red" *ngIf="controlErrors != null && controlErrors['wrongExtension']">
            Falsches Format. Bitte wähle ein PDF-Dokument.
        </small>
        <small *ngIf="controlErrors != null && controlErrors['fileIsEmpty']">
            Die ausgewählte Datei ist leer.
        </small>
    </div>
    <div *ngIf="cvFile.value == null" class="helper-text">
        Mit Upload Deines Lebenslaufs willigst Du darin ein, dass dieser mit Hilfe von KI ausgewertet wird.
        Dies könnte auch besondere Kategorien personenbezogener Daten betreffen.
        Näheres dazu findest Du in unserer
        <a style="font-size: 0.8rem" [href]="privacyUrl" target="_blank">Datenschutzerklärung</a>.
    </div>
</div>

<div *ngIf="cvFile.value == null" class="oder-text">
    oder
</div>

<div *ngIf="cvFile.value == null" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
    <button class="skip-button" (click)="progress()">
        Anmeldung ohne Lebenslauf
    </button>
</div>

<div *ngIf="cvFile.value != null">
    <app-cv-parsing-o-button-bar (pressedNext)="progress()"></app-cv-parsing-o-button-bar>
</div>


