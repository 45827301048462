import {Component, OnInit} from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";
import {Subject} from "rxjs";
import {CachedOBInputs, CVParsingOnboardingService} from "../cv-parsing-onboarding.service";
import {FileService} from "../../../services/file.service";
import {MediaService} from "../../../services/inno-utils/media.service";
import {TalentOnboardingResource} from "utility";
import {environment} from "../../../../environments/environment";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
  selector: 'app-cv-parsing-o-welcome',
  templateUrl: './cv-parsing-o-welcome.component.html',
  styleUrls: ['./cv-parsing-o-welcome.component.scss']
})
export class CVParsingOWelcomeComponent implements OnInit {
  smallerEve: boolean = false
  error: Subject<any> = new Subject<any>()
  controlErrors: ValidationErrors
  fileValidSubject = new Subject()
  readonly cvFile = new FormControl<FileList>(null, this.fileService.uploadFileControlValidator());
  fileIsWordDocument: boolean = false;
  uploadTried: boolean = false;
  preSelectedFile: boolean = false;
  privacyUrl = environment.publicPageUrl + '/privacy';


  constructor(
      private onboardingService: CVParsingOnboardingService,
      public fileService: FileService,
      public mediaService: MediaService,
      private talentOnboardingResource: TalentOnboardingResource,
      private analyticsService: AnalyticsService
  ) { }

  ngAfterViewInit(): void {
    this.updateValidSubject()
  }

  ngOnInit(): void {
    this.onboardingService.reportCurrentStepPageview()

    let file = this.onboardingService.getCv()

    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      this.cvFile.setValue(dataTransfer.files)
      this.preSelectedFile = true
    }
    this.cvFile.valueChanges.subscribe(() => {
      this.updateValidSubject()
    });
  }

  progress(){
    let context = "next"
    if (!this.cvFile.value) {
      context = "skip"
      this.onboardingService.skippedCvUpload = true
    }

    this.onboardingService.delayedRouting('/onboarding/cvp/subject', context)
  }

  updateValidSubject(): boolean {
    let valid = (this.cvFile.valid && !this.fileIsWordDocument) || this.preSelectedFile
    this.fileValidSubject.next(valid)
    return valid
  }

  checkForWordDocument(file) {
    if (!file) {
      this.fileIsWordDocument = false
    } else {
      this.fileIsWordDocument = file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx');
    }

    this.updateValidSubject()
  }

  set(fileEvent: Event) {
    let file = this.cvFile.value[0];

    this.checkForWordDocument(file)
    this.attemptUpload();
  }


  attemptUpload() {
    this.preSelectedFile = false
    this.uploadTried = true
    let valid = this.updateValidSubject()
    this.controlErrors = this.cvFile.errors

    if (valid) {
      this.fileService.parseCVWithoutAccount(this.cvFile.value[0]).subscribe( prepopulationData => {
        this.onboardingService.updateInputs(<CachedOBInputs>{
          firstName: prepopulationData.firstName,
          lastName: prepopulationData.lastName,
          phoneNumber: prepopulationData.phoneNumber,
          email: prepopulationData.emailAdress,
          subjectId: prepopulationData.mostRecentStudies?.id,
          preferredCityId: prepopulationData.location?.id,
          cvUploaded: true,
          // studyEndDate: prepopulationData.studyEndDate
        })
        this.analyticsService.reportCVParsingOnboardingParsingData(prepopulationData)
      }
    )

      this.onboardingService.setCV(this.cvFile.value[0])
    } else {
      this.cvFile.setValue(null)
    }
  }


}
